import { Component, Inject, Input } from "@angular/core";
import { EnvironmentSpecificConfig } from "../../../environment/environment.common";
import { ENVIRONMENT } from "../../../../../../../src/app/injection-token";

@Component({
    selector: "portal-brand",
    templateUrl: "./portal-brand.component.html",
    styleUrls: ["./portal-brand.component.scss"]
})
export class PortalBrandComponent {
    @Input() type: "menu" | "onboarding" = "onboarding";

    readonly secondaryBranding = this.environment.featureFlags.secondaryBranding;

    constructor(@Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig) {}
}
