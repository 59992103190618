import { Component, EventEmitter, Inject, Input, Output } from "@angular/core";
import { MatDialogConfig } from "@angular/material/dialog";
import { IThread, Role } from "@findex/threads";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { CREATE_CARD_LIBRARY } from "src/app/injection-token";
import { GA_EVENTS } from "../../../analytics";
import { CreateCardExtension, ExtensionEntry, ILibrary } from "../../../plugins";

export interface ICreateCardEvent {
    component?: any;
    type?: string;
    thread?: IThread;
    extensionConfig?: { extensionId: string };
    data?: any;
    config?: MatDialogConfig;
}

@Component({
    selector: "create-card",
    templateUrl: "./create-card.component.html",
    styleUrls: ["./create-card.component.scss"]
})
export class CreateCardComponent {
    readonly gaEvents = GA_EVENTS;

    @Input() role: Role;
    @Input() globalRole: Role;
    @Input() showLoader: boolean;
    @Output() newCard = new EventEmitter<ICreateCardEvent>();

    toggleContextMenuState: boolean;
    toggleMeetingContextMenuState: boolean;
    roles = Role;
    loader = new Loader();

    cardExtensions: ExtensionEntry<CreateCardExtension>[];

    constructor(@Inject(CREATE_CARD_LIBRARY) private createCardExtensions: ILibrary<CreateCardExtension>) {
        this.cardExtensions = this.createCardExtensions.listAll();
    }

    async addCard(component: any, data: any, config: any): Promise<void> {
        this.toggleContextMenu();
        const event: ICreateCardEvent = {
            component,
            data,
            config
        };
        this.newCard.emit(event);
    }

    toggleContextMenu() {
        this.toggleContextMenuState = !this.toggleContextMenuState;
    }
}
