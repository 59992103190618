import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
    selector: "fx-tile-button",
    templateUrl: "./fx-tile-button.component.html",
    styleUrls: ["./fx-tile-button.component.scss"]
})
export class FxTileButtonComponent implements OnInit {
    selected: boolean;
    disabled: boolean;
    showTooltip: boolean;

    /**
     * Set tool tip text as string.
     */
    @Input() tooltip: string;

    /**
     * Value to be set and returned as form value.
     */
    @Input() value: string;

    @Output() selectionAppend = new EventEmitter();
    @Output() selectionRemove = new EventEmitter();

    constructor() {}

    ngOnInit() {}

    select() {
        this.selected = !this.selected;
        if (this.selected) {
            this.selectionAppend.emit(this.value);
        } else {
            this.selectionRemove.emit(this.value);
        }
    }
}
