<centered-modal-layout
    (save)="updateThread()"
    (close)="close()"
    [disableSave]="!dueDates"
    analyticsPrefix="update-due-dates"
>
    <div modal-header class="new-thread-title">
        Service Key Dates
    </div>
    <div modal-content>
        <loader-icon *ngIf="loader.counter" color="light"></loader-icon>
        <due-dates
            [title]="thread.title"
            [workflow]="workflow$ | async"
            [workflowState]="thread.workflow"
            [startDate]="startDate"
            (datesUpdated)="updateDueDates($event)"
        >
        </due-dates>
    </div>
</centered-modal-layout>
