import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { WorkflowService } from "../../services/workflow.service";
import { environmentCommon } from "../../../environment/environment.common";
export enum ThreadStatus {
    active = "active",
    closed = "closed",
    cancelled = "cancelled"
}
@Component({
    selector: "status-badge",
    templateUrl: "./status-badge.component.html",
    styleUrls: ["./status-badge.component.scss"]
})
export class StatusBadgeComponent implements OnChanges {
    @Input() workflow;
    readonly states = environmentCommon.workflow.states;

    status: ThreadStatus;
    constructor(private workflowService: WorkflowService) {}

    async ngOnChanges(changes: SimpleChanges) {
        const { workflow } = changes;
        if (workflow && workflow.currentValue) {
            const updatedWorkflow = workflow.currentValue;
            const currentStep = this.workflowService.getCurrentStep(updatedWorkflow);
            const currentStatus = currentStep && (currentStep.internalId || currentStep.clientFacingId);
            switch (currentStatus) {
                case this.states.closed:
                    this.status = ThreadStatus.closed;
                    break;
                case this.states.cancelled:
                    this.status = ThreadStatus.cancelled;
                    break;
                default:
                    this.status = ThreadStatus.active;
                    break;
            }
        }
    }
}
