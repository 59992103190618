import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { IllustrationComponent } from "./components/illustration/illustration.component";

@NgModule({
    imports: [CommonModule],
    exports: [IllustrationComponent],
    declarations: [IllustrationComponent]
})
export class IllustrationsModule {}
