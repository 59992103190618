<app-card
    class="thread-card"
    [ngClass]="{
        'thread-card-expanded': expandCardView || (edit | async) || !(thread | isThreadActive | async)
    }"
    [title]="title"
    [name]="card.createdBy === 'system' ? appName : (card.createdBy | userToName | async)"
    [image]="card.createdBy | profilePicture: cardBorderColor === 'secondary'"
    [timestamp]="card.createdAt"
    [description]="card.description"
    [invertTheme]="invertTheme"
    [edited]="edited"
    [edit]="edit"
    [type]="cardBorderColor"
    [align]="invertTheme ? 'right' : 'left'"
    (save)="save.emit($event)"
    (showSeeMore)="showSeeMore = $event"
    [loading]="loading"
    [attr.data-id]="card.id"
>
    <div
        class="thread-card-see-more"
        (click)="expandCardView = !expandCardView"
        *ngIf="!loading && !(edit | async) && showSeeMore"
    >
        <div *ngIf="thread | isThreadActive | async">
            <i class="las la-angle-{{ expandCardView ? 'up' : 'down' }} thread-card-see-more-icon"></i
            >{{ expandCardView ? "Read less" : "Read more" }}
        </div>
    </div>
    <p class="thread-card-error" *ngIf="!loading && card?.status === cardStatuses.Disabled">
        Tasks on this card have been cancelled.
    </p>
    <ng-container card-actions>
        <ng-content select="[card-actions]"></ng-content>
    </ng-container>
    <ng-container card-loader>
        <ng-content select="[card-loader]"></ng-content>
    </ng-container>
    <ng-content *ngIf="!loading"></ng-content>
    <div *ngIf="!hideReplies" card-replies>
        <thread-card-replies
            [replies]="replies"
            [card]="card"
            [thread]="thread"
            [userId$]="userId$"
        ></thread-card-replies>
    </div>
</app-card>
<div *ngIf="cardVisible$ | async" class="thread-card-readStatus" [ngClass]="{ 'thread-card-readStatus-right': invertTheme }">
    <card-read-status [cardId]="card.id" [threadId]="thread.id"></card-read-status>
</div>
