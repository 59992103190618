import { Component, EventEmitter, Inject, Output } from "@angular/core";
import { IOnboardingStep } from "../onboarding-completion/IOnboardingStep";
import { EnvironmentSpecificConfig } from "../../../../../../projects/portal-modules/src/lib/environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";

@Component({
    selector: "app-business-name",
    templateUrl: "./business-name.component.html",
    styleUrls: ["./business-name.component.scss"],
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: { class: "fx-onboarding-modal-template" }
})
export class BusinessNameComponent implements IOnboardingStep {
    @Output() modelValidated: EventEmitter<boolean> = new EventEmitter();
    @Output() modelUpdated: EventEmitter<any> = new EventEmitter();
    @Output() progressState: EventEmitter<any> = new EventEmitter();

    businessName = "";

    isValid: boolean;
    readonly appName: string;

    constructor(@Inject(ENVIRONMENT) environment: EnvironmentSpecificConfig) {
        this.appName = environment.appName;
    }

    updateParentModel() {
        this.modelUpdated.emit({ businessName: this.businessName });
    }

    nameUpdated() {
        this.isValid = this.businessName.trim() !== "";
        this.modelValidated.emit(this.isValid);
        this.updateParentModel();
    }

    updateModel(data: any) {
        this.businessName = data.businessName || "";
        this.nameUpdated();
    }
}
