<div class="accounts">
    <div class="accounts-header">
        <div>
            <h3>Insights</h3>
            <div>
                Financial performance overview and management reporting
            </div>
        </div>
        <div class="accounts-search-container">
            <app-thread-search
                placeholder="Search accounts"
            ></app-thread-search>
            <!-- <app-thread-search
                [analyticsClickEvent]="gaEvents.ACCOUNTS_SEARCHSUBMIT"
                [isMobileView]="isMobileView"
                (search)="updateSearchTerm($event)"
                (active)="expandSearch = $event"
            ></app-thread-search> -->
        </div>
    </div>

    <loader-icon *ngIf="loader.counter" color="light"></loader-icon>

    <div class="accounts-table">
        <fx-table [tableData]="tableData">
            <ng-container
                *fxColumn="
                    'entity';
                    label: 'Entity';
                    sortable: true;
                    mobileCollapse: true;
                    mobileHideLabel: true;
                    sortAnalyticsEvent: gaEvents.DASHBOARD_FILTER_TITLE;
                    let data
                "
            >
                <div class="threads-title">
                    <a
                        [routerLink]="['/insights/scorecard/overview', data.enterpriseId, 'entity', data.id]"
                        [analyticsClick]="gaEvents.DASHBOARD_TIMELINE"
                    >
                        {{ data.label }}
                    </a>
                </div>
            </ng-container>
        </fx-table>
    </div>
</div>
