<div class="loader" *ngIf="!enableBrandLoader">
    <div class="lds-ring" [ngClass]="{ 'lds-ring--light': color == 'light', 'lds-ring--dark': color == 'dark' }">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>
<div class="brand-loader" *ngIf="enableBrandLoader">
    <img class="brand-loader-image" src="" alt="'logo'" />
</div>
