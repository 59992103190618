import { NgModule } from "@angular/core";
import {
    CalendarModule,
    DashboardModule,
    InsightsModule,
    MessagePluginModule,
    NotificationModule,
    PaymentModule,
    VideoChatModule,
    VaultModule
} from ".";

const plugins = [
    MessagePluginModule,
    VaultModule,
    CalendarModule,
    PaymentModule,
    DashboardModule,
    InsightsModule,
    NotificationModule,
    VideoChatModule
];

@NgModule({
    imports: [...plugins],
    exports: [...plugins]
})
export class SharedPluginsModule {}
