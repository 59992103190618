<div class="calendar-choose-time" *ngIf="!loader.counter">
    <div class="calendar-choose-time-inner">
        <div class="calendar-modal-details" *ngIf="!loader.counter">
            <div class="calendar-modal-content">
                <div class="calendar-modal-content-subhead">
                    <h2>Select an available date and time</h2>
                    <span class="calendar-modal-content-subhead-subtitle" [innerHTML]="timezoneSubtitle"></span>
                </div>
                <fx-calendar *ngIf="slots && minDate"
                    [slots]="slots"
                    [minDate]="minDate"
                    [maxDate]="maxDate"
                    [options]="{ timepicker: { style: 'buttons', inputName: 'time-view-all' } }"
                    (dateTimeSelected)="handleCalendarEvent($event)"
                    (monthChange)="emitMonthSelection.emit($event)"
                >
                </fx-calendar>
                <div *ngIf="calendarError !== ''" class="calendar-modal-error">{{ calendarError }}</div>
            </div>
        </div>
    </div>
</div>
