import { Pipe, PipeTransform } from "@angular/core";
import { IRequestItem } from "@findex/threads";

@Pipe({ name: "getRequestCompletedCount" })
export class GetRequestCompletedCountPipe implements PipeTransform {
    constructor() {}

    transform(requests: IRequestItem[]): number {
        const count = requests.filter((request) => request.response.complete.state);
        return count.length;
    }
}
