<centered-modal-layout
    [loading]="false"
    saveButtonTitle="Save"
    (save)="save()"
    (close)="close()"
    analyticsPrefix="document-rename"
>
    <div modal-header>
        Rename file
    </div>
    <div modal-content>
        <input
            id="name"
            class="fx-form-input"
            type="text"
            aria-label="Document Name"
            [value]="name"
            (change)="name = $event.target.value"
        />
    </div>
</centered-modal-layout>
