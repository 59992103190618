import { Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import {
    ITileModel,
    SelectionTypes
} from "../../../../../../projects/portal-modules/src/lib/findex-ui/components/fx-tile/fx-tile.component";
import { FormControl, FormGroup } from "@angular/forms";

@Component({
    selector: "onboarding-tile-selection",
    templateUrl: "./onboarding-tile-selection.component.html",
    styleUrls: ["./onboarding-tile-selection.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class OnboardingTileSelectionComponent {
    @Input() title: string;
    @Input() subtitle: string;
    @Input() tileModel: ITileModel[];
    @Input() selectionType: SelectionTypes;
    @Input() desktopImage: string;
    @Input() mobileImage: string;
    @Input() fullWidth: boolean;
    form = new FormGroup({
        tileBoxes: new FormControl()
    });
    @Input() analyticsPrefix = "";
    @Output()
    emitTileSelection = new EventEmitter<string>();
    @Output() emitMultiTileSelection = new EventEmitter<string[]>();

    constructor() {
        this.form.valueChanges.subscribe(value => {
            if (this.selectionType === SelectionTypes.Single) {
                this.emitTileSelection.emit(value.tileBoxes);
            } else if (this.selectionType === SelectionTypes.Multi) {
                this.emitMultiTileSelection.emit(value.tileBoxes);
            }
        });
    }
}
