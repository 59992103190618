import { Component, EventEmitter, Inject, Output } from "@angular/core";
import { DateTime } from "luxon";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { ThreadsService } from "projects/portal-modules/src/lib/threads-ui/services/threads.service";
import { IThread, IWorkflow, IWorkflowStep, IWorkflowTimeEstimate } from "@findex/threads";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { Observable } from "rxjs";
import { map, shareReplay, take } from "rxjs/operators";
import { WorkflowService } from "../../services/workflow.service";
import { MAT_LUXON_DATE_FORMATS } from "@angular/material-luxon-adapter";

export type EditThreadDatesData = {
    thread: IThread
}

@Component({
    selector: "edit-thread-dates",
    templateUrl: "./edit-thread-dates.component.html",
    styleUrls: ["./edit-thread-dates.component.scss"],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: "en-AU" },
        { provide: MAT_DATE_FORMATS, useValue: MAT_LUXON_DATE_FORMATS }
    ]
})
export class EditThreadDatesComponent {
    loader = new Loader();
    thread: IThread;

    @Output() dateChange: EventEmitter<MatDatepickerInputEvent<Event>> = new EventEmitter();

    workflow$: Observable<IWorkflow>;
    hasDueDates$: Observable<boolean>;
    dueDates: Record<string, DateTime>;
    startDate: DateTime;

    constructor(
        @Inject(MAT_DIALOG_DATA) data: EditThreadDatesData,
        public dialogRef: MatDialogRef<EditThreadDatesComponent>,
        private threadsService: ThreadsService,
        private workflowService: WorkflowService
    ) {
        this.thread = data.thread;
        this.startDate = DateTime.fromISO(data.thread.createdAt);
        const { id, provider } = this.thread.workflow;
        
        this.workflow$ = this.loader.wrap(this.workflowService.getWorkflow(provider, id)).pipe(
            shareReplay(1)
        );

        this.hasDueDates$ = this.workflow$.pipe(
            map(workflow => workflow?.steps?.some(step => step?.timeEstimate?.duration != null))
        );
    }


    updateDueDates(dates: Record<string, DateTime>|void) {
        if (dates) {
            this.dueDates = dates;
        }
    }

    close() {
        this.dialogRef.close();
    }

    async updateThread() {
        this.loader.show();

        try {
            const workflow = await this.workflow$.pipe(take(1)).toPromise();
            const timeEstimates = this.getTimeEstimates(workflow.steps, this.dueDates, this.startDate);

            await this.threadsService.updateTimeEstimates(this.thread.id, timeEstimates).toPromise();

            this.dialogRef.close(true);
        } catch (error) {
            this.dialogRef.close(false);
            throw error;
        } finally {
            this.loader.hide();
        }
    }

    private getTimeEstimates(workflowSteps: IWorkflowStep[], dates: Record<string, DateTime>, startDate: DateTime): Record<string, IWorkflowTimeEstimate> {
        return workflowSteps.reduce((estimates, step, i) => {
            if (!step.timeEstimate) return estimates;

            const dueDate = dates[step.clientFacingId];
            const previousDueDate = i > 0 ? dates[workflowSteps[i - 1].clientFacingId] : startDate;
            const timeEstimate = this.calculateTimeEstimate(previousDueDate, dueDate);

            return {
                ...estimates,
                [step.clientFacingId]: timeEstimate
            }
        }, {});
    }

    private calculateTimeEstimate(previousDueDate: DateTime | void, dueDate: DateTime | void): IWorkflowTimeEstimate | void {
        if (!previousDueDate || !dueDate) return;

        return {
            duration: dueDate.toMillis() - previousDueDate.toMillis()
        };
    }
}
