import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { AuthService } from "./auth.service";
import { AppUser } from "../model/AppUser";

@Injectable({ providedIn: "root" })
export class NoAuthGuard implements CanActivate {
    private readonly redirectUrls = ["/register/signup", "/register/email-success"];

    constructor(private authService: AuthService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.authService.getUserWithoutRole().pipe(
            switchMap((user: AppUser) => {
                if (!user) {
                    return of(true);
                }

                const redirectUrl = this.getRedirectUrl(user, route, state);
                this.router.navigateByUrl(redirectUrl);

                return of(false);
            })
        );
    }

    private getRedirectUrl(user: AppUser, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): string {
        if (this.redirectUrls.includes(state.url) && !user.mobileNumberVerified) {
            return "/register/verify-mobile";
        }

        if (route.queryParams && route.queryParams.redirect) {
            return route.queryParams.redirect;
        }

        // redirect to dashboard for all logged in users
        return "/dashboard";
    }
}
