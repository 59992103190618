import {
    Component,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges,
    ViewChild,
    ViewContainerRef
} from "@angular/core";
import { IThread, IThreadWorkflowState, Role, WorkflowStepType } from "@findex/threads";
import { IProviderWorkflow, WorkflowService } from "../../services/workflow.service";
import { GA_EVENTS } from "../../../analytics";
import { WorkflowStepsService } from "../../services/workflow-steps.service";
import { Overlay } from "@angular/cdk/overlay";
import { TemplatePortal } from "@angular/cdk/portal";
import { Subscription } from "rxjs";
import { WindowListenersService } from "../../../shared/services/window-listeners.service";
import { ENVIRONMENT } from "src/app/injection-token";
import { EnvironmentSpecificConfig } from "../../../environment/environment.common";

interface IActiveStep {
    index: number;
    title: string;
    isOverdue: boolean;
}

@Component({
    selector: "thread-workflow-progress-indicator",
    templateUrl: "./thread-workflow-progress-indicator.component.html",
    styleUrls: ["./thread-workflow-progress-indicator.component.scss"]
})
export class ThreadWorkflowProgressIndicatorComponent implements OnChanges, OnDestroy {
    @ViewChild("workflowPopUp") workflowPopUpTmplRef;

    readonly stepTypes = WorkflowStepType;
    readonly gaEvents = GA_EVENTS;

    @Input() workflowState: IThreadWorkflowState;
    @Input() small: boolean;
    @Input() enablePopUp: boolean;
    @Input() role: Role;
    @Input() thread: IThread;
    @Input() shouldShowTitle: boolean;
    @Input() fullWidth: boolean;

    roles = Role;

    workflow: IProviderWorkflow;
    currentStep: IActiveStep;
    showPopUp: boolean;
    workflowIsActive: boolean;
    activeWorkflowSteps: IProviderWorkflow;
    overlayRef: any;
    outsidePointerEventsSubscription: Subscription;

    constructor(
        private workflowService: WorkflowService,
        private workflowStepsService: WorkflowStepsService,
        private overlay: Overlay,
        private vcr: ViewContainerRef,
        private windowListenersService: WindowListenersService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig
    ) {}

    async ngOnChanges(changes: SimpleChanges) {
        const { workflowState, thread } = changes;

        if (workflowState?.currentValue || thread?.currentValue) {
            this.workflow = await this.getWorkflow(this.workflowState);
            this.activeWorkflowSteps = this.filterActiveSteps(this.workflow);
            this.currentStep = this.findCurrentStep(workflowState?.currentValue);
            this.workflowIsActive = this.checkWorkFlowIsActive(this.currentStep?.index);
        } else {
            this.workflow = null;
            this.workflowIsActive = false;
        }
    }

    close() {
        if (this.overlayRef) {
            this.overlayRef.dispose();
        }
        if (this.outsidePointerEventsSubscription) {
            this.outsidePointerEventsSubscription.unsubscribe();
        }
    }

    togglePopUp(trigger: HTMLDivElement) {
        if (!this.enablePopUp || !this.workflowIsActive) {
            return;
        }
        this.showPopUp = !this.showPopUp;

        const mobileBreakpoint = this.windowListenersService.isWindowSmaller(
            this.environment.featureFlags.windowWidthTabletBreakpoint
        );

        if (mobileBreakpoint) {
            this.overlayRef = this.overlay.create({
                hasBackdrop: true,
                backdropClass: "transparent-overlay-background",
                scrollStrategy: this.overlay.scrollStrategies.reposition(),
                positionStrategy: this.overlay
                    .position()
                    .global()
                    .centerVertically()
                    .centerHorizontally(),
                height: 400,
                panelClass: "mobile-overlay"
            });
        } else {
            this.overlayRef = this.overlay.create({
                hasBackdrop: true,
                backdropClass: "transparent-overlay-background",
                scrollStrategy: this.overlay.scrollStrategies.reposition(),
                positionStrategy: this.overlay
                    .position()
                    .flexibleConnectedTo(trigger)
                    .withViewportMargin(20)
                    .withPositions([
                        {
                            originX: "start",
                            originY: "top",
                            overlayX: "start",
                            overlayY: "top",
                            offsetY: 20
                        }
                    ])
            });
        }

        const popupPortal = new TemplatePortal(this.workflowPopUpTmplRef, this.vcr);
        this.overlayRef.attach(popupPortal);

        this.outsidePointerEventsSubscription = this.overlayRef.outsidePointerEvents().subscribe(() => {
            this.close();
        });
    }

    async getWorkflow(workflowState: IThreadWorkflowState): Promise<IProviderWorkflow> {
        const workflow = await this.workflowService.getProvidedWorkflow(workflowState);
        return workflow;
    }

    private checkWorkFlowIsActive(currentIndex: number): boolean {
        if (currentIndex == null) {
            return false;
        }

        return currentIndex !== -1;
    }

    private findCurrentStep(workflowState: IThreadWorkflowState): IActiveStep {
        if (!workflowState?.steps) {
            return null;
        }

        const currentStep = workflowState.steps.find(step => step.isCurrentStep);
        const currentIndex = workflowState.steps.indexOf(currentStep);
        const index = currentIndex + 1;
        const title = currentStep.clientFacingName;

        const entries = this.workflowStepsService.getUpdatedStepEntries(workflowState.steps);
        const isOverdue = entries[currentIndex]?.isOverdue;

        return { index, title, isOverdue };
    }

    private filterActiveSteps(threadWorkflow: IProviderWorkflow): IProviderWorkflow {
        if (!threadWorkflow?.workflow) {
            return null;
        }

        const { provider, workflow } = threadWorkflow;
        return {
            provider,
            workflow: {
                id: workflow.id,
                name: workflow.name,
                steps: threadWorkflow.workflow.steps.filter(step => step.type === this.stepTypes.OPEN)
            }
        };
    }

    ngOnDestroy(): void {
        if (this.outsidePointerEventsSubscription) {
            this.outsidePointerEventsSubscription.unsubscribe();
        }
        if (this.overlayRef) {
            this.overlayRef.dispose();
        }
    }
}
