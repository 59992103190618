import { Inject, Pipe, PipeTransform } from "@angular/core";
import { ENVIRONMENT } from "src/app/injection-token";
import { EnvironmentSpecificConfig } from "../../environment/environment.common";

@Pipe({ name: "isSystemName" })
export class IsSystemNamePipe implements PipeTransform {
    systemNames: string[] = ["system"];
    constructor(@Inject(ENVIRONMENT) environment: EnvironmentSpecificConfig) {
        this.systemNames.push(environment.appName.toLowerCase());
    }
    transform(item: string): any {
        return typeof item === "string" && this.systemNames.includes(item.toLowerCase());
    }
}
