import { Component, Inject } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { customValidators } from "../../../shared/validators";
import { EnvironmentSpecificConfig } from "../../../environment/environment.common";
import { ThreadsService } from "../../services/threads.service";
import {
    IInviteUsersResponse,
    IUserSetupRequest,
    IUserSetupRequestAdditionalInfo,
    IUserSetupRequestClientDetails
} from "@findex/threads";
import { Loader } from "../../../shared/services/loader";
import { ENVIRONMENT } from "src/app/injection-token";
import { HandledError } from "../../../shared/services/threads-error-handler";

export interface IInviteClientModalData {
    threadId: string;
    additionalInformation: IUserSetupRequestAdditionalInfo;
}

@Component({
    selector: "invite-client-modal",
    templateUrl: "./invite-client-modal.component.html",
    styleUrls: ["./invite-client-modal.component.scss"]
})
export class InviteClientModalComponent {
    threadId: string;
    additionalInformation: IUserSetupRequestAdditionalInfo;
    formArray = new FormArray([]);
    showSuccessModal = false;
    internationalPhoneNo: string;
    internationalPhoneNoValid: boolean;
    loader = new Loader();
    errorGlobalMessage = "";
    errorMessages = [];
    readonly signupCountries = this.environment.featureFlags.signupCountries;

    constructor(
        @Inject(MAT_DIALOG_DATA) data: IInviteClientModalData,
        private dialog: MatDialogRef<InviteClientModalComponent, IUserSetupRequest>,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private threadsService: ThreadsService
    ) {
        this.additionalInformation = data.additionalInformation;
        this.threadId = data.threadId;
        this.addAnotherInvitee();
    }

    addAnotherInvitee() {
        const formGroup = new FormGroup({
            firstName: new FormControl("", [Validators.required]),
            lastName: new FormControl("", [Validators.required]),
            emailAddress: new FormControl("", [Validators.required, Validators.email]),
            mobile: new FormControl("", [Validators.required, customValidators.mobileValidator(this.signupCountries)])
        });
        this.formArray.push(formGroup);
    }

    addMorePeople() {
        this.showSuccessModal = false;
        this.addAnotherInvitee();
    }

    buildClientRequest(): IUserSetupRequest {
        const clients: IUserSetupRequestClientDetails[] = this.formArray.controls.map(
            (formGroup: FormGroup): IUserSetupRequestClientDetails => ({
                firstName: formGroup.controls.firstName.value,
                lastName: formGroup.controls.lastName.value,
                emailAddress: formGroup.controls.emailAddress.value,
                mobileNumber: `${this.internationalPhoneNo}`
            })
        );
        return {
            clients,
            threadId: this.threadId,
            additionalInformation: this.additionalInformation
        };
    }

    handleInviteUsersResponse(inviteUsersResponse: IInviteUsersResponse[]): void {
        this.formArray.controls = this.formArray.controls.filter((formGroup: FormGroup) => {
            const userSuccessfullyCreated = inviteUsersResponse.find(
                userResponse =>
                    userResponse.success &&
                    userResponse.success.createdUserEmail === formGroup.controls.emailAddress.value &&
                    userResponse.success.createdUserPhone === this.internationalPhoneNo
            );
            return !userSuccessfullyCreated;
        });

        if (this.formArray.controls.length !== 0) {
            this.errorMessages = [];
            this.errorGlobalMessage = "The remaining invites failed to send. Please contact support.";
            inviteUsersResponse
                .filter(userResponse => userResponse?.error)
                .forEach(userResponse => this.errorMessages.push(userResponse?.error?.message));
        } else {
            this.showSuccessModal = true;
        }
    }

    async sendInvite() {
        try {
            this.loader.show();
            const inviteClientRequest = this.buildClientRequest();
            const response = await this.threadsService.sendClientInvites(inviteClientRequest).toPromise();
            this.handleInviteUsersResponse(response);
        } catch (error) {
            this.errorGlobalMessage = "Sorry, something went wrong. Please try again.";
            throw new HandledError(error);
        } finally {
            this.loader.hide();
        }
    }

    removeInvitee(index: number) {
        this.formArray.removeAt(index);
        this.errorMessages.splice(index, 1);
    }

    exitModal() {
        this.dialog.close(this.buildClientRequest());
    }
}
