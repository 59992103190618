import { Injectable } from "@angular/core";
import { ICalendarParticipant, Role } from "@findex/threads";
import { ThreadCardService } from "projects/portal-modules/src/lib/threads-ui/services/thread-card.service";
import { environmentCommon } from "../../../portal-modules/src/lib/environment/environment.common";
import { IThreadCard } from "@findex/threads/dist/interfaces/IThreadCard";
import { CalendarState } from "../calendar-state.type";
import { IInvitee, IStaff } from "@findex/calendar-types";

@Injectable()
export class CalendarCardService {
    constructor(private threadCardService: ThreadCardService) {}

    createRequest(
        threadId: string,
        meetingName: string,
        duration: number,
        attendees: ICalendarParticipant[],
        recurrenceType: string,
        numberOfOccurrences: number,
        description?: string
    ) {
        const createCardRequest = this.buildCalendarCardRequestDetails(
            meetingName,
            duration,
            attendees,
            recurrenceType,
            numberOfOccurrences,
            description
        );

        const { calendar } = environmentCommon.cardsEndpoints;

        return this.threadCardService.createCard<any, IThreadCard>(threadId, calendar, createCardRequest);
    }

    createBooked(
        start: string,
        end: string,
        threadId: string,
        meetingName: string,
        duration: number,
        attendees: ICalendarParticipant[],
        recurrenceType: string,
        numberOfOccurrences: number,
        description?: string
    ) {
        const targetTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const createCardRequestDetails = this.buildCalendarCardRequestDetails(
            meetingName,
            duration,
            attendees,
            recurrenceType,
            numberOfOccurrences,
            description
        );
        const { bookedCalendar } = environmentCommon.cardsEndpoints;

        const request = {
            ...createCardRequestDetails,
            targetTimezone,
            timeSlot: {
                start,
                end
            }
        };

        return this.threadCardService.createCard<any, IThreadCard>(threadId, bookedCalendar, request);
    }

    static getAllAttendees(state: CalendarState): (IStaff|IInvitee)[] {
        if (!state?.attendees) return [];

        return [
            ...state.attendees.staff || [],
            ...state.attendees.invitees || []
        ];
    }

    private buildCalendarCardRequestDetails(
        meetingName: string,
        duration: number,
        attendees: ICalendarParticipant[],
        recurrenceType: string,
        numberOfOccurrences: number,
        description?: string
    ) {
        const staff = attendees.filter(({ role }) => role === Role.Staff);
        const client = attendees.filter(({ role }) => role === Role.Client);

        const getParticipantDetails = (participants: ICalendarParticipant[]) =>
            participants.map(participant => ({ id: participant.id, required: participant.required }));

        const [staffIds, clientIds] = [staff, client].map(getParticipantDetails);
        const recurrence =
            recurrenceType && recurrenceType !== "never" ? { type: recurrenceType, numberOfOccurrences } : undefined;

        return {
            meetingName,
            duration,
            eventTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone || "Australia/Melbourne",
            staffIds,
            clientIds,
            description,
            ...(recurrence && { recurrence })
        };
    }
}
