import { Component, Inject } from "@angular/core";
import { AdminService } from "projects/portal-modules/src/lib/admin/services/admin.service";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { AppUser } from "projects/portal-modules/src/lib/findex-auth";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Role, IThread, IParticipant, ParticipantType, IUserSetupRequest } from "@findex/threads";
import { map, debounceTime, distinctUntilChanged, switchMap, shareReplay, tap } from "rxjs/operators";
import { ThreadsService } from "../../../services/threads.service";
import { FormControl } from "@angular/forms";
import { IInviteClientModalData, InviteClientModalComponent } from "../../invite-client/invite-client-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { ENVIRONMENT } from "src/app/injection-token";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { AccountsService } from "../../../services/accounts.service";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { ParticipantCache } from "../../../services/participant-cache.service";

@Component({
    selector: "thread-add-participant",
    templateUrl: "thread-add-participant.component.html",
    styleUrls: ["thread-add-participant.component.scss"]
})
export class ThreadAddParticipantComponent {
    readonly gaEvents = GA_EVENTS;

    loader = new Loader();
    searchLoader = new Loader();
    roles = Role;
    role: Role;
    searchResults: IParticipant[];
    selectedParticipants: IParticipant[] = [];
    searchTerm = new FormControl();

    inviteClientsEnabled = this.environment.featureFlags.inviteClients;
    private accountParticipants$: Observable<IParticipant[]>;
    private thread: IThread;
    private currentUserId: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) data: any,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private adminService: AdminService,
        private dialogRef: MatDialogRef<ThreadAddParticipantComponent>,
        private threadsService: ThreadsService,
        private participantsCache: ParticipantCache,
        private accountsService: AccountsService,
        private dialog: MatDialog
    ) {
        this.thread = data.thread;
        this.currentUserId = data.currentUserId;
        this.role = data.role;

        if (this.thread && this.thread.accountId) {
            this.accountParticipants$ = this.accountsService.getAccount(this.thread.accountId).pipe(
                map(account => account.contacts.map(contact => contact.id)),
                switchMap(participantIds => this.participantsCache.getParticipants(participantIds)),
                shareReplay()
            );
        }

        this.searchTerm.valueChanges
            .pipe(
                debounceTime(500),
                distinctUntilChanged(),
                tap(() => (this.searchResults = null)),
                switchMap(searchTerm => this.searchLoader.wrap(this.search(searchTerm)))
            )
            .subscribe(value => (this.searchResults = value));

        this.searchTerm.setValue("");
    }

    launchInviteClientModal() {
        const dialogRef = this.dialog.open<InviteClientModalComponent, IInviteClientModalData, IUserSetupRequest>(
            InviteClientModalComponent,
            {
                backdropClass: "modal-backdrop",
                panelClass: ["modal-container", "brand-font-color"],
                maxWidth: "800px",
                height: "auto",
                autoFocus: true,
                data: {
                    threadId: this.thread.id,
                    additionalInformation: {
                        createdTime: new Date(Date.now()).toISOString(),
                        createdBy: this.currentUserId
                    }
                }
            }
        );
        dialogRef.afterClosed().subscribe(data => {
            this.dialogRef.close(data);
        });
    }

    async addParticipants() {
        this.loader.show();

        await Promise.all(
            this.selectedParticipants.map(user => {
                const roleToAdd = user.id.includes("cognito") ? Role.Client : Role.Staff;

                return this.threadsService
                    .addParticipant(this.thread.id, user.id, roleToAdd, ParticipantType.User)
                    .toPromise();
            })
        );

        this.loader.hide();
        this.dialogRef.close(true);
    }

    close() {
        this.dialogRef.close();
    }

    selectUser(searchResult: IParticipant) {
        this.selectedParticipants.push(searchResult);
        this.searchTerm.setValue("");
        this.searchResults = null;
    }

    unselectUser(index: number) {
        this.selectedParticipants.splice(index, 1);
    }

    private mapToParticipant(user: AppUser): IParticipant {
        return {
            id: user.id,
            type: ParticipantType.User,
            profile: {
                name: user.name,
                emailAddress: user.details.emailAddress
            }
        };
    }

    private async search(term: string): Promise<IParticipant[]> {
        const results = await (this.role === Role.Client ? this.searchAccount(term) : this.searchAllUsers(term));
        if (!results) return results;

        return results.filter(user => {
            const isAlreadyParticipant = this.thread.participants.some(participant => participant.id === user.id);
            const isAlreadySelected = this.selectedParticipants.some(selected => selected.id === user.id);
            return !isAlreadyParticipant && !isAlreadySelected;
        });
    }

    private async searchAccount(searchTerm: string): Promise<IParticipant[]> {
        if (!this.accountParticipants$) return null;
        const participants = await this.accountParticipants$.toPromise();
        const lowerTerm = searchTerm.toLowerCase();

        return participants.filter(participant => {
            if (!participant.profile) return true;
            return participant.profile.name.toLowerCase().includes(lowerTerm);
        });
    }

    private async searchAllUsers(searchTerm: string): Promise<IParticipant[]> {
        if (!searchTerm || searchTerm.length <= 2) {
            return null;
        }

        const clients = await this.adminService.searchClients(searchTerm).toPromise();
        return clients.map(user => this.mapToParticipant(user));
    }
}
