import { Component } from '@angular/core';

@Component({
  selector: 'login-footer',
  templateUrl: './login-footer.component.html',
  styleUrls: ['./login-footer.component.scss']
})
export class LoginFooterComponent {
  readonly companyName = 'Findex Group Limited 2024. All rights reserved.';
  readonly disclaimer = `Some of the Findex personnel involved in preparing this webpage may be members of a professional scheme approved
    under Professional Standards Legislation such that their occupational liability is limited under that Legislation.
    To the extent that applies, the following disclaimer applies to them. If you have any questions about the
    applicability of Professional Standards Legislation to Findex's personnel involved in preparing this webpage,
    please speak to your Findex adviser.`;
  readonly liability = 'Liability limited by a scheme approved under Professional Standards Legislation.';
}


