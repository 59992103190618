import { Component, Input, EventEmitter, Output } from "@angular/core";
import { AppUser } from "../../../findex-auth";
import { MenuService, MenuType } from "../../../shared/services/menu.service";
import { environment } from "../../../../../../../src/environments/environment";

@Component({
    selector: "user-profile-dropdown",
    templateUrl: "user-profile-dropdown.component.html",
    styleUrls: ["user-profile-dropdown.component.scss"]
})
export class UserProfileDropdownComponent {
    @Input() user: AppUser;
    @Input() showSwitchAccount = false;
    @Input() supportEmail: string;
    @Output() switchAccount = new EventEmitter();
    @Output() logout = new EventEmitter();
    readonly termsOfUseUrl = environment.termsOfUseUrl;

    constructor(private slideMenuService: MenuService) {}

    hideProfileMenu() {
        this.slideMenuService.hide(MenuType.Profile);
    }
}
