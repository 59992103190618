import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Account } from "@findex/threads";
import { Observable } from "rxjs";
import { distinctUntilChanged, filter, map, shareReplay, switchMap } from "rxjs/operators";
import { Loader } from "../../shared/services/loader";
import { AccountsService } from "../../threads-ui/services/accounts.service";

@Injectable()
export class AccountRouteService {
    private account$: Observable<Account>;

    constructor(route: ActivatedRoute, accountsService: AccountsService, accountLoader: Loader) {
        this.account$ = route.paramMap.pipe(
            map(params => params.get("accountId")),
            filter(accountId => !!accountId),
            distinctUntilChanged(),
            switchMap(accountId => accountLoader.wrap(accountsService.getAccount(accountId))),
            shareReplay()
        );
    }

    getAccount(): Observable<Account> {
        return this.account$;
    }
}
