import { Component, HostListener, Inject, OnDestroy, OnInit } from "@angular/core";
import { NavigationEnd, Route, Router, RouterEvent } from "@angular/router";
import { EVENT_REPLY_TYPE, Role } from "@findex/threads";
import { combineLatest, EMPTY, merge, Observable, of, Subject, Subscription, zip } from "rxjs";
import { debounceTime, distinctUntilChanged, filter, map, switchMap, take, takeUntil, tap } from "rxjs/operators";
import { ThemeService } from "projects/portal-modules/src/lib/shared/services/theme.service";
import { AppUser } from "projects/portal-modules/src/lib/findex-auth/model/AppUser";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth/services/auth.service";
import { OnboardingService } from "projects/portal-modules/src/lib/onboarding/services/onboarding.service";
import { UserProfileService } from "projects/portal-modules/src/lib/user-profile/services/user-profile.service";
import { AnalyticsService, GA_EVENTS, HOT_JAR_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { Loader } from "../../projects/portal-modules/src/lib/shared/services/loader";
import { MenuService, MenuType } from "../../projects/portal-modules/src/lib/shared/services/menu.service";
import { ThreadsService } from "../../projects/portal-modules/src/lib/threads-ui/services/threads.service";
import {
    ActivityNotificationsService,
    BannerNotificationsService
} from "../../projects/portal-modules/src/lib/notifications";
import { Tour, TourService } from "../../projects/portal-modules/src/lib/shared/services/tour.service";
import {
    CloseAction,
    environmentCommon,
    EnvironmentSpecificConfig
} from "projects/portal-modules/src/lib/environment/environment.common";
import { APP_ROUTE_LIBRARY, ENVIRONMENT } from "./injection-token";
import { Notification, NotificationsService, PossibleDeliveryData, WebsocketData } from "@findex/notifications-angular";
import { WorkflowService } from "../../projects/portal-modules/src/lib/threads-ui/services/workflow.service";
import { NewThreadModalComponent } from "../../projects/portal-modules/src/lib/threads-ui/components/new-thread-modal/new-thread-modal.component";
import { SyncFilesModalComponent } from "../solutions/samedaytax";
import { PromptUpdateService } from "../../projects/portal-modules/src/lib/shared/services/prompt-app-update.service";
import { CloseThreadPromptComponent } from "projects/portal-modules/src/lib/threads-ui/components/close-thread-prompt/close-thread-prompt.component";
import { UiCardService } from "projects/portal-modules/src/lib/threads-ui/services/ui-card.service";
import { ILibrary } from "projects/portal-modules/src/lib/plugins";
import { RouteExtension } from "projects/portal-modules/src/lib/plugins/services/Libraries";
import { routes, securedRoutes } from "./app-routing.module";
import { WindowListenersService } from "projects/portal-modules/src/lib/shared/services/window-listeners.service";
import { AuthorizationLevel } from "projects/portal-modules/src/lib/findex-auth/model/AuthorizationLevel";
import { Title } from "@angular/platform-browser";

export interface INotification extends Notification {
    avatarImage?: string;
}

type VisibleExtension = { id: string; label: string; icon: string; path: string };

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit, OnDestroy {
    readonly showPaymentsSubscriptions = this.environment.featureFlags.showPaymentsSubscriptions;
    readonly showPaymentsBilling = this.environment.featureFlags.showPaymentsBilling;
    readonly subscriptionEnabled = this.environment.featureFlags.subscriptionEnabled;
    readonly supportEmail = this.environment.featureFlags.supportEmail;
    readonly showAccounts = this.environment.featureFlags.accountView;
    readonly showInsights = this.environment?.featureFlags?.insightsConfiguration?.showInsights;
    readonly focusWizardPath = environmentCommon.focusWizard.path;
    readonly roles = Role;
    readonly gaEvents = GA_EVENTS;
    readonly hotJarEvents = HOT_JAR_EVENTS;

    role: Role;
    user$: Observable<AppUser>;
    hideLayout: boolean;
    settingsNavigationActive: boolean;

    notifications$: Observable<INotification[]>;
    unreadNotificationCount = 0;

    showNavigation$: Observable<boolean>;
    showNotificationMenu$: Observable<boolean>;
    showNotificationMobileMenu$: Observable<boolean>;
    showProfileMenu$: Observable<boolean>;
    showMobileCollapsedIcon$: Observable<boolean>;

    isCollapsedView = false;
    extensions$: Observable<VisibleExtension[]>;
    isIframe = false;

    private roleSub: Subscription;
    private tourSubscription: Subscription;
    private unreadCountSub: Subscription;
    private unreadCountWsUpdateSub: Subscription;
    private readonly unsubscribe = new Subject()

    bannerUpdates$: Observable<Notification<PossibleDeliveryData>[]>;
    isLaptopViewWidth: boolean;

    @HostListener("window:resize")
    windowResize() {
        this.isCollapsedView = this.windowListenersService.isWindowSmaller(
            this.environment.featureFlags.windowWidthMenuBreakpoint
        );

        if (this.isCollapsedView) {
            this.menuService.hide(MenuType.Navigation);
        } else {
            this.menuService.show(MenuType.Navigation);
        }

        this.menuService.hide(MenuType.NotificationsMobile);
        this.menuService.hide(MenuType.Profile);
        this.menuService.show(MenuType.CollapsedMenuIcon);

        // would prefer not to do it this way but you can't use HostListener in a service and wanting to avoid addEventListener
        this.windowListenersService.windowResized();
    }

    constructor(
        public loader: Loader,
        private authService: AuthService,
        private threadsService: ThreadsService,
        private analyticsService: AnalyticsService,
        private onboardingService: OnboardingService,
        private router: Router,
        private menuService: MenuService,
        public themer: ThemeService,
        private tourService: TourService,
        private userProfileService: UserProfileService,
        workflowService: WorkflowService,
        private promptAppUpdateService: PromptUpdateService,
        private uiCardService: UiCardService,
        private windowListenersService: WindowListenersService,
        private activityNotifications: ActivityNotificationsService,
        private bannerNotifications: BannerNotificationsService,
        private titleService: Title,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        @Inject(APP_ROUTE_LIBRARY) library: ILibrary<RouteExtension>,
        private notificationService: NotificationsService
    ) {
        this.showNavigation$ = menuService.showNavigation$;
        this.showNotificationMobileMenu$ = menuService.showNotificationMenuMobile$;
        this.showProfileMenu$ = menuService.showProfileMenu$;
        this.showMobileCollapsedIcon$ = menuService.showMobileCollapsedIcon$;

        const loadedExtensions = library
            .listAll()
            .map(({ id, extension }) => this.loadExtension(securedRoutes, id, extension));
        this.router.resetConfig(routes);

        this.extensions$ = combineLatest(loadedExtensions).pipe(
            map(extensions => extensions.filter(extension => extension != null))
        );

        workflowService.registerAction(CloseAction.SameDayFileSync, SyncFilesModalComponent, {
            position: { top: "0px" },
            height: "100vh",
            maxWidth: "100vw",
            panelClass: ["mat-dialog-no-styling", "threads-sidebar"],
            disableClose: true
        });

        workflowService.registerAction(CloseAction.CancelMeetings, CloseThreadPromptComponent, {
            panelClass: ["mat-dialog-overflow", "mat-dialog-no-container"],
            maxWidth: "420px",
            disableClose: true
        });
        workflowService.registerAction(CloseAction.CreateThread, NewThreadModalComponent, {
            disableClose: true
        });

        this.themer.apply();
        this.promptAppUpdateService.checkForUpdate();
        this.setupWindowFocusListeners();
    }

    private loadExtension(
        siblingRoutes: Route[],
        id: string,
        extension: RouteExtension
    ): Observable<VisibleExtension | null> {
        const { label, icon, route, showIcon } = extension;

        const visibleRoute = {
            id,
            label,
            path: route.path,
            icon
        };

        siblingRoutes.unshift(route);

        return showIcon().pipe(map(visible => (visible ? visibleRoute : null)));
    }

    private setupWindowFocusListeners() {
        window.addEventListener(
            "focus",
            () => {
                this.analyticsService.recordEvent("window-attention", "focus");
            },
            false
        );
        window.addEventListener(
            "blur",
            () => {
                this.analyticsService.recordEvent("window-attention", "blur");
            },
            false
        );
    }

    ngOnInit() {
        this.isIframe = window !== window.parent && !window.opener;
        this.loader.show();
        setTimeout(() => this.loader.hide());

        this.user$ = this.authService.getUser().pipe(
            takeUntil(this.unsubscribe),
            tap(user => {
                if (user) {
                    this.initAnalytics(user);
                }
            })
        );

        const role$ = this.user$.pipe(
            takeUntil(this.unsubscribe),
            filter(user => !!user),
            map(user => user.globalRole)
        );

        this.user$.pipe(
            takeUntil(this.unsubscribe),
            filter(user => !!user)).subscribe(user => {
            this.onboardingService.checkStaffRole(user);
        });

        this.roleSub = role$
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(role => (this.role = role));

        this.router.events.subscribe((event: RouterEvent) => {
            if (event instanceof NavigationEnd) {
                this.toggleLayout(event);
            }
        });

        const { tours } = this.environment.featureFlags;

        this.tourSubscription = this.router.events
            .pipe(
                takeUntil(this.unsubscribe),
                filter(event => event instanceof NavigationEnd),
                map((event: NavigationEnd) => this.urlMatchesTour(tours, event.url))
            )
            .subscribe(showTour => showTour && this.manageTour(tours));

        this.authService
            .onLoginSuccess()
            .pipe(
                takeUntil(this.unsubscribe),
                filter(user => !!user),
                switchMap((user: AppUser) => {
                    if (user.authorizationLevel !== AuthorizationLevel.VERIFIED) return EMPTY;
                    const updateLastLogin$ = this.userProfileService.updateUserLastLogin(user.id);
                    const updateUserTimeZone$ = this.threadsService.updateUserTimeZone(user.id);
                    return zip(updateLastLogin$, updateUserTimeZone$);
                })
            )
            .subscribe(() => {});

        this.notifications$ = this.user$.pipe(
            takeUntil(this.unsubscribe),
            switchMap(user => {
                if (!user) return of([]);
                return this.activityNotifications.getActivity();
            })
        );

        const unreadCount$ = this.user$.pipe(
            takeUntil(this.unsubscribe),
            filter(user => !!user),
            switchMap(() => this.notificationService.getUnreadCount("activity"))
        );

        const unreadUpdates$ = this.user$.pipe(
            takeUntil(this.unsubscribe),
            filter(user => !!user),
            switchMap(() => this.notificationService.subscribeToChannel("activity")),
            debounceTime(1500),
            switchMap(() => this.notificationService.getUnreadCount("activity"))
        );

        this.unreadCountSub = merge(unreadCount$, unreadUpdates$)
            .pipe(distinctUntilChanged())
            .subscribe(count => {
                this.unreadNotificationCount = count;
                this.updatePageTitle(count);
            });

        this.bannerUpdates$ = this.user$.pipe(
            takeUntil(this.unsubscribe),
            switchMap(user => {
                if (!user) return of([]);
                return this.bannerNotifications.getBanners();
            })
        );
    }

    async getNextActivityPage() {
        await this.activityNotifications.getNextPage();
    }

    private updatePageTitle(unreadCount: number) {
        const newTitle = this.getPageTitle(unreadCount);
        this.titleService.setTitle(newTitle);
    }

    async markAllAsUnread() {
        await this.activityNotifications.markAllAsRead();
        this.recordEvent("markAllRead");
    }

    openNotification(notification: Notification<WebsocketData>) {
        const { threadId, cardId, route } = notification.deliveryData.metadata;
        const { topic } = notification;

        if (threadId && cardId && topic === EVENT_REPLY_TYPE) {
            this.router.navigate(["/timelines", threadId, "cards", cardId], { queryParams: { cardType: topic } });
        } else if (threadId && cardId && typeof cardId === "string") {
            this.router.navigate(["/timelines", threadId, "cards", cardId]);
            this.uiCardService.scrollToCard(cardId);
        } else if (threadId) {
            this.router.navigate(["/timelines", threadId]);
        } else if (route && typeof route === "string") {
            this.router.navigateByUrl(route);
        } else {
            console.warn("Notification has no routing information in metadata");
        }

        this.menuService.hide(MenuType.NotificationsMobile);
    }

    hideProfileMenu() {
        this.menuService.hide(MenuType.Profile);
    }

    toggleUserProfileDropdown() {
        this.menuService.hide(MenuType.NotificationsMobile);
        this.menuService.toggle(MenuType.Profile);
    }

    toggleRecentNotifications() {
        this.menuService.hide(MenuType.Profile);
        this.menuService.toggle(MenuType.NotificationsMobile);
    }

    ngOnDestroy() {
        this.unsubscribe.next()
        this.unsubscribe.complete()
        if (this.roleSub) {
            this.roleSub.unsubscribe();
        }
        if (this.unreadCountSub) {
            this.unreadCountSub.unsubscribe();
        }
        if (this.unreadCountWsUpdateSub) {
            this.unreadCountWsUpdateSub.unsubscribe();
        }
        if (this.tourSubscription) {
            this.tourSubscription.unsubscribe();
        }
    }

    async logout() {
        this.loader.show();

        try {
            this.analyticsService.recordEvent("logout", "clicked");
            await this.authService.logout().toPromise();
            this.router.navigateByUrl("/login");
        } finally {
            this.loader.hide();
        }
    }

    switchUser() {
        this.authService.switchUser();
    }

    menuAction(navigationOpen: boolean) {
        if (navigationOpen) {
            this.menuService.show(MenuType.Navigation);
        } else {
            this.menuService.hide(MenuType.Navigation);
        }

        this.menuService.hide(MenuType.NotificationsMobile);
    }

    mobileMenuAction(navigationOpen: boolean) {
        if (this.isCollapsedView) {
            if (navigationOpen) {
                this.menuAction(true);
            } else {
                this.menuAction(false);
            }
            this.analyticsService.recordEvent("side-menu", "toggle");
        } else {
            this.menuAction(true);
        }
    }

    private getPageTitle(notificationCount: number): string {
        if (!notificationCount) {
            return this.environment.appName;
        }
        return `(${notificationCount}) ${this.environment.appName}`;
    }

    private urlMatchesTour(tours: Tour[], url: string): boolean {
        if (!tours) return false;
        return tours.some(tour => url.includes(tour.tourRoute));
    }

    private toggleLayout(event: NavigationEnd) {
        this.isCollapsedView = this.windowListenersService.isWindowSmaller(
            this.environment.featureFlags.windowWidthMenuBreakpoint
        );

        if (!event && !event.urlAfterRedirects) {
            return;
        }

        const { urlAfterRedirects } = event;

        // TODO: ED-2364 refactor this - let pages register whether they want to hide layout and menu or not
        this.hideLayout =
            (!this.environment.featureFlags.inlineOnboarding && urlAfterRedirects.includes("/register")) ||
            urlAfterRedirects.includes("/email-success") ||
            urlAfterRedirects.includes("/mobile-success") ||
            urlAfterRedirects.includes("/error") ||
            urlAfterRedirects.includes("/login") ||
            urlAfterRedirects.includes("/select-staff") ||
            urlAfterRedirects.includes("/onboarding-profile") ||
            urlAfterRedirects.includes("/onboarding-completion") ||
            urlAfterRedirects.includes("/reset-password") ||
            urlAfterRedirects.includes("/error");

        this.settingsNavigationActive =
            urlAfterRedirects.includes("/profile") ||
            urlAfterRedirects.includes("/subscription") ||
            urlAfterRedirects.includes("/billing");

        if (!this.hideLayout) {
            this.mobileMenuAction(false);
        }

        if (urlAfterRedirects.includes("/verify-mobile") || urlAfterRedirects.includes(`/${this.focusWizardPath}`)) {
            this.menuAction(false);
            this.menuService.hide(MenuType.CollapsedMenuIcon);
        }
    }

    private async initAnalytics(user: AppUser) {
        this.analyticsService.init(user);
    }

    private async manageTour(tours: Tour[]) {
        if (!tours) {
            this.tourService.hideTour();
            return;
        }

        const user = await this.user$
            .pipe(
                takeUntil(this.unsubscribe),
                filter(userObj => !!userObj),
                take(1)
            )
            .toPromise();
        await this.tourService.queueTours(user, tours);
    }

    recordEvent(event: string) {
        if (event === "read") {
            this.analyticsService.recordEvent("mouse-click", this.gaEvents.APP_MARKASREADNOTIFICATION);
            this.analyticsService.recordEvent("mouse-click", this.hotJarEvents.NotificationMarkReadEvent);
        } else if (event === "delete") {
            this.analyticsService.recordEvent("mouse-click", this.gaEvents.APP_DELETENOTIFICATION);
            this.analyticsService.recordEvent("mouse-click", this.hotJarEvents.NotificationDeleteEvent);
        } else if (event === "unread") {
            this.analyticsService.recordEvent("mouse-click", this.gaEvents.APP_MARKASUNREADNOTIFICATION);
        }
    }
}
