<ng-container *ngIf="card$ | async; let card">
    <centered-modal-layout
        *ngIf="{isThreadActive : thread | isThreadActive | async } as thread"
        [disableSave]="!(thread.isThreadActive && form.valid)"
        [loading]="!!loader.counter"
        [cancelButtonTitle]="null"
        [saveButtonTitle]="'Save and close'"
        [analyticsPrefix]="ANALYTICS_PREFIX"
        (save)="updateRequestState(card)"
        (close)="close()"
    >
        <div modal-header>
            Edit request
        </div>
        <div modal-subhead class="request-common-subhead">
            <fx-avatar
                [name]="card?.createdBy | userToName | async"
                [image]="card?.createdBy | profilePicture"
                size="small"
            ></fx-avatar>
            <span class="request-common-subhead-name">{{ card?.createdBy | userToName | async }}&nbsp;</span> created
            this request on {{ card?.createdAt | date: "EEEE dd MMMM yyyy" }}
        </div>
        <div modal-content>
            <div class="request-common">
                <form [formGroup]="form">
                    <div class="request-common-group">
                        <div class="request-common-request-info-fields">
                            <label class="fx-form-label" for="title">Request title</label>
                            <div class="fx-form-control">
                                <input formControlName="title" class="fx-form-input" placeholder="" name="title" [readonly]="!thread.isThreadActive" [ngClass]="{ 'fx-form-input-read-only': !thread.isThreadActive }"/>
                            </div>
                            <div
                                *ngIf="form.controls['title'].hasError('required') && form.controls['title'].touched"
                                class="fx-form-validation"
                            >
                                Request title is required.
                                <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                            </div>
                        </div>
                    </div>

                    <div class="request-common-group">
                        <div class="request-common-request-info-fields">
                            <label class="fx-form-label" for="dueDate">Due Date</label>
                            <div class="fx-form-control">
                                <input
                                    class="fx-form-input"
                                    matInput
                                    [matDatepicker]="picker"
                                    [min]="minDate"
                                    name="dueDate"
                                    formControlName="dueDate"
                                    placeholder="(Optional)"
                                />
                                <mat-datepicker-toggle class="request-common-date-picker-toggle" matSuffix [for]="picker">
                                    <i class="las la-calendar request-common-date-picker-icon" matDatepickerToggleIcon></i>
                                </mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                                <div class="fx-form-validation" *ngIf="!form.controls['dueDate'].valid">
                                    Date must use this format: DD/MM/YYYY and past dates are not allowed
                                    <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="request-common-group">
                        <div class="request-common-label request-common-group-header">
                            Note or message
                        </div>
                        <div class="request-common-request-message-editor-field" [ngClass]="{ 'request-common-request-message-editor-read-only': !thread.isThreadActive }">
                            <quill-editor-wrapper
                                [readOnly]="!thread.isThreadActive"
                                [message]="$any(form.controls.cardDescription)"
                            ></quill-editor-wrapper>
                        </div>
                        <div
                            *ngIf="
                                form.controls['cardDescription'].hasError('required') &&
                                form.controls['cardDescription'].touched
                            "
                            class="fx-form-validation"
                        >
                            Note or message is required.
                            <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                        </div>
                    </div>
                    <div class="request-common-group">
                        <div class="request-common-label request-common-group-header">
                            Request attachments
                        </div>
                        <file-input
                            *ngIf="thread.isThreadActive"
                            [analyticsPrefix]="ANALYTICS_PREFIX"
                            [enableDirectoryDrop]="true"
                            (file)="handleAttachmentAdded($event)"
                        ></file-input>
                        <div class="edit-request-attached" *ngIf="state?.attachments?.data?.length">
                            <div
                                *ngFor="let attachment of state?.attachments?.data; let i = index"
                                class="edit-request-attached-attachment"
                            >
                                <div
                                    class="request-common-file"
                                    (click)="downloadFile(state?.attachments?.fileId, attachment)"
                                >
                                    <i class="request-common-file-icon las la-paperclip"></i>
                                    <a>{{ attachment.filename }}</a>
                                </div>

                                <div
                                    *ngIf="thread.isThreadActive"
                                    (click)="handleAttachmentDeleted(attachment)"
                                    analyticsClick="{{ ANALYTICS_PREFIX }}_remove-file"
                                    class="edit-request-attached-delete"
                                >
                                    <i class="las la-times"></i>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="request-common-secondary-header">
                        <h1>
                            To-do list
                        </h1>
                    </div>

                    <div class="request-common-secondary-sub-header">
                        Action items for this request.
                    </div>

                    <div class="request-common-todo-list" formArrayName="requestItems">
                        <fx-todolist>
                            <ng-container
                                *ngFor="let item of $any(form.get('requestItems')).controls; let i = index"
                                [formGroupName]="i"
                            >
                                <fx-todolist-item
                                    formControlName="completed"
                                    (change)="
                                        markItemComplete($event, item.controls['requestItem'], card, ANALYTICS_PREFIX)
                                    "
                                    [disabled]="!thread.isThreadActive"
                                >
                                    <div
                                        class="request-common-todo"
                                        [ngClass]="{ 'request-common-todo--checked': item.get('completed').value }"
                                    >
                                        <div class="request-common-todo-details">
                                            <textarea
                                                #todoItemComponents
                                                formControlName="description"
                                                (keydown)="keyboardEvent($event)"
                                                (paste)="onPasteTodoItems($event)"
                                                class="request-common-todo-details-field"
                                                type="text"
                                                placeholder="Type something..."
                                                cdkTextareaAutosize
                                                cdkAutosizeMinRows="1"
                                                cdkAutosizeMaxRows="3"
                                                [readOnly]="!thread.isThreadActive"
                                            ></textarea>
                                            <button
                                                *ngIf="$any(form.get('requestItems')).controls.length > 1 && thread.isThreadActive"
                                                (click)="handleRequestItemRemoved(i, (item?.controls)['requestItem'])"
                                                class="request-common-todo-details-remove"
                                            >
                                                <i class="las la-times icon-size"></i>
                                            </button>
                                        </div>
                                        <request-todo-actions
                                            [userId]="userId$ | async"
                                            *ngIf="(item?.controls)['requestItem']?.value"
                                            [readonly]="true"
                                            [analyticsPrefix]="ANALYTICS_PREFIX"
                                            [requestItem]="item.controls['requestItem'].value"
                                        ></request-todo-actions>
                                    </div>
                                </fx-todolist-item>
                                <div
                                    *ngIf="
                                        item.get('description').hasError('required') && item.get('description').dirty
                                    "
                                    class="fx-form-validation request-common-todo-error"
                                >
                                    Message is required.
                                </div>
                            </ng-container>
                        </fx-todolist>
                        <button
                            *ngIf="thread.isThreadActive"
                            (click)="addRequestItem()"
                            [ngClass]="{ 'request-common-todo-add-item--disabled': !form.get('requestItems').valid }"
                            class="request-common-todo-add-item"
                        >
                            + Add a new item
                        </button>
                    </div>
                    <div
                        *ngIf="thread.isThreadActive"
                        [ngClass]="
                            actionedPercentage === 100
                                ? 'request-common-complete-all--disabled'
                                : 'request-common-complete-all'
                        "
                    >
                        <button (click)="markAllComplete(true, card)">Mark all as complete</button>
                    </div>
                </form>
            </div>
        </div>
    </centered-modal-layout>
</ng-container>
