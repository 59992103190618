import { TaskAction } from "../../../projects/portal-modules/src/lib/plugins/services/Libraries";
import { GA_EVENTS } from "../../../projects/portal-modules/src/lib/analytics";
import { CardResources } from "../../portal-modules/src/lib/threads-ui/interfaces/IUiCard";
import { EditRequestComponent } from "./components/request/edit-request/edit-request.component";
import { ActionRequestComponent } from "./components/request/action-request/action-request.component";
import { IRequestModalData } from "./components/request/request-common.component";
import { Injector } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { CardTaskActionLabel } from "@findex/threads";

export const vaultRequestAction = (isEditView: boolean, readonly: boolean) => async (
    cardResources: Partial<CardResources>,
    injector: Injector
) => {
    const matDialog = injector.get(MatDialog);
    const { thread$, card$, state$, role, replies$ } = cardResources;
    const data = {
        state$,
        thread$,
        replies$,
        card$,
        role,
        readonly
    };

    return matDialog
        .open<any, IRequestModalData, boolean>(isEditView ? EditRequestComponent : ActionRequestComponent, {
            data,
            panelClass: ["centered-modal"],
            disableClose: true,
            width: "761px"
        })
        .afterClosed()
        .toPromise();
};

export const provideInfoVaultRequestTaskAction: TaskAction<boolean> = {
    analyticsEvents: [GA_EVENTS.APP_PROVIDEDOCUMENTS],
    cardTypes: ["vault-request"],
    action: vaultRequestAction(false, false),
    buttonLabel: CardTaskActionLabel.RequestResponse,
    statusIcon: "las la-check-square"
};

export const viewVaultRequestTaskAction: TaskAction<boolean> = {
    analyticsEvents: [GA_EVENTS.RFICARD_VIEW],
    cardTypes: ["vault-request"],
    action: vaultRequestAction(false, false),
    buttonLabel: CardTaskActionLabel.ViewResponse,
    statusIcon: "las la-check-square"
};

export const viewCanceledRequestTaskAction: TaskAction<boolean> = {
    analyticsEvents: [GA_EVENTS.RFICARD_VIEW],
    cardTypes: ["vault-request"],
    action: vaultRequestAction(true, false),
    buttonLabel: CardTaskActionLabel.ViewResponse,
    statusIcon: "las la-check-square"
};

export const editVaultRequestTaskAction: TaskAction<boolean> = {
    analyticsEvents: [GA_EVENTS.RFICARD_EDIT],
    cardTypes: ["vault-request"],
    action: vaultRequestAction(true, true),
    buttonLabel: CardTaskActionLabel.EditResponse,
    statusIcon: "las la-check-square"
};

export const signDocumentsTaskAction: TaskAction<boolean> = {
    cardTypes: ["vault"],
    action: async ({ threadId, cardId }, injector) => {
        const router = injector.get(Router);
        return await router.navigate(["timelines", threadId, "cards", cardId]);
    },
    buttonLabel: CardTaskActionLabel.SignFiles,
    statusIcon: "las la-user-edit"
};
