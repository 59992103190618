import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { filter, map, switchMap, takeUntil } from "rxjs/operators";
import { Loader } from "../../../../portal-modules/src/lib/shared/services/loader";
import { Router } from "@angular/router";
import { Observable } from "rxjs/internal/Observable";
import { interval, Subject, Subscription } from "rxjs";
import { CalendarService } from "../../../calendar/services/calendar.service";
import { ThreadsWebsocketService } from "../../../../portal-modules/src/lib/shared/services/threads-websocket.service";
import { IUpcomingMeeting } from "@findex/threads";
import { CardState, CardSubject } from "../../types/card-state";

@Component({
    selector: "upcoming-meetings",
    templateUrl: "./upcoming-meetings.component.html",
    styleUrls: ["./upcoming-meetings.component.scss"]
})
export class UpcomingMeetingsComponent implements OnChanges, OnDestroy, OnInit {
    @Input() state: CardState;
    upcomingMeetings: IUpcomingMeeting[];
    loader = new Loader();
    currentDateMinuteInterval$: Observable<Date>;
    private unbindState$ = new Subject();
    private websocketSubs: Subscription[] = [];
    constructor(
        private calendarService: CalendarService,
        private router: Router,
        private websocketService: ThreadsWebsocketService
    ) {
        this.currentDateMinuteInterval$ = interval(1000 * 60).pipe(map(() => new Date()));
    }

    async ngOnInit() {
        this.loader.show();
        this.upcomingMeetings = await this.calendarService.getUpcomingMeetings().toPromise();
        this.loader.hide();
    }

    async ngOnChanges(changes: SimpleChanges) {
        const { state } = changes;

        if (state && state.currentValue) {
            if (state.previousValue) {
                const newCalendarSubjects = this.getNewCalendarSubjects(state.previousValue, state.currentValue);
                if (newCalendarSubjects.length !== 0) {
                    this.upcomingMeetings = await this.calendarService.getUpcomingMeetings().toPromise();
                    newCalendarSubjects.forEach(subject => {
                        this.websocketSubs.push(this.subscribeToSubject(subject));
                    });
                }
            }
            if (this.websocketSubs.length === 0) {
                await this.initWebsocketUpdates(state.currentValue);
            }
        }
    }

    private getNewCalendarSubjects(previousCardState: CardState, currentCardState: CardState): CardSubject[] {
        const previousCalendarSubjects = previousCardState.cardSubjects.filter(subject => subject.type === "calendar");
        const currentCalendarSubjects = currentCardState.cardSubjects.filter(subject => subject.type === "calendar");

        return currentCalendarSubjects.filter(
            currentCardSubject =>
                !previousCalendarSubjects.some(
                    previousCardSubject => previousCardSubject.subjectId === currentCardSubject.subjectId
                )
        );
    }

    subscribeToSubject(subject: CardSubject): Subscription {
        return this.websocketService
            .watchCardId(subject.threadId, subject.cardId)
            .pipe(
                takeUntil(this.unbindState$),
                //Filter on card events - without this it will update when a user comments on the calendar card.
                filter(event => event.subjectType === "card"),
                switchMap(() => this.calendarService.getUpcomingMeetings())
            )
            .subscribe(upcomingMeetings => {
                this.upcomingMeetings = upcomingMeetings;
            });
    }

    async initWebsocketUpdates(state: CardState) {
        const calendarSubjects = state.cardSubjects.filter(subject => subject.type === "calendar");
        this.websocketSubs = calendarSubjects.map(subject => this.subscribeToSubject(subject));
    }

    ngOnDestroy() {
        this.unbindState$.next(null);
        this.unbindState$.complete();
    }

    goToMeeting(meeting: IUpcomingMeeting) {
        const { threadId, cardId } = meeting;
        this.router.navigate(["/timelines", threadId, "cards", cardId]);
    }
}
