import { Pipe, PipeTransform } from "@angular/core";
import { Metric, ThreadAuditSubtypes, ThreadTypes } from "@findex/threads";

export type ThreadMetric = Metric<any> & {
    threadName: string;
    clientNames: string[];
    workflowState: string;
    actorName: string;
    accountId: string;
    createdAt: string;
    lastActivityDate: string;
    type: ThreadTypes;
    subType: ThreadAuditSubtypes;
};

@Pipe({ name: "reportSearch" })
export class ReportSearchPipe implements PipeTransform {
    constructor() {}

    transform(metrics: ThreadMetric[], searchTerm: string) {
        if (!searchTerm || !metrics) return metrics;

        const lowerSearch = searchTerm.toLowerCase();

        return metrics.filter(metric =>
            Object.keys(metric).some(
                key =>
                    metric[key] &&
                    metric[key] !== null &&
                    metric[key]
                        .toString()
                        .toLowerCase()
                        .includes(lowerSearch)
            )
        );
    }
}
