import { Component, Inject, OnDestroy } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { MatTableDataSource } from "@angular/material/table";
import {
    CardReply,
    CardStatus,
    IThread,
    IThreadCard,
    Role,
    VaultStateAction,
    IVaultRequestCardState
} from "@findex/threads";
import { distinctUntilChanged, filter, map, shareReplay, switchMap, take } from "rxjs/operators";
import { Loader } from "../../../../portal-modules/src/lib/shared/services/loader";
import {
    ThreadsVaultService,
    VaultRequestRow
} from "../../../../portal-modules/src/lib/threads-ui/services/threads-vault.service";
import { CardResources, ROLE, THREAD } from "../../../../portal-modules/src/lib/threads-ui/interfaces/IUiCard";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { VaultRequestService } from "../../services/vault-request.service";
import { ILibrary, TaskAction } from "projects/portal-modules/src/lib/plugins";
import { TASK_ACTION_LIBRARY } from "src/app/injection-token";
import { TaskActionService } from "projects/portal-modules/src/lib/shared/components/actionable-card/task-action.service";

@Component({
    selector: "request-list",
    templateUrl: "./request-list.component.html",
    styleUrls: ["./request-list.component.scss"]
})
export class RequestListComponent implements OnDestroy {
    protected cardResources: Partial<CardResources>;

    roles = Role;
    cardStatuses = CardStatus;

    loader = new Loader();
    tableData = new MatTableDataSource<VaultRequestRow>();
    requestSubscription: Subscription;

    requests$: Observable<VaultRequestRow[]>;
    userId$: Observable<string>;

    actionInProgress = false;

    constructor(
        @Inject(THREAD) public thread$: Observable<IThread>,
        @Inject(ROLE) public role$: Observable<Role>,
        @Inject(TASK_ACTION_LIBRARY) protected taskActions: ILibrary<TaskAction<void>>,
        private threadsVaultService: ThreadsVaultService,
        private authService: AuthService,
        private taskActionService: TaskActionService
    ) {
        this.requests$ = this.thread$.pipe(
            distinctUntilChanged(),
            switchMap(thread => this.threadsVaultService.getRequestList(thread.id, this.loader)),
            shareReplay(1)
        );
        this.requestSubscription = this.requests$.subscribe(requests => (this.tableData.data = requests));

        this.userId$ = this.authService.getUserWithoutRole().pipe(
            filter(user => !!user),
            map(user => user.id)
        );
    }

    async getCardResources(vaultId: string, role: Role) {
        const state$ = this.getRowState(vaultId);
        const replies$ = this.getRowReplies(vaultId);
        const card$ = this.getRowCard(vaultId);
        return {
            card$,
            replies$,
            role,
            state$,
            thread$: this.thread$
        };
    }

    async openRequestModal(request: VaultRequestRow) {
        const { vaultId } = request.state;
        const role = await this.role$.pipe(take(1)).toPromise();
        const state$ = this.getRowState(vaultId);
        const isClient = role === Role.Client;
        const requestState = await state$.pipe(take(1)).toPromise();
        const isComplete = VaultRequestService.isRequestComplete(requestState.requestItems);
        const isCancelled =
            request.card.status === this.cardStatuses.Disabled || request.card.status === this.cardStatuses.Removed;
        if (isComplete || isCancelled || !isClient) {
            this.action(VaultStateAction.ViewResponse, vaultId, role);
        } else {
            this.action(VaultStateAction.RequestResponse, vaultId, role);
        }
    }

    async action(actionId: string, vaultId: string, role: Role) {
        const resources = await this.getCardResources(vaultId, role);
        this.loader.show();
        await this.taskActionService.action(actionId, resources);
        this.loader.hide();
    }

    private getRowState(vaultId: string): Observable<IVaultRequestCardState> {
        return this.getRequestRow(vaultId).pipe(map(row => row.state));
    }

    private getRowReplies(vaultId: string): Observable<CardReply[]> {
        return this.getRequestRow(vaultId).pipe(map(row => row.cardReplies));
    }

    private getRowCard(vaultId: string): Observable<IThreadCard> {
        return this.getRequestRow(vaultId).pipe(map(row => row.card));
    }

    private getRequestRow(vaultId: string): Observable<VaultRequestRow> {
        return this.requests$.pipe(
            map(requests => requests.find(request => request.state.vaultId === vaultId)),
            filter(row => !!row)
        );
    }

    ngOnDestroy() {
        if (this.requestSubscription) {
            this.requestSubscription.unsubscribe();
        }
    }
}
