import { Component, OnInit, Input, Output, EventEmitter, Inject } from "@angular/core";
import { Role } from "@findex/threads";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { DocumentCategory } from "projects/default-plugins/vault/enums/DocumentCategory";
import { ENVIRONMENT } from "src/app/injection-token";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";

export enum ReportingPeriod {
    Monthly = "Monthly",
    Quarterly = "Quarterly"
}

export type IDocument = {
    description: string;
    file: File;
    signature?: boolean;
    category?: DocumentCategory;
    reportingPeriod?: ReportingPeriod;
};

@Component({
    selector: "create-card-document",
    templateUrl: "./create-card-document.component.html",
    styleUrls: ["./create-card-document.component.scss"]
})
export class CreateCardDocumentComponent implements OnInit {
    readonly gaEvents = GA_EVENTS;
    uploadIncludesReport = false;
    uploadIncludesSign = false;

    @Input() role: Role;
    @Input() errorMessages: string[];
    @Input() documents: IDocument[];

    @Output() remove = new EventEmitter<IDocument>();
    @Output() rename = new EventEmitter<IDocument>();
    @Output() markAsReport = new EventEmitter<number>();

    readonly showInsights = this.environment.featureFlags?.insightsConfiguration?.showInsights;

    documentType = DocumentCategory;
    roles = Role;

    constructor(@Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig) {}

    ngOnInit() {}

    removeDocument(document: IDocument) {
        this.remove.emit(document);
    }

    isReportPresent(documentList: IDocument[]): boolean {
        return documentList.some(document => document.category === DocumentCategory.Report);
    }
    isSignPresent(documentList: IDocument[]): boolean {
        return documentList.some(document => document.signature);
    }
    updateDocumentTypeState() {
        this.uploadIncludesReport = this.isReportPresent(this.documents);
        this.uploadIncludesSign = this.isSignPresent(this.documents);
    }
    markDocumentAsReport(documentIndex: number, document: IDocument) {
        document.signature = false;
        this.markAsReport.emit(documentIndex);
    }
    markDocumentAsSign(document: IDocument) {
        document.signature = !document.signature;
    }
}
