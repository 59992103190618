import { Component, Inject, OnInit } from "@angular/core";
import { IParticipant, IThreadCard } from "@findex/threads";
import { THREAD_CARD_RESOURCES, CardResources } from "projects/portal-modules/src/lib/threads-ui/interfaces/IUiCard";
import { ParticipantCache } from "projects/portal-modules/src/lib/threads-ui/services/participant-cache.service";
import { combineLatest, Observable, of } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";

export enum NotificationCardTypes {
    USER_ADDED = "USER_ADDED",
    USER_REMOVED = "USER_REMOVED",
    USERS_INVITED = "USERS_INVITED",
    ATTACHMENT_PURGED = "ATTACHMENT_PURGED"
}

const NO_PARTICIPANTS = "NO_PARTICIPANTS" as const;

export interface INotificationCardState<T> {
    notificationCardObject: T;
    notificationType: string;
}

@Component({
    selector: "notification-card.component",
    templateUrl: "./notification-card.component.html",
    styleUrls: ["./notification-card.component.scss"]
})
export class NotificationCardComponent implements OnInit {
    readonly NOTIFICATION_CARD_TYPES = NotificationCardTypes;

    card$: Observable<IThreadCard>;
    state$: Observable<INotificationCardState<any>>;
    notificationType$: Observable<NotificationCardTypes>;
    notificationCardObject$: Observable<unknown>;

    actor$: Observable<IParticipant>;
    participants$: Observable<IParticipant[] | typeof NO_PARTICIPANTS>;

    loaded$: Observable<any>;
    participantChannelUpdate$: Observable<string>;

    constructor(
        @Inject(THREAD_CARD_RESOURCES) private resources: CardResources,
        private participantCache: ParticipantCache,
    ) {}

    ngOnInit() {
        const { card$, state$, thread$ } = this.resources;
        this.card$ = card$;

        this.notificationType$ = state$.pipe(
            map(
                (state: INotificationCardState<unknown>) =>
                    state.notificationType as NotificationCardTypes
            )
        );
        this.notificationCardObject$ = state$.pipe(
            map(
                (state: INotificationCardState<unknown>) =>
                    state.notificationCardObject as NotificationCardTypes
            )
        );

        this.participantChannelUpdate$ = combineLatest([state$, thread$]).pipe(
            switchMap(([state, thread]) => {
                const participantId = state.notificationCardObject.participants.find(val => val);
                if (state.notificationType === this.NOTIFICATION_CARD_TYPES.USER_ADDED) {
                    return of(`activity/participants/${participantId}/threads/${thread.id}/add`);
                } else if (state.notificationType === this.NOTIFICATION_CARD_TYPES.USER_REMOVED) {
                    return of(`activity/participants/${participantId}/threads/${thread.id}/remove`);
                }
                return of("");
            })
        );

        this.actor$ = state$.pipe(
            map(state => state.notificationCardObject),
            switchMap(obj => this.participantCache.getParticipant(obj?.actor))
        );

        this.participants$ = state$.pipe(
            map(state => state.notificationCardObject),
            switchMap(obj => {
                if (obj == null || !("participants" in obj)) {
                    return of(NO_PARTICIPANTS);
                }
                return this.participantCache.getParticipants(obj?.participants);
            })
        );

        this.loaded$ = combineLatest([card$, this.participants$]).pipe(
            // directly after inviting a user their name is not available yet, filter it out if this is the case
            filter(([_card, participants]) => {
                if (participants == NO_PARTICIPANTS) { return true; }
                if (!participants?.length) return false;
                for (const participant of participants) {
                    if (!participant?.profile?.name) return false;
                }
                return true;
            })
        );
    }
}
