import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { OpenMeasuresService } from "../../services/open-measures.service";
import { Period, TableReport } from "@findex/openmeasures-core";

@Component({
    selector: "app-insights-overview",
    templateUrl: "./insights-overview.component.html",
    styleUrls: ["./insights-overview.component.scss"]
})
// rename to scorecard
export class InsightsOverviewComponent implements OnInit {
    loader = new Loader();
    period: Period;
    report: TableReport;

    constructor(private route: ActivatedRoute, private router: Router, private openMeasures: OpenMeasuresService) {}


    async ngOnInit() {
        this.loader.show();
        const enterpriseId = this.route.snapshot.params.enterpriseId;
        const entityId = this.route.snapshot.params.entityId;

        const periods = await this.openMeasures.getPeriods(enterpriseId).toPromise();
        const period = periods?.pop();

        if (!period) return;

        this.period = period;
        this.report =  await this.openMeasures.getMetrics(enterpriseId, period.id, entityId).toPromise();

        console.log('GOT RESULTS', enterpriseId, entityId, period, this.report)
        this.loader.hide();
    }

    navigateTo(path: string) {
        this.router.navigate([`./${path}`], { relativeTo: this.route });
    }
}
