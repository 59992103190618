import { Component, Input, EventEmitter, Output, OnDestroy, ViewChild, Inject } from "@angular/core";
import { Subject } from "rxjs";
import { EnvironmentSpecificConfig, environmentCommon } from "../../../environment/environment.common";
import { QuillViewComponent } from "ngx-quill";
import { ENVIRONMENT } from "src/app/injection-token";

@Component({
    selector: "app-card",
    templateUrl: "./card.component.html",
    styleUrls: ["./card.component.scss"]
})
export class CardComponent implements OnDestroy {
    @Input() name?: string;
    @Input() image?: string;
    @Input() timestamp?: string | Date;
    @Input() title?: string;
    @Input() description?: string;
    @Input() lineCard: boolean;
    @Input() invertTheme?: boolean;
    @Input() conversation = true;
    @Input() type?: "primary" | "secondary";
    @Input() align?: "left" | "right" | "centre";
    @Input() edit: Subject<boolean>;
    @Input() edited: boolean | Subject<boolean>;
    @Input() loading = false;

    @Output() save = new EventEmitter();
    @Output() showSeeMore = new EventEmitter<boolean>();
    quillStyles = environmentCommon.quillConfig.styling;
    updatedDescription: string;
    QUILL_MAX_HEIGHT = environmentCommon.quillConfig.maxHeight.card;
    readonly appName = this.environment.appName;

    @ViewChild("descriptionComponent") descriptionComponent: QuillViewComponent;
    private resizeObservable: ResizeObserver;

    constructor(
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig
    ) {
        this.resizeObservable = new ResizeObserver(() => this.toggleSeeMoreButton());
    }

    observeQuill() {
        if (this.descriptionComponent?.quillEditor?.root) {
            this.resizeObservable.observe(this.descriptionComponent.quillEditor.root);
        }
    }

    toggleSeeMoreButton() {
        //Measures the height of the quill editor and triggers 'see more' if longer than our max height
        this.showSeeMore.emit(this.descriptionComponent?.quillEditor?.root?.offsetHeight > this.QUILL_MAX_HEIGHT);
    }

    saveEdit($event: MouseEvent) {
        $event.preventDefault();
        this.edit.next(false);
        this.save.emit(this.updatedDescription);
    }

    cancelEdit($event: MouseEvent) {
        $event.preventDefault();
        this.edit.next(false);
    }

    ngOnDestroy() {
        if (this.edit) {
            this.edit.unsubscribe();
        }

        if (this.resizeObservable) {
            this.resizeObservable.disconnect();
        }
    }
}
