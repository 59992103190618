import {
    Component,
    ErrorHandler,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { customValidators } from "../../validators";
import { merge, Subject } from "rxjs";
import { delay, filter, mapTo, startWith, takeWhile } from "rxjs/operators";
import { AnalyticsService } from "projects/portal-modules/src/lib/analytics";
import { EnvironmentSpecificConfig } from "../../../environment/environment.common";
import { ENVIRONMENT, MOBILE_VERIFY_SERVICE } from "src/app/injection-token";
import { PortalService } from "../../services/portal.service";
import { HandledError } from "../../services/threads-error-handler";
import { IStepComponent } from "../../../threads-ui/components/focus-action-wizard/step-component.interface";
import { MobileVerifyService, MobileVerifyStatus } from "../../services/mobile-verify/mobile-verify.service";
import { Loader } from "../../services/loader";
import { AuthService } from "../../../findex-auth";

const loader = new Loader();
@Component({
    selector: "app-verify-mobile-form",
    templateUrl: "./verify-mobile-form.component.html",
    styleUrls: ["./verify-mobile-form.component.scss"],
    providers: [{ provide: Loader, useValue: loader }]
})
export class VerifyMobileFormComponent implements OnInit, OnChanges, IStepComponent {
    @Input()
    mobileNumber: string;
    @Input()
    codeSent: boolean;
    @Input()
    isInvitation?: boolean = false;

    @Output()
    back: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    completed: EventEmitter<void> = new EventEmitter<void>();

    readonly appName = this.environment.appName;
    readonly theme = this.environment.theme;
    readonly signupCountries = this.environment.featureFlags.signupCountries;
    readonly textResource = this.environment.featureFlags.text;
    readonly supportEmail = this.environment.featureFlags.supportEmail;

    errorMessage = "";
    infoMessage = "";
    internationalPhoneNo: string;
    internationalPhoneNoValid: boolean;

    showVerifyCode = false;

    form = new FormGroup({
        mobileNumber: new FormControl(null, [
            Validators.required,
            customValidators.mobileValidator(this.signupCountries)
        ]),
        verifyCode: new FormControl({ value: null, disabled: true }, [
            Validators.required,
            Validators.pattern("[0-9]{6}"),
            Validators.maxLength(6),
            Validators.minLength(6)
        ])
    });

    private resendClicked = new Subject<true>();
    disableResend$ = merge(
        this.resendClicked.pipe(startWith(false)), //Start with false so the button is enabled initially
        this.resendClicked.pipe(delay(10000), mapTo(false)) //Un-disable the button after a delay
    );

    constructor(
        private analyticsService: AnalyticsService,
        private portalService: PortalService,
        private errorHandler: ErrorHandler,
        public loaderService: Loader,
        private authService: AuthService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        @Inject(MOBILE_VERIFY_SERVICE) private mobileVerifyService: MobileVerifyService
    ) {}

    ngOnInit() {
        this.form.setValue({
            mobileNumber: this.mobileNumber || "",
            verifyCode: ""
        });
        if (this.codeSent) {
            this.enableVerify();
        }
        this.authService
            .getUser()
            .pipe(
                filter(user => !!user?.mobileNumberVerified),
                takeWhile(() => !this.loaderService.counter),
                delay(1)
            )
            .subscribe(() => {
                this.completed.emit();
            });
    }
    ngOnChanges(changes: SimpleChanges) {
        if (changes?.mobileNumber?.currentValue) {
            this.form.controls.mobileNumber.setValue(changes.mobileNumber.currentValue);
            this.internationalPhoneNoValid = changes.mobileNumber.currentValue;
        }
    }

    private enableVerify() {
        this.showVerifyCode = true;
        this.form.controls.verifyCode.enable();
    }

    async requestMFACode() {
        this.resendClicked.next(true);
        this.enableVerify();
        this.infoMessage = "";
        this.errorMessage = "";
        try {
            const result = await this.mobileVerifyService.requestMFACode(this.internationalPhoneNo);
            switch (result.status) {
                case MobileVerifyStatus.OK:
                    if (this.showVerifyCode) {
                        this.recordAnalyticsEvent("begin-resend");
                        this.infoMessage = `We've sent another code, please check your mobile`;
                    } else {
                        this.recordAnalyticsEvent("begin-resend");
                        this.infoMessage = `We've sent a code, please check your mobile`;
                    }
                    this.recordAnalyticsEvent("begin-resend");
                    this.showVerifyCode = true;
                    break;
                case MobileVerifyStatus.VERIFIED:
                    this.recordAnalyticsEvent("begin-already-verified");
                    break;
                case MobileVerifyStatus.THROTTLED:
                    this.recordAnalyticsEvent("begin-throttled");
                    this.errorMessage = "Sorry, you've sent too many requests recently.";
                    break;
                case MobileVerifyStatus.MOBILE_IN_USE:
                    this.recordAnalyticsEvent("begin-number-in-use");
                    this.errorMessage = "This mobile number is already in use.";
                    break;
                case MobileVerifyStatus.REQUEST_ERROR:
                    this.recordAnalyticsEvent("begin-request-error");
                    this.errorMessage = "An error occurred, please try again.";
                    this.errorHandler.handleError(new HandledError(this.errorMessage));
                    break;
                case MobileVerifyStatus.UNKNOWN_ERROR:
                default:
                    this.recordAnalyticsEvent("begin-unknown-error");
                    this.errorMessage = "An error occurred, please try again.";
                    this.errorHandler.handleError(new HandledError(this.errorMessage));
                    break;
            }
        } catch (err) {
            this.recordAnalyticsEvent("begin-resend-unknown-error");
            this.errorMessage = "An error occurred, please try again.";
            this.errorHandler.handleError(new HandledError(this.errorMessage));
        }
    }

    async verifyMobileCode() {
        //For some reason this value is cleared when refreshUserTokens is called in cognito. Weird! --Spencer note: afraid to touch this line
        const code = `${this.form.controls.verifyCode.value}`;

        this.loaderService.show();
        this.recordAnalyticsEvent("mobile-entered");
        try {
            const success = await this.submitCode(code);
            if (success === true) {
                //Update freshsales and account details after verifying mobile for visory onboarding
                if (this.environment.featureFlags.updateAccountMetadataOnVerifyMobile) {
                    await this.portalService.updateContactAccountMetadata("me", {
                        primaryPhoneNumber: this.internationalPhoneNo
                    });
                }
                this.recordAnalyticsEvent("mobile-verified");
                this.completed.emit();
            } else {
                this.recordAnalyticsEvent("fail");
                // this.errorMessage = result.message;
                this.loaderService.hide();
            }
        } catch (error) {
            this.recordAnalyticsEvent("mobile-unknown-error");
            this.errorMessage = error.message;
            this.loaderService.hide();
        }
    }

    private async submitCode(code: string): Promise<boolean> {
        if (!code) {
            return false;
        }

        this.loaderService.show();

        try {
            const result = await this.mobileVerifyService.validateMFACode(code);
            this.loaderService.hide();
            switch (result.status) {
                case MobileVerifyStatus.OK:
                    this.recordAnalyticsEvent("confirm-success");
                    return true;
                case MobileVerifyStatus.INCORRECT_CODE:
                    this.errorMessage = "Sorry, invalid pin, please try again";
                    this.recordAnalyticsEvent("confirm-incorrect");
                    return false;
                case MobileVerifyStatus.EXPIRED:
                    this.errorMessage = `The verification code expired, please click "resend" to try again.`;
                    this.recordAnalyticsEvent("confirm-expired");
                    return false;
                case MobileVerifyStatus.REQUEST_ERROR:
                    this.recordAnalyticsEvent("confirm-request-error");
                    this.errorMessage = "An error occurred, please try again.";
                    this.errorHandler.handleError(new HandledError(this.errorMessage));
                    return false;
                case MobileVerifyStatus.UNKNOWN_ERROR:
                default:
                    this.recordAnalyticsEvent("confirm-unknown-error");
                    this.errorMessage = "An error occurred, please try again.";
                    this.errorHandler.handleError(new HandledError(this.errorMessage));
            }
        } catch (err) {
            this.recordAnalyticsEvent("confirm-unknown-error");
            this.errorMessage = "An error occurred, please try again.";
            this.errorHandler.handleError(new HandledError(this.errorMessage));
        }
        return false;
    }

    private recordAnalyticsEvent(category: string) {
        this.analyticsService.recordEvent("verify-mobile", category);
    }
}
