import { Component, EventEmitter, Input, Output } from "@angular/core";
import { IThread, IThreadWorkflowState, IThreadWorkflowStep, Role } from "@findex/threads";
import { AnalyticsService, GA_EVENTS_PREFIX } from "../../../analytics";
import { Loader } from "../../../shared/services/loader";
import { ThreadsService } from "../../services/threads.service";
import { MatDialog } from "@angular/material/dialog";
import { EditThreadDatesComponent, EditThreadDatesData } from "../edit-thread-dates/edit-thread-dates.component";

@Component({
    selector: "thread-workflow-popup",
    templateUrl: "./thread-workflow-popup.component.html",
    styleUrls: ["./thread-workflow-popup.component.scss"]
})
export class ThreadWorkflowPopupComponent {
    @Input() workflowState: IThreadWorkflowState;
    @Input() role: Role;
    @Input() thread: IThread;

    @Output() close = new EventEmitter<boolean>();

    loader = new Loader();

    constructor(
        private dialog: MatDialog,
        private threadsService: ThreadsService,
        private analyticsService: AnalyticsService
    ) {}

    async saveState(thread: IThread, toStep: IThreadWorkflowStep): Promise<void> {
        this.loader.show();
        try {
            await this.threadsService.saveWorkflowState(thread, toStep.clientFacingId);
            this.analyticsService.recordEvent(GA_EVENTS_PREFIX.THREADS, toStep.internalId);
            this.close.emit();
        } finally {
            this.loader.hide();
        }
    }

    editDates(thread: IThread) {
        const config = {
            data: { thread },
            panelClass: ["centered-modal"],
            disableClose: true
        };

        this.dialog.open<EditThreadDatesComponent, EditThreadDatesData>(EditThreadDatesComponent, config);
    }
}
