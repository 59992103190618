<form [formGroup]="rhythmDates" autocomplete="off">
    <p class="new-thread-description">
        Select an end date for each milestone in
        <span *ngIf="title" class="new-thread-description-type">
            {{ title }}
        </span>
    </p>

    <div class="new-thread-rhythm-group" *ngIf="workflow">
        <div *ngFor="let step of workflow?.steps" class="new-thread-rhythm-fields">
            <ng-container *ngIf="(rhythmDates?.controls)[step.clientFacingId]; let rhythmControl">
                <label class="new-thread-rhythm-label" [for]="step.clientFacingName">
                    {{ step.clientFacingName }}
                </label>

                <div class="new-thread-rhythm-date-picker">
                    <input
                        class="new-thread-rhythm-input"
                        matInput
                        [matDatepicker]="picker"
                        [name]="step.clientFacingName"
                        (input)="handleChange()"
                        (dateChange)="handleChange()"
                        [formControlName]="step.clientFacingId"
                        required
                    />
                    <mat-datepicker-toggle class="new-thread-rhythm-date-picker-toggle" matSuffix [for]="picker">
                        <i class="las la-calendar new-thread-rhythm-icon" matDatepickerToggleIcon></i>
                    </mat-datepicker-toggle>
                </div>

                <mat-datepicker #picker></mat-datepicker>

                <div class="fx-form-validation" *ngIf="rhythmControl.hasError('required')">
                    Date must use this format: DD/MM/YYYY
                    <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                </div>
            </ng-container>
        </div>

        <div class="fx-form-validation" *ngIf="rhythmDates.hasError('datesOutOfOrder')">
            Dates need to be in order from earliest to largest
            <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
        </div>
    </div>
</form>
