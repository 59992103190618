<div class="inline-edit-controls">
    <input type="text" [(ngModel)]="updatedText" />

    <button [disabled]="updatedText === text" (click)="updated.emit(updatedText)">
        <i class="las la-check"></i> Save
    </button>
    
    <button (click)="updatedText = text; updated.emit(text)">
        <i class="las la-ban"></i> Discard
    </button>
</div>