<div class="industry-type-select">
    <div class="fx-search">
        <div class="fx-form-control">
            <div class="fx-form-icon">
                <i class="las la-search icon-brand-secondary"></i>
            </div>
            <input
                class="fx-form-input"
                type="text"
                placeholder="Search"
                aria-label="Industry type"
                [(ngModel)]="filter"
                (ngModelChange)="searchUpdated()"
            />
        </div>
    </div>
    <div class="industry-type-select-list" [style.maxHeight]="maxHeight" [style.minHeight]="maxHeight">
        <app-business-industry-option
            *ngFor="let option of filteredIndustryTypes"
            [name]="option.name"
            [value]="option.value"
            [selected]="option.selected"
            (updated)="selectionToggled(option)"
        ></app-business-industry-option>
        <div class="industry-type-select-list-no-results" *ngIf="!filteredIndustryTypes.length">
            No results found
        </div>
    </div>
</div>
