<div class="buttons-container">
    <button *ngIf="discardTitle"
            [disabled]="discardDisabled"
            (click)="onDiscard()"
            [analyticsClick]="[hotJarEvents.MessageReplyDiscardEvent]"
            class="reply-discard">
        <i class="las la-ban"></i>
        <span>{{discardTitle}}</span>
    </button>

    <button *ngIf="submitTitle"
            [disabled]="submitDisabled"
            (click)="onSubmit()"
            class="reply-submit">
        <i class="las la-check"></i>
        <span>{{submitTitle}}</span>
    </button>
</div>
