<div class="document-preview-modal-controls">
    <div class="document-preview-close-icon" (click)="close()">
        <i class="las la-times icon-size"></i>
    </div>
</div>
<ng-container *ngIf="trustedHtml$ | async; let sanitizedHtml; else: loading">
    <div class="document-preview-html-container" [innerHTML]="sanitizedHtml"></div>
    <div
        class="document-preview-signing fx-form fx-form--outlined"
        *ngIf="this.isSignable && (role$ | async | permissions: 'SignDocumentsVaultCard' | async)"
    >
        <div class="document-preview-signing-container">
            <div class="fx-form-group">
                <label class="fx-form-label" for="name">Your name</label>
                <div class="fx-form-control">
                    <input
                        id="name"
                        class="fx-form-input document-preview-signing-name"
                        type="text"
                        aria-label="Your Name"
                        placeholder="eg. John Doe"
                        [value]="name"
                        (change)="name = $event.target.value"
                    />
                </div>
            </div>

            <div class="fx-form-group">
                <label class="fx-form-label" for="title">Your title</label>
                <div class="fx-form-control">
                    <input
                        id="title"
                        class="fx-form-input document-preview-signing-title"
                        type="text"
                        aria-label="Your title"
                        placeholder="eg. Director"
                        [value]="title"
                        (change)="title = $event.target.value"
                    />
                </div>
            </div>

            <fx-checkbox class="document-preview-signing-checkbox" [checked]="agreed" (change)="agreed = !agreed">
                I am the party intended to sign the above document, or am authorised to sign it on their behalf. I
                acknowledge that the information in the above document is true and accurate. I agree or am authorised to
                agree to be bound by the terms referenced or contained in the above document. Together, this dialog box
                and the above document form the agreement.
            </fx-checkbox>

            <p *ngIf="errorMessage" class="document-preview-signing-modal-file-sign-error">{{ errorMessage }}</p>

            <button
                class="fx-btn document-preview-signing-accept-button"
                [ngClass]="['fx-btn--primary' + (theme.formSubmitActionOutlined ? '-outlined' : '')]"
                role="button"
                (click)="accept()"
            >
                I ACCEPT
            </button>
        </div>
    </div>
</ng-container>
<ng-template #loading>
    <div class="document-preview-loading">
        <loader-icon *ngIf="loading" [color]="'light'"></loader-icon>
    </div>
</ng-template>
