<div class="scorecard">
    <!-- <div class="scorecard-header">
        <div class="scorecard-header-select">
            <fx-select>
                <fx-option *ngFor="let entity of entities">{{ entity.label }}</fx-option>
            </fx-select>
        </div>
    </div> -->

    <fx-tabs class="scorecard-tabs" [mobileScroll]="true" #tabs>        
        <fx-tabs-item
            #scorecardTab
            header="FINANCIAL SCORECARD"
            [analyticsClick]="gaEvents.INSIGHTS_TABSCORECARD"
            [analyticsTriggerOn]="scorecardTab.active"
            (selected)="navigateTo('/insights')"
        ></fx-tabs-item>

        <fx-tabs-item
            #reportsTab
            header="MANAGEMENT REPORTS"
            [analyticsClick]="gaEvents.INSIGHTS_TABREPORTS"
            [analyticsTriggerOn]="reportsTab.active"
            (selected)="navigateTo('./reports')"
        ></fx-tabs-item>
    </fx-tabs>

    <div class="scorecard-tabs-container">
        <div class="scorecard-tabs-content">
            <router-outlet></router-outlet>
        </div>

        <loader-icon *ngIf="loader.counter" color="light"></loader-icon>
    </div>
</div>
