<div class="fx-onboarding-modal">
    <div class="fx-onboarding-modal-image">
        <img src="./assets/images/onboarding-modal/sigma-business-name.svg" />
    </div>
    <div class="fx-onboarding-modal-content">
        <h1 class="fx-onboarding-modal-title">Welcome to {{ appName }}</h1>
        <p class="fx-onboarding-body">
            Let our highly qualified team get to know your business a bit better ensuring we can provide the best
            possible support.
        </p>
        <div class="fx-onboarding-form fx-form fx-form--dark">
            <div class="fx-form-group">
                <div class="fx-form-control">
                    <input
                        class="fx-form-input"
                        id="name"
                        type="text"
                        placeholder="Business name"
                        aria-label="Business name"
                        [(ngModel)]="businessName"
                        (ngModelChange)="nameUpdated()"
                    />
                </div>
            </div>
            <div class="fx-form-group">
                <button
                    class="fx-btn fx-btn--full-width fx-btn--primary-outlined business-name-button"
                    [disabled]="!isValid"
                    (click)="progressState.emit()"
                    analyticsClick="onboarding_businessnamenext"
                >
                    Continue
                </button>
            </div>
        </div>
    </div>
</div>
