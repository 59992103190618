<div class="participant-list" [ngSwitch]="!!showLoader">
    <div *ngSwitchCase="true" class="participant-list-container participant-list-searching">
        <loader-icon color="light"></loader-icon>
    </div>
    <ng-container *ngSwitchDefault>
        <div class="participant-list-container" *ngFor="let participant of participants">
            <div class="participant-list-participant" (click)="selectParticipant.emit(participant)">
                <fx-avatar [name]="participant?.profile?.name" [image]="participant?.id | profilePicture"></fx-avatar>
                <div class="participant-list-user">
                    <div class="participant-list-user-name">
                        {{ participant?.profile?.name }}
                    </div>
                    <div class="participant-list-user-title">
                        {{ participant?.profile?.emailAddress }}
                    </div>
                </div>
            </div>
        </div>
        <div class="participant-list-container" *ngIf="participants?.length === 0">
            <div [ngSwitch]="showInvite">
                <div *ngSwitchCase="false" class="participant-list-participant">
                    0 search results
                </div>
                <div *ngSwitchDefault class="participant-list-invite">
                    <p class="no-matches">We couldn't find any matches</p>

                    <p>New to {{ appName }}? <button class="btn-invite" (click)="invite()">INVITE</button></p>
                </div>
            </div>
        </div>
    </ng-container>
</div>
