import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { IWorkflowStep, WorkflowStepType } from "@findex/threads";


export function dueDateValidator(workflowSteps: IWorkflowStep[]): ValidatorFn {
    return (rhythmDatesControl: AbstractControl): ValidationErrors | null => {
        const rhythmDates = rhythmDatesControl.value;
        if (!rhythmDates) return null;

        const inOrder = workflowSteps
            .filter(step => step.type === WorkflowStepType.OPEN)
            .every((step, i, allSteps) => {
                if (i === 0) return true;

                const currentDueDate = rhythmDates[step.clientFacingId];
                const previousDueDate = rhythmDates[allSteps[i - 1].clientFacingId];
                return currentDueDate >= previousDueDate;
            });

        if (inOrder) {
            return null;
        } else {
            return { datesOutOfOrder: { value: "Dates need to be in order" } }
        }
    };
}