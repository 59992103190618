import { Injectable } from "@angular/core";
import { INewsArticle } from "./INewsArticle";
import { IPortalNewsArticle } from "./IPortalNewsArticle";
import { INewsArticleMapper } from "./INewsArticleMapper";

@Injectable()
export class PortalNewsArticle implements INewsArticleMapper {
    constructor() {}

    map(response: any): INewsArticle[] {
        return response.data.findexCms.insightses.slice(0, 1).map((args: IPortalNewsArticle) => ({
            id: args.id,
            title: args.title,
            date: args.createdAt,
            author: args.adviser ? `${args.adviser.givenName} ${args.adviser.surname}` : "",
            body: args.body.html.split("</p><p>")[1],
            url: `${args.webAddress}`,
            bgUrl: `https://media.graphcms.com/${args.previewImage.handle}`
        }));
    }
}
