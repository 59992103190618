<div class="new-thread-modal">
    <multi-component-layout [enableBreadCrumb]="true" [enableProgressBar]="false" [enableClose]="false"
        [stepConfigurations]="stepConfigurations" (emitTransition)="handleTransition($event)" (emitClose)="close()"
        (emitComplete)="triggerThreadTransition()" [loading]="loader.counter !== 0"
        contentClass="multi-component-layout--new-timeline" [enableFooter]="true" [enableHeader]="true"
        [condenseBreadcrumb]="true">

        <div class="new-thread-header multi-component-layout-header">
            <div class="new-thread-header-close">
                <div class="fx-l-modal-header-icon" (click)="close()">
                    <i class="las la-times icon-size"></i>
                </div>
            </div>
        </div>

        <step-layout stepTitle="Select service">
            <form [formGroup]="form">
                <div modal-header class="new-thread-title">Select service</div>

                <div class="new-thread-type">
                    <div class="fx-form-group">
                        <label class="fx-form-label">Service</label>
                        <div class="fx-form-control">
                            <select placeholder="Select a service" class="fx-form-select" formControlName="threadType"
                                (change)="handleChange(true)">
                                <option [ngValue]="null" selected disabled hidden>Select a service</option>
                                <option *ngFor="let threadType of newThreadTypes" [ngValue]="threadType">
                                    {{ threadType.title }}
                                </option>
                            </select>

                        </div>
                    </div>
                </div>

                <select *ngIf="!!threadTypeControl.value?.requiresCustomMonth"
                    [formControl]="$any(form.get('currentMonth'))" class="fx-form-select new-thread-select" required>
                    <option *ngFor="let month of newThreadMonths" [selected]="form.value?.currentMonth === month"
                        [value]="month">
                        {{ month }}
                    </option>
                </select>

                <ng-container *ngIf="!!threadTypeControl.value?.requiresCustomTitle">
                    <div class="new-thread-title-input">
                        <span class="new-thread-title-input-prefix" matPrefix>{{form.get('threadType').value?.type}} - &nbsp; </span>
                        <input class="fx-form-input" placeholder="Timeline title..."
                            formControlName="customThreadTitle" (input)="handleChange(true)" required />
                    </div>

                    <div class="fx-form-validation" *ngIf="!form.valid">
                        Please enter a timeline title less than {{ CUSTOM_TITLE_MAX_LENGTH }} characters.
                        <em class="fx-form-validation-icon las la-exclamation-triangle"></em>
                    </div>
                    <br />
                </ng-container>

                <ng-container *ngIf="!!threadTypeControl.value?.requiresCustomYear && customYears">
                    <label class="fx-form-label">{{customYearsTitle}} year</label>
                    <select class="fx-form-select" formControlName="customFormYear" (change)="handleChange(true)">
                        <option *ngFor="let year of customYears">{{year}}</option>
                    </select>
                </ng-container>

                <ng-container *ngIf="!!threadTypeControl.value?.requiresCustomSubtypes">
                    <div class="new-thread-subtype">
                        <label class="fx-form-label">Subtype</label>
                        <select placeholder="Select a Subtype" class="fx-form-select"
                        formControlName="customSubtype"
                            (change)="handleChange(true)">
                            <option [ngValue]="null" selected disabled hidden>Select a Subtype</option>
                            <option *ngFor="let threadSubtype of newThreadSubtypes" [ngValue]="threadSubtype.type">
                                {{ threadSubtype.title }}
                            </option>
                        </select>
                    </div>
                </ng-container>

            </form>
        </step-layout>

        <step-layout stepTitle="Service information">
            <due-dates
                *ngIf="activeStepIndex === 1"
                [title]="threadTypeControl?.value?.title"
                [workflow]="workflow$ | async"
                [startDate]="startDate"
                (datesUpdated)="updateDueDates($event)">
            </due-dates>
        </step-layout>

    </multi-component-layout>
</div>
