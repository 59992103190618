import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FindexUiModule } from "../findex-ui";
import {
    NOTIFICATIONS_APPID,
    NOTIFICATIONS_WS_URL,
    NOTIFICATIONS_WS_HEADERS,
    NOTIFICATIONS_URL,
    NotificationsService,
    NotificationsModule as FindexNotificationsModule
} from "@findex/notifications-angular";
import { EnvironmentSpecificConfig } from "../environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";
import { AuthService } from "../findex-auth";
import { filter, switchMap } from "rxjs/operators";
import { CardReadStatusComponent } from "./components/card-read-status/card-read-status.component";
import { ListNotificationBannersComponent } from "./components/list-notification-banners/list-notification-banners.component";
import { NotificationBannerComponent } from "./components/notification-banner/notification-banner.component";
import { ThreadsUiPipesModule } from "../threads-ui/pipes/threads-ui-pipes.module";
import { SharedPipesModule } from "../shared/pipes/shared-pipes.module";
import { PluginsModule } from "../plugins";

const exportDeclarations = [
    CardReadStatusComponent,
    ListNotificationBannersComponent,
    NotificationBannerComponent
];

@NgModule({
    imports: [
        CommonModule, 
        FindexUiModule, 
        FindexNotificationsModule, 
        ThreadsUiPipesModule, 
        SharedPipesModule, 
        PluginsModule
    ],
    declarations: [...exportDeclarations],
    exports: [FindexNotificationsModule, ...exportDeclarations],
    providers: [
        {
            provide: NOTIFICATIONS_APPID,
            useFactory: (environment: EnvironmentSpecificConfig) => environment.appId,
            deps: [ENVIRONMENT]
        },
        {
            provide: NOTIFICATIONS_WS_URL,
            useFactory: (environment: EnvironmentSpecificConfig) => environment.notificationsEndpoints.websockets,
            deps: [ENVIRONMENT]
        },
        {
            provide: NOTIFICATIONS_WS_HEADERS,
            useFactory: (authService: AuthService) =>
                authService.getUser().pipe(
                    filter(user => !!user),
                    switchMap(() => authService.getVerifiedHttpHeaders())
                ),
            deps: [AuthService]
        },
        {
            provide: NOTIFICATIONS_URL,
            useFactory: (environment: EnvironmentSpecificConfig) => environment.notificationsEndpoints.base,
            deps: [ENVIRONMENT]
        },
        NotificationsService
    ]
})
export class NotificationsModule {}
