<ng-container *ngIf="article$ | async; else noArticles; let articles">
    <ng-container *ngIf="articles.length; else noArticles">
        <div class="news-view-all">
            <a analyticsClick="app_news" [href]="newsUrl" target="_blank" rel="noopener noreferrer">View All</a>
        </div>
        <div class="news-articles">
            <news-article-item *ngFor="let article of articles" [article]="article"></news-article-item>
        </div>
    </ng-container>
</ng-container>

<ng-template #noArticles>
    <ng-container *ngIf="!loader.counter">
        Sorry, there are no articles available.
    </ng-container>
</ng-template>

<loader-icon *ngIf="loader.counter" [color]="'light'"></loader-icon>
