import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { Observable, of } from "rxjs";
import { switchMap, mapTo } from "rxjs/operators";
import { AuthService } from "./auth.service";
import { AppUser, AuthStrategy } from "../model/AppUser";
import { LoginDialogComponent } from "../components/login-dialog/login-dialog.component";
import { InvitationAuthStrategy } from "./strategies/invitation-auth.strategy";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
    constructor(
        private authService: AuthService,
        private dialog: MatDialog,
        private router: Router,
        private invitationStrategy: InvitationAuthStrategy
    ) {}

    canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UrlTree | boolean> {
        return this.authService.getUserWithoutRole().pipe(
            switchMap(user => {
                // TODO: remove after we allow invitation users inside the platform
                if (user?.type === AuthStrategy.Invitation) {
                    try {
                        const invitationId = this.invitationStrategy.getActiveInvitationId();
                        return of(this.router.parseUrl(`/perform-action/${invitationId}`));
                    } catch (err) {
                        return of(this.router.parseUrl(`/login`));
                    }
                }
                if (state.url === "/register/signup") {
                    return this.loggedOutHandler(user);
                }
                if (state.url.startsWith("/register/verify-mobile")) {
                    return of(true);
                }
                return this.validOrAuth(user);
            })
        );
    }

    private loggedOutHandler(user: AppUser): Observable<UrlTree | boolean> {
        if (!user) {
            return of(true);
        } else if (user.mobileNumberVerified) {
                return of(this.router.parseUrl("/dashboard"));
            } else {
                return of(this.router.parseUrl("/register/verify-mobile"));
            }
    }

    private validOrAuth(user: AppUser): Observable<boolean | UrlTree> {
        if (user) {
            if (!user.mobileNumberVerified) {
                return of(this.router.parseUrl("/register/verify-mobile"));
            }
            return of(true);
        } else {
            const options = {
                disableClose: true,
                backdropClass: "modal-backdrop",
                panelClass: ["modal-container", "mat-dialog-no-styling"],
                maxWidth: "100%",
                minWidth: "100%",
                maxHeight: "100%",
                minHeight: "100%"
            };

            return this.dialog
                .open(LoginDialogComponent, options)
                .afterClosed()
                .pipe(mapTo(true));
        }
    }
}
