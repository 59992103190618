<ng-container *ngIf="card$ | async; let card">
    <app-card
        *ngIf="card.status === cardStatuses.Removed"
        [lineCard]="card.status === cardStatuses.Removed"
        [description]="'This message has been removed.'"
        [timestamp]="card.modifiedAt"
        [conversation]="false"
    >
    </app-card>

    <ng-container *ngIf="card.status !== cardStatuses.Removed && userId$ | async; let userId">
        <thread-card
            [title]="!!customCardTitle ? customCardTitle : 'Files'"
            [thread]="thread$ | async"
            [card]="card"
            [replies]="replies$ | async"
            [invertTheme]="userId === card.createdBy"
            [createdById]="card.createdBy"
            [edited]="card.status === cardStatuses.Edited"
            [edit]="edit$"
            (save)="save($event)"
            [loading]="!state?.groups?.length || !!loader.counter"
        >
            <button
                [analyticsClick]="gaEvents.APP_VIEWINSIGHTS"
                *ngIf="report?.fileId && report?.vaultId && !(role | permissions: 'CreateInsights' | async)"
                class="fx-btn fx-btn--primary-outlined rfi-view-insights"
                [routerLink]="['/insights/report', report.vaultId, report.fileId]"
            >
                <i class="las la-chart-bar"></i>
                VIEW INSIGHTS
            </button>

            <ng-container [ngSwitch]="vaultCardType">
                <div class="rfi-content" [ngClass]="{ 'rfi-content--inverted': userId === card.createdBy }">
                    <div class="attachments" *ngSwitchDefault>
                        <vault-attachments
                            *ngIf="state?.groups"
                            [groups]="state.groups"
                            [disabled]="card?.status === cardStatuses.Disabled"
                            [canSign]="role | permissions: 'SignDocumentsVaultCard' | async"
                            [editable]="edit$ | async"
                            [unsignedCount]="documentsUnsigned"
                            (download)="downloadItem($event)"
                            (preview)="openDocumentPreview($event)"
                            (sign)="signItem($event)"
                            (rename)="renameItem($event.item, $event.name)"
                            (delete)="deleteItem($event)"
                        ></vault-attachments>

                        <ng-container *ngIf="edit$ | async">
                            <file-input
                                [analyticsPrefix]="gaEventsPrefix.DOCUMENT_CARD"
                                [enableDirectoryDrop]="true"
                                (file)="uploadFile(card, $event)"
                            ></file-input>

                            <button (click)="edit$.next(false)" class="fx-btn fx-btn--primary-outlined">Done</button>
                        </ng-container>
                    </div>
                </div>
            </ng-container>

            <div card-actions *ngIf="allowEdit && (thread$ | async | isThreadActive | async )">
                <fx-context-menu
                    #menu
                    [gutter]="false"
                    *ngIf="
                        (card.createdBy === userId &&
                        (role | permissions: 'UpdateRequestOrVaultCard' | async)) ||
                        (role | permissions: 'ThreadUpdateAll' | async)
                    "
                >
                    <a [analyticsClick]="gaEvents.RFICARD_EDIT" (click)="editCard()">Edit</a>
                    <a
                        [analyticsClick]="gaEvents.RFICARD_REMOVE"
                        *ngIf="vaultCardType !== vaultCardTypes.RFI && !documentsRequireSignature"
                        (click)="removeMessage()"
                        >Remove</a
                    >
                    <a
                        [analyticsClick]="gaEvents.RFICARD_CANCEL"
                        *ngIf="
                            (vaultCardType === vaultCardTypes.RFI || documentsRequireSignature) &&
                            card?.status !== cardStatuses.Disabled
                        "
                        (click)="cancelCard()"
                        >Cancel</a
                    >
                </fx-context-menu>
            </div>
            <div card-loader>
                <loader-icon [color]="'light'"></loader-icon>
            </div>
            <div class="thread-card-error" *ngIf="errorMessage">
                {{ errorMessage }}
            </div>
        </thread-card>
    </ng-container>
</ng-container>
