<div class="card-read-status">
    <ng-container *ngIf="readStatus$ | async; let cardReadStatus">
        <span (click)="toggleExpandedNames()" [analyticsClick]="gaEvents.APP_SEENUNSEEN">
            <i
                *ngIf="isResolved$ | async; let isResolved; else: isNotResolved"
                class="card-read-status-icon las la-check-double"
            ></i>
            <ng-template #isNotResolved>
                <i class="card-read-status-icon las la-check"></i>
            </ng-template>

            <ng-container *ngIf="expandNames">
                <span class="card-read-status-text" [ngClass]="{ 'card-read-status-text-active': expandNames }">
                    <ng-container *ngIf="isResolved$ | async; else isNotResolved">Seen by everyone</ng-container>

                    <ng-template #isNotResolved>
                        <ng-container *ngIf="cardReadStatus.resolved.participantIds.length === 0; else seenBy">
                            Delivered
                        </ng-container>

                        <ng-template #seenBy>
                            Seen by
                            <ng-container
                                *ngFor="let participantId of cardReadStatus.resolved.participantIds; let index = index"
                            >
                                {{ participantId | userShortName | async
                                }}{{ index === cardReadStatus.resolved.participantIds.length - 1 ? "" : ", " }}
                            </ng-container>
                        </ng-template>
                    </ng-template>
                </span>
            </ng-container>
        </span>
    </ng-container>
</div>
