import { CognitoUser, CognitoUserSession, IMfaSettings, CognitoUserAttribute } from "amazon-cognito-identity-js";

export enum SMS_CHALLENGE {
    CODE_DELIVERY_DELIVERY_MEDIUM = "string",
    CODE_DELIVERY_DESTINATION = "string" //masked phone number +*******7315
}

interface CognitoUserWithDeviceKey extends CognitoUser {
    deviceKey: string;
}

function hasDeviceKey(cognitoUser: any): cognitoUser is CognitoUserWithDeviceKey {
    return cognitoUser.deviceKey !== undefined;
}

export const refreshSession = (cognitoUser: CognitoUser): Promise<CognitoUserSession> =>
    new Promise((resolve, reject) => {
        const currentSession = cognitoUser.getSignInUserSession();
        cognitoUser.refreshSession(currentSession.getRefreshToken(), (err, result) => {
            if (err) return reject(err);
            return resolve(result);
        });
    });

export const getUserAttributes = (cognitoUser: CognitoUser): Promise<CognitoUserAttribute[]> =>
    new Promise((resolve, reject) => {
        cognitoUser.getUserAttributes((err, result) => {
            if (err) return reject(err);
            return resolve(result);
        });
    });

export const logout = (cognitoUser: CognitoUser) =>
    new Promise((resolve, reject) => {
        cognitoUser.globalSignOut({
            onSuccess: result => resolve(result),
            onFailure: err => reject(err)
        });
    });

export const sendCustomChallengeAnswer = (cognitoUser: CognitoUser, answerChallenge: string) =>
    new Promise((resolve, reject) => {
        cognitoUser.sendCustomChallengeAnswer(answerChallenge, {
            onFailure: err => reject(err),
            onSuccess: result => resolve(result)
        });
    });

export const sendMFACode = (cognitoUser: CognitoUser, code: string): Promise<CognitoUserSession> =>
    new Promise((resolve, reject) => {
        cognitoUser.sendMFACode(code, {
            onSuccess: result => {
                resolve(result);
            },
            onFailure: result => {
                reject(result);
            }
        });
    });

export const setDeviceStatusRemembered = (cognitoUser: CognitoUser) =>
    new Promise((resolve, reject) => {
        cognitoUser.setDeviceStatusRemembered({
            onSuccess: result => resolve(result),
            onFailure: result => reject(result)
        });
    });

export const setDeviceStatusNotRemembered = (cognitoUser: CognitoUser) =>
    new Promise((resolve, reject) => {
        cognitoUser.setDeviceStatusNotRemembered({
            onSuccess: result => resolve(result),
            onFailure: err => reject(err)
        });
    });

export const enableMFA = (cognitoUser: CognitoUser) =>
    new Promise((resolve, reject) => {
        cognitoUser.enableMFA((err, result) => {
            if (err) return reject(err);
            return resolve(result);
        });
    });

export const setUserMfaPreference = (cognitoUser: CognitoUser, smsMfa: IMfaSettings, softwareTokenMfa: IMfaSettings) =>
    new Promise((resolve, reject) => {
        cognitoUser.setUserMfaPreference(smsMfa, softwareTokenMfa, (err, result) => {
            if (err) return reject(err);
            return resolve(result);
        });
    });

export const getMFAOptions = (cognitoUser: CognitoUser) =>
    new Promise((resolve, reject) => {
        cognitoUser.getMFAOptions((err, result) => {
            if (err) return reject(err);
            return resolve(result);
        });
    });

export const listDevices = (cognitoUser: CognitoUser, limit: number, paginationToken: string) =>
    new Promise((resolve, reject) => {
        cognitoUser.listDevices(limit, paginationToken, {
            onFailure: err => reject(err),
            onSuccess: result => resolve(result)
        });
    });

export const rememberDevice = (cognitoUser: CognitoUser) => {
    if (hasDeviceKey(cognitoUser)) {
        return setDeviceStatusRemembered(cognitoUser);
    }

    return Promise.resolve("no device found");
};

export const forgetDevice = (cognitoUser: CognitoUserWithDeviceKey) => {
    if (hasDeviceKey(cognitoUser)) {
        return setDeviceStatusNotRemembered(cognitoUser);
    }

    return Promise.resolve("no device found");
};
