import { Component, Input, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { ThreadsService } from "../../services/threads.service";
import { ToastService } from "../../services/toast.service";
import { Role } from "@findex/threads";
import { AuthService } from "../../../findex-auth";

@Component({
    selector: "lib-edit-title",
    templateUrl: "./edit-title.component.html",
    styleUrls: ["./edit-title.component.scss"],
})
export class EditTitleComponent implements OnInit {
    @Input() title: string = undefined;
    @Input() threadId: string = undefined;

    role: Role;
    editMode: boolean;
    showLoader: boolean;
    formEditControl: FormControl = new FormControl("", [Validators.required]);

    constructor(
        private threadsService: ThreadsService,
        private toast: ToastService,
        private authService: AuthService
    ) {}

    async ngOnInit(): Promise<void> {
        this.role = await this.authService.getGlobalRole().toPromise();
    }

    toggleEditMode(value: boolean): void {
        this.editMode = value;
        if(!value) {
            this.formEditControl.reset()
        }
    }

    async changeTitle(newTitle: string): Promise<void> {
        try {
            this.showLoader = true;
            await this.threadsService.updateThread(this.threadId, { title: newTitle }).toPromise();

            this.editMode = false;
        } catch (err) {
            this.toast.openPromptDialog("Failed to update title", "OK");
        }
        this.showLoader = false;
    }
}
