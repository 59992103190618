<loader-icon *ngIf="showLoader" [color]="'light'"></loader-icon>
<div class="fx-onboarding fx-onboarding-bg-verify-mobile">
    <div class="fx-onboarding-container">
        <div class="fx-onboarding-content two-factor">
            <portal-brand type="onboarding"></portal-brand>
            <h1 class="fx-onboarding-title">Verify your identity</h1>
            <p class="fx-onboarding-body">
                Please enter the code we sent to your mobile.
            </p>
            <form class="fx-onboarding-form fx-form fx-form--dark" [formGroup]="form">
                <div class="fx-form-group" *ngIf="errorMessage">
                    <div class="fx-form-validation-panel">
                        <div class="fx-form-validation-panel-item fx-onboarding-form-error">{{ errorMessage }}</div>
                    </div>
                </div>
                <div class="fx-form-group" *ngIf="successMessage">
                    <div class="fx-form-validation-panel">
                        <div class="fx-form-validation-panel-item fx-onboarding-form-success">{{ successMessage }}</div>
                    </div>
                </div>
                <div class="fx-form-group">
                    <label class="fx-form-label" for="verifyCode">Pin</label>
                    <div class="fx-form-control fx-form-control--inline-button">
                        <input
                            autocomplete="one-time-code"
                            formControlName="verifyCode"
                            class="fx-form-input"
                            name="verifyCode"
                            placeholder="PIN"
                            type="text"
                            fxAutoFocus
                            tabindex="5"
                            [ngClass]="{
                                'fx-form-input--error':
                                    !form.controls['verifyCode'].valid && form.controls['verifyCode'].touched
                            }"
                        />
                    </div>
                    <button
                        class="fx-form-group-action"
                        type="button"
                        [disabled]="disableResend$ | async"
                        (click)="rerequestMFACode()"
                        analyticsClick="login_verifyresend"
                    >
                        Resend PIN
                    </button>
                    <div class="fx-form-validation" *ngIf="form.controls['verifyCode'].hasError('pattern')">
                        Verification code must be 6 numeric characters.
                        <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                    </div>
                </div>
                <div class="fx-form-group">
                    <fx-checkbox [checked]="rememberDevice" (change)="toggleRememberDevice($event)">
                        Remember me to skip this step on this device
                    </fx-checkbox>
                </div>
                <div class="fx-form-group">
                    <button
                        class="fx-btn fx-btn--full-width"
                        [ngClass]="['fx-btn--primary' + (theme.formSubmitActionOutlined ? '-outlined' : '')]"
                        [disabled]="form.controls.verifyCode.valid === false"
                        (click)="submitCode()"
                        tabindex="6"
                        [analyticsClick]="['login_mobilerememberme','Login2FACompleteEvent']"
                    >
                        CONTINUE
                    </button>
                </div>
                <p class="fx-onboarding-body" *ngIf="supportEmail">
                    <a href="mailto:{{ supportEmail }}" tabindex="7" analyticsClick="login_mobilesupport">Click here</a>
                    to contact support if you do not receive a security code via SMS.
                </p>
            </form>
        </div>
    </div>
</div>
