import { Directive, TemplateRef } from "@angular/core";

@Directive({
    selector: "[fxRowChild]"
})
export class FxRowChildDirective {
    constructor(public template: TemplateRef<any>) {
        
    }
}
