import { Component, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { IThreadWorkflowState, IThreadWorkflowStep, Role, WorkflowStepType } from "@findex/threads";
import { ENVIRONMENT } from "src/app/injection-token";
import { EnvironmentSpecificConfig } from "../../../../environment/environment.common";
import { DefaultPermissions, PermissionService } from "../../../services/permissions.service";
import { WorkflowStepsService } from "../../../services/workflow-steps.service";
import { IWorkflowStepUI } from "../../../interfaces/IWorkflowStepUI";
import { CheckboxComponent } from "@findex/fx-ui";
@Component({
    selector: "workflow-step-list",
    templateUrl: "./workflow-step-list.component.html",
    styleUrls: ["./workflow-step-list.component.scss"]
})
export class WorkflowStepListComponent implements OnChanges {
    @Input() workflowState: IThreadWorkflowState;
    @Input() role: Role;
    @Output() save = new EventEmitter<IThreadWorkflowStep>();
    @Output() editDates = new EventEmitter<void>();

    readonly stepTypes = WorkflowStepType;
    readonly theme = this.environment.theme;

    stepEntries: IWorkflowStepUI[] = [];
    selectedStep: IThreadWorkflowStep;
    stateChanged = false;
    hasDueDates: boolean;

    roles = Role;

    constructor(
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private permissionService: PermissionService,
        private workflowStepsService: WorkflowStepsService
    ) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.workflowState) {
            this.selectedStep = this.workflowState.steps.find(step => step.isCurrentStep) ?? this.workflowState.steps[0];
            this.stepEntries = this.workflowStepsService.getUpdatedStepEntries(this.workflowState.steps);
            this.hasDueDates = this.workflowState.steps?.some(step => !!step.timeEstimate);
        }
    }

    saveState() {
        this.save.emit(this.selectedStep);
        this.stateChanged = false;
    }

    async selectStepAt(selectedIndex: number, checkbox: CheckboxComponent) {
        const hasPermission = await this.permissionService
            .checkPermissions(this.role, DefaultPermissions.UpdateInternalWorkflow)
            .toPromise();

        if (!hasPermission) {
            return;
        }

        this.stateChanged = true;

        const steps = this.workflowState.steps;

        this.workflowState.steps = this.workflowStepsService.updateSteps(steps, selectedIndex);
        this.stepEntries = this.workflowStepsService.getUpdatedStepEntries(this.workflowState.steps);
        this.selectedStep = this.workflowState.steps.find(step => step.isCurrentStep) ?? this.workflowState.steps[0];
        checkbox.isChecked = false; //FxUi checkbox limition hack, not easy to resolve
    }

    trackEntry(_index: number, entry: IWorkflowStepUI): string|IWorkflowStepUI {
        return entry?.step?.clientFacingId || entry;
    }

}
