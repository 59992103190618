import { Component, EventEmitter, Input, Output, OnInit } from "@angular/core";
import { Role } from "@findex/threads";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { Observable } from "rxjs";
import { VideoChatService } from "../../services/video-chat.service";

@Component({
    selector: "meeting-details",
    templateUrl: "./meeting-details.component.html",
    styleUrls: ["./meeting-details.component.scss"]
})
export class MeetingDetailsComponent implements OnInit {
    readonly gaEvents = GA_EVENTS;
    readonly roles = Role;

    @Input() status: string;
    @Input() joinDisabled: boolean;
    @Input() sessionTerminated: boolean;
    @Input() role: Role;
    @Input() showButtons: boolean;
    isUserJoined$: Observable<boolean>;

    @Output() terminate = new EventEmitter();
    @Output() join = new EventEmitter();

    constructor(private videoChatService: VideoChatService) {}

    ngOnInit() {
        this.isUserJoined$ = this.videoChatService.sharedIsUserInMeeting$;
    }

    terminateSession() {
        this.terminate.emit();
    }
}
