import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NgxDropzoneModule } from "ngx-dropzone";
import { AnalyticsModule } from "projects/portal-modules/src/lib/analytics";
import { FileInputComponent } from "./file-input.component";

@NgModule({
    imports: [CommonModule, NgxDropzoneModule, AnalyticsModule],
    declarations: [FileInputComponent],
    exports: [FileInputComponent]
})
export class FileInputModule {}
