import { Component, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";

type IndustryOption = { name?: string; value: string; selected: boolean };

const isSelected = industryType => industryType.selected;

@Component({
    selector: "app-industry-type-selection",
    templateUrl: "./industry-type-selection.component.html",
    styleUrls: ["./industry-type-selection.component.scss"]
})
export class IndustryTypeSelectionComponent implements OnChanges {
    @Input() selectedIndustryTypes: string[] = [];
    @Output() modelValidated: EventEmitter<boolean> = new EventEmitter();
    @Output() modelUpdated: EventEmitter<any> = new EventEmitter();
    @Input() maxHeight: string;
    @Input() minHeight: string;

    filter = "";
    industryTypes: IndustryOption[];
    filteredIndustryTypes: IndustryOption[];

    constructor(@Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig) {
        const businessIndustryOptions: IndustryOption[] = this.environment.featureFlags.onboardingConfiguration.selectIndustry.tiles.map(
            option => ({
                ...option,
                selected: false
            })
        );
        this.industryTypes = [...businessIndustryOptions].sort((left, right) => left.name.localeCompare(right.name));
        this.filteredIndustryTypes = this.industryTypes;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedIndustryTypes) {
            this.industryTypes.map(industryType => {
                industryType.selected = changes.selectedIndustryTypes.currentValue.some(
                    selectedType => selectedType === industryType.value
                );
            });
            this.updateValidationState();
        }
    }

    updateParentModel() {
        const selectedIndustryTypeNames = this.industryTypes.filter(isSelected).map(industryType => industryType.value);
        this.modelUpdated.emit(selectedIndustryTypeNames);
        this.updateValidationState();
    }

    updateValidationState() {
        if (this.industryTypes.some(isSelected)) {
            this.modelValidated.emit(true);
        } else {
            this.modelValidated.emit(false);
        }
    }

    searchUpdated() {
        if (!this.filter || this.filter.trim() === "") {
            this.filteredIndustryTypes = this.industryTypes;
        } else {
            this.filteredIndustryTypes = this.industryTypes.filter(industryType =>
                industryType.name.toLowerCase().includes(this.filter.toLowerCase())
            );
        }
    }

    selectionToggled(option: IndustryOption) {
        this.industryTypes.map(industryType => {
            if (option === industryType) {
                option.selected = !option.selected;
            } else {
                industryType.selected = false;
            }
        });
        this.updateParentModel();
    }
}
