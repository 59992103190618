import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { UserProfileRoutingModule } from "./user-profile-routing.module";
import { FindexUiModule } from "../findex-ui";
import { FindexAuthModule } from "../findex-auth";
import { ThreadsUiModule } from "../threads-ui";
import { UserSettingsComponent } from "./components/user-settings/user-settings.component";
import { UserProfileComponent } from "./components/user-profile/user-profile.component";
import { OnboardingModule } from "../onboarding/onboarding.module";
import { FxUiModule } from "@findex/fx-ui";

import { ChangePasswordDialogComponent } from "./components/change-password-dialog/change-password-dialog.component";
import { ChangePasswordFormComponent } from "./components/change-password-form/change-password-form.component";
import { ConfirmEmailComponent } from "./components/confirm-email/confirm-email.component";
import { AdminSetPasswordDialogComponent } from "./components/admin-set-password-dialog/admin-set-password-dialog";
import { SharedModule } from "../shared";
import { UserProfileExtensionDirective } from "./components/user-profile/user-profile-extension.directive";

import { GeneralSettingsComponent } from "./components/user-profile/general-settings/general-settings.component";
import { InsightsPermissionsComponent } from "./components/user-profile/insights-permissions/insights-permissions.component";
import { EnterprisePermissionsComponent } from "./components/user-profile/enterprise-permissions/enterprise-permissions.component";
import { AddEnterpriseDialogComponent } from "./components/user-profile/add-enterprise-dialog/add-enterprise-dialog.component";
import { OpenMeasuresService } from "projects/default-plugins/insights/services/open-measures.service";

const angularImports = [CommonModule, FormsModule, FindexAuthModule];

@NgModule({
    imports: [
        ...angularImports,
        FindexUiModule,
        ThreadsUiModule,
        UserProfileRoutingModule,
        OnboardingModule,
        FxUiModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule
    ],
    declarations: [
        UserSettingsComponent,
        UserProfileComponent,
        UserProfileExtensionDirective,
        ChangePasswordDialogComponent,
        ChangePasswordFormComponent,
        ConfirmEmailComponent,
        AdminSetPasswordDialogComponent,
        GeneralSettingsComponent,

        //MOVE TO INSIGHTS PLUGIN
        InsightsPermissionsComponent,
        EnterprisePermissionsComponent,
        AddEnterpriseDialogComponent
    ],
    exports: [],
    providers: [
        OpenMeasuresService
    ]
})
export class UserProfileModule {}
