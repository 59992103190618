<loader-icon *ngIf="loader.counter" color="light"></loader-icon>
<div class="fx-onboarding fx-onboarding-bg-login">
    <div class="fx-onboarding-container">
        <div class="fx-onboarding-content">
            <portal-brand type="onboarding"></portal-brand>
            <h1 class="fx-onboarding-title">{{ textResource.loginTitle || textResource.default.loginTitle }}</h1>
            <div class="user-message" *ngIf="loginMessage">{{ loginMessage }}</div>
            <form class="fx-onboarding-form fx-form fx-form--dark" [formGroup]="form" (submit)="login()">
                <div class="fx-form-group fx-onboarding-form-error" *ngIf="errorMessage">
                    <div class="fx-form-validation-panel">
                        <div class="fx-form-validation-panel-item">{{ errorMessage }}</div>
                    </div>
                </div>
                <div class="fx-form-group">
                    <label class="fx-form-label" for="emailAddress">Email address</label>
                    <div class="fx-form-control">
                        <input
                            formControlName="emailAddress"
                            id="emailAddress"
                            class="fx-form-input"
                            type="email"
                            placeholder="john.doe@findex.com.au"
                            aria-label="Email"
                            fxAutoFocus
                            tabindex="1"
                            [ngClass]="{
                                'fx-form-input--error':
                                    !form.controls['emailAddress'].valid && form.controls['emailAddress'].dirty
                            }"
                        />
                    </div>
                    <ng-container
                        *ngIf="
                            form.controls['emailAddress'].dirty &&
                                (form.controls['emailAddress'].hasError('required') ||
                                    (form.controls['emailAddress'].hasError('email') &&
                                        !form.controls['emailAddress'].valid));
                            else errorSpacer
                        "
                    >
                        <div *ngIf="form.controls['emailAddress'].hasError('required')" class="fx-form-validation">
                            Email is required.
                            <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                        </div>
                        <div
                            *ngIf="
                                form.controls['emailAddress'].hasError('email') && !form.controls['emailAddress'].valid
                            "
                            class="fx-form-validation"
                        >
                            Email is invalid.
                            <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                        </div>
                    </ng-container>
                </div>
                <div class="fx-form-group">
                    <label class="fx-form-label" for="password">Password</label>
                    <div class="fx-form-control">
                        <input
                            formControlName="password"
                            id="password"
                            class="fx-form-input"
                            type="password"
                            placeholder="Password"
                            aria-label="Password"
                            togglePassword
                            [showClass]="'fx-form-icon fx-form-icon--right las la-eye-slash'"
                            [hideClass]="'fx-form-icon fx-form-icon--right las la-eye'"
                            tabindex="2"
                            [ngClass]="{
                                'fx-form-input--error':
                                    !form.controls['password'].valid && form.controls['password'].dirty
                            }"
                        />
                    </div>
                    <ng-container
                        *ngIf="
                            form.controls['password'].dirty &&
                                (form.controls['password'].hasError('required') || !form.controls['password'].valid);
                            else errorSpacer
                        "
                    >
                        <div *ngIf="form.controls['password'].hasError('required')" class="fx-form-validation">
                            Password is required.
                            <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                        </div>
                        <div
                            *ngIf="!form.controls['password'].hasError('required') && !form.controls['password'].valid"
                            class="fx-form-validation"
                        >
                            Password is invalid.
                            <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                        </div>
                    </ng-container>
                    <a
                        class="fx-form-group-action"
                        role="button"
                        (click)="showForgotPassword($event)"
                        analyticsClick="login_forgotpassword"
                        tabindex="4"
                        >Forgot password?</a
                    >
                </div>

                <div class="fx-form-group">
                    <div *ngIf="showAgreePoliciesLogin" class="fx-onboarding-footer">
                        By clicking '{{ textResource.loginAction || textResource.default.loginAction }}' you accept
                        <br />{{ appName }}'{{ appName[appName.length - 1] === "s" ? "" : "s" }}
                        <a
                            *ngIf="termsUrl"
                            [href]="termsUrl"
                            target="_blank"
                            rel="noopener noreferrer"
                            class="fx-form-disclaimer-link"
                            role="button"
                            >Terms</a
                        >
                        <ng-container *ngIf="termsUrl && policyUrl"> &amp; </ng-container>
                        <a
                            *ngIf="policyUrl"
                            [href]="policyUrl"
                            target="_blank"
                            rel="noopener noreferrer"
                            class="fx-form-disclaimer-link"
                            role="button"
                            >Privacy Policy</a
                        >
                        <ng-container *ngIf="(termsUrl || policyUrl) && termsOfUseUrl"> &amp; </ng-container>
                        <a
                            *ngIf="termsOfUseUrl"
                            [href]="termsOfUseUrl"
                            target="_blank"
                            rel="noopener noreferrer"
                            class="fx-form-disclaimer-link"
                            role="button"
                            >Terms of Use</a
                        >
                        <ng-container *ngIf="(termsUrl || policyUrl || termsOfUseUrl) && disclaimerUrl">
                            &amp;
                        </ng-container>
                        <a
                            *ngIf="disclaimerUrl"
                            [href]="disclaimerUrl"
                            target="_blank"
                            rel="noopener noreferrer"
                            class="fx-form-disclaimer-link"
                            role="button"
                            >Disclaimer and Disclosure</a
                        >
                        <ng-container
                            *ngIf="(termsUrl || policyUrl || termsOfUseUrl || disclaimerUrl) && emailDisclaimerUrl"
                        >
                            &amp;
                        </ng-container>
                        <a
                            *ngIf="emailDisclaimerUrl"
                            [href]="emailDisclaimerUrl"
                            target="_blank"
                            rel="noopener noreferrer"
                            class="fx-form-disclaimer-link"
                            role="button"
                            >Email Disclaimer</a
                        >
                    </div>
                    <button
                        class="fx-onboarding-submit fx-onboarding-button fx-btn"
                        [ngClass]="['fx-btn--primary' + (theme.formSubmitActionOutlined ? '-outlined' : '')]"
                        [disabled]="!form.valid"
                        type="submit"
                        [analyticsClick]="['login_login','LoginCompleteEvent']"
                    >
                        {{ textResource.loginAction || textResource.default.loginAction }}
                    </button>
                </div>
            </form>
            <div class="fx-onboarding-actions" *ngIf="showSignup">
                Don't have an account?
                <a
                    role="button"
                    analyticsClick="login_signup"
                    [routerLink]="['/register']"
                    [queryParams]="{ emailAddress: form.controls['emailAddress'].value }"
                    (click)="loginCancelled()"
                    >Create account</a
                >
            </div>

            <div class="fx-onboarding-actions staff-login">
                Not a client? Login as
                <a role="button" analyticsClick="login_staff" (click)="loginAsStaff()">Staff</a>
            </div>
        </div>
        <login-footer></login-footer>
    </div>
</div>

<ng-template #errorSpacer>
    <div class="error-spacer"></div>
</ng-template>
