import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { PaymentMethodTypes, IPaymentMethod, AcceptedCurrencies } from "@findex/payments-service-sdk";
import {
    loadStripe,
    Stripe,
    StripeCardCvcElement,
    StripeCardExpiryElement,
    StripeCardNumberElement,
    StripeElements
} from "@stripe/stripe-js";
import { IPaymentInvoiceDetails } from "@findex/threads";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";
import { environmentCommon } from "src/environments/environment";
import { PaymentService } from "../../services/payment.service";

@Component({
    selector: "app-edit-billing-details",
    templateUrl: "./edit-billing-details.component.html",
    styleUrls: ["./edit-billing-details.component.scss"]
})
export class EditBillingDetailsComponent implements OnInit {
    form = new FormGroup({
        name: new FormControl("", [Validators.required]),
        address: new FormControl("", [Validators.required]),
        city: new FormControl("", [Validators.required]),
        postcode: new FormControl("", [Validators.required]),
        country: new FormControl(null, [Validators.required]),
        state: new FormControl("", [Validators.required]),
        setAsDefault: new FormControl(false)
    });
    cardNumber: StripeCardNumberElement;
    expiryDate: StripeCardExpiryElement;
    cvcNumber: StripeCardCvcElement;

    stripe: Stripe;
    elements: StripeElements;

    readonly theme = this.environment.theme;
    errorMessage: string;
    errorState = {
        cardNumber: {
            message: ""
        },
        expiryDate: {
            message: ""
        },
        cvcNumber: {
            message: ""
        }
    };
    acceptedCountries = environmentCommon.acceptedCountries;
    loading: boolean;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: { customerId: string; paymentMethods: IPaymentMethod[]; currency: AcceptedCurrencies },
        private dialogRef: MatDialogRef<EditBillingDetailsComponent>,
        private paymentService: PaymentService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig
    ) {}
    private createStripeElements() {
        this.cardNumber = this.elements.create("cardNumber", {
            iconStyle: "solid",
            showIcon: true,
            style: environmentCommon.paymentElementStyle
        });
        this.expiryDate = this.elements.create("cardExpiry", { style: environmentCommon.paymentElementStyle });
        this.cvcNumber = this.elements.create("cardCvc", { style: environmentCommon.paymentElementStyle });
    }

    private mountStripeElements() {
        this.cardNumber.mount("#card-number");
        this.expiryDate.mount("#card-expiry");
        this.cvcNumber.mount("#card-cvc");
    }

    private addStripeElementEvents() {
        this.cardNumber.on("change", event => {
            if (event.error) {
                this.errorState.cardNumber.message = event.error.message;
            }
            if (event.complete) {
                this.errorState.cardNumber.message = "";
            }
        });
        this.expiryDate.on("change", event => {
            if (event.error) {
                this.errorState.expiryDate.message = event.error.message;
            }
            if (event.complete) {
                this.errorState.expiryDate.message = "";
            }
        });
        this.cvcNumber.on("change", event => {
            if (event.error) {
                this.errorState.cvcNumber.message = event.error.message;
            }
            if (event.complete) {
                this.errorState.cvcNumber.message = "";
            }
        });
    }

    private async initStripeCardElements() {
        this.stripe = await loadStripe(this.environment.payments.publishableApiKey);
        this.elements = this.stripe.elements({
            fonts: [
                {
                    cssSrc: "https://fonts.googleapis.com/css?family=Lato:300,400,600|Philosopher:400,700&display=swap"
                }
            ]
        });
        this.createStripeElements();
        this.mountStripeElements();
        this.addStripeElementEvents();
    }

    async ngOnInit() {
        await this.initStripeCardElements();
    }

    private buildCardData(): {
        address_line1: string;
        address_country: string;
        name: string;
        address_state: string;
        address_zip: string;
        address_city: string;
    } {
        return {
            name: this.form.controls.name.value,
            address_line1: this.form.controls.address.value,
            address_city: this.form.controls.city.value,
            address_country: this.form.controls.country.value,
            address_state: this.form.controls.state.value,
            address_zip: this.form.controls.postcode.value
        };
    }
    close() {
        this.dialogRef.close(false);
    }
    private buildAddressData(): IPaymentInvoiceDetails {
        return {
            name: this.form.controls.name.value,
            address: {
                line1: this.form.controls.address.value,
                city: this.form.controls.city.value,
                state: this.form.controls.state.value,
                country: "Australia", //TODO support NZ
                postal_code: this.form.controls.postcode.value
            }
        };
    }
    async update() {
        const setAsDefault = this.form?.get("setAsDefault")?.value || false;
        this.loading = true;
        try {
            const cardData = this.buildCardData();
            const cardToken = await this.stripe.createToken(this.cardNumber, cardData);
            if (cardToken.error) {
                this.errorMessage = cardToken.error.message;
                return;
            }

            const addressData = this.buildAddressData();
            const setupIntent = await this.paymentService.setupPaymentMethod(
                cardToken.token.id,
                PaymentMethodTypes.Card,
                addressData,
                this.data.currency,
                undefined,
                this.data.customerId
            );
            if (setupIntent.status !== "succeeded") {
                const cardSetup = await this.stripe.confirmCardSetup(
                    setupIntent.client_secret,
                    {
                        payment_method: setupIntent.payment_method as string
                    },
                    {
                        handleActions: true
                    }
                );
                if (cardSetup.error) {
                    this.errorMessage = cardSetup.error.message;
                    return;
                }
            }
            if (setAsDefault) {
                await this.paymentService
                    .setDefaultPaymentMethod(this.data.customerId, setupIntent.payment_method as string)
                    .toPromise();
                this.dialogRef.close(true);
            }
        } catch (err) {
            this.errorMessage = err.message || "Sorry, something went wrong";
        } finally {
            this.loading = false;
        }
    }
}
