import { Component, EventEmitter, Input, Output } from "@angular/core";
import { HOT_JAR_EVENTS } from "projects/portal-modules/src/lib/analytics";
/**
 * USAGE NOTES:
 * <centered-modal-layout (save)="()" (close)="()" [disableSave]="boolean" [loading]="boolean" [cancelButtonTitle]="string" [saveButtonTitle]="string" analyticsPrefix="string">
        <div modal-header></div>
        <div modal-subhead></div>
        <div modal-content></div>
    </centered-modal-layout>
 */
@Component({
    selector: "centered-modal-layout",
    templateUrl: "./centered-modal-layout.component.html",
    styleUrls: ["./centered-modal-layout.component.scss"]
})
export class CenteredModalLayoutComponent {
    @Input() loading = false;
    @Input() disableSave = false;
    @Input() cancelButtonTitle = "Cancel";
    @Input() saveButtonTitle = "Save";
    @Input() error: string;
    @Input() analyticsPrefix = "modal";
    @Input() analyticsPostfix = "_save-button";
    @Input() contentMaxHeight?: string = 'auto';

    @Output() close = new EventEmitter();
    @Output() save = new EventEmitter();

    readonly hotJarEvents = HOT_JAR_EVENTS;

    cancelButtonAnalyticsCode: string[];
    closeButtonAnalyticsCode: string[];

    constructor() {
        this.setAnalyticCodes();
    }

    setAnalyticCodes(): void {
        this.cancelButtonAnalyticsCode = [
            `${this.analyticsPrefix}_cancel-button`,
            this.hotJarEvents.RFIResponseCloseEvent
        ]
        this.closeButtonAnalyticsCode = [
            `${this.analyticsPrefix}_close-button`,
            this.hotJarEvents.RFIResponseCloseEvent
        ]
    }
}
