import { Injectable } from "@angular/core";
import { Observable, of, ObservableInput } from "rxjs";
import { tap, switchMap, finalize } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class Loader {
    public counter = 0;

    show() {
        Promise.resolve().then(() => this.counter++);
    }

    hide() {
        Promise.resolve().then(() => {
            this.counter--;
            if (this.counter < 0) {
                console.info("Hiding loader too many times");
                this.counter = 0;
            }
        });
    }

    wrap<T>(observable: ObservableInput<T>): Observable<T> {
        return of(null).pipe(
            tap(() => this.show()),
            switchMap(() => observable),
            finalize(() => this.hide())
        );
    }

    toggleVisibility(showLoader: boolean): void {
        if (showLoader === true) {
            this.show();
        } else {
            this.hide();
        }
    }
}
