<loader-icon *ngIf="loader.counter" color="light"></loader-icon>

<ng-container
    *ngIf="
        { documents: documents$ | async, role: role$ | async, thread: thread$ | async, userId: userId$ | async };
        let context
    "
>
    <div class="documents">
        <div class="documents-empty" *ngIf="context.documents?.length === 0">
            <span>There are no files in this timeline</span>
        </div>

        <div *ngIf="tableData.data.length">
            <fx-table
                [tableData]="tableData"
                [selectable]="true"
                (selected)="selectedDocuments = $event"
                [analyticsPrefix]="gaEventsPrefix.DOCUMENTS"
            >
                <ng-container
                    *fxColumn="
                        'title';
                        label: 'Title';
                        sortable: true;
                        mobileCollapse: true;
                        mobileHideLabel: true;
                        let document
                    "
                >
                    <div class="document-col" [ngSwitch]="document.renameMode && !document.isRfi">
                        <div *ngSwitchCase="true">
                            <inline-edit
                                [text]="document.title"
                                (updated)="renameDocument(context.thread.id, document, $event)"
                            ></inline-edit>
                        </div>
                        <div *ngSwitchDefault class="document-col-file-container" (click)="download(document)" [analyticsClick]="hotJarEvents.FileDownloadTabEvent">
                            <span class="document-col-file-container-title">
                                <i class="las la-chart-bar" *ngIf="document.category === 'ManagementReport'"></i>
                                <i class="las la-list-alt" *ngIf="document.isRfi"></i>
                                {{ document.title }}
                            </span>
                        </div>
                    </div>
                </ng-container>

                <ng-container
                    *fxColumn="'actorId'; label: 'Uploaded by'; sortable: true; mobileCollapse: true; let document"
                >
                    <div class="document-user">
                        <fx-avatar
                            class="document-user-avatar"
                            [name]="document.actorId | userToName | async"
                            [image]="document.actorId | profilePicture"
                            size="small"
                        ></fx-avatar>
                        <div class="document-user-owner">
                            {{ document.actorId | userToName | async }}
                        </div>
                    </div>
                </ng-container>

                <ng-container
                    *fxColumn="'timestamp'; label: 'Date added'; sortable: true; mobileCollapse: true; let document"
                >
                    <div class="document-col">
                        <div class="document-col-date">{{ document.timestamp | timeAgo }}</div>
                    </div>
                </ng-container>

                <ng-container
                    *fxColumn="'status'; label: 'Status'; mobileCollapse: true; mobileHideLabel: true; let document"
                >
                    <ng-container *ngIf="document.signed && document.signer">
                        <signature-icon [theme]="'dark'" [cursor]="false"
                            >Signed by {{ document.signer | userToName | async }}
                            {{ document.signedOn | date: "dd MMM yyy" }}</signature-icon
                        >
                    </ng-container>

                    <ng-container *ngIf="document.signable && !document.signed">
                        <signature-icon
                            *ngIf="context.role | permissions: 'SignDocumentsVaultCard' | async"
                            (click)="sign(context.thread.id, document.card.id, document.vaultId, document.fileId)"
                            analyticsClick="app_documentssigndocument"
                            [theme]="'light'"
                            [cursor]="true"
                            [disabled]="document.card?.status === cardStatuses.Disabled"
                            >Sign</signature-icon
                        >
                        <signature-icon
                            *ngIf="!(context.role | permissions: 'SignDocumentsVaultCard' | async)"
                            [theme]="'light'"
                            [cursor]="false"
                            >Pending Signature</signature-icon
                        >
                    </ng-container>
                </ng-container>

                <ng-container *fxColumn="'contextmenu'; mobileHideLabel: true; let document">
                    <fx-context-menu
                        [gutter]="false"
                        *ngIf="
                            {
                                editableDocument: !document.isRfi && !document.signable && !document.signed,
                                editableUser:
                                    (document.actorId === context.userId &&
                                    (context.role | permissions: 'UpdateRequestOrVaultCard' | async)) ||
                                    (context.role | permissions: 'ThreadUpdateAll' | async)
                            };
                            let flags
                        "
                    >
                        <a
                            class="menu-button"
                            *ngIf="selectedDocuments?.size <= 1 && flags.editableDocument && flags.editableUser"
                            (click)="document.renameMode = true"
                            [analyticsClick]="gaEvents.DOCUMENTS_RENAME"
                        >
                            <i class="las la-pencil-alt la-lg"></i>
                            <span>Rename</span>
                        </a>

                        <a
                            class="menu-button"
                            (click)="downloadAll(document, selectedDocuments)"
                            [analyticsClick]="gaEvents.DOCUMENTS_DOWNLOAD"
                        >
                            <i class="las la-download la-lg"></i>
                            <span
                                >Download
                                {{ selectedDocuments?.size > 1 ? selectedDocuments.size + " files" : "" }}</span
                            >
                        </a>

                        <a
                            class="menu-button"
                            *ngIf="selectedDocuments?.size <= 1 && flags.editableDocument && flags.editableUser"
                            (click)="deleteDocument(context.thread.id, document)"
                            [analyticsClick]="gaEvents.DOCUMENTS_DELETE"
                        >
                            <i class="las la-trash-alt la-lg"></i>
                            <span>Delete</span>
                        </a>
                    </fx-context-menu>
                </ng-container>
            </fx-table>
        </div>
    </div>
</ng-container>
