import { UnsavedModalDialogService } from 'projects/portal-modules/src/lib/shared/services/unsaved-modal-dialog.service';
import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { ICreatePaymentCard } from "../../interfaces/ICreatePaymentCard";
import { ThreadsService } from "projects/portal-modules/src/lib/threads-ui/services/threads.service";
import { IThread } from "@findex/threads";
import { PaymentTypes, AcceptedCurrencies } from "@findex/payments-service-sdk";
import { Router } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ENVIRONMENT } from "src/app/injection-token";
import { paymentTypeIsSubscriptionBased } from "../../pipes/payment-type-subscription-based.pipe";
import { DateTime } from "luxon";
import { environmentCommon } from "src/environments/environment";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { HandledError } from "projects/portal-modules/src/lib/shared/services/threads-error-handler";
import { ThreadCardService } from "projects/portal-modules/src/lib/threads-ui/services/thread-card.service";
import { IThreadCard } from "@findex/threads/dist/interfaces/IThreadCard";

type PaymentModalData = { userId?: string; thread?: IThread };

@Component({
    selector: "create-payment-modal",
    styleUrls: ["create-payment-modal.component.scss"],
    templateUrl: "create-payment-modal.component.html"
})
export class CreatePaymentModalComponent implements OnInit {
    readonly tailoredSubscriptionPriceId = environmentCommon.paymentsTailoredSubscriptions.tailoredSubscriptionId;
    readonly tomorrow = DateTime.fromJSDate(new Date())
        .plus({ days: 1 })
        .toISO();

    AcceptedCurrencies = AcceptedCurrencies;
    PaymentTypes = PaymentTypes;
    loader = new Loader();
    threads: IThread[];
    errorMessage: string;
    form = new FormGroup({
        thread: new FormControl(null, [Validators.required]),
        paymentType: new FormControl(null, [Validators.required]),
        currency: new FormControl(null, [Validators.required]),
        paymentAmount: new FormControl("", [Validators.required, Validators.pattern("[0-9.]*")]),
        description: new FormControl("", [Validators.required]),
        datePicker: new FormControl(this.tomorrow)
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: PaymentModalData,
        private dialogRef: MatDialogRef<CreatePaymentModalComponent>,
        private router: Router,
        private cardService: ThreadCardService,
        private threadsService: ThreadsService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private unsavedDialogService: UnsavedModalDialogService,
    ) {
        this.dialogRef.disableClose = true;
    }

    async ngOnInit() {
        this.loader.show();

        if (this.data.thread) {
            this.form.controls.thread.setValue(this.data.thread);
            this.loader.hide();
        } else {
            try {
                this.threads = await this.threadsService.getActiveThreadsByUserId(this.data.userId);

                if (this.threads.length === 1) {
                    this.form.controls.thread.setValue(this.threads[0]);
                }
            } finally {
                this.loader.hide();
            }
        }
    }

    async createCard() {
        this.loader.show();

        try {
            const subscriptionItems = this.getSubscriptionItems();
            const { thread, paymentType, description, paymentAmount, currency, datePicker } = this.form.value;

            const centsAmount = this.getCentsAmount(paymentAmount);

            const { payment } = environmentCommon.cardsEndpoints;
            const data = {
                paymentType,
                description,
                amount: centsAmount,
                currency,
                subscriptionItems,
                startDate: paymentType === PaymentTypes.ScheduledPayment && datePicker
            };

            await this.cardService.createCard<ICreatePaymentCard, IThreadCard>(thread.id, payment, data).toPromise();

            await this.router.navigate(["/timelines", thread.id]);
            this.dialogRef.close();
        } catch (error) {
            this.errorMessage =
                error?.error?.message ||
                "Sorry, payment card could not be created. Please try again or contact support.";
            throw new HandledError(error);
        } finally {
            this.loader.hide();
        }
    }

    private getCentsAmount(amount: string): string {
        const amountNum = Number(amount);

        return Math.round(amountNum * 100).toString();
    }

    private getSubscriptionItems(): { price: string; taxRateId: string }[] {
        const { paymentType, currency } = this.form.value;

        if (!paymentTypeIsSubscriptionBased(paymentType)) {
            return undefined;
        }
        const taxRateId = this.environment.payments.taxRateIds[currency];
        return [{ price: this.tailoredSubscriptionPriceId, taxRateId }];
    }

    /*
        helper func for choosing billing time during testing (since we don't have a time picker and they don't really need one in prod)

        select create-payment-modal in html viewer
        right click -> store as global variable
        in console:
        var date = new Date()
        date.setMinutes(57) // 1.57pm for example
        ng.getComponent($0).developerCreateCard(date)
    */
    // @ts-ignore
    private async developerCreateCard(date: Date) {
        this.loader.show();

        try {
            const subscriptionItems = this.getSubscriptionItems();
            const { thread, paymentType, description, paymentAmount, currency } = this.form.value;

            const centsAmount = this.getCentsAmount(paymentAmount);
            const { payment } = environmentCommon.cardsEndpoints;

            const data = {
                paymentType,
                description,
                amount: centsAmount,
                currency,
                subscriptionItems,
                startDate: paymentType === PaymentTypes.ScheduledPayment && date.toISOString()
            };

            await this.cardService.createCard<ICreatePaymentCard, IThreadCard>(thread.id, payment, data).toPromise();

            await this.router.navigate(["/timelines", thread.id]);
            this.dialogRef.close();
        } catch (error) {
            this.errorMessage =
                error?.error?.message ||
                "Sorry, payment card could not be created. Please try again or contact support.";
            throw new HandledError(error);
        } finally {
            this.loader.hide();
        }
    }

    async close() {
        if (!this.form.dirty) {
            this.dialogRef.close();
        } else {
            const confirmClose = await this.unsavedDialogService.confirmClose('payment-create');
            if (confirmClose) {
                this.dialogRef.close();
            }    
        }
    }
}
