<sidebar-modal-layout (close)="close()" [header]="'Industry type'">
    <div modal-body>
        <div class="industry-dialog-content">
            <app-industry-type-selection
                [selectedIndustryTypes]="industryTypes"
                (modelUpdated)="updateUserModel($event)"
                (modelValidated)="validStateChanged($event)"
            ></app-industry-type-selection>
        </div>
    </div>
    <div modal-footer>
        <button
            class="fx-btn fx-btn--full-width"
            [ngClass]="['fx-btn--primary' + (theme.formSubmitActionOutlined ? '-outlined' : '')]"
            [disabled]="!shouldDisableSave()"
            type="submit"
            (click)="save()"
        >
            Back
        </button>
    </div>
</sidebar-modal-layout>
