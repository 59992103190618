import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";

export interface ICreateInvitationCloseModalData {
    confirmTitle: string;
    confirmDescription: string;
    acceptButtonName: string;
    rejectButtonName: string;
}

@Component({
    selector: "create-invitation-close-modal",
    templateUrl: "./create-invitation-close-modal.component.html",
    styleUrls: ["./create-invitation-close-modal.component.scss"]
})
export class CreateInvitationCloseModalComponent {
    readonly theme = this.environment.theme;
    confirmTitle = "Are you sure you want to close this window?";
    confirmDescription = "Any unsaved work will be lost.";
    acceptButtonName = "Close";
    rejectButtonName = "Keep Editing";
    constructor(
        private dialogRef: MatDialogRef<CreateInvitationCloseModalComponent>,
        @Inject(MAT_DIALOG_DATA) data: ICreateInvitationCloseModalData,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig
    ) {
        this.confirmTitle = data.confirmTitle;
        this.confirmDescription = data.confirmDescription;
        this.acceptButtonName = data.acceptButtonName;
        this.rejectButtonName = data.rejectButtonName;
    }

    acceptPrompt() {
        this.dialogRef.close(true);
    }

    close() {
        this.dialogRef.close();
    }
}
