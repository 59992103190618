<div class="fdx-toast-wrapper" role="alert">
        <div class="icon" [ngSwitch]="state?.severity">
            <i *ngSwitchCase="'error'" role="figure" class="las la-exclamation-circle icon-size"></i>
            <i *ngSwitchCase="'success'" role="figure" class="las la-check-circle icon-size"></i>
            <i *ngSwitchDefault role="figure" class="las la-info-circle icon-size"></i>
        </div>
        <div class="fx-snackbar-wrapper">
                <div class="effects-wrapper">                
                    <h3 id="toast-title">{{state?.title}}</h3>
                    <p id="toast-content">
                        {{state.content}}
                    </p>
                </div>
                <div class="vc-performance-toast-button-group" id="toast-actions">
                    <button id="toast-dismiss-button" class="fx-btn fx-btn--medium fx-btn--white" (click)="snackRef.dismiss()">{{state.dismissButtonLabel}}</button>
                    <button id="toast-accept-button" (click)="snackRef.dismissWithAction()" 
                        class="fx-btn fx-btn--medium fx-btn--primary"
                        role="button"
                    >{{state.acceptButtonLabel}}</button>
                </div>
        </div>
    </div>