import { Component, Inject, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { Role } from "@findex/threads";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { filter, map } from "rxjs/operators";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";

@Component({
    selector: "app-sameday-profile-form",
    templateUrl: "./sameday-profile-form.component.html",
    styleUrls: ["./sameday-profile-form.component.scss"]
})
export class SamedayProfileFormComponent {
    @Input() form: FormGroup;

    readonly appName = this.environment.appName;
    roles = Role;
    role$: Observable<Role>;

    constructor(authService: AuthService, @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig) {
        this.role$ = authService.getUser().pipe(
            filter(user => !!user),
            map(user => user.globalRole)
        );
    }
}
