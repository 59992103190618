import { NgModule } from "@angular/core";
import { ActivatedRouteSnapshot, ActivationEnd, Route, Router, RouterModule, Routes } from "@angular/router";
import { AnalyticsService } from "projects/portal-modules/src/lib/analytics";
import { filter } from "rxjs/operators";
import { AuthGuard } from "projects/portal-modules/src/lib/findex-auth";
import { VerifiedGuard } from "projects/portal-modules/src/lib/findex-auth/services/verified.guard";
import { LoginPageComponent } from "projects/portal-modules/src/lib/findex-auth/components/login-page/login-page.component";
import { ThreadsListRouteComponent } from "../../projects/portal-modules/src/lib/threads-ui/components/threads-list-route/threads-list-route.component";
import { ThreadRouteComponent } from "../../projects/portal-modules/src/lib/threads-ui/components/thread-route/thread-route.component";
import { DashboardComponent } from "../../projects/portal-modules/src/lib/threads-ui/components/dashboard/dashboard.component";
import { StaffGuard } from "projects/portal-modules/src/lib/findex-auth/services/staff.guard";
import { environment } from "../environments/environment";
import { ErrorComponent } from "projects/portal-modules/src/lib/shared/components/error/error.component";
import { ResetPasswordComponent } from "projects/portal-modules/src/lib/findex-auth/components/reset-password/reset-password.component";
import { NoAuthGuard } from "projects/portal-modules/src/lib/findex-auth/services/no-auth.guard";
import { LoggedInGuard } from "projects/portal-modules/src/lib/findex-auth/services/logged-in.guard";
import { OnboardingProfileComponent } from "../solutions/samedaytax/components/onboarding-profile/onboarding-profile.component";
import { SelectStaffComponent } from "../solutions/samedaytax/components/select-staff/select-staff.component";
import { FocusActionWizardComponent } from "projects/portal-modules/src/lib/threads-ui/components/focus-action-wizard/focus-action-wizard.component";
import { environmentCommon } from "src/environments/environment";

const registrationEnabledPaths = [
    {
        path: "register",
        loadChildren: () =>
            import("projects/portal-modules/src/lib/onboarding/onboarding.module").then(m => m.OnboardingModule)
    }
];
const rootPaths = environment.featureFlags.signupEnabled ? registrationEnabledPaths : [];
const focusWizardPath = environmentCommon.focusWizard.path;
const focusWizardPerformAction = {
    path: `${focusWizardPath}/:actionId`,
    component: FocusActionWizardComponent
};

const focusWizardPaths = environment.featureFlags.focusWizardEnabled ? [focusWizardPerformAction] : [];

export const securedRoutes: Route[] = [
    {
        path: "timelines",
        component: ThreadsListRouteComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: ":threadId",
                component: ThreadRouteComponent,
                canActivate: [AuthGuard],
            },
            {
                path: ":threadId/cards/:cardId",
                component: ThreadRouteComponent,
                canActivate: [AuthGuard]
            }
        ]
    },
    {
        path: "dashboard",
        component: DashboardComponent,
        canActivate: [AuthGuard]
    },
    {
        path: "admin",
        loadChildren: () => import("projects/portal-modules/src/lib/admin/admin.module").then(m => m.AdminModule),
        canActivate: [StaffGuard]
    },
    {
        path: "accounts",
        loadChildren: () => import("projects/portal-modules/src/lib/account/account.module").then(m => m.AccountModule),
        canActivate: [AuthGuard]
    },
    {
        path: "reports",
        loadChildren: () => import("projects/portal-modules/src/lib/reports/reports.module").then(m => m.ReportsModule),
        canActivate: [StaffGuard]
    },
    { path: "select-staff", component: SelectStaffComponent, canActivate: [AuthGuard] },
    { path: "onboarding-profile", component: OnboardingProfileComponent, canActivate: [AuthGuard] },
    {
        path: "profile",
        loadChildren: () =>
            import("projects/portal-modules/src/lib/user-profile/user-profile.module").then(m => m.UserProfileModule)
    },
    { path: "reset-password", component: ResetPasswordComponent, canActivate: [NoAuthGuard] },
    { path: "error", component: ErrorComponent },
    ...rootPaths,
    { path: "", redirectTo: "/login", pathMatch: "full" },
    { path: "**", redirectTo: "/login", pathMatch: "full" }
];

export const routes: Routes = [
    { path: "login", component: LoginPageComponent, canActivate: [LoggedInGuard] },
    ...focusWizardPaths,
    { path: "staff-login", redirectTo: "login" },
    {
        //Redirect users to the mobile verification screen if they are not mobile verified
        canActivateChild: [VerifiedGuard],
        path: "",
        children: securedRoutes
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
    constructor(router: Router, analyticsService: AnalyticsService) {
        router.events.pipe(filter(event => event instanceof ActivationEnd)).subscribe((event: ActivationEnd) => {
            if (!event.snapshot.firstChild) {
                const eventPath = this.getFullRoutePath(event.snapshot);
                const santizedEventPath = eventPath === "" ? "/" : eventPath;
                analyticsService.pageViewed(santizedEventPath);
            }
        });
    }

    private getFullRoutePath(snapshot: ActivatedRouteSnapshot): string {
        if (!snapshot || !snapshot.routeConfig) {
            return "";
        }
        const parentPath = this.getFullRoutePath(snapshot.parent);
        const prefix = parentPath ? `${parentPath}/` : "";
        return `${prefix}${snapshot.routeConfig.path}`;
    }
}
