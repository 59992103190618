import { Component, Inject, Input, OnInit } from "@angular/core";
import { INewsArticle } from "../model/INewsArticle";
import { ENVIRONMENT } from "src/app/injection-token";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";

@Component({
    selector: "news-article-item",
    templateUrl: "./news-article-item.component.html",
    styleUrls: ["./news-article-item.component.scss"]
})
export class NewsArticleItemComponent implements OnInit {
    @Input() article: INewsArticle;
    sigmaWebUrl = this.environment.news.sourceUrl;

    constructor(@Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig) {}

    ngOnInit() {}
}
