import { Component, ElementRef, EventEmitter, HostBinding, Input, Output, ViewChild } from "@angular/core";
import { OpenMeasuresService } from "projects/default-plugins/insights/services/open-measures.service";
import { MatDialog } from "@angular/material/dialog";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { Observable, of, Subject, Subscription } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { EnterpriseListing, Enterprise, Entity, AccessLevel } from "@findex/openmeasures-core";
import { AddEnterpriseDialogComponent, AddEnterpriseDialogData } from "../add-enterprise-dialog/add-enterprise-dialog.component";
import { ConfirmPromptComponent, ConfirmPromptData } from "projects/portal-modules/src/lib/threads-ui/components/confirm-prompt/confirm-prompt.component";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";


export type PermissionsListing = EnterpriseListing & {
    enterprise$: Observable<Enterprise>;
    entities$: Observable<Entity[]>;
    permissions$: Observable<AccessLevel>;
};

@Component({
    selector: "insights-permissions-component",
    templateUrl: "./insights-permissions.component.html",
    styleUrls: ["./insights-permissions.component.scss"]
})
export class InsightsPermissionsComponent {
    @Input() userId: string;

    @Input() isMobileView = true;
    @Input() placeholder = "Search timelines";
    @Output() search = new EventEmitter<string>();
    @Output() active = new EventEmitter<boolean>();

    @HostBinding("class.active") activeClass: boolean;
    @ViewChild("search", { static: false }) inputElement: ElementRef;

    expandSearch = false;

    searchTerm$: Subject<string> = new Subject<string>();
    searchSubscription$: Subscription;

    constructor(private openMeasures: OpenMeasuresService, private dialog: MatDialog) {}

    loader = new Loader();
    enterpriseListings$: Observable<PermissionsListing[]>;
    

    async savePermissions(enterpriseId: string, userId: string, accessLevel: AccessLevel) { 
        this.loader.show();
        await this.openMeasures.setPermissions(enterpriseId, userId, accessLevel).toPromise();
        this.loader.hide();
    }

    async removeEnterprise(enterpriseId: string, userId: string): Promise<void> {
        const dialogData: ConfirmPromptData = { 
            header: 'Are you sure you want to remove this enterprise?',
            message: 'This will remove access to the enterprise for the user.',
            analyticsPrefix: GA_EVENTS.INSIGHTS_REMOVE_DIALOG,
            saveButtonTitle: "DELETE"
        };

        const shouldDelete = await this.dialog
            .open<ConfirmPromptComponent, ConfirmPromptData, boolean>(ConfirmPromptComponent, {
                panelClass: ["centered-modal"],
                width: "420px",
                disableClose: true,
                data: dialogData
            })
            .afterClosed()
            .toPromise();

        if (!shouldDelete) return;

        this.loader.show();
        await this.openMeasures.removeUser(enterpriseId, userId).toPromise();
        this.loader.hide();
        
        this.enterpriseListings$ = this.getPermissions(this.userId);
    }


    async ngOnChanges() {
        //if userId is an input we need to do this in ngOnChanges, but given it's going to be a route, leaving here for now
        //const userId$ = this.route.params.get('userId')
        //this.enterpriseListings$ = combineLatest(enterprise$, userId$)
        this.enterpriseListings$ = this.getPermissions(this.userId);
    }

    setActive(active: boolean) {
        if (active) {
            setTimeout(() => this.inputElement.nativeElement.focus());
        }

        this.expandSearch = active;
        this.activeClass = active;
        this.active.emit(active);
    }

    async addEnterprise(userId: string, enterprises: EnterpriseListing[]) {
        const existingEnterpriseIds = enterprises?.map(enterprise => enterprise.id) || [];

        await this.dialog.open<AddEnterpriseDialogComponent, AddEnterpriseDialogData, void>(AddEnterpriseDialogComponent, {
            panelClass: ["centered-modal"],
            width: "761px",
            disableClose: true,
            data: { userId, existingEnterpriseIds }
        }).afterClosed().toPromise();

        this.enterpriseListings$ = this.getPermissions(this.userId);
    }

    private getPermissions(userId: string): Observable<PermissionsListing[]> {
        const enterprises$ = this.loader.wrap(this.openMeasures.listEnterprises(userId));

        return enterprises$.pipe(
            map(enterpriseListings => {
                return enterpriseListings.map(listing => this.getPermissionsListing(listing, userId));
            })
        );
    }

    private getPermissionsListing(listing: EnterpriseListing, userId: string): PermissionsListing {
        //TODO: handle error instead of swallowing
        const enterprise$ = this.loader.wrap(this.openMeasures.getEnterprise(listing.id).pipe(catchError(err => (console.log('ERROR', err), of(null)))));
        const entities$ = this.loader.wrap(this.openMeasures.getEntities(listing.id));
        const permissions$ = this.loader.wrap(this.openMeasures.getPermissions(listing.id, userId));
        
        return {
            ...listing,
            enterprise$,
            entities$,
            permissions$
        };
    }
}
