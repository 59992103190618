import { Inject, Injectable } from "@angular/core";
import { Role } from "@findex/threads";
import { ENVIRONMENT } from "../../../../../../src/app/injection-token";
import { EnvironmentSpecificConfig } from "../../environment/environment.common";
import { AppUser } from "../../findex-auth";
import { IAnalyticsService } from "./IAnalyticsService";
declare global {
    interface Window {
        dataLayer?: any;
    }
}

@Injectable()
export class GaAnalyticsService implements IAnalyticsService {
    configId: string;

    constructor(@Inject(ENVIRONMENT) environment: EnvironmentSpecificConfig) {
        const { tagManagerId } = environment.analytics.google;

        this.configId = tagManagerId;
    }

    setUserId(user: AppUser) {
        if (!user) {
            return;
        }

        const userId = user.id;
        const userType = user.globalRole || Role.Client;

        if (this.configId && window.dataLayer) {
            const hasUserId = window.dataLayer.some(val => val.user_id === userId);
            const hasUserType = window.dataLayer.some(val => val.userType === userType);

            if (!hasUserId) {
                window.dataLayer.push({ user_id: userId });
            }

            if (!hasUserType) {
                window.dataLayer.push({ userType });
            }
        }
    }

    init(user: AppUser) {
        this.setUserId(user);
    }

    pageViewed(path: string) {
        if (this.configId && window.dataLayer) {
            window.dataLayer.push({
                event: "app.pageview",
                pageview_path: path
            });
        }
    }

    sendEvent(category: string, action: string, label?: string, value?: number) {
        if (this.configId && window.dataLayer) {
            window.dataLayer.push({
                event: "app.event",
                event_action: action,
                event_category: category,
                event_label: label,
                event_value: value
            });
        }
    }
}
