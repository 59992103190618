<loader-icon *ngIf="loader.counter" color="light" [showBackground]="true"></loader-icon>
<div class="message-attach">
    <div class="message-attach-header">
        <h1>
            New message or attachment
        </h1>
        <div class="message-attach-header-close" (click)="close()" [analyticsClick]="[gaEvents.APP_CREATE_MESSAGE_CLOSE, hotJarEvents.MessageNewCloseEvent]">
            <i class="las la-times icon-size"></i>
        </div>
    </div>

    <div class="message-attach-subhead">
        Everyone on the timeline can read your message.
    </div>
    <div class="message-attach-message">
        <quill-editor-wrapper
            [message]="messageFormControl"
            [autoFocusOnInit]="!isMobileView"
            (error)="quillError = $event"
        ></quill-editor-wrapper>
    </div>

    <div *ngIf="role$ | async | permissions: 'CreateVaultCard' | async" class="message-attach-file-upload">
        <file-input
            [analyticsPrefix]="gaEvents.APP_CREATE_MESSAGE_FILE_UPLOAD"
            [enableDirectoryDrop]="true"
            (file)="attachFile($event)"
        ></file-input>
    </div>

    <div class="message-attach-attachments">
        <create-card-document
            *ngIf="documents.length || attachmentErrorMessages.length"
            [role]="role$ | async"
            [documents]="documents"
            [errorMessages]="attachmentErrorMessages"
            (remove)="removeFile($event)"
            (rename)="renameFile($event)"
            (markAsReport)="markAsReport($event)"
        ></create-card-document>
    </div>
</div>
<div class="message-attach-footer">
    <div class="message-attach-footer-buttons message-attach-footer-buttons--mobile">
        <button [analyticsClick]="[gaEvents.APP_CREATE_MESSAGE_CLOSE, hotJarEvents.MessageNewCloseEvent]" (click)="close()" class="fx-btn fx-btn--white">
            Cancel
        </button>
        <button
            [disabled]="quillError || (messageFormControl.value === '' && documents?.length === 0)"
            (click)="sendMessage()"
            class="fx-btn fx-btn--primary"
            role="button"
            [analyticsClick]="[gaEvents.APP_CREATE_MESSAGE, hotJarEvents.MessageNewCompleteEvent]"
        >
            Send Message
        </button>
    </div>
</div>
