import { Inject, Injectable } from "@angular/core";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { Role } from "@findex/threads";
import { ParticipantDetail } from "projects/portal-modules/src/lib/user-profile/services/user-profile.service";
import { PrivateProfileService } from "../../solutions/samedaytax/services/private-profile.service";
import { IParticipantEnrichmentService } from "../../../projects/portal-modules/src/lib/threads-ui/components/thread-participants/thread-participants.component";
import { ENVIRONMENT } from "../injection-token";

@Injectable({ providedIn: "root" })
export class ParticipantEnrichmentService implements IParticipantEnrichmentService {
    constructor(
        private privateProfileService: PrivateProfileService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig
    ) {}

    async enrichParticipantDetail(participant: ParticipantDetail, role: Role) {
        switch (this.environment.appName) {
            //TODO this switch statement can be removed when we have app-specific projects
            case "Same Day Tax Refunds": {
                return await this.privateProfileService.enrichParticipantDetail(participant, role);
            }
            default:
                return participant;
        }
    }
}
