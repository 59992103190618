import { Component, Inject, OnInit } from "@angular/core";
import { IThreadListing, Role, SubjectType } from "@findex/threads";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { PortalService } from "projects/portal-modules/src/lib/shared/services/portal.service";
import { ThreadsWebsocketService } from "projects/portal-modules/src/lib/shared/services/threads-websocket.service";
import { THREAD_CARD_RESOURCES, CardResources } from "projects/portal-modules/src/lib/threads-ui/interfaces/IUiCard";
import { debounceTime, filter, map, shareReplay, startWith, switchMap } from "rxjs/operators";
import { ENVIRONMENT } from "src/app/injection-token";
import { Observable, combineLatest, merge } from "rxjs";
import { CardState } from "../../types/card-state";
import { TourService } from "projects/portal-modules/src/lib/shared/services/tour.service";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { ThreadStatus } from "projects/portal-modules/src/lib/threads-ui/components/status-badge/status-badge.component";
import { IEnrichedThreadListing } from "projects/portal-modules/src/lib/threads-ui/components/threads-list-route/threads-list-route.component";

@Component({
    selector: "dashboard-card",
    templateUrl: "./dashboard-card.component.html",
    styleUrls: ["./dashboard-card.component.scss"]
})
export class DashboardCardComponent implements OnInit {
    readonly dashboardNewsTitle =
        this.environment.featureFlags.text.dashboardNewsTitle ||
        this.environment.featureFlags.text.default.dashboardNewsTitle;
    readonly blockMyTasksRefreshDuringTour = this.environment.featureFlags.blockMyTasksRefreshDuringTour;

    role$: Observable<Role>;
    threads$: Observable<IEnrichedThreadListing[]>;
    state$: Observable<CardState>;
    loader = new Loader();
    // TODO: Remove this flag once article section is ready to get content from CMS
    readonly showArticlesSection = this.environment.featureFlags.showArticlesSectionFromDashboard;

    constructor(
        @Inject(THREAD_CARD_RESOURCES) private resources: CardResources<CardState>,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private threadWebsocketService: ThreadsWebsocketService,
        private portalService: PortalService,
        private authService: AuthService,
        private tourService: TourService
    ) {}

    ngOnInit() {
        const { state$, threadId } = this.resources;
        this.role$ = this.authService.getUser().pipe(
            filter(user => !!user),
            map(user => user.globalRole || this.resources.role),
            shareReplay(1)
        );

        this.threads$ = this.role$.pipe(
            switchMap(() => this.getThreadsList(threadId)),
            map(threads => this.sortThreadsByLatest(threads)),
            shareReplay(1)
        );

        this.state$ = combineLatest([this.threads$, state$, this.tourService.tourIsActive$]).pipe(
            filter(([_threads, _state, tourIsActive]) => !this.blockMyTasksRefreshDuringTour || !tourIsActive),
            map(([threads, cardState]) => this.filterStateByCurrentThreads(threads, cardState))
        );
    }

    private filterStateByCurrentThreads(threads: IThreadListing[], state: CardState) {
        const filteredSubjects = state.cardSubjects.filter(cardSubject =>
            threads.find(thread => cardSubject.threadId.includes(thread.id))
        );
        return {
            ...state,
            cardSubjects: filteredSubjects
        };
    }

    private getThreadsList(dashboardThreadId: string): Observable<IEnrichedThreadListing[]> {
        const initialList$ = this.getActiveThreadList().pipe(
            shareReplay(1)
        );
        const newThreads$ = this.getThreadListUpdate(dashboardThreadId);
        return merge(initialList$, newThreads$).pipe(switchMap(threads => this.getThreadsUpdates(threads)));
    }

    private getThreadListUpdate(dashboardThreadId: string) {
        return this.threadWebsocketService.watchThreadId(dashboardThreadId).pipe(
            filter(notification => notification.subjectType === "card" && notification.eventType === "updated"),
            switchMap(() => this.getActiveThreadList()),
        )
    }

    private getActiveThreadList() {
        const threadList$ = this.portalService.getThreadList({ status: ThreadStatus.active });
        return this.loader.wrap(threadList$);
    }

    private getThreadsUpdates(
        threads: IEnrichedThreadListing[]
    ): Observable<IEnrichedThreadListing[]> {
        const threadUpdates$ = threads.map(thread => this.bindThreadUpdate(thread));
        return combineLatest(threadUpdates$);
    }

    private bindThreadUpdate(thread: IEnrichedThreadListing): Observable<IEnrichedThreadListing> {
        return this.threadWebsocketService.watchThreadId(thread.id).pipe(
            filter(notification => notification.eventType === "updated"),
            filter(notification => notification.subjectType === SubjectType.Thread),
            debounceTime(1000), // fetching immediately doesn't seem to resolve unread count correctly
            switchMap(() => this.portalService.getThreadListById(thread.id)),
            startWith(thread),
            shareReplay(1)
        );
    }

    private sortThreadsByLatest(threads: IEnrichedThreadListing[]): IEnrichedThreadListing[] {
        if (!threads) return [];

        return threads.sort((a, b) => {
            const dateA = a.preview?.timestamp || a.createdAt;
            const dateB = b.preview?.timestamp || b.createdAt;

            return new Date(dateB).getTime() - new Date(dateA).getTime();
        });
    }
}
