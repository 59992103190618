<div class="email-verification-sent">
    <h1 class="email-verification-sent-title">
        {{ textResource.verifyEmailTitle || textResource.default.verifyEmailTitle }}
    </h1>
    <p class="email-verification-sent-message" *ngIf="textResource.verifyEmailDescription">
        {{ textResource.verifyEmailDescription }} <strong> {{ emailAddress }}</strong>
    </p>
    <p class="email-verification-sent-help">
        Didn't receive an email?
        <a
            class="email-verification-sent-help-link"
            (click)="emitResendEmail.emit()"
            role="button"
            analyticsClick="onboarding_verifyresend"
            >Resend</a
        >
    </p>
    <p class="email-verification-sent-back-to-login">
        <a
            class="email-verification-sent-back-to-login-link"
            (click)="backToLogin()"
            role="button"
            analyticsClick="onboarding_backtologin"
            >Back to Log In</a
        >
    </p>
    <div
        *ngIf="verifyEmailMessage && verifyEmailMessage.error !== undefined"
        [ngClass]="verifyEmailMessage.error ? 'fx-onboarding-form-error' : 'fx-onboarding-form-info'"
    >
        {{ verifyEmailMessage.message }}
    </div>
</div>
