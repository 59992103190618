<loader-icon class="small-loader" *ngIf="actionInProgress || loader.counter"></loader-icon>
<div class="threads-task">
    <div class="threads-task-label">{{ task.taskLabel }}</div>
    <div class="threads-task-status"><i *ngIf="statusIcon" class="{{ statusIcon }}"></i>{{ statusText }}</div>
    <div class="threads-task-due-date" *ngIf="card$ | async as card">
        <span *ngIf="card.dueDate">Due By {{ card.dueDate | date: "dd/MM/yy" }}</span>
    </div>
    <div class="threads-task-action">
        <button
            class="fx-btn fx-btn--primary"
            (click)="action(task.taskId)"
            [analyticsClick]="GA_EVENTS.DASHBOARD_TASK_ITEM"
        >
            {{ actionLabel$ | async }}
        </button>
    </div>
</div>
