<div class="fx-onboarding-modal">
    <loader-icon color="light" *ngIf="loader.counter"></loader-icon>
    <ng-container>
        <!-- fake ngFor to force a rerender, allowing for animations to work -->
        <ng-container *ngFor="let staff of staffMembers">
            <app-staff-viewer
                *ngIf="staff === staffMembers[activeStaffMemberIndex]"
                (staffMemberSelected)="staffMemberSelected()"
                [staffProfile]="activeStaffMember$ | async"
                [firstChoice]="(activeStaffMember$ | async) === staffMembers[firstChoiceIndex]"
                [analyticsPrefix]="analyticsPrefix"></app-staff-viewer>
        </ng-container>
    </ng-container>
</div>
