import { Component, Inject, Input, OnChanges, SimpleChanges } from "@angular/core";
import { ISlot } from "@findex/fx-ui/lib/components/calendar/calendar";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { IInvitee } from "@findex/calendar-types";
import { ENVIRONMENT } from "src/app/injection-token";
import { ParticipantCache } from "projects/portal-modules/src/lib/threads-ui/services/participant-cache.service";
import { IAvatarContent } from "@findex/fx-ui";
import { Observable } from "rxjs";
import { ReccurrenceOptions } from "../create-invitation/create-invitation.component";
import { environmentCommon } from "src/environments/environment";
import { switchMap } from "rxjs/operators";

export interface IMeetingReviewData {
    title: string;
    date: ISlot;
    organiser: { name: string; id: string };
    attendees: IInvitee[];
    recurrence: { type: ReccurrenceOptions; numberOfOccurrences: number };
    message: string;
}

@Component({
    selector: "app-calendar-review-meeting",
    templateUrl: "./calendar-review-meeting.component.html",
    styleUrls: ["./calendar-review-meeting.component.scss"]
})
export class CalendarReviewMeetingComponent implements OnChanges {
    readonly theme = this.environment.theme;
    readonly appName = this.environment.appName;
    readonly meetingTimeFormat = environmentCommon.dateFormats.short;
    @Input() data: IMeetingReviewData;
    avatars$: Observable<IAvatarContent[]>;
    constructor(
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private participantCache: ParticipantCache
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.data?.currentValue?.attendees) {
            const participantIds = this.data.attendees?.map(attendee => attendee.id);

            this.avatars$ = this.participantCache.getParticipants(participantIds).pipe(
                switchMap(participants => this.participantCache.getMultipleAvatars(participants))
            );
        }
    }
}
