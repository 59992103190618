import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { THREAD_CARD_RESOURCES, CardResources } from "projects/portal-modules/src/lib/threads-ui/interfaces/IUiCard";
import { IThreadCard, IThread, CardStatus, CardReply } from "@findex/threads";
import { Observable, Subject } from "rxjs";
import { map, filter } from "rxjs/operators";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { ENVIRONMENT } from "src/app/injection-token";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { ThreadCardService } from "projects/portal-modules/src/lib/threads-ui/services/thread-card.service";

@Component({
    selector: "message-card",
    templateUrl: "./message-card.component.html",
    styleUrls: ["./message-card.component.scss"]
})
export class MessageCardComponent implements OnDestroy, OnInit {
    readonly allowEdit = this.environment.featureFlags.editCardDescription;

    private threadId: string;
    private cardId: string;

    card$: Observable<IThreadCard>;
    thread$: Observable<IThread>;
    userId$: Observable<string>;
    replies$: Observable<CardReply[]>;

    edit$ = new Subject<boolean>();

    errorMessage: string;
    cardStatuses = CardStatus;
    loading: boolean;
    message: string;
    constructor(
        @Inject(THREAD_CARD_RESOURCES) private resources: CardResources,
        private authService: AuthService,
        private cardService: ThreadCardService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig
    ) {}

    ngOnInit() {
        const { threadId, cardId, card$, thread$, replies$ } = this.resources;

        this.threadId = threadId;
        this.cardId = cardId;
        this.thread$ = thread$;
        this.card$ = card$;

        this.replies$ = replies$;
        this.userId$ = this.authService.getUser().pipe(
            filter(user => !!user),
            map(user => user.id)
        );
    }

    editMessage() {
        this.edit$.next(true);
    }

    async removeMessage() {
        this.errorMessage = null;
        this.loading = true;
        try {
            await this.cardService.removeCard(this.threadId, this.cardId).toPromise();

            this.loading = false;
        } catch {
            this.loading = false;
            this.errorMessage = "Sorry, something went wrong";
        }
    }
    async save(updatedMessage: string) {
        this.errorMessage = null;
        this.loading = true;
        try {
            await this.cardService
                .updateCardDescription(this.threadId, this.cardId, updatedMessage, CardStatus.Edited)
                .toPromise();

            this.loading = false;
        } catch {
            this.loading = false;
            this.errorMessage = "Sorry, something went wrong";
        }
    }
    ngOnDestroy() {
        this.edit$.unsubscribe();
        this.userId$.subscribe();
    }
}
