import { CenteredModalLayoutComponent } from './../../components/modal-layout/centered-modal-layout/centered-modal-layout.component';
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatDialogModule } from '@angular/material/dialog';
import { AnalyticsModule } from '../../../analytics/analytics.module';
import { FindexUiModule } from '../../../findex-ui/findex-ui.module';

@NgModule({
    imports: [
        CommonModule,
        MatDialogModule,
        AnalyticsModule,
        FindexUiModule,
    ],
    declarations: [
        CenteredModalLayoutComponent,
    ],
    exports: [CenteredModalLayoutComponent]
})
export class ModalsModule {}
