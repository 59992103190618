<fx-date-picker
    *ngIf="slots"
    [range]="{ start: minDate, end: maxDate }"
    [times]="slots"
    [startDate]="startDate"
    (dateSelected)="setDate($event)"
    (monthChange)="monthChange.emit($event)"
></fx-date-picker>
<div class="time-picker-container">
    <ng-container *ngIf="selectedDate?.date && showDatePreview">
        <p class="time-picker-date-preview">
            {{ selectedDate?.date?.iSOString | date: "EEE d MMM, YYYY" }}
        </p>
        <span class="time-picker-subtitle" [innerHTML]="timezoneSubtitle"></span>
    </ng-container>
    <fx-time-picker
        *ngIf="selectedDate"
        [times]="selectedDate.date?.times"
        [options]="options.timepicker"
        (timeSelected)="setTime($event)"
    ></fx-time-picker>
</div>
