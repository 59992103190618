import { ModalsModule } from "./modules/modals/modals.module";
import { NgModule } from "@angular/core";
import { FxUiModule } from "@findex/fx-ui";
import { FindexUiModule } from "../findex-ui";
import { CommonModule } from "@angular/common";
import { PortalModule } from "@angular/cdk/portal";

import { ThreadComponent } from "./components/thread/thread.component";
import { ThreadListComponent } from "./components/thread-list/thread-list.component";
import { ThreadCardComponent } from "./components/thread-card/thread-card.component";
import { UiCardPortalComponent } from "./components/ui-card-portal/ui-card-portal.component";
import { UserDetailCardComponent } from "./components/user-detail-card/user-detail-card.component";
import { UserItemComponent } from "./components/user-item/user-item.component";
import { UpcomingMeetingDetailComponent } from "./components/upcoming-meeting-detail/upcoming-meeting-detail.component";
import { ThreadListItemComponent } from "./components/thread-list-item/thread-list-item.component";
import { ThreadListNodeGhostComponent } from "./components/thread-list/thread-list-node-ghost/thread-list-node-ghost.component";
import { ThreadsInputComponent } from "./components/threads-input/threads-input.component";
import { ThreadsCheckboxComponent } from "./components/threads-checkbox/threads-checkbox.component";
import { SidebarModalLayoutComponent } from "./components/modal-layout/sidebar-modal-layout/sidebar-modal-layout.component";
import { ThreadListNodeComponent } from "./components/thread-list/thread-list-node/thread-list-node.component";
import { ThreadWorkflowStatusComponent } from "./components/thread-workflow-status/thread-workflow-status.component";
import { ScrollToDirective } from "./directives/scroll-to.directive";
import { AnalyticsModule } from "../analytics";
import { FxTableModule } from "../fx-table/fx-table.module";
import { ThreadsListRouteComponent } from "./components/threads-list-route/threads-list-route.component";
import { ThreadsStateModalComponent } from "./components/threads-state/thread-state-modal/threads-state-modal.component";
import { NestedMessageComponent } from "./components/nested-message/nested-message.component";
import { CreateCardComponent } from "./components/create-card/create-card.component";
import { CreateCardDocumentComponent } from "./components/create-card/create-card-document/create-card-document.component";
import { RenameFileModalComponent } from "./components/create-card/rename-file-modal/rename-file-modal.component";
import { ThreadRouteComponent } from "./components/thread-route/thread-route.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { SlideMenuIconComponent } from "./components/slide-menu-icon/slide-menu-icon.component";
import { ThreadParticipantsComponent } from "./components/thread-participants/thread-participants.component";
import { ThreadAddParticipantComponent } from "./components/thread-participants/thread-add-participant/thread-add-participant.component";
import { ThreadRemoveParticipantComponent } from "./components/thread-participants/thread-remove-participant/thread-remove-participant.component";
import { CancelThreadModalComponent } from "./components/cancel-thread-modal/cancel-thread-modal.component";
import { CancelThreadReasonModalComponent } from "./components/cancel-thread-reason-modal/cancel-thread-reason-modal.component";
import { NewThreadModalComponent } from "./components/new-thread-modal/new-thread-modal.component";
import { RouterModule } from "@angular/router";
import { SharedModule } from "../shared";
import { NotificationsModule } from "../notifications";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AvatarPillComponent } from "./components/avatar-pill/avatar-pill.component";
import { ParticipantListComponent } from "./components/participant-list/participant-list.component";
import { ParticipantPickerComponent } from "./components/participant-picker/participant-picker.component";
import { ParticipantAvatarsComponent } from "./components/participant-avatars/participant-avatars.component";
import { InviteClientModalComponent } from "./components/invite-client/invite-client-modal.component";
import { WorkflowStepListComponent } from "./components/threads-state/workflow-step-list/workflow-step-list.component";
import { StatusBadgeComponent } from "./components/status-badge/status-badge.component";
import { StaffDetailComponent } from "./components/staff-detail/staff-detail.component";
import { QuillModule } from "ngx-quill";
import { CardComponent } from "./components/card/card.component";
import { ToastComponent } from "./components/toast/toast.component";
import { QuillEditorWrapperComponent } from "./components/quill-wrapper/quill-editor-wrapper.component";
import { QuillEditorFormControlComponent } from "./components/quill-form-control/quill-form-control.component";
import { DueDatesComponent } from "./components/due-dates/due-dates.component";
import { EditThreadDatesComponent } from "./components/edit-thread-dates/edit-thread-dates.component";

import { CloseThreadPromptComponent } from "./components/close-thread-prompt/close-thread-prompt.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTableModule } from "@angular/material/table";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatTooltipModule } from "@angular/material/tooltip";
import { StepLayoutComponent } from "./components/multi-component-layout/step-layout/step-layout.component";
import { MultiComponentLayoutComponent } from "./components/multi-component-layout/multi-component-layout.component";
import { MultiComponentService } from "./components/multi-component-layout/multi-component.service";
import { ProgressBarComponent } from "./components/progress-bar/progress-bar.component";
import { CalendarComponent } from "./components/calendar/calendar.component";
import { UserProfileDropdownComponent } from "./components/user-profile-dropdown/user-profile-dropdown.component";
import { ThreadCardRepliesComponent } from "./components/thread-card-replies/thread-card-replies.component";
import { ReplyComponent } from "./components/reply/reply.component";
import { CreateReportModalComponent } from "./components/create-report-modal/create-report-modal.component";

import { ThreadSearchComponent } from "./components/thread-search/thread-search.component";
import { ThreadPreviewComponent } from "./components/thread-preview/thread-preview.component";
import { ThreadWorkflowPopupComponent } from "./components/thread-workflow-popup/thread-workflow-popup.component";
import { ThreadWorkflowProgressIndicatorComponent } from "./components/thread-workflow-progress-indicator/thread-workflow-progress-indicator.component";
import { ThreadsUiPipesModule } from "./pipes/threads-ui-pipes.module";
import { PluginsModule } from "../plugins";
import { FocusActionWizardComponent } from "./components/focus-action-wizard/focus-action-wizard.component";
import { DashboardGreetingBannerComponent } from "./../../../../default-plugins/dashboard/components/greeting/dashboard-greeting-banner";
import { ReplyButtonsComponent } from "./components/reply-buttons/reply-buttons.component";
import { ConfirmPromptComponent } from "./components/confirm-prompt/confirm-prompt.component";
import { LuxonDateModule } from "@angular/material-luxon-adapter";
import { EditTitleComponent } from './components/edit-title/edit-title.component';

const exportDeclarations = [
    UserItemComponent,
    ThreadCardRepliesComponent,
    UserProfileDropdownComponent,
    CalendarComponent,
    ProgressBarComponent,
    MultiComponentLayoutComponent,
    StepLayoutComponent,
    CardComponent,
    StaffDetailComponent,
    StatusBadgeComponent,
    InviteClientModalComponent,
    ParticipantPickerComponent,
    ParticipantListComponent,
    ParticipantAvatarsComponent,
    AvatarPillComponent,
    ThreadCardComponent,
    ThreadListComponent,
    ThreadListItemComponent,
    ThreadComponent,
    ThreadsInputComponent,
    ThreadsCheckboxComponent,
    SidebarModalLayoutComponent,
    UserDetailCardComponent,
    ThreadListNodeComponent,
    ThreadsListRouteComponent,
    ThreadsStateModalComponent,
    NestedMessageComponent,
    CreateCardComponent,
    CreateCardDocumentComponent,
    RenameFileModalComponent,
    ThreadRouteComponent,
    DashboardComponent,
    SlideMenuIconComponent,
    ThreadParticipantsComponent,
    ThreadAddParticipantComponent,
    ThreadRemoveParticipantComponent,
    CancelThreadModalComponent,
    CancelThreadReasonModalComponent,
    NewThreadModalComponent,
    WorkflowStepListComponent,
    ToastComponent,
    UpcomingMeetingDetailComponent,
    CloseThreadPromptComponent,
    ThreadListNodeGhostComponent,
    QuillEditorWrapperComponent,
    QuillEditorFormControlComponent,
    ReplyButtonsComponent,
    CreateReportModalComponent,
    ThreadSearchComponent,
    ThreadWorkflowProgressIndicatorComponent,
    ThreadWorkflowPopupComponent,
    ThreadPreviewComponent,
    FocusActionWizardComponent,
    ThreadWorkflowStatusComponent,
    DashboardGreetingBannerComponent,
    ConfirmPromptComponent,
    DueDatesComponent,
    EditThreadDatesComponent
];

@NgModule({
    imports: [
        CommonModule,
        FindexUiModule,
        FxUiModule,
        PortalModule,
        AnalyticsModule,
        RouterModule,
        ModalsModule,
        SharedModule,
        NotificationsModule,
        ReactiveFormsModule,
        QuillModule,
        FormsModule,
        MatDialogModule,
        MatTableModule,
        FxTableModule,
        ThreadsUiPipesModule,
        PluginsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatFormFieldModule,
        MatInputModule,
        MatTooltipModule,
        LuxonDateModule
    ],
    declarations: [
        ...exportDeclarations,
        UiCardPortalComponent,
        ScrollToDirective,
        ReplyComponent,
        ReplyButtonsComponent,
        EditTitleComponent
    ],
    exports: [...exportDeclarations, ThreadsUiPipesModule, ModalsModule],
    providers: [MultiComponentService],
    entryComponents: [
        ToastComponent,
        InviteClientModalComponent,
        ThreadsStateModalComponent,
        RenameFileModalComponent,
        ThreadAddParticipantComponent,
        ThreadRemoveParticipantComponent,
        CancelThreadModalComponent,
        CancelThreadReasonModalComponent,
        NewThreadModalComponent,
        CloseThreadPromptComponent,
        CreateReportModalComponent
    ]
})
export class ThreadsUiModule {}
