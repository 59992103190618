import {
    AfterContentInit,
    Component,
    ContentChildren,
    Input,
    OnChanges,
    QueryList,
    SimpleChanges,
    EventEmitter,
    Output,
    ViewChild,
    OnDestroy,
    ElementRef
} from "@angular/core";
import { Subscription } from "rxjs";
import { delay } from "rxjs/operators";
import { StepLayoutComponent } from "./step-layout/step-layout.component";

export enum ButtonType {
    Backward = "backward",
    Forward = "forward",
    Finish = "finish"
}
export interface IStepConfigurationButton {
    title: string;
    analyticsTitle?: string;
    isDisabled: boolean;
    isHidden: boolean;
    type: ButtonType;
}
export interface IStepConfiguration {
    name?: string;
    buttons: IStepConfigurationButton[];
    stepIndex: number;
}

@Component({
    selector: "multi-component-layout",
    templateUrl: "./multi-component-layout.component.html",
    styleUrls: ["./multi-component-layout.component.scss"]
})
export class MultiComponentLayoutComponent implements AfterContentInit, OnChanges, OnDestroy {
    @ViewChild("mainContent") contentDiv: ElementRef<HTMLDivElement>;
    @ContentChildren(StepLayoutComponent) steps: QueryList<StepLayoutComponent>;
    @Output() emitComplete = new EventEmitter();
    @Output() emitTransition = new EventEmitter();
    @Output() closeClicked = new EventEmitter();
    @Input() activeStepIndex = 0;
    buttonTypes = ButtonType;
    progress = 1;
    @Input() enableBreadCrumb = true;
    @Input() condenseBreadcrumb = false;    
    @Input() enableProgressBar = true;
    @Input() enableFooter = false;
    @Input() enableHeader = false;
    @Input() enableClose = false;
    @Input() fullscreen = false;
    @Input() contentClass = "";
    @Input() stepConfigurations: IStepConfiguration[];
    @Input() loading = false;
    @Input() analyticsPrefix = "";

    scrollToTopSub: Subscription;
    progressBarStepText = [];

    ngAfterContentInit() {
        const activeSteps = this.steps.filter(tab => tab.active);
        
        this.updateProgressBarText();

        if (activeSteps.length === 0) {
            this.selectStep(this.steps.toArray()[this.activeStepIndex] || this.steps.first);
        }
        this.scrollToTopSub = this.emitTransition.pipe(delay(50)).subscribe(() => {
            this.contentDiv.nativeElement.scrollTop = 0;
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.stepConfigurations?.currentValue) {
            this.stepConfigurations = changes?.stepConfigurations?.currentValue;

            this.updateProgressBarText();
        }
        if (!!this.steps?.length && typeof changes?.activeStepIndex?.currentValue === "number") {
            this.selectStepIndex(changes.activeStepIndex.currentValue);

            this.progressBarStepText = this.stepConfigurations.map(step => ({
                highlighted: step.stepIndex <= this.activeStepIndex,
                text: step.name
            }));
        }
    }

    updateProgressBarText() {
        if (this.fullscreen) {
            this.progressBarStepText = this.stepConfigurations.map(step => ({
                highlighted: step.stepIndex <= this.activeStepIndex,
                text: step.name
            }));
        }
    }

    selectStepIndex(selectedStepIndex: number) {
        this.selectStep(this.steps.toArray()[selectedStepIndex]);
    }

    async selectStep(selectedStep: StepLayoutComponent) {
        this.steps.toArray().forEach(step => (step.active = false));

        // HACK:  I hate using hacks this way but not sure which lifecycle methods to use to fix this.
        // Basically the ngFor in focus-wizard-component renders the step-layouts after the view is checked,
        // which results in an error on this line. Promise.resolve causes it to run on the next lifecycle
        Promise.resolve().then(() => {
            selectedStep.active = true;
        });

        this.activeStepIndex = this.steps.toArray().findIndex(step => step === selectedStep);
        this.progress = ((this.activeStepIndex + 1) / this.stepConfigurations.length) * 100;
    }

    selectNextStep() {
        if (this.activeStepIndex < this.steps.length - 1) {
            this.selectStep(this.steps.toArray()[this.activeStepIndex + 1]);
            this.emitTransition.emit(this.activeStepIndex);
        }
    }

    selectPreviousStep() {
        if (this.activeStepIndex > 0) {
            this.selectStep(this.steps.toArray()[this.activeStepIndex - 1]);
            this.emitTransition.emit(this.activeStepIndex);
        }
    }

    triggerAction(buttonType: ButtonType) {
        switch (buttonType) {
            case ButtonType.Backward:
                this.selectPreviousStep();
                return;
            case ButtonType.Forward:
                this.selectNextStep();
                return;
            case ButtonType.Finish:
                this.emitComplete.emit(true);
                return;
            default:
                return;
        }
    }

    ngOnDestroy() {
        if (this.scrollToTopSub) {
            this.scrollToTopSub.unsubscribe();
        }
    }
}
