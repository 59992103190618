import {
    IThread,
    Role,
    WorkflowStepType,
    IThreadUnresolvedNotifications,
    IThreadListing,
    IThreadWorkflowStep
} from "@findex/threads";
import { Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { EnvironmentSpecificConfig } from "../../../environment/environment.common";
import { AuthService, AppUser } from "../../../findex-auth";
import { Observable, Subscription } from "rxjs";
import { Subject } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { ThreadsStateModalComponent } from "../threads-state/thread-state-modal/threads-state-modal.component";
import { NewThreadModalComponent } from "../new-thread-modal/new-thread-modal.component";
import { IThreadWorkflowGroup, WorkflowService } from "../../services/workflow.service";
import { ENVIRONMENT } from "src/app/injection-token";
import { ThreadsService } from "../../services/threads.service";
import { ThreadStatus } from "../status-badge/status-badge.component";
import { NotificationsService } from "@findex/notifications-angular";
import { IEnrichedThreadListing } from "../threads-list-route/threads-list-route.component";
import { map, take } from "rxjs/operators";
import { HOT_JAR_EVENTS } from "../../../analytics/services/hotjarAnalytics.service";

@Component({
    selector: "thread-list-item",
    templateUrl: "./thread-list-item.component.html",
    styleUrls: ["./thread-list-item.component.scss"]
})
export class ThreadListItemComponent implements OnChanges, OnInit, OnDestroy {
    readonly jobStatusEnabled = this.environment.featureFlags.jobStatusEnabled;
    readonly showAccounts = this.environment.featureFlags.accountView;
    readonly roles = Role;
    readonly hotJarEvents = HOT_JAR_EVENTS;

    user$: Observable<AppUser>;
    currentWorkflowStep: IThreadWorkflowStep;
    stepTypes = WorkflowStepType;
    ThreadStatus = ThreadStatus;

    getUnread$ = new Subject<IThreadUnresolvedNotifications>();
    private unreadSub: Subscription;

    @Input() thread: IEnrichedThreadListing;
    thread$: Observable<IEnrichedThreadListing>;
    @Input() active: boolean;
    @Input() globalRole: Role;
    @Output() itemClicked = new EventEmitter<IThreadListing>();

    constructor(
        private dialog: MatDialog,
        private authService: AuthService,
        private workflowService: WorkflowService,
        private notificationsService: NotificationsService,
        private threadsService: ThreadsService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig
    ) {}

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        const { thread } = changes;
        if (thread && thread.currentValue) {
            const updatedThread: IThread = thread.currentValue;
            this.currentWorkflowStep = await this.workflowService.getCurrentStep(updatedThread.workflow);
            this.getUnread$.next({ unresolved: this.thread.unresolvedNotifications });
        }
    }

    ngOnInit() {
        this.user$ = this.authService.getUser();
    }

    async createTimeline() : Promise<void>{
        const currentUserId = await this.user$
            .pipe(
                map(user => user.id),
                take(1)
            ).toPromise();

        const config = {
            data: {
                thread: this.thread,
                role: this.globalRole,
                editModeEnable: false,
                currentUserId
            },
            panelClass: ["centered-modal"],
            disableClose: true
        };
        this.dialog.open(NewThreadModalComponent, config);
    }

    editTimeline() : void {
        const config = {
            data: {
                thread: this.thread,
                role: this.globalRole,
                editModeEnable: true
            },
            panelClass: ["centered-modal"],
            disableClose: true
        };
        this.dialog.open(NewThreadModalComponent, config);
    }

    async cancelTimeline() : Promise<void> {
        const workflow = await this.workflowService.resolveWorkflow(this.thread.workflow);
        const cancelStep = workflow.steps.find(
            step => step.internalId === ThreadStatus.cancelled || step.clientFacingId === ThreadStatus.cancelled
        );
        const shouldUpdateThread = await this.workflowService.handleActions(this.thread as IThread, cancelStep);
        if (shouldUpdateThread) {
            await this.threadsService.setActiveWorkflowStep(this.thread.id, cancelStep.clientFacingId).toPromise();
            this.notificationsService.deleteInChannel(this.thread.id);
        }
    }

    async manageStatus() {
        const groupedThreads: IThreadWorkflowGroup[] = await this.workflowService.groupThreadsByWorkflow([
            this.thread as IThread
        ]);
        const config = {
            data: {
                groupedThreads,
                role: this.globalRole
            },
            disableClose: true,
            backdropClass: "modal-backdrop",
            panelClass: ["modal-container", "threads-sidebar", "mat-dialog-no-styling"],
            maxWidth: "100%",
            maxHeight: "100%",
            minHeight: "100%"
        };
        this.dialog.open(ThreadsStateModalComponent, config);
    }

    selected(event: MouseEvent, thread: IEnrichedThreadListing) {
        event.stopPropagation();
        this.getUnread$.next({ unresolved: 0 });
        this.itemClicked.next(thread);
    }

    ngOnDestroy(): void {
        if (this.unreadSub) this.unreadSub.unsubscribe();
    }
}
