import { Directive, Input, OnChanges, SimpleChanges, ElementRef, Output, EventEmitter } from "@angular/core";

@Directive({ selector: "[scrollTo]" })
export class ScrollToDirective implements OnChanges {
    @Input() scrollTo: boolean;

    @Output() scroll = new EventEmitter<void>();

    constructor(private element: ElementRef) {}

    ngOnChanges(changes: SimpleChanges) {
        const { scrollTo } = changes;
        if (scrollTo.currentValue) {
            setTimeout(() => {
                this.element.nativeElement.scrollIntoView({ block: "center" });
                this.scroll.emit();
            }, 500);
        }
    }
}
