import { Component, Input, Output, EventEmitter, ViewChild, forwardRef, OnInit } from "@angular/core";
import { IParticipant, IRequestFileData, IRequestItem, IRequestTextData } from "@findex/threads";
import { RequestResponseComponent } from "../request-response/request-response";
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from "@angular/forms";
const CONTROL_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => RequestTodoActionsComponent),
    multi: true
};

@Component({
    selector: "request-todo-actions",
    templateUrl: "./request-todo-actions.component.html",
    styleUrls: ["./../request-common.scss", "./request-todo-actions.component.scss"],
    providers: [CONTROL_VALUE_ACCESSOR]
})
export class RequestTodoActionsComponent implements  ControlValueAccessor, OnInit {
    @ViewChild(RequestResponseComponent) requestResponseComponent: RequestResponseComponent;

    showTextResponse = false;
    showAttach = false;
    isAddingNewResponse = false;
    onChanged: (newValue?: IRequestItem) => void = () => {};
    onTouched: () => void = () => {};

    @Output() textInput = new EventEmitter<{ requestItem: IRequestItem; value: any }>();
    @Output() fileAttached = new EventEmitter<{ requestItem: IRequestItem; file: File }>();
    @Output() fileRemoved = new EventEmitter<{ requestItem: IRequestItem; file: IRequestFileData }>();
    @Output() fileDownloaded = new EventEmitter<{ requestItem: IRequestItem; file: IRequestFileData }>();

    @Input() requestItem: IRequestItem;
    @Input() userId: string;
    @Input() analyticsPrefix: string;
    @Input() readonly = false;
    @Input() participants: IParticipant[] = [];

    comments: IRequestTextData[] = [];
    files: IRequestFileData[] = [];

    constructor() {}

    ngOnInit(): void {
        const commentsByParticipants = this.getCommentByParticipants(this.participants);
        this.comments = commentsByParticipants.sort((firstDate, secondDate) => this.sortCommentsByModified(firstDate.modifiedAt, secondDate.modifiedAt));
        this.files = this.requestItem.response.data.state;
    }

    private sortCommentsByModified(firstDate: string, secondDate: string): number {
        return new Date(firstDate).valueOf() - new Date(secondDate).valueOf();
    }

    getCommentByParticipants(participants: IParticipant[]): IRequestTextData[] {
        const comment = this.formatAsComent(this.requestItem.response.text.state);
        return participants.reduce((accumulator, participant) => {
            const emptyRequestText = {modifiedBy: participant.id, modifiedAt: new Date().toISOString(), textinput: ""};
            if(!comment) {
                return [...accumulator, emptyRequestText];
            }
            const requestTextData = comment.filter(({modifiedBy}) => modifiedBy === participant.id);
            return (requestTextData.length) ?  [...accumulator, ...requestTextData ] : [...accumulator, emptyRequestText ];
        }, []);
    }

    formatAsComent(comment: IRequestTextData[]): IRequestTextData[] {
        if(comment && (typeof comment === "string" || comment["textinput"])) {
            return [{
                    modifiedAt: comment["modifiedAt"],
                    modifiedBy: comment["modifiedBy"],
                    textinput: comment ?? comment["textinput"]
                }]
        }
        return comment;
    }

    downloadFile(fileDownloaded: IRequestFileData): void {
        this.fileDownloaded.emit({ requestItem: this.requestItem, file: fileDownloaded });
    }

    toggleTextResponse(): void {
        const {textinput} = this.comments.find(item => item.modifiedBy === this.userId);
        if(!textinput) {
            this.isAddingNewResponse = !this.isAddingNewResponse;
            return;
        }
        this.showTextResponse = !this.showTextResponse;
        this.discardNewResponse();
    }

    discardChanges(): void {
        this.showTextResponse = false;
        this.discardNewResponse();
    }

    discardNewResponse(): void {
        this.isAddingNewResponse = false;
    }

    toggleAttach(): void {
        this.showAttach = !this.showAttach;
    }

    submitTextResponse(value: IRequestTextData): void {
        if(this.isAddingNewResponse) {
            this.comments = [...this.comments, value];
            this.isAddingNewResponse = !this.isAddingNewResponse;
        } else {
            this.comments = this.comments.map(comment => {
                const newComment = {
                    ...comment
                }
                if(
                    comment.modifiedBy === this.userId &&
                    comment.modifiedAt === value.modifiedAt
                ) {
                    newComment.textinput = value.textinput;
                    newComment.modifiedAt = new Date().toISOString();
                }
                return newComment;
            })
        }
        this.requestItem.response.text.state = this.comments;
        this.textInput.emit({ requestItem: this.requestItem, value: this.comments});
        this.discardChanges();
    }

    submitNewResponse(newComment: IRequestTextData): void {
        this.comments = [...this.comments, newComment];
        this.requestItem.response.text.state = this.comments;
        this.textInput.emit({ requestItem: this.requestItem, value: this.comments});
        this.showTextResponse = false;
        this.discardNewResponse();
    }

    deleteTextResponse(toDelete: IRequestTextData): void {
        this.comments = this.comments.map(comment => {
            if (comment === toDelete) {
                comment.textinput = "";
            }
            return comment;
        } );
        this.requestItem.response.text.state = this.comments;
        this.textInput.emit({ requestItem: this.requestItem, value: this.comments});
        this.discardChanges()
    }

    attachFile(attachment: File): void {
        this.files = [...this.files, {
            filename: attachment.name,
            actorId: this.userId,
            timestamp: new Date().toISOString()
        }];
        this.fileAttached.emit({ requestItem: this.requestItem, file: attachment });
        this.showAttach = false;
    }

    removeFile(removedFile: IRequestFileData): void {
        this.files = this.files.filter(file => file !== removedFile)
        this.requestItem.response.data.state = this.files;
        this.fileRemoved.emit({ requestItem: this.requestItem, file: removedFile });
    }

    responseUpdated(_response: string): void {
        this.onTouched();
    }

    writeValue(value: IRequestItem): void {
        this.requestItem = value;
    }

    registerOnChange(fn: any): void {
        this.onChanged = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
}
