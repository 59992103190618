<div class="user-detail" @fadeIn *ngIf="user$ | async as user">
    <fx-avatar [name]="user?.profile?.name" size="medium" [inverted]="false" [image]="user.id | profilePicture"></fx-avatar>
    <div class="user-detail-info">
        <p>{{ user?.profile?.name }}</p>
        <p class="user-detail-info-title">{{ user?.profile?.title }}</p>
        <div *ngIf="meetingStart" class="user-detail-time">
            <i class="las la-calendar"></i> {{ meetingStart | date: "d MMM, h.mm aaa" }}
        </div>
    </div>
</div>
