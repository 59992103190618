import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { IInvitation } from "@findex/calendar-types";
import { IAvatarContent } from "@findex/fx-ui";
import { IParticipant, IThread, Role } from "@findex/threads";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { ParticipantCache } from "projects/portal-modules/src/lib/threads-ui/services/participant-cache.service";
import { combineLatest, Observable } from "rxjs";
import { map, shareReplay, switchMap } from "rxjs/operators";
import { ENVIRONMENT } from "src/app/injection-token";
import { environmentCommon } from "src/environments/environment";
import { CalendarState, MeetingState } from "../../calendar-state.type";
import { CalendarCardService } from "../../services/calendar-card.service";
import { CalendarService } from "../../services/calendar.service";

export interface CalendarDetailsModel {
    state$: Observable<CalendarState>;
    userId$: Observable<string>;
    thread$: Observable<IThread>;
    meetingState$: Observable<string>;
    invitedToMeeting$: Observable<boolean>;
    avatar$: Observable<IAvatarContent[]>;
    meetingName$: Observable<string>;
    invitationCancelled: boolean;
    start: Date;
    end: Date;
    role: Role;
    invitationId: string;
    appointmentConfirmed: boolean;
    organiser: IParticipant;
}

@Component({
    selector: "calendar-details-modal",
    templateUrl: "./calendar-details-modal.component.html",
    styleUrls: ["./calendar-details-modal.component.scss"]
})
export class CalendarDetailsModalComponent implements OnInit {
    readonly notInvitedToMeetingText = this.environment.featureFlags.text.default.notInvitedToMeeting;
    readonly roles = Role;
    readonly meetingStates = MeetingState;
    readonly meetingTimeFormat = environmentCommon.dateFormats.short;

    state$: Observable<CalendarState>;
    userId$: Observable<string>;
    thread$: Observable<IThread>;
    meetingState$: Observable<string>;
    invitedToMeeting$: Observable<boolean>;
    avatar$: Observable<IAvatarContent[]>;
    meetingName$: Observable<string>;
    invitation$: Observable<IInvitation>;
    attendees$: Observable<IParticipant[]>;
    canRescheduleMeeting$: Observable<boolean>;

    loaded$: Observable<any>;

    invitationCancelled: boolean;
    start: Date;
    end: Date;
    role: Role;
    invitationId: string;
    appointmentConfirmed: boolean;
    organiser: IParticipant;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: CalendarDetailsModel,
        private dialog: MatDialogRef<CalendarDetailsModalComponent, { openCalendarModal: string }>,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private calendarService: CalendarService,
        private participantsCache: ParticipantCache
    ) {}

    ngOnInit() {
        const {
            state$,
            userId$,
            thread$,
            meetingState$,
            invitedToMeeting$,
            avatar$,
            meetingName$,
            invitationCancelled,
            start,
            end,
            role,
            invitationId,
            appointmentConfirmed,
            organiser
        } = this.data;

        this.state$ = state$;
        this.userId$ = userId$;
        this.thread$ = thread$;
        this.meetingState$ = meetingState$;
        this.invitedToMeeting$ = invitedToMeeting$.pipe(shareReplay());
        this.canRescheduleMeeting$ = this.invitedToMeeting$.pipe(
            map((isInvited: boolean) => (this.role !== this.roles.Client || isInvited) && this.appointmentConfirmed)
        );
        this.avatar$ = avatar$;
        this.meetingName$ = meetingName$;
        this.invitationCancelled = invitationCancelled;
        this.start = start;
        this.end = end;
        this.role = role;
        this.invitationId = invitationId;
        this.appointmentConfirmed = appointmentConfirmed;
        this.organiser = organiser;

        this.attendees$ = state$.pipe(
            switchMap(state => {
                const participantIds = CalendarCardService.getAllAttendees(state).map(attendee => attendee.id);
                return this.participantsCache.getParticipants(participantIds);
            })
        );

        this.invitation$ = this.calendarService.getClientInvitation(invitationId);

        this.loaded$ = combineLatest([this.attendees$, this.invitation$]);
    }

    openCalendarModal(action: string) {
        this.dialog.close({ openCalendarModal: action });
    }
}
