import { Directive, Input, OnChanges, SimpleChanges, TemplateRef } from "@angular/core";
import { ReplaySubject } from "rxjs";

@Directive({
    selector: "[fxColumn]"
})
export class FxColumnDirective implements OnChanges {
    @Input("fxColumn") id: string;
    @Input("fxColumnLabel") label: string;
    @Input("fxColumnSortable") sortable: boolean;
    @Input("fxColumnMobileCollapse") mobileCollapse: boolean;
    @Input("fxColumnMobileHideLabel") mobileHideLabel: boolean;
    @Input("fxColumnSortAnalyticsEvent") sortAnalyticsEvent: string|string[];

    idUpdated = new ReplaySubject<string>();

    constructor(public template: TemplateRef<any>) {}

    ngOnChanges(changes: SimpleChanges) {
        const { id } = changes;

        if (id) {
            this.idUpdated.next(id.currentValue);
        }
    }
}
