import { FormGroup } from "@angular/forms";
import { ChangeDetectorRef, Component, Inject, OnInit, QueryList, ViewChildren } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { combineLatest, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { IRequestModalData, RequestCommonComponent } from "../request-common.component";
import { VaultRequestService } from "../../../services/vault-request.service";
import { VaultService } from "@findex/vault";
import { CompleteRfiModalComponent } from "../../upload/complete-rfi-modal/complete-rfi-modal.component";
import { IThreadCard } from "@findex/threads/dist/interfaces/IThreadCard";
import { AnalyticsService, GA_EVENTS_PREFIX } from "projects/portal-modules/src/lib/analytics";
import { CardStatus, VaultStateAction } from "@findex/threads";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { TaskActionService } from "projects/portal-modules/src/lib/shared/components/actionable-card/task-action.service";
import { ThreadCardService } from "projects/portal-modules/src/lib/threads-ui/services/thread-card.service";
import { PermissionService } from "projects/portal-modules/src/lib/threads-ui/services/permissions.service";
import { RequestTodoActionsComponent } from "../request-todo-actions/request-todo-actions.component";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { CardResources } from "projects/portal-modules/src/lib/threads-ui/interfaces/IUiCard";

@Component({
    selector: "action-request",
    templateUrl: "./action-request.component.html",
    styleUrls: ["./../request-common.scss", "./action-request.component.scss"]
})
export class ActionRequestComponent extends RequestCommonComponent implements OnInit {
    @ViewChildren(RequestTodoActionsComponent) requestTodoActionComponents: QueryList<RequestTodoActionsComponent>;

    readonly ANALYTICS_PREFIX = GA_EVENTS_PREFIX.RFI_ACTION_REQUEST;
    readonly VAULT_ACTION_EDIT_RESPONSE = VaultStateAction.EditResponse;
    readonly ERROR_MESSAGE_ON_DELETE = "Sorry, something went wrong";
    isReadonly: boolean;
    loader = new Loader();
    errorMessage: string;
    cardStatuses = CardStatus;
    initialActionedPercentage?: number;

    formGroup = new FormGroup({});

    canEditCard$: Observable<boolean>;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        private modalData: IRequestModalData,
        private dialogRef: MatDialogRef<ActionRequestComponent>,
        private taskActionService: TaskActionService,
        private cardService: ThreadCardService,
        private permissionService: PermissionService,
        vaultRequestService: VaultRequestService,
        authService: AuthService,
        vaultService: VaultService,
        analytics: AnalyticsService,
        dialog: MatDialog,
        cdr: ChangeDetectorRef

    ) {
        super(vaultRequestService, vaultService, authService, analytics, cdr, dialog);
    }

    async ngOnInit() {
        await this.initData(this.modalData);
        this.isReadonly = this.modalData.readonly;
        this.initialActionedPercentage = this.actionedPercentage;
        this.canEditCard$ = this.canEditRequest();
    }

    canEditRequest(): Observable<boolean> {
        return combineLatest([
            this.state$,
            this.card$,
            this.permissionService.checkPermissions(this.currentUserRole, "UpdateRequestOrVaultCard"),
            this.permissionService.checkPermissions(this.currentUserRole, "ThreadUpdateAll"),
            this.userId$
        ]).pipe(
            map(([_state$, card, hasUpdateRFIPermission, hasThreadUpdateAllPermission, currentUserId]) => {
                if (card.status === CardStatus.Disabled) {
                    return false;
                }
                if (hasThreadUpdateAllPermission || (card.createdBy === currentUserId && hasUpdateRFIPermission)) {
                    return true;
                }
                return false;
            })
        );
    }

    getCardResources(): Partial<CardResources> {
        return {
            card$: this.card$,
            replies$: this.replies$,
            role: this.currentUserRole,
            state$: this.state$,
            thread$: this.thread$
        };
    }

    replySaved() {
        this.formGroup.controls.replyMessage.markAsPristine();
    }

    private async savePendingChanges(): Promise<void> {
        this.todoItemActionComponents.forEach(component => {
            if (component.requestResponseComponent?.showCompose) {
                component.submitTextResponse(component.requestResponseComponent.editResponse.value);
            }
        });

        if (this.threadCardRepliesComponent?.showCompose) {
            await this.threadCardRepliesComponent.reply(
                this.threadCardRepliesComponent.formGroup.controls.replyMessage as any
            );
        }

        this.threadCardRepliesComponent?.replyComponents?.forEach(async replyComponent => {
            const replyValue = replyComponent.editReplyMessage?.value;
            if (replyValue) {
                await replyComponent.updateReply(replyComponent.reply, replyComponent.editReplyMessage);
            }
        });
    }

    async save(card: IThreadCard): Promise<void> {
        const actionsChanged = this.actionedPercentage !== this.initialActionedPercentage;
        const shouldComplete = !this.isReadonly && this.actionedPercentage === 100 && actionsChanged
        if (shouldComplete) {
            const complete = await this.dialog
                .open(CompleteRfiModalComponent, { panelClass: "centered-modal" })
                .afterClosed()
                .toPromise();

            if (!complete) {
                await this.markAllComplete(false, card);
            } else {
                await this.savePendingChanges();
                this.closeDialog();
            }
        } else {
            await this.savePendingChanges();
            this.closeDialog();
        }
    }

    closeDialog() : void {
        this.dialogRef.close();
    }

    async deleteCard(): Promise<void> {
        try {
            this.loader.show();
            await this.cardService.deleteCard(this.thread.id, this.card.id).toPromise();
            this.closeDialog()
        } catch {
            this.errorMessage = this.ERROR_MESSAGE_ON_DELETE;
        } finally {
            this.loader.hide();
        }
    }

    async openEditRequest(): Promise<void> {
        this.closeDialog()
        this.loader.show();
        await this.taskActionService.action(this.VAULT_ACTION_EDIT_RESPONSE, this.getCardResources());
        this.loader.hide();
    }
}
