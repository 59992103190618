import { Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { IThreadListing, Role } from "@findex/threads";
import { IThreadGroupListing, ThreadGrouper } from "./thread-grouper";
import { defer, EMPTY, Observable, Subscription } from "rxjs";
import { AnalyticsService, GA_EVENTS, HotjarAnalyticsService } from "../../../analytics";
import { WorkflowService } from "../../services/workflow.service";
import { WindowListenersService } from "../../../shared/services/window-listeners.service";
import { IEnrichedThreadListing } from "../threads-list-route/threads-list-route.component";
import { FormControl } from "@angular/forms";
import { ENVIRONMENT } from "src/app/injection-token";
import { EnvironmentSpecificConfig } from "../../../environment/environment.common";

export const HIDE_LIST_SIZE = 1024;
@Component({
    selector: "thread-list",
    templateUrl: "./thread-list.component.html",
    styleUrls: ["./thread-list.component.scss"]
})
export class ThreadListComponent implements OnChanges, OnInit, OnDestroy {
    @Input() threadListings: IEnrichedThreadListing[];
    @Input() activeId: string;
    @Input() globalRole: Role;
    @Input() statusFilter: string;
    @Input() loading: boolean;
    @Output() statusFilterUpdate = new EventEmitter<string>();
    @Output() selected = new EventEmitter<IThreadListing>();

    readonly gaEvents = GA_EVENTS;
    readonly role = Role;
    threadListFilterStatus = this.environment.featureFlags.threadListFilterStatus;
    threadStatusFilterFormControl = new FormControl(this.threadListFilterStatus.active?.toLowerCase() || "");
    smallWidth: boolean = window.innerWidth < HIDE_LIST_SIZE;
    filter = "";

    threadGroups$: Observable<IThreadGroupListing[]>;
    expandSearch: boolean;
    windowResizeSub: Subscription;

    constructor(
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private analyticsService: AnalyticsService,
        private hotjarAnalyticsService: HotjarAnalyticsService,
        private workflowService: WorkflowService,
        private windowListenersService: WindowListenersService,
        private threadGrouper: ThreadGrouper
    ) {
    }

    ngOnInit() {
        this.windowResizeSub = this.windowListenersService.resize.subscribe(() => {
            this.smallWidth = this.windowListenersService.isWindowSmaller(HIDE_LIST_SIZE);
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.threadListings) {
            this.threadGroups$ = this.groupThreads();
        }

        if (changes.statusFilter) {
            this.threadStatusFilterFormControl.setValue(changes.statusFilter.currentValue || "");
        }
    }

    private triggerSurvey(threads: IThreadListing[]) {
        this.analyticsService.initialized.subscribe(async initialized => {
            if (!initialized) {
                return;
            }

            const listThreadWorkflow = threads.map(thread => this.workflowService.getCurrentStep(thread.workflow));

            const checkCancelledTimeline = listThreadWorkflow.find(
                step => !this.workflowService.isStepOpen(step)
            );

            if (checkCancelledTimeline) {
                this.hotjarAnalyticsService.triggerSurvey(
                    `${checkCancelledTimeline.internalId || checkCancelledTimeline.clientFacingId}`
                );
            }
        });
    }

    private groupThreads(): Observable<IThreadGroupListing[]> {
        if (this.threadListings) {
            this.triggerSurvey(this.threadListings);
            return defer(() => this.threadGrouper.groupThreads(this.threadListings));
        }

        return EMPTY;
    }

    ngOnDestroy() {
        if (this.windowResizeSub) {
            this.windowResizeSub.unsubscribe();
        }
    }
}
