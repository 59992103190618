import { Component, Input } from "@angular/core";
import { IThreadPreview } from "@findex/threads";

@Component({
    selector: "thread-preview",
    templateUrl: "thread-preview.component.html",
    styleUrls: ["thread-preview.component.scss"]
})
export class ThreadPreviewComponent {
    @Input() preview: IThreadPreview;
    @Input() hasMultipleParticipants: boolean;
    @Input() userId: string;
    @Input() createdAt: string;

    constructor() {}
}
