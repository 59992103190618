import { Injectable, Injector } from "@angular/core";
import { ComponentType } from "@angular/cdk/portal";
import { RegistryLibrary } from "./RegistryLibrary";
import { Observable } from "rxjs";
import { Route } from "@angular/router";
import { IThread } from "@findex/threads";
import { MatDialogConfig } from "@angular/material/dialog";
import { CardResources } from "../../threads-ui/interfaces/IUiCard";

export type ViewExtension = {
    label: string;
    componentRef: ComponentType<any>;
    showView$?: Observable<boolean>;
};

export type RouteExtension<RouteEntity = unknown> = {
    label: string;
    icon: string;
    route: Route;
    showIcon: (entity?: RouteEntity) => Observable<boolean>;
};

export type FocusWizardExtension = {
    componentRef: ComponentType<any>;
    taskLabel: string;
};

interface ICreateMenuExtensionOption {
    title: string;
    description: string;
    analyticsEvent: string;
    permission: string;
}

interface IMenuExtensionWithAction extends ICreateMenuExtensionOption {
    action$: (thread?: IThread) => Observable<any>;
}
interface IMenuExtensionWithComponentRef extends ICreateMenuExtensionOption {
    componentRef: ComponentType<any>;
}

export type CreateMenuExtensionOption = IMenuExtensionWithAction | IMenuExtensionWithComponentRef;

export type CreateMenuExtension = {
    title: string;
    description: string;
    extensions: CreateMenuExtensionOption[];
};

export type TaskAction<ActionResult = unknown, ActionInput = unknown> = {
    /**
     * Cards you can run this action on, will fail if not in this array
     */
    cardTypes: string[];
    action: (
        cardResources: Partial<CardResources>,
        injector?: Injector,
        data?: ActionInput
    ) => Promise<ActionResult>;
    buttonLabel?: string;
    analyticsEvents?: string[];
    statusIcon?: string;
};

export type CreateCardExtension = {
    title: string;
    analyticsEvent: string;
    permission: string | string[];
    icon: string;
    componentRef: ComponentType<any>;
    data?: any;
    config?: MatDialogConfig;
};

export type BannerExtension = {
    componentRef: ComponentType<any>;
};

@Injectable()
export class Libraries {
    readonly createCard = new RegistryLibrary<CreateCardExtension>();
    readonly cardViews = new RegistryLibrary<ComponentType<any>>();
    readonly focusWizardViews = new RegistryLibrary<FocusWizardExtension>();
    readonly threadViews = new RegistryLibrary<ViewExtension>();
    readonly accountRoutes = new RegistryLibrary<RouteExtension>();
    readonly insightsRoutes = new RegistryLibrary<RouteExtension>();
    readonly appRoutes = new RegistryLibrary<RouteExtension>();
    readonly extensionMenu = new RegistryLibrary<CreateMenuExtension>();
    readonly taskActions = new RegistryLibrary<TaskAction>();
    readonly banners = new RegistryLibrary<BannerExtension>();
}
