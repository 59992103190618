<fx-accordion
    type="secondary"
    [custom]="true"
    [isExpanded]="expandInitial"
    [toggleGutters]="true"
    headerColour="transparent"
>
    <ng-container accordion-header>
        <div
            class="upload-rfi-group-title"
            [analyticsClick]="gaEvents.RFI_CHILDCOLLAPSE"
            [analyticsLabel]="item.displayName"
        >
            <span class="title">{{ item.displayName }}</span>
            <div>
                <span *ngIf="item.informationType !== rfiTypes.TextInput">{{ item.files?.length || 0 }}</span>
            </div>
        </div>
        <i
            class="fx-accordion-toggle-icon las la-angle-down"
            [analyticsClick]="gaEvents.RFI_CHILDCOLLAPSE"
            [analyticsLabel]="item.displayName"
        ></i>
    </ng-container>
    <ng-container accordion-content>
        <ng-container
            [ngSwitch]="item.informationType"
            *ngIf="
                !closed &&
                (role | permissions: 'CreateVaultCard' | async) &&
                !(role | permissions: 'UploadSignableDocumentVaultCard' | async)
            "
        >
            <data-input
                *ngSwitchCase="rfiTypes.TextInput"
                [name]="item.displayName"
                (textFile)="uploadFile.emit($event)"
                [item]="item"
            ></data-input>
            <file-input
                *ngSwitchCase="rfiTypes.Document"
                [enableDirectoryDrop]="true"
                [analyticsPrefix]="ANALYTICS_PREFIX"
                (file)="uploadFile.emit($event)"
            ></file-input>
            <file-input
                *ngSwitchDefault
                [enableDirectoryDrop]="true"
                [analyticsPrefix]="ANALYTICS_PREFIX"
                (file)="uploadFile.emit($event)"
            ></file-input>
        </ng-container>
        <div
            *ngIf="
                item.files &&
                item.files.length > 0 &&
                (item.informationType !== rfiTypes.TextInput ||
                    !(
                        (role | permissions: 'CreateVaultCard' | async) &&
                        !(role | permissions: 'UploadSignableDocumentVaultCard' | async)
                    ) ||
                    closed)
            "
            class="files"
        >
            <div class="file" *ngFor="let file of item.files; trackBy: trackFile">
                <ng-container *ngIf="item.informationType !== rfiTypes.TextInput">
                    <div class="details">
                        <ng-container *ngIf="file.deleting">
                            Removing file, please wait....
                        </ng-container>

                        <ng-container *ngIf="file.downloading">
                            Downloading, please wait....
                        </ng-container>

                        <ng-container *ngIf="!file.deleting && !file.downloading">
                            <div
                                class="filename filename--uploading"
                                [ngClass]="{ 'filename--uploading': file.progress }"
                                (click)="!file.uploadSub && openFile.emit(file)"
                            >
                                <i class="las la-file"></i>{{ file.filename }}
                            </div>
                            <div class="upload-percent" *ngIf="file.uploadSub">
                                {{ file.progress | number: "0.0-0" }}%
                            </div>
                            <div
                                class="cancel"
                                *ngIf="!closed && (role | permissions: 'UploadRFI' | async)"
                                (click)="cancel(file)"
                            >
                                <i class="las la-times"></i>
                            </div>
                        </ng-container>
                    </div>
                    <div class="progress-bar" *ngIf="file.uploadSub">
                        <div class="progress-bar-inner" [ngStyle]="{ width: file.progress + '%' }"></div>
                    </div>
                </ng-container>
                <ng-container *ngIf="item.informationType === rfiTypes.TextInput">
                    <div class="details">
                        <ng-container *ngIf="file.deleting">
                            Removing file, please wait....
                        </ng-container>

                        <ng-container *ngIf="file.downloading">
                            Downloading, please wait....
                        </ng-container>

                        <ng-container *ngIf="!file.deleting && !file.downloading">
                            <div class="filename-text">
                                <div><i class="las la-pen"></i></div>
                                {{ getTextContent(item, file) | async }}
                            </div>
                            <div
                                class="cancel"
                                *ngIf="!closed && (role | permissions: 'UploadRFI' | async)"
                                (click)="cancel(file)"
                            >
                                <i class="las la-times"></i>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-container>
</fx-accordion>
