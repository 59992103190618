<div class="fx-threads">
    <div class="fx-threads-frame">
        <fx-layout [gutters]="false">
            <fx-layout-column
                class="fx-threads-frame-column"
                *ngIf="!smallWidth || !activeId"
                [sizes]="{ mobile: 24, laptop: 8 }"
            >
                <ng-container *ngIf="{ threadGroups: threadGroups$ | async }; let context">
                    <div class="threads-filter">
                        <div class="threads-filter-status" *ngIf="threadListFilterStatus && !expandSearch">
                            <div class="fx-select-box">
                                <select
                                    class="thread-filter-status-select"
                                    analyticsClick="app_timelinestatusselect"
                                    #threadStatusFilter
                                    (input)="statusFilterUpdate.emit($event.target.value)"
                                    [formControl]="threadStatusFilterFormControl"
                                >
                                    <option value="">All Timelines</option>
                                    <ng-container *ngFor="let status of threadListFilterStatus | keyvalue">
                                        <option [value]="status.key">
                                            {{
                                                status.value === threadListFilterStatus.active
                                                    ? status.value + " Timelines"
                                                    : status.value
                                            }}
                                        </option>
                                    </ng-container>
                                </select>
                            </div>
                        </div>
                        <div *ngIf="globalRole !== role.Client" class="threads-filter-search-container">
                            <app-thread-search
                                [analyticsClickEvent]="gaEvents.APP_SEARCHTIMELINES"
                                (search)="filter = $event"
                                (active)="expandSearch = $event"
                                placeholder="Search timelines"
                            ></app-thread-search>
                        </div>
                    </div>

                    <ng-container *ngIf="!loading && context.threadGroups; else loadingTemplate">
                        <p class="no-threads" *ngIf="!context.threadGroups?.length">You have no messages</p>

                        <thread-list-node
                            *ngIf="context.threadGroups?.length"
                            [filter]="filter"
                            [globalRole]="globalRole"
                            [activeId]="activeId"
                            [nodes]="$any(context.threadGroups)"
                            [userStatusFilter]="statusFilter"
                            (userStatusChanged)="statusFilterUpdate.emit($event)"
                            (itemClicked)="selected.emit($event)"
                        ></thread-list-node>
                    </ng-container>
                </ng-container>
                <ng-template #loadingTemplate>
                    <thread-list-node-ghost [globalRole]="globalRole"></thread-list-node-ghost>
                </ng-template>
            </fx-layout-column>

            <fx-layout-column
                *ngIf="!smallWidth || activeId"
                [sizes]="{ mobile: 24, laptop: 16 }"
                customClass="thread-main"
            >
                <fx-layout [gutters]="false">
                    <fx-layout-column [sizes]="{ mobile: 24 }">
                        <ng-content></ng-content>
                    </fx-layout-column>
                </fx-layout>
            </fx-layout-column>
        </fx-layout>
    </div>
</div>
