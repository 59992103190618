<div class="banner-item dark" *ngIf="banner$ | async as banner">
    <div class="fx-container fx-container--fluid" *ngIf="banner">
        <div class="banner-item-content">
            <view-extension
                *ngIf="banner.uiCard.card$ | async as card"
                [componentRef]="banner.componentRef"
                [injector]="banner.injector"
                class="banner-item-component"
            ></view-extension>
            <button class="banner-item-close" (click)="close.emit(banner.notification.channel)">
                <i class="las la-times"></i>
            </button>
        </div>
    </div>
</div>