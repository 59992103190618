import { Injectable } from "@angular/core";
import { IThread } from "@findex/threads";
import { ThreadCardService } from "../../services/thread-card.service";
import { ThreadsService } from "../../services/threads.service";
import { CalendarService } from "projects/default-plugins/calendar/services/calendar.service";

@Injectable()
export class CloseThreadService {
    constructor(
        private cardsService: ThreadCardService,
        private threadsService: ThreadsService,
        private calendarService: CalendarService
    ) { }

    async getPendingTasks(threadId: string): Promise<string[]> {
        const cards = await this.cardsService.getCards(threadId).toPromise();
        const states = await Promise.all(cards.map(card => this.cardsService.getCardState(threadId, card.id).toPromise()));
        return states.filter(state => state.state)
            .filter(({ state }) => state.requestItems)
            .map(({ state }) => state)
            .filter(({ requestItems }) =>
                requestItems.some(({ response }) => response?.complete?.state !== true)
            ).map(({title}) => title);
    }

    async threadHasUpcomingMeetings(threadId: string): Promise<boolean> {
        const allUpcomingMeetings = await this.calendarService.getUpcomingMeetings().toPromise();
        return allUpcomingMeetings.some(meeting => meeting.threadId === threadId);
    }

    async findThreadsToMigrateMeetings(thread: IThread, userId: string): Promise<IThread[]> {
        const requiredParticipantIds = thread.participants.map(participant => participant.id);
        const activeThreads = await this.threadsService.getActiveThreadsByUserId(userId);
        return activeThreads
            .filter(activeThread => activeThread.id !== thread.id)
            .filter(activeThread => {
                const participantIds = activeThread.participants.map(participant => participant.id);

                return requiredParticipantIds.every(requiredParticipantId =>
                    participantIds.some(participantId => requiredParticipantId === participantId)
                );
            });
    }

    async migrateCalendarCards(threadId: string, targetThreadId: string) {
        return this.cardsService.migrateCalendarCards(threadId, targetThreadId).toPromise();
    }
}
