import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from "@angular/core";
import { VAULT_ACTION } from "@findex/vault";
import { IVaultItem } from "projects/default-plugins/vault/interfaces/IVaultItem";
import { AnalyticsService, GA_EVENTS } from "projects/portal-modules/src/lib/analytics";

@Component({
    selector: "vault-card-item",
    templateUrl: "./vault-card-item.component.html",
    styleUrls: ["./vault-card-item.component.scss"]
})
export class VaultCardItemComponent implements OnChanges {
    readonly vaultActions = VAULT_ACTION;
    readonly gaEvents = GA_EVENTS;

    @Input() icon: string;
    @Input() item: IVaultItem;
    @Input() disabled: boolean;
    @Input() canSign: boolean;
    @Input() canRename: boolean;
    @Input() canDelete: boolean;
    @Input() isReport: boolean;

    @Output() download = new EventEmitter<void>();
    @Output() preview = new EventEmitter<void>();
    @Output() sign = new EventEmitter<void>();
    @Output() delete = new EventEmitter<void>();
    @Output() rename = new EventEmitter<string>();

    displayName: string;
    renameMode = false;

    constructor(private analyticsService: AnalyticsService) {}

    ngOnChanges(changes: SimpleChanges) {
        const { item, canRename, canDelete } = changes;

        if (item) {
            this.displayName = this.item?.displayName;
        }

        if (canRename || canDelete) {
            if (!this.canRename && !this.canDelete) {
                this.renameMode = false;
            }
        }
    }

    renameItem(name: string): void {
        this.renameMode = false;

        if (name !== this.item.displayName) {
            this.rename.emit(name);
        }
    }

    downloadClicked() {
        if (this.isReport) {
            this.analyticsService.recordEvent("mouse-click", this.gaEvents.INSIGHTS_DOWNLOAD);
        } else {
            this.analyticsService.recordEvent("mouse-click", this.gaEvents.DOCUMENTS_DOWNLOAD);
        }

        this.download.emit();
    }
}
