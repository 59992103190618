import { Component, Output, EventEmitter, Input, Inject } from "@angular/core";
import { NgxDropzoneChangeEvent } from "ngx-dropzone";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { environmentCommon } from "projects/portal-modules/src/lib/environment/environment.common";
import { WindowListenersService } from "../../../../portal-modules/src/lib/shared/services/window-listeners.service";
import { EnvironmentSpecificConfig } from "../../../../portal-modules/src/lib/environment/environment.common";
import { ENVIRONMENT } from "../../../../../src/app/injection-token";

@Component({
    selector: "file-input",
    templateUrl: "./file-input.component.html",
    styleUrls: ["./file-input.component.scss"],
})
export class FileInputComponent {
    readonly uploadFilesizeLimitErrorMessage = environmentCommon.uploadFilesizeLimitErrorMessage;
    readonly uploadFilesizeLimitMB = environmentCommon.uploadFilesizeLimitMB;
    readonly gaEvents = GA_EVENTS;

    errorMessages = [];

    @Input() analyticsPrefix: string;
    @Input() supportedFiles: string;
    @Input() enableDirectoryDrop: boolean;
    isMobile = this.windowListenersService.isWindowSmaller(this.environment.featureFlags.windowWidthTabletBreakpoint);
    @Output() file = new EventEmitter<File>();

    constructor(
        private windowListenersService: WindowListenersService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig
    ) {}

    onSelect(event: NgxDropzoneChangeEvent) {
        this.errorMessages = [];
        for (const file of event.addedFiles) {
            if (file.size / (1024 * 1024) > this.uploadFilesizeLimitMB) {
                this.errorMessages.push(this.uploadFilesizeLimitErrorMessage.replace("<filename>", file.name));
            } else {
                this.file.emit(file);
            }
        }
    }
}
