import { Inject, Injectable } from "@angular/core";
import { IThread, IThreadCard, ICardEvent, Role } from "@findex/threads";
import { concat, Observable, Subject, ReplaySubject, defer, merge, EMPTY, of } from "rxjs";
import { shareReplay, filter, switchMap, distinct, map, mergeMap } from "rxjs/operators";
import { CardResources, IEventService, IUiCard } from "../interfaces/IUiCard";
import { Loader } from "../../shared/services/loader";
import { ThreadsWebsocketService } from "../../shared/services/threads-websocket.service";
import { CardStateResponse, ThreadsService } from "./threads.service";
import { ComponentType } from "@angular/cdk/portal";
import { CARD_LIBRARY } from "src/app/injection-token";
import { ILibrary } from "../../plugins";
import { ThreadCardService } from "./thread-card.service";

@Injectable({ providedIn: "root" })
export class UiCardService {
    activeCardScroller$ = new Subject<string>();
    constructor(
        private threadsService: ThreadsService,
        private cardService: ThreadCardService,
        private websocketService: ThreadsWebsocketService,
        @Inject(CARD_LIBRARY) private cardLibrary: ILibrary<ComponentType<any>>
    ) {}

    mapCard(thread: IThread, card: IThreadCard, role: Role): IUiCard {
        const { type, createdAt, modifiedAt } = card;
        const component = this.cardLibrary.resolve(type);

        if (!component) {
            console.warn("Unsupported card", card);
            return null;
        }
        const loader = new Loader();

        const eventsSubject = new Subject<ICardEvent>();
        const navigateToSubject = new ReplaySubject<void>(1);
        const cardResources = this.getCardResources(thread, card, role, navigateToSubject, eventsSubject);

        return {
            ...cardResources,
            timestamp: new Date(modifiedAt || createdAt).getTime(),
            component,
            loader,
            navigateToSubject,
            eventsSubject
        };
    }

    /**
     * maps cardResources without making any network requests until the respective properties are subscribed to.
     */
    getCardResources(
        thread: IThread,
        card: IThreadCard,
        role: Role,
        navigateToSubject?: Subject<void>,
        eventsSubject?: Subject<any>
    ): CardResources {
        const loader = new Loader();
        const eventService = this.getCardEvents(eventsSubject, loader, thread.id, card.id);
        const stateWrapper = this.getCardState(thread.id, card.id, loader);

        const state$ = stateWrapper.pipe(map(stateResponse => stateResponse.state));
        const replies$ = stateWrapper.pipe(
            map(state => state.cardReplies.filter(reply => reply.message)),
            map(replies => replies.sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()))
        );

        const thread$ = merge(of(thread), this.threadChanges(thread.id)).pipe(shareReplay());

        const card$ = merge(of(card), this.cardChanges(thread.id, card.id)).pipe(
            shareReplay()
        );

        return {
            threadId: thread.id,
            thread$,
            card$,
            cardId: card.id,
            state$,
            replies$,
            eventService,
            navigateTo$: navigateToSubject.asObservable(),
            role
        };
    }

    addCardEvent(allCards: IUiCard[], cardId: string, event: ICardEvent) {
        const card = allCards.find(uiCard => uiCard.cardId === cardId);
        if (!card) return console.warn("Could not find card", cardId, event);

        const eventTime = new Date(event.createdAt);
        card.eventsSubject.next(event);
        card.timestamp = eventTime.getTime();
    }

    getActiveCardScroller() {
        return this.activeCardScroller$.asObservable();
    }

    scrollToCard(cardId: string) {
        this.activeCardScroller$.next(cardId);
    }

    resetScroll() {
        this.activeCardScroller$.next("");
    }

    routeToCard(allCards: IUiCard[], cardId: string, isModalDisabled?: boolean) {
        const card = allCards.find(uiCard => uiCard.cardId === cardId);
        if (!card) return console.error("Could not find card with id", cardId);

        console.info("Routing to card", cardId);
        card.scrollTo = true;
        if (!isModalDisabled) {
            card.navigateToSubject.next(null);
        }

        this.scrollToCard(cardId);
    }

    compareCards(a: IUiCard, b: IUiCard) {
        return a.timestamp - b.timestamp;
    }

    private getCardEvents(
        subject: Subject<ICardEvent>,
        loader: Loader,
        threadId: string,
        cardId: string
    ): IEventService {
        let last: string;

        const loadHistorical = async (fromStart = false) => {
            loader.show();
            const { next, result } = await this.cardService
                .getCardEvents(threadId, cardId, fromStart ? null : last)
                .toPromise();
            if (!fromStart) last = next;

            for (const event of result.reverse()) {
                subject.next(event);
            }
            loader.hide();
            return last ? true : false;
        };

        const loadLatestEvents = defer(() => loadHistorical()).pipe(switchMap(() => EMPTY));

        const websocketEvents = this.websocketService.watchCardId(threadId, cardId).pipe(
            filter(event => event.subjectType === "event"),
            switchMap(() => loadHistorical(true)),
            mergeMap(() => EMPTY)
        );

        const events = merge(loadLatestEvents, subject, websocketEvents).pipe(distinct(event => event.id));

        return { events, loadHistorical };
    }

    private getCardState(threadId: string, cardId: string, loader: Loader): Observable<CardStateResponse<any>> {
        const state$ = loader.wrap(this.cardService.getCardState(threadId, cardId));
        const changes$ = this.cardStateChanges(threadId, cardId);

        return concat(state$, changes$).pipe(shareReplay(1));
    }

    private cardStateChanges(threadId: string, cardId: string): Observable<CardStateResponse<any>> {
        return this.websocketService.watchCardId(threadId, cardId).pipe(
            filter(notification => notification.state),
            switchMap(() => this.cardService.getCardState(threadId, cardId))
        );
    }

    private threadChanges(threadId: string): Observable<IThread> {
        return this.websocketService.watchThreadId(threadId).pipe(
            filter(
                notification =>
                    notification.threadId === threadId && notification.subjectType === "thread" && !notification.preview
            ),
            switchMap(() => this.threadsService.getThread(threadId))
        );
    }

    private cardChanges(threadId: string, cardId: string): Observable<IThreadCard> {
        return this.websocketService.watchCardId(threadId, cardId).pipe(
            filter(
                notification =>
                    (notification.state || notification.eventType === "updated" || notification.eventType === "deleted") && !notification.state
            ),
            switchMap(() => this.cardService.getCard(threadId, cardId))
        );
    }
}
