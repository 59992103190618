import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { ICardReadStatus } from "@findex/threads";
import { Observable } from "rxjs/internal/Observable";
import { TaskNotificationsService } from "../../services/task-notifications.service";
import { concat } from "rxjs";
import { map, shareReplay, switchMap, filter } from "rxjs/operators";
import { NotificationsService } from "@findex/notifications-angular";
import { GA_EVENTS } from "../../../analytics";

@Component({
    templateUrl: "card-read-status.component.html",
    selector: "card-read-status",
    styleUrls: ["card-read-status.component.scss"]
})
export class CardReadStatusComponent implements OnChanges {
    readonly gaEvents = GA_EVENTS;

    @Input() threadId: string;
    @Input() cardId: string;

    readStatus$: Observable<ICardReadStatus>;
    isResolved$: Observable<boolean>;

    expandNames = false;

    constructor(
        private taskNotificationsService: TaskNotificationsService,
        private notificationService: NotificationsService
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        const { threadId, cardId } = changes;

        if (threadId || cardId) {
            this.initCardStatus(this.threadId, this.cardId);
        }
    }

    toggleExpandedNames() {
        this.expandNames = !this.expandNames;
    }

    private initCardStatus(threadId: string, cardId: string) {
        if (!threadId || !cardId) {
            this.readStatus$ = null;
            this.isResolved$ = null;
        }

        this.readStatus$ = this.getCardStatus(threadId, cardId);
        this.isResolved$ = this.readStatus$.pipe(map(status => this.isStatusResolved(status)));
    }

    private getCardStatus(threadId: string, cardId: string): Observable<ICardReadStatus> {
        const cardStatus$ = this.taskNotificationsService.getCardReadStatus(threadId, cardId);

        const channel = `activity/threads/${threadId}/cards/${cardId}`;
        const websocketUpdates$ = this.notificationService
            .subscribeToChannel(channel)
            .pipe(switchMap(() => cardStatus$));

        return concat(cardStatus$, websocketUpdates$).pipe(
            filter(status => !!status),
            shareReplay()
        );
    }

    private isStatusResolved(status: ICardReadStatus): boolean {
        const { resolved, unresolved } = status;
        return unresolved.participantIds.length === 0 && resolved.participantIds.length !== 0;
    }
}
