import { Component, ComponentFactoryResolver, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import {
    OnboardingService,
    SignupBusinessPayload
} from "../../../../../../projects/portal-modules/src/lib/onboarding/services/onboarding.service";
import { ActivatedRoute, Router } from "@angular/router";
import { switchMap, take } from "rxjs/operators";
import { IOnboardingStep } from "./IOnboardingStep";
import { OnboardingStepDirective } from "./onboarding-step.directive";
import { Subscription } from "rxjs";
import { EnvironmentSpecificConfig } from "../../../../../../projects/portal-modules/src/lib/environment/environment.common";
import { BusinessNameComponent } from "../business-name/business-name.component";
import { IndustryTypeComponent } from "../industry-type/industry-type.component";
import { SelectAccountExecutiveComponent } from "../select-account-executive/select-account-executive.component";
import { AuthService } from "../../../../../../projects/portal-modules/src/lib/findex-auth";
import { AnalyticsService } from "../../../../../../projects/portal-modules/src/lib/analytics";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ENVIRONMENT } from "src/app/injection-token";

const STEPS = {
    "business-name": { component: BusinessNameComponent, name: "Business name", allowNavigateBack: false },
    "industry-type": { component: IndustryTypeComponent, name: "Industry type", allowNavigateBack: true },
    "select-account-executive": {
        component: SelectAccountExecutiveComponent,
        name: "Select your Onboarding Specialist",
        allowNavigateBack: true
    }
};

@Component({
    selector: "app-onboarding-completion",
    templateUrl: "./onboarding-completion.component.html",
    styleUrls: ["./onboarding-completion.component.scss"]
})
export class OnboardingCompletionComponent implements OnInit, OnDestroy {
    @ViewChild(OnboardingStepDirective, { static: true }) onboardingStep: OnboardingStepDirective;

    modelUpdatedSubscription: Subscription;
    modelValidatedSubscription: Subscription;
    progressStateSubscription: Subscription;
    goBackSubscription: Subscription;

    persistedUserDataModel: any = {};
    //This is updated when the values of the child components changes
    workingUserDataModel: any = {};

    errorMessage = "";

    currentStepId: string;
    currentStepName: string;
    showLoader = false;
    isValid = false;
    steps = STEPS;

    readonly stepNames: string[];
    readonly appName = this.environment.appName;
    readonly theme = this.environment.theme;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        private data: { step: any },
        private onboardingService: OnboardingService,
        private authService: AuthService,
        private analyticsService: AnalyticsService,
        private route: ActivatedRoute,
        private router: Router,
        private componentFactoryResolver: ComponentFactoryResolver,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig
    ) {
        this.stepNames = Object.keys(STEPS).map(stepId => STEPS[stepId].name);
    }

    ngOnInit(): void {
        this.showLoader = true;

        if (this.data && this.data.step) {
            this.currentStepId = this.data.step;
        }

        this.route.queryParams.subscribe(params => {
            if (this.currentStepId) {
                return;
            }

            const status = params.status;
            if (status in STEPS) {
                this.currentStepId = status;
            } else {
                this.currentStepId = Object.keys(STEPS)[0];
            }
        });

        this.authService
            .getUser()
            .pipe(
                take(1),
                switchMap(user => this.onboardingService.getCompletionDetails(user.id))
            )
            .subscribe((userDetails: SignupBusinessPayload) => {
                this.workingUserDataModel = { ...userDetails };
                this.persistedUserDataModel = { ...userDetails };
                this.showLoader = false;
                setTimeout(() => {
                    this.setCurrentStep(this.currentStepId);
                }, 10);
            });
    }

    ngOnDestroy() {
        this.unsubscribeStepListeners();
    }

    async updateUserModel(updatedClientModel: SignupBusinessPayload) {
        Object.keys(updatedClientModel).map(key => {
            if (key !== undefined || key !== "") {
                this.workingUserDataModel[key] = updatedClientModel[key];
            }
        });
    }

    validStateChanged(isValid: boolean) {
        this.isValid = isValid;
    }

    progressState() {
        this.showLoader = true;
        this.authService
            .getUser()
            .pipe(
                take(1),
                switchMap(user => this.onboardingService.updateCompletionDetails(user.id, this.workingUserDataModel))
            )
            .subscribe(() => {
                this.showLoader = false;
                this.persistedUserDataModel = { ...this.workingUserDataModel };

                const analyticsPath = this.route.snapshot.pathFromRoot
                    .map(value => value.url.map(urlValue => urlValue.toString()).join("/"))
                    .join("/");

                this.analyticsService.recordEvent(`register-completion-${this.currentStepId}`, "next");

                this.setCurrentStep(this.getNextStep());
                this.analyticsService.pageViewed(analyticsPath);
                this.isValid = false;
            });
    }

    navigateStep(index: number) {
        const previousStepId = Object.keys(STEPS)[index];
        this.setCurrentStep(previousStepId);
    }

    goBack() {
        const stepIds = Object.keys(STEPS);
        const currentStepIndex = stepIds.indexOf(this.currentStepId);
        if (currentStepIndex < 0) {
            return;
        }

        // remove query string so on reload we return to the correct page
        if (window.location.search.length > 0) {
            window.history.replaceState(null, null, window.location.pathname);
        }
        this.workingUserDataModel = { ...this.persistedUserDataModel };

        const currentStepRoute = Object.keys(STEPS)[this.currentStepId];
        this.recordAnalyticsEvent(`${currentStepRoute}-go-back`);

        const previousStepIndex = Math.max(currentStepIndex - 1, 0);
        const previousStepId = Object.keys(STEPS)[previousStepIndex];
        this.setCurrentStep(previousStepId);
    }

    getNextStep() {
        const stepIds = Object.keys(STEPS);
        const currentStepIndex = stepIds.indexOf(this.currentStepId);

        if (currentStepIndex < 0) {
            return this.currentStepId;
        }
        const nextStepIndex = currentStepIndex + 1;
        if (nextStepIndex >= stepIds.length) {
            return this.currentStepId;
        }

        return stepIds[nextStepIndex];
    }

    private recordAnalyticsEvent(category: string) {
        this.analyticsService.recordEvent("onboarding-completion", category);
    }

    navigateToDashboard() {
        this.router.navigateByUrl("/dashboard");
    }

    private setCurrentStep(stepId: string) {
        const step = STEPS[stepId];

        this.currentStepId = stepId;
        this.currentStepName = step.name;
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory<IOnboardingStep>(step.component);

        this.unsubscribeStepListeners();

        const viewContainerRef = this.onboardingStep.viewContainerRef;
        viewContainerRef.clear();

        const componentRef = viewContainerRef.createComponent<IOnboardingStep>(componentFactory);
        const { instance } = componentRef;

        if (instance.modelUpdated) {
            this.modelUpdatedSubscription = instance.modelUpdated.subscribe(this.updateUserModel.bind(this));
        }
        if (instance.modelValidated) {
            this.modelValidatedSubscription = instance.modelValidated.subscribe(this.validStateChanged.bind(this));
        }
        if (instance.progressState) {
            this.progressStateSubscription = instance.progressState.subscribe(this.progressState.bind(this));
        }
        if (instance.goBack) {
            this.goBackSubscription = instance.goBack.subscribe(this.goBack.bind(this));
        }
        instance.updateModel(this.workingUserDataModel);
        window.history.replaceState(null, null, `${window.location.pathname}?status=${this.currentStepId}`);
    }

    private unsubscribeStepListeners() {
        if (this.modelUpdatedSubscription) {
            this.modelUpdatedSubscription.unsubscribe();
            this.modelUpdatedSubscription = null;
        }
        if (this.modelValidatedSubscription) {
            this.modelValidatedSubscription.unsubscribe();
            this.modelValidatedSubscription = null;
        }
        if (this.progressStateSubscription) {
            this.progressStateSubscription.unsubscribe();
            this.progressStateSubscription = null;
        }
        if (this.goBackSubscription) {
            this.goBackSubscription.unsubscribe();
            this.goBackSubscription = null;
        }
    }
}
