import { ComponentType } from "@angular/cdk/portal";
import { HttpClient } from "@angular/common/http";
import { EventEmitter, Inject, Injectable, InjectionToken, Injector } from "@angular/core";
import { IActionLink } from "@findex/threads";
import { BehaviorSubject, Observable } from "rxjs";
import { ENVIRONMENT, FOCUS_WIZARD_LIBRARY } from "src/app/injection-token";
import { environmentCommon, EnvironmentSpecificConfig } from "../../../environment/environment.common";
import { AuthService } from "../../../findex-auth";
import { IAuthInvitation } from "../../../findex-auth/services/strategies/invitation-auth.strategy";
import { ILibrary } from "../../../plugins";
import { FocusWizardExtension } from "../../../plugins/services/Libraries";
import { IStepConfiguration } from "../multi-component-layout/multi-component-layout.component";

export const FOCUS_WIZARD_DATA = new InjectionToken("FOCUS_WIZARD_DATA");

export interface IFocusWizardStep extends IStepConfiguration {
    type: IActionLink["type"];
    injector?: Injector;
    component?: ComponentType<any>;
    footerComponent?: ComponentType<any>;
}

export interface FocusWizardModel {
    invitation: IAuthInvitation;
    actionLink: IActionLink;
    state: BehaviorSubject<any>;
    complete: EventEmitter<any>;
    back?: EventEmitter<any>;
}

@Injectable({
    providedIn: "root"
})
export class FocusActionWizardService {
    constructor(
        private authService: AuthService,
        private httpClient: HttpClient,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        @Inject(FOCUS_WIZARD_LIBRARY) private focusWizardLibrary: ILibrary<FocusWizardExtension>
    ) {}

    async getActionInvitation(actionId: string): Promise<{ authInvitation: IAuthInvitation; actionLink: IActionLink }> {
        const authInvitation$ = this.authService.fetchInvitation(actionId);
        const actionLink$ = this.getActionLink(actionId);
        const [authInvitation, actionLink] = await Promise.all([authInvitation$, actionLink$.toPromise()]);
        return { authInvitation, actionLink };
    }

    buildStepConfiguration(actionLink: IActionLink, injector: Injector): IFocusWizardStep[] {
        const startSteps = actionLink?.progress?.steps?.filter(step => step.type === "dummy");
        const verifySteps = actionLink?.progress?.steps?.filter(step => step.type === "verify");
        const customSteps = actionLink?.progress?.steps?.filter(step => !["dummy", "verify"].includes(step.type));

        return [...startSteps, ...verifySteps, ...customSteps].map((step, index) => {
            const stepPlugin = this.focusWizardLibrary.resolve(step.type);
            switch (step?.type) {
                case "dummy":
                case "verify":
                    return {
                        stepIndex: index - startSteps.length + 1,
                        name: step?.label,
                        buttons: [],
                        type: step?.type
                    };
                default:
                    return {
                        stepIndex: index - startSteps.length + 1,
                        name: step?.label || stepPlugin?.taskLabel,
                        buttons: [],
                        type: step?.type,
                        component: stepPlugin?.componentRef,
                        injector
                    };
            }
        });
    }

    buildFocusWizardProvider(
        invitation: IAuthInvitation,
        actionLink: IActionLink,
        focusWizardState: BehaviorSubject<any>
    ): FocusWizardModel {
        return {
            invitation,
            actionLink,
            state: focusWizardState,
            complete: new EventEmitter<any>(),
            back: new EventEmitter<any>()
        };
    }

    private getActionLink(invitationId: string): Observable<IActionLink> {
        const { base } = this.environment.publicEndpoints;
        const { actionLink } = environmentCommon.threadsEndpoints;
        const url = `${base}${actionLink}`.replace(":actionId", invitationId);
        return this.httpClient.get<IActionLink>(url);
    }
}
