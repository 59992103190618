import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { EnvironmentSpecificConfig } from "../../../../../../../projects/portal-modules/src/lib/environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";
import { AnalyticsService } from "../../../../../../../projects/portal-modules/src/lib/analytics";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
    selector: "onboarding-video-modal",
    templateUrl: "./onboarding-video-modal.component.html",
    styleUrls: ["./onboarding-video-modal.component.scss"]
})
export class OnboardingVideoModalComponent implements OnInit {
    onboardingVideoUrl: SafeResourceUrl;

    constructor(
        private dialogRef: MatDialogRef<OnboardingVideoModalComponent>,
        private analyticsService: AnalyticsService,
        private domSanitizer: DomSanitizer,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig
    ) {}

    ngOnInit(): void {
        this.onboardingVideoUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.environment.onboardingVideoUrl);
        this.recordAnalyticsEvent("displayed");
    }

    private recordAnalyticsEvent(category: string) {
        this.analyticsService.recordEvent("onboarding-video-modal", category);
    }

    close() {
        this.dialogRef.close();
    }
}
