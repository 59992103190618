<div class="fx-threads-list">
    <button
        class="fx-threads-summary-card"
        [ngClass]="active ? 'fx-threads-summary-card--active' : 'fx-threads-summary-card--inactive'"
        [analyticsClick]="hotJarEvents.TimelineEvent"
        (click)="selected($event, thread)"
    >
        <div class="fx-threads-summary-card-unread" *ngIf="!active && (getUnread$ | async)?.unresolved"></div>

        <div class="fx-threads-summary-card-avatar" *ngIf="thread?.participants?.length">
            <participant-avatars [participants]="thread.participants"></participant-avatars>
        </div>

        <div
            class="fx-threads-summary-card-row"
            [ngClass]="{ 'fx-threads-summary-card-row--inactive': !(thread | isThreadActive | async) }"
        >
            <div class="fx-threads-summary-card-category">
                <span
                    class="fx-threads-summary-card-category-title"
                    [title]="thread.title"
                    (click)="selected($event, thread)"
                >
                    {{ thread.title | uppercase }}
                </span>
                <div class="fx-threads-summary-card-context-container">
                    <thread-workflow-status
                        [thread]="thread"
                        [workflowState]="thread?.workflow"
                        [small]="true"
                        [role]="globalRole"
                    ></thread-workflow-status>
                    <fx-context-menu
                        #menu
                        *ngIf="jobStatusEnabled && (globalRole | permissions: 'UpdateParticipants' | async)"
                    >
                        <a *ngIf="thread?.workflow" (click)="manageStatus()">
                            Manage Status
                        </a>
                        <a (click)="createTimeline()">
                            Create Timeline
                        </a>
                        <a
                            *ngIf="
                                thread.workflow &&
                                currentWorkflowStep?.type !== stepTypes.INACTIVE &&
                                currentWorkflowStep?.type !== stepTypes.CLOSED
                            "
                            (click)="cancelTimeline()"
                        >
                            Cancel Timeline
                        </a>
                        <a *ngIf="showAccounts && thread.accountId" [routerLink]="['/accounts', thread.accountId]">
                            View Account
                        </a>
                        <a
                            *ngIf="globalRole === roles.Administrator"
                            (click)="editTimeline()">
                            Timeline Type
                        </a>
                    </fx-context-menu>
                </div>
            </div>

            <div class="fx-threads-summary-card-top" [ngClass]="{ 'fx-threads-summary-card-name--active': active }">
                <div
                    *ngIf="globalRole !== roles.Client && thread.accountId && thread.account?.label"
                    [title]="thread.account.label | striphtml"
                    class="fx-threads-summary-card-name"
                >
                    {{ thread.account.label | striphtml }}
                </div>
                <div
                    *ngIf="globalRole === roles.Client && thread.participants.length"
                    class="fx-threads-summary-card-name"
                >
                    {{ thread.participants | listOtherParticipants | async }}
                </div>
                <div
                    *ngIf="
                        (globalRole !== roles.Client && !thread.accountId && !thread.account?.label) ||
                        (globalRole === roles.Client && !thread?.participants?.length)
                    "
                >
                    {{ thread.title }}
                </div>
            </div>

            <thread-preview
                [preview]="thread?.preview"
                [hasMultipleParticipants]="thread?.participants?.length > 2"
                [userId]="(user$ | async)?.id"
                [createdAt]="thread?.createdAt"
            >
            </thread-preview>
        </div>
    </button>
</div>
