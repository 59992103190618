<ng-container *ngIf="staffProfile">
    <div class="staff-avatar">
        <fx-avatar [name]="staffProfile.name" [image]="staffProfile.userId | profilePicture" size="large"></fx-avatar>
    </div>
    <div class="staff-title">
        <strong>{{ staffProfile.name }}</strong>
        <div class="staff-position">{{ staffProfile.title }}</div>
    </div>
    <div class="staff-bio">
        {{ staffProfile.description }}
    </div>
    <div class="staff-speciality">
        <div class="staff-speciality-header">Specialities</div>
        <div>
            <span
                class="staff-speciality-chip"
                [title]="speciality"
                *ngFor="let speciality of staffProfile.specialities; let last = last"
            >
                {{ speciality }}
            </span>
        </div>
    </div>
</ng-container>
