import { Component, Input, SimpleChanges, OnChanges, Injector, OnDestroy, Output, EventEmitter, ViewChild, ElementRef } from "@angular/core";
import { IThreadCard, IThread, Role, CardReply } from "@findex/threads";
import {
    THREAD_CARD_RESOURCES,
    IUiCard,
    IEventService
} from "projects/portal-modules/src/lib/threads-ui/interfaces/IUiCard";
import { Observable, ReplaySubject, Subscription } from "rxjs";
import { map, filter, take, switchMapTo, switchMap, shareReplay } from "rxjs/operators";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { ViewportObserverService } from "../../services/viewport-observer.service";

@Component({
    selector: "ui-card-portal",
    templateUrl: "./ui-card-portal.component.html",
    styleUrls: ["./ui-card-portal.component.scss"]
})
export class UiCardPortalComponent implements OnChanges, OnDestroy {
    @ViewChild('viewExtension', { read: ElementRef, static: false }) set viewExtension(content: ElementRef) {
        this.viewExtension$.next(content)
    }

    viewExtension$ = new ReplaySubject<ElementRef>(1);

    @Input() uiCard: IUiCard;
    @Output() cardUpdated = new EventEmitter<void>();

    userId$: Observable<string>;
    injector: Injector;

    private cardSubscription: Subscription;

    constructor(
        private parentInjector: Injector,
        authService: AuthService,
        private viewPortObserverService: ViewportObserverService
    ) {
        this.userId$ = authService.getUser().pipe(
            filter(user => !!user),
            map(user => user.id)
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        const { uiCard } = changes;

        if (uiCard && uiCard.currentValue) {
            const {
                threadId,
                cardId,
                thread$,
                card$,
                state$,
                replies$,
                eventService,
                navigateTo$,
                role
            } = uiCard.currentValue as IUiCard;

            this.updateComponent(threadId, cardId, thread$, card$, state$, replies$, eventService, navigateTo$, role);
        }
    }

    ngOnDestroy() {
        if (!this.uiCard) return;

        if (this.cardSubscription) {
            this.cardSubscription.unsubscribe();
        }

        //Complete what we can so card-modules don't accidentally cause memory leaks
        this.uiCard.eventsSubject.complete();
        this.uiCard.navigateToSubject.complete();
        this.viewExtension$.complete();
    }

    private updateComponent(
        threadId: string,
        cardId: string,
        thread$: Observable<IThread>,
        card$: Observable<IThreadCard>,
        state$: Observable<any>,
        replies$: Observable<CardReply[]>,
        eventService: IEventService,
        navigateTo$: Observable<any>,
        role: Role
    ) {
        const isVisible$ = this.viewExtension$.pipe(
            switchMap(elm => this.viewPortObserverService.observe(elm)),
            filter(isVisible => !!isVisible),
            take(1),
            shareReplay(1)
        );

        const visibleState$ = isVisible$.pipe(switchMapTo(state$));
        const visibleReplies$ = isVisible$.pipe(switchMapTo(replies$));

        const cardResources = {
            threadId,
            cardId,
            thread$,
            card$,
            state$: visibleState$,
            replies$: visibleReplies$,
            eventService,
            navigateTo$,
            role
        };

        this.injector = Injector.create({
            parent: this.parentInjector,
            providers: [{ provide: THREAD_CARD_RESOURCES, useValue: cardResources }]
        });

        if (this.cardSubscription) {
            this.cardSubscription.unsubscribe();
        }

        this.cardSubscription = cardResources.card$.subscribe(card => {
            //TODO: refactor timestamp usages to use card observable so we don't need to mutate
            const { modifiedAt, createdAt } = card;
            this.uiCard.timestamp = new Date(modifiedAt || createdAt).getTime();
            this.cardUpdated.emit();
        });
    }
}
