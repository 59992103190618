import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { IParticipant, IThread, RecordingStatus, Role } from "@findex/threads";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { ROLE, THREAD } from "projects/portal-modules/src/lib/threads-ui/interfaces/IUiCard";
import { Observable, Subscription } from "rxjs";
import { switchMap } from "rxjs/operators";
import {
    IRecordingWithContext,
    ThreadsRecordingService
} from "projects/portal-modules/src/lib/threads-ui/services/threads-recording.service";
import { MatTableDataSource } from "@angular/material/table";
import { ParticipantCache } from "projects/portal-modules/src/lib/threads-ui/services/participant-cache.service";
import { IAvatarContent } from "@findex/fx-ui";
import { RecordingProcessingModalComponent } from "../recording-processing-modal/recording-processing-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { environmentCommon } from "src/environments/environment";

@Component({
    selector: "recording-list",
    templateUrl: "./recording-list.component.html",
    styleUrls: ["./recording-list.component.scss"]
})
export class RecordingListComponent implements OnInit, OnDestroy {
    readonly dateFormat = environmentCommon.dateFormats;
    loader = new Loader();
    hasRecordings: boolean;
    recordings$: Observable<IRecordingWithContext[]>;
    recordingSubscription: Subscription;
    selectedRecordings: Set<IRecordingWithContext>;
    tableData = new MatTableDataSource<IRecordingWithContext>();

    constructor(
        @Inject(THREAD) public thread$: Observable<IThread>,
        @Inject(ROLE) public role$: Observable<Role>,
        private recordingsService: ThreadsRecordingService,
        private participantsCache: ParticipantCache,
        private dialog: MatDialog
    ) {}

    ngOnInit() {
        this.recordings$ = this.thread$.pipe(
            switchMap(thread => this.loader.wrap(this.recordingsService.getThreadRecordings(thread.id)))
        );
        this.recordingSubscription = this.recordings$.subscribe(recordings => (this.tableData.data = recordings));
    }

    ngOnDestroy() {
        if (this.recordingSubscription) {
            this.recordingSubscription.unsubscribe();
        }
    }

    async download(recording: IRecordingWithContext) {
        if (recording.status !== RecordingStatus.Ready) {
            this.dialog.open(RecordingProcessingModalComponent, { panelClass: ["centered-modal"] });
        } else {
            const downloadWindow = window.open("", "_self");
            const downloadUrl = await this.getDownloadUrl(recording);
            downloadWindow.location.href = downloadUrl;
        }
    }

    private async getDownloadUrl(recording: IRecordingWithContext): Promise<string> {
        this.loader.show();
        const { threadId, cardId, id } = recording;

        try {
            return this.recordingsService.getDownloadUrl(threadId, cardId, id);
        } finally {
            this.loader.hide();
        }
    }

    async getAttendeeAvatars(participants: IParticipant[]): Promise<IAvatarContent[]> {
        return this.participantsCache.getMultipleAvatars(participants).toPromise();
    }
}
