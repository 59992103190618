import { Injectable } from "@angular/core";
import { CalendarAction, IThread } from "@findex/threads";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { ExtensionMenuModalComponent } from "projects/portal-modules/src/lib/extension-shared/components/extension-menu-modal/extension-menu-modal.component";
import { IPluginFactory } from "projects/portal-modules/src/lib/plugins";
import { Libraries } from "projects/portal-modules/src/lib/plugins/services/Libraries";
import { VideoChatService } from "../video-chat/services/video-chat.service";
import { CalendarBookMeetingComponent } from "./components/calendar-book-meeting/calendar-book-meeting.component";
import { CalendarCardComponent } from "./components/calendar-card/calendar-card.component";
import { CalendarMeetingRequestComponent } from "./components/calendar-meeting-request/calendar-meeting-request.component";
import { calendarRescheduleTaskAction, calendarScheduleTaskAction } from "./calendar-task-actions";
import { CalendarMeetingStartedBannerComponent } from "./components/calendar-meeting-started-banner/calendar-meeting-started-banner.component";

@Injectable()
export class CalendarPlugin implements IPluginFactory {
    readonly id = "CalendarPlugin";
    readonly gaEvents = GA_EVENTS;

    constructor(libraries: Libraries, private videoChatService: VideoChatService) {
        libraries.cardViews.register("calendar", CalendarCardComponent);

        libraries.taskActions.register(CalendarAction.SCHEDULE, calendarScheduleTaskAction);
        libraries.taskActions.register(CalendarAction.RESCHEDULE, calendarRescheduleTaskAction);

        libraries.extensionMenu.register("create-meeting", {
            title: "New Meeting",
            description: "Select one of the meeting options.",
            extensions: [
                {
                    title: "Create a meeting request",
                    description: "Ask for an available meeting time.",
                    analyticsEvent: this.gaEvents.APP_CREATE_REQUEST_MEETING,
                    componentRef: CalendarMeetingRequestComponent,
                    permission: "CreateCalendarCard"
                },
                {
                    title: "Schedule a meeting",
                    description: "Invite your team to a scheduled meeting.",
                    analyticsEvent: this.gaEvents.APP_CREATE_SCHEDULE_MEETING,
                    componentRef: CalendarBookMeetingComponent,
                    permission: "CreateCalendarCard"
                },
                {
                    title: "Start a video call now",
                    description: "A call with your team will begin right away.",
                    analyticsEvent: this.gaEvents.APP_CREATE_VIDEO_CHAT,
                    permission: "CreateVcCard",
                    action$: (thread: IThread) => this.videoChatService.createChat(thread.id)
                }
            ]
        });

        libraries.createCard.register("calendar-book", {
            title: "Meeting",
            analyticsEvent: this.gaEvents.APP_CREATEMEETING,
            permission: ["CreateCalendarCard"],
            icon: "la-calendar-alt",
            componentRef: ExtensionMenuModalComponent,
            data: {
                extensionConfig: {
                    extensionId: "create-meeting"
                }
            },
            config: {
                panelClass: ["threads-sidebar", "mat-dialog-no-styling"],
                autoFocus: false,
                disableClose: false,
                backdropClass: "modal-backdrop",
                closeOnNavigation: true,
                maxWidth: "100%",
                maxHeight: "100%",
                minHeight: "100%",
                height: "100vh"
            }
        });

        libraries.banners.register("meeting-started", {
            componentRef: CalendarMeetingStartedBannerComponent
        })
    }
}
