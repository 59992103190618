import { Component, Inject, OnInit } from "@angular/core";
import { PortalService } from "../../../../../../projects/portal-modules/src/lib/shared/services/portal.service";
import { Router } from "@angular/router";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { OnboardingService } from "projects/portal-modules/src/lib/onboarding/services/onboarding.service";
import { take } from "rxjs/operators";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ISlot } from "@findex/fx-ui/lib/components/calendar/calendar";
import { IStaffProfile } from "@findex/threads";
import { IOnboardingStep } from "../onboarding-completion/IOnboardingStep";
import { EnvironmentSpecificConfig } from "../../../../../../projects/portal-modules/src/lib/environment/environment.common";
import { ProductTypes, StaffService } from "projects/portal-modules/src/lib/shared/services/staff.service";
import { AuthService, AppUser } from "projects/portal-modules/src/lib/findex-auth";
import { IInvitation } from "@findex/calendar-types";
import { OnboardingCompletionComponent } from "../onboarding-completion/onboarding-completion.component";
import { ENVIRONMENT } from "src/app/injection-token";
import { Tour, TourService } from "projects/portal-modules/src/lib/shared/services/tour.service";
import { CalendarService } from "projects/default-plugins/calendar/services/calendar.service";
import { CalendarModalComponent } from "projects/default-plugins/calendar/components/calendar-modal/calendar-modal.component";

@Component({
    selector: "app-select-account-executive",
    templateUrl: "./select-account-executive.component.html",
    styleUrls: ["./select-account-executive.component.scss"],
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: { class: "fx-onboarding-modal-template" }
})
export class SelectAccountExecutiveComponent implements OnInit, IOnboardingStep {
    readonly theme;

    user: AppUser;
    accountExecutives: IStaffProfile[];
    error: string;
    loader = new Loader();
    currentSlide = 0;

    constructor(
        private staffService: StaffService,
        private portalService: PortalService,
        private router: Router,
        private authService: AuthService,
        private onboardingService: OnboardingService,
        private dialog: MatDialog,
        private dialogRef: MatDialogRef<OnboardingCompletionComponent>,
        private calendarService: CalendarService,
        private tourService: TourService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig
    ) {
        this.theme = environment.theme;
    }

    getFirstName(staffProfile: IStaffProfile) {
        const parts = staffProfile.name.split(" ");
        return parts.length > 0 ? parts[0] : "advisor";
    }

    async ngOnInit() {
        this.loader.show();
        try {
            const staffResponse = await this.staffService.fetchStaff({ product: ProductTypes.Bookkeeping }).toPromise();
            this.accountExecutives = staffResponse.matchingStaff;

            this.user = await this.authService
                .getUser()
                .pipe(take(1))
                .toPromise();
            await this.checkUserAccountExecutiveStatus();
        } catch (err) {
            this.error = "An error occurred, please try again";
        } finally {
            this.loader.hide();
        }
    }

    async checkUserAccountExecutiveStatus() {
        const onboardingDetails = await this.onboardingService.getCompletionDetails(this.user.id).toPromise();
        if (onboardingDetails && onboardingDetails.selectedStaff) {
            this.router.navigateByUrl("/dashboard");
        }
    }

    async requestChat(accountExecutive: IStaffProfile, type: string) {
        this.error = null;

        this.loader.show();

        try {
            const { userId } = accountExecutive;
            const createOnboarding$ = this.portalService.createOnboarding(userId, type === "call");
            const { thread, appointmentId } = await this.loader.wrap(createOnboarding$).toPromise();

            await this.onboardingService
                .updateCompletionDetails(this.user.id, { selectedStaff: accountExecutive })
                .toPromise();

            this.dialogRef.close();

            if (type !== "call") {
                this.router.navigate(["timelines", thread.id]);
                return;
            }

            const invite = await this.calendarService.getClientInvitation(appointmentId).toPromise();
            this.router.navigate(["/dashboard"]);
            await this.openCalendarModal(appointmentId, accountExecutive, invite);
        } catch (err) {
            this.error = "An error occurred, please try again";
            throw err;
        } finally {
            this.loader.hide();
        }
    }

    async openCalendarModal(invitationId: string, accountExecutive: IStaffProfile, invite: IInvitation) {
        const options = {
            disableClose: true,
            backdropClass: "modal-backdrop",
            panelClass: ["threads-sidebar", "threads-sidebar--large", "mat-dialog-no-styling"],
            maxWidth: "100%",
            maxHeight: "100%",
            minHeight: "100%",
            data: {
                invitationId,
                staffParticipant: accountExecutive,
                participants: [...invite.invitees, ...invite.staff],
                duration: invite.duration,
                organiser: { name: accountExecutive.name, id: accountExecutive.userId },
                title: invite.details.title,
                message: invite.message,
                recurrence: invite.recurrence
            }
        };

        this.dialog
            .open<CalendarModalComponent, any, ISlot>(CalendarModalComponent, options)
            .afterClosed()
            .subscribe(async booking => {
                if (booking) {
                    await this.bookMeeting(invitationId, booking);
                }

                const { tours } = this.environment.featureFlags;
                const shouldShowTour = this.urlMatchesTour(tours, this.router.url);
                if (shouldShowTour) {
                    this.manageTour(tours);
                }
            });
    }

    private async bookMeeting(invitationId: string, slot: ISlot) {
        this.loader.show();

        try {
            await this.calendarService.setAppointment(invitationId, slot.start, slot.end).toPromise();
        } finally {
            this.loader.hide();
        }
    }

    updateModel() {
        //No update required.
    }

    prevSlide() {
        this.scrollToSlide("slide", this.currentSlide - 1);
    }

    nextSlide() {
        this.scrollToSlide("slide", this.currentSlide + 1);
    }

    scrollToSlide(prefix: string, sectionId: number) {
        const id = prefix + "-" + sectionId;
        const element = document.getElementById(id);
        this.currentSlide = sectionId;
        if (element) {
            element.scrollIntoView();
        }
    }

    private urlMatchesTour(tours: Tour[], url: string): boolean {
        if (!tours) return false;
        return tours.some(tour => url.includes(tour.tourRoute));
    }

    private async manageTour(tours: Tour[]) {
        if (!tours) {
            this.tourService.hideTour();
            return;
        }

        await this.tourService.queueTours(this.user, tours);
    }
}
