import { FindInPipe } from "./find-in.pipe";
import { FormatOrdinalDatePipe } from "./format-ordinal-date.pipe";
import { IsSystemNamePipe } from "./is-system-name.pipe";
import { ParseStringDatePipe } from "./parse-string-date.pipe";
import { PermissionsPipe } from "./permissions.pipe";
import { ReversePipe } from "./reverse.pipe";
import { StripHtmlPipe } from "./strip-html.pipe";
import { StripHtmlLinksPipes } from "./strip-html-links.pipe";
import { AllowWhitelistLinksPipe } from "./allow-whitelist-links.pipe";
import { NgModule } from "@angular/core";
import { UserShortNamePipe } from "./user-short-name.pipe";
import { IsAllSelectedPipe } from "./is-all-selected.pipe";
import { TruncatePipe } from "./truncate-string.pipe";

const exportDeclarations = [
    UserShortNamePipe,
    FindInPipe,
    FormatOrdinalDatePipe,
    IsSystemNamePipe,
    ParseStringDatePipe,
    PermissionsPipe,
    ReversePipe,
    StripHtmlPipe,
    StripHtmlLinksPipes,
    AllowWhitelistLinksPipe,
    IsAllSelectedPipe,
    TruncatePipe
];

@NgModule({
    imports: [],
    declarations: [...exportDeclarations],
    exports: [...exportDeclarations],
    providers: [
        TruncatePipe,
        StripHtmlPipe,
        StripHtmlLinksPipes,
        FormatOrdinalDatePipe,
        AllowWhitelistLinksPipe,
        IsAllSelectedPipe
    ]
})
export class SharedPipesModule {}
