<div class="workflow-state-list" [ngClass]="{ 'workflow-state-list--read-only': !role || role === roles.Client }">
    <ng-container *ngFor="let entry of stepEntries; let index = index; trackBy: trackEntry">
        <div [title]="entry.step.clientFacingName" class="workflow-state-list-item">
            <fx-checkbox
                #checkbox
                [ngClass]="{
                    'workflow-state-list-item-checkbox--active': entry.step.isCurrentStep,
                    'workflow-state-list-item-checkbox--completed': entry.isCompleted,
                    'workflow-state-list-item-checkbox--not-ready': !(entry.step.isCurrentStep || entry.isCompleted)
                }"
                (change)="selectStepAt(index, checkbox)"
                [checked]="entry.isCompleted"
                [disabled]="!(role | permissions: 'UpdateInternalWorkflow' | async)"
            >
                <div
                    class="workflow-state-list-item-step"
                    [ngClass]="{
                        'workflow-state-list-item-step--active': entry.step.isCurrentStep,
                        'workflow-state-list-item-step--not-ready': !(entry.step.isCurrentStep || entry.isCompleted)
                    }"
                >
                    {{ entry.step.internalName || entry.step.clientFacingName }}
                </div>
            </fx-checkbox>
            <div
                class="workflow-state-list-item-timestamp"
                *ngIf="role | permissions: 'UpdateInternalWorkflow' | async"
            >
                <div *ngIf="entry.dueDate || entry.isCompleted">
                    <span *ngIf="entry.isCompleted && entry.step?.completedDate; else due">
                        {{ "Completed on " + (entry.step.completedDate | date: "d MMM") }}
                    </span>
                    <ng-template #due>
                        <span *ngIf="entry?.dueDate">{{ "Due on " + (entry.dueDate | date: "d MMM") }}</span>
                    </ng-template>
                    <fx-badge *ngIf="entry.isOverdue" label="OVERDUE" type="error"> </fx-badge>
                </div>
            </div>
            <svg
                *ngIf="index !== 0"
                class="workflow-state-list-item-progress-line"
                [ngClass]="{
                    'workflow-state-list-item-progress-line--not-ready': !(
                        entry.step.isCurrentStep || entry.isCompleted
                    )
                }"
            >
                <line x1="0" y1="0" x2="0" y2="65" />
            </svg>
        </div>
    </ng-container>

    <button
        *ngIf="(role | permissions: 'UpdateWorkflowTimeEstimates' | async) && hasDueDates"
        (click)="editDates.emit()"
        class="fx-btn fx-btn--white fx-btn--full-width"
        role="button"
    >
        Edit Dates
    </button>
    <button
        *ngIf="role | permissions: 'UpdateInternalWorkflow' | async"
        [disabled]="!stateChanged"
        (click)="saveState()"
        class="fx-btn fx-btn--primary fx-btn--full-width"
        role="button"
    >
        {{ stepEntries[stepEntries.length - 1].step.isCurrentStep ? "Save and Complete" : "Save" }}
    </button>
</div>
