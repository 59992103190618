<div class="insights-report-list">
    <div *ngFor="let group of reportGroups; let index = index">
        <fx-accordion [isExpanded]="index === selectedReportGroupIndex" [toggleGutters]="true">
            <ng-container accordion-header>
                <div class="insights-report-list-year">{{ group.calendarYear }}</div>
                <div class="insights-report-list-count">
                    {{ group.reports.length }} report{{ group.reports.length !== 1 ? "s" : "" }}
                    <i class="fx-accordion-toggle-icon las la-angle-down"></i>
                </div>
            </ng-container>
            <ng-container accordion-content *ngFor="let report of group.reports">
                <insights-report-list-item
                    [report]="report"
                    [active]="selectedReport?.file?.id === report.file.id"
                    (emitSelected)="reportSelection.emit($event)"
                    (emitDownloadReport)="downloadReport.emit($event)"
                ></insights-report-list-item>
            </ng-container>
        </fx-accordion>
    </div>
</div>
