<centered-modal-layout
    (save)="confirm()"
    (close)="close()"
    [loading]="!!loader.counter"
    cancelButtonTitle="Cancel"
    [saveButtonTitle]="data.saveButtonTitle || 'Confirm'"
    [analyticsPrefix]="data.analyticsPrefix"
>
    <div modal-header>{{ data.header }} </div>
    <div modal-subhead>{{ data.message }} </div>
</centered-modal-layout>
