import { Pipe, PipeTransform } from "@angular/core";
import { IParticipant } from "@findex/threads";
import { IThreadGroupListing } from "../components/thread-list/thread-grouper";
import { IEnrichedThreadListing } from "../components/threads-list-route/threads-list-route.component";

@Pipe({ name: "threadsSearch", pure: true })
export class ThreadsSearchPipe implements PipeTransform {
    transform(groups: IThreadGroupListing[], searchTerm: string) {
        if (!searchTerm || !groups) return groups;

        return groups.map(group => {
            return {
                ...group,
                children: group.children.filter(thread => this.searchThread(thread, searchTerm.toLowerCase()))
            }
        });
    }

    private searchThread(thread: IEnrichedThreadListing, term: string) {
        const threadContains =
            (thread.title && thread.title.toLowerCase().includes(term)) ||
            (thread.type && thread.type.toLowerCase().includes(term)) ||
            thread?.account?.label?.toLowerCase().includes(term) ||
            (thread.participants && thread.participants.find(participant => this.searchParticipant(participant, term)));

        return threadContains;
    }

    private searchParticipant(participant: IParticipant, term: string): boolean {
        if (!participant || !participant.profile) return false;

        const { name, emailAddress } = participant.profile;
        return (
            (name && name.toLowerCase().includes(term)) || (emailAddress && emailAddress.toLowerCase().includes(term))
        );
    }
}
