<div class="participant-picker" (blur)="closeParticipantLists()">
    <div
        [ngClass]="
            !enableOptionalParticipants ? 'participant-picker-container' : 'participant-picker-container-full-width'
        "
    >
        <div class="participant-picker-selector">
            <div class="participant-picker-selector-outer">
                <div>
                    <div class="participant-picker-selector-selected">
                        <div *ngFor="let participant of selectedParticipants" class="participant-picker-selector-pill">
                            <avatar-pill
                                *ngIf="participant.required"
                                [name]="participant.profile.name"
                                [image]="participant.id | profilePicture"
                                [id]="participant.id"
                                (crossClicked)="unselectParticipant($event)"
                            ></avatar-pill>
                        </div>
                        <div *ngIf="unselectedParticipants.length !== 0" class="participant-picker-selector-add">
                            <input
                                (click)="toggleRequiredParticipantList()"
                                class="fx-form-input remove-underline"
                                placeholder="{{ isRequiredParticipantSelected() ? '' : 'Add attendees' }}"
                                aria-label="Add attendees"
                                [value]="filterRequiredString"
                                (input)="filterTextUpdated($event.target.value, attendeeTypes.Required)"
                                [disabled]="disableRequired || unselectedParticipants.length === 0"
                            />
                        </div>
                    </div>
                    <div class="participant-picker-selector-underline"></div>
                </div>
                <div class="participant-picker-list-container" *ngIf="showRequiredParticipantList">
                    <div class="participant-picker-list-inner">
                        <div class="participant-picker-list-full-height">
                            <participant-list
                                [participants]="filteredRequiredParticipants"
                                (selectParticipant)="selectParticipant($event, true)"
                            ></participant-list>
                        </div>
                    </div>
                </div>
            </div>
            <div
                *ngIf="
                    enableOptionalParticipants &&
                    (isOptionalParticipantSelected() || unselectedParticipants.length !== 0)
                "
                class="participant-picker-selector-outer"
                (blur)="toggleOptionalParticipantList()"
            >
                <div>
                    <div class="participant-picker-selector-selected">
                        <div class="participant-picker-optional-text">Optional:</div>
                        <div *ngFor="let participant of selectedParticipants" class="participant-picker-selector-pill">
                            <avatar-pill
                                *ngIf="!participant.required"
                                [name]="participant.profile.name"
                                [id]="participant.id"
                                [image]="participant.id | profilePicture"
                                (crossClicked)="unselectParticipant($event)"
                            ></avatar-pill>
                        </div>
                        <div class="participant-picker-selector-add">
                            <input
                                (click)="toggleOptionalParticipantList()"
                                class="fx-form-input remove-underline"
                                placeholder="{{ isOptionalParticipantSelected() ? '' : 'Add optional attendees' }}"
                                aria-label="Add optional attendees"
                                [value]="filterOptionalString"
                                (input)="filterTextUpdated($event.target.value, attendeeTypes.Optional)"
                                [disabled]="disableOptional || unselectedParticipants.length === 0"
                            />
                        </div>
                    </div>
                    <div class="participant-picker-selector-underline"></div>
                </div>
                <div class="participant-picker-list-container" *ngIf="showOptionalParticipantList">
                    <div class="participant-picker-list-inner">
                        <div class="participant-picker-list-full-height">
                            <participant-list
                                [participants]="filteredOptionalParticipants"
                                (selectParticipant)="selectParticipant($event, false)"
                            ></participant-list>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="participant-picker-optional" *ngIf="!enableOptionalParticipants" (click)="toggleOptionalParticipants()">
        + optional
    </div>
</div>
