import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    QueryList,
    ViewChild,
    ViewChildren
} from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { Observable, Subscription } from "rxjs";
import { filter, map, mapTo, first } from "rxjs/operators";
import { DateTime } from "luxon";
import {
    CardReply,
    IRequestFileData,
    IThread,
    IThreadCard,
    IUpdatedRequestItems,
    Role, IVaultRequestCardState, IRequestItem
} from "@findex/threads";
import { VaultRequestService } from "../../services/vault-request.service";
import { VaultService } from "@findex/vault";
import { take } from "rxjs/operators";
import { Loader } from "../../../../portal-modules/src/lib/shared/services/loader";
import { AnalyticsService, HOT_JAR_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { MatDialog } from "@angular/material/dialog";
import { IReopenRequestParams, ReopenRequestModalComponent } from "./reopen-request/reopen-request-modal.component";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { RequestTodoActionsComponent } from "./request-todo-actions/request-todo-actions.component";
import { ThreadCardRepliesComponent } from "projects/portal-modules/src/lib/threads-ui/components/thread-card-replies/thread-card-replies.component";

export interface IRequestModalData {
    card$: Observable<IThreadCard>;
    thread$: Observable<IThread>;
    state$: Observable<IVaultRequestCardState>;
    replies$: Observable<CardReply[]>;
    role: Role;
    readonly?: boolean;
}
@Component({
    selector: "request-common",
    template: "",
    styles: []
})
export class RequestCommonComponent implements OnDestroy, AfterViewInit {
    @ViewChildren("todoItemComponents", { read: ElementRef }) todoItemComponents: QueryList<ElementRef>;
    @ViewChildren("todoItemActionComponents") todoItemActionComponents: QueryList<RequestTodoActionsComponent>;
    @ViewChild("threadCardRepliesComponent") threadCardRepliesComponent: ThreadCardRepliesComponent;

    @HostListener("window:beforeunload", ["$event"])
    beforeUnloadHandler(_event: Event) {
        if (this.loader.counter) {
            return confirm('Your files are not completely uploaded...');
        }
        return true;
    }

    readonly hotJarEvents = HOT_JAR_EVENTS;
    readonly roles = Role;
    actionedPercentage = 0;
    numActionedRequestItems = 0;
    loader = new Loader();
    form = new FormGroup({
        title: new FormControl("", [Validators.required]),
        dueDate: new FormControl(""),
        cardDescription: new FormControl("", [Validators.required]),
        requestItems: new FormArray([])
    });
    userId$: Observable<string>;
    thread: IThread;
    card: IThreadCard;
    state$: Observable<IVaultRequestCardState>;
    state: IVaultRequestCardState;
    card$: Observable<IThreadCard>;
    replies$: Observable<CardReply[]>;
    thread$: Observable<IThread>;
    currentUserRole: Role;
    stateSub: Subscription;
    cardSub: Subscription;
    todoItemsFocusSub: Subscription;
    requestItemsState: IRequestItem[];

    constructor(
        protected vaultRequestService: VaultRequestService,
        protected vaultService: VaultService,
        private authService: AuthService,
        private analytics: AnalyticsService,
        private cdr: ChangeDetectorRef,
        public dialog?: MatDialog
    ) {}

    ngAfterViewInit() : void {
        this.todoItemsFocusSub = this.todoItemComponents.changes
            .pipe(mapTo(this.todoItemComponents))
            .subscribe(components => {
                components.last?.nativeElement.focus();
                this.cdr.detectChanges();
            });
    }

    ngOnDestroy() : void {
        if (this.cardSub) {
            this.cardSub.unsubscribe();
        }

        if (this.stateSub) {
            this.stateSub.unsubscribe();
        }

        if (this.todoItemsFocusSub) {
            this.todoItemsFocusSub.unsubscribe();
        }
    }

    async initData(modalData: IRequestModalData) : Promise<void> {
        this.loader.show();
        this.thread = await modalData.thread$.pipe(take(1)).toPromise();
        this.thread$ = modalData.thread$;
        this.card = await modalData.card$.pipe(take(1)).toPromise();
        this.card$ = modalData.card$;
        this.cardSub = this.card$.subscribe(card =>
            this.form.patchValue({
                cardDescription: card.description,
                dueDate: card?.dueDate ? DateTime.fromISO(card.dueDate) : null
            })
        );
        this.state$ = modalData.state$;
        this.stateSub = this.state$.subscribe(state => {
            this.updateState(state);
        });
        this.requestItemsState = (await (modalData.state$.pipe(first()).toPromise())).requestItems;
        this.currentUserRole = modalData.role;
        this.replies$ = modalData.replies$;
        this.userId$ = this.authService.getUser().pipe(
            filter(user => !!user),
            map(user => user.id)
        );
        this.loader.hide();
    }

    addRequestItem() {
        const control = this.form.get("requestItems") as FormArray;
        if (!control.valid) return;

        const requestItem = new FormGroup({
            fileId: new FormControl(""),
            description: new FormControl("", [Validators.required]),
            completed: new FormControl({ value: false, disabled: true })
        });

        control.push(requestItem);
    }

    removeControl(index: number) : void {
        const control = this.form.get("requestItems") as FormArray;

        if (control.controls.length > 1) {
            control.removeAt(index);
        }
    }

    onPasteTodoItems(event: ClipboardEvent): boolean {
        const clipboardText = event.clipboardData.getData("text");
        const clipboard = this.getClipboardTextList(clipboardText);

        if(clipboard.length > 1) {
            this.addMultipleRequestItems(clipboard);
            event.preventDefault();
        }
        return true;
    }

    private addMultipleRequestItems(clipboard: string[]): void {
        const control = this.form.get("requestItems") as FormArray;
        clipboard.forEach(text => {
            const requestItem = new FormGroup({
                fileId: new FormControl(""),
                description: new FormControl(text, [Validators.required]),
                completed: new FormControl({ value: false, disabled: true })
            });
            const idEmptyInput = control.controls.findIndex(item => item.value.description === "");

            if(idEmptyInput > -1) {
                control.controls[idEmptyInput] = requestItem;
                return;
            }
            control.push(requestItem);
        });
    }

    private getClipboardTextList(clipboardText: string) : string[] {
        // The code replaces all newline characters with an empty string and all tab characters with a carriage return in the pasted text from the clipboard.
        // This helps in cleaning up and formatting the text for further use.
        const clipboardFormattedText = clipboardText
            .replace(/\n/g, "")
            .replace(/\t/g, "\r");
        const clipboardList = clipboardFormattedText.split("\r")
            .filter(text => text.trim() !== "");
        return clipboardList;
    }

    keyboardEvent(event: KeyboardEvent) : void {
        if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault();
            this.addRequestItem();
        }
    }

    markAsComplete(val: boolean) : void {
        const control = this.form.get("requestItems") as FormArray;
        control.controls.map(fromGroup => {
            const completedControl = (fromGroup as FormGroup).controls.completed;
            if (completedControl.disabled) {
                return;
            }
            completedControl.setValue(val);
        });
    }

    async updateFormDescription(card: IThreadCard) : Promise<void> {
        this.form.patchValue({
            title: this.state.title,
            cardDescription: card.description
        });
    }

    validateItemHasChanged(item: IRequestItem): boolean {
        if (!this.requestItemsState) return false;
        const index = this.requestItemsState.findIndex((requestItem: IRequestItem) => item.fileId === requestItem.fileId);
        if (index === -1) {
            this.requestItemsState.push(item);
            return false;
        }
        if (item.response.complete.modifiedAt !== this.requestItemsState[index].response.complete.modifiedAt) {
            this.requestItemsState[index] = item;
            return true;
        }
        return false;
    }

    getItemTodoListValue(item: IRequestItem): FormGroup {
        return new FormGroup({
            requestItem: new FormControl({ value: item, disabled: true }),
            description: new FormControl({ value: item.description, disabled: true }),
            completed: new FormControl({ value: item.response.complete.state, disabled: false })
        });
    }

    getOldItemTodoListValue(item: IRequestItem): FormGroup {
        const control = this.form.get("requestItems") as FormArray;
        let oldItemTodoList = this.getItemTodoListValue(item);
        control.controls.forEach((itemTodoList: FormGroup) => {
            if (!itemTodoList.get("requestItem")) {
                return;
            };
            const auxItem = itemTodoList.get("requestItem").value;
            if (auxItem.fileId === item.fileId){
                oldItemTodoList = itemTodoList;
            }
        })
        return oldItemTodoList;
    }

    getItemsTodoListOnEditing(): FormGroup[] {
        const control = this.form.get("requestItems") as FormArray;
        const formGroupEditing = [];
        control.controls.forEach((itemTodoList: FormGroup) => {
            if (!itemTodoList.get("requestItem"))
                formGroupEditing.push(itemTodoList);
        });
        return formGroupEditing;
    }

    getMergedItemsTodoList(requestItems: IRequestItem[]): FormArray {
        return new FormArray(
            [ ...requestItems.map(
                item => {
                    const hasChanged = this.validateItemHasChanged(item);
                    if (!hasChanged) return this.getOldItemTodoListValue(item)
                    return this.getItemTodoListValue(item);
                }
            ),
             ...this.getItemsTodoListOnEditing()
            ]);
    }

    async updateState(state: IVaultRequestCardState) : Promise<void>{
        if (state) {
            this.form.patchValue({
                title: state.title
            });
            this.state = state;
            const requestItems = this.getMergedItemsTodoList(this.state.requestItems);
            this.form.setControl("requestItems", requestItems);
            this.updateRequestProgress();
        }
    }

    private updateRequestProgress() : void {
        const progress = VaultRequestService.calculateProgress(this.state.requestItems);
        this.numActionedRequestItems = progress.numActionedRequestItems;
        this.actionedPercentage = progress.actionedPercentage;
    }

    async updateRequestItems(
        updatedRequestItems: IUpdatedRequestItems[],
        card: IThreadCard,
    ): Promise<void> {
        await this.vaultRequestService.updateRequestItems(
            this.thread.id,
            card.id,
            this.state.vaultId,
            updatedRequestItems,
            null,
        ).toPromise();
    }

    uploadFileItem(
        updatedRequestItem: IUpdatedRequestItems,
        card: IThreadCard,
        file: File
    ): Promise<void> {
        return this.vaultRequestService.uploadRequestResponse(
            this.thread.id,
            card.id,
            this.state.vaultId,
            updatedRequestItem,
            null,
            file
        );
    }

    async markItemComplete(
        state: boolean,
        requestItemControl: FormControl,
        card: IThreadCard,
        analyticsPrefix: string
    ) {
        this.trackAnalyticsEvent("mouse-click", `${analyticsPrefix}_markitemcomplete`);
        if (this.currentUserRole === this.roles.Client) {
            this.trackAnalyticsEvent("mouse-click", this.hotJarEvents.RFIResponseCompleteEvent);
        }

        if (!requestItemControl) {
            return;
        }

        if (
            this.currentUserRole !== this.roles.Client &&
            VaultRequestService.isRequestComplete(this.state.requestItems) &&
            state === false
        ) {
            const shouldReopenRequest = await this.reopenRequestModal();

            if (!shouldReopenRequest) {
                return;
            }
        }

        const updatedRequestItem = {
            fileId: requestItemControl.value.fileId,
            complete: state
        };

        this.numActionedRequestItems = this.numActionedRequestItems + (state ? 1 : -1);
        this.actionedPercentage = Math.floor((this.numActionedRequestItems / this.state.requestItems.length) * 100);
        await this.updateRequestItems([updatedRequestItem], card);
        requestItemControl.markAsPristine();
    }

    async reopenRequestModal(): Promise<boolean> {
        const data: IReopenRequestParams = {
            threadId: this.thread.id,
            cardId: this.card.id
        };

        const config = {
            data,
            panelClass: ["centered-modal"],
            disableClose: true
        };

        return await this.dialog
            .open(ReopenRequestModalComponent, config)
            .afterClosed()
            .toPromise();
    }

    async markAllComplete(state: boolean, card: IThreadCard) : Promise<void> {
        this.markAsComplete(state);
        const updatedRequestItems: IUpdatedRequestItems[] = this.state.requestItems
            .filter(requestItem => requestItem.response.complete.state !== state)
            .map(requestItem => ({
                fileId: requestItem.fileId,
                complete: state
            }));
        this.numActionedRequestItems = state ? this.state.requestItems.length : 0;
        this.actionedPercentage = state ? 100 : 0;
        await this.updateRequestItems(updatedRequestItems, card);
    }

    async downloadFile(fileId: string, attachment: IRequestFileData) : Promise<void>  {
        await this.vaultRequestService.downloadFile(this.state.vaultId, fileId, attachment.filename);
    }

    async handleFileDeleted(requestItem: IRequestItem, file: IRequestFileData) : Promise<void> {
        const filesToDelete = [
            {
                fileId: requestItem.fileId,
                filenames: [file.filename]
            }
        ];
        const card = await this.card$.pipe(take(1)).toPromise();
        await this.vaultRequestService
            .deleteRequestFiles(this.thread.id, card.id, this.state.vaultId, filesToDelete)
            .toPromise();
    }

    async handleFileAttached(requestItem: IRequestItem, file: File, card: IThreadCard) : Promise<void> {
        const updatedRequestItem = {
            fileId: requestItem.fileId,
            filenames: [file.name]
        };
        this.loader.show();
        await this.uploadFileItem(updatedRequestItem, card, file);
        this.loader.hide();
    }

    async handleTextResponse(requestItem: IRequestItem, value: string, card: IThreadCard) {
        if (!requestItem) {
            return;
        }

        const updatedRequestItem = {
            fileId: requestItem?.fileId,
            textResponse: value
        };

        await this.updateRequestItems([updatedRequestItem], card);
        this.form.controls.requestItems.markAsUntouched();
    }

    trackRequestControl(_index: number, requestItemControl: FormGroup): string {
        const requestItem: IRequestItem = requestItemControl.controls.requestItem.value;
        return requestItem.fileId;
    }

    trackAnalyticsEvent(action:string, event: string): void {
        this.analytics.recordEvent(action, event);
    }
}
