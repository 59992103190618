import { Inject, Injectable } from "@angular/core";
import { Hub } from "@aws-amplify/core";
import { CognitoUser, Auth } from "@aws-amplify/auth";
import { BehaviorSubject, defer, Observable, of, ReplaySubject, throwError, EMPTY, merge, from } from "rxjs";
import { IAuthenticationStrategy } from "../../model/IAuthenticationStrategy";
import { AppUser, AuthStrategy } from "../../model/AppUser";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { CognitoUserAttribute, CognitoUserSession } from "amazon-cognito-identity-js";
import { validateAuthorizationLevel } from "../../model/AuthorizationLevel";
import { HttpClient, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpParams, HttpRequest } from "@angular/common/http";
import { LoginChallenge, LoginChallengeResult, LoginStep, LoginStepDetails } from "../../model/LoginStep";
import {
    getUserAttributes,
    refreshSession,
    rememberDevice,
    sendMFACode,
    setUserMfaPreference
} from "./cognito-js-promise-wrappers";
import { environmentCommon, EnvironmentSpecificConfig } from "../../../environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";

@Injectable({ providedIn: "root" })
export class CognitoAuthStrategy implements IAuthenticationStrategy, HttpInterceptor {
    private user$ = new ReplaySubject<AppUser>(1);
    private login$ = new BehaviorSubject<LoginStepDetails>({ step: undefined });

    private credentials: { username: string; password: string };
    private cognitoUser: CognitoUser;
    constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig) {
        console.info("Registering HUB");

        Auth.configure({ authenticationFlowType: this.environment.auth.authenticationFlowType });

        Auth.configure({
            region: environmentCommon.auth.region,
            userPoolId: this.environment.auth.userPoolId,
            userPoolWebClientId: this.environment.auth.userPoolWebClientId,
            clientMetadata: {
                themeName: this.environment.appTheme
            }
        });

        Hub.listen("auth", async event => {
            if (event.payload.event === "signIn") {
                this.cognitoUser = event.payload.data;
                const user = await this.mapToUser(this.cognitoUser);
                this.user$.next(user);
                this.login$.next({ step: LoginStep.LOGIN_COMPLETE });
            }
        });

        Auth.currentAuthenticatedUser().then(
            async cognitoUser => {
                this.cognitoUser = cognitoUser;
                const user = await this.mapToUser(cognitoUser);
                this.user$.next(user);
            },
            () => {
                this.user$.next(null);
            }
        );
    }

    private async performSignIn(username: string, password: string): Promise<LoginStepDetails> {
        return Auth.signIn(username, password).then(async (userDetails: CognitoUser | any) => {
            this.cognitoUser = userDetails;
            if (userDetails.signInUserSession && userDetails.signInUserSession instanceof CognitoUserSession) {
                await this.setUser(userDetails.signInUserSession);
                return {
                    step: LoginStep.LOGIN_COMPLETE
                };
            }

            if (userDetails.challengeName === "NEW_PASSWORD_REQUIRED") {
                return {
                    step: LoginStep.PASSWORD_RESET,
                    details: {
                        userDetails
                    }
                };
            }

            if (userDetails.challengeName === "CUSTOM_CHALLENGE") {
                const type = userDetails.challengeParam ? userDetails.challengeParam.type : null;
                //Trigger the next step in the process
                this.login$.next({
                    step: LoginStep.LOGIN_CHALLENGE,
                    details: {
                        userDetails,
                        type: type || "unknown"
                    }
                });
            }

            if (userDetails.challengeName === LoginChallenge.smsMfa) {
                const type = userDetails.challengeName;
                return {
                    step: LoginStep.LOGIN_CHALLENGE,
                    details: {
                        userDetails,
                        type
                    }
                };
            }

            return {
                step: LoginStep.LOGIN_PROCESSING
            };
        });
    }

    getLogin() {
        return this.login$.asObservable();
    }

    startLogin(username: string, password?: string): Observable<LoginStepDetails> {
        // TODO: ED-1018 Remove this when Cognito Supports resending smsMfa
        this.credentials = { username, password };
        const login = this.performSignIn(username, password);
        return merge(login, this.getLogin());
    }

    reLogin() {
        if (!this.credentials) return EMPTY;
        return this.startLogin(this.credentials.username, this.credentials.password);
    }

    answerChallenge(currentUserDetails: any, answer: string): Observable<LoginChallengeResult> {
        const currentType = currentUserDetails.challengeParam ? currentUserDetails.challengeParam.type : null;
        return defer(() =>
            Auth.sendCustomChallengeAnswer(currentUserDetails, answer).then(async updatedDetails => {
                const type = updatedDetails.challengeParam ? updatedDetails.challengeParam.type : null;
                if (updatedDetails.signInUserSession) {
                    this.cognitoUser = updatedDetails;
                    await this.setUser(updatedDetails.signInUserSession);
                    this.login$.next({
                        step: LoginStep.LOGIN_COMPLETE
                    });
                    return LoginChallengeResult.SUCCESS;
                }

                if (type !== currentType) {
                    this.login$.next({
                        step: LoginStep.LOGIN_CHALLENGE,
                        details: {
                            userDetails: updatedDetails,
                            type: type || "unknown"
                        }
                    });
                    return LoginChallengeResult.SUCCESS;
                }
                //The same challenge was issued, so they got the challenge wrong.
                return LoginChallengeResult.FAILED_ATTEMPT;
            })
        ).pipe(
            catchError((error: Error) => {
                if (error.message && error.message.includes("Invalid session")) {
                    return of(LoginChallengeResult.TIMEOUT);
                }
                return of(LoginChallengeResult.DENIED);
            })
        );
    }

    completeTwoFactor(code: string, remember?: boolean): Observable<LoginChallengeResult> {
        return this.sendMFACode(code, remember);
    }

    sendMFACode(code: string, remember?: boolean): Observable<LoginChallengeResult> {
        return defer(() =>
            sendMFACode(this.cognitoUser, code).then(async session => {
                if (session) {
                    // set sms as user mfa preference for already created users
                    await setUserMfaPreference(this.cognitoUser, { PreferredMfa: true, Enabled: true }, null);
                    await this.setUser(session, remember);
                    this.login$.next({ step: LoginStep.LOGIN_COMPLETE });
                    return LoginChallengeResult.SUCCESS;
                }
                return LoginChallengeResult.FAILED_ATTEMPT;
            })
        ).pipe(
            catchError((error: Error) => {
                if (error.message && error.message.includes("Invalid code")) {
                    return of(LoginChallengeResult.FAILED_ATTEMPT);
                }
                if (error.message && error.message.includes("Invalid session")) {
                    return of(LoginChallengeResult.TIMEOUT);
                }
                return of(LoginChallengeResult.DENIED);
            })
        );
    }

    async setUser(session: CognitoUserSession, remember?: boolean) {
        this.cognitoUser.setSignInUserSession(session);
        if (remember) {
            await rememberDevice(this.cognitoUser);
        }
        const user = await this.mapToUser(this.cognitoUser);
        this.credentials = null;
        this.user$.next(user);
    }

    beginVerifyMobileNumber(mobileNumber: string): Observable<{ status: string; message: string }> {
        const url = `${this.environment.auth.base}${environmentCommon.auth.endpoints.verifyMobile}`;
        return this.authApiPost(url, {
            mobileNumber,
            userPoolClientId: this.environment.auth.userPoolWebClientId,
            redirectUrl: this.environment.emailVerifyUrl,
            themeName: this.environment.appTheme
        });
    }

    confirmVerifyMobileNumber(code: string): Observable<{ status: string; message: string }> {
        const url = `${this.environment.auth.base}${environmentCommon.auth.endpoints.confirmMobile}`;
        return this.authApiPost(url, { code });
    }

    confirmVerifyEmailAddress(code: string): Observable<{ status: string; message: string }> {
        const url = `${this.environment.auth.base}${environmentCommon.auth.endpoints.confirmEmail}`;
        return this.authApiPost(url, { code });
    }

    private authApiPost(url: string, body: any) {
        return defer(() => this.http.post(url, body)).pipe(
            map((response: any) => ({
                status: response.data.status,
                message: response.data.message
            })),
            catchError(
                (response: any): Observable<{ status: string; message: string }> => {
                    if (response && response.error && response.error.data) {
                        return of({
                            status: response.error.data.status,
                            message: response.error.message
                        });
                    } else {
                        return throwError(response);
                    }
                }
            )
        );
    }

    logout() {
        return defer(() => Auth.signOut({ global: true }))
            .pipe(
                tap(() => {
                    this.login$.next({ step: undefined });
                    this.user$.next(null);
                }),
                map(() => this.revokeToken())
            );
    }

    private revokeToken(): Observable<unknown> {
        try {
            const url = `${this.environment.cognitoDomainUrl}${environmentCommon.auth.oauth2}${environmentCommon.auth.endpoints.revoke}`
            const userSession = this.cognitoUser.getSignInUserSession()
            if (userSession === null) return of(null)
            const headers = new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded'
            });
            const body = new HttpParams()
                .append('token', userSession.getRefreshToken()?.getToken())
                .append('client_id', userSession.getAccessToken()?.payload?.client_id);
            return this.http.post(url, body.toString(), { headers })
        } catch (error) {
            return throwError(`Error while revoking user token. Error: ${error}`)
        }
    }

    getUser(): Observable<AppUser> {
        return this.user$.asObservable();
    }

    getHttpHeaders(): Observable<any> {
        return defer(() => Auth.currentSession()).pipe(
            map(session => ({
                Authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
                awsaccesstoken: `${session.getAccessToken().getJwtToken()}`,
                "findex-invitation": "none"
            })),
            catchError(() => of({}))
        );
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.getHttpHeaders().pipe(
            switchMap(setHeaders => {
                if (!setHeaders) {
                    return next.handle(req);
                }
                const reqWithAuth = req.clone({ setHeaders });
                return next.handle(reqWithAuth);
            })
        );
    }

    private attrVal(attributes: CognitoUserAttribute[], name: string): string {
        const attribute = attributes.find(attr => attr.getName() === name);
        if (!attribute) return null;

        return attribute.getValue();
    }

    private async mapToUser(cognitoUser: CognitoUser): Promise<AppUser> {
        const data = await getUserAttributes(cognitoUser);
        const accessLevel = this.attrVal(data, "custom:authorization-level");
        const rawUserId = this.attrVal(data, "sub");
        const prefixedUserId = `${environmentCommon.auth.userIdPrefix}-${rawUserId}`;
        return {
            id: prefixedUserId,
            name: this.attrVal(data, "name"),
            emailAddressVerified: this.attrVal(data, "email_verified") === "true",
            mobileNumberVerified: this.attrVal(data, "phone_number_verified") === "true",
            details: {
                givenName: this.attrVal(data, "given_name"),
                familyName: this.attrVal(data, "family_name"),
                mobileNumber: this.attrVal(data, "phone_number"),
                emailAddress: this.attrVal(data, "email").toLowerCase()
            },
            type: AuthStrategy.Cognito,
            authorizationLevel: validateAuthorizationLevel(accessLevel),
            globalRole: null
        };
    }

    async refreshTokens() {
        try {
            await refreshSession(this.cognitoUser);
            const user = await this.mapToUser(this.cognitoUser);
            this.user$.next(user);
        } catch (error) {
            //Ignore
            console.error(error);
        }
    }

    completeNewPassword(userDetails: any, newPassword: string): Observable<any> {
        return from(
            Auth.completeNewPassword(
                userDetails,
                newPassword,
                {},
                {
                    userPoolClientId: this.environment.auth.userPoolWebClientId,
                    themeName: this.environment.appTheme,
                    redirectUrl: this.environment.emailVerifyUrl
                }
            ).then(updatedDetails => {
                this.credentials.password = newPassword;
                this.cognitoUser = updatedDetails;
                if (updatedDetails.challengeName === LoginChallenge.smsMfa) {
                    this.login$.next({
                        step: LoginStep.LOGIN_CHALLENGE,
                        details: {
                            userDetails,
                            type: LoginChallenge.smsMfa
                        }
                    });
                }
            })
        ).pipe(
            catchError((error: Error) => {
                //The pre-token authentication prevents token generation for new password - this forces 2FA to occur.
                if (error.message && error.message.includes("RE_AUTHENTICATION_REQUIRED")) {
                    return this.performSignIn(userDetails.challengeParam.userAttributes.email, newPassword);
                } else {
                    return throwError(error);
                }
            })
        );
    }
}
