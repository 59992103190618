<ng-container *ngIf="user$ | async; let user">
    <thread-card
        *ngIf="card$ | async; let card"
        title="Conference"
        [thread]="thread$ | async"
        [card]="card"
        [createdById]="card.createdBy"
        [replies]="replies$ | async"
        [invertTheme]="user.id === card.createdBy"
        [loading]="!!loader.counter"
    >
        <ng-container *ngIf="vcDetails$ | async; let details">
            <meeting-details
                *ngIf="details.sessionId"
                [status]="details.status"
                (join)="openFullscreen(true)"
                (terminate)="terminateSession()"
                [sessionTerminated]="details.isTerminated"
                [role]="role"
                [showButtons]="thread$ | async | isThreadActive | async"
            ></meeting-details>

            <div class="recording-link" *ngIf="(role | permissions: 'DownloadRecording' | async) && card.status !== CardStatus.Removed && card.status !== CardStatus.Disabled">
                <recording-link
                    *ngIf="details.isTerminated"
                    [threadId]="(thread$ | async)?.id"
                    [cardId]="card.id"
                    (showLoader)="loader.toggleVisibility($event)"
                ></recording-link>
            </div>
        </ng-container>
        <div card-loader>
            <loader-icon [color]="'light'"></loader-icon>
        </div>
        <div class="thread-card-error" *ngIf="errorMessage">
            {{ errorMessage }}
        </div>
    </thread-card>
</ng-container>
