import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class ColorService {

    public readonly defaultColors = {
        "background": "#00adf0",
        "selectedSolid": "#fed4e8",
        "targetLine": "#3f3f3f"
    };

    private colorStack = [
        "#004B78",
        "#CCD6DC",
        "#2367AB",
        "#78A2CC",
        "#00ADF0",
        "#8FF8FF",
        "#6B7683",
        "#16D3D3",
    ];
    private colorMap = new Map();

    constructor() {
    }

    public getColor(classification: string): string {
        const color = this.colorMap.get(classification);

        return color ?? this.getAssignedColor(classification);
    }

    private getAssignedColor(classification: string): string {
        const colorFromStack = this.colorStack.pop();
        const assignedColor = colorFromStack ?? this.getGeneratedColor();
        this.colorMap.set(classification, assignedColor);

        return this.colorMap.get(classification);
    }

    private getGeneratedColor(): string {
        return "#" + Math.floor(Math.random() * 16777215).toString(16);
    }
}
