<div [ngClass]="{ 'insights-list-item--selected': active }"  class="insights-list-item">
    <div class="insights-list-item-report" (click)="selectReport(report)">
        <i class="pdf-icon las la-file-pdf"></i>
        <div class="insights-list-item-report-details">
            <div
                class="insights-list-item-report-details-filename"
                [ngClass]="{ 'insights-list-item-report-details-filename--selected': active }"
            >
                {{ report.file.displayName }}
            </div>
            <div class="insights-list-item-report-details-timestamp">Uploaded on {{ report.file.timestamp | date: "dd/MM/yy"}}</div>
        </div>
    </div>
    <i class="download-icon las la-download" (click)="emitDownloadReport.emit(report)"></i>
</div>
