<thread-list
    [threadListings]="threadsList$ | async"
    [globalRole]="globalRole$ | async"
    [activeId]="route.snapshot.firstChild?.params.threadId"
    [statusFilter]="userStatusFilter$ | async"
    [loading]="!!loader.counter"
    (statusFilterUpdate)="statusFilterUpdate($event)"
    (selected)="selectThread($event)"
>
    <router-outlet (activate)="showDisabledThread = false" (deactivate)="showDisabledThread = true"></router-outlet>

    <ng-container *ngIf="showDisabledThread">
        <fx-layout [gutters]="false">
            <fx-layout-column [sizes]="{ mobile: 24 }">
                <div class="fx-threads-breadcrumbs"></div>
                <div class="fx-threads-tabs">
                    <fx-tabs [disabled]="true">
                        <fx-tabs-item header="Timeline"></fx-tabs-item>
                        <fx-tabs-item header="Files"></fx-tabs-item>
                    </fx-tabs>
                    <button class="thread-participants-toggle" [disabled]="true">
                        <i class="las la-user-plus"></i> <span class="badge">0</span>
                    </button>
                </div>
            </fx-layout-column>
            <div class="tooltip-column">
                <div class="tooltip-panel">
                    <p class="tooltip-header">Select a timeline</p>
                    <p>It seems you have no timeline selected. Start by selecting a timeline</p>
                </div>
            </div>
        </fx-layout>
    </ng-container>
</thread-list>
