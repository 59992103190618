import { Component, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { OpenMeasuresService } from "../../services/open-measures.service";

@Component({
    selector: "app-insights-account-listing",
    templateUrl: "./insights-account-listing.component.html",
    styleUrls: ["./insights-account-listing.component.scss"]
})
export class InsightsAccountListingComponent implements OnInit {
    readonly gaEvents = GA_EVENTS;

    loader = new Loader();

    // add types
    mockData = [
        {
            classifications: [],
            id: "2b3d71a4-c8fa-454a-9466-d6c945108cce",
            enterpriseId: "04dbc102-b32e-401e-a34e-06c8155166f5",
            label: "Kunalstop South Yarra",
            tags: []
        },
        {
            classifications: [],
            id: "96f1a9ad-958c-4d30-95c4-c4231ec5a8e1",
            enterpriseId: "04dbc102-b32e-401e-a34e-06c8155166f5",
            label: "Peterstop Northlakes",
            tags: []
        }
    ];

    tableData = new MatTableDataSource<any>();

    constructor(private openMeasures: OpenMeasuresService) {

    }

    async ngOnInit() {
        this.loader.show();
        
        const enterprises = await this.openMeasures.getEnterprises().toPromise();

        const entities$ = enterprises.map(async enterprise => {
            const entities = await this.openMeasures.getEntities(enterprise.id).toPromise();
            return entities.map(entity => ({
                ...entity,
                label: `${enterprise.label} - ${entity.label}`
            }));
        })

        const entities = await Promise.all(entities$);
        this.tableData.data = [].concat(...entities);
        this.loader.hide();
    }
}
