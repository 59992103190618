<div class="mobile-sort">
    <fx-select class="mobile-sort--transparent" (change)="sortBy($event, sort?.direction)" placeholder="Sort By">
        <fx-option *ngFor="let column of columns" [value]="column.id">{{ column.label }}</fx-option>
    </fx-select>

    <fx-select class="mobile-sort--transparent" (change)="sortBy(sort.active, $event)" placeholder="Sort Direction">
        <fx-option value="asc">Ascending</fx-option>
        <fx-option value="desc">Descending</fx-option>
    </fx-select>

    <fx-checkbox
        *ngIf="selectable === true"
        class="fx-table-select-all"
        (change)="toggleSelectAll()"
        [checked]="selection.hasValue() && (selectedRowCount | isAllSelected: totalRowsCount)"
    >
        Select All
    </fx-checkbox>
</div>
<table
    mat-table
    [dataSource]="tableData"
    *ngIf="displayedColumns$ | async; let displayedColumns"
    matSort
    [multiTemplateDataRows]="true"
>
    <ng-container [matColumnDef]="checkboxColumnId">
        <th mat-header-cell *matHeaderCellDef class="v-align-top">
            <fx-checkbox
                (change)="toggleSelectAll()"
                [checked]="selection.hasValue() && (selectedRowCount | isAllSelected: totalRowsCount)"
            ></fx-checkbox>
        </th>
        <td mat-cell *matCellDef="let row" class="checkbox">
            <fx-checkbox (change)="checkboxClicked(row, $event)" [checked]="selection.isSelected(row)"> </fx-checkbox>
        </td>
    </ng-container>

    <ng-container *ngFor="let column of columns; trackBy: trackColumn" [matColumnDef]="column.id">
        <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            [disabled]="!column.sortable"
            (click)="analyticsSortClick(column)"
        >
            {{ column.label }}
        </th>

        <td mat-cell *matCellDef="let row" [ngClass]="{ mobile: column.mobileCollapse }">
            <span class="cell-label" *ngIf="!column.mobileHideLabel">{{ column.label }}: </span>
            <ng-template
                [ngTemplateOutlet]="column.template"
                [ngTemplateOutletContext]="{ $implicit: row }"
            ></ng-template>
        </td>
    </ng-container>

    <ng-container matColumnDef="rowChild" *ngIf="rowChild">
        <td mat-cell *matCellDef="let row" [attr.colspan]="displayedColumns.length">
            <ng-template
                [ngTemplateOutlet]="rowChild.template"
                [ngTemplateOutletContext]="{ $implicit: row }"
            ></ng-template>
        </td>
    </ng-container>

    <!-- Header and Row Declarations -->
    <tr class="header" mat-header-row *matHeaderRowDef="displayedColumns"></tr>

    <tr
        [class.selected]="selectedRows.has(row)"
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        [ngClass]="{
            'mat-header-row--clickable': rowClickable,
            'mat-row--has-child': rowChild && !(row?.hideRowChild$ | async)
        }"
        (click)="rowClick.emit(row)"
    ></tr>

    <ng-container *ngIf="rowChild">
        <ng-container *matRowDef="let row; columns: []">
            <ng-container *ngIf="!!(row?.hideRowChild$ | async)">
                <hr class="row-child-divider" />
            </ng-container>
        </ng-container>

        <tr
            class="row-child"
            mat-row
            *matRowDef="let row; columns: ['rowChild']"
            [hidden]="!!(row?.hideRowChild$ | async)"
        ></tr>
        <ng-container *matRowDef="let row; columns: []">
            <ng-container *ngIf="!(row?.hideRowChild$ | async)">
                <hr class="row-child-divider" />
            </ng-container>
        </ng-container>
    </ng-container>
</table>
