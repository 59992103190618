import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { IParticipant, IThread } from "@findex/threads";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { CalendarCardService } from "../../services/calendar-card.service";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import {
    CreateInvitationCloseModalComponent,
    ICreateInvitationCloseModalData
} from "./close-modal/create-invitation-close-modal.component";
import { ENVIRONMENT } from "src/app/injection-token";
import { IMeetingRequestDetails } from "../create-invitation/create-invitation.component";
import { CalendarBookMeetingComponent } from "../calendar-book-meeting/calendar-book-meeting.component";
import { Observable } from "rxjs";
import {
    ButtonType,
    IStepConfiguration
} from "projects/portal-modules/src/lib/threads-ui/components/multi-component-layout/multi-component-layout.component";
import { MultiComponentService } from "projects/portal-modules/src/lib/threads-ui/components/multi-component-layout/multi-component.service";
import { AnalyticsService, GA_EVENTS, HOT_JAR_EVENTS } from "projects/portal-modules/src/lib/analytics";

export enum ModalTitle {
    "editMeeting" = "Edit Attendees",
    "createMeeting" = "Create Meeting Request"
}

export type MeetingRequestModalData = {
    meetingData: IMeetingRequestDetails;
    thread: IThread;
    edit: boolean;
};

@Component({
    selector: "calendar-meeting-request",
    templateUrl: "./calendar-meeting-request.component.html",
    styleUrls: ["./calendar-meeting-request.component.scss"]
})
export class CalendarMeetingRequestComponent implements OnInit {
    readonly theme = this.environment.theme;
    participants: IParticipant[] = [];
    modalTitle: string;
    editAttendees: boolean;
    attendees$: Observable<IParticipant[]>;
    showAttendeeErrorMessage = false;
    loader = new Loader();
    activeStepIndex = 0;
    meetingData: IMeetingRequestDetails;
    showValidationErrors = false;
    stepConfigurations: IStepConfiguration[] = [
        {
            stepIndex: 0,
            buttons: [
                {
                    title: "Send Request",
                    type: ButtonType.Finish,
                    isDisabled: false,
                    isHidden: false
                }
            ]
        }
    ];

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: MeetingRequestModalData,
        private dialogRef: MatDialogRef<CalendarMeetingRequestComponent, IParticipant[]>,
        private dialog: MatDialog,
        private cardService: CalendarCardService,
        private multiComponentService: MultiComponentService,
        private analytics: AnalyticsService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig
    ) {
        this.dialogRef.disableClose = true;
    }

    ngOnInit() {
        if (this.data.edit) {
            this.modalTitle = ModalTitle.editMeeting;
            this.editAttendees = this.data.edit;
        } else {
            this.modalTitle = ModalTitle.createMeeting;
        }

        this.meetingData = this.data?.meetingData;
        this.participants = this.data?.thread.participants;
    }

    async submit() {
        if (!CalendarBookMeetingComponent.validateMeetingDetailsEventBody(this.meetingData)) {
            this.showValidationErrors = true;
            this.stepConfigurations = this.multiComponentService.toggleForwardButtons(
                this.activeStepIndex,
                this.stepConfigurations,
                false
            );

            return;
        }

        if (this.editAttendees) {
            this.dialogRef.close(this.meetingData.attendees);
        } else {
            this.create();
        }
    }

    async create() {
        this.analytics.recordEvent("calendar", GA_EVENTS.CALENDAR_CREATEINVITATION);
        this.analytics.recordEvent("calendar", HOT_JAR_EVENTS.MeetingSendRequestCompleteEvent);
        try {
            if (!this.meetingData.meetingDescription) {
                const data: ICreateInvitationCloseModalData = {
                    acceptButtonName: "Send",
                    rejectButtonName: "Cancel",
                    confirmDescription: "Are you sure you want to send this meeting request without a description?",
                    confirmTitle: "Missing description"
                };
                const shouldSend = await this.confirmClose(data);
                if (!shouldSend) {
                    return;
                }
            }
            this.loader.show();
            await this.cardService
                .createRequest(
                    this.data.thread.id,
                    this.meetingData.title,
                    this.meetingData.duration,
                    this.meetingData.attendees,
                    this.meetingData.recurrenceType,
                    this.meetingData.numberOfOccurrences,
                    this.meetingData.meetingDescription
                )
                .toPromise();
            this.dialogRef.close();
        } finally {
            this.loader.hide();
        }
    }

    async close() {
        const data: ICreateInvitationCloseModalData = {
            acceptButtonName: "Discard",
            rejectButtonName: "Keep Editing",
            confirmDescription: "Are you sure you want to discard this meeting?",
            confirmTitle: "Discard meeting request"
        };
        const confirmClose = await this.confirmClose(data);
        if (confirmClose) {
            this.dialogRef.close();
        }
    }

    handleMeetingDetailsUpdated(event: IMeetingRequestDetails) {
        this.meetingData = event;
        this.stepConfigurations = this.multiComponentService.toggleForwardButtons(
            this.activeStepIndex,
            this.stepConfigurations,
            true
        );
    }

    private async confirmClose(data: ICreateInvitationCloseModalData) {
        const config = {
            data,
            panelClass: ["threads-sidebar", "threads-sidebar-inner-close-modal"],
            autoFocus: true
        };

        return await this.dialog
            .open(CreateInvitationCloseModalComponent, config)
            .afterClosed()
            .toPromise();
    }
}
