import { Component, Inject, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy } from "@angular/core";
import { IParticipant, ICalendarParticipant } from "@findex/threads";
import { map, take } from "rxjs/operators";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import {
    defaultCalendarMeetingDurations,
    EnvironmentSpecificConfig,
    ICalendarMeetingDuration,
    environmentCommon
} from "projects/portal-modules/src/lib/environment/environment.common";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ENVIRONMENT } from "src/app/injection-token";
import { RecurrenceType } from "@findex/calendar-types";
import { Subscription } from "rxjs";
import { isEqual } from "lodash-es";

export interface IMeetingRequestDetails {
    title: string;
    numberOfOccurrences: number;
    meetingDescription: string;
    recurrenceType: RecurrenceType;
    attendees: ICalendarParticipant[];
    duration: number;
    organiser: string;
}
export type ReccurrenceOptions = "never" | "daily" | "weekly" | "fortnightly" | "monthly";
@Component({
    selector: "calendar-create-invitation",
    templateUrl: "./create-invitation.component.html",
    styleUrls: ["./create-invitation.component.scss"]
})
export class CreateInvitationComponent implements OnChanges, OnDestroy {
    readonly theme = this.environment.theme;
    readonly timeSlots: ICalendarMeetingDuration[] =
        this.environment.featureFlags.calendarMeetingDurations || defaultCalendarMeetingDurations;
    readonly recurrenceOptions = [
        { value: "never", name: "Never" },
        { value: "weekly", name: "Weekly" },
        { value: "fortnightly", name: "Fortnightly" },
        { value: "monthly", name: "Every 4 weeks" }
    ];
    readonly textAreaCharacterLimit = environmentCommon.textareaCharacterLimit;

    @Input() participants: IParticipant[] = [];
    @Input() showValidationErrors = false;
    @Input() meetingDetails?: IMeetingRequestDetails;
    @Input() editAttendees: boolean;
    @Output() emitDetails = new EventEmitter<IMeetingRequestDetails>();

    form = new FormGroup({
        title: new FormControl("", [Validators.required]),
        recurrenceType: new FormControl("never", [Validators.required]),
        numberOfOccurrences: new FormControl(10, []),
        meetingDescription: new FormControl("", [
            Validators.required,
            Validators.maxLength(this.textAreaCharacterLimit)
        ])
    });

    attendees: ICalendarParticipant[] = [];
    meetingTitle = "";
    recurrence: string;
    meetingDuration = this.timeSlots.find(timeSlot => timeSlot.defaultSelection)?.durationMinutes;
    isValidOccurrence = false;
    currentUserId: string;
    loader = new Loader();
    formSub: Subscription;

    quillError = false;

    constructor(private authService: AuthService, @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig) {
        this.isValidOccurrence = this.validOccurrence();
        this.form.valueChanges.subscribe(() => {
            this.isValidOccurrence = this.validOccurrence();
        });
        this.formSub = this.form.valueChanges.subscribe(() => {
            this.emitChanges();
        });
    }

    ngOnDestroy() {
        this.formSub.unsubscribe();
    }

    emitChanges() {
        this.emitDetails.emit({
            ...this.form.value,
            attendees: this.attendees,
            duration: this.meetingDuration,
            organiser: this.currentUserId
        });
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes?.participants?.currentValue) {
            this.currentUserId = await this.authService
                .getUser()
                .pipe(
                    map(user => user.id),
                    take(1)
                )
                .toPromise();

            if (!this.meetingDetails?.attendees?.length) {
                await this.setInitialAttendee();
            } else {
                this.attendees = this.meetingDetails.attendees;
            }
        }

        if (changes?.meetingDetails?.currentValue) {
            const { title, duration, recurrenceType, numberOfOccurrences, meetingDescription } = this.meetingDetails;
            const update = {
                title: title || "",
                recurrenceType: recurrenceType || "never",
                numberOfOccurrences: numberOfOccurrences || 2,
                meetingDescription: meetingDescription || ""
            };

            if (!isEqual(update, this.form.value)) {
                this.meetingDuration = duration;
                this.form.setValue(update);
            }
        }
    }

    async setInitialAttendee() {
        this.attendees = this.participants
            .filter(participant => participant.id === this.currentUserId)
            .map(participant => ({ ...participant, required: true }));
    }

    selectedParticipants(participants: IParticipant[]) {
        this.attendees = participants;
        this.emitDetails.emit({
            ...this.form.value,
            attendees: this.attendees,
            duration: this.meetingDuration,
            organiser: this.currentUserId
        });
    }

    private validOccurrence() {
        const { recurrenceType, numberOfOccurrences } = this.form.getRawValue();
        return recurrenceType === "never" || (numberOfOccurrences > 1 && numberOfOccurrences <= 10);
    }
}
