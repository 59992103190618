<centered-modal-layout
    cancelButtonTitle="Cancel"
    saveButtonTitle="Reopen"
    (save)="onContinue()"
    (close)="close()"
    [loading]="!!loader.counter"
    class="reopen-request-modal"
    [analyticsPrefix]="ANALYTICS_EVENTS"
>
    <div class="reopen-request-header" modal-header>
        Are you sure you want to reopen this request?
    </div>
    <div modal-subhead>
        If reopened, the client will receive the original request again.
    </div>
    <div modal-content>
        <form [formGroup]="form" class="reopen-request-modal-content">
            <div class="reopen-request-modal-content-message">
                <p>
                    Message to the Client (optional)
                </p>
                <textarea
                    class="reopen-request-modal-content-message-description"
                    rows="5"
                    placeholder="This message will be a 'Reply' on the request"
                    formControlName="message"
                ></textarea>
            </div>
        </form>
        <div class="thread-card-error" *ngIf="errorMessage">
            {{ errorMessage }}
        </div>
    </div>
</centered-modal-layout>
