<div class="file-input">
    <ngx-dropzone (change)="onSelect($event)" [accept]="supportedFiles || '*'" [processDirectoryDrop]="enableDirectoryDrop">
        <ngx-dropzone-label>
            <p *ngIf="!isMobile">
                <i class="las la-cloud-upload-alt file-input-icon"></i>
                <span>
                    Drop your files here, or
                </span>
                <a role="button" analyticsClick="{{ analyticsPrefix }}_browsefiles" class="file-input-browse">
                    browse
                </a>
            </p>
            <p *ngIf="isMobile">
                <a
                    role="button"
                    analyticsClick="{{ analyticsPrefix }}_browsefiles"
                    class="file-input-browse fx-btn fx-btn--secondary fx-btn--large"
                >
                    <i class="las la-cloud-upload-alt"></i> Upload Files
                </a>
            </p>

            <div *ngIf="supportedFiles" class="file-input-supported">Supports {{ supportedFiles }}</div>
            <div class="file-input-error" *ngIf="errorMessages">
                <div *ngFor="let messages of errorMessages" class="file-input-error-messages">
                    {{ messages }}
                </div>
            </div>
        </ngx-dropzone-label>
    </ngx-dropzone>
</div>
