import {
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

@Component({
    selector: "app-thread-search",
    templateUrl: "./thread-search.component.html",
    styleUrls: ["./thread-search.component.scss"]
})
export class ThreadSearchComponent implements OnInit, OnChanges, OnDestroy {
    @Input() isMobileView = true;
    @Input() analyticsClickEvent: string;
    @Input() placeholder: string;
    @Output() search = new EventEmitter<string>();
    @Output() active = new EventEmitter<boolean>();

    @HostBinding("class.active") activeClass: boolean;
    @ViewChild("search", { static: false }) inputElement: ElementRef;

    expandSearch = false;

    searchTerm$: Subject<string> = new Subject<string>();

    searchSubscription$: Subscription;
    constructor() {}

    ngOnChanges(changes: SimpleChanges) {
        const { isMobileView } = changes;
        if (!isMobileView?.currentValue) {
            this.expandSearch = !this.isMobileView;
        }
    }

    ngOnInit() {
        this.searchSubscription$ = this.searchTerm$.pipe(debounceTime(200), distinctUntilChanged()).subscribe(term => {
            this.search.emit(term);
        });
    }

    setActive(active: boolean) {
        if (active) {
            setTimeout(() => this.inputElement.nativeElement.focus());
        }

        this.expandSearch = active;
        this.activeClass = active;
        this.active.emit(active);
    }

    ngOnDestroy() {
        if (this.searchSubscription$) {
            this.searchSubscription$.unsubscribe();
        }
    }
}
