<div class="fx-threads-status" *ngIf="workflowState | getCurrentStep; let currentStep">
    <thread-workflow-progress-indicator
        *ngIf="
            currentStep.clientFacingId !== ThreadStatus.cancelled && currentStep.clientFacingId !== ThreadStatus.closed;
            else hasStatusBadge
        "
        [workflowState]="workflowState"
        [enablePopUp]="enablePopUp"
        [small]="small"
        [role]="role"
        [thread]="thread"
        [shouldShowTitle]="shouldShowTitle"
        [fullWidth]="fullWidth"
    >
    </thread-workflow-progress-indicator>

    <ng-template #hasStatusBadge>
        <status-badge [workflow]="workflowState"></status-badge>
    </ng-template>
</div>
