<div class="modal">
    <loader-icon color="light" *ngIf="loading" [showBackground]="true"></loader-icon>
    <div class="modal-template">
        <div class="modal-template-header">
            <div>            
                <h1>
                    <ng-content select="[modal-header]"></ng-content>
                </h1>
            </div>
            <div class="modal-template-header-action">
                <ng-content class="" select="[modal-template-header-action]"></ng-content>
            </div>
            <div class="modal-template-header-close">
                <i
                    (click)="close.emit()"
                    class="las la-times icon-size"
                    [analyticsClick]="closeButtonAnalyticsCode"
                ></i>
            </div>
        </div>
        <div class="modal-template-subhead">
            <ng-content select="[modal-subhead]"></ng-content>
        </div>
        <div class="modal-template-content" [ngStyle]="{maxHeight: contentMaxHeight}">
            <ng-content select="[modal-content]"></ng-content>
        </div>
        <div *ngIf="error" class="modal-template-error">
            {{ error }}
        </div>
        <div class="modal-template-footer">
            <button
                *ngIf="cancelButtonTitle"
                (click)="close.emit()"
                class="fx-btn fx-btn--white modal-template-close"
                [analyticsClick]="cancelButtonAnalyticsCode"
            >
                {{ cancelButtonTitle }}
            </button>
            <button
                class="fx-btn fx-btn--primary modal-template-save"
                role="button"
                (click)="save.emit($event)"
                [disabled]="disableSave"
                analyticsClick="{{ analyticsPrefix }}{{ analyticsPostfix }}"
            >
                {{ saveButtonTitle }}
            </button>
        </div>
    </div>
</div>
