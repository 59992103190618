import { Component, Input, OnInit } from "@angular/core";
import { RouterEvent, NavigationEnd, Router } from "@angular/router";
import { Role } from "@findex/threads";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";

@Component({
    selector: "thread-list-node-ghost",
    templateUrl: "./thread-list-node-ghost.component.html",
    styleUrls: [
        "./../thread-list.component.scss",
        "./../../thread-list-item/thread-list-item.component.scss",
        "./../thread-list-node/thread-list-node.component.scss",
        "./thread-list-node-ghost.component.scss"
    ]
})
export class ThreadListNodeGhostComponent implements OnInit {
    @Input() showAnimation = true;
    @Input() globalRole: Role;

    ghostArray = new Array(7);
    role = Role;

    constructor(public loader: Loader, private router: Router) {}

    async ngOnInit() {
        this.showAnimation = !window.location.pathname.includes("/register");
        this.router.events.subscribe((event: RouterEvent) => {
            if (event instanceof NavigationEnd) {
                const { urlAfterRedirects } = event;
                this.showAnimation = !urlAfterRedirects.includes("/register");
            }
        });
    }
}
