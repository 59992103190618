import { Component, EventEmitter, Inject, Output, ViewChild } from "@angular/core";
import { DateTime } from "luxon";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { ThreadsService } from "projects/portal-modules/src/lib/threads-ui/services/threads.service";
import { IThread, IWorkflow, IWorkflowStep, IThreadWorkflowStep, IWorkflowTimeEstimate, ThreadTypes } from "@findex/threads";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ENVIRONMENT } from "src/app/injection-token";
import { EnvironmentSpecificConfig } from "../../../environment/environment.common";
import {
    ButtonType,
    IStepConfiguration,
    MultiComponentLayoutComponent
} from "../multi-component-layout/multi-component-layout.component";
import { MultiComponentService } from "../multi-component-layout/multi-component.service";
import { UnsavedModalDialogService } from "../../../shared/services/unsaved-modal-dialog.service";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { defer, Observable } from "rxjs";
import { distinctUntilChanged, filter, map, shareReplay, startWith, switchMap, take } from "rxjs/operators";
import { WorkflowService } from "../../services/workflow.service";
import { DialogDataConfig } from "./new-thread-modal.interface";
import { MAT_LUXON_DATE_FORMATS } from "@angular/material-luxon-adapter";

enum STEPS {
    "SelectService" = 0,
    "ServiceInformation" = 1
}

export type NewThreadType = {
    title: string;
    type: ThreadTypes;
    subtype?: string;
    workflowId: string;
    initialMessage?: string;
    requiresCustomTitle?: boolean;
    requiresCustomMonth?: boolean;
    requiresCustomYear?: boolean;
    requiresCustomSubtypes?: boolean;
    titleMapping?: (formValue: { customThreadTitle: string; currentMonth: string; threadType: string, customFormYear: number, customSubtype: string }) => string;
};

const WORKFLOW_PROVIDER = "legacy";
const DEFAULT_YEARS_PRIOR = 6;

@Component({
    selector: "new-thread",
    templateUrl: "./new-thread-modal.component.html",
    styleUrls: ["./new-thread-modal.component.scss"],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: "en-AU" },
        { provide: MAT_DATE_FORMATS, useValue: MAT_LUXON_DATE_FORMATS }
    ]
})
export class NewThreadModalComponent {
    loader = new Loader();
    thread: IThread;
    editModeEnable: boolean;
    currentUserId: string;

    @ViewChild(MultiComponentLayoutComponent)
    multiComponentLayoutComponent: MultiComponentLayoutComponent;

    @Output() dateChange: EventEmitter<MatDatepickerInputEvent<Event>> = new EventEmitter();

    CUSTOM_TITLE_MAX_LENGTH = 20;
    newThreadMonths = this.generateMonths();
    threadTypeControl = new FormControl(null, [Validators.required]);
    workflow$: Observable<IWorkflow>;
    hasDueDates$: Observable<boolean>;
    rhythmDates = new FormControl({});
    customSubtype = new FormControl("");
    customYears: number[];
    customYearsTitle: string;
    initialCustomYear = DateTime.local().year + 1;

    form = new FormGroup({
        customThreadTitle: new FormControl("", [
            Validators.required,
            Validators.maxLength(this.CUSTOM_TITLE_MAX_LENGTH)
        ]),
        currentMonth: new FormControl(this.newThreadMonths[2]),
        threadType: this.threadTypeControl,
        rhythmDates: this.rhythmDates,
        customFormYear: new FormControl(this.initialCustomYear),
        customSubtype: this.customSubtype,
    });
    readonly newThreadTypes = this.environment.featureFlags.creatableThreadsConfiguration;
    readonly newThreadSubtypes = this.environment.featureFlags.auditSubtypes;
    activeStepIndex = 0;
    startDate = DateTime.now();

    stepConfigurations: IStepConfiguration[] = [
        {
            stepIndex: STEPS.SelectService,
            buttons: [
                {
                    type: ButtonType.Forward,
                    title: "Next",
                    isHidden: false,
                    isDisabled: true
                }
            ]
        },
        {
            stepIndex: STEPS.ServiceInformation,
            buttons: [
                {
                    type: ButtonType.Backward,
                    title: "Back",
                    isHidden: true,
                    isDisabled: false
                },
                {
                    type: ButtonType.Finish,
                    title: "Create",
                    isHidden: true,
                    isDisabled: false
                }
            ]
        }
    ];

    constructor(
        @Inject(MAT_DIALOG_DATA) data: DialogDataConfig,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        public dialogRef: MatDialogRef<NewThreadModalComponent>,
        private router: Router,
        private threadsService: ThreadsService,
        private workflowService: WorkflowService,
        private multiComponentService: MultiComponentService,
        private unsavedDialogService: UnsavedModalDialogService
    ) {
        this.thread = data.thread;
        this.editModeEnable = data.editModeEnable;
        this.currentUserId = data.currentUserId;

        const threadType$ = defer(() => this.threadTypeControl.valueChanges.pipe(startWith(this.threadTypeControl.value)));

        this.workflow$ = threadType$.pipe(
            distinctUntilChanged(),
            filter(threadType => !!threadType),
            switchMap((threadType: NewThreadType) =>
                this.loader.wrap(this.workflowService.getWorkflow(WORKFLOW_PROVIDER, threadType.workflowId))
            ),
            shareReplay(1)
        );

        this.hasDueDates$ = this.workflow$.pipe(
            map(workflow => workflow?.steps?.some(step => step?.timeEstimate?.duration != null))
        );
    }


    updateDueDates(dates: Record<string, DateTime>|void) {
        if (dates) {
            this.rhythmDates.setValue(dates);
        }

        this.handleChange(!!dates);
    }

    handleChange(hasValidDates: boolean) {
        const threadTitle = this.form.get("customThreadTitle");
        const hasCustomTitle = this.threadTypeControl.value?.requiresCustomTitle;
        const hasCustomYear = this.threadTypeControl.value?.requiresCustomYear;
        const { title } = this.threadTypeControl.value;

        if(hasCustomYear) {
            this.customYears = this.generateCustomYears();
            this.customYearsTitle = title;
        }

        if (this.form.valid && hasCustomTitle && threadTitle && hasValidDates) {
            this.stepConfigurations = [
                ...this.multiComponentService.toggleForwardButtons(this.activeStepIndex, this.stepConfigurations, true)
            ];
        } else if (hasCustomTitle === undefined && hasValidDates) {
            this.stepConfigurations = [
                ...this.multiComponentService.toggleForwardButtons(this.activeStepIndex, this.stepConfigurations, true)
            ];
        } else {
            this.stepConfigurations = [
                ...this.multiComponentService.toggleForwardButtons(this.activeStepIndex, this.stepConfigurations, false)
            ];
        }
    }

    async handleTransition(activeStepIndex: number) {
        this.stepConfigurations = [
            ...this.multiComponentService.showCurrentStepButtons(activeStepIndex, this.stepConfigurations)
        ];

        if (activeStepIndex === 1) {
            const hasDueDates = await this.hasDueDates$
                .pipe(take(1))
                .toPromise();

            if (!hasDueDates) {
                await this.triggerThreadTransition();
            }
        }

        this.activeStepIndex = activeStepIndex;
    }

    async triggerThreadTransition() : Promise<void>  {
        if(this.editModeEnable) {
            return await this.editThread();
        }
        return await this.createThread();
    }

    private getThreadTitle() : string{
        const currentThreadType = this.threadTypeControl.value;
        const threadType = this.newThreadTypes?.find(type => type.workflowId === currentThreadType.workflowId);
        if (threadType?.titleMapping) {
            return threadType.titleMapping(this.form.value);
        } else {
            return threadType.title;
        }
    }

    async editThread(): Promise<void> {
        this.loader.show();
        const {
            workflowId,
            type,
            requiresCustomSubtypes
        } = this.threadTypeControl.value;
        const { steps: workflowSteps } = await this.workflowService.getWorkflow(WORKFLOW_PROVIDER, workflowId);
        const subType = (requiresCustomSubtypes) ? this.customSubtype.value : "";
        const steps: IThreadWorkflowStep[] = workflowSteps.map((item, key) => ({
            ...item,
            isCurrentStep: (key === 0)
        }));

        const threadWorkflow = {
            id: workflowId,
            provider: WORKFLOW_PROVIDER,
            steps
        }

        await this.threadsService.updateThread(
            this.thread.id,
            {
                workflow: threadWorkflow,
                type,
                subType
            }
        ).toPromise();

        this.dialogRef.close(true);
        this.router.navigate(["/timelines", this.thread.id]);
    }

    async createThread(): Promise<void> {
        this.loader.show();

        try {
            const title = this.getThreadTitle();
            const { type, workflowId, requiresCustomSubtypes } = this.threadTypeControl.value;

            const subType = (requiresCustomSubtypes) ? this.customSubtype.value : "";
            const rhythmDates = this.rhythmDates.value;
            const workflow = await this.workflow$.pipe(take(1)).toPromise();

            const timeEstimates = this.getTimeEstimates(workflow.steps, rhythmDates, this.startDate);

            const participants = this.thread.participants.filter(item => item.id === this.currentUserId);

            const newThread = await this.threadsService
                .createThread(
                    type,
                    subType,
                    title,
                    participants,
                    WORKFLOW_PROVIDER,
                    workflowId,
                    this.thread.accountId,
                    this.threadTypeControl.value.initialMessage,
                    timeEstimates
                )
                .toPromise();

            this.dialogRef.close(true);
            this.router.navigate(["/timelines", newThread.id]);
        } catch (error) {
            this.dialogRef.close(false);
            throw error;
        } finally {
            this.loader.hide();
        }
    }

    private getTimeEstimates(workflowSteps: IWorkflowStep[], dates: Record<string, DateTime>, startDate: DateTime): Record<string, IWorkflowTimeEstimate> {
        return workflowSteps.reduce((estimates, step, i) => {
            if (!step.timeEstimate) return estimates;

            const dueDate = dates[step.clientFacingId];
            const previousDueDate = i > 0 ? dates[workflowSteps[i - 1].clientFacingId] : startDate;
            const timeEstimate = this.calculateTimeEstimate(previousDueDate, dueDate);

            return {
                ...estimates,
                [step.clientFacingId]: timeEstimate
            }
        }, {});
    }

    private calculateTimeEstimate(previousDueDate: DateTime | void, dueDate: DateTime | void): IWorkflowTimeEstimate | void {
        if (!previousDueDate || !dueDate) return;

        return {
            duration: dueDate.toMillis() - previousDueDate.toMillis()
        };
    }

    private generateMonths(): string[] {
        const today = DateTime.local();
        const months = [];

        for (let i = -2; i < 3; i++) {
            const monthString = today.plus({ month: i }).toFormat("LLLL yyyy");
            months.push(monthString);
        }

        return months;
    }

    private generateCustomYears(): number[] {
        const customYears: number[] = [];

        for (let offset = 0; offset <= DEFAULT_YEARS_PRIOR; offset++) {
            customYears.push(this.initialCustomYear - offset);
        }

        return customYears;
    }

    async close() {
        if (!this.form.dirty) {
            this.dialogRef.close();
        } else {
            const confirmClose = await this.unsavedDialogService.confirmClose("new-thread");
            if (confirmClose) {
                this.dialogRef.close();
            }
        }
    }
}
