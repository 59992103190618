import { Component, OnDestroy, Inject, OnInit } from "@angular/core";
import { Subscription, Observable, combineLatest } from "rxjs";
import { IThreadCard } from "@findex/threads/dist/interfaces/IThreadCard";
import { IParticipantListing, Role, IThread, CardReply, CardStatus, VideoChatAction } from "@findex/threads";
import { VcStateBuilder } from "../../services/vc-state-builder";
import { filter, switchMap, take } from "rxjs/operators";
import { THREAD_CARD_RESOURCES, CardResources } from "projects/portal-modules/src/lib/threads-ui/interfaces/IUiCard";
import { VideoChatService } from "../../services/video-chat.service";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { AppUser, AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { ThreadCardService } from "projects/portal-modules/src/lib/threads-ui/services/thread-card.service";
import { ActionableCardComponent } from "projects/portal-modules/src/lib/shared/components/actionable-card/actionable-card";
import { ISlot } from "projects/default-plugins/calendar/services/calendar.service";
import { ILibrary, TaskAction } from "projects/portal-modules/src/lib/plugins";
import { TASK_ACTION_LIBRARY } from "src/app/injection-token";
import { TaskActionService } from "projects/portal-modules/src/lib/shared/components/actionable-card/task-action.service";

@Component({
    selector: "vc-card",
    templateUrl: "./vc-card.component.html",
    styleUrls: ["vc-card.component.scss"]
})
export class VcCardComponent extends ActionableCardComponent<any> implements OnDestroy, OnInit {
    private fullscreenSub: Subscription;
    private eventSub: Subscription;
    private userSub: Subscription;
    private modelBuilder = new VcStateBuilder();

    thread$: Observable<IThread>;
    card$: Observable<IThreadCard>;
    user$: Observable<AppUser>;
    replies$: Observable<CardReply[]>;
    role: Role;
    vcDetails$ = this.modelBuilder.getState();
    loader = new Loader();
    errorMessage: string;
    CardStatus = CardStatus;

    constructor(
        @Inject(THREAD_CARD_RESOURCES) private cardResources: CardResources,
        @Inject(TASK_ACTION_LIBRARY) protected taskActions: ILibrary<TaskAction<ISlot>>,
        private authService: AuthService,
        private cardService: ThreadCardService,
        private videoChatService: VideoChatService,
        protected taskActionService: TaskActionService
    ) {
        super(cardResources, taskActionService);
    }

    ngOnInit() {
        const { threadId, cardId, thread$, card$, eventService, navigateTo$, role, replies$: replies } = this.cardResources;

        this.thread$ = thread$;
        this.card$ = card$;
        this.role = role;
        this.replies$ = replies;
        this.user$ = this.authService.getUser().pipe(filter(user => !!user));
        this.fullscreenSub = navigateTo$.subscribe(() => this.openFullscreen(true));
        this.eventSub = eventService.events.subscribe(event => this.modelBuilder.addEvent(event));
        this.modelBuilder.setThreadAndState(threadId, cardId);

        //I'm guessing eventually this will change to be other participants in the call but not sure on UI
        //But then what if you are the first to join? UI question + need ED-248
        this.userSub = combineLatest([this.user$, this.thread$])
            .pipe(filter(([user]) => !!user))
            .subscribe(([user, thread]) => this.setCaller(thread.participants, user.id));
    }

    ngOnDestroy() {
        if (this.fullscreenSub) this.fullscreenSub.unsubscribe();
        if (this.eventSub) this.eventSub.unsubscribe();
        if (this.userSub) this.userSub.unsubscribe();
    }

    openFullscreen(join?: boolean) {
        if (join) {
            this.action(VideoChatAction.JOIN_CALL);
        } else {
            this.action(VideoChatAction.START_CALL);
        }
    }

    async terminateSession() {
        const detailSub = this.vcDetails$
            .pipe(
                take(1),
                switchMap(detail => {
                    const threadId = detail.threadId;
                    const cardId = detail.cardId;
                    const { sessionId } = detail;

                    const terminate$ = this.videoChatService.terminateSession(sessionId, threadId, cardId);
                    return this.loader.wrap(terminate$);
                })
            )
            .subscribe(() => {
                detailSub.unsubscribe();
            });
        }

    async actionCallback(actionId: string) {
        if (actionId === VideoChatAction.JOIN_CALL) {
            this.action(VideoChatAction.END_SESSION);
        }
    }

    async cancelCard(threadId: string, cardId: string) {
        try {
            this.errorMessage = "";
            this.loader.show();
            await this.cardService.cancelCard(threadId, cardId).toPromise();
        } catch {
            this.errorMessage = "Sorry, something went wrong";
        } finally {
            this.loader.hide();
        }
    }

    private setCaller(participants: IParticipantListing[], excludeId: string): void {
        //This is basically a temp hack
        //What do we want to show when there is more than one participant?? UI Question
        let found = participants.find(participant => participant.id !== excludeId);
        if (!found) found = participants.find(() => true);

        if (!found || !found.profile || !found.profile.name) return;
        this.modelBuilder.setCaller(found.profile.name, found.profile.title);
    }
}
