import { Component, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { IStaffProfile } from "@findex/threads";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";

@Component({
    selector: "app-staff-viewer",
    templateUrl: "./staff-viewer.component.html",
    styleUrls: ["./staff-viewer.component.scss"]
})
export class StaffViewerComponent implements OnChanges {
    @Output() staffMemberSelected = new EventEmitter();
    @Input() staffProfile: IStaffProfile;
    @Input() firstChoice: boolean; // if not the first choice display good match instead of strong match
    @Input() analyticsPrefix = "";
    staffFirstName: string;
    readonly STAGE = this.environment.stage;
    readonly ONBOARDING_STAFF_TITLE = this.environment.featureFlags.onboardingStaffTitle;
    readonly gaEvents = GA_EVENTS;

    constructor(@Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.staffProfile?.currentValue) {
            this.staffFirstName = changes.staffProfile.currentValue.name.split(" ").shift();
        }
    }
}
