<div
    class="thread-card-replies thread-card-replies--hide-left-border"
    [ngClass]="{ 'thread-card-replies--show-compose': showCompose, 'thread-card-replies--readonly': readonly }"
>
    <loader-icon *ngIf="replyLoader.counter" color="light"></loader-icon>
    <div class="thread-card-replies-message" *ngFor="let reply of replies; trackBy: replyTrack">
        <reply
            #replyComponents
            [reply]="reply"
            [thread]="thread"
            [card]="card"
            [userId$]="userId$"
            (replyLoading)="toggleReplyLoader($event)"
        ></reply>
    </div>
    <div
        class="thread-card-replies-button"
        *ngIf="!showCompose && !readonly && (thread | isThreadActive | async)"
        (click)="showCompose = true"
        [analyticsClick]="card.type + '_cardreply'"
    >
        Reply
    </div>

    <div class="thread-card-replies-compose" *ngIf="showCompose">
        <div class="thread-card-replies-input">
            <quill-editor-wrapper
                (userInputEmitter)="onUserInput($event)"
                (error)="quillError = $event"
                [message]="replyMessage"
                [inline]="true"
            ></quill-editor-wrapper>
        </div>

        <reply-buttons
            [submitDisabled]="!replyMessage?.value || !!replyLoader.counter || quillError"
            [submitTitle]="'Send'"
            [analyticsClick]="hotJarEvents.MessageReplyCompleteEvent"
            (submitOutput)="reply(replyMessage)"
            (discardOutput)="replyMessage.reset(); showCompose = false"
        >
        </reply-buttons>
    </div>
</div>
