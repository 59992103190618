<centered-modal-layout
    [loading]="!!loader.counter"
    saveButtonTitle="Create"
    (save)="save()"
    (close)="close()"
    [analyticsPrefix]="ANALYTICS_PREFIX"
    [disableSave]="!form?.valid"
    [error]="errorMessage"
    [contentMaxHeight]="contentMaxHeight"
>
    <div modal-header>New request</div>
    <div modal-subhead>
        Create a request for documents, actions or questions
    </div>
    <div modal-content>
        <div class="request-common">
            <form [formGroup]="form">
                <div class="request-common-group">
                    <div class="request-common-request-info-fields">
                        <label class="fx-form-label" for="vaultTitle">Request title</label>
                        <div class="fx-form-control">
                            <input
                                id="vaultTitle"
                                formControlName="vaultTitle"
                                class="fx-form-input"
                                name="vaultTitle"
                                placeholder="Add a title to this request"
                            />
                        </div>
                        <div
                            *ngIf="
                                form.controls['vaultTitle'].hasError('required') && form.controls['vaultTitle'].touched
                            "
                            class="fx-form-validation"
                        >
                            Request title is required.
                            <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                        </div>
                    </div>
                </div>
                <div class="request-common-group">
                    <div class="request-common-request-info-fields">
                        <label class="fx-form-label" for="dueDate">Due Date</label>
                        <div class="fx-form-control">
                            <input
                                class="fx-form-input"
                                matInput
                                [matDatepicker]="picker"
                                [min]="minDate"
                                name="dueDate"
                                formControlName="dueDate"
                                placeholder="(Optional)"
                            />
                            <mat-datepicker-toggle class="request-common-date-picker-toggle" matSuffix [for]="picker">
                                <i class="las la-calendar request-common-date-picker-icon" matDatepickerToggleIcon></i>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            <div class="fx-form-validation" *ngIf="!form.controls['dueDate'].valid">
                                Date must use this format: DD/MM/YYYY and past dates are not allowed
                                <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                            </div>
                        </div>
                    </div>
                </div>            
                <div class="request-common-group">
                    <div class="request-common-label request-common-group-header">
                        Note or message
                    </div>
                    <quill-editor-wrapper
                        [message]="$any(form.controls.cardDescription)"
                        placeholder="Add a message, notes, and attachments that relate to your request."
                    ></quill-editor-wrapper>
                    <div
                        *ngIf="
                            form.controls['cardDescription'].hasError('required') &&
                            form.controls['cardDescription'].touched
                        "
                        class="fx-form-validation"
                    >
                        Note or message is required.
                        <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                    </div>
                </div>
                <div class="request-common-group">
                    <div>
                        <div class="request-common-label request-common-group-header">
                            Request attachments
                        </div>
                        <file-input [enableDirectoryDrop]="true" [analyticsPrefix]="ANALYTICS_PREFIX" (file)="attachFile($event)"></file-input>
                    </div>
                </div>

                <div class="request-common-group">
                    <div class="create-request-attachments">
                        <div
                            class="create-request-attachments-attachment"
                            *ngFor="let attachment of attachments; let i = index"
                        >
                            {{ attachment.name }}
                            <button
                                (click)="removeAttachment(i)"
                                analyticsClick="{{ ANALYTICS_PREFIX }}_remove-file"
                                class="create-request-attachments-remove-file"
                            >
                                <i class="las la-times icon-size"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="request-common-secondary-header">
                    <h1>
                        To-do list
                    </h1>
                </div>

                <div class="request-common-secondary-sub-header">
                    Create a list of action items for this request. Press Enter to create new items.
                </div>

                <div class="request-common-todo-list" formArrayName="requestItems">
                    <fx-todolist>
                        <ng-container
                            *ngFor="let item of $any(form.get('requestItems')).controls; let i = index"
                            [formGroupName]="i"
                        >
                            <fx-todolist-item formControlName="completed">
                                <div class="request-common-todo">
                                    <div class="request-common-todo-details">
                                        <textarea
                                            #todoItemComponents
                                            formControlName="description"
                                            (keydown)="keyboardEvent($event)"
                                            (paste)="onPasteTodoItems($event)"
                                            class="request-common-todo-details-field"
                                            type="text"
                                            placeholder="Type something..."
                                            cdkTextareaAutosize
                                            cdkAutosizeMinRows="1"
                                            cdkAutosizeMaxRows="3"
                                        ></textarea>
                                        <button
                                            *ngIf="$any(form.get('requestItems')).controls.length > 1"
                                            (click)="removeControl(i)"
                                            class="request-common-todo-details-remove"
                                        >
                                            <i class="las la-times icon-size"></i>
                                        </button>
                                    </div>
                                </div>
                            </fx-todolist-item>

                            <div
                                *ngIf="item.get('description').hasError('required') && item.get('description').touched"
                                class="fx-form-validation request-common-todo-error"
                            >
                                Message is required.
                            </div>
                        </ng-container>
                    </fx-todolist>
                    <button
                        (click)="form.get('requestItems').valid ? addRequestItem() : null"
                        [ngClass]="{ 'request-common-todo-add-item--disabled': !form.get('requestItems').valid }"
                        class="request-common-todo-add-item"
                    >
                        + Add a new item
                    </button>
                </div>
            </form>
        </div>
    </div>
</centered-modal-layout>
