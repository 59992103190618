import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { OnboardingRoutingModule } from "./onboarding-routing.module";
import { SignUpComponent } from "./components/onboarding-initial/signup/signup.component";
import { OnboardingService } from "./services/onboarding.service";
import { OnboardingInitialComponent } from "./components/onboarding-initial/onboarding-initial.component";
import { CreatePasswordComponent } from "./components/onboarding-initial/create-password/create-password.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { VerifyEmailSuccessComponent } from "./components/verify-email-success/verify-email-success.component";
import { MatDialogModule } from "@angular/material/dialog";
import { FindexUiModule } from "projects/portal-modules/src/lib/findex-ui";
import { VerifyEmailPromptComponent } from "./components/onboarding-initial/verify-email-prompt/verify-email-prompt.component";
import { CheckStaffRoleDialogComponent } from "./components/check-staff-role-dialog/check-staff-role-dialog.component";
import { SharedModule } from "projects/portal-modules/src/lib/shared";
import { AnalyticsModule, HotjarAnalyticsService } from "projects/portal-modules/src/lib/analytics";
import { ThreadsUiModule } from "../threads-ui";
import { VerifyMobileWizardComponent } from "./components/verify-mobile-wizard/verify-mobile-wizard.component";

@NgModule({
    imports: [
        CommonModule,
        OnboardingRoutingModule,
        ReactiveFormsModule,
        FormsModule,
        RouterModule,
        MatDialogModule,
        FindexUiModule,
        SharedModule,
        AnalyticsModule,
        ThreadsUiModule
    ],
    declarations: [
        SignUpComponent,
        OnboardingInitialComponent,
        CreatePasswordComponent,
        VerifyEmailPromptComponent,
        VerifyEmailSuccessComponent,
        VerifyMobileWizardComponent,
        CheckStaffRoleDialogComponent
    ],
    exports: [SignUpComponent, VerifyEmailPromptComponent],
    providers: [OnboardingService, HotjarAnalyticsService]
})
export class OnboardingModule {
    constructor() {}
}
