import { Component, OnInit, Input } from "@angular/core";

@Component({
    selector: "findex-dropdown",
    templateUrl: "./findex-dropdown.component.html",
    styleUrls: ["./findex-dropdown.component.scss"]
})
export class FindexDropdownComponent implements OnInit {
    @Input() isRightAligned = true;

    toggleMenu: boolean;

    constructor() {}

    ngOnInit() {}
}
