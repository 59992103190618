import { Injectable } from "@angular/core";
import { IPluginFactory } from "projects/portal-modules/src/lib/plugins";
import { Libraries } from "projects/portal-modules/src/lib/plugins/services/Libraries";
import { DashboardCardComponent } from "./components/dashboard-card/dashboard-card.component";

@Injectable()
export class DashboardPlugin implements IPluginFactory {
    readonly id = "DashboardPlugin";

    constructor(libraries: Libraries) {
        libraries.cardViews.register("thread", DashboardCardComponent);
    }
}
