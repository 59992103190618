<div class="fx-onboarding-dialog">
    <div class="fx-onboarding-back">
        <button class="fx-onboarding-back-button" (click)="closeDialog(false)">
            <i class="fx-onboarding-back-icon las la-arrow-left"></i>
        </button>
    </div>
    <div class="fx-onboarding-dialog-content">
        <app-two-factor-authentication-form
            [userDetails]="userDetails"
            (codeEntered)="closeDialog(true)"
        ></app-two-factor-authentication-form>
    </div>
</div>
