<sidebar-modal-layout (close)="close()" header="Client Details" [subHeader]="thread.title" [footer]="false">
    <div modal-body>
        <loader-icon *ngIf="loader.counter" color="light"></loader-icon>

        <user-detail-card [userId]="userId"></user-detail-card>
        <client-details-form
            *ngIf="currentState === FormState.ClientDetails"
            [data]="clientDetails$ | async"
            (continue)="completeClientDetails($event)"
        >
        </client-details-form>

        <div class="uploading" *ngIf="currentState === FormState.FileSync">
            <span *ngIf="!uploadFilename">Processing....</span>
            <span *ngIf="uploadFilename">Uploading {{ uploadFilename }} {{ uploadProgress | number: "0.0-0" }}%</span>
        </div>
    </div>
</sidebar-modal-layout>
