import { Component, Inject, Input, OnInit } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { ParticipantCache } from "../../services/participant-cache.service";
import { trigger, transition, animate, style } from "@angular/animations";
import { of, timer } from "rxjs";
import { AuthService, AppUser } from "../../../findex-auth";
import { filter, map, switchMap } from "rxjs/operators";
import { IParticipant, IUpcomingMeeting } from "@findex/threads";
import { ENVIRONMENT } from "src/app/injection-token";
import { EnvironmentSpecificConfig } from "../../../environment/environment.common";
import { Router } from "@angular/router";
import { environmentCommon } from "src/environments/environment";
import { GA_EVENTS } from "../../../analytics";


@Component({
    selector: "upcoming-meeting-detail",
    templateUrl: "./upcoming-meeting-detail.component.html",
    styleUrls: ["./upcoming-meeting-detail.component.scss"],
    animations: [
        trigger("fadeIn", [
            transition(":enter", [style({ opacity: 0 }), animate("100ms", style({ opacity: 1 }))]),
            transition(":leave", [animate("100ms", style({ opacity: 0 }))])
        ])
    ]
})
export class UpcomingMeetingDetailComponent implements OnInit {
    readonly GA_EVENTS = GA_EVENTS;
    readonly theme = this.environment.theme;
    readonly dateFormat = environmentCommon.dateFormats;
    @Input() upcomingMeeting: IUpcomingMeeting;

    user$: Observable<AppUser>;
    createdBy$: Observable<IParticipant>;
    avatars$: Observable<any[]>;

    timeTillMeetingBegins: number;

    meetingIsImminent$: Observable<boolean>;

    constructor(
        private participantCacheService: ParticipantCache,
        private authService: AuthService,
        private router: Router,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig
    ) {
        this.avatars$ = of([]);
    }

    ngOnInit() {
        const { staff, invitees } = this.upcomingMeeting.attendees;
        const participants = [].concat(staff).concat(invitees);

        this.user$ = this.authService.getUser();
        this.createdBy$ = this.participantCacheService.getParticipant(this.upcomingMeeting.createdBy);
        this.avatars$ = this.user$.pipe(
            filter(user => !!user),
            switchMap(currentUser => this.participantCacheService.getMultipleAvatars(participants, currentUser)),
        );
        this.setMeetingIsImminent();
    }

    private setMeetingIsImminent(): void {
        // if meeting start time is +-15 minutes of now, show join button, check every minute
        this.meetingIsImminent$ = timer(0, 1000 * 60).pipe(
            map(() => {
                const now = Date.now();
                if (
                    Date.parse(this.upcomingMeeting.nextInstance.start) - now <= 15 * 60 * 1000 ||
                    (now >= Date.parse(this.upcomingMeeting.nextInstance.start) &&
                        now <= Date.parse(this.upcomingMeeting.nextInstance.end))
                ) {
                    this.timeTillMeetingBegins = Date.parse(this.upcomingMeeting.nextInstance.start) - now;
                    return true;
                } else return false;
            })
        );
    }

    async navigateToThread() {
        await this.router.navigate(["timelines", this.upcomingMeeting.threadId, "cards", this.upcomingMeeting.cardId]);
    }

    async joinMeeting() {
        await this.router.navigate(["timelines", this.upcomingMeeting.threadId, "cards", this.upcomingMeeting.cardId], {
            queryParams: { join: true }
        });
    }
}
