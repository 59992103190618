import { Pipe, PipeTransform } from "@angular/core";
import { IParticipant } from "@findex/threads";

@Pipe({ name: "threadParticipant" })
export class ThreadParticipantPipe implements PipeTransform {
    transform(participantId: string, participants: IParticipant[]) {
        if (!participantId || !participants) return null;

        return participants.find(participant => participant.id === participantId);
    }
}
