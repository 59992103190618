<div class="thread-state fx-l-modal">
    <div class="fx-l-modal-header">
        <div class="fx-l-modal-header-icon" (click)="close()">
            <i class="las la-times"></i>
        </div>
        <h2>Job status</h2>
    </div>
    <div class="fx-l-modal-content fx-l-modal-content--no-gutters">
        <ng-container *ngIf="groupedThreads">
            <fx-accordion
                *ngFor="let group of groupedThreads"
                [type]="'secondary'"
                [custom]="true"
                [disabled]="false"
                [isExpanded]="true"
                [toggleGutters]="true"
            >
                <!--                (accordionClicked)="group.active = !group.active"-->
                <ng-container accordion-header>
                    <div class="thread-state-title">
                        <span>{{ group.workflow.name }}</span>
                    </div>
                    <i class="fx-accordion-toggle-icon las la-angle-down"></i>
                </ng-container>
                <ng-container accordion-content class="upload-rfi-group-content">
                    <div class="thread-state-thread" *ngFor="let thread of group.threads">
                        <div class="thread-state-thread-header">
                            <span
                                >{{ thread.title }} -
                                {{ thread.participants | threadParticipants: roles.Client | async }}</span
                            >
                        </div>
                        <workflow-step-list
                            [workflowState]="thread.workflow"
                            [role]="role"
                            [title]="thread.title"
                            (save)="saveState(thread, $event)"
                            (editDates)="editDates(thread)"
                        ></workflow-step-list>
                    </div>
                </ng-container>
            </fx-accordion>
        </ng-container>
    </div>
</div>
