import { Component, Input } from "@angular/core";
import { InsightsMetricService } from "../../services/insights-metric.service";
import { TableReportRow } from "@findex/openmeasures-core";

@Component({
    selector: "insights-metric-widget",
    templateUrl: "./insights-metric-widget.component.html",
    styleUrls: ["./insights-metric-widget.component.scss"]
})
export class InsightsMetricWidgetComponent {
    @Input() public lastUpdated: string;
    @Input() public set tableReportRow(tableReportRow: TableReportRow) {
        this.setMetricsUI(tableReportRow);
        this.setValuesUI(tableReportRow);
        this._tableReportRow = tableReportRow;
    };
    public get tableReportRow(): TableReportRow {
        return this._tableReportRow;
    }

    public amount: number;
    public isMetricBelowTarget: boolean;
    public isMetricBetterThenTarget: boolean;
    public label: string;
    public target: number;
    public unit: string;
    public value: number;

    private _tableReportRow: TableReportRow;

    constructor(private insightsMetricService: InsightsMetricService) {
    }

    private setMetricsUI(tableReportRow: TableReportRow): void {
        this.isMetricBelowTarget = this.insightsMetricService.isMetricBelow(tableReportRow);
        this.isMetricBetterThenTarget = this.insightsMetricService.isMetricBetterThanTarget(tableReportRow);
    }

    private setValuesUI(tableReportRow: TableReportRow) {
        this.unit = tableReportRow?.metric.unit;
        this.amount = tableReportRow?.result?.value;
        this.target = tableReportRow?.target?.targetValue;
        this.label = tableReportRow.metric?.label;
        this.value = (this.amount - this.target) / this.target * 100;
    }
}
