<ng-container *ngIf="role">
    <div class="dashboard-greeting-banner">
        <div class="dashboard-greeting-banner-text">
            {{ greeting$ | async }} <span class="dashboard-greeting-banner-text-emoji">👋</span>
        </div>
        <div class="dashboard-greeting-banner-note" *ngIf="!(role | permissions: 'UpdateThread' | async)">
            <ng-container *ngIf="(tasks$ | async)?.length; let taskCount">
                <span>{{ 'You have ' }}</span>
                <span class="dashboard-greeting-banner-note-tasks">{{ taskCount }}</span>
                <span> {{ taskCount > 1 ? ' tasks needing responses' : 'task needing a response' }} </span>
            </ng-container>
        </div>
    </div>
</ng-container>
