<div #dragContainer
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
    [cdkDragDisabled]="isDisabled"
    class="vc-modal">
    <div [ngClass]="{ active: !!vcToken }" class="close" (click)="close()" title="Close">
        <i class="las la-times"></i>
    </div>
    <loader-icon *ngIf="loading" [color]="'light'" [static]="true"></loader-icon>
    <ng-container *ngIf="details$ | async; let details">
        <div *ngIf="!isPreCallComplete && !isPreCallStarted && !!vcToken !== true" class="meeting">
            <meeting-details
                *ngIf="!autoJoin"
                [status]="details.status"
                [joinDisabled]="!details.sessionId"
                (join)="togglePreCall()"
                [sessionTerminated]="details.isTerminated"
                (terminate)="terminateSession()"
                [role]="role"
            ></meeting-details>
        </div>

        <div *ngIf="!isPreCallComplete && isPreCallStarted && !!vcToken !== true">
            <pre-call-details
                [publishAudio]="publishAudio"
                [publishVideo]="publishVideo"
                [name]="(user$ | async)?.name"
                (joinCall)="joinVc(details.sessionId)"
                (toggleAudio)="publishAudio = !publishAudio"
                (toggleVideo)="publishVideo = !publishVideo"
                (addVisualEffect)="addBackground()"
                [visualEffect]="visualEffect"
                (setAudioDevice)="audioDeviceId = $event"
                (onStat)="log($event)"
            >
            </pre-call-details>
        </div>
        <vc-session
            #vcSession
            *ngIf="isPreCallComplete && !!vcToken === true"
            [sessionId]="details.sessionId"
            [token]="vcToken"
            [publishAudio]="publishAudio"
            [publishVideo]="publishVideo"
            (addVisualEffect)="addBackground()"
            (end)="close()"
            (onStat)="log($event)"
            (minimise)="toggleMinimise(vcSession)"
            (screenShare)="recordAnalytics()"
            [name]="(user$ | async).name"
            [audioId]="audioDeviceId"
            (onError)="handleError($event, vcSession)"
            [visualEffect]="visualEffect"
        ></vc-session>
        <div class="vc-modal-error" *ngIf="errorMessage">{{ errorMessage }}</div>
    </ng-container>
</div>
