<div class="payment-details">
    <div class="payment-details-package">
        <div class="payment-details-package-name">
            <div class="payment-details-package-name-icon">
                <i class="la la-certificate"></i>
            </div>
            <div class="payment-details-package-name-text">
                <div class="payment-details-package-name-text-value">{{ cardState?.packageName }}</div>
                <a
                    class="payment-details-package-name-text-link"
                    [routerLink]="['/accounts', accountId, 'billing']"
                    *ngIf="role === roles.Client"
                    [analyticsClick]="gaEvents.PAYMENTCARD_VIEW"
                >
                    VIEW
                </a>
            </div>
        </div>
        <div class="payment-details-package-price">
            <div>
                {{ 0 | currency: (cardState?.currency | uppercase) | slice: 0:-4 }}
            </div>
            <div class="payment-details-package-price-dollar">{{ packagePriceDetails?.priceDollars }}</div>
            <div class="payment-details-package-price-cent">
                <div class="payment-details-package-price-cent-value">{{ packagePriceDetails?.priceCents }}</div>
                <div *ngIf="cardState?.isRecurring" class="payment-details-package-price-cent-text">/MONTH</div>
            </div>
        </div>
        <div class="payment-details-package-period">
            <ng-container *ngIf="cardState?.isRecurring" class="payment-details-package-period-icon">
                <i class="las la-sync-alt payment-details-package-period-icon"></i>
                <div>This is a recurring direct debit.</div>
            </ng-container>
            <div *ngIf="!cardState?.isRecurring">This is a once-off payment.</div>
        </div>
    </div>
    <ng-container *ngIf="!cardState?.isPaid && cardState?.paymentType === paymentTypes.ScheduledPayment">
        <div
            class="payment-details-billing-date"
            *ngIf="card.status !== cardStatuses.Disabled; else subscriptionCancelled"
        >
            <p>The billing and subscription will begin on {{ cardState.billingDate | date: "MMMM d, y" }}.</p>
        </div>
    </ng-container>
    <ng-container *ngIf="card.status === cardStatuses.Disabled && !cardState?.isRecurring">
        <ng-container *ngTemplateOutlet="paymentCancelled"></ng-container>
    </ng-container>

    <div class="payment-details-buttons" *ngIf="card.status !== cardStatuses.Disabled">
        <ng-container *ngIf="!(role | permissions: 'PayPaymentCard' | async)">
            <ng-container *ngIf="cardState?.paymentType !== paymentTypes.ScheduledPayment">
                <button
                    class="fx-btn fx-btn--primary-outlined payment-details-buttons-pay-now disable-hover-text"
                    *ngIf="!cardState?.isPaid"
                    [disabled]="true"
                >
                    Pending Payment
                </button>
                <button
                    class="fx-btn fx-btn--primary-outlined payment-details-buttons-paid disable-hover-text"
                    *ngIf="cardState?.isPaid"
                    [disabled]="true"
                >
                    Payment Successful <i class="las la-check"></i>
                </button>
            </ng-container>
            <ng-container *ngIf="cardState?.paymentType === paymentTypes.ScheduledPayment">
                <button
                    class="fx-btn fx-btn--primary-outlined payment-details-buttons-pay-now disable-hover-text"
                    *ngIf="!cardState?.isPaid && !cardState?.paymentMethodId"
                    [disabled]="true"
                >
                    Pending Payment Method
                </button>
                <button
                    class="fx-btn fx-btn--primary-outlined payment-details-buttons-pay-now disable-hover-text"
                    *ngIf="!cardState?.isPaid && cardState?.paymentMethodId"
                    [disabled]="true"
                >
                    Pending Billing Date
                </button>
                <button
                    class="fx-btn fx-btn--primary-outlined payment-details-buttons-paid disable-hover-text"
                    *ngIf="cardState?.isPaid"
                    [disabled]="true"
                >
                    Payment Successful <i class="las la-check"></i>
                </button>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="role | permissions: 'PayPaymentCard' | async"
            ><ng-container *ngIf="cardState?.paymentType !== paymentTypes.ScheduledPayment">
                <button
                    *ngIf="!cardState?.isPaid"
                    class="fx-btn fx-btn--primary-outlined payment-details-buttons-pay-now"
                    role="button"
                    (click)="makePayment.emit(PAY_NOW_TASK_ACTION)"
                >
                    PAY NOW
                </button>
                <button
                    class="fx-btn fx-btn--primary-outlined payment-details-buttons-paid"
                    role="button"
                    *ngIf="cardState?.isPaid"
                    [routerLink]="['/billing']"
                    [analyticsClick]="gaEvents.PAYMENTCARD_PAID"
                >
                    PAID <i class="las la-check"></i>
                </button>
            </ng-container>
            <ng-container *ngIf="cardState?.paymentType === paymentTypes.ScheduledPayment">
                <button
                    *ngIf="!cardState?.isPaid && !cardState?.paymentMethodId"
                    class="fx-btn fx-btn--primary-outlined payment-details-buttons-pay-now"
                    role="button"
                    (click)="makePayment.emit(ADD_PAYMENT_METHOD_TASK_ACTION)"
                >
                    ADD PAYMENT METHOD
                </button>
                <button
                    class="fx-btn fx-btn--primary-outlined payment-details-buttons-paid"
                    role="button"
                    *ngIf="!cardState?.isPaid && cardState?.paymentMethodId"
                    [routerLink]="['/billing']"
                    [analyticsClick]="gaEvents.PAYMENTCARD_PAYMENTMETHODREADY"
                >
                    PAYMENT METHOD READY <i class="las la-check"></i>
                </button>
                <button
                    class="fx-btn fx-btn--primary-outlined payment-details-buttons-paid"
                    role="button"
                    *ngIf="cardState?.isPaid"
                    [routerLink]="['/billing']"
                    [analyticsClick]="gaEvents.PAYMENTCARD_PAID"
                >
                    PAID <i class="las la-check"></i></button
            ></ng-container>
        </ng-container>
    </div>
</div>

<ng-template #subscriptionCancelled>
    <div class="payment-details-billing-date">
        This subscription has been cancelled.
    </div>
</ng-template>

<ng-template #paymentCancelled>
    <div class="payment-details-billing-date">
        This payment has been cancelled.
    </div>
</ng-template>
