<loader-icon *ngIf="loading" [static]="true" color="light"></loader-icon>
<div class="close-thread-prompt">
    <div mat-dialog-content *ngIf="!loading">
        <ng-container *ngIf="pendingTasks?.length">
            <h2>Tasks pending on timeline</h2>
            <p class="close-thread-prompt-task">
                Your client still has pending tasks. You will need to complete these tasks before you are able to
                {{ workflowSteps.cancelled === workflowStepName ? "cancel" : "close" }} the timeline.
            </p>

            <div class="close-thread-prompt-task-list">
                <div *ngFor="let task of pendingTasks" class="close-thread-prompt-task-list-item">
                    <strong>{{ task }}</strong>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="hasUpcomingMeetings">
            <div class="close-thread-prompt-thread-list">
                <p>
                    {{
                        threads?.length
                            ? "Looks like you have upcoming meetings booked in this timeline. If you would like to copy these meetings to a different timeline, please select from the list below."
                            : "Looks like you have upcoming meetings booked in this timeline. If you would like to copy them, please create a new timeline with the current participants and revisit this screen."
                    }}
                </p>
                <div class="fx-form-group" *ngIf="threads?.length">
                    <label class="fx-form-label">Active timelines</label>
                    <div class="fx-form-control">
                        <select class="fx-form-select" [(ngModel)]="selectedThread">
                            <option value="">No timeline selected</option>
                            <option *ngFor="let thread of threads" [ngValue]="thread"
                                >{{ thread.title }} - (<span [innerHTML]="thread.id"></span>)</option
                            >
                        </select>
                    </div>
                </div>

                <p>
                    <strong>
                        {{
                            threads?.length
                                ? "If you do not wish to copy all outstanding meetings, these meetings will be cancelled. To cancel all meetings, don't select an active timeline and click 'OK'."
                                : "If you do not wish to copy all upcoming meetings, please click 'OK' to proceed. All upcoming meetings will be cancelled."
                        }}
                    </strong>
                </p>
            </div>
            <div class="close-thread-prompt-error" *ngIf="error">
                {{ error }}
            </div>
        </ng-container>

        <p class="close-thread-prompt-notasks" *ngIf="!hasUpcomingMeetings && !pendingTasks?.length">
            Are you sure you want to {{ workflowSteps.cancelled === workflowStepName ? "cancel" : "close" }} this
            timeline?
        </p>

        <div class="close-thread-prompt-buttons">
            <button
                class="close-thread-prompt-buttons-decline fx-btn fx-btn--secondary-outlined"
                type="button"
                (click)="close(false)"
            >
                {{ !pendingTasks?.length ? "Go back" : "Okay, got it" }}
            </button>
            <button
                *ngIf="!pendingTasks?.length"
                class="close-thread-prompt-buttons-decline fx-btn fx-btn--primary-outlined"
                type="button"
                (click)="selectedThread ? copyMeetingsCards(selectedThread.id) : close(true)"
            >
                Ok
            </button>
        </div>
    </div>
</div>
