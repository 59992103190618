<div *ngIf="!groups.length">
    <loader-icon color="light" [static]="true"></loader-icon>
</div>

<div class="vault-attachments-empty" *ngIf="!downloadCount">
    Attachments on this message have been removed.
</div>

<ng-container *ngIf="downloadCount">
    <fx-accordion *ngIf="!editable" [isExpanded]="expand" (accordionClicked)="expand = !expand" [custom]="true">
        <ng-container accordion-header *ngIf="downloadCount; let count">
            <div class="files-header-title"
                [analyticsClick]="['app_expandfiles', hotJarEvents.FileDownloadTimelineEvent]">
                <i class="files-header-paperclip las la-paperclip"></i>&nbsp; {{ count }} attachment{{
                count > 1 ? "s" : ""
                }}

                <signature-icon *ngIf="unsignedCount" theme="fill" [cursor]="false">{{
                    unsignedCount | number: "0.0-0"
                    }}</signature-icon>
            </div>

            <i class="fx-accordion-toggle-icon las la-angle-down" analyticsClick="app_expandfiles"></i>
        </ng-container>

        <div accordion-content class="files" *ngIf="groups; let groups">
            <ng-container *ngFor="let group of groups; trackBy: trackGroup">
                <ng-container *ngIf="group.items.length">
                    <div class="file" *ngFor="let item of group.items; trackItem">
                        <vault-card-item
                            [icon]="group.displayName === DocumentCategory.Report ? 'la-chart-bar' : 'la-file'"
                            [item]="item" [canRename]="false" [canDelete]="false" [disabled]="disabled"
                            [canSign]="canSign" [isReport]="group.displayName === DocumentCategory.Report"
                            (download)="download.emit(item)" (preview)="preview.emit(item)" (sign)="sign.emit(item)">
                        </vault-card-item>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </fx-accordion>

    <div class="editable" *ngIf="editable">
        <ng-container *ngFor="let group of groups; trackBy: trackGroup">
            <div class="file" *ngFor="let item of group.items; trackItem">
                <vault-card-item [icon]="group.displayName === DocumentCategory.Report ? 'la-chart-bar' : 'la-file'"
                    [item]="item" [canRename]="editable && !item.signed" [canDelete]="editable && !item.signed"
                    [disabled]="disabled" [canSign]="canSign" [isReport]="group.displayName === DocumentCategory.Report"
                    (download)="download.emit(item)" (preview)="preview.emit(item)" (sign)="sign.emit(item)"
                    (delete)="delete.emit(item)" (rename)="renameItem(item, $event)">
                </vault-card-item>
            </div>
        </ng-container>
    </div>
</ng-container>