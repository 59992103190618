import { CardReply, CardStatus, IThreadCard, IVaultListItem, IVaultRequestCardState } from "@findex/threads";
import { Inject, Injectable } from "@angular/core";
import { VAULT_ACTION } from "@findex/vault";
import { IVaultItem } from "projects/default-plugins/vault/interfaces/IVaultItem";
import { RFI_TYPES } from "projects/default-plugins/vault/components/upload/upload-item/upload-item.component";
import { IVaultItemFile } from "projects/default-plugins/vault/interfaces/IVaultItemFile";
import { IVaultState } from "projects/default-plugins/vault/interfaces/IVaultState";
import { ThreadCardService } from "./thread-card.service";
import { ThreadsWebsocketService } from "../../shared/services/threads-websocket.service";
import { filter, map, switchMap } from "rxjs/operators";
import { combineLatest, concat, Observable, of } from "rxjs";
import { Loader } from "../../shared/services/loader";
import { VaultRequestService } from "../../../../../default-plugins/vault/services/vault-request.service";
import { CardStateResponse } from "./threads.service";
import { ENVIRONMENT } from "src/app/injection-token";
import { EnvironmentSpecificConfig } from "../../environment/environment.common";
import { HttpClient } from "@angular/common/http";
import { ParticipantCache } from "./participant-cache.service";

export type VaultDocument = {
    card: IThreadCard;
    actorId: string;
    timestamp: string;
    title: string;
    category: string;
    vaultId: string;
    fileId: string;
    filename: string;
    isRfi?: boolean;
    signable?: boolean;
    signed?: boolean;
    signer?: string;
    signedOn?: string;
};
export type VaultRequestRow = {
    title: string;
    createdBy: string;
    progress: number;
    status: string;
    cardReplies: CardReply[];
    card: IThreadCard;
    state: IVaultRequestCardState;
};

@Injectable({ providedIn: "root" })
export class ThreadsVaultService {
    constructor(
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private cardService: ThreadCardService,
        private websocketService: ThreadsWebsocketService,
        private http: HttpClient,
        private participantCache: ParticipantCache
    ) {}

    getDocumentList(threadId: string, loader: Loader): Observable<VaultDocument[]> {
        const cards$ = this.cardService.getCards(threadId);

        return loader.wrap(cards$).pipe(switchMap(cards => this.getVaultDocuments(threadId, cards, loader)));
    }

    getRequestList(threadId: string, loader: Loader): Observable<VaultRequestRow[]> {
        const cards$ = this.cardService.getCards(threadId);
        return loader.wrap(cards$).pipe(switchMap(cards => this.getRequestRows(threadId, cards, loader)));
    }

    private isValidRequestCard(type: string, status: CardStatus): boolean {
        return type === "vault-request" && status !== CardStatus.Removed && status !== CardStatus.Deleted;
    }

    private getRequestRows(threadId: string, cards: IThreadCard[], loader: Loader): Observable<VaultRequestRow[]> {
        const requestCards = cards.filter(card => this.isValidRequestCard(card.type, card.status));
        if (requestCards?.length === 0) {
            return of([]);
        }
        const rows$ = requestCards.map(card => this.getRequestRow(threadId, card, loader));

        return combineLatest(rows$).pipe(map(cardRequest => [].concat.apply([], cardRequest)));
    }

    private getRequestRow(threadId: string, card: IThreadCard, loader: Loader): Observable<Partial<VaultRequestRow>> {
        return this.getStateUpdates(threadId, card, loader).pipe(
            map(state => this.mapRequestRow(card, state.cardReplies, state.state))
        );
    }

    private getVaultDocuments(threadId: string, cards: IThreadCard[], loader: Loader): Observable<VaultDocument[]> {
        const documentCards = cards.filter(
            card => (card.type === "vault" || card.type === "vault-request") && card.status !== CardStatus.Removed
        );
        if (documentCards?.length === 0) {
            return of([]);
        }

        const documents$ = documentCards.map(card => this.getCardDocuments(threadId, card, loader));

        return combineLatest(documents$).pipe(map(cardDocuments => [].concat.apply([], cardDocuments)));
    }

    private getStateUpdates<cardState = any>(
        threadId: string,
        card: IThreadCard,
        loader: Loader
    ): Observable<CardStateResponse<cardState>> {
        const state$ = loader.wrap(this.cardService.getCardState<cardState>(threadId, card.id));

        const stateUpdates$ = this.websocketService.watchCardId(threadId, card.id).pipe(
            filter(notification => notification.state),
            switchMap(() => loader.wrap(this.cardService.getCardState<cardState>(threadId, card.id)))
        );

        return concat(state$, stateUpdates$);
    }

    private getCardDocuments(threadId: string, card: IThreadCard, loader: Loader): Observable<VaultDocument[]> {
        return this.getStateUpdates(threadId, card, loader).pipe(
            map(state => this.stateToDocuments(card, state && state.state))
        );
    }

    private mapVaultDocuments(card: IThreadCard, state: IVaultState): VaultDocument[] {
        if (!state || !state.groups) return [];

        const documents: VaultDocument[] = [];

        for (const group of state.groups) {
            for (const item of group.items) {
                const itemDocs = this.groupItemToDocuments(group.displayName, card, item);
                documents.push.apply(documents, itemDocs);
            }
        }

        return documents;
    }

    private mapRequestRow(card: IThreadCard, cardReplies: CardReply[], state: IVaultRequestCardState): VaultRequestRow {
        const { actionedPercentage } = VaultRequestService.calculateProgress(state.requestItems);
        return {
            title: state.title,
            createdBy: card.createdBy,
            progress: actionedPercentage,
            status: actionedPercentage === 100 ? "Complete" : "Pending",
            state,
            card,
            cardReplies
        };
    }

    private mapRequestFileData(
        card: IThreadCard,
        actorId: string,
        timestamp: string,
        vaultId: string,
        fileId: string,
        filename: string
    ): VaultDocument {
        return {
            card,
            actorId,
            timestamp,
            title: filename,
            category: "Request",
            vaultId,
            fileId,
            filename,
            isRfi: true,
            signable: false,
            signed: false,
            signer: null,
            signedOn: null
        };
    }

    private buildAttachmentDocuments(card: IThreadCard, state: IVaultRequestCardState): VaultDocument[] {
        return state?.attachments?.data
            ?.map(requestFileData =>
                this.mapRequestFileData(
                    card,
                    requestFileData.actorId,
                    requestFileData.timestamp,
                    state.vaultId,
                    state.attachments.fileId,
                    requestFileData.filename
                )
            )
            .filter(document => !!document);
    }

    private buildRequestItemDocuments(card: IThreadCard, state: IVaultRequestCardState): VaultDocument[] {
        return state?.requestItems
            .map(requestItem =>
                requestItem?.response?.data?.state?.map(requestFileData =>
                    this.mapRequestFileData(
                        card,
                        requestFileData.actorId,
                        requestFileData.timestamp,
                        state.vaultId,
                        requestItem.fileId,
                        requestFileData.filename
                    )
                )
            )
            .reduce((accumulator, value) => accumulator.concat(value), [])
            .filter(document => !!document);
    }

    private mapRequestDocuments(card: IThreadCard, state: IVaultRequestCardState): VaultDocument[] {
        const attachmentDocuments = this.buildAttachmentDocuments(card, state);
        const requestItemDocuments = this.buildRequestItemDocuments(card, state);
        return [...attachmentDocuments, ...requestItemDocuments];
    }

    private stateToDocuments(card: IThreadCard, state: any): VaultDocument[] {
        if (card.type === "vault") {
            return this.mapVaultDocuments(card, state);
        } else if (card.type === "vault-request") {
            return this.mapRequestDocuments(card, state);
        }
        console.warn("Unknown vault card type " + card.type);
        return [];
    }

    private groupItemToDocuments(category: string, card: IThreadCard, item: IVaultItem): VaultDocument[] {
        if (!item || !item.files) return [];
        return item.files.map(file => {
            const { vaultId, fileId, signed, signedOn, signer, actions } = item;
            const { filename, actorId, timestamp } = file;

            const signable = actions.includes(VAULT_ACTION.Sign);
            const isRfi = actions.includes(VAULT_ACTION.Rfi);
            const title = this.getDocumentTitle(item, file);

            return {
                card,
                actorId,
                timestamp,
                title,
                vaultId,
                fileId,
                filename,
                category,
                isRfi,
                signed,
                signable,
                signedOn,
                signer
            };
        });
    }

    private getDocumentTitle(item: IVaultItem, file: IVaultItemFile): string {
        const { displayName, informationType, files, actions } = item;
        const { filename } = file;

        const isRfi = actions.includes(VAULT_ACTION.Rfi);

        if (informationType === RFI_TYPES.TextInput || (files.length === 1 && !isRfi)) {
            return displayName;
        } else {
            return filename;
        }
    }

    async enrichVaultListWithActorProfile(vaultList: IVaultListItem[]) {
        return await Promise.all(
            vaultList.map(async vaultItem => {
                const actorProfile = await this.participantCache.getParticipant(vaultItem.actorId).toPromise();
                return {
                    actorName: actorProfile.profile.name || actorProfile.name || "Deleted",
                    ...vaultItem
                };
            })
        );
    }

    listVaults(userId: string = "me"): Observable<IVaultListItem[]> {
        const { base } = this.environment.commonEndpoints;
        const url = `${base}/vault/user/${userId}`;
        return this.http
            .get<IVaultListItem[]>(url)
            .pipe(switchMap(vaultList => this.enrichVaultListWithActorProfile(vaultList)));
    }
}
