import { ActivatedRoute } from "@angular/router";
import { combineLatest, Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { ILibrary } from "../interfaces/ILibrary";
import { RouteExtension } from "./Libraries";

export type VisibleExtension = { id: string; label: string; icon: string; path: string };

export class RouteHelper {
    static loadAll(activatedRoute: ActivatedRoute, library: ILibrary<RouteExtension>, routeEntity?: unknown) {
        const loadedExtensions = library
            .listAll()
            .map(({ id, extension }) => RouteHelper.loadExtension(activatedRoute, id, extension, routeEntity));

        return combineLatest(loadedExtensions).pipe(
            map(extensions => extensions.filter(extension => extension != null)),
            shareReplay()
        );
    }

    static loadExtension(
        activatedRoute: ActivatedRoute,
        id: string,
        extension: RouteExtension,
        routeEntity?: unknown
    ): Observable<VisibleExtension | null> {
        const routeChildren = activatedRoute?.routeConfig?.children;
        const { label, icon, route, showIcon } = extension;

        const visibleRoute = {
            id,
            label,
            path: route.path,
            icon
        };

        if (!routeChildren?.includes(route)) {
            routeChildren?.unshift(route);
        }

        return showIcon(routeEntity).pipe(
            map(visible => (visible ? visibleRoute : null)),
            shareReplay()
        );
    }
}
