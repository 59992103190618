import { NgModule } from "@angular/core";
import { GetAccountDetailsPipe } from "./get-account.pipe";
import { IsThreadActivePipe } from "./is-thread-active.pipe";
import { ListOtherParticipantsPipe } from "./list-other-participants.pipe";
import { ProfilePicturePipe } from "./profile-picture.pipe";
import { ReportSearchPipe } from "./report-search.pipe";
import { ReportUserSearchPipe } from "./report-user-search.pipe";
import { ReportRequestSearchPipe } from "./report-request-search.pipe";
import { GetRequestCompletedCountPipe } from "./get-request-completed-count.pipe";
import { GetRequestCompletedDatePipe } from "./get-request-completed-date.pipe";
import { ThreadParticipantsPipe } from "./thread-participants.pipe";
import { ThreadParticipantPipe } from "./thread-participant.pipe";
import { ThreadsSearchPipe } from "./threads-search.pipe";
import { ThreadsStatusSearchPipe } from "./threads-status-search.pipe";
import { TimeAgoPipe } from "./time-ago.pipe";
import { TimeOverDuePipe } from "./time-over-due.pipe";
import { UserToNamePipe } from "./user-to-name";
import { ThreadTypeNamePipe } from "./thread-type-name.pipe";
import { GetCurrentStepPipe } from "./get-current-step.pipe";
import { SearchByPipe } from "./search-by.pipe";

const exportDeclarations = [
    GetAccountDetailsPipe,
    IsThreadActivePipe,
    ListOtherParticipantsPipe,
    ProfilePicturePipe,
    ReportSearchPipe,
    ReportUserSearchPipe,
    ReportRequestSearchPipe,
    GetRequestCompletedCountPipe,
    GetRequestCompletedDatePipe,
    ThreadParticipantsPipe,
    ThreadParticipantPipe,
    ThreadsSearchPipe,
    ThreadsStatusSearchPipe,
    TimeAgoPipe,
    TimeOverDuePipe,
    UserToNamePipe,
    ThreadTypeNamePipe,
    GetCurrentStepPipe,
    SearchByPipe
];

@NgModule({
    imports: [],
    declarations: [...exportDeclarations],
    exports: [...exportDeclarations],
    providers: []
})
export class ThreadsUiPipesModule {}
