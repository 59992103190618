import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { OpenMeasuresService } from "../../services/open-measures.service";
import { ScatterOrder } from "../scatter-report/scatter-report.component";
import { Loader } from "../../../../portal-modules/src/lib/shared/services/loader";

@Component({
    selector: "app-insights-metric-detail-route",
    templateUrl: "./insights-metric-detail-route.component.html",
    styleUrls: ["./insights-metric-detail-route.component.scss"]
})
export class InsightsMetricDetailRouteComponent implements OnInit {
    loader = new Loader();
    period: any;
    report: any;
    metric: any;

    enterpriseId: string;
    entityId: string;
    periodId: string;
    metricId: string;
    metricName: string;
    groupByIndex: number;
    sortOrder = ScatterOrder.Name;

    constructor(private location: Location, private route: ActivatedRoute, private router: Router, private openMeasures: OpenMeasuresService) {}

    async ngOnInit() {
        this.loader.show();
        const enterpriseId = this.route.snapshot.params.enterpriseId;
        const entityId = this.route.snapshot.params.entityId;
        const metricId = this.route.snapshot.params.metricId;

    
        const periods = await this.openMeasures.getPeriods(enterpriseId).toPromise();
        const period = periods?.pop();

        if (!period) return;

        this.period = period;
        this.report =  await this.openMeasures.getMetrics(enterpriseId, period.id, entityId).toPromise();


        console.log('GOT RESULTS', enterpriseId, entityId, period, this.report)

        const group = this.report?.groups.find(group => group?.rows.find(row => row.metric.id == metricId))
        const metric = group?.rows.find(row => row.metric.id == metricId);
        console.log('metric', metric)
        this.router;
        this.metric = metric;

        
        this.enterpriseId = enterpriseId;
        this.entityId = entityId;
        this.periodId = period.id;
        this.metricId = metricId;

        this.loader.hide();
    }

    goBack() {
        this.location.back()
    }
}
