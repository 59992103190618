<div
    *ngIf="activeWorkflowSteps?.workflow?.steps?.length > 1 && shouldShowTitle"
    class="title"
    [ngClass]="{ 'title--full-width': fullWidth }"
>
    {{ currentStep?.title }}
</div>

<div class="progress" *ngIf="activeWorkflowSteps?.workflow?.steps?.length > 1">
    <div
        class="progress-bar"
        cdkOverlayOrigin
        #trigger
        (click)="togglePopUp(trigger)"
        [ngClass]="{
            'progress-bar--allow-click': enablePopUp && workflowIsActive,
            'progress-bar--small': small,
            'progress-bar-overdue': currentStep?.isOverdue && (role | permissions: 'UpdateInternalWorkflow' | async),
            'progress-bar--full-width': fullWidth
        }"
        [analyticsClick]="gaEvents.APP_WORKFLOWPROGRESSBAR"
    >
        <div
            class="progress-bar-track"
            [style.width]="
                workflowIsActive ? (currentStep?.index / activeWorkflowSteps.workflow.steps.length) * 100 + '%' : '100%'
            "
            [ngClass]="{
                'progress-bar-track-overdue':
                    currentStep?.isOverdue && (role | permissions: 'UpdateInternalWorkflow' | async)
            }"
        ></div>
        <ng-template #workflowPopUp>
            <thread-workflow-popup
                [workflowState]="workflowState"
                [role]="role"
                [thread]="thread"
                (close)="close()"
            ></thread-workflow-popup>
        </ng-template>
    </div>
    <i
        *ngIf="enablePopUp && workflowIsActive"
        class="progress-icon las la-info-circle"
        (click)="togglePopUp(trigger)"
    ></i>
</div>
