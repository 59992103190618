import { Component, Inject, OnInit } from "@angular/core";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ISlot } from "@findex/fx-ui/lib/components/calendar/calendar";
import { IStaffProfile } from "@findex/threads";
import { DateTime } from "luxon";
import { SamedayService } from "src/solutions/samedaytax/services/sameday.service";
import { HandledError } from "projects/portal-modules/src/lib/shared/services/threads-error-handler";
import { EnvironmentSpecificConfig } from "../../../../../projects/portal-modules/src/lib/environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";

@Component({
    selector: "find-availability",
    templateUrl: "./find-availability-modal.component.html",
    styleUrls: ["./find-availability-modal.component.scss"]
})
export class FindAvailabilityModalComponent implements OnInit {
    readonly theme = this.environment.theme;

    selectedDate: ISlot;
    start: string;
    end: string;
    minDate: string;
    maxDate: string;
    slots: ISlot[];
    calendarError: string;
    loader = new Loader();
    rescheduleAppointment: boolean;
    selectedStaff: IStaffProfile;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: {
            staffProfiles: IStaffProfile[];
            meetingDescription?: string;
            packageId: string;
        },
        public dialogRef: MatDialogRef<FindAvailabilityModalComponent>,
        private samedayService: SamedayService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig
    ) {
        this.selectedDate = null;
    }

    async ngOnInit() {
        this.minDate = new Date().toISOString();
        this.maxDate = new Date(Date.now() + 60 * 24 * 60 * 60 * 1000).toISOString();
        this.monthChange(this.minDate);
    }

    handleCalendarEvent(event: any): void {
        if (!event) {
            this.selectedDate = null;
            return;
        }

        this.start = event.start || null;
        this.end = event.end || null;

        if (event.start && event.end) {
            this.selectedDate = { start: this.start, end: this.end };
            this.findStaff(this.selectedDate);
        } else {
            this.selectedDate = null;
        }
    }

    async findStaff(selectedDate: ISlot) {
        this.loader.show();

        const { start, end } = selectedDate;
        const packageId = this.data.packageId;
        const availableStaff = await this.samedayService.findAvailableStaff({ packageId }, start, end).toPromise();
        this.selectedStaff = availableStaff.shift();

        this.loader.hide();
    }

    onClose(confirmed: boolean): void {
        if (confirmed) {
            this.dialogRef.close({ slot: this.selectedDate, staff: this.selectedStaff });
        } else {
            this.dialogRef.close(null);
        }
    }

    async monthChange(today: string) {
        this.loader.show();

        const startParsed = DateTime.fromISO(today).startOf("month");
        const endParsed = startParsed.endOf("month");

        try {
            const packageId = this.data.packageId;
            const start = startParsed.toISO();
            const end = endParsed.toISO();

            const response: any = await this.samedayService.findAvailability({ packageId }, start, end).toPromise();
            this.slots = response?.slots || [];
        } catch (error) {
            this.calendarError = `Failed to load availabilities from calendar service. Response: ${error.status} ${error.statusText}`;
            throw new HandledError(error);
        } finally {
            this.loader.hide();
        }
    }
}
