import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NotificationsService, Notification } from "@findex/notifications-angular";

@Component({
    selector: "list-notification-banners",
    templateUrl: "./list-notification-banners.component.html",
    styleUrls: ["./list-notification-banners.component.scss"]
})
export class ListNotificationBannersComponent {
    @Input() notifications: Notification<any>[];
    @Output() bannerDisplayed = new EventEmitter<Notification<any>>();
    @Output() bannerHidden = new EventEmitter<Notification<any>>();

    constructor(private notificationsService: NotificationsService) {

    }

    close(notification: Notification) {
        this.notificationsService.markAsRead(notification.channel);
        this.bannerHidden.emit(notification);
    }

    trackNotification(_index: number, notification: Notification) {
        return notification?.id;
    }   
}
