/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
import { CountryCode, parsePhoneNumberFromString } from "libphonenumber-js";
import { DateTime } from "luxon";

function isValidMobileNumber(countryCode: CountryCode, phoneNumber: string): boolean {
    const parsedPhone = parsePhoneNumberFromString(phoneNumber, countryCode);

    if (!parsedPhone || !parsedPhone.isValid()) {
        return false;
    }

    const phoneType = parsedPhone.getType();
    if (!phoneType) {
        return true; //phone is valid, just an unknown type
    }

    return phoneType === "MOBILE" || phoneType === "FIXED_LINE_OR_MOBILE";
}

function countryMobileValidator(countryControl: AbstractControl) {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.value || control.hasError("required")) {
            return null;
        }

        const country = countryControl.value || "AU";
        const isValid = isValidMobileNumber(country, control.value);

        if (isValid) {
            return null;
        } else {
            const invalid = { value: control.value };
            return { invalid };
        }
    };
}

function mobileValidator(supportedCountries: CountryCode[]) {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.value || control.hasError("required")) {
            return null;
        }

        const isValid = supportedCountries.some(country => isValidMobileNumber(country, control.value));

        if (isValid) {
            return null;
        } else {
            const invalid = { value: control.value };
            return { invalid };
        }
    };
}

const passwordValidator = (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value) {
        return null;
    }
    if (control.value.length < 8) {
        return { length: { value: control.value } };
    }
    if (!/[A-Z]/.test(control.value)) {
        return { needsUppercase: { value: control.value } };
    }
    if (!/[a-z]/.test(control.value)) {
        return { needsLowercase: { value: control.value } };
    }
    if (!/[0-9]/.test(control.value)) {
        return { needsNumber: { value: control.value } };
    }
    return null;
};

const passwordsMatchValidator = (field1Name: string, field2Name: string): ValidatorFn => {
    const errorResult = { matchingFields: false };
    return (group: FormGroup): ValidationErrors | null => {
        const field1 = group.get(field1Name);
        const field2 = group.get(field2Name);
        if (!field1 || !field2) {
            return errorResult;
        }
        //Don't show an error if either field is not valid.
        if (!field1.valid || !field2.valid) {
            return null;
        }
        return field1.value !== field2.value ? errorResult : null;
    };
};

const dateValidator = (dateFormat = "dd/MM/yyyy") => (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    const errorResult = {
        date: { value }
    };

    try {
        //Throws if date is invalid
        //TODO check that it throws if date is invalid
        if (value) {
            const result = DateTime.fromFormat(value, dateFormat);
            if (!result.isValid) {
                return errorResult;
            }
        }
    } catch (error) {
        return errorResult;
    }
    //No errors
    return null;
};

const taxFileNumberValidator = (control: AbstractControl): { [key: string]: any } | null => {
    const value: string = control.value || "";
    if (!value) return null;

    const errorResult = {
        taxFileNumber: {
            value
        }
    };

    //TFN must be 9 digits long
    if (value.length !== 9) {
        return errorResult;
    }
    //Algorithm from https://en.wikipedia.org/wiki/Tax_file_number
    const digits = Array.from(value);
    const weights = [1, 4, 3, 7, 5, 8, 6, 9, 10];

    const mapped = digits.map((digit, index) => Number(digit) * weights[index]);
    const total = mapped.reduce((sum, currentVal) => sum + currentVal, 0);

    //Sum of weighted digits must be evenly divisible by 11 (e.g. 253)
    if (total % 11 !== 0) {
        return errorResult;
    }
    return null;
};

const expiryDateValidator = (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value) {
        return null;
    }
    return !control.value.match(/^(0\d|1[0-2])\/\d{2}$/) ? { expiryDate: { value: control.value } } : null;
};

export const customValidators = {
    countryMobileValidator,
    mobileValidator,
    passwordValidator,
    passwordsMatchValidator,
    dateValidator,
    taxFileNumberValidator,
    expiryDateValidator
};

export const getPasswordErrorMessage = (controlName: string = "Password", passwordMatchError: boolean): string =>
    !passwordMatchError
        ? `${controlName} must have at least 8 characters and contain at least one of the following; uppercase letter, lowercase letter and number.`
        : `The passwords don't match!`;
