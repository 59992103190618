import { Tour } from "projects/portal-modules/src/lib/shared/services/tour.service";

export const introductionTour: Tour = {
    id: "introduction",
    tourRoute: "dashboard",
    steps: [
        {
            titleHtml: `Welcome to <img class="tour-logo tour-logo-align-center" src="" alt="Visory Logo" />`,
            contentHtml: `
                <p>Since this is your first time on the platform, we've prepared a quick guide to show you around.</p>`,
            nextButtonText: `Start Guide`
        },
        {
            titleHtml: `Your Dashboard`,
            contentHtml: `<p>Your dashboard is a summary of your timelines, tasks and scheduled upcoming meetings.</p>`,
            nextButtonText: `Got it!`,
            attachTo: {
                selector: "#home-menu-item .menu-item",
                side: "bottom"
            },
            menuStateBeforeShow: "open"
        },
        {
            titleHtml: `Timelines`,
            contentHtml: `<p>Timelines are where you'll communicate with your Visory team, access outstanding tasks, join meetings and more.</p>`,
            nextButtonText: `Got it!`,
            attachTo: {
                selector: "#timeline-menu-item .menu-item",
                side: "bottom"
            },
            menuStateBeforeShow: "open"
        },
        {
            titleHtml: `Accounts`,
            contentHtml: `<p>The Accounts tab will let you access records and details associated with your business.</p>`,
            nextButtonText: `Got it!`,
            attachTo: {
                selector: "#accounts-menu-item .menu-item",
                side: "bottom"
            },
            menuStateBeforeShow: "open"
        },
        {
            titleHtml: `Your Tasks`,
            contentHtml: `<p>Your task list will show outstanding action items from your timelines. When visible, click each item to see more detail.</p>`,
            nextButtonText: `Got it!`,
            attachTo: {
                selector: "#dashboard-threads",
                side: "bottom"
            },
            menuStateBeforeShow: "closed"
        },
        {
            titleHtml: `Upcoming Meetings`,
            contentHtml: `<p>All meetings are held on the platform. Please ensure that you join the meeting at the scheduled time. You will need a working microphone and speaker with video camera optional. </p>`,
            nextButtonText: `Got it!`,
            attachTo: {
                selector: "#dashboard-upcoming-meetings .card",
                side: "bottom"
            },
            menuStateBeforeShow: "closed"
        }
    ]
};
export const visoryNotifications: Tour = {
    id: "visory-notifications",
    tourRoute: "dashboard",
    steps: [
        {
            titleHtml: `Notifications`,
            contentHtml: `<p>Your notification feed will keep you up to date on account activity, so nothing is missed.</p>`,
            nextButtonText: `Got it!`,
            attachTo: {
                selector: ".recent-notifications-link",
                side: "bottom"
            }
        }
    ]
};
export const visoryInsights: Tour = {
    id: "visory-insights",
    tourRoute: "dashboard",
    steps: [
        {
            titleHtml: `Insights`,
            contentHtml: `<p>All uploaded Insight Reports delivered by your Visory team can be found here.</p>`,
            nextButtonText: `Got it!`,
            attachTo: {
                selector: ".insights-menu-item",
                side: "bottom"
            },
            menuStateBeforeShow: "open"
        }
    ]
};

export const filesTour: Tour = {
    id: "files",
    tourRoute: "dashboard",
    steps: [
        {
            titleHtml: `Files`,
            contentHtml: `<p>The files tab will allow you to access and search for all accessible documents.</p>`,
            nextButtonText: `Got it!`,
            attachTo: {
                selector: "#files-menu-item .menu-item",
                side: "bottom"
            },
            menuStateBeforeShow: "open"
        },
    ]
};
