import { Component, OnInit } from "@angular/core";
import { ThreadsService } from "projects/portal-modules/src/lib/threads-ui/services/threads.service";
import { filter, map, shareReplay, switchMap, take } from "rxjs/operators";
import { Observable } from "rxjs";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { IThread, Role } from "@findex/threads";
import { PortalService } from "projects/portal-modules/src/lib/shared/services/portal.service";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";

@Component({
    selector: "app-dashboard",
    templateUrl: "./dashboard.component.html",
    styleUrls: ["./dashboard.component.scss"]
})
export class DashboardComponent implements OnInit {
    thread$: Observable<IThread>;
    role$: Observable<Role>;
    userId$: Observable<string>;
    loader = new Loader();

    constructor(
        private authService: AuthService,
        private portalService: PortalService,
        private threadsService: ThreadsService
    ) {}

    ngOnInit() {
        this.userId$ = this.authService.getUser().pipe(
            filter(user => !!user),
            map(user => user.id),
            shareReplay(1)
        );

        const threadId$ = this.userId$.pipe(
            switchMap(() => this.loader.wrap(this.portalService.getMyDashboard())),
            filter(threadId => !!threadId),
            shareReplay(1)
        );

        const thread$ = threadId$.pipe(switchMap(threadId => this.threadsService.getThread(threadId)));

        const role$ = this.authService.getUser().pipe(
            filter(user => !!user),
            map(user => user.globalRole),
            take(1)
        );

        this.thread$ = thread$;
        this.role$ = this.loader.wrap(role$);
    }
}
