<div class="calendar-book-meeting">
    <multi-component-layout
        [stepConfigurations]="stepConfigurations"
        (emitComplete)="create()"
        (emitTransition)="handleTransition($event)"
        [loading]="loader.counter !== 0"
        [enableHeader]="true"
        [enableFooter]="true"
        contentClass="multi-component-layout--book-meeting"
    >
        <div class="calendar-book-meeting-header multi-component-layout-header">
            <div class="calendar-book-meeting-title">
                Schedule
            </div>
            <div class="calendar-book-meeting-header-close">
                <div class="fx-l-modal-header-icon" (click)="close()">
                    <i class="las la-times icon-size"></i>
                </div>
            </div>
        </div>
        <step-layout [stepTitle]="'Meeting details'">
            <calendar-create-invitation
                [participants]="participants"
                [showValidationErrors]="showValidationErrors"
                (emitDetails)="handleMeetingDetailsUpdated($event)"
            ></calendar-create-invitation>
        </step-layout>
        <step-layout [stepTitle]="'Calendar'">
            <app-calendar-choose-time
                [maxDate]="maxDate"
                [minDate]="minDate"
                [slots]="slots"
                [duration]="duration"
                (emitMonthSelection)="monthChange($event)"
                (emitSelection)="handleTimeSelection($event)"
            ></app-calendar-choose-time>
        </step-layout>
        <step-layout [stepTitle]="'Review and book'">
            <app-calendar-review-meeting [data]="meetingReviewData"></app-calendar-review-meeting>
        </step-layout>
        <loader-icon *ngIf="loader.counter" [color]="'light'"></loader-icon>
    </multi-component-layout>
</div>
