import { Component, Input, OnChanges, SimpleChanges, Output, EventEmitter } from "@angular/core";
import { FormControl } from "@angular/forms";
import { IRequestTextData } from "@findex/threads";

@Component({
    selector: "request-response",
    styleUrls: ["request-response.scss"],
    templateUrl: "request-response.html"
})
export class RequestResponseComponent implements OnChanges {
    showCompose = true;
    editResponse = new FormControl("");
    quillError: boolean;

    @Input() modifiedBy: string;
    @Input() modifiedAt: string;
    @Input() message: string;
    @Input() userId: string;
    @Input() readonly = false;
    @Output() valueChanges = new EventEmitter<string>();
    @Output() discardChanges = new EventEmitter<string>();
    @Output() formSubmitResponse = new EventEmitter<IRequestTextData>();
    @Output() formDeleteResponse = new EventEmitter();

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.message?.currentValue) {
            this.editResponse.setValue(changes.message.currentValue);
            this.showCompose = false;
        }
    }

    setResponse(control: FormControl): void {
        this.editResponse = control;
        this.valueChanges.emit(this.editResponse?.value);
    }

    public discard(): void {
        this.editResponse.setValue(this.message);
        this.showCompose = false;
        this.discardChanges.emit();
    }

    submitResponse(newResponse: IRequestTextData): void {
        const responseToEmit = {
            ...newResponse,
            modifiedAt: this.modifiedAt
        };
        this.message = responseToEmit.textinput;
        this.showCompose = false;
        this.modifiedBy = this.userId;
        this.modifiedAt = new Date().toISOString();
        this.formSubmitResponse.emit(responseToEmit);
        this.quillError = null;
    }

    getResponseValue(): string {
        return this.editResponse.value;
    }

    editMessage(): void {
        this.showCompose = !this.showCompose;
    }

    deleteMessage(): void {
        this.showCompose = true;
        this.formDeleteResponse.emit();
    }
}
