import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { MAT_LUXON_DATE_FORMATS } from "@angular/material-luxon-adapter";
import { RequestCommonComponent } from "../request-common.component";
import { VaultService } from "@findex/vault";
import { ThreadCardService } from "../../../../../portal-modules/src/lib/threads-ui/services/thread-card.service";
import { IThreadCard, IThread } from "@findex/threads";
import { VaultRequestService } from "../../../services/vault-request.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Loader } from "../../../../../portal-modules/src/lib/shared/services/loader";
import { AnalyticsService, GA_EVENTS_PREFIX, HOT_JAR_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { ICreateRequestMenuTemplateDefinition } from "projects/default-plugins/vault/types/CreateRequest";
import { UnsavedModalDialogService } from "projects/portal-modules/src/lib/shared/services/unsaved-modal-dialog.service";

interface IModalData {
    thread: IThread;
    template?: ICreateRequestMenuTemplateDefinition;
}

interface ICreateRequestCardResponse {
    card: IThreadCard;
    attachments: { vaultId: string; fileId: string };
}
@Component({
    selector: "app-create-request",
    templateUrl: "./create-request.component.html",
    styleUrls: ["./../request-common.scss", "./create-request.component.scss"],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: "en-AU" },
        { provide: MAT_DATE_FORMATS, useValue: MAT_LUXON_DATE_FORMATS }
    ]
})
export class CreateRequestComponent extends RequestCommonComponent implements OnInit {
    readonly ANALYTICS_PREFIX = GA_EVENTS_PREFIX.RFI_CREATE_REQUEST;
    readonly hotJarEvents = HOT_JAR_EVENTS;

    attachments: File[] = [];
    errorMessage: string;
    loader = new Loader();
    contentMaxHeight: string = '65vh';
    minDate: Date = new Date();

    form = new FormGroup({
        vaultTitle: new FormControl("", [Validators.required]),
        dueDate: new FormControl(null),
        cardDescription: new FormControl("", [Validators.required]),
        requestItems: new FormArray([
            new FormGroup({
                description: new FormControl("", [Validators.required]),
                completed: new FormControl({ value: false, disabled: true })
            })
        ])
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: IModalData,
        private cardService: ThreadCardService,
        private dialogRef: MatDialogRef<CreateRequestComponent>,
        private formBuilder: FormBuilder,
        vaultRequestService: VaultRequestService,
        authService: AuthService,
        vaultService: VaultService,
        analytics: AnalyticsService,
        cdr: ChangeDetectorRef,
        private unsavedDialogService: UnsavedModalDialogService,
    ) {
        super(vaultRequestService, vaultService, authService, analytics, cdr);
    }

    ngOnInit() {
        this.initControls(this.data?.template);
    }

    private initControls(template: ICreateRequestMenuTemplateDefinition): void {
        if (!template) {
            return;
        }

        const { vaultTitle, cardDescription, requestItems } = template;
        if (vaultTitle || cardDescription) {
            this.form.patchValue({
                vaultTitle: vaultTitle || "",
                cardDescription: cardDescription || ""
            });
        }

        if (requestItems?.length) {
            this.initRequestItems(requestItems);
        }
    }

    private initRequestItems(requestItems: string[]): void {
        this.form.setControl(
            "requestItems",
            this.formBuilder.array(
                requestItems.map(
                    val =>
                        new FormGroup({
                            description: new FormControl(val, [Validators.required]),
                            completed: new FormControl({ value: false, disabled: true })
                        })
                )
            )
        );
    }

    attachFile(attachment: File) {
        this.attachments.push(attachment);
    }

    removeAttachment(index: number) {
        this.attachments.splice(index, 1);
    }

    async save() {
        try {
            this.loader.show();
            const { vaultTitle, dueDate, cardDescription, requestItems } = this.form.value;
            const dueDateUTC = dueDate ? dueDate.toUTC() : null;
            const result = await this.cardService
                .createCard<any, ICreateRequestCardResponse>(this.data.thread.id, "vault-request", {
                    cardDescription,
                    vaultTitle,
                    requestItems,
                    dueDate: dueDateUTC
                })
                .toPromise();

            await this.vaultRequestService.uploadRequestAttachments(
                this.data.thread.id,
                result.card.id,
                result.attachments.vaultId,
                result.attachments.fileId,
                this.attachments
            );
            this.trackAnalyticsEvent("mouse-click", this.hotJarEvents.RFINewCompleteEvent);
            if(this.attachments.length) {
                this.trackAnalyticsEvent("mouse-click", this.hotJarEvents.RFIAttachmentCompleteEvent);
            }

            this.dialogRef.close();
        } finally {
            this.loader.hide();
        }
    }

    async close() {
        this.trackAnalyticsEvent("mouse-click", this.hotJarEvents.RFINewCloseEvent);
        if(!this.form.dirty && this.attachments.length === 0) {
            this.dialogRef.close();
        } else {
            const confirmClose = await this.unsavedDialogService.confirmClose('rfi-create');
            if (confirmClose) {
                this.trackAnalyticsEvent("mouse-click", this.hotJarEvents.RFINewUnsavedEvent);
                this.dialogRef.close();
            }
        }
    }
}
