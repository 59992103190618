<fx-accordion [isExpanded]="true" [toggleGutters]="true">
    <div accordion-header>
        <div class="fx-threads-group-name">
            <ghost-loader [showAnimation]="showAnimation" [width]="100" shape="square" secondary="true"></ghost-loader>
        </div>
    </div>

    <div accordion-content>
        <div class="fx-threads-list" *ngFor="let ghost of ghostArray">
            <div class="fx-threads-category">
                <span class="fx-threads-category-title">
                    <ghost-loader
                        [showAnimation]="showAnimation"
                        [width]="100"
                        shape="square"
                        secondary="true"
                    ></ghost-loader>
                </span>
            </div>
            <button class="fx-threads-summary-card">
                <div class="fx-threads-summary-card-avatar">
                    <ghost-loader [showAnimation]="showAnimation" [width]="42" shape="round"></ghost-loader>
                </div>
                <div class="fx-threads-summary-card-row">
                    <div class="fx-threads-summary-card-top">
                        <span class="fx-threads-summary-card-name">
                            <ghost-loader [showAnimation]="showAnimation" [width]="100" shape="square"></ghost-loader>
                        </span>
                        <span class="fx-threads-summary-card-type">
                            <ghost-loader [showAnimation]="showAnimation" [width]="100" shape="square"></ghost-loader>
                        </span>
                    </div>
                    <div class="fx-threads-summary-card-bottom">
                        <span class="fx-threads-summary-card-summary">
                            <ghost-loader [showAnimation]="showAnimation" [width]="100" shape="square"></ghost-loader>
                        </span>
                        <span class="fx-threads-summary-card-time">
                            <ghost-loader [showAnimation]="showAnimation" [width]="100" shape="square"></ghost-loader>
                        </span>
                    </div>
                </div>
            </button>
        </div>
    </div>
</fx-accordion>
