<div class="onboarding-tile-selection">
    <div class="onboarding-tile-selection-header">
        <div class="onboarding-tile-selection-header-title" *ngIf="title" [innerHTML]="title">
            <img *ngIf="mobileImage" src="{{ mobileImage }}" />
        </div>
        <div class="onboarding-tile-selection-header-subtitle" *ngIf="subtitle" [innerHTML]="subtitle"></div>
    </div>
    <div class="onboarding-tile-selection-body">
        <form [formGroup]="form">
            <fx-tile-component
                [selectionType]="selectionType"
                formControlName="tileBoxes"
                [class.full-width]="fullWidth"
            >
                <ng-container *ngFor="let tile of tileModel">
                    <fx-tile-button
                        [value]="tile.value.toLocaleLowerCase()"
                        class="onboarding-tile-selection-body-tile"
                        data-cy="onboarding-tile"
                        [tooltip]="tile?.tooltip"
                        [analyticsClick]="analyticsPrefix + tile.value.toLocaleLowerCase() + '_button'"
                    >
                        <div>
                            <div *ngIf="tile.image">
                                <illustration [name]="tile.image"></illustration>
                            </div>
                            <div
                                class="onboarding-tile-selection-body-tile-title"
                                *ngIf="tile.title"
                                [innerHTML]="tile?.title"
                            ></div>
                            <div
                                class="onboarding-tile-selection-body-tile-name"
                                *ngIf="tile.name"
                                [innerHTML]="tile?.name"
                            ></div>
                            <div
                                class="onboarding-tile-selection-body-tile-subtitle"
                                *ngIf="tile.subtitle"
                                [innerHTML]="tile?.subtitle"
                            ></div></div
                    ></fx-tile-button>
                </ng-container>
            </fx-tile-component>
        </form>
        <img class="onboarding-tile-selection-body-image" *ngIf="desktopImage" src="{{ desktopImage }}" />
    </div>
</div>
