import { Injectable } from "@angular/core";
import { ButtonType, IStepConfigurationButton, IStepConfiguration } from "./multi-component-layout.component";

@Injectable()
export class MultiComponentService {
    showCurrentStepButtons(activeStepIndex: number, stepConfigurations: IStepConfiguration[]): IStepConfiguration[] {
        return stepConfigurations.map(stepConfig => {
            if (activeStepIndex === stepConfig.stepIndex) {
                return this.showButtons(stepConfig);
            } else {
                return this.hideButtons(stepConfig);
            }
        });
    }

    toggleForwardButtons(
        activeStepIndex: number,
        stepConfigurations: IStepConfiguration[],
        enable: boolean
    ): IStepConfiguration[] {
        return stepConfigurations.map(stepConfig => {
            if (stepConfig.stepIndex === activeStepIndex) {
                return {
                    ...stepConfig,
                    buttons: this.updateStepButtons(stepConfig.buttons, enable)
                };
            } else return stepConfig;
        });
    }

    updateStepButtons(stepButtons: IStepConfigurationButton[], enable: boolean) {
        return stepButtons.map(button => {
            switch (button.type) {
                case ButtonType.Finish:
                case ButtonType.Forward:
                    return {
                        ...button,
                        isDisabled: !enable
                    };
                case ButtonType.Backward:
                default:
                    return button;
            }
        });
    }

    private hideButtons(stepConfig: IStepConfiguration): IStepConfiguration {
        return {
            ...stepConfig,
            buttons: stepConfig.buttons.map(button => ({
                ...button,
                isHidden: true
            }))
        };
    }

    private showButtons(stepConfig: IStepConfiguration): IStepConfiguration {
        return {
            ...stepConfig,
            buttons: stepConfig.buttons.map(button => ({
                ...button,
                isHidden: false
            }))
        };
    }
}
