import { Inject, Injectable } from "@angular/core";
import { EnvironmentSpecificConfig } from "../../../../projects/portal-modules/src/lib/environment/environment.common";
import { catchError, map } from "rxjs/operators";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { IParticipant, Role } from "@findex/threads";
import { IPrivateProfileDetails } from "../interfaces/IPrivateProfileDetails";
import { ParticipantDetail } from "../../../../projects/portal-modules/src/lib/user-profile/services/user-profile.service";
import { endpoints } from "../endpoints";
import { ENVIRONMENT } from "src/app/injection-token";

@Injectable({ providedIn: "root" })
export class PrivateProfileService {
    constructor(private httpClient: HttpClient, @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig) {}

    updatePrivateProfileInfo<T>(userId: string, details: T) {
        const { base } = this.environment.threadsEndpoints;
        const { base: sameDayTaxBase, secureProfile, secureProfileBase } = endpoints;
        const url = `${base}${sameDayTaxBase}${secureProfileBase}/${userId}${secureProfile}`;
        return this.httpClient
            .post<T>(url, details)
            .pipe(
                catchError((errorResponse: HttpErrorResponse) =>
                    throwError(new Error(errorResponse.error ? errorResponse.error.message : errorResponse.message))
                )
            );
    }

    getPrivateProfileDetails<T>(userId: string): Observable<T> {
        const { base } = this.environment.threadsEndpoints;
        const { base: sameDayTaxBase, secureProfile, secureProfileBase } = endpoints;
        const url = `${base}${sameDayTaxBase}${secureProfileBase}/${userId}${secureProfile}`;
        return this.httpClient.get<T>(url);
    }

    async enrichParticipantDetail(participant: IParticipant, currentRole: Role): Promise<ParticipantDetail> {
        try {
            if (
                !(currentRole === Role.Staff || currentRole === Role.Administrator) ||
                participant.role !== Role.Client
            ) {
                return participant;
            }
            const privateDetails = await this.getPrivateProfileDetails<IPrivateProfileDetails>(
                participant.id
            ).toPromise();
            const participantDetail =
                privateDetails && privateDetails.taxFileNumber
                    ? `TFN ${privateDetails.taxFileNumber}`
                    : "No TFN Provided";
            return { ...participant, participantDetail };
        } catch (error) {
            console.error("Unable to get participant detail", participant.id);
            return participant;
        }
    }

    updateCompleteProfileCard<T>(threadId: string, cardId: string, details: T) {
        const { base } = this.environment.threadsEndpoints;
        const { base: sameDayTaxBase, secureProfile } = endpoints;
        const url = `${base}${sameDayTaxBase}/threads/${threadId}/cards/${cardId}${secureProfile}`;
        return this.httpClient.post(url, details).pipe(
            map((response: any) => {
                const { updatedUser } = response;
                return {
                    updatedUser
                };
            }),
            catchError((errorResponse: HttpErrorResponse) =>
                throwError(new Error(errorResponse.error ? errorResponse.error.message : errorResponse.message))
            )
        );
    }
}
