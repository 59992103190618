<div *ngIf="thread?.participants">
    <button
        class="thread-participants-toggle"
        [ngClass]="{ active: showDialog }"
        (click)="showDialog = !showDialog"
        cdkOverlayOrigin
        #trigger="cdkOverlayOrigin"
        analyticsClick="app_viewparticipants"
    >
        <i
            class="las"
            [ngClass]="{
                'la-user-plus': role | permissions: 'UpdateParticipants' | async,
                'la-user-friends': !(role | permissions: 'UpdateParticipants' | async)
            }"
        ></i>
        <span class="badge">{{ thread.participants.length }}</span>
    </button>

    <ng-template
        cdkConnectedOverlay
        (backdropClick)="showDialog = false"
        cdkConnectedOverlayBackdropClass="transparent-overlay-background"
        [cdkConnectedOverlayHasBackdrop]="true"
        [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayOpen]="showDialog"
        [cdkConnectedOverlayPositions]="[
            {
                originX: 'end',
                originY: 'bottom',
                overlayX: 'end',
                overlayY: 'top'
            }
        ]"
    >
        <div class="overlay-window">
            <div class="thread-participants">
                <div class="dialog">
                    <div class="dialog-participant-list">
                        <div class="dialog-participant-container" *ngFor="let participant of threadParticipants">
                            <div class="dialog-participant" (click)="openProfile(participant.id)">
                                <fx-avatar
                                    [name]="participant.profile?.name"
                                    [image]="participant.id | profilePicture"
                                    size="medium"
                                ></fx-avatar>
                                <div class="dialog-participant-user">
                                    <div
                                        class="dialog-participant-user-name"
                                        [ngClass]="{
                                            'dialog-participant-user-name--link':
                                                role | permissions: 'ReadProfile' | async
                                        }"
                                    >
                                        {{ participant.profile?.name }}
                                    </div>
                                    <div class="dialog-participant-user-title">
                                        {{ participant.profile?.title || participant.businessName }}
                                    </div>
                                    <div class="dialog-participant-user-title" *ngIf="threadParticipantDetails">
                                        {{ participant.participantDetail }}
                                    </div>
                                </div>
                            </div>
                            <div
                                class="dialog-participant-delete"
                                *ngIf="
                                    addParticipantsButton &&
                                    thread.participants.length > 2 &&
                                    (thread | isThreadActive | async) &&
                                    (role | permissions: 'UpdateParticipants' | async)
                                "
                                (click)="remove(participant)"
                                [analyticsClick]="gaEvents.APP_REMOVEPARTICIPANT"
                            >
                                &#10006;
                            </div>
                        </div>
                    </div>
                    <div
                        class="dialog-add"
                        *ngIf="
                            addParticipantsButton &&
                            (role | permissions: 'UpdateParticipants' | async) &&
                            (thread | isThreadActive | async)
                        "
                    >
                        <button
                            (click)="openDialog()"
                            class="fx-btn fx-btn--primary-outlined"
                            [analyticsClick]="gaEvents.APP_ADDPARTICIPANTS"
                        >
                            ADD PEOPLE
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</div>
