import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FindexHeaderComponent } from "./components/findex-header/findex-header.component";
import { LoaderIconComponent } from "./components/loader-icon/loader-icon.component";
import { FindexProfileComponent } from "./components/findex-profile/findex-profile.component";
import { FindexMainComponent } from "./components/findex-main/findex-main.component";
import { FindexSlideMenuComponent } from "./components/findex-slide-menu/findex-slide-menu.component";
import { FindexDropdownComponent } from "./components/findex-dropdown/findex-dropdown.component";
import { SignatureIconComponent } from "./components/signature-icon/signature-icon.component";
import { GhostLoaderComponent } from "./components/ghost-loader/ghost-loader.component";
import { FxTileButtonComponent } from "./components/fx-tile/fx-tile-button/fx-tile-button.component";
import { FxTileComponent } from "./components/fx-tile/fx-tile.component";
import { FxBadgeComponent } from "./components/fx-badge/fx-badge.component";
import { RouterModule } from "@angular/router";
import { FxUiModule } from "@findex/fx-ui";
import { AnalyticsModule } from "../analytics";
import { FxNotifierComponent, FxNotifierService } from "./components/fx-notifier"
import { MatSliderModule } from "@angular/material/slider";
import { IllustrationsModule } from "../illustrations";
import { FindexMenuModule } from "../findex-menu/findex-menu.module";
import { PortalBrandComponent } from "./components/portal-brand/portal-brand.component";

@NgModule({
    declarations: [
        FindexHeaderComponent,
        FindexMainComponent,
        FindexProfileComponent,
        FindexSlideMenuComponent,
        LoaderIconComponent,
        FindexDropdownComponent,
        SignatureIconComponent,
        GhostLoaderComponent,
        FxTileButtonComponent,
        FxTileComponent,
        FxBadgeComponent,
        PortalBrandComponent,
        FxNotifierComponent
    ],
    exports: [
        FindexHeaderComponent,
        FindexMainComponent,
        FindexProfileComponent,
        FindexSlideMenuComponent,
        LoaderIconComponent,
        FindexDropdownComponent,
        FxUiModule,
        IllustrationsModule,
        SignatureIconComponent,
        AnalyticsModule,
        GhostLoaderComponent,
        FxTileButtonComponent,
        FxTileComponent,
        FindexMenuModule,
        FxBadgeComponent,
        PortalBrandComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        FxUiModule,
        AnalyticsModule,
        MatSliderModule,
        IllustrationsModule,
        FindexMenuModule
    ],
    providers: [FxNotifierService]
})
export class FindexUiModule {}
