import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { VaultService } from "@findex/vault";
import { map, take } from "rxjs/operators";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { IVaultItem } from "../../interfaces/IVaultItem";
import { IVaultItemFile } from "../../interfaces/IVaultItemFile";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";

@Component({
    selector: "signing-modal",
    templateUrl: "./signing-modal.component.html",
    styleUrls: ["./signing-modal.component.scss"]
})
export class SigningModalComponent implements OnInit {
    name = "";
    title = "";
    agreed = false;
    errorMessage: string;

    userId: string;
    readonly theme = this.environment.theme;
    readonly signingRequiresFileDownloadEnabled = this.environment.featureFlags.signingRequiresFileDownloadEnabled;

    constructor(
        @Inject(MAT_DIALOG_DATA) public vaultItem: IVaultItem,
        private dialogRef: MatDialogRef<SigningModalComponent>,
        private vaultService: VaultService,
        private authService: AuthService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig
    ) {}

    ngOnInit() {
        this.authService
            .getUser()
            .pipe(
                take(1),
                map(user => user && user.id)
            )
            .subscribe(userId => (this.userId = userId));
    }

    async download() {
        if (!this.vaultItem.files.length) return;
        const filename = this.vaultItem.files[0].filename;

        const { vaultId, fileId } = this.vaultItem;

        const downloadWindow = window.open("", "_self");
        const downloadUrl = await this.vaultService.getDownloadUrl(vaultId, fileId, filename).toPromise();
        downloadWindow.location.href = downloadUrl;
    }

    accept() {
        this.errorMessage = "";

        if (!this.name || !this.title || !this.agreed) {
            this.errorMessage = "Please ensure all fields are complete.";
            return;
        }

        if (this.signingRequiresFileDownloadEnabled && !this.hasUserDownloadedFile(this.vaultItem.files, this.userId)) {
            this.errorMessage = "Please ensure you have downloaded and read the file.";
            return;
        }

        this.dialogRef.close({
            name: this.name,
            title: this.title,
            agreed: this.agreed
        });
    }

    closeModal() {
        return this.dialogRef.close();
    }

    hasUserDownloadedFile(files: IVaultItemFile[], userId: string): boolean {
        const hasUserDownloaded = files.every(file => {
            if (!file.actionLogs || !Array.isArray(file.actionLogs)) {
                return false;
            }

            const fileDownloadedActions = file.actionLogs.find(
                log => log.action === "file-downloaded" && log.actorId === userId
            );
            return !!fileDownloadedActions;
        });
        return hasUserDownloaded;
    }
}
