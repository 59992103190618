import { TourStep } from "../../projects/portal-modules/src/lib/shared/services/tour.service";
import {
    defaultCalendarMeetingDurations,
    FeatureConfig
} from "../../projects/portal-modules/src/lib/environment/environment.common";
import { defaultTextResource } from "../../projects/portal-modules/src/lib/environment/default-text-resource";
import { ThreadAuditSubtypes, ThreadTypes, ThreadAuditSubtypesPhases } from "@findex/threads";
import { filesTour } from "./tours";
import { AuditSubtypes } from "../../projects/default-plugins/vault/types/AuditSubtypes";
import { startCase } from "lodash-es";

enum MyFindexLegacyWorkflows {
    AccountManagement = "account_management",
    Audit = "audit",
    PayrollFortnightly = "payroll_fortnightly",
    PayrollMonthly = "payroll_monthly",
    PayrollWeekly = "payroll",
    Lending = "lending",
    AccountingTax = "accountingTax"
}

const introductionTourSteps: TourStep[] = [
    {
        titleHtml: `<p>Hi #{username}!</p>`,
        contentHtml: `
            <p>Welcome to myFindex.</p>
            <p>myFindex is an online portal where clients of Findex & Crowe as well as their advisers can securely communicate via direct messaging, virtual meetings & document sharing. Soon, job status & payments will be accessible via myFindex. </p>
            <img class="tour-tagline" src="assets/images/tagline-orange.png"/>`,
        nextButtonText: `Let's go!`,
        routeTo: "/dashboard"
    },
    {
        titleHtml: `Your Dashboard`,
        contentHtml: `<p>Your dashboard contains a summary of activity, tasks & scheduled upcoming meetings in myFindex. Latest business insights are available in your latest news section.</p>`,
        nextButtonText: `Got it!`,
        attachTo: {
            selector: "#home-menu-item .menu-item",
            side: "bottom"
        },
        menuStateBeforeShow: "open"
    },
    {
        titleHtml: `Your Timeline`,
        contentHtml: `<p>Timelines is where clients of Findex & Crowe as well as their advisers can securely communicate via direct messaging, virtual meetings & document sharing.</p>`,
        nextButtonText: `Got it!`,
        attachTo: {
            selector: "#timeline-menu-item .menu-item",
            side: "bottom"
        }
    },
    {
        titleHtml: `Your Tasks`,
        contentHtml: `<p>Your task list will show outstanding action items from your timelines. When visible, click each item to see more detail.</p>`,
        nextButtonText: `Got it!`,
        attachTo: {
            selector: "#dashboard-threads",
            side: "bottom"
        },
        menuStateBeforeShow: "closed"
    },
    {
        titleHtml: `Upcoming Meetings`,
        contentHtml: `<p>All meetings are held on the platform. Please ensure that you join the meeting at the scheduled time. You will need a working microphone and speaker with video camera optional. </p>`,
        nextButtonText: `Got it!`,
        attachTo: {
            selector: "#dashboard-upcoming-meetings .card",
            side: "bottom"
        },
        menuStateBeforeShow: "closed"
    },
    {
        titleHtml: `Notifications`,
        contentHtml: `<p>Your notification feed will keep you up to date on account activity, so nothing is missed.</p>`,
        nextButtonText: `Got it!`,
        attachTo: {
            selector: "#slide-menu-notifications",
            side: "bottom"
        }
    }
];

const AUDIT_SUBTYPES: Array<AuditSubtypes> = [
    {
        type: ThreadAuditSubtypes.Planning,
        title: `${ThreadAuditSubtypesPhases.PhaseA} - Planning`
    },
    {
        type: ThreadAuditSubtypes.Fieldwork,
        title: `${ThreadAuditSubtypesPhases.PhaseB} - Fieldwork`
    },
    {
        type: ThreadAuditSubtypes.BalanceSheet,
        title: `${ThreadAuditSubtypesPhases.PhaseB} - Balance Sheet`
    },
    {
        type: ThreadAuditSubtypes.Assets,
        title: `${ThreadAuditSubtypesPhases.PhaseB} - Assets`
    },
    {
        type: ThreadAuditSubtypes.Liabilities,
        title: `${ThreadAuditSubtypesPhases.PhaseB} - Liabilities`
    },
    {
        type: ThreadAuditSubtypes.Equity,
        title: `${ThreadAuditSubtypesPhases.PhaseB} - Equity`
    },
    {
        type: ThreadAuditSubtypes.IncomeStatement,
        title: `${ThreadAuditSubtypesPhases.PhaseB} - Income Statement`
    },
    {
        type: ThreadAuditSubtypes.Revenue,
        title: `${ThreadAuditSubtypesPhases.PhaseB} - Revenue`
    },
    {
        type: ThreadAuditSubtypes.Expenditure,
        title: `${ThreadAuditSubtypesPhases.PhaseB} - Expenditure`
    },
    {
        type: ThreadAuditSubtypes.Payroll,
        title: `${ThreadAuditSubtypesPhases.PhaseB} - Payroll`
    },
    {
        type: ThreadAuditSubtypes.Completion,
        title: `${ThreadAuditSubtypesPhases.PhaseC} - Completion`
    }
]

const requestConfigurationItems =  {
    financialStatementsAudit: [
        "System Access - Please provide access to your accounting software. Should you utilise the payroll module, please ensure access is granted to this too.",
        "General - Receipt of signed 'Acknowledgment' page from the engagement letter",
        "Trial Balance - Trial Balance, Balance Sheet and P&L Statement as at balance date, for the full financial year, in Excel",
        "General - Copy of the general ledger, in excel, for the full financial year",
        "General - General Journals listing for the full financial year, in Excel",
        "COVID-19 disclosures - Supporting assessment for the need to include a subsequent events disclosure in relation to COVID-19 and any other supporting documentation that may be relevant.",
        "Event after the reporting period - Any events after year end that would indicate any adjustments?",
        "Governance - Copies of signed minutes of all Board meetings, or any other committee meetings held from the start of the financial year until the signing of the audit report",
        "Going concern - Copy of the latest balance sheet and P/L and any budgets and cashflow forecasts, in excel, for next financial year to address going concern and COVID-19 requirements. Budget  / forecast to cover the period of 12 months following the expected date of the completion of the audit. Please include a comment of management going concern assessment and ability to meet debts when become due.",
        "Governance - Please advise if there has been any suspected, alleged or actual fraud during from the start of the financial year until the completion of the audit?",
        "Governance - Documentation (if any) detailing the delegation of authority, internal controls and processes.",
        "Governance - Are there any related parties? Please provide a listing of all related party transactions for the financial year and related party asset or liability balances as at balance date.",
        "IT - Details of IT systems used (computer operating systems, accounting software, antivirus etc) - updates from prior year if any",
        "Cash and Cash Equivalent - For each bank account, provide the bank reconciliation and bank statements as at balance date (in pdf and excel both)",
        "Receivables - Aged receivables Listing as at balance date, reconciled to the TB",
        "Receivables - For long outstanding receivables, please include comment regarding their recoverability.",
        "BAS - BAS statements of the year, including ATO itemised account detail for the period.",
        "BAS - BAS reconciliation for the year. This includes a reconciliation of revenue disclosed on the BAS returns to that disclosed in the P&L. In addition the reconciliation of the balance outstanding to the ATO in the TB to that on the ATO account as at year end.",
        "Other assets - List of  prepayments (if any) at balance date, reconciled to the TB.",
        "PPE - Fixed assets register at balance date, reconciled to TB.",
        "PPE - Depreciation schedule / supporting information on depreciation calculation for the financial year.",
        "PPE - List of PPE additions and disposals during the financial year, in Excel.",
        "Leases - AASB 16 - Relevant calculation to support accounting for leases under AASB 16 Leases - Right of use Assets, Lease Liability, depreciation and interest expense. Please also confirm that there are no other contracts that the entity is a party to which would constitute a lease agreement.",
        "Leases - AASB 16 - Copies of lease agreements for any new leases.",
        "Payables - Aged payables listing as at balance date, reconciled to the TB.",
        "Payables - List of accrued expenses, as at balance date, if any.",
        "Payables - For each bank account, provide the bank statement for the period subsequent to balance date until the completion of the audit.",
        "Provisions - Working papers supporting the calculation the balance of the provision for annual leave at balance date (including on-costs and wage inflation factors).",
        "Provisions - Working papers supporting the calculation of the provision for long service leave, including: Assumptions used to determine liability than can be unconditionally deferred, Application of wage inflation factor and on-costs and Rates used to discount liability to present value",
        "Loans - Provide loan agreements, amortisation schedules and statements of balance at year end.",
        "Revenue - Copies of any grant agreements and assessments of timing of revenue recognition.",
        "Revenue - For membership based organisations, provide summary of membership based, as extracted from client management system.",
        "Expenditure - Legal - List of legal expenses for the financial year, in Excel (if any) and copies of legal invoices.",
        "Expenditure - Legal - Discussion with management for open litigations and legal matters, and contingent liabilities or assets.",
        "Expenditure - Rent - Copy of all leases agreement (premises and equipment) (if any).",
        "Expenditure - Other - A sample will be extracted for testing to invoices. Should there be any significant line items, please provide an explanation and supporting agreements.",
        "Payroll - List of all staff as at balance date including a calculation of FTE.",
        "Payroll - Listing of all employee commencements during the financial year to date.",
        "Payroll - Listing of all employee terminations during the financial year to date.",
        "Payroll - Payroll reports for the financial year summarising pay per employee, reconciled to the trial balance.",
        "Payroll - Details of any 'across the board' pay increases since the start of the financial year."
    ],
    realEstateTrustAudit: [
        "Signed Engagement Letter.",
        "Signed Representation Letter.",
        "Bank Statement for each month of the financial year for all Trust Accounts.",
        "Trust Trial Balance for each month for Trust Accounts.",
        "Bank reconciliation for each month for Trust Accounts.",
        "Monthly cashbook of receipts and payments for all Trust Account for each month.",
        "Transaction List Report for each month of the financial year for all Trust Accounts.",
        "Copy of the Trust Journal report for the year.",
        "Active Trust Ledgers for each month for Trust Accounts.",
        "Dormant Ledger Listing for the year ended 31 March 2022 (if applicable).",
        "Investments ledger at the end of each month.",
        "EFT/Cheque payments listing at the end of each month."
    ],
    solicitorsTrustAudit: [
        "Signed Engagement Letter.",
        "Signed Representation Letter.",
        "Bank Statement for each month of the financial year for all Trust Accounts.",
        "Trust Trial Balance for each month for Trust Accounts.",
        "Bank reconciliation for each month for Trust Accounts.",
        "Monthly cashbook of receipts and payments for all Trust Account for each month.",
        "Transaction List Report for each month of the financial year for all Trust Accounts.",
        "Copy of the Trust Journal report for the year.",
        "Active Trust Ledgers for each month for Trust Accounts.",
        "Dormant Ledger Listing for the year ended 31 March (if applicable).",
        "Investments ledger at the end of each month.",
        "EFT/Cheque payments listing at the end of each month.",
        "All Controlled Money Records for the year (if applicable) including; Notices to Clients Maintained in Register, Monthly Ledger Listings of Accounts, Ledgers/Bank Statements for all Clients, Receipts and Written Records for all Withdrawals.",
        "Transit Money Register (if applicable).",
        "Record of all dealings relating to trust money subject to a specific power and supporting documentation in relation to the dealings (if applicable).",
        "Written direction instructions relating to trust money subject to written direction.",
        "Register of Powers and Estates in regard to estates in which the law practice or an associate of the law practice is acting or entitled to act (alone or jointly with the law practice or one or more associates of the law practice) as the executor or administrator as at 31 March.",
        "Investments Money Register and Investments Money statements for the year ended 31 March",
        "Register of financial interest where a legal practitioner associate had a financial interest in any company, partnership, or other entity (except for companies listed on the Australian Stock Exchange, and shelf companies (companies that have already been registered but have not traded) maintained for sale), and which engages in any dealing with trust money received by the associate or the law practice (if applicable).",
        "All Mortgage Records for the year end (if applicable) including; Summaries of Mortgage Maintained in an Investment Register, All Documents in Relation to Variations of Mortgages, All General and/or Specific Mortgage Lending Authorities and Monthly Listings of Mortgages (if applicable).",
        "Details of Law Society Statutory Deposit/Letter of Credit (if applicable) including any April 2021 adjustments. We will need your calculation of your statutory deposit as well as the statutory deposit or letter of credit."
    ],
    myFindexAudit: [
        "Please provide the Digital Audit Hub Client Services Team access to your accounting software using DigitalAuditHubCST@Crowe.com.au, if you are unable to provide access a list of reports will be requested for you provide.",
        "Signed Minutes of any Meetings for the full financial year to current date.",
        "Bank Statements for all bank accounts for the period for the full financial year.",
        "Bank Statements for all bank accounts for the period at least one month post financial year end.",
        "BAS statements for the financial year from the ATO Portal (lodged versions), including any ITO itemised account detail for the period.",
        "Fixed assets register at balance date, reconciled to Trial Balance.",
        "Depreciation schedule / supporting information on depreciation calculation for the financial year.",
        "List of Property Plant & Equipment additions and disposals during the financial year. in Excel.",
        "Copies of any grant agreements and grant funding agreements & remittance."
    ],
    planning: [
        "System Access - Please provide the Digital Audit Hub Client Services Team  access to your accounting software using DigitalAuditHubCST@Crowe.com.au, if you are unable to provide access a list of reports will be requested for you to provide.",
        "Governance - Signed Minutes of any Meetings for the full financial year to current date",
        "Cash and Cash Equivalent - Bank Statements for all bank accounts for the period for the full financial year",
        "Payables - Bank statements for all bank accounts for the period at least one month post financial year end",
        "BAS - BAS statements for the financial year from the ATO Portal (lodged versions), including any ITO itemised account detail for the period.",
        "PPE - Fixed assets register at balance date, reconciled to Trial Balance",
        "PPE - Depreciation schedule / supporting information on depreciation calculation for the financial year",
        "PPE - List of Property Plant & Equipment additions and disposals during the financial year, in Excel",
        "Revenue - Copies of any grant agreements and grant funding agreements & remittance"
    ],
    fieldwork: [
        "Signed Minutes of Meetings - Is there a meeting held  from July 1, 2023 up to this date? If so, please provide copy of the signed minutes",
        "Contracts/Agreements - Are there any lease agreements entered during the year? (lease a building, photocopy machine etc.)",
        "Contracts/Agreements - Any new contracts entered during the year?",
        "Contracts/Agreements - Please provide the insurance contract",
        "Forecasted/Budgeted Cash Flows - Please provide cash flow forecast, if available",
        "Related Party Transactions - Is there related party transactions made during the year?",
        "Commitments and Contingencies - Has the entity entered into any commitments such as to purchase large amounts of PPE during the year or subsequent to financial year end?",
        "Commitments and Contingencies - Does the entity have any contingent liabilities (potential payouts due to legal action) during the financial year or subsequent to year end?",
        "Commitments and Contingencies - Has the entity been affected by any natural disasters, fire or significant theft subsequent to year end?",
        "Commitments and Contingencies - Is the Board/Committee aware of any fraud committed during the year?",
        "Commitments and Contingencies - Are there any matters that management and the Board are aware of that may affect the entity's ability to continue under the current going concern assumption?",
        "Subsequent Events - Confirmation if there are any subsequent events requiring disclosure or adjustment after Balance Sheet date that are not yet reflected in the financials, if any",
        "Bank Statements - Kindly provide the  bank statements from July 1 2023 up to this date",
        "Bank Walkthrough - Who are the bank signatories?",
        "Bank Walkthrough - Is there a threshold that requires dual signatories?",
        "Bank Walkthrough - Please provide sample documents/screen shot that shows dual signatories",
        "Bank Walkthrough - Are payments made through EFT, cheque and cash?",
        "Bank Walkthrough - How may required signatories to process payments through EFT/Cheque?",
        "Revenue & Expense Walkthrough - Who prepares, records, reviews, and approves the invoices?",
        "Revenue & Expense Walkthrough - Are all revenues received through direct deposit?",
        "Revenue & Expense Walkthrough - Is there a monthly meeting to review revenue and expenses?",
        "Revenue & Expense Walkthrough - Who approves purchases/expenses or acquisition of assets?",
        "Revenue & Expense Walkthrough - How is approval being made? (ex. Through email/purchase request form?)",
        "Payroll Walkthrough - How do you track the employee attendance? If timesheet, please provide a sample, if system, please provide a sample screenshot.",
        "Payroll Walkthrough - Who prepares/computes payroll?",
        "Payroll Walkthrough - Are payroll payments made in batches? Monthly or fortnightly?",
        "Payroll Walkthrough - Who reviews and approves payroll before processing?",
        "Leave Provision - Please provide the leave liability report",
        "Samples - Kindly provide the invoices/receipts/contracts and any other supporting documents of the revenue and expenses in Annex A and Annex B"
    ]

}

const requestConfigurationTemplates = [
    {
        title: "Financial Statements Audit",
        type: [ThreadTypes.Audit, ThreadTypes.AccountingTax],
        template: {
            vaultTitle: "Financial Statements Audit",
            cardDescription:
                "Hi, <br /><br />The following is a guide to assist you to assemble the records we require to plan for your upcoming audit. Making available all the applicable items listed below to our team at your earliest convenience will help ensure the minimum of disruption to your business as well as ensuring costs are kept to a minimum. <br /><br /> 1. Attach soft copies of all documentation in the link below. <br /> 2. Where possible please can we have this in Excel format. <br /> 3. Save the document including the reference number of the request. <br /> 4. Note if specific requested information can be extracted directly from your accounting system. <br /><br /> We will only commence with the audit once we have received all information requested. <br /> Please note this list is not designed to be exhaustive. <br /> Further requests will likely be required as information comes to hand. If you have any queries please contact us.",
            requestItems: requestConfigurationItems.financialStatementsAudit
        }
    },
    {
        title: "Real Estate Trust Audit",
        type: [ThreadTypes.Audit, ThreadTypes.AccountingTax],
        template: {
            vaultTitle: "Real Estate Trust Audit",
            cardDescription:
                "Hi, <br /><br />The following is a guide to assist you to assemble the records we require to plan for your upcoming audit. Making available all the applicable items listed below to our team at your earliest convenience will help ensure the minimum of disruption to your business as well as ensuring costs are kept to a minimum. <br /><br /> 1. Attach soft copies of all documentation in the link below. <br /> 2. Where possible please can we have this in Excel format. <br /> 3. Save the document including the reference number of the request. <br /> 4. Note if specific requested information can be extracted directly from your accounting system. <br /><br /> We will only commence with the audit once we have received all information requested. <br /> Please note this list is not designed to be exhaustive. <br /> Further requests will likely be required as information comes to hand. If you have any queries please contact us.",
            requestItems: requestConfigurationItems.realEstateTrustAudit
        }
    },
    {
        title: "Solicitor's Trust Audit",
        type: [ThreadTypes.Audit, ThreadTypes.AccountingTax],
        template: {
            vaultTitle: "Solicitor's Trust Audit",
            cardDescription:
                "Hi, <br /><br />The following is a guide to assist you to assemble the records we require to plan for your upcoming audit. Making available all the applicable items listed below to our team at your earliest convenience will help ensure the minimum of disruption to your business as well as ensuring costs are kept to a minimum. <br /><br /> 1. Attach soft copies of all documentation in the link below. <br /> 2. Where possible please can we have this in Excel format. <br /> 3. Save the document including the reference number of the request. <br /> 4. Note if specific requested information can be extracted directly from your accounting system. <br /><br /> We will only commence with the audit once we have received all information requested. <br /> Please note this list is not designed to be exhaustive. <br /> Further requests will likely be required as information comes to hand. If you have any queries please contact us.",
            requestItems: requestConfigurationItems.solicitorsTrustAudit
        }
    },
    {
        title: "My Findex Audit",
        type: [ThreadTypes.Audit, ThreadTypes.AccountingTax],
        template: {
            vaultTitle: "My Findex Audit",
            cardDescription:
                "Line Item, <br /><br />1-System Access<br /><br />2-Governance<br /><br />3-Cash and Cash Equivalent<br /><br />4-Payable<br /><br />5-BAS<br /><br />6-PPE<br /><br />7-PPE<br /><br />8-PPE<br /><br />9-Revenue",
            requestItems: requestConfigurationItems.myFindexAudit
        }
    },
    {
        title: "Planning",
        type: [ThreadTypes.Audit, ThreadTypes.AccountingTax],
        template: {
            vaultTitle: "Planning",
            cardDescription:
                "Thanks for confirming our engagement for this years audit. The request list below is a guide to assist you to assemble the records we require to plan for your upcoming audit.<br /><br />Making available all the applicable items requested to our team at your earliest convenience will help ensure the minimum of disruption to your business as well as ensuring costs are kept to a minimum.<br /><br />Please note this list is not designed to be exhaustive. Further requests will likely be required as information comes to hand - this will be requested by the auditor conducting the work.<br /><br />If you have any questions about this, then please do not hesitate to contact me.",
            requestItems: requestConfigurationItems.planning
        }
    },
    {
        title: "Fieldwork",
        type: [ThreadTypes.Audit, ThreadTypes.AccountingTax],
        template: {
            vaultTitle: "Fieldwork",
            cardDescription:
                "Hi there,<br /><br />We are planning to conduct the fieldwork phase of your audit. The following is a guide to assist you to assemble the records we require. Making available all the applicable items listed below to our team at your earliest convenience will help ensure the minimum of disruption to your business as well as ensuring costs are kept to a minimum.<br /><br />1. Attach soft copies of all documentation in the link below.<br />2. Where possible please can we have this in Excel format.<br />3. Save the document including the reference number of the request.<br />4. Note if specific requested information can be extracted directly from your accounting system.<br /><br />We will only commence with the audit once we have received all information requested.<br /><br />Please note this list is not designed to be exhaustive.<br /><br />Further requests will likely be required as information comes to hand. If you have any queries please contact us.<br />",
            requestItems: requestConfigurationItems.fieldwork
        }
    }
];

export const CLIENT_PORTAL_FEATURE_CONFIG: FeatureConfig = {
    editCardDescription: true,
    threadListFilterStatus: {
        closed: "Complete",
        cancelled: "Cancelled",
        active: "Active"
    },
    signupEnabled: false,
    signupCountries: ["AU", "NZ"],
    subscriptionEnabled: false,
    mobileVerifiedRedirect: "/dashboard",
    rfiCategories: ["Permanent documents", "Financial statements", "Investments", "Banking", "Receipts", "Other"],
    newThreadButton: false,
    calendarMeetingDurations: defaultCalendarMeetingDurations,
    jobStatusEnabled: true,
    closeThreadContextMenu: false,
    secondaryBranding: true,
    tours: [
        {
            id: "introduction",
            steps: introductionTourSteps,
            tourRoute: "dashboard"
        },
        filesTour
    ],
    supportEmail: "myfindex@findex.com.au",
    serviceDeskUrl: "https://jira.findex.com.au/servicedesk/customer/portal/68/group/387",
    packageDisplayName: "subscription",
    addParticipants: true,
    inviteClients: true,
    threadParticipantDetail: false,
    showAgreePoliciesLogin: true,
    focusWizardEnabled: true,
    temporaryFeatureFlags: {
        hideThreadTypeMyTasks: true
    },
    accountMetadataConfiguration: {
        primaryPhoneNumber: {
            label: "Phone number"
        },
        businessName: {
            label: "Business name"
        },
        emailAddress: {
            label: "Primary contact email address"
        }
    },
    text: {
        signUpTitle: "",
        signupInstruction: "",
        signupAction: "",
        loginTitle: "Log in to your account",
        loginDescription: "",
        loginAction: "",
        loginFooter: "",
        createPasswordTitle: "Reset password",
        verifyMobileTitle: "Verify your identity",
        verifyMobileDescription: "Enter the code we sent to your mobile below to verify that it is really you.",
        verifyMobileAction: "Verify",
        verifyMobileFooter:
            "Your mobile number will not be shared with third parties or used for marketing communications.",
        verifyCodeTitle: "Verify your identity",
        verifyCodeDescription: "We've sent a PIN to your mobile, enter it below to continue.",
        verifyCodeAction: "Verify",
        default: defaultTextResource
    },
    signingRequiresFileDownloadEnabled: true,
    createNewPayments: false,
    windowWidthMenuBreakpoint: 1279,
    windowWidthTabletBreakpoint: 768,
    requiresOnboarding: false,
    blockMyTasksRefreshDuringTour: false,
    enableBrandLoader: false,
    inlineOnboarding: false,
    accountView: true,
    hideDashboardThreadsStatusCol: true,
    auditSubtypes: AUDIT_SUBTYPES,
    creatableThreadsConfiguration: [
        {
            title: "Account Management",
            type: ThreadTypes.MyAccount,
            workflowId: MyFindexLegacyWorkflows.AccountManagement
        },
        {
            title: "Audit",
            type: ThreadTypes.Audit,
            subtype: "",
            titleMapping: form => `Audit ${form.customFormYear} ${startCase(form.customSubtype)}`,
            workflowId: MyFindexLegacyWorkflows.Audit,
            requiresCustomYear: true,
            requiresCustomSubtypes: true
        },
        {
            title: "Payroll (Weekly)",
            type: ThreadTypes.Payroll,
            workflowId: MyFindexLegacyWorkflows.PayrollWeekly,
            requiresCustomTitle: true,
            requiresCustomSubtypes: false,
            titleMapping: form => `Payroll - ${form.customThreadTitle}`,
            initialMessage:
            "Welcome to your new Payroll timeline. To complete your work, we might require a few pieces of information, so be sure to keep an eye on this timeline for any requests."
        },
        {
            title: "Payroll (Fortnightly)",
            type: ThreadTypes.Payroll,
            workflowId: MyFindexLegacyWorkflows.PayrollFortnightly,
            requiresCustomTitle: true,
            requiresCustomSubtypes: false,
            titleMapping: form => `Payroll - ${form.customThreadTitle}`,
            initialMessage:
            "Welcome to your new Payroll timeline. To complete your work, we might require a few pieces of information, so be sure to keep an eye on this timeline for any requests."
        },
        {
            title: "Payroll (Monthly)",
            type: ThreadTypes.Payroll,
            workflowId: MyFindexLegacyWorkflows.PayrollMonthly,
            requiresCustomTitle: true,
            requiresCustomSubtypes: false,
            titleMapping: form => `Payroll - ${form.customThreadTitle}`,
            initialMessage:
            "Welcome to your new Payroll timeline. To complete your work, we might require a few pieces of information, so be sure to keep an eye on this timeline for any requests."
        },
        {
            title: "Lending",
            type: ThreadTypes.Lending,
            workflowId: MyFindexLegacyWorkflows.Lending
        },
        {
            title: "Accounting & Tax",
            type: ThreadTypes.AccountingTax,
            workflowId: MyFindexLegacyWorkflows.AccountingTax,
            titleMapping: form => `Accounting & Tax ${form.customFormYear}`,
            requiresCustomYear: true,
        }
    ],
    createRequestConfiguration: [
        {
            title: "New blank request",
            description: "Manually enter the details of a request."
        },
        {
            title: "New request from template",
            description: "Select from a prefilled request template.",
            templates: requestConfigurationTemplates
        }
    ],
    showArticlesSectionFromDashboard: false
};
