<div class="thread-add-participant">
    <div class="modal-header">
        <h3>Add People</h3>
        <div class="fx-l-modal-header-icon" (click)="close()">
            <i class="las la-times"></i>
        </div>
    </div>
    <div modal-body>
        <div class="participant-picker">
            <div class="participant-picker-container-full-width">
                <div class="participant-picker-selector">
                    <div class="participant-picker-selector-selected">
                        <div
                            *ngFor="let user of selectedParticipants; index as i"
                            class="participant-picker-selector-pill"
                        >
                            <avatar-pill
                                [name]="user.profile?.name"
                                [id]="user.id"
                                [image]="user.id | profilePicture"
                                (crossClicked)="unselectUser(i)"
                            ></avatar-pill>
                        </div>
                        <div class="participant-picker-selector-add">
                            <input
                                #clientSearchInput
                                class="fx-form-input remove-underline"
                                placeholder="{{
                                    !selectedParticipants.length
                                        ? role === roles.Client
                                            ? 'Add another member of your company'
                                            : 'Search name or email'
                                        : ''
                                }}"
                                aria-label="Search name or email"
                                [formControl]="searchTerm"
                                analyticsClick="app_searchclients"
                                autocomplete="off"
                            />
                        </div>
                    </div>
                </div>
                <div class="participant-picker-list-container" *ngIf="searchLoader.counter || searchResults">
                    <participant-list
                        [showLoader]="!!searchLoader.counter"
                        [participants]="searchResults"
                        [showInvite]="inviteClientsEnabled && (role | permissions: 'UpdateParticipants' | async)"
                        (selectParticipant)="selectUser($event)"
                        (inviteClicked)="launchInviteClientModal()"
                    ></participant-list>
                </div>
            </div>
        </div>
        <loader-icon *ngIf="loader.counter" [color]="'light'"></loader-icon>
        <div class="participant-picker-selector-underline"></div>
        <div class="modal-footer">
            <button
                class="fx-btn--primary-outlined"
                type="button"
                (click)="launchInviteClientModal()"
            >
                Invite via email
            </button>
            <button
                *ngIf="inviteClientsEnabled && (role | permissions: 'UpdateParticipants' | async)"
                class="fx-btn--primary-outlined"
                type="button"
                (click)="addParticipants()"
                [disabled]="!selectedParticipants.length"
                [analyticsClick]="gaEvents.APP_INVITEPARTICIPANTS"
            >
                Send
            </button>
        </div>
    </div>
</div>
