<div class="banner">
    <ng-container *ngFor="let notification of notifications; let i = index; trackBy: trackNotification">
        <notification-banner 
            *ngIf="i === 0"
            [notification]="notification"
            (displayed)="bannerDisplayed.emit(notification)"
            (close)="close(notification)">
        </notification-banner>
    </ng-container>
    
</div>
