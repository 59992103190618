<loader-icon *ngIf="loader.counter" color="light"></loader-icon>

<div class="back">
    <a (click)="goBack()"><i class="las la-angle-left"></i> BACK</a>
</div>

<insights-metric-summary [tableReportRow]="metric" [report]="report"></insights-metric-summary>

<div class="scatter">
    <h2 class="title">BENCHMARK</h2>
    <h3 class="subtitle">
        <span>{{ metric?.metric?.label }}</span>
        <span> {{metric?.metric?.isHigherBetter ? "(higher is better)" : "(lower is better)"}}</span>
    </h3>
    <scatter-report
        [enterpriseId]="enterpriseId"
        [entityId]="entityId"
        [periodId]="periodId"
        [metricId]="metricId"
        [metricName]="metric?.metric?.label"
        [groupByIndex]="2"
        [sortOrder]="sortOrder"
    >
    </scatter-report>
</div>

<div class="scatter">
    <h2 class="title">TREND OVER TIME</h2>
    <h3 class="subtitle">
        <span>{{ metric?.metric?.label }}</span>
        <span> {{metric?.metric?.isHigherBetter ? "(higher is better)" : "(lower is better)"}}</span>
    </h3>
    <historical-report
        [enterpriseId]="enterpriseId"
        [entityId]="entityId"
        [periodId]="periodId"
        [row]="metric"
    >
    </historical-report>
</div>
