import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ENVIRONMENT } from "src/app/injection-token";
import { IMeetingReviewData } from "../calendar-review-meeting/calendar-review-meeting.component";
import { IInvitee, IRecurrence } from "@findex/calendar-types";
import { CalendarService } from "../../services/calendar.service";
import { ISlot } from "@findex/fx-ui/lib/components/calendar/calendar";
import { take, map, catchError, finalize } from "rxjs/operators";
import { EMPTY, Observable } from "rxjs";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import {
    ButtonType,
    IStepConfiguration
} from "projects/portal-modules/src/lib/threads-ui/components/multi-component-layout/multi-component-layout.component";
import { MultiComponentService } from "projects/portal-modules/src/lib/threads-ui/components/multi-component-layout/multi-component.service";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { IThreadCard } from "@findex/threads";
import { ParticipantCache } from "projects/portal-modules/src/lib/threads-ui/services/participant-cache.service";

export interface ICalendarModalData {
    card: IThreadCard;
    invitationId: string;
    reschedule?: boolean;
}

export interface CalendarModel {
    invitationId?: string;
    participants: IInvitee[];
    rescheduleAppointment: boolean;
    meetingDescription?: string;
    organiser: { name: string; id: string };
    duration: number;
    title: string;
    recurrence: IRecurrence;
    message: { description: string };
}

@Component({
    selector: "app-calendar-modal",
    templateUrl: "./calendar-modal.component.html",
    styleUrls: ["./calendar-modal.component.scss"]
})
export class CalendarModalComponent implements OnInit {
    readonly theme = this.environment.theme;
    slots$: Observable<ISlot[]>;
    minDate: string;
    maxDate: string;
    duration: number;
    meetingReviewData: IMeetingReviewData;
    activeStepIndex = 0;
    stepConfigurations: IStepConfiguration[] = [
        {
            stepIndex: 0,
            buttons: [
                {
                    title: "Next",
                    type: ButtonType.Forward,
                    isDisabled: true,
                    isHidden: false
                }
            ]
        },
        {
            stepIndex: 1,
            buttons: [
                {
                    title: "Calendar",
                    type: ButtonType.Backward,
                    isDisabled: false,
                    isHidden: true
                },
                {
                    title: "Yes, Book Meeting",
                    type: ButtonType.Finish,
                    isDisabled: false,
                    isHidden: true
                }
            ]
        }
    ];
    errorMessage: string;
    loader = new Loader();

    data: CalendarModel = null;

    constructor(
        @Inject(MAT_DIALOG_DATA) public props: ICalendarModalData,
        public dialogRef: MatDialogRef<CalendarModalComponent, ISlot>,
        private calendarService: CalendarService,
        private multiComponentService: MultiComponentService,
        private participantCache: ParticipantCache,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig
    ) {}

    async ngOnInit() {
        this.loader.show();
        const invite = await this.calendarService.getClientInvitation(this.props.invitationId).toPromise(); //TODO: drive from card state, update backend
        const organiser = await this.participantCache
            .getParticipant(this.props.card.createdBy)
            .pipe(take(1))
            .toPromise();

        this.data = {
            invitationId: this.props.invitationId,
            participants: [...invite.invitees, ...invite.staff],
            duration: invite.duration,
            organiser: { name: organiser.profile.name, id: organiser.id },
            rescheduleAppointment: !!this.props.reschedule,
            title: invite.details.title,
            recurrence: invite.recurrence,
            message: {
                description: invite.message.description
            }
        };

        this.maxDate = new Date(Date.now() + 60 * 24 * 60 * 60 * 1000).toISOString();
        this.minDate = await this.getStartDate().toPromise();
        this.monthChange(this.minDate);
        this.duration = this.data.duration;
        this.loader.hide();
    }

    monthChange(startDate: string | Date) {
        this.loader.show();
        this.slots$ = this.calendarService
            .checkAvailability(this.data.invitationId, new Date(startDate).toISOString())
            .pipe(
                map(availabilityResponse => availabilityResponse.slots),
                catchError(() => {
                    this.errorMessage = "Sorry, something went wrong. Please contact support.";
                    return EMPTY;
                }),
                take(1),
                finalize(() => this.loader.hide())
            );
    }

    private getStartDate(): Observable<string> {
        return this.calendarService.getClientInvitation(this.data.invitationId).pipe(
            map(invitation => {
                const today = new Date();
                if (invitation.dateRange.start) {
                    const startDate = new Date(invitation.dateRange.start);
                    if (startDate.getTime() > today.getTime()) {
                        return startDate.toISOString();
                    }
                }
                return today.toISOString();
            })
        );
    }

    handleTransition(activeStepIndex: number) {
        this.stepConfigurations = [
            ...this.multiComponentService.showCurrentStepButtons(activeStepIndex, this.stepConfigurations)
        ];
        this.activeStepIndex = activeStepIndex;
    }

    handleSelection(event: ISlot) {
        this.meetingReviewData = {
            ...this.data,
            message: this.data.message.description,
            attendees: this.data.participants,
            date: event
        };
        this.stepConfigurations = [
            ...this.multiComponentService.toggleForwardButtons(this.activeStepIndex, this.stepConfigurations, true)
        ];
    }

    onClose(confirmMeeting: boolean): void {
        if (confirmMeeting) {
            this.dialogRef.close(this.meetingReviewData?.date);
        } else {
            this.dialogRef.close();
        }
    }
}
