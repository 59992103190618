import { Component, Inject } from "@angular/core";
import { map, switchMap, takeWhile } from "rxjs/operators";
import { LoginChallenge, LoginStep, LoginStepDetails } from "../../model/LoginStep";
import { of } from "rxjs";
import { NewPasswordDialogComponent } from "../new-password-dialog/new-password-dialog.component";
import { TwoFactorAuthenticationDialogComponent } from "../two-factor-authentication-dialog/two-factor-authentication-dialog.component";
import { AuthService } from "../../services/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { ENVIRONMENT } from "../../../../../../../src/app/injection-token";
import { EnvironmentSpecificConfig } from "../../../environment/environment.common";
import { Loader } from "../../../shared/services/loader";

@Component({
    selector: "login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"]
})
export class LoginComponent {
    loader = new Loader();
    errorMessage = "";

    constructor(
        protected authService: AuthService,
        protected activatedRoute: ActivatedRoute,
        protected router: Router,
        protected dialog: MatDialog,
        @Inject(ENVIRONMENT) protected environment: EnvironmentSpecificConfig
    ) {}

    async handleLogin(emailAddress: string, password: string): Promise<void> {
        const login$ = this.authService.loginWithEmail(emailAddress, password).pipe(
            switchMap(loginStepDetails => this.handleLoginStep(loginStepDetails)),
            // We need to listen to other steps like reset password, sms mfa because those steps would need to open new dialog
            // if there is cancelled or complete event, user would need to get redirected or if it's in a dialog context, close the dialog
            takeWhile(({ step }) => ![LoginStep.LOGIN_CANCELLED].includes(step))
        );

        try {
            await this.loader.wrap(login$).toPromise();
        } catch (error) {
            this.errorMessage = error && error.message ? error.message : "Incorrect username or password";
        }
    }

    private handleLoginStep(loginStepDetails: LoginStepDetails) {
        const step =
            loginStepDetails.step === LoginStep.LOGIN_CHALLENGE ? loginStepDetails.details.type : loginStepDetails.step;
        if (step === LoginStep.PASSWORD_RESET) {
            return this.showNewPasswordDialog(loginStepDetails.details.userDetails).pipe(
                map((submitted: boolean) => {
                    if (submitted) {
                        return { step: LoginStep.LOGIN_PROCESSING };
                    } else {
                        return { step: LoginStep.LOGIN_CANCELLED };
                    }
                })
            );
        }

        if (step === LoginChallenge.smsMfa) {
            return this.show2faDialog(loginStepDetails.details.userDetails).pipe(
                map((submitted: boolean) => {
                    if (submitted) {
                        return { step: LoginStep.LOGIN_PROCESSING };
                    } else {
                        return { step: LoginStep.LOGIN_CANCELLED };
                    }
                })
            );
        }
        return of(loginStepDetails);
    }

    showNewPasswordDialog(userDetails: any) {
        const options = {
            disableClose: true,
            panelClass: ["modal-container", "mat-dialog-no-styling"],
            maxWidth: "100%",
            minWidth: "100%",
            maxHeight: "100%",
            minHeight: "100%",
            data: {
                userDetails
            }
        };

        return this.dialog.open(NewPasswordDialogComponent, options).afterClosed();
    }

    show2faDialog(userDetails: any) {
        const options = {
            disableClose: true,
            panelClass: ["modal-container", "mat-dialog-no-styling"],
            maxWidth: "100%",
            minWidth: "100%",
            maxHeight: "100%",
            minHeight: "100%",
            data: {
                userDetails
            }
        };

        return this.dialog.open(TwoFactorAuthenticationDialogComponent, options).afterClosed();
    }
}
