/* eslint-disable no-console */
import { Pipe, PipeTransform } from "@angular/core";
import { DateTime } from "luxon";

@Pipe({
    name: "timeAgo",
    pure: true
})
export class TimeAgoPipe implements PipeTransform {
    transform(date: string): string {
        const formattedRelativeTime = this.sentenceCase(DateTime.fromISO(date).toRelative());

        if (this.toShowLastMonthOrYear(formattedRelativeTime)) {
            return this.sentenceCase(formattedRelativeTime.replace("1", "last").replace("ago", "")).trim();
        }
        return formattedRelativeTime;
    }

    sentenceCase(relativeTime: string): string {
        if (!relativeTime) return relativeTime;
        return relativeTime[0].toUpperCase() + relativeTime.substring(1);
    }

    toShowLastMonthOrYear(date: string): boolean {
        if (!date) return false;

        if (
            date.includes("1") &&
            !date.includes("hour") &&
            !date.includes("hours") &&
            !date.includes("day") &&
            !date.includes("days") &&
            !date.includes("minute") &&
            !date.includes("minutes") &&
            !date.includes("second") &&
            !date.includes("seconds") &&
            !date.includes("10") &&
            !date.includes("11")
        ) {
            return true;
        }
        return false;
    }
}
