import { Component, Inject, Input, OnInit } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { NewsService } from "./services/news.service";
import { INewsArticle } from "./model/INewsArticle";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { catchError, map } from "rxjs/operators";
import { HandledError } from "projects/portal-modules/src/lib/shared/services/threads-error-handler";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";

@Component({
    selector: "news",
    templateUrl: "./news.component.html",
    styleUrls: ["./news.component.scss"]
})
export class NewsComponent implements OnInit {
    @Input() articleAmount = 1;

    article$: Observable<INewsArticle[]>;
    loader = new Loader();
    newsUrl = `${this.environment.news.sourceUrl}/${this.environment.news.allArticlePath}`;

    constructor(
        private newsService: NewsService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig
    ) {}

    ngOnInit() {
        this.article$ = this.loader.wrap(
            this.newsService.getRecentArticles().pipe(
                map(articles => {
                    if (articles && articles.length) {
                        return articles.slice(0, this.articleAmount);
                    } else {
                        return articles;
                    }
                }),
                catchError(error => throwError(new HandledError(error)))
            )
        );
    }
}
