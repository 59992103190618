<div>
    <h2>Choose your audio and video settings for this meeting</h2>
    <p>
        We record all video meetings for quality assurance purposes.
        <a *ngIf="policyUrl" [href]="policyUrl">Privacy policy.</a>
    </p>
    <div class="pre-call-session-group">
        <pre-call-session
            #session
            [name]="name"
            [publishAudio]="publishAudio"
            [publishVideo]="publishVideo"
            [audioId]="selectedAudioId"
            [visualEffect]="visualEffect"
            (onError)="handleError($event)"
        ></pre-call-session>
    </div>
    <div class="pre-call-controls-group">
        <span
            (click)="toggleAudio.emit()"
            class="pre-call-control pre-call-microphone-control"
            [ngClass]="{ 'video-control--active': publishAudio, 'video-control--inactive': !publishAudio }"
        >
            <i class="las" [ngClass]="{ 'la-microphone': publishAudio, 'la-microphone-slash': !publishAudio }"></i>
        </span>

        <span
            (click)="toggleVideo.emit()"
            class="pre-call-control pre-call-video-control"
            [ngClass]="{ 'video-control--active': publishVideo, 'video-control--inactive': !publishVideo }"
        >
            <i class="las" [ngClass]="{ 'la-video': publishVideo, 'la-video-slash': !publishVideo }"></i>
        </span>

          <span
            (click)="addVisualEffect.emit()"
            class="pre-call-control pre-call-virtual-background-control video-control--inactive"
        >
            <i class="vb-icon portal-icons-virtual-background"></i>
        </span>

        <div class="pre-call pre-call-join-call">
            <button
                class="fx-btn fx-btn--primary"
                role="button"
                (click)="joinCall.emit()"
                [disabled]="session?.videoCapture?.loading"
            >
                JOIN
            </button>
        </div>
    </div>
    <ng-container *ngIf="audioDevices$ | async; let audioDevices">
        <div class="pre-call-devices" *ngIf="audioDevices.length">
            <label class="fx-form-label">Audio Input Device:</label>
            <div class="fx-select-box">
                <select (change)="selectAudioDevice($event.target.value)">
                    <option *ngFor="let device of audioDevices" [value]="device.deviceId">{{ device.label }}</option>
                </select>
            </div>
        </div>
    </ng-container>
</div>
