import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { IThread } from "@findex/threads";

export interface ConfirmModalParams {
    confirmText?: string;
    declineText?: string;
    promptText: string;
    areYouSureText: string;
    thread: IThread;
}

@Component({
    selector: "app-upload-cancel-modal",
    templateUrl: "./confirm-modal.component.html",
    styleUrls: ["./confirm-modal.component.scss"]
})
export class ConfirmModalComponent implements OnInit {
    confirmText: string;
    declineText: string;
    promptText: string;
    areYouSureText: string;
    threads: IThread[];

    selectedThread: IThread;
    loading = true;
    error: string;
    hasUpcomingMeetings: boolean;

    constructor(@Inject(MAT_DIALOG_DATA) private data: ConfirmModalParams) {}

    ngOnInit() {
        this.confirmText = this.data.confirmText || "OK";
        this.declineText = this.data.declineText || "Cancel";
        this.promptText = this.data.promptText;
        this.areYouSureText = this.data.areYouSureText;
    }
}
