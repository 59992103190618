import { Injectable } from "@angular/core";
import { IPluginFactory } from "projects/portal-modules/src/lib/plugins";
import { Libraries } from "projects/portal-modules/src/lib/plugins/services/Libraries";
import { VcCardComponent } from "./components/vc-card/vc-card.component";
import { RecordingListComponent } from "./components/recording-list/recording-list.component";
import { Observable } from "rxjs";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { PermissionService } from "projects/portal-modules/src/lib/threads-ui/services/permissions.service";
import { filter, map, switchMap } from "rxjs/operators";
import { VideoChatAction  } from "@findex/threads";
import { vcEndSessionTaskAction, vcJoinCallTaskAction } from "./video-chat-task-action";

const LIST_RECORDINGS = "ListRecordings";
@Injectable()
export class VideoChatPlugin implements IPluginFactory {
    id = "VideoChatPlugin";

    constructor(libraries: Libraries, private authService: AuthService, private permissionService: PermissionService) {
        libraries.cardViews.register("video-chat", VcCardComponent);
        libraries.threadViews.register("video-chat", {
            label: "Recordings",
            componentRef: RecordingListComponent,
            showView$: this.getShowView()
        });

        libraries.taskActions.register(VideoChatAction.JOIN_CALL, vcJoinCallTaskAction);
        libraries.taskActions.register(VideoChatAction.END_SESSION, vcEndSessionTaskAction);
    }

    private getShowView(): Observable<boolean> {
        return this.authService.getUser().pipe(
            filter(user => !!user),
            map(user => user.globalRole),
            switchMap(role => this.permissionService.checkPermissions(role, LIST_RECORDINGS))
        );
    }
}
