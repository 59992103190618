<loader-icon *ngIf="loader.counter" [color]="'light'"></loader-icon>
<fx-layout [gutters]="false">
    <fx-layout-column [sizes]="{ mobile: 24 }" *ngIf="thread$ | async; let thread">
        <ng-container *ngIf="role$ | async; let role">
            <div class="fx-threads-title">
                <button class="fx-threads-title-button" role="button" (click)="goUp()">
                    <i class="las la-arrow-left"></i>
                </button>
                <div *ngIf="thread.accountId">
                    <a [routerLink]="['/accounts', thread.accountId]">{{ thread.account?.label }}</a>
                    <lib-edit-title [title]="thread.title" [threadId]="thread.id"></lib-edit-title>
                </div>
                <div *ngIf="!thread.accountId">{{ thread.title }}</div>
                <thread-workflow-status
                    *ngIf="thread?.workflow"
                    [workflowState]="thread?.workflow"
                    [enablePopUp]="true"
                    [role]="role"
                    [thread]="thread"
                >
                </thread-workflow-status>
            </div>
            <div class="fx-threads-tabs">
                <fx-tabs>
                    <thread-participants header-content [thread]="thread" [role]="role"></thread-participants>
                    <fx-tabs-item
                        #threadTab
                        analyticsClick="app_tabtimelines"
                        [analyticsTriggerOn]="threadTab.active"
                        header="Timeline"
                        [ngClass]="{ hide: !threadTab.active }"
                    >
                        <div #threadsContainer class="fx-threads-messages" invertScroll>
                            <thread
                                [ngClass]="{ loading: loader.counter }"
                                [thread]="thread"
                                [role]="role"
                                [routeToCardId]="cardId$ | async"
                                [excludeCardTypes]="['thread']"
                                (loadCardComplete)="scrollToBottom()"
                            ></thread>
                        </div>
                        <div class="fx-threads-new-message" *ngIf="!loader.counter">
                            <create-card
                                *ngIf="thread | isThreadActive | async"
                                [showLoader]="!!createLoader.counter"
                                [role]="role"
                                [globalRole]="globalRole$ | async"
                                (newCard)="addCard($event, thread)"
                            ></create-card>
                        </div>
                    </fx-tabs-item>

                    <fx-tabs-item
                        *ngFor="let extension of threadExtensions$ | async; trackBy: trackByExtension"
                        #extensionTab
                        analyticsClick="app_tab{{ extension.label }}"
                        [analyticsTriggerOn]="extensionTab.active"
                        [header]="extension.label"
                        [ngClass]="{ hide: !extensionTab.active }"
                    >
                        <view-extension
                            *ngIf="extensionTab.active"
                            class="fx-threads-extension"
                            [componentRef]="extension.componentRef"
                            [injector]="injector"
                        ></view-extension>
                    </fx-tabs-item>

                    <fx-context-menu
                        #menu
                        *ngIf="contextEnabled && role | permissions: 'UpdateInternalWorkflow' | async"
                        [ngSwitch]="thread | isThreadActive | async"
                    >
                        <ng-container *ngSwitchCase="true">
                            <a (click)="changeTimelineState(thread, ContextActions.close)"> Close Timeline </a>
                            <a (click)="changeTimelineState(thread, ContextActions.cancel)"> Cancel Timeline </a>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            <a (click)="changeTimelineState(thread, ContextActions.reopen)"> Reopen Timeline </a>
                            <a *ngIf="thread.notes" (click)="cancellationReason(thread)"> Cancellation Info </a>
                        </ng-container>
                    </fx-context-menu>
                </fx-tabs>
            </div>
        </ng-container>
    </fx-layout-column>
</fx-layout>
