<div class="progress-bar" [ngClass]="{ 'progress-bar--show-step-text': !!stepText?.length }">
    <div class="progress-bar-outer">
        <div [style.width]="(progress || 0) + '%'" id="progress-fill-bar"></div>
    </div>
    <div class="progress-bar-step-text-container" *ngIf="!!stepText?.length">
        <!-- innerHTML here to allow for text styling, design had <b></b> around date -->
        <div
            *ngFor="let step of stepText"
            class="progress-bar-step-text"
            [class.progress-bar-step-text--highlighted]="step.highlighted"
            [innerHTML]="step.text"
        ></div>
    </div>
</div>
