<sidebar-modal-layout
    (close)="onClose(false)"
    [contentGutters]="false"
    header="Schedule meeting"
    [headerDescription]="data.meetingDescription"
>
    <div modal-body>
        <div class="calendar-modal-content">
            <loader-icon *ngIf="loader.counter" [color]="'light'"></loader-icon>
            <div *ngIf="calendarError !== ''" class="calendar-modal-error">{{ calendarError }}</div>

            <div *ngIf="selectedStaff" class="calendar-modal-participant">
                <fx-avatar
                    [name]="selectedStaff.name"
                    size="medium"
                    [image]="selectedStaff.userId | profilePicture"
                ></fx-avatar>
                <div class="calendar-modal-participant-user">
                    <div class="calendar-modal-participant-user-name">
                        {{ selectedStaff.name }}
                    </div>
                    <div class="calendar-modal-participant-user-title">
                        {{ selectedStaff.title }}
                    </div>
                </div>
            </div>

            <fx-calendar
                [slots]="slots"
                [minDate]="minDate"
                [maxDate]="maxDate"
                (dateTimeSelected)="handleCalendarEvent($event)"
                (monthChange)="monthChange($event)"
            >
            </fx-calendar>
        </div>
    </div>
    <div modal-footer>
        <button
            class="fx-btn fx-btn--full-width"
            [ngClass]="['fx-btn--primary' + (theme.formSubmitActionOutlined ? '-outlined' : '')]"
            type="submit"
            [disabled]="!selectedDate"
            (click)="onClose(true)"
        >
            Confirm
        </button>
    </div>
</sidebar-modal-layout>
