import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";
import { CreateRequestComponent } from "../create-request/create-request.component";

import { ICreateRequestMenu, ICreateRequestMenuItem } from "../../../types/CreateRequest";
import { IThread } from "@findex/threads";
import { AnalyticsService, GA_EVENTS_PREFIX } from "projects/portal-modules/src/lib/analytics";
import { WorkflowService } from "projects/portal-modules/src/lib/threads-ui/services/workflow.service";

@Component({
    selector: "app-pre-create-request",
    templateUrl: "./pre-create-request.component.html",
    styleUrls: ["./pre-create-request.component.scss"]
})
export class PreCreateRequestComponent implements OnInit {
    readonly createRequestConfiguration = this.environment.featureFlags?.createRequestConfiguration;
    readonly ANALYTICS_PREFIX = GA_EVENTS_PREFIX.RFI_CREATE_REQUEST;

    currentTemplateSelection: ICreateRequestMenuItem;
    currentActionSelection: ICreateRequestMenu;
    menuOptions: ICreateRequestMenu[];

    constructor(
        private dialog: MatDialog,
        private dialogRef: MatDialogRef<PreCreateRequestComponent>,
        private analytics: AnalyticsService,
        private workflowService: WorkflowService,
        @Inject(MAT_DIALOG_DATA) private data: { thread: IThread },
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig
    ) {}

    async ngOnInit() {
        this.menuOptions = await this.filterTemplatesByTimeline();

        const hasTemplatesDefinitions = this.menuOptions.some(val => val.templates?.length);

        if (!hasTemplatesDefinitions) {
            this.currentActionSelection = this.menuOptions[0];
            this.openCreateModal();
        }
    }

    selectionActionType(value: any): void {
        this.currentTemplateSelection = null;
        if (value !== this.currentActionSelection) {
            this.currentActionSelection = value;
            this.analytics.recordEvent(
                "mouse-click",
                `${this.ANALYTICS_PREFIX}_${this.currentActionSelection?.templates ? "template" : "blank"}`
            );
        } else {
            this.currentActionSelection = null;
        }
    }

    templateSelection($event: any): void {
        if (!$event) {
            this.currentTemplateSelection = null;
        } else {
            this.currentTemplateSelection = $event;
        }
    }

    close(): void {
        this.dialogRef.close();
    }

    openCreateModal(): void {
        this.dialogRef.close();
        this.analytics.recordEvent("mouse-click", this.buildAnalyticsTemplate());
        this.dialog.open(CreateRequestComponent, {
            panelClass: ["centered-modal"],
            width: "761px",
            data: {
                template: this.currentTemplateSelection?.template || null,
                thread: this.data.thread
            },
            disableClose: true
        });
    }

    private sanitizeString(val: string): string {
        return val.replace(/\s/g, "_").toLowerCase();
    }

    private buildAnalyticsTemplate(): string {
        const selection = this.currentActionSelection;
        const templateSelection = this.currentTemplateSelection;

        if (!templateSelection) {
            return `${this.ANALYTICS_PREFIX}_template-selected_${this.sanitizeString(selection.title)}`;
        } else {
            return `${this.ANALYTICS_PREFIX}_template-selected_${this.sanitizeString(templateSelection.title)}`;
        }
    }

    private async filterTemplatesByTimeline(): Promise<ICreateRequestMenu[]> {
        const { id: workflowId } = await this.workflowService.resolveWorkflow(this.data.thread.workflow);
        return this.createRequestConfiguration.map(options =>
            options?.templates
                ? {
                      ...options,
                      templates: options.templates.filter(val => val.type.some(type => type === workflowId))
                  }
                : options
        );
    }
}
