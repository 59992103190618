<div class="thread-workflow-popup">
    <workflow-step-list
        *ngIf="workflowState"
        [workflowState]="workflowState"
        [role]="role"
        (save)="saveState(thread, $event)"
        (editDates)="editDates(thread)"
    ></workflow-step-list>

    <loader-icon *ngIf="!workflowState || !!loader.counter" [showBackground]="true"></loader-icon>
</div>
