<div class="modal-header" role="heading">
    <h2>Choose background</h2>
    <div class="close" role="button" (click)="matDialogRef.close()" title="Close">
        <i class="las la-times"></i>
    </div>
</div>

<div class="effects-wrapper" role="main">
    <div class="column backgrounds-container" role="grid">
        <div
            role="gridcell"
            id="none-background"
            (click)="selectBackground(backgroundNone)"
            [ngClass]="isActive(backgroundNone) ? 'vb-item-active' : ''"
            class="vb-item-container">
            <div class="v-bg-none">
                <img src="assets/images/none_background.png" width="50%" />
            </div>
        </div>
        <div
            role="gridcell"
            id="blur-background"
            (click)="selectBackground(backgroundBlur)"
            [ngClass]="isActive(backgroundBlur) ? 'vb-item-active' : ''"
            class="vb-item-container">
            <div class="v-bg-blur">
                <img src="assets/images/vb-blur.jpg" width="50%" />
            </div>
        </div>
        <div
            role="gridcell"
            [id]="'image-background-' + background.name"
            (click)="selectBackground(background)"
            [ngClass]="isActive(background) ? 'vb-item-active' : ''"
            class="vb-item-container"
            *ngFor="let background of presetBackgrounds">
            <div
                [class]="'v-effect-img v-bg-' + background.type"
                [style.background-image]="'url(' + background.src + ')'"></div>
            <span class="background-label" *ngIf="background.type !== 'image'">{{ background.type | uppercase }}</span>
        </div>
    </div>

    <button class="fx-btn fx-btn fx-btn--primary" (click)="applyEffect()">Apply effect</button>
</div>
