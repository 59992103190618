<div class="card-item" [ngSwitch]="canRename || canDelete">
    <div *ngIf="!renameMode" class="display-name">
        <div class="file-icon">
            <i class="las" [ngClass]="icon"></i>
        </div>

        <div class="filename" (click)="downloadClicked()">
            {{ displayName }}
        </div>
    </div>

    <inline-edit *ngIf="renameMode" [text]="item.displayName" (updated)="renameItem($event)"></inline-edit>

    <ng-container *ngSwitchDefault>
        <ng-container *ngIf="item.actions.includes(vaultActions.Preview) && !!item.previewItem">
            <button
                class="fx-btn fx-btn--primary-outlined view-preview-button"
                type="button"
                analyticsClick="app_opendocumentpreview"
                (click)="preview.emit()"
            >
                View Preview
            </button>
        </ng-container>

        <ng-container *ngIf="!item.signed && item.actions.includes(vaultActions.Sign)">
            <signature-icon
                [disabled]="disabled"
                (click)="sign.emit()"
                [analyticsClick]="gaEvents.APP_SIGNDOCUMENT"
                *ngIf="canSign"
                theme="light"
            >
                Sign
            </signature-icon>

            <signature-icon *ngIf="!canSign" theme="dark" [cursor]="false">Pending</signature-icon>
        </ng-container>

        <signature-icon *ngIf="item.signed" theme="dark" [cursor]="true">Signed</signature-icon>
    </ng-container>

    <ng-container *ngIf="!renameMode">
        <fx-context-menu [gutter]="false" *ngSwitchCase="true">
            <a *ngIf="canRename" (click)="renameMode = true" analyticsClick="app_vault_file_rename">Rename</a>
            <a *ngIf="canDelete" (click)="delete.emit()" analyticsClick="app_vault_file_delete">Delete</a>
        </fx-context-menu>
    </ng-container>
</div>
