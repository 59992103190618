import { Component, Inject, Input, Output, EventEmitter, SimpleChanges, OnInit, OnChanges } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CalendarService, ISlot } from "../../services/calendar.service";
import { ENVIRONMENT } from "src/app/injection-token";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";

export interface ISlotPreview {
    title: string;
    slots: any;
}

@Component({
    selector: "app-calendar-choose-time",
    templateUrl: "./calendar-choose-time.component.html",
    styleUrls: ["./calendar-choose-time.component.scss"]
})
export class CalendarChooseTimeComponent implements OnInit, OnChanges {
    readonly theme = this.environment.theme;
    @Input() slots: ISlot[];
    @Input() minDate: string;
    @Input() maxDate: string;
    @Input() duration: number;
    @Output() emitSelection = new EventEmitter();
    @Output() emitMonthSelection = new EventEmitter();
    loader = new Loader();
    selectedDate: ISlot;
    slotPreviews: ISlotPreview[];
    start: string;
    end: string;
    calendarError: string;
    timezoneSubtitle: string;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public dialogRef: MatDialogRef<CalendarChooseTimeComponent>,
        private calendarService: CalendarService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig
    ) {
        this.selectedDate = null;
        this.loader.show();
    }

    ngOnInit(): void {
        this.timezoneSubtitle = this.calendarService.getTimezoneSubtitle();
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes?.slots?.currentValue) {
            this.slotPreviews = this.calendarService.createSlotPreviews(changes.slots.currentValue);
            this.loader.hide();
        }
    }

    handleCalendarEvent(event: ISlot): void {
        if (!event) {
            this.selectedDate = null;
            return;
        }

        this.start = event.start || null;
        this.end = event.end || null;

        if (event.start && event.end) {
            this.selectedDate = { start: this.start, end: this.end };
            this.emitSelection.emit(this.selectedDate);
        } else {
            this.selectedDate = null;
        }
    }
}
