import { Component, Inject } from "@angular/core";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";

@Component({
    selector: "app-match-loader",
    templateUrl: "./match-loader.component.html",
    styleUrls: ["./match-loader.component.scss"]
})
export class MatchLoaderComponent {
    readonly textResource = this.environment.featureFlags?.text;

    constructor(@Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig) {}
}
