<loader-icon *ngIf="loader.counter" color="light"></loader-icon>
<div class="create-invitation">
    <div class="create-invitation-inner">
        <div class="create-invitation-details">
            <form class="create-invitation-form-container" [formGroup]="form">
                <div class="create-invitation-details-title">
                    <label class="fx-form-label" for="title"></label>
                    <div class="fx-form-control">
                        <input
                            fxAutoFocus
                            class="fx-form-input create-invitation-details-title-text light-border"
                            formControlName="title"
                            placeholder="Add title"
                            [attr.disabled]="editAttendees"
                            [ngClass]="{
                                'fx-form-input--error': !form.controls['title'].valid && form.controls['title'].touched
                            }"
                        />
                    </div>
                    <div
                        class="fx-form-validation brand-danger"
                        *ngIf="form.controls['title'].hasError('required') && form.controls['title'].touched"
                    >
                        Must add a title.
                        <i class="fx-form-validation-icon las la-exclamation-triangle brand-danger"></i>
                    </div>
                </div>
                <div class="create-invitation-details-attendees">
                    <div class="create-invitation-details-attendees-icon">
                        <i class="las la-user icon-size"></i>
                    </div>
                    <div class="create-invitation-details-attendees-text">
                        <participant-picker
                            [enableOptionalParticipants]="editAttendees"
                            [disableRequired]="editAttendees"
                            [selectedParticipants]="attendees"
                            [participants]="participants"
                            (selectedParticipantOutput)="selectedParticipants($event)"
                        ></participant-picker>
                        <ng-container *ngIf="showValidationErrors">
                            <div class="fx-form-validation brand-danger" *ngIf="attendees.length < 2">
                                Must add at least 2 attendees.
                                <i class="fx-form-validation-icon las la-exclamation-triangle brand-danger"></i>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="create-invitation-details-duration">
                    <div class="create-invitation-details-duration-icon">
                        <i class="las la-history icon-size"></i>
                    </div>
                    <div class="create-invitation-details-duration-text">
                        <label class="fx-form-label">Meeting Duration</label>
                        <div class="create-invitation-details-duration-button-row">
                            <button
                                class="fx-btn fx-btn--primary-outlined create-invitation-details-duration-button"
                                *ngFor="let timeSlot of timeSlots"
                                [disabled]="editAttendees"
                                [ngClass]="{ selected: meetingDuration === timeSlot.durationMinutes }"
                                analyticsClick="calendar_settime"
                                [analyticsLabel]="timeSlot.description"
                                (click)="meetingDuration = timeSlot.durationMinutes; emitChanges()"
                            >
                                {{ timeSlot.description }}
                            </button>
                        </div>
                    </div>
                </div>

                <div class="create-invitation-form-group">
                    <div class="fx-form-col create-invitation-details-recurrence-options">
                        <div class="fx-col-row">
                            <div class="create-invitation-details-recurrence-icon">
                                <i class="las la-sync icon-size"></i>
                            </div>
                            <select
                                placeholder="Repeat"
                                aria-label="Select Recurrence Type"
                                class="fx-form-select light-border"
                                formControlName="recurrenceType"
                                [attr.disabled]="editAttendees"
                            >
                                <option *ngFor="let option of recurrenceOptions" [value]="option.value">
                                    {{ option.name }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="create-invitation-form-group-col create-invitation-details-recurrence-number">
                        <div
                            class="create-invitation-form-group-row create-invitation-details-recurrence-input"
                            *ngIf="form.controls.recurrenceType.value !== 'never'"
                        >
                            <label for="numberOfOccurrences" class="fx-form-label">Number of occurrences</label>
                            <input
                                type="number"
                                formControlName="numberOfOccurrences"
                                class="fx-form-input light-border"
                                [attr.disabled]="editAttendees"
                                [ngClass]="{
                                    'fx-form-input--error':
                                        !form.controls.numberOfOccurrences.valid &&
                                        form.controls.numberOfOccurrences.touched
                                }"
                            />
                        </div>
                        <div class="fx-form-validation brand-danger" *ngIf="!isValidOccurrence">
                            Must be between 2 and 10.
                            <i class="fx-form-validation-icon las la-exclamation-triangle brand-danger"></i>
                        </div>
                    </div>
                </div>
                <div class="create-invitation-details-description">
                    <div class="create-invitation-details-description-icon">
                        <i class="las la-comment icon-size"></i>
                    </div>
                    <div class="create-invitation-details-description-text">
                        <div class="fx-form-control">
                            <div
                                class="create-invitation-details-description-border     "
                                [ngClass]="{
                                    'fx-form-input--error':
                                        !form.controls.meetingDescription.valid &&
                                        form.controls.meetingDescription.touched
                                }"
                            >
                                <quill-editor-wrapper
                                    [message]="$any(form.controls.meetingDescription)"
                                    [readOnly]="editAttendees"
                                    (keydown)="$event.stopPropagation()"
                                    (error)="quillError = $event"
                                ></quill-editor-wrapper>
                            </div>
                        </div>
                        <div
                            class="fx-form-validation brand-danger"
                            *ngIf="
                                !form.controls.meetingDescription.valid &&
                                form.controls.meetingDescription.touched &&
                                form.controls.meetingDescription.value &&
                                !quillError
                            "
                        >
                            Your agenda should be maximum of {{ textAreaCharacterLimit }} characters
                            <i class="fx-form-validation-icon las la-exclamation-triangle brand-danger"></i>
                        </div>
                        <div
                            class="fx-form-validation brand-danger"
                            *ngIf="
                                !form.controls.meetingDescription.valid &&
                                form.controls.meetingDescription.touched &&
                                !form.controls.meetingDescription.value
                            "
                        >
                            Please provide an agenda
                            <i class="fx-form-validation-icon las la-exclamation-triangle brand-danger"></i>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
