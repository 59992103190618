import { Inject, Pipe, PipeTransform } from "@angular/core";
import matchUrl from "match-url-wildcard";
import { ENVIRONMENT } from "src/app/injection-token";
import { EnvironmentSpecificConfig } from "../../environment/environment.common";

@Pipe({ name: "allowWhitelistLinks" })
export class AllowWhitelistLinksPipe implements PipeTransform {
    readonly whitelistedUrls = this.environment.whitelistedUrls || [];

    constructor(@Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig) {}

    transform(urlString: string): string {
        /* This regex matches against any link eg. http(s)://... */
        const urlRegex = /((https?):\/\/)?([\w-]+(\.[\\w-]+)*\.([a-z]+))(([\w.,@?^=%&amp;:\/~+#()!-]*)([\w@?^=%&amp;\/~+#()!-]))?/gi;

        const replacer = (match: string): string => {
            /* Wrap whitelisted URLs in anchor tags */
            const isWhitelisted = this.whitelistedUrls.some(rule => matchUrl(match, rule));

            if (isWhitelisted) {
                const anchor = match.startsWith("http://") || match.startsWith("https://") ? match : `https://${match}`;

                return `<a href="${anchor}" target="_blank" rel="noopener noreferrer">${match}</a>`;
            } else {
                return match;
            }
        };

        return urlString.replace(urlRegex, replacer);
    }
}
