<app-card class="upcoming-meeting-details-container" @fadeIn *ngIf="user$ | async as user">
    <div class="upcoming-meeting-details">
        <div class="upcoming-meeting-details-top">
            <div class="upcoming-meeting-details-info">
                <p *ngIf="upcomingMeeting?.accountLabel" class="upcoming-meeting-details-account-label">{{ upcomingMeeting.accountLabel }}</p>
                <p class="upcoming-meeting-details-info-title">{{ upcomingMeeting.title }}</p>
                <div *ngIf="upcomingMeeting?.nextInstance?.start" class="upcoming-meeting-details-time">
                    <i class="las la-calendar"></i>
                    {{ upcomingMeeting.nextInstance.start | date: dateFormat.long }} -
                    {{ upcomingMeeting.nextInstance.end | date: dateFormat.short }}
                </div>

                <a
                    class="upcoming-meeting-details-link"
                    (click)="navigateToThread()"
                    [analyticsClick]="GA_EVENTS.DASHBOARD_VIEW_MEETING_DETAILS"
                    >View Details</a
                >
            </div>

            <div class="upcoming-meeting-details-participants">
                <fx-avatar-multi *ngIf="(avatars$ | async)?.length; else createdByAvatar" [content]="avatars$ | async">
                </fx-avatar-multi>
                <ng-template #createdByAvatar>
                    <fx-avatar
                        [name]="(createdBy$ | async)?.name"
                        size="medium"
                        [image]="(createdBy$ | async)?.id | profilePicture"
                    ></fx-avatar>
                </ng-template>
            </div>
        </div>

        <div
            *ngIf="(meetingIsImminent$ | async) && !upcomingMeeting?.hasEnded"
            class="upcoming-meeting-details-join-meeting"
        >
            <p *ngIf="timeTillMeetingBegins > 1000 * 60" class="upcoming-meeting-details-join-meeting-info">
                Starting in {{ timeTillMeetingBegins | date: "m" }}mins
            </p>
            <p *ngIf="timeTillMeetingBegins <= 0" class="upcoming-meeting-details-join-meeting-info">
                Meeting about to begin
            </p>
            <button
                class="upcoming-meeting-details-join-meeting-button"
                [ngClass]="['fx-btn--primary' + (theme.formSubmitActionOutlined ? '-outlined' : '')]"
                (click)="joinMeeting()"
                analyticsClick="dashboard_quickjoinmeeting"
            >
                JOIN NOW
            </button>
        </div>
    </div>
</app-card>
