<div class="fx-onboarding-modal">
    <div class="fx-onboarding-modal-image">
        <img src="./assets/images/onboarding-modal/sigma-industry.svg" />
    </div>
    <div class="fx-onboarding-modal-content">
        <h1 class="fx-onboarding-modal-title">Select relevant industries for your business</h1>
        <div class="industry-type-selection">
            <app-industry-type-selection
                maxHeight="275px"
                minHeight="275px"
                [selectedIndustryTypes]="selectedIndustryTypes"
                (modelUpdated)="updateParentModel($event)"
                (modelValidated)="updateParentValidation($event)"
            >
            </app-industry-type-selection>
            <button
                class="fx-btn fx-btn--full-width fx-btn--primary-outlined industry-type-button"
                [disabled]="!isValid"
                (click)="progressState.emit()"
                analyticsClick="onboarding_industrynext"
            >
                Continue
            </button>
        </div>
    </div>
</div>
