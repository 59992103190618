import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ThreadsService } from "projects/portal-modules/src/lib/threads-ui/services/threads.service";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { IThread, IThreadWorkflowStep, Role } from "@findex/threads";
import { IThreadWorkflowGroup } from "../../../services/workflow.service";
import { AnalyticsService, GA_EVENTS_PREFIX } from "projects/portal-modules/src/lib/analytics";
import { MatDialog } from "@angular/material/dialog";
import { EditThreadDatesComponent, EditThreadDatesData } from "../../edit-thread-dates/edit-thread-dates.component";

@Component({
    selector: "threads-state",
    templateUrl: "./threads-state-modal.component.html",
    styleUrls: ["./threads-state-modal.component.scss"]
})
export class ThreadsStateModalComponent {
    role: Role;
    roles = Role;
    threads: IThread[];
    groupedThreads: IThreadWorkflowGroup[];

    constructor(
        @Inject(MAT_DIALOG_DATA)
        data: {
            groupedThreads: IThreadWorkflowGroup[];
            role: Role;
        },
        private dialog: MatDialog,
        private dialogRef: MatDialogRef<ThreadsStateModalComponent>,
        private threadsService: ThreadsService,
        public loader: Loader,
        private analyticsService: AnalyticsService
    ) {
        this.groupedThreads = data.groupedThreads;
        this.role = data.role;
    }

    close() {
        this.dialogRef.close();
    }

    editDates(thread: IThread) {
        const config = {
            data: { thread },
            panelClass: ["centered-modal"],
            disableClose: true
        };

        this.dialog.open<EditThreadDatesComponent, EditThreadDatesData>(EditThreadDatesComponent, config);
    }

    async saveState(thread: IThread, toStep: IThreadWorkflowStep) {
        this.loader.show();

        try {
            await this.threadsService.saveWorkflowState(thread, toStep.clientFacingId);
            this.analyticsService.recordEvent(GA_EVENTS_PREFIX.THREADS, toStep.internalId);
        } finally {
            this.loader.hide();
            this.dialogRef.close();
        }
    }
}
