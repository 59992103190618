<div class="report">
    <ng-container *ngIf="selectedReport">
        <div class="report-header">
            <p class="report-header-account">
                {{ selectedReport?.account?.name ? selectedReport?.account?.name + " — " : "" }}
            </p>
            <p class="report-header-filename">{{ selectedReport.file.displayName }}</p>
        </div>
        <div *ngIf="selectedVaultFile && !loading" class="report-display">
            <file-preview class="report-display-viewer" [file]="selectedVaultFile" [vaultId]="selectedReport.vaultId">
                <div class="report-display-error">
                    <i class="info-icon las la-info-circle"></i>
                    <div class="report-display-error-header">This report can’t be displayed</div>
                    <div class="report-display-error-message">
                        Something went wrong that has prevented the report from displaying, but you can still download
                        it below.
                    </div>
                    <button
                        [analyticsClick]="gaEvents.INSIGHTS_REPORTBUTTONDOWNLOAD"
                        class="fx-btn fx-btn--primary report-display-error-download"
                        (click)="downloadReport.emit(selectedReport)"
                    >
                        Download Report
                    </button>
                </div>
            </file-preview>
        </div>
    </ng-container>
</div>
