import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { AuthorizationLevel } from "projects/portal-modules/src/lib/findex-auth/model/AuthorizationLevel";
import { AppUser, AuthStrategy } from "projects/portal-modules/src/lib/findex-auth/model/AppUser";
import { environmentCommon, EnvironmentSpecificConfig } from "../../environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";

@Injectable()
export class InjectableUser extends AppUser {}

export interface UserListingEntry {
    id: string;
    name: string;
    emailAddress: string;
    mobileNumber: string;
    details?: {
        givenName: string;
        familyName: string;
        mobileNumber: string;
        emailAddress: string;
        lastLoginTimestamp: string;
        createdTime: string;
    };
}

@Injectable({ providedIn: "root" })
export class AdminService {
    constructor(private httpClient: HttpClient, @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig) {}

    searchClients(searchName: string): Observable<AppUser[]> {
        const params = {
            name: searchName
        };

        const { base } = this.environment.threadsEndpoints;
        const { users } = environmentCommon.threadsEndpoints;
        const url = `${base}${users}`;

        return this.httpClient
            .get<UserListingEntry[]>(url, { params })
            .pipe(
                map((userListingEntries: UserListingEntry[]): AppUser[] =>
                    userListingEntries.map(
                        (userListingEntry: UserListingEntry): AppUser => ({
                            id: userListingEntry.id,
                            name: userListingEntry.name,
                            authorizationLevel: AuthorizationLevel.UNKNOWN,
                            emailAddressVerified: undefined,
                            mobileNumberVerified: undefined,
                            type: AuthStrategy.Cognito,
                            details: {
                                givenName: userListingEntry.details.givenName,
                                familyName: userListingEntry.details.familyName,
                                emailAddress: userListingEntry.emailAddress || userListingEntry.details.emailAddress,
                                mobileNumber: userListingEntry.mobileNumber || userListingEntry.details.mobileNumber,
                                lastLoginTimestamp: userListingEntry.details.lastLoginTimestamp,
                                createdTime: userListingEntry.details.createdTime
                            },
                            globalRole: null
                        })
                    )
                )
            );
    }

    listStaff(): Observable<AppUser[]> {
        //TODO when we have staff authentication
        // const params = {
        //     type: "staff"
        // };
        // const url = `${AUTH_CONFIG.url}${AUTH_CONFIG.endpoints.user}`;
        // return this.httpClient
        //     .get<{ users: User[] }>(url, { params })
        //     .pipe(map(response => response.users));
        return of([]);
    }
}
