<div class="subscription">
    <ng-container *ngIf="packageDisplayName">
        <h2 class="subscription-header">{{ packageDisplayName | titlecase }}</h2>
    </ng-container>

    <fx-layout [columns]="24" [gutters]="false">
        <ng-container *ngIf="customer$ | async; let customer; else: loading">
            <app-payments-subscriptions
                *ngIf="customer"
                [userPaymentDetails]="customer"
                [paymentMethods]="paymentMethods$ | async"
                [hidePaymentMethods]="!(showPaymentMethods$ | async)"
                [packageDisplayName]="packageDisplayName"
                (updatePaymentMethods)="initPaymentSubscriptions()"
            ></app-payments-subscriptions>
        </ng-container>
        <ng-template #loading>
            <loader-icon *ngIf="loader.counter" [static]="true" color="light"></loader-icon>
            <span *ngIf="!loader.counter">No active subscriptions.</span>
        </ng-template>
    </fx-layout>
</div>
