import { Injectable, Pipe, PipeTransform } from "@angular/core";
import { IThreadListing } from "@findex/threads";
import { environmentCommon } from "../../environment/environment.common";
import { IThreadGroupListing } from "../components/thread-list/thread-grouper";

@Injectable({ providedIn: "root" })
@Pipe({ name: "threadsStatusSearch", pure: true })
export class ThreadsStatusSearchPipe implements PipeTransform {
    constructor() {}

    transform(groups: IThreadGroupListing[], stateId: string): IThreadGroupListing[] {
        if (!stateId || !groups) return groups;
        return groups.map(group => {
            return {
                ...group,
                children: group.children.filter(thread => this.searchThread(thread, stateId))
            }
        });
    }

    private searchThread(thread: IThreadListing, stateId: string): boolean {
        const threadContains = this.isThreadInState(thread, stateId);
        return threadContains;
    }

    isThreadInState(thread: Partial<IThreadListing>, stateId: string): boolean {
        const states = environmentCommon.workflow.states;
        const notActiveStates = [states.cancelled, states.closed];

        const step = thread.workflow?.steps?.find(step => step.isCurrentStep);
        const currentId = step?.clientFacingId || step?.internalId;

        if (!currentId) {
            return false;
        }

        if (stateId === "active") {
            return !notActiveStates.includes(currentId);
        }
        
        return currentId === stateId;
    }
}
