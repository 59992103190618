import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "truncate", pure: true })
// @ts-ignore
export class TruncatePipe implements PipeTransform {
    transform(value: string, limit = 16, completeWords = false, ellipsis = "...") {
        if (!value) {
            return "";
        }
        if (completeWords) {
            limit = value.substr(0, limit).lastIndexOf(" ");
        }
        return value.length > limit ? value.substr(0, limit) + ellipsis : value;
    }
}
