import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { IVaultItem } from "../../../interfaces/IVaultItem";
import { IVaultItemFile } from "../../../interfaces/IVaultItemFile";
import { Role } from "@findex/threads";
import { VaultService } from "@findex/vault";
import { HttpClient } from "@angular/common/http";
import { switchMap } from "rxjs/operators";
import { Observable, AsyncSubject } from "rxjs";
import { GA_EVENTS, GA_EVENTS_PREFIX } from "projects/portal-modules/src/lib/analytics";

export enum RFI_TYPES {
    TextInput = "vault/rfi/text-input",
    Document = "vault/rfi/document"
}
@Component({
    selector: "upload-item",
    templateUrl: "./upload-item.component.html",
    styleUrls: ["./upload-item.component.scss"]
})
export class UploadItemComponent implements OnInit {
    readonly ANALYTICS_PREFIX = GA_EVENTS_PREFIX.RFI;
    readonly gaEvents = GA_EVENTS;
    readonly roles = Role;
    readonly rfiTypes = RFI_TYPES;

    toggleMenu: boolean;

    @Output() uploadFile = new EventEmitter<File>();
    @Output() deleteFile = new EventEmitter<IVaultItemFile>();
    @Output() openFile = new EventEmitter<IVaultItemFile>();

    @Input() expandInitial: boolean;
    @Input() role: Role;
    @Input() closed: boolean;
    @Input() item: IVaultItem;
    @Input() error?: string;

    private textContentMap: { [key: string]: Observable<string> };

    constructor(private vaultService: VaultService, private http: HttpClient) {
        this.textContentMap = {};
    }

    ngOnInit() {}

    closeMenu() {
        this.toggleMenu = false;
    }

    cancel(file: IVaultItemFile) {
        if (file.uploadSub) {
            file.uploadSub.unsubscribe();
            file.uploadSub = null;

            const index = this.item.files.indexOf(file);
            if (index < 0) return;

            this.item.files.splice(index, 1);
        } else {
            this.deleteFile.emit(file);
        }
    }

    trackFile(_index: number, file: IVaultItemFile) {
        return file.filename;
    }

    getTextContent(item: IVaultItem, file: IVaultItemFile) {
        const key = `${item.vaultId}-${item.fileId}-${file.filename}`;
        if (!this.textContentMap[key]) {
            this.textContentMap[key] = this.vaultService.getDownloadUrl(item.vaultId, item.fileId, file.filename).pipe(
                switchMap(url => this.http.get(url, { responseType: "blob" })),
                switchMap((blob: Blob) => {
                    // unfortunately blob.text() isn't widely supported (notably by Safari), so we have to use FileReader
                    const fileReader = new FileReader();
                    const callbackSubject = new AsyncSubject<string>();
                    fileReader.readAsText(blob);
                    fileReader.onload = () => {
                        callbackSubject.next(fileReader.result as string);
                        callbackSubject.complete();
                    };
                    return callbackSubject.asObservable();
                })
            );
        }
        return this.textContentMap[key];
    }
}
