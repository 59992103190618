import { Component, Output, EventEmitter, Input } from "@angular/core";

@Component({
    selector: "sidebar-modal-layout",
    templateUrl: "./sidebar-modal-layout.component.html",
    styleUrls: ["./sidebar-modal-layout.component.scss"]
})
export class SidebarModalLayoutComponent {
    @Input() contentGutters: boolean;
    @Input() stickyFooter: boolean;
    @Input() header: string;
    @Input() subHeader: string;
    @Input() headerDescription: string;
    @Input() customHeader: string | boolean;
    @Input() footer = true;

    @Output() close = new EventEmitter<void>();
}
