import { Component, Inject, Injector, Input, OnChanges, SimpleChanges } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { NotificationsService } from "@findex/notifications-angular";
import { ICardTaskDetail, IThreadListing, Role } from "@findex/threads";
import { GA_EVENTS, HOT_JAR_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { EnvironmentSpecificConfig, taskChannelPrefix } from "projects/portal-modules/src/lib/environment/environment.common";
import { TaskNotificationsService } from "projects/portal-modules/src/lib/notifications";
import { ILibrary, TaskAction } from "projects/portal-modules/src/lib/plugins";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { IEnrichedThreadListing } from "projects/portal-modules/src/lib/threads-ui/components/threads-list-route/threads-list-route.component";
import { THREAD_CARD_RESOURCES } from "projects/portal-modules/src/lib/threads-ui/interfaces/IUiCard";
import { PermissionService } from "projects/portal-modules/src/lib/threads-ui/services/permissions.service";
import { ThreadCardService } from "projects/portal-modules/src/lib/threads-ui/services/thread-card.service";
import { UiCardService } from "projects/portal-modules/src/lib/threads-ui/services/ui-card.service";
import { concat, EMPTY, Observable, of, ReplaySubject, Subject, forkJoin } from "rxjs";
import { map, shareReplay, switchMap } from "rxjs/operators";
import { ENVIRONMENT, TASK_ACTION_LIBRARY } from "src/app/injection-token";
import { DASHBOARD_THREAD_TASK_DATA, ThreadsTaskComponent } from "../threads-task/threads-task.component";

export type ThreadTaskComponentExtension = ICardTaskDetail & {
    injector: Injector;
};
export interface IThreadListingWithTasks extends IThreadListing {
    hideRowChild$: Observable<boolean>;
    tasks$: Observable<ThreadTaskComponentExtension[]>;
}

type ThreadTaskMap = { [threadId: string]: ICardTaskDetail[]; };

@Component({
    selector: "dashboard-threads",
    templateUrl: "./dashboard-threads.component.html",
    styleUrls: ["./dashboard-threads.component.scss"]
})
export class DashboardThreadsComponent implements OnChanges {
    readonly gaEvents = GA_EVENTS;
    readonly hotJarEvents = HOT_JAR_EVENTS;
    readonly hideStatusCol = this.environment.featureFlags.hideDashboardThreadsStatusCol;
    threadTaskComponentClass = ThreadsTaskComponent;

    @Input() threads: IEnrichedThreadListing[];
    @Input() role: Role;

    loader = new Loader();
    tableData = new MatTableDataSource<IThreadListingWithTasks>();

    loading: boolean;
    threadTasks$: Observable<ThreadTaskMap>;

    private canViewDashboardTasks$: Observable<boolean>;

    constructor(
        @Inject(TASK_ACTION_LIBRARY) private taskActionLibrary: ILibrary<TaskAction<void>>,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private taskNotificationService: TaskNotificationsService,
        private notificationService: NotificationsService,
        private injector: Injector,
        private uiCardService: UiCardService,
        private permissionService: PermissionService,
        private cardService: ThreadCardService
    ) {}

    private getThreadTasks() {
        const tasks$ = this.loader.wrap(this.taskNotificationService.getCardTasks());

        const updates$ = this.notificationService
            .subscribeToChannel(taskChannelPrefix)
            .pipe(
                switchMap(() => this.taskNotificationService.getCardTasks())
            );

        const taskUpdates$ = concat(tasks$, updates$).pipe(shareReplay(1));

        return taskUpdates$.pipe(
            map(tasks => this.groupTasksByThread(tasks))
        );
    }

    private groupTasksByThread(tasks: ICardTaskDetail[]) {
        if (!tasks) return {};

        const actionableTasks = tasks.filter(task => this.taskHasAssociatedAction(task.taskId));

        const taskGroups = {};

        for (const task of actionableTasks) {
            const threadId = task?.threadId;

            taskGroups[threadId] = taskGroups[threadId] || [];
            taskGroups[threadId].push(task);
        }

        return taskGroups;
    }

    trackThreadTask(_index: number, cardTask: ICardTaskDetail): string {
        return `${cardTask.threadId}/${cardTask.cardId}/${cardTask.taskId}`;
    }

    ngOnChanges(changes: SimpleChanges) {
        const { threads, role } = changes;

        if (role?.currentValue) {
            const hasTaskPermission$ = this.loader.wrap(this.permissionService.checkPermissions(role.currentValue, "ReadTask"))

            this.canViewDashboardTasks$ = hasTaskPermission$.pipe(
                shareReplay(1)
            );

            this.threadTasks$ = this.canViewDashboardTasks$.pipe(
                switchMap(hasTaskPermission => hasTaskPermission ? this.getThreadTasks() : EMPTY),
                shareReplay(1)
            );
        }

        if ((threads || role) && this.threads && this.role) {
            const sortedThreads = this.sortThreadsByLatest(this.threads);
            this.tableData.data = this.enrichThreadsWithTaskUpdates(sortedThreads, this.threadTasks$);
        }
    }

    private sortThreadsByLatest(threads: IEnrichedThreadListing[]): IEnrichedThreadListing[] {
        return threads.sort((a, b) => {
            const dateA = a.preview?.timestamp || a.createdAt;
            const dateB = b.preview?.timestamp || b.createdAt;

            return new Date(dateB).getTime() - new Date(dateA).getTime();
        });
    }

    private taskHasAssociatedAction(taskId: string) {
        return !!this.taskActionLibrary.resolve(taskId);
    }

    private async buildThreadTaskExtension(thread: IThreadListing, task: ICardTaskDetail): Promise<ThreadTaskComponentExtension> {
        const navigateToSubject = new ReplaySubject<void>(1);
        const eventsSubject = new Subject();
        const card = await this.cardService.getCard(thread.id, task.cardId).toPromise();

        const cardResources = this.uiCardService.getCardResources(
            thread,
            card,
            this.role,
            navigateToSubject,
            eventsSubject
        );

        return {
            ...task,
            injector: Injector.create({
                providers: [
                    { provide: THREAD_CARD_RESOURCES, useValue: cardResources },
                    { provide: DASHBOARD_THREAD_TASK_DATA, useValue: task }
                ],
                parent: this.injector
            })
        };
    }

    private mapToTaskExtensions(tasks: ThreadTaskMap, threadListing: IThreadListing): Observable<ThreadTaskComponentExtension[]> {
        const taskExtensions = tasks?.[threadListing.id]?.map(task => this.buildThreadTaskExtension(threadListing, task));
        if (!taskExtensions) return of([]);
        return forkJoin(taskExtensions);
    }

    private enrichThreadsWithTaskUpdates(threadGroupListing: IEnrichedThreadListing[], threadTasks$: Observable<ThreadTaskMap>): IThreadListingWithTasks[] {
        return threadGroupListing.map(threadListing => {
            const tasks$ = threadTasks$.pipe(
                switchMap(tasks => this.mapToTaskExtensions(tasks, threadListing)),
                shareReplay(1)
            );

            const hasNoTasks$ = tasks$.pipe(
                map(tasks => !tasks?.length)
            );

            const hideRowChild$ = this.canViewDashboardTasks$.pipe(
                switchMap(canViewTasks => canViewTasks ? hasNoTasks$ : of(true))
            );

            const updatedAt = threadListing.preview?.timestamp ?? threadListing.createdAt;

            return {
                ...threadListing,
                hideRowChild$,
                tasks$,
                updatedAt
            };
        });
    }
}
