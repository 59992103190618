import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from "@angular/core";
import { IThreadListing, Role } from "@findex/threads";
import { IThreadGroupListing } from "../thread-grouper";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";

@Component({
    selector: "thread-list-node",
    templateUrl: "./thread-list-node.component.html",
    styleUrls: ["./thread-list-node.component.scss"]
})
export class ThreadListNodeComponent implements OnChanges {
    readonly gaEvents = GA_EVENTS;
    readonly role = Role;

    @Input() globalRole: Role;
    @Input() activeId: string;
    @Input() nodes: IThreadGroupListing[] = [];
    @Input() userStatusFilter: string;
    @Input() filter: string;
    @Output() itemClicked: EventEmitter<IThreadListing> = new EventEmitter();

    ngOnChanges(changes: SimpleChanges) {
        const { activeId, nodes } = changes;

        if (activeId?.currentValue || nodes?.currentValue) {
            this.nodes.forEach(node => {
                node.isExpanded = !!node.children.find(thread => thread.id === this.activeId);
            });
        }
    }

    trackItem(_index: number, node: IThreadGroupListing): string {
        return node.id;
    }

    toggleAccordion(event: MouseEvent, node: IThreadGroupListing) {
        event.stopPropagation();
        node.isExpanded = !node.isExpanded;
    }
}
