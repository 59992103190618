import { Component, OnInit } from "@angular/core";
import { IInvoice } from "@findex/payments-service-sdk";
import { PaymentService } from "projects/default-plugins/payment/services/payment.service";
import { AccountRouteService } from "projects/portal-modules/src/lib/account/services/account-route.service";
import { EMPTY, Observable, of } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { GA_EVENTS } from "../../../../portal-modules/src/lib/analytics";

export enum BillingTableHeaders {
    InvoiceNo = "Invoice Number",
    Item = "Item",
    Date = "Date",
    BillingPeriod = "Billing period",
    Status = "Status",
    Amount = "Amount",
    Receipt = "Receipt"
}

@Component({
    selector: "account-billing-history",
    templateUrl: "./account-billing-history.component.html",
    styleUrls: ["./account-billing-history.component.scss"]
})
export class AccountBillingHistoryComponent implements OnInit {
    readonly gaEvents = GA_EVENTS;

    tableHeaders = BillingTableHeaders;
    invoices$: Observable<IInvoice[]>;
    errorMessage: string;

    constructor(private paymentService: PaymentService, private accountRoute: AccountRouteService) {}

    ngOnInit() {
        this.invoices$ = this.accountRoute.getAccount().pipe(
            switchMap(account => this.paymentService.getAccountCustomer(account.id)),
            switchMap(customer => {
                if (!customer) {
                    return of([]);
                }
                return this.paymentService.listInvoices(customer.id);
            }),
            catchError(() => {
                this.errorMessage = "Sorry, something went wrong";
                return EMPTY;
            })
        );
    }
}
