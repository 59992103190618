import { Injectable } from "@angular/core";
import { IAnalyticsService } from "./IAnalyticsService";
import { AppUser } from "../../findex-auth";

declare global {
    interface Window {
        hj?: any;
    }
}

export const HOT_JAR_EVENTS = {
    DashboardNotificationEvent: "DashboardNotificationEvent",
    MessageNewCompleteEvent: "MessageNewCompleteEvent",
    MessageNewCloseEvent: "MessageNewCloseEvent",
    MessageReplyCompleteEvent: "MessageReplyCompleteEvent",
    MessageReplyDiscardEvent: "MessageReplyDiscardEvent",
    DashboardTimelineEvent: "DashboardTimelineEvent",
    TimelineEvent: "TimelineEvent",
    RFIResponseCloseEvent: "RFIResponseCloseEvent",
    RFIResponseCompleteEvent: "RFIResponseCompleteEvent",
    RFINewCompleteEvent: "RFINewCompleteEvent",
    RFINewCloseEvent: "RFINewCloseEvent",
    RFINewUnsavedEvent: "RFINewUnsavedEvent",
    RFIEditCompleteEvent: "RFIEditCompleteEvent",
    RFIAttachmentCompleteEvent: "RFIAttachmentCompleteEvent",
    MessageAttachmentCompleteEvent: "MessageAttachmentCompleteEvent",
    FileDownloadTabEvent: "FileDownloadTabEvent",
    FileDownloadTimelineEvent: "FileDownloadTimelineEvent",
    NotificationOpenEvent: "NotificationOpenEvent",
    NotificationDeleteEvent: "NotificationDeleteEvent",
    NotificationMarkReadEvent: "NotificationMarkReadEvent",
    MeetingSendRequestCompleteEvent: "MeetingSendRequestCompleteEvent",
    MeetingSessionEndCompleteEvent: "MeetingSessionEndCompleteEvent",
    MeetingDetailsViewEvent: "MeetingDetailsViewEvent",
    MeetingScheduleCompleteEvent: "MeetingScheduleCompleteEvent",
    MeetingScheduleCloseEvent: "MeetingScheduleCloseEvent",
    MeetingRescheduleCompleteEvent: "MeetingRescheduleCompleteEvent",
    MeetingRescheduleCloseEvent: "MeetingRescheduleCloseEvent",
    MeetingShareEvent: "MeetingShareEvent",
    Login2FACompleteEvent: "Login2FACompleteEvent",
    LoginCompleteEvent: "LoginCompleteEvent",
    OnboardingCompleteEvent: "OnboardingCompleteEvent",
    DashboardEvent: "DashboardEvent",
    rfi_createrfi: ["RFI", "RFI Created", "RFI Interaction"],
    rfi_close: ["RFI", "RFI Close", "RFI Interaction"],
    rfi_complete: ["RFI", "RFI Complete", "RFI Interaction"],
    rfi_confirmcomplete: ["RFI", "RFI Complete", "RFI Interaction"],
    rfi_childcollapse: ["RFI", "RFI Interaction"],
    rfi_adddocument: ["RFI", "RFI Interaction"],
    rfi_browsefiles: ["RFI", "RFI Interaction"],
    rfi_cancelcomplete: ["RFI", "RFI Interaction"],
    rfi_collapse: ["RFI", "RFI Interaction"],
    rficard_view: ["RFI", "RFI Card View", "RFI Interaction"],
    rficard_edit: ["RFI", "RFI Card Edit", "RFI Interaction"],
    rficard_remove: ["RFI", "RFI Card Remove", "RFI Interaction"],
    rficard_cancel: ["RFI", "RFI Card Cancel", "RFI Interaction"],
    rficreate_markasreport: [
        "RFI",
        "RFI Create Mark As Report",
        "RFI Interaction",
        "Insights",
        "Insights Mark As Report"
    ],
    rficreate_rename: ["RFI", "RFI Create Rename", "RFI Interaction"],
    rficreate_delete: ["RFI", "RFI Create Delete", "RFI Interaction"],
    vault_cardreply: ["RFI", "RFI Reply", "RFI Interaction"],
    app_providedocuments: ["RFI", "RFI Provide Document", "RFI View Document", "RFI Interaction"],
    documents_download: ["Documents", "Document Download File"],
    app_joinvc: ["Meetings", "Meeting Joined", "Meeting Interaction"],
    app_screensharevc: ["Meetings", "Meeting Screenshare"],
    calendar_openscheduling: ["Meetings", "Meeting Scheduled Open Overlay", "Meeting Interaction"],
    calendar_openmeetingrequest: ["Meetings", "Meeting Request Open Overlay", "Meeting Interaction"],
    calendar_createinvitation: ["Meetings", "Meeting Request Created", "Meeting Interaction"],
    calendar_viewdetails: ["Meetings", "Meeting View Details", "Meeting Interaction"],
    calendar_createscheduled: ["Meetings", "Meeting Scheduled Created", "Meeting Interaction"],
    calendar_cardreply: ["Meetings", "Meeting Reply", "Meeting Interaction"],
    app_requestsignature: ["Documents", "Documents Request Signature"],
    app_attachfile: ["Documents", "Documents Attach Files"],
    app_signdocument: ["Documents", "Documents Client Sign"],
    app_tabdocuments: ["Documents", "Documents Tab View"],
    app_error400: ["Errors", "400 Bad Request"],
    app_error401: ["Errors", "401 Unauthorised"],
    app_error403: ["Errors", "403 Forbidden Request"],
    app_error500: ["Errors", "500 Internal Server Error"],
    app_error504: ["Errors", "504 Timeout"],
    paymentcard_view: ["Payments", "Payment View"],
    paymentcard_paynow: ["Payments", "Payment Paynow"],
    paymentcard_paid: ["Payments", "Payment Paid"],
    paymentcard_addpaymentmethod: ["Payments", "Add Payment Method"],
    paymentcard_paymentmethodready: ["Payments", "Payment Method Ready"],
    paymentcard_edit: ["Payments", "Payment Card Edit"],
    paymentcard_cancel: ["Payments", "Payment Card Cancel"],
    payments_cardreply: ["Payments", "Payment Reply"],
    app_recentnotifications: ["Notifications", "Recent Notifications"],
    app_markasreadnotification: ["Notifications", "Mark As Read Notification"],
    app_deletenotification: ["Notifications", "Delete Notification"],
    app_viewinsights: ["Insights", "Insights View"],
    insights_download: ["Insights", "Insights Download"]
};
@Injectable()
export class HotjarAnalyticsService implements IAnalyticsService {
    configId: string;

    constructor() {}

    init(user: AppUser) {
        this.setUserId(user);
    }

    setUserId(user: AppUser) {
        if (!user) {
            return;
        }
        const userId = user.id;
        const userDetailsHasName = user.details?.givenName && user.details?.familyName;
        const name = userDetailsHasName ? `${user.details.givenName} ${user.details.familyName}` : user.name;
        if (window.hj) {
            window.hj("identify", userId, {
                name,
                role: user?.globalRole ? user.globalRole : "Client"
            });
        }
    }
    pageViewed(_path: string) {}
    sendEvent(_category: string, action: string, _label?: string, _value?: number) {
        if (window.hj) {
            for (const [eventName, tagName] of Object.entries(HOT_JAR_EVENTS)) {
                if (action === eventName) {
                    window.hj(_category, tagName);
                }
            }
        }
    }

    triggerSurvey(triggerName: string) {
        if (window.hj) {
            window.hj("trigger", triggerName);
        }
    }
}
