import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DocumentCategory } from "projects/default-plugins/vault/enums/DocumentCategory";
import { IDocument, ReportingPeriod } from "../create-card/create-card-document/create-card-document.component";

@Component({
    selector: "app-create-report-modal",
    templateUrl: "./create-report-modal.component.html",
    styleUrls: ["./create-report-modal.component.scss"]
})
export class CreateReportModalComponent implements OnInit {
    readonly PDF_FILE_TYPE = "application/pdf";

    reportingPeriod = ReportingPeriod;
    createReportForm = new FormGroup({
        title: new FormControl("", [Validators.required]),
        reportingPeriod: new FormControl("", [Validators.required])
    });
    isFileValid: boolean;

    constructor(
        @Inject(MAT_DIALOG_DATA) public document: IDocument,
        public dialogRef: MatDialogRef<CreateReportModalComponent>
    ) {}

    ngOnInit() {
        if (this.document.description) {
            this.createReportForm.patchValue({ title: this.document.description });
        }

        this.isFileValid = this.document.file.type === this.PDF_FILE_TYPE;
    }

    close() {
        this.dialogRef.close();
    }

    save() {
        this.dialogRef.close({
            category: DocumentCategory.Report,
            file: this.document.file,
            description: this.createReportForm.value.title,
            reportingPeriod: this.createReportForm.value.reportingPeriod
        });
    }
}
