import { Pipe, PipeTransform } from "@angular/core";
import { AppUser } from "../../findex-auth";

@Pipe({ name: "reportUserSearch" })
export class ReportUserSearchPipe implements PipeTransform {
    constructor() {}

    transform(metrics: AppUser[], searchTerm: string) {
        if (!searchTerm || !metrics) return metrics;

        const lowerSearch = searchTerm.toLowerCase();

        return metrics.filter(metric =>
            metric.name.toLowerCase().includes(lowerSearch) ||
            metric.id.toLowerCase().includes(lowerSearch) ||
            (metric.details && metric.details.mobileNumber.toLowerCase().includes(lowerSearch)) ||
            (metric.details && metric.details.emailAddress.toLowerCase().includes(lowerSearch)) ||
            (metric.details && metric.details.mobileNumber.toLowerCase().includes(lowerSearch))
          );
    }
}
