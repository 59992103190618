import { Directive, Input, HostListener, OnChanges, SimpleChanges } from "@angular/core";
import { AnalyticsService } from "../services/analytics.service";

@Directive({ selector: "[analyticsClick]" })
export class AnalyticsClickDirective implements OnChanges {
    @Input("analyticsClick") action: string|string[];
    @Input("analyticsCategory") category = "mouse_click";
    @Input("analyticsLabel") label?: string;
    @Input("analyticsValue") value?: number;
    @Input("analyticsTriggerOn") triggerOn?: any;

    constructor(private analyticsService: AnalyticsService) {}

    @HostListener("click")
    handleClick() {
        if (this.triggerOn) return;
        this.emitAnalytics();
    }

    ngOnChanges(changes: SimpleChanges) {
        const { triggerOn } = changes;

        if (triggerOn && triggerOn.currentValue) {
            this.emitAnalytics();
        }
    }

    private emitAnalytics() {
        if (Array.isArray(this.action)) {
            this.action.forEach(actionItem => {
                this.analyticsService.recordEvent(this.category, actionItem, this.label, this.value);
            });
        } else {
            this.analyticsService.recordEvent(this.category, this.action, this.label, this.value);
        }
    }
}
