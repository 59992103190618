<ng-container *ngIf="thread$ | async | isThreadActive | async">
    <ng-container *ngIf="userId | async; let userId">
        <thread-card
            *ngIf="card$ | async; let card"
            title="Documents"
            [thread]="thread$ | async"
            [card]="card"
            [replies]="replies$ | async"
            [createdById]="card.createdBy"
            [invertTheme]="userId === card.createdBy"
        >
            <ng-container *ngIf="role | permissions: 'ReadUsers' | async">
                <p *ngIf="completed">The client has completed their profile.</p>
                <p *ngIf="!completed">The client has not yet completed their profile.</p>
            </ng-container>
            <ng-container *ngIf="role === roles.Client">
                <p *ngIf="completed">
                    Thank you, your profile has been completed. Click <a href="" [routerLink]="'/profile'">here</a> to
                    view or change it.
                </p>
                <div class="fx-form" *ngIf="!completed">
                    <loader-icon *ngIf="loader.counter" [color]="'light'"></loader-icon>
                    <p class="client-profile-form-instruction">Have you used Same Day Tax Refunds services before?</p>
                    <form class="fx-onboarding-form fx-form fx-form--dark" [formGroup]="form" (submit)="saveProfile()">
                        <div class="fx-form-group">
                            <div class="client-profile-form-checkbox-container-inline">
                                <fx-checkbox
                                    [checked]="isReturningCustomer"
                                    (click)="isReturningCustomer = true"
                                    class="client-profile-form-checkbox"
                                >
                                    Yes
                                </fx-checkbox>
                                <fx-checkbox
                                    [checked]="!isReturningCustomer"
                                    (click)="isReturningCustomer = false"
                                    class="client-profile-form-checkbox"
                                >
                                    No
                                </fx-checkbox>
                            </div>
                        </div>
                        <ng-container *ngIf="!isReturningCustomer">
                            <p class="client-profile-form-instruction">
                                So we can set you up on our system we need you to provide your date of birth and tax
                                file number below
                            </p>
                            <app-sameday-profile-form [form]="form"></app-sameday-profile-form>
                        </ng-container>
                        <button
                            class="fx-btn fx-btn--full-width"
                            [ngClass]="['fx-btn--primary' + (theme.formSubmitActionOutlined ? '-outlined' : '')]"
                            [disabled]="shouldDisableSave()"
                            type="submit"
                        >
                            Save
                        </button>
                    </form>
                </div>
            </ng-container>
        </thread-card>
    </ng-container>
</ng-container>
