<li class="todoitem">
    <fx-checkbox
        class="todoitem-green-checkbox"
        [checked]="completed"
        (change)="trackStatusChanges($event)"
        [disabled]="disabled"
    ></fx-checkbox>
    <div [ngClass]="{ 'todoitem--checked': completed }" class="todoitem-content">
        <ng-content></ng-content>
    </div>
</li>
