import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

export enum MenuType {
    Profile = "Profile",
    NotificationsMobile = "NotificationsMobile",
    Navigation = "Navigation",
    CollapsedMenuIcon = "CollapsedMenuIcon" // hamburger button for mobile
}

@Injectable({
    providedIn: "root"
})
export class MenuService {
    private showNavigationMenu: BehaviorSubject<boolean> = new BehaviorSubject(false);
    showNavigation$: Observable<boolean> = this.showNavigationMenu.asObservable();

    private showNotificationMobileMenu: BehaviorSubject<boolean> = new BehaviorSubject(false);
    showNotificationMenuMobile$: Observable<boolean> = this.showNotificationMobileMenu.asObservable();

    private showProfileMenu: BehaviorSubject<boolean> = new BehaviorSubject(false);
    showProfileMenu$: Observable<boolean> = this.showProfileMenu.asObservable();

    private showMobileCollapsedIcon: BehaviorSubject<boolean> = new BehaviorSubject(true);
    showMobileCollapsedIcon$: Observable<boolean> = this.showMobileCollapsedIcon.asObservable();

    show(menuType: MenuType) {
        this.toggleMenu(menuType, true);
    }

    hide(menuType: MenuType) {
        this.toggleMenu(menuType, false);
    }

    toggle(menuType: MenuType) {
        this.toggleMenu(menuType);
    }

    private toggleMenu(menuType: MenuType, showMenu?: boolean) {
        switch (menuType) {
            case MenuType.Profile:
                this.showProfileMenu.next(this.getNextMenuState(showMenu, this.showProfileMenu.value));
                break;
            case MenuType.NotificationsMobile:
                this.showNotificationMobileMenu.next(
                    this.getNextMenuState(showMenu, this.showNotificationMobileMenu.value)
                );
                break;
            case MenuType.Navigation:
                this.showNavigationMenu.next(this.getNextMenuState(showMenu, this.showNavigationMenu.value));
                break;
            case MenuType.CollapsedMenuIcon:
                this.showMobileCollapsedIcon.next(this.getNextMenuState(showMenu, this.showMobileCollapsedIcon.value));
                break;
            default:
                return;
        }
    }

    private getNextMenuState(value: boolean, currentValue: boolean): boolean {
        return typeof value === "boolean" ? value : !currentValue;
    }
}
