<div class="create-card-actions" [ngClass]="{ 'slide-up': toggleContextMenuState }">
    <loader-icon *ngIf="showLoader || loader.counter" color="light" [showBackground]="true"></loader-icon>
    <div class="create-card-actions-controls">
        <div class="create-card-actions-controls-toggle create-card-actions-open" (click)="toggleContextMenu()">
            <i [ngClass]="{ rotate90: toggleContextMenuState }" class="las la-plus"></i>
        </div>
        <div class="create-card-actions-controls-buttons">
            <ng-container *ngFor="let cardExtension of cardExtensions">
                <a
                    *ngIf="
                        cardExtension.extension.permission
                            ? (role | permissions: cardExtension.extension.permission | async)
                            : true
                    "
                    (click)="
                        addCard(
                            cardExtension.extension.componentRef,
                            cardExtension.extension?.data,
                            cardExtension.extension?.config
                        )
                    "
                    class="create-card-menu-button"
                    [analyticsClick]="cardExtension.extension.analyticsEvent"
                >
                    <i [ngClass]="cardExtension.extension.icon" class="las la-2x"></i>
                    {{ cardExtension.extension.title }}
                </a>
            </ng-container>
        </div>
    </div>
</div>
<div class="menu" [ngClass]="{ 'menu--meeting-context-menu-active': toggleMeetingContextMenuState }">
    <fx-slide-up-menu [toggleMenu]="toggleContextMenuState" [offset]="86">
        <div class="create-card-menu">
            <ng-container *ngFor="let cardExtension of cardExtensions">
                <a
                    *ngIf="
                        cardExtension.extension.permission
                            ? (role | permissions: cardExtension.extension.permission | async)
                            : true
                    "
                    (click)="
                        addCard(
                            cardExtension.extension.componentRef,
                            cardExtension.extension?.data,
                            cardExtension.extension?.config
                        )
                    "
                    class="create-card-menu-button"
                    [analyticsClick]="cardExtension.extension.analyticsEvent"
                >
                    <i [ngClass]="cardExtension.extension.icon" class="las la-2x"></i>
                    {{ cardExtension.extension.title }}
                </a>
            </ng-container>
        </div>
    </fx-slide-up-menu>
</div>
