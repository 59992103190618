<div class="nodes">
    <ng-container
        *ngFor="
            let node of nodes | threadsSearch: filter | threadsStatusSearch: userStatusFilter;
            trackBy: trackItem;
            let i = index
        "
    >
        <fx-accordion
            *ngIf="node.children?.length"
            [isExpanded]="node.isExpanded"
            [toggleGutters]="true"
            (accordionClicked)="toggleAccordion($event, node)"
        >
            <div accordion-header analyticsClick="app_togglethreadlist">
                <div class="fx-threads-group-name">{{ node.name }}</div>
                <i class="fx-accordion-toggle-icon icon ion-ios-arrow-down"></i>
            </div>

            <div accordion-content>
                <thread-list-item
                    *ngFor="let child of node.children; trackBy: trackItem"
                    [thread]="child"
                    [active]="activeId === child.id"
                    [globalRole]="globalRole"
                    (itemClicked)="itemClicked.next($event)"
                >
                </thread-list-item>
            </div>
        </fx-accordion>
    </ng-container>
</div>

<div class="fx-accordion-toggle--has-gutters notimelines">
    0 timelines found.
</div>
