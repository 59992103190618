import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IRequestTextData } from '@findex/threads';

@Component({
    selector: 'request-response-form',
    templateUrl: './request-response-form.component.html',
    styleUrls: ['./request-response-form.component.scss']
})
export class RequestResponseFormComponent implements OnInit {

    @Output() formSubmitResponse = new EventEmitter<IRequestTextData>();
    @Output() discardChanges = new EventEmitter<string>();
    @Input() editResponseValue: string;
    @Input() userId: string;
    newResponse = new FormControl("");
    quillError: boolean;
    showTextResponse = false;

    constructor() { }

    ngOnInit(): void {
        this.newResponse.setValue(this.editResponseValue);
    }

    public discard(): void {
        this.discardChanges.emit();
    }

    submitResponse(): void {
        const newResponse: IRequestTextData = {
            modifiedBy: this.userId,
            textinput: this.newResponse.value,
            modifiedAt: new Date().toISOString(),
        }
        this.formSubmitResponse.emit(newResponse);
    }
}
