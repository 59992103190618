import { Component, HostListener, Input, OnInit } from "@angular/core";

@Component({
    selector: "signature-icon",
    templateUrl: "./signature-icon.component.html",
    styleUrls: ["./signature-icon.component.scss"]
})
export class SignatureIconComponent implements OnInit {
    @Input() theme: string;
    @Input() cursor = true;
    @Input() styled = true;
    @Input() disabled = false;

    class: string;

    constructor() {}

    @HostListener("click", ["$event"])
    handleClick(event: MouseEvent) {
        if (this.disabled) {
            event.preventDefault();
        }
    }

    ngOnInit() {
        this.class = `signature-icon--${this.theme}`;
        if (!this.cursor) {
            this.class += " signature-icon--no-click";
        }
        if (!this.styled) {
            this.class += " signature-icon--unstyled";
        }
        if (this.disabled) {
            this.class += " signature-icon--disabled";
        }
    }
}
