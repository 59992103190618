<div class="meeting-details">
    <div class="meeting-details-status">{{ status }}</div>
    <div *ngIf="showButtons && !sessionTerminated" class="meeting-details-controls">
        <button
            class="fx-btn fx-btn--primary-outlined"
            role="button"
            [analyticsClick]="gaEvents.APP_JOINVC"
            [disabled]="joinDisabled || (isUserJoined$ | async)"
            (click)="join.emit()">
            JOIN CALL
        </button>
        <button
            class="fx-btn fx-btn--primary-outlined"
            role="button"
            *ngIf="role | permissions: 'EndSession' | async"
            (click)="terminateSession()"
            [analyticsClick]="gaEvents.APP_ENDVCSESSION">
            END SESSION
        </button>
    </div>
</div>
