<div class="metric">
    <h4>
        <span class="metric-label">{{ tableReportRow?.metric?.label }}</span>

        <span class="metric-info">
           <i [matTooltip]="tableReportRow?.metric?.description"
              matTooltipPosition="after"
              class="las la-exclamation-circle">
           </i>
        </span>
    </h4>

    <div class="metric-result">
        <span *ngIf="tableReportRow?.result?.value !== null && tableReportRow?.metric?.unit === '$'" class="metric-result-value">
            {{ tableReportRow?.result?.value | currency }}
        </span>
        <span *ngIf="tableReportRow?.result?.value !== null && tableReportRow?.metric?.unit !== '$'" class="metric-result-value">
            {{ tableReportRow?.result?.value | number:'1.0-2' }} {{ tableReportRow?.metric?.unit }}
        </span>
        <span class="metric-result-value" *ngIf="tableReportRow?.result?.value === null">-</span>
        <span class="metric-result-update">Updated {{ report?.period?.start | timeAgo }}</span>
    </div>

    <div class="metric-comparison">
        <div class="metric-target first">
            <div class="metric-target-label">Your target</div>

            <div class="metric-target-value">
                <div *ngIf="tableReportRow?.target?.targetValue !== null && tableReportRow?.metric?.unit === '$'">
                    {{ tableReportRow?.target?.targetValue | currency }}
                </div>

                <div *ngIf="tableReportRow?.target?.targetValue !== null && tableReportRow?.metric?.unit !== '$'">
                    {{ tableReportRow?.target?.targetValue | number:'1.0-2' }} {{ tableReportRow?.metric?.unit }}
                </div>

                <div *ngIf="tableReportRow?.target?.targetValue === null">
                    -
                </div>
            </div>

            <insights-trend-pill [isBetterThanTarget]="isMetricBetterThenTarget"
                                 [isBelowTarget]="isMetricBelowTarget"
                                 [value]="targetValue | absolute | number:'1.0-1'">
            </insights-trend-pill>

        </div>

        <ng-container *ngFor="let benchmark of tableReportRow?.benchmarks">
            <div *ngIf="benchmark?.percentiles[3]" class="metric-target">
                <div>
                    <span class="metric-target-label">{{ benchmark.label }}</span>
                    <span class="metric-info">
                        <i [matTooltip]="comparisonArea"
                           matTooltipPosition="after"
                           class="las la-exclamation-circle">
                        </i>
                    </span>
                </div>

                <div class="metric-target-value">
                    <span
                        *ngIf="benchmark?.percentiles[3] && tableReportRow?.metric?.unit === '$'">{{ benchmark?.percentiles[3] | currency }}
                    </span>
                    <span
                        *ngIf="benchmark?.percentiles[3] !== null && tableReportRow?.metric?.unit !== '$'">{{ benchmark?.percentiles[3] | number:'1.0-2' }} {{ tableReportRow?.metric?.unit }}
                    </span>
                    <span *ngIf="benchmark?.percentiles[3] === null">-</span>
                </div>


                <insights-trend-pill [isBetterThanTarget]="isMetricBetterThenTarget"
                                     [isBelowTarget]="isMetricBelowTarget"
                                     [value]="getBenchmarkValue(tableReportRow, benchmark) | absolute | number:'1.0-1'">
                </insights-trend-pill>

            </div>
        </ng-container>
    </div>
</div>


