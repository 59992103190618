<ng-container *ngIf="role$ | async; let role">
    <div class="fx-dashboard">
        <fx-layout>
            <fx-layout-column [sizes]="{ mobile: 24, laptop: 8 }">
                <loader-icon *ngIf="loader.counter" [static]="true" color="light"></loader-icon>
                <dashboard-threads *ngIf="threads$ | async as threads" [threads]="threads" [role]="role"></dashboard-threads>
            </fx-layout-column>

            <fx-layout-column
                [sizes]="{ mobile: 24, laptop: (role | permissions: 'UpdateInternalWorkflow' | async) ? 12 : 8 }"
            >
                <div class="fx-dashboard-card" id="dashboard-upcoming-meetings">
                    <h2>Upcoming meetings</h2>
                    <upcoming-meetings [state]="state$ | async"></upcoming-meetings>
                </div>
            </fx-layout-column>

            <fx-layout-column
                [sizes]="{ mobile: 24, laptop: (role | permissions: 'UpdateInternalWorkflow' | async) ? 12 : 8 }"
                *ngIf="showArticlesSection"
            >
                <div class="fx-dashboard-card">
                    <h2>{{ dashboardNewsTitle }}</h2>
                    <news [articleAmount]="(role | permissions: 'UpdateInternalWorkflow' | async) ? 2 : 1"></news>
                </div>
            </fx-layout-column>
        </fx-layout>
    </div>
</ng-container>
