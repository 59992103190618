<div class="verify-mobile-form">
    <div class="verify-mobile-form-content">
        <ng-container *ngIf="!!loaderService.counter">
            <div class="verify-mobile-form-loader">
                <loader-icon [color]="'light'"></loader-icon>
            </div>
        </ng-container>
        <ng-container *ngIf="!showVerifyCode">
            <h1 class="fx-onboarding-title" *ngIf="textResource.verifyMobileTitle">
                {{ textResource.verifyMobileTitle }}
            </h1>
            <p class="fx-onboarding-body" *ngIf="textResource.verifyMobileDescription">
                {{ textResource.verifyMobileDescription }}
            </p>
        </ng-container>
        <ng-container *ngIf="showVerifyCode">
            <h1 class="fx-onboarding-title" *ngIf="textResource.verifyCodeTitle">{{ textResource.verifyCodeTitle }}</h1>
            <p class="fx-onboarding-body" *ngIf="textResource.verifyCodeDescription">
                {{ textResource.verifyCodeDescription }}
            </p>
        </ng-container>

        <form class="fx-onboarding-form fx-form fx-form--dark" [formGroup]="form">
            <div class="fx-form-group" *ngIf="errorMessage">
                <div class="fx-form-validation-panel">
                    <div class="fx-form-validation-panel-item fx-onboarding-form-error">{{ errorMessage }}</div>
                </div>
            </div>
            <div class="fx-form-group">
                <label class="fx-form-label" for="mobileNumber">Mobile</label>
                <div
                    class="fx-form-control"
                    [ngClass]="{ 'fx-form-control--inline-button': showVerifyCode }"
                    [class.no-flag]="signupCountries.length < 2"
                >
                    <input
                        class="fx-form-input"
                        type="text"
                        name="Mobile"
                        formControlName="mobileNumber"
                        [ngClass]="{
                            'fx-form-input--error':
                                !form.controls['mobileNumber'].valid && form.controls['mobileNumber'].touched
                        }"
                        intlTel
                        [intlTelOptions]="{
                            initialCountry: 'au',
                            onlyCountries: signupCountries,
                            allowDropdown: signupCountries.length > 1,
                            customContainer: 'fx-onboarding-intl-input'
                        }"
                        [intlTelInitialValue]="form.controls.mobileNumber.value"
                        (intlTelValid)="internationalPhoneNoValid = $event"
                        (intlTelCurrentValue)="internationalPhoneNo = $event"
                        [attr.disabled]="isInvitation ? true : null"
                    />
                </div>
                <button
                    class="fx-form-group-action"
                    *ngIf="showVerifyCode"
                    type="button"
                    [disabled]="
                        !isInvitation && (!internationalPhoneNoValid || !form.controls.mobileNumber.valid || (disableResend$ | async))
                    "
                    (click)="requestMFACode()"
                    analyticsClick="onboarding_resend"
                >
                    Resend PIN
                </button>
                <div
                    class="fx-form-validation"
                    *ngIf="form.controls['mobileNumber'].hasError('required') && form.controls['mobileNumber'].touched"
                >
                    Mobile is required.
                    <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                </div>
                <div
                    class="fx-form-validation"
                    *ngIf="form.controls['mobileNumber'].hasError('invalid') && form.controls['mobileNumber'].touched"
                >
                    Mobile number is not valid.
                    <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                </div>
            </div>
            <div *ngIf="!showVerifyCode" class="fx-form-group">
                <div class="fx-onboarding-footer">
                    {{ textResource.verifyMobileFooter || textResource.default.verifyMobileFooter }}
                </div>
                <button
                    [type]="showVerifyCode ? 'button' : 'submit'"
                    class="fx-btn fx-btn--primary-outlined fx-btn--full-width"
                    [disabled]="
                        !isInvitation && (!internationalPhoneNoValid || !form.controls.mobileNumber.valid || (disableResend$ | async))
                    "
                    analyticsClick="onboarding_sendphone"
                    (click)="requestMFACode()"
                >
                    {{ textResource.verifyMobileAction || textResource.default.verifyMobileAction }}
                </button>
            </div>
            <div class="fx-form-group" *ngIf="showVerifyCode">
                <div class="fx-form-control">
                    <label class="fx-form-label" for="verifyCode">Pin</label>
                    <input
                        autocomplete="one-time-code"
                        formControlName="verifyCode"
                        class="fx-form-input"
                        name="verifyCode"
                        placeholder="PIN"
                        type="text"
                        [ngClass]="{
                            'fx-form-input--error':
                                !form.controls['verifyCode'].valid && form.controls['verifyCode'].touched
                        }"
                    />
                </div>
                <div class="fx-form-validation" *ngIf="form.controls['verifyCode'].hasError('pattern')">
                    Verification code must be 6 numeric characters.
                    <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                </div>
            </div>
            <div class="fx-form-group" *ngIf="showVerifyCode">
                <div class="fx-onboarding-footer">
                    {{ textResource.verifyCodeFooter || textResource.default.verifyCodeFooter }}
                </div>
                <button
                    class="fx-btn fx-btn--full-width"
                    [ngClass]="['fx-btn--primary' + (theme.formSubmitActionOutlined ? '-outlined' : '')]"
                    [disabled]="form.controls.verifyCode.valid === false"
                    (click)="verifyMobileCode()"
                    analyticsClick="onboarding_verify"
                >
                    {{ textResource.verifyCodeAction || textResource.default.verifyCodeAction }}
                </button>
            </div>
            <p class="fx-onboarding-footer" *ngIf="supportEmail">
                Click <a href="mailto:{{ supportEmail }}" analyticsClick="onboarding_mobilesupport">here</a> to contact
                support if you are not receiving a security code via SMS text
            </p>
        </form>
    </div>
</div>
