import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { FindexUiModule } from "projects/portal-modules/src/lib/findex-ui";
import { ThreadsUiModule } from "projects/portal-modules/src/lib/threads-ui";
import { FxUiModule } from "@findex/fx-ui";
import { DashboardCardComponent } from "./components/dashboard-card/dashboard-card.component";
import { UpcomingMeetingsComponent } from "./components/upcoming-meetings/upcoming-meetings.component";
import { NewsArticleItemComponent } from "./components/news-article/news-article-item/news-article-item.component";
import { NewsComponent } from "./components/news-article/news.component";
import { AllActivityComponent } from "./components/all-activity/all-activity.component";
import { DashboardThreadsComponent } from "./components/dashboard-threads/dashboard-threads.component";
import { RouterModule } from "@angular/router";
import { SharedModule } from "projects/portal-modules/src/lib/shared";
import { AnalyticsModule } from "projects/portal-modules/src/lib/analytics";
import { FindexAuthModule } from "projects/portal-modules/src/lib/findex-auth";
import { PluginsModule, PLUGIN_FACTORY } from "projects/portal-modules/src/lib/plugins";
import { DashboardPlugin } from "./dashboard.plugin";
import { FxTableModule } from "projects/portal-modules/src/lib/fx-table/fx-table.module";
import { ThreadsTaskComponent } from "./components/threads-task/threads-task.component";

const angularImports = [CommonModule, FormsModule, FindexAuthModule, RouterModule];

@NgModule({
    imports: [
        FindexUiModule,
        ThreadsUiModule,
        ...angularImports,
        FxUiModule,
        SharedModule,
        AnalyticsModule,
        FxTableModule,
        PluginsModule
    ],
    declarations: [
        DashboardCardComponent,
        UpcomingMeetingsComponent,
        NewsArticleItemComponent,
        NewsComponent,
        AllActivityComponent,
        DashboardThreadsComponent,
        ThreadsTaskComponent
    ],
    providers: [{ provide: PLUGIN_FACTORY, useClass: DashboardPlugin, multi: true }]
})
export class DashboardModule {}
