<fx-container [fluid]="true">
    <fx-layout [columns]="24" [gutters]="false">
        <fx-layout-column class="general-settings" [sizes]="{ mobile: '24' }">
            <fx-layout [columns]="24" [gutters]="true" class="general-settings-layout">
                <fx-layout-column class="general-settings-form" [sizes]="{ mobile: '24', tablet: '10' }"
                    [ngClass]="{ 'general-settings-form--hidden': !!loader.counter }">
                    <form class="fx-form fx-form--dark" [formGroup]="form">
                        <div class="fx-form-group" *ngIf="errorMessage">
                            <div class="fx-form-validation-panel">
                                <div class="fx-form-validation">{{ errorMessage }}</div>
                            </div>
                        </div>

                        <div class="fx-form-group">
                            <label class="fx-form-label general-settings-form-label" for="givenName">First name</label>
                            <div class="fx-form-control">
                                <input
                                    formControlName="givenName"
                                    id="givenName"
                                    class="fx-form-input"
                                    type="text"
                                    placeholder=""
                                    aria-label="First name"
                                    [ngClass]="{
                                                'fx-form-input--error':
                                                    !form.controls['givenName'].valid &&
                                                    form.controls['givenName'].touched
                                            }" />
                            </div>
                            <div
                                class="fx-form-validation"
                                *ngIf="
                                            form.controls['givenName'].hasError('required') &&
                                            form.controls['givenName'].touched
                                        ">
                                First name is required.
                                <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                            </div>
                        </div>

                        <div class="fx-form-group">
                            <label class="fx-form-label general-settings-form-label" for="familyName">Last name</label>
                            <div class="fx-form-control">
                                <input
                                    formControlName="familyName"
                                    id="familyName"
                                    class="fx-form-input"
                                    type="text"
                                    placeholder=""
                                    aria-label="Last name"
                                    [ngClass]="{
                                                'fx-form-input--error':
                                                    !form.controls['familyName'].valid &&
                                                    form.controls['familyName'].touched
                                            }" />
                            </div>
                            <div
                                class="fx-form-validation"
                                *ngIf="
                                            form.controls['familyName'].hasError('required') &&
                                            form.controls['familyName'].touched
                                        ">
                                Last name is required.
                                <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                            </div>
                        </div>
                        <div class="fx-form-group">
                            <label class="fx-form-label general-settings-form-label" for="emailAddress">Email</label>
                            <div class="fx-form-control">
                                <input
                                    formControlName="emailAddress"
                                    id="emailAddress"
                                    class="fx-form-input"
                                    type="text"
                                    placeholder="john.doe@findex.com.au"
                                    aria-label="Email"
                                    [ngClass]="{
                                                'fx-form-input--error':
                                                    !form.controls['emailAddress'].valid &&
                                                    form.controls['emailAddress'].touched
                                            }" />
                            </div>
                            <div
                                class="fx-form-validation"
                                *ngIf="
                                            form.controls['emailAddress'].hasError('required') &&
                                            form.controls['emailAddress'].touched
                                        ">
                                Email is required.
                                <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                            </div>
                            <div
                                class="fx-form-validation"
                                *ngIf="
                                            form.controls['emailAddress'].hasError('email') &&
                                            form.controls['emailAddress'].touched
                                        ">
                                Email is invalid.
                                <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                            </div>
                        </div>

                        <div class="fx-form-group">
                            <label class="fx-form-label general-settings-form-label" for="mobileNumber">Mobile</label>
                            <div class="fx-form-control" [class.no-flag]="signupCountries.length < 2">
                                <input
                                    class="fx-form-input"
                                    type="text"
                                    name="Mobile"
                                    formControlName="mobileNumber"
                                    [ngClass]="{
                                                'fx-form-input--error':
                                                    !form.controls['mobileNumber'].valid &&
                                                    form.controls['mobileNumber'].touched
                                            }"
                                    intlTel
                                    [intlTelOptions]="{
                                                initialCountry: 'au',
                                                onlyCountries: signupCountries,
                                                allowDropdown: signupCountries.length > 1,
                                                customContainer: 'fx-onboarding-intl-input'
                                            }"
                                    [intlTelInitialValue]="form.controls.mobileNumber.value"
                                    (intlTelValid)="internationalPhoneNoValid = $event"
                                    (intlTelCurrentValue)="internationalPhoneNo = $event" />
                            </div>
                            <div
                                class="fx-form-validation"
                                *ngIf="
                                            form.controls['mobileNumber'].hasError('required') &&
                                            form.controls['mobileNumber'].touched
                                        ">
                                Mobile is required.
                                <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                            </div>
                            <div
                                class="fx-form-validation"
                                *ngIf="
                                            form.controls['mobileNumber'].hasError('invalid') &&
                                            form.controls['mobileNumber'].touched
                                        ">
                                Mobile number is not valid.
                                <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                            </div>
                        </div>
                        <ng-template userProfileExtension></ng-template>
                    </form>

                    <div class="fx-form-group" *ngIf="(myRole$ | async) == Role.Administrator">
                        <label class="fx-form-label general-settings-form-label">Global User Role</label>
                        <div class="fx-form-control">
                            <select class="fx-form-select" (change)="setProfileRole($event.target.value)">
                                <option [selected]="!originalProfileRole" [value]="null">No Global Role</option>
                                <option
                                    *ngFor="let entry of roleOptions"
                                    [value]="entry[1]"
                                    [selected]="entry[1] === originalProfileRole">{{ entry[0] }}</option>
                            </select>
                        </div>
                    </div>

                    <button
                        *ngIf="showChangePassword$ | async"
                        (click)="showResetPasswordDialog()"
                        analyticsClick="app_changepassword"
                        class="general-settings-form-password"
                        [disabled]="!!loader.counter"
                        [ngClass]="{ 'general-settings-form-password--hidden': !!loader.counter }">
                        Change password
                    </button>
                </fx-layout-column>
                <fx-layout-column [sizes]="{ mobile: '24', tablet: '14' }">
                    <div
                        class="general-settings-avatar"
                        [ngClass]="{ 'general-settings-avatar--hidden': !!loader.counter }">
                        <fx-avatar
                            [name]="avatarNameString"
                            [image]="userId | profilePicture"
                            size="large"></fx-avatar>
                    </div>
                </fx-layout-column>
            </fx-layout>
            <fx-layout-column class="general-settings-actions" [sizes]="{ mobile: '24' }">
                <button
                    *ngIf="isClientSearch$ | async"
                    class="fx-btn fx-btn--primary-outlined general-settings-actions-button"
                    [ngClass]="['fx-btn--primary' + (theme.formSubmitActionOutlined ? '-outlined' : '')]"
                    (click)="back()">
                    BACK
                </button>
                <button
                    class="fx-btn fx-btn--primary general-settings-actions-button"
                    [disabled]="!saveShouldBeEnabled() || !!loader.counter"
                    (click)="saveChanges()"
                    type="submit">
                    Save Changes
                </button>
            </fx-layout-column>
        </fx-layout-column>
    </fx-layout>
</fx-container>