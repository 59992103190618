<div class="thread-search" [ngClass]="{ 'thread-search--active': expandSearch }">
    <div class="fx-form-control">
        <div class="fx-form-icon" (click)="setActive(true)" [ngClass]="{ 'thread-search--disabled': !isMobileView }">
            <i class="las la-search"></i>
        </div>
        <input
            #search
            class="fx-form-input threads-filter-search"
            [placeholder]="placeholder"
            attr.aria-label="Search Timelines"
            analyticsClick="{{ analyticsClickEvent }}"
            type="text"
            [value]="(searchTerm$ | async) || ''"
            (input)="searchTerm$.next($event.target.value)"
        />
        <div
            *ngIf="expandSearch && isMobileView"
            (click)="setActive(false); searchTerm$.next('')"
            class="thread-search-close"
        >
            <i class="las la-times cross-icon"></i>
        </div>
    </div>
</div>
