import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
    selector: "pdf-preview",
    templateUrl: "./pdf-preview.component.html",
    styleUrls: ["./pdf-preview.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PdfPreviewComponent implements OnChanges {
    @Input() data: string;

    dataUrlBypass: SafeResourceUrl;

    constructor(private sanitizer: DomSanitizer) {}

    ngOnChanges(changes: SimpleChanges) {
        const { data } = changes;

        if (data) {
            if (data.currentValue) {
                //WARNING: if at any point we let users upload previewable documents we need to reconsider
                this.dataUrlBypass = this.sanitizer.bypassSecurityTrustResourceUrl(data.currentValue);
            } else {
                this.dataUrlBypass = null;
            }
        }
    }
}
