import { Injectable } from "@angular/core";
import { IWorkflowDueDate, IWorkflowStepUI } from "../interfaces/IWorkflowStepUI";
import { IThreadWorkflowStep, IWorkflowStep, WorkflowStepType } from "@findex/threads";
import { environmentCommon } from "../../environment/environment.common";

@Injectable({
    providedIn: "root"
})
export class WorkflowStepsService {

    constructor() {
    }

    public updateSteps(steps: IThreadWorkflowStep[], selectedIndex: number): IThreadWorkflowStep[] {
        const nowString = Date.now().toString();

        return steps.map((step, i) => {
            const isCurrentStep = selectedIndex === i;

            if (i < selectedIndex) {
                return {
                    ...step,
                    isCurrentStep,
                    startedDate: step.startedDate ?? nowString,
                    completedDate: step.completedDate ?? nowString
                }
            } else {
                return {
                    ...step,
                    isCurrentStep,
                    startedDate: null,
                    completedDate: null,
                }
            }
        });
    }

    public getUpdatedStepEntries(steps: IThreadWorkflowStep[]): IWorkflowStepUI[] {
        const dateNow = Date.now();
        const workflowStartDate = steps[0].startedDate ? new Date(steps[0].startedDate).getTime() : dateNow;
        const dueDates = this.getDueDates(steps, workflowStartDate);
        const currentStepIndex = steps.findIndex(step => step.isCurrentStep);

        return steps
            .filter(step => step.type === WorkflowStepType.OPEN || step.clientFacingId === environmentCommon.workflow.states.closed)
            .map((step: IThreadWorkflowStep, index: number) => {
                const isLastStep = index === steps.length - 1;
                if (step.isCurrentStep) {
                    if (isLastStep) {
                        step.completedDate = dateNow.toString();
                    } else {
                        step.startedDate = step.startedDate ?? new Date(dateNow).toISOString();
                        step.completedDate = null;
                    }
                }
                const precalculatedDueDate = dueDates[index];
                const duration = step.timeEstimate?.duration;
                const startTime = new Date(step.startedDate).getTime();
                const dueDate = precalculatedDueDate ?? this.getStepDueDate(startTime, duration);
                const isCompleted = index < currentStepIndex;

                return this.getStepEntry(step, isCompleted, dueDate);
            });
    }

    private getStepEntry(step: IThreadWorkflowStep, isCompleted: boolean, dueDate: number): IWorkflowStepUI {
        const isOverdue = dueDate ? dueDate < Date.now() : false;

        return {
            step,
            isCompleted,
            dueDate,
            isOverdue
        };
    }

    private getStepDueDate(startDate: number, duration: number): number {
        if (!duration || !startDate) {
            return null;
        }
        return startDate + duration;
    }


    //TODO: cleanup/consolidate once types are cleaned up
    private getDueDates(steps: IThreadWorkflowStep[], workflowStartDate: number): number[] {
        const dueDates: number[] = [];
        let accumulatedDueDate = workflowStartDate;

        for (const step of steps) {
            const duration = step.timeEstimate?.duration;
            if (duration == null) {
                break;
            }
            dueDates.push(accumulatedDueDate + duration);
            accumulatedDueDate += duration;
        }

        return dueDates;
    }

    calculateDueDates(steps: IWorkflowStep[], startTime: number): IWorkflowDueDate[] {
        const dueDates: IWorkflowDueDate[] = [];

        if (!steps.length) {
            return dueDates;
        }

        let accumulatedDueDate = startTime;
        for (const step of steps) {
            const duration = step.timeEstimate?.duration;
            if (duration == null) {
                break;
            }

            const dueTime = accumulatedDueDate + duration;
            accumulatedDueDate += duration;

            dueDates.push(this.mapDueDate(step, dueTime));
        }

        return dueDates;
    }

    private mapDueDate(step: IWorkflowStep, dueDate: number): IWorkflowDueDate {
        const isOverdue = dueDate > Date.now();
        return { step, dueDate, isOverdue };
    }
}
