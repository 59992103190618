import { Component, Input } from "@angular/core";
import { InsightsMetricService } from "../../services/insights-metric.service";
import { MetricBenchmark, TableReport, TableReportRow } from "@findex/openmeasures-core";

@Component({
    selector: "insights-metric-summary",
    templateUrl: "./insights-metric-summary.component.html",
    styleUrls: ["./insights-metric-summary.component.scss"]
})
export class InsightsMetricSummaryComponent {

    @Input() public set tableReportRow(tableReportRow: TableReportRow) {
        this.setMetricsUI(tableReportRow);
        this._tableReportRow = tableReportRow;
    };

    @Input() public report: TableReport;
    @Input() public comparisonArea: string = "In comparison to upper quartile";

    public get tableReportRow(): TableReportRow {
        return this._tableReportRow;
    }

    public targetValue: number;
    public isMetricBelowTarget: boolean;
    public isMetricBetterThenTarget: boolean;

    private _tableReportRow: TableReportRow;

    constructor(private insightsMetricService: InsightsMetricService) {
    }

    public setTargetValue(tableReportRow: TableReportRow): void {
        this.targetValue = (((tableReportRow?.result?.value - tableReportRow?.target?.targetValue) / tableReportRow?.target?.targetValue) * 100);
    }

    public getBenchmarkValue(tableReportRow: TableReportRow, benchmark: MetricBenchmark): number {
        return (((tableReportRow?.result?.value - benchmark?.percentiles[3]) / benchmark?.percentiles[3]) * 100);
    }

    private setMetricsUI(tableReportRow: TableReportRow): void {
        this.isMetricBelowTarget = this.insightsMetricService.isMetricBelow(tableReportRow);
        this.isMetricBetterThenTarget = this.insightsMetricService.isMetricBetterThanTarget(tableReportRow);
        this.setTargetValue(tableReportRow);
    }
}
