import { Component } from "@angular/core";
import { OpenMeasuresService } from "projects/default-plugins/insights/services/open-measures.service";
import { AppUser } from "projects/portal-modules/src/lib/findex-auth/model/AppUser";
import { Observable, of } from "rxjs";
import { catchError, map, shareReplay } from "rxjs/operators";
import { Loader } from "../../../shared/services/loader";

@Component({
    selector: "app-user-profile",
    templateUrl: "./user-profile.component.html",
    styleUrls: ["./user-profile.component.scss"]
})
export class UserProfileComponent {
    loader = new Loader();
    userId: string;
    avatarNameString = "";

    hasInsightsPermission$: Observable<boolean>;
   
    constructor(openMeasuresService: OpenMeasuresService) {
        this.hasInsightsPermission$ = openMeasuresService.getAllEnterprises().pipe(
            catchError(() => of(null)),
            map(enterprises => !!enterprises),
            shareReplay(1)
        );
    }

    public getUserProfile(userProfile: AppUser):void {
        this.userId = userProfile.id;
        this.avatarNameString = userProfile.name;
    }
}
