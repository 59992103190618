import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import {
    CalendarAction,
    CardReply,
    ICalendarParticipant,
    IParticipant,
    IThread,
    IThreadCard,
    Role,
    SubjectType,
    VideoChatAction,
    CardStatus
} from "@findex/threads";
import { combineLatest, forkJoin, Observable, of, Subject, Subscription } from "rxjs";
import { filter, map, shareReplay, switchMap, take } from "rxjs/operators";
import { CardResources, THREAD_CARD_RESOURCES } from "projects/portal-modules/src/lib/threads-ui/interfaces/IUiCard";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { ThreadsService } from "projects/portal-modules/src/lib/threads-ui/services/threads.service";
import { ENVIRONMENT, TASK_ACTION_LIBRARY } from "src/app/injection-token";
import { ParticipantCache } from "projects/portal-modules/src/lib/threads-ui/services/participant-cache.service";
import { IAvatarContent } from "@findex/fx-ui";
import {
    CalendarDetailsModalComponent,
    CalendarDetailsModel
} from "../calendar-details-modal/calendar-details-modal.component";
import { PermissionService } from "projects/portal-modules/src/lib/threads-ui/services/permissions.service";
import {
    CalendarMeetingRequestComponent,
    MeetingRequestModalData
} from "../calendar-meeting-request/calendar-meeting-request.component";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { VcStateBuilder } from "projects/default-plugins/video-chat/services/vc-state-builder";
import { CalendarInstance, CalendarState, MeetingState } from "../../calendar-state.type";
import { ThreadCardService } from "projects/portal-modules/src/lib/threads-ui/services/thread-card.service";
import { CalendarService, ISlot } from "../../services/calendar.service";
import { AnalyticsService, GA_EVENTS, HOT_JAR_EVENTS } from "projects/portal-modules/src/lib/analytics";
import {
    CalendarInstanceData,
    CalendarInstanceModalComponent
} from "../calendar-instance-modal/calendar-instance-modal.component";
import { environmentCommon } from "src/environments/environment";
import { ActionableCardComponent } from "projects/portal-modules/src/lib/shared/components/actionable-card/actionable-card";
import { TaskActionService } from "projects/portal-modules/src/lib/shared/components/actionable-card/task-action.service";
import { ILibrary, TaskAction } from "projects/portal-modules/src/lib/plugins";
import { VideoChatService } from "projects/default-plugins/video-chat/services/video-chat.service";
import { CalendarCardService } from "../../services/calendar-card.service";

const localLoader = () => {
    return new Loader();
};

@Component({
    selector: "calendar-card",
    templateUrl: "./calendar-card.component.html",
    styleUrls: ["./calendar-card.component.scss"],
    providers: [{ provide: Loader, useFactory: localLoader }]
})
export class CalendarCardComponent extends ActionableCardComponent<ISlot> implements OnInit, OnDestroy {
    constructor(
        @Inject(THREAD_CARD_RESOURCES) protected cardResources: CardResources,
        @Inject(TASK_ACTION_LIBRARY) protected taskActions: ILibrary<TaskAction<ISlot>>,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        public loader: Loader,
        private router: Router,
        private threadsService: ThreadsService,
        private cardService: ThreadCardService,
        private calendarService: CalendarService,
        private authService: AuthService,
        private activatedRoute: ActivatedRoute,
        private dialog: MatDialog,
        private participantsCache: ParticipantCache,
        private permissionService: PermissionService,
        private analytics: AnalyticsService,
        protected taskActionService: TaskActionService,
        private videoChatService: VideoChatService
    ) {
        super(cardResources, taskActionService);
        this.meetingState$ = this.cardResources.state$.pipe(map(state => CalendarCardComponent.mapStateType(state)));
    }
    readonly allowEdit = this.environment.featureFlags.editCardDescription;
    readonly notInvitedToMeetingText = this.environment.featureFlags.text.default.notInvitedToMeeting;
    readonly meetingStates = MeetingState;
    readonly gaEvents = GA_EVENTS;
    readonly hotJarEvents = HOT_JAR_EVENTS;
    readonly meetingTimeFormat = environmentCommon.dateFormats.short;
    readonly CALENDAR_SCHEDULE_TASK_ACTION_ID = CalendarAction.SCHEDULE;
    readonly CALENDAR_RESCHEDULE_TASK_ACTION_ID = CalendarAction.RESCHEDULE;

    state$: Observable<CalendarState>;
    private threadId: string;
    private cardId: string;
    private modelBuilder = new VcStateBuilder();

    card$: Observable<IThreadCard>;
    userId$: Observable<string>;
    thread$: Observable<IThread>;
    replies$: Observable<CardReply[]>;
    invitedToMeeting$: Observable<boolean>;
    otherParticipant$: Observable<IParticipant[]> = of([]);
    avatar$: Observable<IAvatarContent[]> = of([]);
    meetingName$: Observable<string>;
    canRescheduleMeeting$: Observable<boolean>;

    subscriptions: Subscription[] = [];
    navigateSub: Subscription;

    edit$ = new Subject<boolean>();

    role: Role;
    roles = Role;
    invitationId: string;
    start: Date;
    end: Date;
    appointmentConfirmed = false;
    invitationCancelled = false;
    errorMessage: string;
    cardStatuses = CardStatus;
    organiser: IParticipant;
    participants: IParticipant[];
    meetingState$: Observable<string>;
    attendees$: Observable<IParticipant[]>;

    vcDetails$ = this.modelBuilder.getState();
    CardStatus = CardStatus;

    // TODO: remove static when refactoring. Was made static to allow for tests.
    static mapStateType(state: CalendarState): MeetingState {
        if (!state?.cancelled && !state?.scheduled) {
            return MeetingState.Request;
        }

        const nextInstance = this.findNextInstance(state.instances);
        const nextTime = nextInstance ? new Date(nextInstance.end).getTime() : 0;

        // TODO: Show meeting has ended after last scheduled instance
        if (state?.cancelled || Date.now() > nextTime) {
            return MeetingState.Ended;
        }
        if (state?.scheduled) {
            return MeetingState.Confirmed;
        }
        return MeetingState.Unknown;
    }

    // TODO: remove static when refactoring. Was made static to allow for tests.
    // TODO think of a better name, it's next or last.
    static findNextInstance(instances: CalendarInstance[]): CalendarInstance {
        const now = Date.now();

        if (instances) {
            const nextMeeting = instances.find(instance => new Date(instance.end).getTime() > now);
            return nextMeeting || instances[instances.length - 1];
        }

        return null;
    }

    async ngOnInit() {
        const { thread$, card$, threadId, cardId, eventService, state$, role, replies$ } = this.cardResources;
        this.userId$ = this.authService.getUser().pipe(
            filter(user => !!user),
            map(user => user.id)
        );

        this.thread$ = thread$;
        this.card$ = card$;
        this.threadId = threadId;
        this.cardId = cardId;
        this.state$ = state$ as Observable<CalendarState>;
        this.role = role;
        this.replies$ = replies$;
        this.subscriptions.push(this.state$.subscribe(result => this.setState(result)));
        this.subscriptions.push(eventService.events.subscribe(event => this.modelBuilder.addEvent(event)));
        this.modelBuilder.setThreadAndState(threadId, cardId);

        this.initializeInvitationId();
        this.triggerReschedule();

        this.vcDetails$
            .pipe(
                filter(details => !!details.sessionId),
                take(1)
            )
            .subscribe(() => {
                this.triggerJoinMeeting();
            });

        const participant$ = combineLatest([this.state$, this.userId$]);

        this.invitedToMeeting$ = this.getInvitedToMeeting(participant$);
        this.canRescheduleMeeting$ = this.invitedToMeeting$.pipe(
            map((isInvited: boolean) => (this.role !== this.roles.Client || isInvited) && this.appointmentConfirmed)
        );

        this.otherParticipant$ = participant$.pipe(
            switchMap(([state, userId]) => {
                const attendees = CalendarCardService.getAllAttendees(state);

                const participantIds = attendees
                    .filter(attendee => attendee.id !== userId)
                    .map(attendee => attendee.id);

                return this.participantsCache.getParticipants(participantIds);
            })
        );

        const createdBy = (await this.card$.pipe(take(1)).toPromise()).createdBy;

        this.organiser = await this.participantsCache.getParticipant(createdBy).toPromise();

        this.avatar$ = this.otherParticipant$.pipe(
            switchMap(participants => this.participantsCache.getMultipleAvatars(participants))
        );

        this.meetingName$ = this.state$.pipe(
            map(state => {
                if (state?.details?.meetingName) {
                    return state.details.meetingName;
                } else {
                    return "Meeting";
                }
            })
        );

        this.attendees$ = this.state$.pipe(
            switchMap(state => {
                const attendees = CalendarCardService.getAllAttendees(state);
                const participantIds = attendees.map(attendee => attendee.id);
                return this.participantsCache.getParticipants(participantIds);
            })
        );
    }

    private getInvitedToMeeting(participant$: Observable<[CalendarState, string]>): Observable<boolean> {
        return participant$.pipe(
            map(([state, userId]) => {
                const attendees = CalendarCardService.getAllAttendees(state);
                return attendees.some(attendee => attendee.id === userId);
            }),
            shareReplay(1)
        );
    }

    private triggerReschedule() {
        const { cardId } = this.activatedRoute.snapshot.params;
        const { reschedule } = this.activatedRoute.snapshot.queryParams;
        if (cardId === this.cardId && reschedule && this.role === Role.Client) {
            this.action(this.CALENDAR_RESCHEDULE_TASK_ACTION_ID);
        }
    }

    private async triggerJoinMeeting() {
        const { cardId } = this.activatedRoute.snapshot.params;
        const { join } = this.activatedRoute.snapshot.queryParams;

        if (cardId === this.cardId && join === "true") {
            this.openFullscreen(true);
            await this.router.navigate([], { queryParams: { join: false } });
        }
    }

    async updateAppointmentAttendees(
        threadId: string,
        cardId: string,
        invitationId: string,
        attendees: ICalendarParticipant[]
    ) {
        if (!invitationId || !attendees) return;

        this.loader.show();
        try {
            const staff = attendees.filter(
                attendee => attendee.role === Role.Administrator || attendee.role === Role.Staff
            );
            const invitees = attendees.filter(attendee => attendee.role === Role.Client);

            await this.calendarService
                .updateAppointmentAttendees(threadId, cardId, invitationId, staff, invitees)
                .toPromise();
        } finally {
            this.loader.hide();
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => {
            sub.unsubscribe();
        });
        if (this.navigateSub) {
            this.navigateSub.unsubscribe();
        }
    }

    async cancelMeeting(): Promise<void> {
        this.loader.show();

        const currentUserId = await this.userId$.pipe(take(1)).toPromise();
        const currentUserInvited = await this.invitedToMeeting$.pipe(take(1)).toPromise();
        const firstAttendeeId = await this.attendees$
            .pipe(
                map(attendees => attendees.slice(0, 1).pop().id),
                take(1)
            )
            .toPromise();

        const attendeeId = currentUserInvited ? currentUserId : firstAttendeeId;

        //Terminate vc
        await this.terminateSession();
        //Set invitation state to Cancelled and remove any existing appointments.
        await this.calendarService.cancelAppointment(this.invitationId, attendeeId).toPromise();

        await this.removeScheduledTasks();
        this.loader.hide();
        this.invitationCancelled = true;
    }

    async removeScheduledTasks(): Promise<void> {
        const subjects = await this.card$
            .pipe(
                map(card => card.subjects),
                take(1)
            )
            .toPromise();
        //Delete all scheduled tasks
        const tasks = subjects.filter(subject => subject.type === SubjectType.ScheduledTask);
        await Promise.all(tasks.map(task => this.threadsService.removeTask(task.id).toPromise()));

        //Remove scheduled tasks from subjects
        const otherSubjects = subjects.filter(subject => !tasks.includes(subject));
        await this.cardService.updateCard(this.threadId, this.cardId, otherSubjects).toPromise();
    }

    initializeInvitationId(): void {
        const { card$ } = this.cardResources;
        this.subscriptions.push(
            card$.subscribe(card => {
                if (card.status !== CardStatus.Removed) {
                    this.invitationId = this.getInvitationId(card);
                }
            })
        );
    }

    private getInvitationId(card: IThreadCard): string {
        const calendarSubjects = card.subjects.filter(cardSubject => cardSubject.type === SubjectType.Calendar);
        if (calendarSubjects.length !== 1) {
            console.error("Did not find exactly 1 calendar subject", calendarSubjects);
            return null;
        }

        const subject = calendarSubjects.pop();
        return subject.id;
    }

    private async setState(state: Partial<CalendarState>) {
        if (state) {
            const { instances, scheduled, cancelled } = state;
            const lastInstance = CalendarCardComponent.findNextInstance(instances);

            if (lastInstance) {
                const { start, end } = lastInstance;
                const startDate = new Date(start);
                const endDate = new Date(end);

                if (!isNaN(startDate.getTime())) {
                    this.start = startDate;
                }

                if (!isNaN(endDate.getTime())) {
                    this.end = endDate;
                }
            }

            this.appointmentConfirmed = scheduled;
            this.invitationCancelled = cancelled;
        }

        if (!this.navigateSub) {
            this.navigateSub = this.cardResources.navigateTo$.subscribe(async () => await this.openCard());
        }
    }

    editMessage() {
        this.edit$.next(true);
    }

    async save(updatedMessage: string) {
        this.errorMessage = null;
        this.loader.show();
        try {
            await this.cardService
                .updateCardDescription(this.threadId, this.cardId, updatedMessage, CardStatus.Edited)
                .toPromise();

            this.loader.hide();
        } catch {
            this.loader.hide();
            this.errorMessage = "Sorry, something went wrong";
        }
    }

    private async openCard() {
        const { join } = this.activatedRoute.snapshot.queryParams;
        if (join || !this.meetingState$ || !this.invitedToMeeting$) return;

        const [meetingState, createPermission, invitedToMeeting] = await forkJoin([
            this.meetingState$.pipe(take(1)),
            this.permissionService.checkPermissions(this.role, "CreateCalendarCard"),
            this.invitedToMeeting$.pipe(take(1))
        ]).toPromise();

        const inviteIsSchedulable =
            !this.invitationCancelled &&
            meetingState !== this.meetingStates.Ended &&
            meetingState !== this.meetingStates.Confirmed;
        const userCanSchedule = !createPermission && inviteIsSchedulable && invitedToMeeting;

        if (userCanSchedule) {
            this.action(this.CALENDAR_SCHEDULE_TASK_ACTION_ID);
        } else if (this.invitationId) {
                this.openDetailsModal();
            }
    }

    async actionCallback(actionId: string, slot: ISlot) {
        switch (actionId) {
            case this.CALENDAR_RESCHEDULE_TASK_ACTION_ID:
                if (!slot) {
                    this.analytics.recordEvent("calendar", this.hotJarEvents.MeetingRescheduleCloseEvent);
                } else {
                    this.handleRescheduleAction();
                }
                break;
            case this.CALENDAR_SCHEDULE_TASK_ACTION_ID:
                if (slot) {
                    this.handleScheduleAction();
                }
                break;
            case VideoChatAction.JOIN_CALL:
                this.promptAndTerminateSession();
                break;
        }
    }

    private handleRescheduleAction() {
        this.appointmentConfirmed = true;
        this.start = null;
        this.end = null;
        this.analytics.recordEvent("calendar", this.hotJarEvents.MeetingRescheduleCompleteEvent);
    }

    private handleScheduleAction() {
        this.appointmentConfirmed = true;
        this.analytics.recordEvent("calendar", this.hotJarEvents.MeetingRescheduleCompleteEvent);
    }

    async openInstanceModal() {
        this.loader.show();

        const state = await this.state$.pipe(take(1)).toPromise();
        if (!state) return null;

        const thread = await this.thread$.pipe(take(1)).toPromise();

        this.loader.hide();

        const options = {
            disableClose: false,
            backdropClass: "modal-backdrop",
            panelClass: ["threads-sidebar", "threads-sidebar--large", "mat-dialog-no-styling"],
            closeOnNavigation: true,
            maxWidth: "100%",
            maxHeight: "100%",
            minHeight: "100%",
            data: { invitationId: this.invitationId, state, thread }
        };

        this.dialog.open<CalendarInstanceModalComponent, CalendarInstanceData>(CalendarInstanceModalComponent, options);
    }

    async openMeetingRequestModal(edit?: boolean) {
        this.loader.show();

        const state = await this.state$.pipe(take(1)).toPromise();
        if (!state) return null;

        const invite = await this.calendarService.getClientInvitation(this.invitationId).toPromise(); //TODO: should be able to get all details from card state, no need to pull invite

        const calendarInvitees = [...invite.staff, ...invite.invitees];
        const participantIds = calendarInvitees.map(attendee => attendee.id);

        //TODO: again, should be able to power this from backend state, in the interest of time/overall calendar cleanup work pending
        const attendees = await this.participantsCache
            .getParticipants(participantIds)
            .pipe(
                map(participants =>
                    participants.map(participant => {
                        const invitee = calendarInvitees.find(calendarInvitee => calendarInvitee.id === participant.id);
                        return {
                            ...participant,
                            required: invitee.required
                        };
                    })
                )
            )
            .toPromise();

        this.loader.hide();
        const thread = await this.thread$.pipe(take(1)).toPromise();

        const detailsData: MeetingRequestModalData = {
            thread,
            edit,
            meetingData: {
                title: state.details.title,
                meetingDescription: invite.message.description,
                numberOfOccurrences: invite.recurrence?.numberOfOccurrences,
                recurrenceType: invite.recurrence?.type,
                attendees,
                duration: invite.duration,
                organiser: invite.organizer.id
            }
        };

        const options = {
            disableClose: false,
            backdropClass: "modal-backdrop",
            panelClass: ["threads-sidebar", "threads-sidebar--large", "mat-dialog-no-styling"],
            closeOnNavigation: true,
            maxWidth: "100%",
            maxHeight: "100%",
            minHeight: "100%",
            height: "100vh",
            data: detailsData
        };

        return this.dialog
            .open<CalendarMeetingRequestComponent, MeetingRequestModalData, ICalendarParticipant[]>(
                CalendarMeetingRequestComponent,
                options
            )
            .afterClosed()
            .subscribe(postMeetingAttendees => {
                if (postMeetingAttendees) {
                    this.updateAppointmentAttendees(
                        this.threadId,
                        this.cardId,
                        this.invitationId,
                        postMeetingAttendees
                    );
                }
            });
    }

    openFullscreen(join?: boolean) {
        if (join) {
            this.action(VideoChatAction.JOIN_CALL);
        } else {
            this.action(VideoChatAction.START_CALL);
        }
    }

    promptAndTerminateSession() {
        return this.action(VideoChatAction.END_SESSION);
    }

    async terminateSession() {
        return await this.vcDetails$
            .pipe(
                take(1),
                switchMap(detail => {
                    const threadId = this.threadId;
                    const cardId = this.cardId;
                    const sessionId = detail.sessionId;
                    if (!sessionId) {
                        return of(null);
                    }
                    const terminate$ = this.videoChatService.terminateSession(sessionId, threadId, cardId);
                    return this.loader.wrap(terminate$);
                })
            )
            .toPromise();
    }

    openDetailsModal() {
        this.analytics.recordEvent("mouse-click", this.gaEvents.CALENDAR_VIEWDETAILS);
        const detailsData: CalendarDetailsModel = {
            state$: this.state$,
            userId$: this.userId$,
            thread$: this.thread$,
            meetingState$: this.meetingState$,
            invitedToMeeting$: this.invitedToMeeting$,
            avatar$: this.avatar$,
            meetingName$: this.meetingName$,
            invitationCancelled: this.invitationCancelled,
            start: this.start,
            end: this.end,
            role: this.role,
            invitationId: this.invitationId,
            appointmentConfirmed: this.appointmentConfirmed,
            organiser: this.organiser
        };

        const dialog = this.dialog.open<
            CalendarDetailsModalComponent,
            CalendarDetailsModel,
            { openCalendarModal?: "reschedule" | "schedule" }
        >(CalendarDetailsModalComponent, {
            disableClose: false,
            backdropClass: "modal-backdrop",
            panelClass: ["calendar-details-modal", "modal-container", "mat-dialog-no-styling"],
            closeOnNavigation: true,
            hasBackdrop: true,
            data: detailsData,
            height: "auto"
        });

        this.subscriptions.push(
            dialog.afterClosed().subscribe(data => {
                this.analytics.recordEvent("mouse-click", this.hotJarEvents.MeetingDetailsViewEvent);
                if (data?.openCalendarModal) {
                    if (data.openCalendarModal === "reschedule") {
                        this.action(this.CALENDAR_RESCHEDULE_TASK_ACTION_ID);
                    } else {
                        this.action(this.CALENDAR_SCHEDULE_TASK_ACTION_ID);
                    }
                }
            })
        );
    }
}
