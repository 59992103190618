import { Component, EventEmitter, Inject, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { getPasswordErrorMessage, customValidators } from "projects/portal-modules/src/lib/shared/validators";
import { SignUpUserPayload } from "../../../services/onboarding.service";
import { EnvironmentSpecificConfig } from "../../../../environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";

@Component({
    selector: "app-create-password",
    templateUrl: "./create-password.component.html",
    styleUrls: ["./create-password.component.scss"]
})
export class CreatePasswordComponent implements OnInit {
    @Input() userDataModel: SignUpUserPayload;
    @Input() errorMessage: string;
    @Output() progress: EventEmitter<SignUpUserPayload> = new EventEmitter();

    readonly textResource = this.environment.featureFlags.text;

    appName = this.environment.appName;
    theme = this.environment.theme;

    form = new FormGroup(
        {
            password: new FormControl(null, [Validators.required, customValidators.passwordValidator]),
            confirmPassword: new FormControl(null, [Validators.required, customValidators.passwordValidator])
        },
        customValidators.passwordsMatchValidator("password", "confirmPassword")
    );

    constructor(@Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig) {}

    ngOnInit() {
        this.form.setValue({
            password: this.userDataModel.password,
            confirmPassword: this.userDataModel.password
        });
    }
    updateParentModel() {
        this.progress.emit(this.form.value);
    }

    getPasswordError(controlName: string, passwordMatchError: boolean): string {
        return getPasswordErrorMessage(controlName, passwordMatchError === false);
    }
}
