<loader-icon *ngIf="loader.counter" color="light"></loader-icon>

<ng-container [ngSwitch]="previewType">
    <pdf-preview *ngSwitchCase="supportedTypes.PDF" [data]="previewData$ | async">
        <ng-content></ng-content>
    </pdf-preview>
    <html-preview *ngSwitchCase="supportedTypes.HTML" [url]="previewData$ | async">
        <ng-content></ng-content
    ></html-preview>

    <div *ngSwitchDefault>Sorry, this file is not supported to preview</div>
</ng-container>
