import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { AnalyticsService } from "projects/portal-modules/src/lib/analytics";

@Component({
    selector: "forgot-password-dialog",
    templateUrl: "./forgot-password-dialog.component.html",
    styleUrls: ["./forgot-password-dialog.component.scss"]
})
export class ForgotPasswordDialogComponent implements OnInit {
    constructor(
        private dialogRef: MatDialogRef<ForgotPasswordDialogComponent>,
        private analyticsService: AnalyticsService
    ) {}

    ngOnInit(): void {
        this.recordAnalyticsEvent("displayed");
    }

    private recordAnalyticsEvent(category: string) {
        this.analyticsService.recordEvent("login", category);
    }

    public closeDialog() {
        this.dialogRef.close();
    }
}
