import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
    AcceptedCurrencies,
    ICustomer,
    ICustomerUpdateParams,
    IInvoice,
    IPayment,
    IPaymentMethod,
    IPrice,
    ISetupIntent,
    ISubscription,
    PaymentMethodTypes
} from "@findex/payments-service-sdk";
import { Observable } from "rxjs/internal/Observable";
import { UrlHttpCodec } from "./url-http-codec";
import { IPaymentInvoiceDetails } from "@findex/threads";
import { ENVIRONMENT } from "src/app/injection-token";
import {
    environmentCommon,
    EnvironmentSpecificConfig
} from "projects/portal-modules/src/lib/environment/environment.common";

interface SetupPaymentMethodPayload {
    threadId: string;
    cardId: string;
    paymentMethodId: string;
    paymentMethodType: PaymentMethodTypes;
    invoiceDetails: IPaymentInvoiceDetails;
    currency: AcceptedCurrencies;
    accountId?: string;
    customerId?: string;
    paymentSubjectId?: string;
}

@Injectable({ providedIn: "root" })
export class PaymentService {
    constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig) {}

    getPrices(): Observable<IPrice[]> {
        const url = `${this.environment.paymentsEndpoints.base}/${environmentCommon.paymentsEndpoints.prices}`;
        return this.http.get<IPrice[]>(url);
    }

    async completePayment(
        threadId: string,
        paymentId: string,
        paymentMethodId: string,
        removeFailedCard: boolean,
        currency
    ): Promise<IPayment> {
        const taxRateId = this.environment.payments.taxRateIds[currency];
        const body = {
            threadId,
            paymentMethodId,
            removeFailedCard,
            taxRate: {
                taxRateId
            }
        };
        const url = `${this.environment.paymentsEndpoints.base}/${environmentCommon.paymentsEndpoints.payment}/${paymentId}/${environmentCommon.paymentsEndpoints.completePayment}`;

        return this.http.post<IPayment>(url, body, {}).toPromise();
    }

    async activateSubscription(
        threadId: string,
        subscriptionId: string,
        paymentMethodId: string,
        removeFailedCard: boolean
    ): Promise<ISubscription> {
        const body = {
            threadId,
            paymentMethodId,
            removeFailedCard
        };
        const url = `${this.environment.paymentsEndpoints.base}/${environmentCommon.paymentsEndpoints.subscription}/${subscriptionId}/${environmentCommon.paymentsEndpoints.activateSubscription}`;
        return this.http.post<ISubscription>(url, body).toPromise();
    }
    async getSubscription(subscriptionId: string, threadId: string): Promise<ISubscription> {
        const url = `${this.environment.paymentsEndpoints.base}/${environmentCommon.paymentsEndpoints.subscription}`;
        const params = UrlHttpCodec.parseParams({ threadId });

        return this.http
            .get<ISubscription>(`${url}/${subscriptionId}`, { params })
            .toPromise();
    }
    getAccountCustomer(accountId: string): Observable<ICustomer> {
        const url = `${this.environment.paymentsEndpoints.base}/${environmentCommon.paymentsEndpoints.account}/${accountId}/${environmentCommon.paymentsEndpoints.customer}`;
        return this.http.get<ICustomer>(url, {});
    }
    getCurrentUserPaymentDetails(): Observable<ICustomer[]> {
        const url = `${this.environment.paymentsEndpoints.base}/${environmentCommon.paymentsEndpoints.userDetails}/${environmentCommon.paymentsEndpoints.currentUserDetails}`;
        return this.http.get<ICustomer[]>(url, {});
    }

    getUserPaymentDetails(userId: string): Observable<ICustomer[]> {
        const url = `${this.environment.paymentsEndpoints.base}/${environmentCommon.paymentsEndpoints.userDetails}/${userId}`;
        return this.http.get<ICustomer[]>(url, {});
    }

    getPaymentMethods(customerId: string): Observable<IPaymentMethod[]> {
        const url = `${this.environment.paymentsEndpoints.base}/${environmentCommon.paymentsEndpoints.paymentMethod}`;
        return this.http.get<IPaymentMethod[]>(`${url}/${customerId}`);
    }

    updateCustomer(customerId: string, updateCustomerParams: ICustomerUpdateParams): Observable<ICustomer> {
        const url = `${this.environment.paymentsEndpoints.base}/${environmentCommon.paymentsEndpoints.customer}`;
        const payload = {
            updateCustomerParams
        };
        return this.http.put<ICustomer>(`${url}/${customerId}`, payload);
    }

    setDefaultPaymentMethod(customerId: string, paymentMethodId: string): Observable<ICustomer> {
        const url = `${this.environment.paymentsEndpoints.base}/${environmentCommon.paymentsEndpoints.setDefaultPaymentMethod}`;
        const payload = {
            paymentMethodId,
            customerId
        };
        return this.http.put<ICustomer>(`${url}`, payload);
    }

    removePaymentMethod(customerId: string, paymentMethodId: string): Observable<IPayment> {
        const url = `${this.environment.paymentsEndpoints.base}/${environmentCommon.paymentsEndpoints.removePaymentMethod}`;
        return this.http.delete<IPayment>(`${url}/${customerId}/${paymentMethodId}`);
    }

    listInvoices(customerId: string): Observable<IInvoice[]> {
        const url = `${this.environment.paymentsEndpoints.base}/${environmentCommon.paymentsEndpoints.invoices}`;
        const params = UrlHttpCodec.parseParams({ customerId });

        return this.http.get<IInvoice[]>(url, { params });
    }
    async setupPaymentMethod(
        paymentMethodToken: string,
        paymentMethodType: PaymentMethodTypes,
        invoiceDetails: IPaymentInvoiceDetails,
        currency: AcceptedCurrencies,
        accountId?: string,
        customerId?: string
    ): Promise<ISetupIntent> {
        const taxRateId = this.environment.payments.taxRateIds[currency];
        const body = {
            paymentMethodToken,
            paymentMethodType,
            invoiceDetails,
            taxRate: {
                taxRateId
            },
            currency,
            accountId,
            customerId
        };
        const url = `${this.environment.paymentsEndpoints.base}/${environmentCommon.paymentsEndpoints.setupIntent}/${environmentCommon.paymentsEndpoints.paymentMethod}`;
        return this.http.post<ISetupIntent>(url, body).toPromise();
    }

    async setupPaymentMethodForCard(payload: SetupPaymentMethodPayload): Promise<void> {
        const {
            paymentMethodId,
            paymentMethodType,
            invoiceDetails,
            currency,
            accountId,
            customerId,
            threadId,
            cardId,
            paymentSubjectId
        } = payload;
        const taxRateId = this.environment.payments.taxRateIds[currency];
        const body = {
            paymentMethodId,
            paymentMethodType,
            invoiceDetails,
            taxRate: {
                taxRateId
            },
            currency,
            accountId,
            customerId,
            threadId,
            cardId,
            description: "Payment Method Added",
            paymentObjectId: paymentSubjectId
        };
        const url = `${this.environment.paymentsEndpoints.base}/${environmentCommon.paymentsEndpoints.setupIntent}/${environmentCommon.paymentsEndpoints.paymentMethodPaymentCard}`;
        return this.http.post<void>(url, body).toPromise();
    }
}
