import { ComponentPortal, ComponentType } from "@angular/cdk/portal";
import { Component, Injector, Input, OnChanges, SimpleChanges } from "@angular/core";

@Component({
    selector: "view-extension",
    templateUrl: "./view-extension.component.html",
    styleUrls: ["./view-extension.component.scss"]
})
export class ViewExtensionComponent implements OnChanges {
    @Input() componentRef: ComponentType<unknown>;
    @Input() injector: Injector;

    mounted: ComponentPortal<unknown>;

    constructor() {}

    ngOnChanges(changes: SimpleChanges) {
        const { component, injector } = changes;

        if (component || injector) {
            this.mounted = this.getMounted(this.componentRef, this.injector);
        }
    }

    private getMounted(component: ComponentType<unknown>, injector: Injector): ComponentPortal<unknown> {
        if (!component || !injector) {
            return null;
        }

        return new ComponentPortal(component, null, injector);
    }
}
