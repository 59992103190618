<div
    class="card"
    [ngClass]="{
        'card--inverted': invertTheme,
        'card--conversation': conversation,
        'card--align-left': align === 'left',
        'card--align-right': align === 'right',
        'card--align-centre': align === 'centre'
    }"
>
    <div class="card-avatar" *ngIf="name | isSystemName">
        <fx-avatar class="system-avatar" [border]="false" [name]="appName" [image]="'./assets/images/myfindex-system-avatar.svg'"></fx-avatar>
    </div>
    <div class="card-avatar" *ngIf="!(name | isSystemName) && (image || name)">
        <fx-avatar [name]="name" [image]="image" [border]="false" [secondary]="type === 'secondary'"></fx-avatar>
    </div>
    <div
        class="card-panel"
        [ngClass]="{
            'card-panel--line-card': lineCard,
            'card-panel--primary': type === 'primary',
            'card-panel--secondary': type === 'secondary'
        }"
    >
        <div class="card-main-content">
            <div class="card-name" *ngIf="name">
                {{ name }}
            </div>
            <div class="card-right" *ngIf="timestamp">
                {{ timestamp | date: "dd/MM/yy, h:mm a" }}
                <div class="card-actions">
                    <span class="card-edited" *ngIf="edited">Edited</span>
                    <ng-content select="[card-actions]"></ng-content>
                </div>
            </div>
            <div class="card-title" *ngIf="title">{{ title }}</div>
            <div class="card-loader" *ngIf="loading; else defaultContent">
                <ng-content select="[card-loader]"></ng-content>
            </div>
            <ng-template #defaultContent>
                <ng-container *ngIf="!(edit | async); else editing">
                    <div class="card-description" *ngIf="description">
                        <quill-view
                            [style]="quillStyles"
                            [content]="description | allowWhitelistLinks"
                            aria-label="message"
                            #descriptionComponent
                            (onEditorCreated)="observeQuill()"
                        ></quill-view>
                    </div>
                </ng-container>
                <ng-template #editing>
                    <div class="card-edit-panel">
                        <quill-editor
                            [styles]="quillStyles"
                            analyticsClick="app_typeamessage"
                            [placeholder]="'Type a description here...'"
                            aria-label="message"
                            (onEditorCreated)="$event.focus()"
                            [ngModel]="description"
                            (onContentChanged)="updatedDescription = $event.html"
                        >
                        </quill-editor>
                        <div class="card-edit-panel-buttons">
                            <button
                                class="fx-btn fx-btn--primary fx-btn--white"
                                type="button"
                                role="button"
                                (click)="cancelEdit($event)"
                            >
                                Discard
                            </button>
                            <button
                                class="fx-btn fx-btn--primary-outlined"
                                type="button"
                                role="button"
                                [disabled]="!updatedDescription || description === updatedDescription"
                                (click)="saveEdit($event)"
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </ng-template>
            </ng-template>
            <div class="card-content">
                <ng-content></ng-content>
            </div>
        </div>
        <ng-content select="[card-replies]"></ng-content>
    </div>
</div>
