import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { IThread, IThreadCard, Account } from "@findex/threads";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environmentCommon, EnvironmentSpecificConfig } from "../../environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";
import { IEnrichedThreadListing } from "../../threads-ui/components/threads-list-route/threads-list-route.component";

export type IInitialVaultPayload = {
    description: string;
    documents: {
        description: string;
        category: string;
    }[];
};

type OnboardingResponse = {
    thread: IThread;
    card?: IThreadCard;
    appointmentId: string;
};

@Injectable({ providedIn: "root" })
export class PortalService {
    constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig) {}

    createOnboarding(
        participantId: string,
        createMeeting: boolean,
        initialVaultPayload?: IInitialVaultPayload
    ): Observable<OnboardingResponse> {
        const { base } = this.environment.sigmaEndpoints;
        const { onboarding } = environmentCommon.sigmaEndpoints;
        const url = `${base}${onboarding}`;

        const appName = this.environment.appName;

        return this.http.post<OnboardingResponse>(url, {
            participantId,
            createMeeting,
            appName,
            threadType: this.environment.featureFlags.initialThreadDetails.threadType,
            initialVaultPayload
        });
    }

    getThreadList(filterParams?: { status: string }): Observable<IEnrichedThreadListing[]> {
        const { base } = this.environment.commonEndpoints;
        const { timelines } = environmentCommon.threadsEndpoints;
        const baseUrl = `${base}${timelines}`;
        const url = this.appendQuery(filterParams, baseUrl);

        return this.http.get<{ data: IEnrichedThreadListing[] }>(url).pipe(map(({ data }) => data));
    }

    getThreadListById(threadId: string): Observable<IEnrichedThreadListing> {
        const { base } = this.environment.commonEndpoints;
        const { timelines } = environmentCommon.threadsEndpoints;
        const url = `${base}${timelines}/${threadId}`;
        return this.http.get<{ data: IEnrichedThreadListing }>(url).pipe(map(({ data }) => data));
    }

    getMyDashboard(): Observable<string> {
        const { base } = this.environment.sigmaEndpoints;
        const { dashboard } = environmentCommon.sigmaEndpoints;
        const url = `${base}${dashboard}`;
        return this.http.get<{ dashboardThreadId: any }>(url).pipe(map(({ dashboardThreadId }) => dashboardThreadId));
    }

    getDashboard(userId: string): Observable<string> {
        const { base } = this.environment.sigmaEndpoints;
        const { dashboard } = environmentCommon.sigmaEndpoints;
        const url = `${base}${dashboard}/${userId}`;
        return this.http.get<{ dashboardThreadId: any }>(url).pipe(map(({ dashboardThreadId }) => dashboardThreadId));
    }

    async updateContactAccountMetadata(contactId: string, accountMetadata: any): Promise<void> {
        const { contacts } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.sigmaEndpoints;
        const url = `${base}${contacts}/${contactId}/account`;
        await this.http
            .post<Account>(url, { accountMetadata })
            .toPromise();
    }

    private appendQuery(filterParams: Record<string, string>, baseUrl: string) {
        if (!filterParams) return baseUrl;

        return Object.entries(filterParams).reduce((acc, [key, val], i) => {
            if (i === 0) {
                return encodeURI(`${acc}?${key}=${val}`);
            }
            return encodeURI(`${acc}&${key}=${val}`);
        }, baseUrl);
    }
}
