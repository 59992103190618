<li class="menu-item">
    <a
        [routerLink]="route"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: exact }"
    >
        <i *ngIf="icon" class="las {{ icon }}"></i>
        <div class="menu-item-content">
            <ng-content></ng-content>
        </div>
    </a>
</li>