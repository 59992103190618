<centered-modal-layout
    cancelButtonTitle="Close"
    saveButtonTitle="Continue"
    [analyticsPrefix]="ANALYTICS_PREFIX"
    [disableSave]="!currentActionSelection || (currentActionSelection.templates && !currentTemplateSelection)"
    (save)="openCreateModal()"
    (close)="close()"
>
    <div modal-header>New request</div>
    <div modal-subhead>Create a request from scratch or prefilled data.</div>
    <div modal-content>
        <div class="pre-create-request-menu-options">
            <ng-container *ngFor="let menuItem of menuOptions">
                <div
                    *ngIf="menuItem.templates === undefined || menuItem.templates?.length"
                    class="pre-create-request-menu-options-option"
                >
                    <fx-checkbox
                        (change)="selectionActionType(menuItem)"
                        [checked]="currentActionSelection == menuItem"
                    >
                        {{ menuItem.title }}
                    </fx-checkbox>
                    <div class="pre-create-request-menu-options-option-description">
                        {{ menuItem.description }}
                        <div *ngIf="menuItem.templates?.length && currentActionSelection == menuItem">
                            <fx-select
                                (change)="templateSelection($event)"
                                placeholder="Select template"
                                analyticsClick="{{ ANALYTICS_PREFIX }}_dropdown"
                            >
                                <fx-option
                                    *ngFor="let template of menuItem.templates"
                                    [value]="template"
                                    analyticsClick="{{ ANALYTICS_PREFIX }}_{{ template.title }}"
                                    >{{ template.title }}</fx-option
                                >
                            </fx-select>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</centered-modal-layout>
