import { Component, Input, Output, EventEmitter } from "@angular/core";
import { FormControl } from "@angular/forms";
import { environmentCommon } from "../../../environment/environment.common";
import { QuillEditorService } from "projects/portal-modules/src/lib/shared/services/quill-editor.service";

@Component({
    selector: "quill-editor-wrapper",
    templateUrl: "./quill-editor-wrapper.component.html",
    styleUrls: ["./quill-editor-wrapper.component.scss"]
})
export class QuillEditorWrapperComponent {
    @Output() userInputEmitter = new EventEmitter<FormControl>();
    @Output() userBlurEmitter = new EventEmitter<FormControl>();
    @Output() error = new EventEmitter<boolean>();

    @Input() message: FormControl;
    @Input() placeholder: string;
    @Input() inline = false;
    @Input() readOnly = false;
    @Input() autoFocusOnInit = true;

    toolbar = false;
    withToolbar = environmentCommon.quillConfig.toolbarState.withToolbar;
    quillStyles = environmentCommon.quillConfig.styling;
    quillContainer = environmentCommon.quillConfig.bound;
    characterError: boolean;

    constructor(private quillEditorService: QuillEditorService) {}

    toggleToolbar() {
        if (!this.readOnly) {
            this.toolbar = !this.toolbar;
        }
    }

    onEditorCreated(quill): void {
        this.autoFocus(quill);
        this.quillEditorService.updateContentEditorOnSanitizeLink(quill);
    }

    autoFocus(quill) {
        if (!this.readOnly && this.autoFocusOnInit) {
            quill.focus();
        }
    }

    onChange() {
        this.validateInput();
        this.userInputEmitter.emit(this.message);
    }

    validateInput() {
        const val = this.message.value?.length > 200000;
        this.characterError = val;
        this.error.emit(val);
    }
}
