<div class="user-profile">
    <loader-icon *ngIf="loader.counter" [color]="'light'"></loader-icon>

    <form class="fx-form fx-form--dark">
        <div class="user-profile-header">
            <fx-avatar
                class="user-profile-header-avatar"
                [name]="avatarNameString"
                [image]="userId | profilePicture"
                size="medium">
            </fx-avatar>
            <div class="user-profile-header-name">
                {{ avatarNameString }}
            </div>
        </div>
    </form>

    <fx-tabs class="account-tabs" [mobileScroll]="true" #tabs>

        <fx-tabs-item
            header="Profile"
            #profileTab>
            <general-settings-component (userProfile)="getUserProfile($event)"></general-settings-component>
        </fx-tabs-item>

        <fx-tabs-item
            *ngIf="hasInsightsPermission$ | async"
            header="Insights Permissions"
            #insightsPermissionsTab>
            <insights-permissions-component [userId]="userId"></insights-permissions-component>
        </fx-tabs-item>
    </fx-tabs>
</div>