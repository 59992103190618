import { HttpParameterCodec, HttpParams } from "@angular/common/http";

export class UrlHttpCodec implements HttpParameterCodec {
    encodeKey(key: string): string {
        return encodeURIComponent(key);
    }

    encodeValue(value: string): string {
        return encodeURIComponent(value);
    }

    decodeKey(key: string): string {
        return decodeURIComponent(key);
    }

    decodeValue(value: string): string {
        return decodeURIComponent(value);
    }

    static parseParams(params: { [key: string]: string }): HttpParams {
        return new HttpParams({
            encoder: new UrlHttpCodec(),
            fromObject: params
        });
    }
}
