import { Component, EventEmitter, Input, Output } from "@angular/core";
import { HOT_JAR_EVENTS } from "../../../analytics/services/hotjarAnalytics.service";

@Component({
    selector: "reply-buttons",
    templateUrl: "./reply-buttons.component.html",
    styleUrls: ["./reply-buttons.component.scss"]
})
export class ReplyButtonsComponent {

    @Input() public discardTitle = "Discard";
    @Input() public submitTitle = "Submit";
    @Input() public discardDisabled: boolean;
    @Input() public submitDisabled: boolean;

    @Output() public discardOutput = new EventEmitter<void>();
    @Output() public submitOutput = new EventEmitter<void>();

    readonly hotJarEvents = HOT_JAR_EVENTS;

    constructor() {}

    public onSubmit(): void {
        this.submitOutput.emit();
    }

    public onDiscard(): void {
        this.discardOutput.emit();
    }

}
