<div class="user-profile-dropdown" *ngIf="user">
    <div class="user-profile-dropdown-header">
        <fx-avatar [name]="user.name" [image]="user.id | profilePicture" size="large"></fx-avatar>
        <div class="user-profile-dropdown-user">
            <div class="user-profile-dropdown-user-name">
                {{ user.name }}
            </div>
            <a
                class="user-profile-dropdown-user-profile"
                [routerLink]="['profile']"
                analyticsClick="app_viewprofile"
                routerLinkActive="active"
                (click)="hideProfileMenu()"
                >Edit profile</a
            >
        </div>
    </div>
    <div class="user-profile-dropdown-segment">
        <a
            *ngIf="showSwitchAccount"
            class="user-profile-dropdown-item"
            analyticsClick="app_switchuser"
            role="button"
            (click)="switchAccount.emit(true)"
            (click)="hideProfileMenu()"
            >Switch User</a
        >
        <a
            *ngIf="supportEmail"
            class="user-profile-dropdown-item"
            href="mailto:{{ supportEmail }}"
            analyticsClick="app_contactsupport"
            (click)="hideProfileMenu()"
            >Email Support</a
        >
        <a
            *ngIf="termsOfUseUrl"
            class="user-profile-dropdown-item"
            [href]="termsOfUseUrl"
            analyticsClick="app_termsofuse"
            (click)="hideProfileMenu()"
            >Terms of Use</a
        >
    </div>
    <div class="user-profile-dropdown-segment">
        <a
            class="user-profile-dropdown-item"
            (click)="logout.emit(true)"
            analyticsClick="app_logout"
            role="button"
            (click)="hideProfileMenu()"
            >Sign Out</a
        >
    </div>
</div>
