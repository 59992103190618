import { VAULT_ACTION } from "@findex/vault";
import { IVaultGroup } from "../../../interfaces/IVaultGroup";
import { IVaultItem } from "../../../interfaces/IVaultItem";

export class VaultCountService {
    static countVaultFiles(groups: IVaultGroup[]): number {
        return groups?.reduce((count, group) => count + (group?.items?.length || 0), 0) || 0;
    }

    static countActions(groups: IVaultGroup[], actions: VAULT_ACTION[]): number {
        return groups?.reduce((count, group) => count + this.countActionsInGroup(group, actions), 0) || 0;
    }

    private static countActionsInGroup(group: IVaultGroup, actions: VAULT_ACTION[]): number {
        return group?.items.reduce((count, item) => count + this.countActionsInItem(item, actions), 0) || 0;
    }

    private static countActionsInItem(item: IVaultItem, actions: VAULT_ACTION[]): number {
        const hasAction = actions.some(action => item.actions.some(itemAction => itemAction === action));
        if (hasAction) {
            return item.files?.length || 1;
        } else {
            return 0;
        }
    }
}
