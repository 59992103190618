<div class="calendar-review-meeting" *ngIf="data">
    <h2>
        Are these meeting details correct?
    </h2>
    <div class="calendar-review-meeting-details">
        <div class="calendar-review-meeting-details-item">
            <div class="calendar-review-meeting-details-item-icon">
                <i class="las la-calendar"></i>
            </div>
            <div class="calendar-review-meeting-details-title">
                <strong>{{ data.title }}</strong>
            </div>
        </div>

        <div class="calendar-review-meeting-details-item">
            <div class="calendar-review-meeting-details-item-icon">
                <i class="las la-user"></i>
            </div>
            <div class="calendar-review-meeting-details-participants">
                <div
                    class="calendar-review-meeting-details-participants-item"
                    *ngFor="let participants of data.attendees"
                >
                    <fx-avatar
                        [name]="participants.name"
                        [image]="participants.id | profilePicture"
                        size="medium"
                    ></fx-avatar>
                    <span class="calendar-review-meeting-details-participants-text">
                        {{ participants.name }}
                    </span>
                </div>
            </div>
        </div>

        <div class="calendar-review-meeting-details-item">
            <div class="calendar-review-meeting-details-item-icon">
                <i class="las la-stopwatch"></i>
            </div>
            <div>
                <strong>{{ data.date.start | date: "EEEE d MMM yyyy" }}</strong>
                <br />
                {{ data.date.start | date: meetingTimeFormat }} - {{ data.date.end | date: meetingTimeFormat }}
            </div>
        </div>

        <div class="calendar-review-meeting-details-item" *ngIf="data.recurrence">
            <div class="calendar-review-meeting-details-item-icon">
                <i class="las la-sync"></i>
            </div>
            <div>
                Repeat:
                <span>
                    <strong>
                        {{ data.recurrence.type | titlecase
                        }}<ng-container *ngIf="data.recurrence.type !== 'never'"
                            >,{{ data.recurrence.numberOfOccurrences }} times</ng-container
                        >
                    </strong>
                </span>
            </div>
        </div>
        <div class="calendar-review-meeting-details-item">
            <div class="calendar-review-meeting-details-item-icon">
                <i class="las la-map-marker"></i>
            </div>
            <div>
                <div>
                    <strong>{{ appName }} Platform</strong>
                </div>
                <div>
                    Video Call (you will be sent a reminder)
                </div>
            </div>
        </div>
        <div class="calendar-review-meeting-details-item" *ngIf="data.message">
            <div class="calendar-review-meeting-details-item-icon">
                <i class="las la-comment"></i>
            </div>
            <div>
                <quill-view [content]="data.message | allowWhitelistLinks" aria-label="message">
                </quill-view>
            </div>
        </div>
    </div>
</div>
