export enum MobileVerifyStatus {
    OK = "OK",
    INCORRECT_CODE = "INCORRECT_CODE",
    EXPIRED = "EXPIRED",
    UNKNOWN_ERROR = "UNKNOWN_ERROR",
    VERIFIED = "VERIFIED",
    THROTTLED = "THROTTLED",
    MOBILE_IN_USE = "MOBILE_IN_USE",
    REQUEST_ERROR = "REQUEST_ERROR"
}

export interface IMobileVerifyResponse {
    status: MobileVerifyStatus;
    message: string;
}

export interface MobileVerifyService {
    requestMFACode(phoneNo: string): Promise<IMobileVerifyResponse>;
    validateMFACode(code: string): Promise<IMobileVerifyResponse>;
}
