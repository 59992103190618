import { Component, Input } from "@angular/core";

export enum GhostLoaderShape {
    Round = "round",
    Square = "square"
}

@Component({
    selector: "ghost-loader",
    templateUrl: "./ghost-loader.component.html",
    styleUrls: ["./ghost-loader.component.scss"]
})
export class GhostLoaderComponent {
    @Input() width = 100;
    @Input() height = 100;
    @Input() animation = false;
    @Input() shape: "round" | "square" = GhostLoaderShape.Square;
    @Input() light: string;
    @Input() secondary: string;
    @Input() showAnimation: boolean;

    GhostLoaderShape = GhostLoaderShape;

    constructor() {}
}
