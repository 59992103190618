import { Injectable } from "@angular/core";
@Injectable()
export class QuillEditorService {
    TYPE_USER_SOURCE_EDITOR = "user";
    PROTOCOL_WHITELIST = ["https", "mailto", "tel", "radar", "rdar", "smb", "sms"];
    private sanitizeLink(sanitizedUrl: string): string {
        if (!sanitizedUrl || sanitizedUrl === 'about:blank') return sanitizedUrl;
        try {
            const newURL = new URL(sanitizedUrl);
            if (this.hasWhitelistedProtocol(newURL)) return sanitizedUrl;
            if (newURL.protocol === "http:") return sanitizedUrl.replace(/^http:\/\//i, 'https://');
        }
        catch (error) {
            if (error instanceof TypeError) return `https://${sanitizedUrl}`;
        } 
        return `https://${sanitizedUrl}`;   
    }

    hasWhitelistedProtocol(url:URL): boolean {
        return this.PROTOCOL_WHITELIST.some((protocol: string) => {
            return url.protocol.startsWith(protocol);
        });
    } 
    //returning type unknown because we don't know external type from library quill 
    private getNewDeltaSanitized(delta): unknown {
        const newOps = delta.ops.map(op => 
            (op.attributes && op.attributes.link) ? { ...op, attributes: { ...op.attributes, link: this.sanitizeLink(op.attributes.link) }} : op);
        return { ...delta, ops: newOps };
    }

    updateContentEditorOnSanitizeLink(quill): void {
        quill.on('text-change', (delta, _oldDelta, source) => {
            if (source !== this.TYPE_USER_SOURCE_EDITOR) return;
            if (delta.ops && delta.ops.length <= 0 ) return;
            const findIndex = delta.ops.findIndex(op => (op.attributes && op.attributes.link));
            if (findIndex === -1) return;
            const newDelta = this.getNewDeltaSanitized(delta);
            quill.updateContents(newDelta);
        });
    }
}
