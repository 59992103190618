<loader-icon *ngIf="loader.counter" color="light"></loader-icon>
<ng-container *ngIf="{ requests: requests$ | async, role: role$ | async, userId: userId$ | async }; let context">
    <div class="requests">
        <div class="requests-empty" *ngIf="context.requests?.length === 0">
            <span>There are no requests in this timeline</span>
        </div>

        <div *ngIf="context?.requests?.length">
            <fx-table [tableData]="tableData" [selectable]="false">
                <ng-container
                    *fxColumn="
                        'title';
                        label: 'Title';
                        sortable: true;
                        mobileCollapse: true;
                        mobileHideLabel: true;
                        let request
                    "
                >
                    <div class="requests-title" (click)="openRequestModal(request)">
                        {{ request.title }}
                    </div>
                </ng-container>
                <ng-container
                    *fxColumn="
                        'card.createdBy';
                        label: 'Created By';
                        sortable: true;
                        mobileCollapse: true;
                        mobileHideLabel: true;
                        let request
                    "
                >
                    <div class="requests-user" (click)="openRequestModal(request)">
                        <fx-avatar
                            class="requests-user-avatar"
                            [name]="request.card.createdBy | userToName | async"
                            [image]="request.card.createdBy | profilePicture"
                            size="small"
                        ></fx-avatar>
                        <div>
                            {{ request.card.createdBy | userToName | async }}
                        </div>
                    </div>
                </ng-container>
                <ng-container
                    *fxColumn="
                        'card.dueDate';
                        label: 'Due Date';
                        sortable: true;
                        mobileCollapse: true;
                        mobileHideLabel: true;
                        let request
                    "
                >
                    <div>
                        {{ request.card?.dueDate | date: "dd/MM/yy" }}
                    </div>
                </ng-container>
                <ng-container
                    *fxColumn="
                        'dateTillDue';
                        label: 'Days till due';
                        sortable: false;
                        mobileCollapse: true;
                        mobileHideLabel: false;
                        let request
                    "
                >
                    <div>
                        {{ request.card?.dueDate | timeOverDue }}
                    </div>
                </ng-container>
                <ng-container
                    *fxColumn="
                        'progress';
                        label: 'Progress';
                        sortable: true;
                        mobileCollapse: true;
                        mobileHideLabel: true;
                        let request
                    "
                >
                    <div (click)="openRequestModal(request)">
                        <progress-bar [progress]="request.progress"></progress-bar>
                    </div>
                </ng-container>
                <ng-container
                    *fxColumn="
                        'status';
                        label: 'Status';
                        sortable: true;
                        mobileCollapse: true;
                        mobileHideLabel: true;
                        let request
                    "
                >
                    <fx-badge
                        *ngIf="
                            request.card.status === cardStatuses.Disabled ||
                                request.card.status === cardStatuses.Removed;
                            else openRequest
                        "
                        [label]="'Cancelled'"
                        [type]="'error'"
                    >
                    </fx-badge>
                    <ng-template #openRequest>
                        <fx-badge
                            (click)="openRequestModal(request)"
                            [label]="request.progress === 100 ? 'Completed' : 'Pending'"
                            [type]="request.progress === 100 ? 'success' : 'default'"
                        ></fx-badge>
                    </ng-template>
                </ng-container>
            </fx-table>
        </div>
    </div>
</ng-container>
