<ng-container *ngIf="form" [formGroup]="form">
    <div class="fx-form-group">
        <label class="fx-form-label" for="dateOfBirth">Date of birth</label>
        <div class="fx-form-control">
            <input
                [formControlName]="'dateOfBirth'"
                id="dateOfBirth"
                class="fx-form-input"
                type="text"
                placeholder="e.g. dd/mm/yyyy"
                aria-label="Date of birth"
                [ngClass]="{
                    'fx-form-input--error': !form.controls['dateOfBirth'].valid && form.controls['dateOfBirth'].touched
                }"
            />
        </div>
        <div
            class="fx-form-validation"
            *ngIf="form.controls['dateOfBirth'].hasError('required') && form.controls['dateOfBirth'].touched"
        >
            Date of birth is required.

            <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
        </div>
        <div
            class="fx-form-validation"
            *ngIf="form.controls['dateOfBirth'].hasError('date') && form.controls['dateOfBirth'].touched"
        >
            Date of birth is invalid, please enter a valid dd/mm/yyyy date.
            <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
        </div>
    </div>
    <div class="fx-form-group">
        <label class="fx-form-label" for="taxFileNumber">TFN</label>
        <div class="fx-form-control">
            <input
                formControlName="taxFileNumber"
                id="taxFileNumber"
                class="fx-form-input"
                type="text"
                placeholder="e.g. 123456782"
                aria-label="Tax file number"
                [ngClass]="{
                    'fx-form-input--error':
                        !form.controls['taxFileNumber'].valid && form.controls['taxFileNumber'].touched
                }"
            />
        </div>
        <div
            class="fx-form-validation"
            *ngIf="form.controls['taxFileNumber'].hasError('required') && form.controls['taxFileNumber'].touched"
        >
            TFN is required.

            <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
        </div>
        <div
            class="fx-form-validation"
            *ngIf="form.controls['taxFileNumber'].hasError('taxFileNumber') && form.controls['taxFileNumber'].touched"
        >
            TFN is invalid.
            <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
        </div>
    </div>

    <div class="fx-form-group" *ngIf="form.controls.isReturningCustomer; let control">
        <label>Have you used {{ appName }} services before?</label>
        <div class="half-inputs">
            <fx-checkbox (change)="control.setValue(true)" [checked]="control.value === true">Yes</fx-checkbox>
            <fx-checkbox (change)="control.setValue(false)" [checked]="control.value === false">No</fx-checkbox>
        </div>
    </div>

    <ng-container *ngIf="form.controls.idType; let control">
        <div class="fx-form-group">
            <label>ID type</label>
            <div class="half-inputs">
                <fx-checkbox
                    (change)="
                        control.setValue('licence');
                        form.controls.idNumber.setValue('');
                        form.controls.idExpiry.setValue('')
                    "
                    [checked]="control.value === 'licence'"
                    >Driver licence</fx-checkbox
                >
                <fx-checkbox
                    (change)="
                        control.setValue('passport');
                        form.controls.idNumber.setValue('');
                        form.controls.idExpiry.setValue('')
                    "
                    [checked]="control.value === 'passport'"
                    >Passport</fx-checkbox
                >
            </div>
        </div>

        <div class="half-inputs" *ngIf="control.value === 'licence'">
            <threads-input formControlName="idNumber">Licence Number</threads-input>
            <threads-input formControlName="idExpiry">Licence Expiry</threads-input>
        </div>

        <div class="half-inputs" *ngIf="control.value === 'passport'">
            <threads-input formControlName="idNumber">Passport Number</threads-input>
            <threads-input formControlName="idExpiry">Passport Expiry</threads-input>
        </div>
    </ng-container>

    <ng-container *ngIf="role$ | async | permissions: 'UpdateClientUsers' | async">
        <div class="fx-form-group" *ngIf="form.controls['bankName']; let control">
            <label class="fx-form-label" for="bankName">Bank</label>
            <div class="fx-form-control">
                <input
                    formControlName="bankName"
                    id="bankName"
                    class="fx-form-input"
                    type="text"
                    placeholder="not yet supplied"
                    aria-label="Bank"
                    [ngClass]="{
                        'fx-form-input--error': !control.valid && control.touched
                    }"
                />
            </div>
            <div class="fx-form-validation" *ngIf="control.hasError('required') && control.touched">
                Bank is required.

                <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
            </div>
        </div>
        <div class="fx-form-group" *ngIf="form.controls['accountName']; let control">
            <label class="fx-form-label" for="accountName">Account name</label>
            <div class="fx-form-control">
                <input
                    formControlName="accountName"
                    id="accountName"
                    class="fx-form-input"
                    type="text"
                    placeholder="not yet supplied"
                    aria-label="Account name"
                    [ngClass]="{
                        'fx-form-input--error': !control.valid && control.touched
                    }"
                />
            </div>
            <div class="fx-form-validation" *ngIf="control.hasError('required') && control.touched">
                Account name is required.

                <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
            </div>
        </div>
        <div class="half-width">
            <div class="fx-form-group" *ngIf="form.controls['accountBsb']; let control">
                <label class="fx-form-label" for="accountBsb">BSB</label>
                <div class="fx-form-control">
                    <input
                        formControlName="accountBsb"
                        id="accountBsb"
                        class="fx-form-input"
                        type="text"
                        placeholder="not yet supplied"
                        aria-label="BSB"
                        [ngClass]="{ 'fx-form-input--error': !control.valid && control.touched }"
                    />
                </div>
                <div class="fx-form-validation" *ngIf="control.hasError('required') && control.touched">
                    Account BSB is required.
                    <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                </div>
                <div class="fx-form-validation" *ngIf="control.hasError('pattern') && control.touched">
                    BSB must only contain numbers.
                    <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                </div>
            </div>
            <div class="fx-form-group" *ngIf="form.controls['accountNumber']; let control">
                <label class="fx-form-label" for="accountNumber">Account number</label>
                <div class="fx-form-control">
                    <input
                        formControlName="accountNumber"
                        id="accountNumber"
                        class="fx-form-input"
                        type="text"
                        placeholder="not yet supplied"
                        aria-label="Account number"
                        [ngClass]="{ 'fx-form-input--error': !control.valid && control.touched }"
                    />
                </div>
                <div class="fx-form-validation" *ngIf="control.hasError('required') && control.touched">
                    Account number is required.
                    <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                </div>
                <div class="fx-form-validation" *ngIf="control.hasError('pattern') && control.touched">
                    Account number must only contain numbers.
                    <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>
