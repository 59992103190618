import { ICardEvent, IThreadCard, IThread, Role, CardReply } from "@findex/threads";
import { ComponentType } from "@angular/cdk/portal";
import { InjectionToken } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { Loader } from "../../shared/services/loader";

export const THREAD = new InjectionToken<Observable<IThread>>("THREAD");
export const ROLE = new InjectionToken<Observable<Role>>("ROLE");
export const THREAD_CARD_RESOURCES = new InjectionToken<CardResources>("THREAD_CARD_RESOURCES");
export const BANNER_LABEL = new InjectionToken<CardResources>("BANNER_LABEL");

export interface IEventService {
    events: Observable<ICardEvent>;
    loadHistorical(): Promise<boolean>;
}

export type CardResources<StateType = any> = {
    threadId: string;
    cardId: string;
    thread$: Observable<IThread>;
    card$: Observable<IThreadCard>;
    state$: Observable<StateType>;
    replies$: Observable<CardReply[]>;
    eventService: IEventService;
    navigateTo$: Observable<void>;
    role: Role;
    navigateToSubject?: Subject<void>;
    eventsSubject?: Subject<ICardEvent>;
};

export interface IUiCard<C = any, StateType = any> extends CardResources<StateType> {
    navigateToSubject: Subject<void>;
    eventsSubject: Subject<ICardEvent>;
    timestamp: number;
    loader: Loader;
    scrollTo?: boolean;
    component: ComponentType<C>;
}
