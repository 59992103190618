<div class="news-article-item">
    <fx-layout [columns]="24" [gutters]="false">
        <fx-layout-column [sizes]="{ mobile: 24, desktop: 10 }">
            <div class="news-article-item-image" [ngStyle]="{ 'background-image': 'url(' + article.bgUrl + ')' }"></div>
        </fx-layout-column>

        <fx-layout-column [sizes]="{ mobile: 24, desktop: 14 }">
            <div class="news-article-item-desc">
                <div class="news-article-item-desc-title">
                    {{ article.title | striphtml }}
                </div>
                <div class="news-article-item-desc-blurb">{{ article.body | striphtml | slice: 0:84 }}...</div>
                <a
                    class="news-article-item-desc-read-more"
                    *ngIf="article.url"
                    [href]="article.url"
                    analyticsClick="app_newsreadmore"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Read more</a
                >
            </div>
        </fx-layout-column>
    </fx-layout>
</div>
