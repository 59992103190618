import { Inject, Injectable } from "@angular/core";
import { ShepherdService } from "angular-shepherd";
import { MenuType, MenuService } from "./menu.service";
import { delay, map, take } from "rxjs/operators";
import { Observable, ReplaySubject } from "rxjs";
import { ThreadsService } from "../../threads-ui/services/threads.service";
import { AnalyticsService, HOT_JAR_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { Router } from "@angular/router";
import { AppUser } from "projects/portal-modules/src/lib/findex-auth";
import { MatDialog } from "@angular/material/dialog";
import { WindowListenersService } from "./window-listeners.service";
import { ENVIRONMENT } from "src/app/injection-token";
import { EnvironmentSpecificConfig } from "../../environment/environment.common";
import Step from 'shepherd.js/src/types/step';

export interface Tour {
    id: string;
    steps: TourStep[];
    tourRoute: string;
}

export interface TourStep {
    titleHtml: string;
    contentHtml: string;
    nextButtonText: string;
    attachTo?: {
        selector: string;
        side: "right" | "left" | "top" | "bottom";
    };

    // If present, show or hide the sidebar before this step is shown.
    menuStateBeforeShow?: "open" | "closed";
    // Default to false
    canClickTarget?: boolean;
    routeTo?: string;
}

const mapTourStep = (
    tourStep: TourStep,
    user: AppUser,
    showMenuFunction: (bool: boolean) => void,
    waitFor: Observable<any>,
    shepherdService: ShepherdService,
    index: number
): Step.StepOptions => {
    const { attachTo: attachToConfig } = tourStep;

    const attachTo = attachToConfig ? { element: attachToConfig.selector, on: attachToConfig.side } : undefined;

    //This promise resolves when the step is ready to show. For steps where the slide menu needs to be open,
    //it will delay for long enough so that the menu can open. If we don't do this, the tour cannot attach to the
    //element.
    const beforeShowPromise = tourStep.menuStateBeforeShow
        ? () =>
              new Promise<void>(resolve => {
                  showMenuFunction(tourStep.menuStateBeforeShow === "open");
                  waitFor.pipe(take(1), delay(250)).subscribe(() => {
                      const element = document.querySelector(tourStep.attachTo.selector);
                      if (!element) {
                          if (shepherdService.tourObject.getById(index + 1)) {
                              shepherdService.show(index + 1);
                          } else {
                              shepherdService.complete();
                          }
                      } else {
                          resolve();
                      }
                  });
              })
        : () => Promise.resolve();

    // replace #{username} with their name
    const processedTitleHtml = tourStep.titleHtml.replace(/#{username}/g, user.name);
    const processedContentHtml = tourStep.contentHtml.replace(/#{username}/g, user.name);

    return {
        attachTo,
        beforeShowPromise,
        arrow: false,
        buttons: [
            {
                classes: "fx-btn fx-btn--primary",
                text: tourStep.nextButtonText,
                action: function () {
                    this.next();
                }
            }
        ],
        cancelIcon: {
            enabled: false
        },
        scrollTo: { behavior: "smooth", block: "center" },
        text: [
            `
        <div class="tour-content">
            <div class="tour-header">${processedTitleHtml}</div>
            <div class="tour-body">${processedContentHtml}</div>
        </div>
        `
        ],
        canClickTarget: tourStep.canClickTarget || false
    };
};

@Injectable({ providedIn: "root" })
export class TourService {
    tourIsActive$ = new ReplaySubject<boolean>(1);
    readonly hotJarEvents = HOT_JAR_EVENTS;
    constructor(
        private shepherdService: ShepherdService,
        private menuService: MenuService,
        private threadsService: ThreadsService,
        private analyticsService: AnalyticsService,
        private router: Router,
        private dialog: MatDialog,
        private windowListenersService: WindowListenersService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig
    ) {
        this.tourIsActive$.next(false);
    }

    async queueTours(user: AppUser, tours: Tour[]): Promise<void> {
        if (!tours) return;

        const completedTours = await this.getCompletedTours(user.id);
        for (const tour of tours) {
            if (!completedTours.includes(tour.id)) {
                await this.startTour(user, tour);
            }
        }
    }

    async initializeTour(user: AppUser, tour: Tour): Promise<void> {
        if (!tour) {
            return;
        }

        const completedTours = await this.getCompletedTours(user.id);
        if (!completedTours.includes(tour.id)) {
            await this.initializeTour(user, tour);
        }

        await this.startTour(user, tour);
    }

    hideTour() {
        if (this.shepherdService.isActive) {
            this.shepherdService.hide();
        }
    }

    private async startTour(user: AppUser, tour: Tour): Promise<void> {
        this.tourIsActive$.next(true);
        if (this.dialog.openDialogs.length) {
            console.warn("Waiting for dialog to close before starting tour");
            await this.dialog.afterAllClosed.toPromise();
        }

        await this.routeToPreferredRoute(tour);

        this.shepherdService.modal = true;
        this.shepherdService.confirmCancel = false;

        const windowWidthMenuBreakpoint = this.environment.featureFlags.windowWidthMenuBreakpoint;
        const isTabletWidth = this.windowListenersService.isWindowSmaller(windowWidthMenuBreakpoint);

        const showMenu = (show: boolean) => {
            if (show || !isTabletWidth) {
                this.menuService.show(MenuType.Navigation);
            } else {
                this.menuService.hide(MenuType.Navigation);
            }
        };

        this.shepherdService.addSteps(
            tour.steps.map((step, index) =>
                mapTourStep(step, user, showMenu, this.menuService.showNavigation$, this.shepherdService, index)
            )
        );

        this.recordAnalyticsEvent("start");
        await this.showShepherd();

        this.recordAnalyticsEvent(this.hotJarEvents.OnboardingCompleteEvent);
        this.recordAnalyticsEvent("complete");
        this.tourIsActive$.next(false);

        // put nav back on mobile after completing
        if (isTabletWidth) {
            this.menuService.hide(MenuType.Navigation);
        }

        await this.markTourComplete(user.id, tour);
    }

    isActive() {
        return this.shepherdService.isActive;
    }

    private showShepherd(): Promise<void> {
        return new Promise(resolve => {
            this.shepherdService.tourObject.on("complete", () => resolve());
            this.shepherdService.start();
        });
    }

    private async routeToPreferredRoute(tour: Tour) {
        const startingRoute = tour.steps[0].routeTo;
        if (startingRoute && startingRoute !== this.router.url) {
            await this.router.navigate([startingRoute]);
        }
    }

    private async getCompletedTours(userId: string): Promise<string[]> {
        const userProfile = await this.threadsService.getUserData(userId).toPromise();

        if (!userProfile || !userProfile.tourConfig || !Array.isArray(userProfile.tourConfig.completedTours)) {
            return [];
        }

        return userProfile.tourConfig.completedTours;
    }

    private async markTourComplete(userId: string, tour: Tour) {
        const completedTours = await this.getCompletedTours(userId);
        const userData = { tourConfig: { completedTours: [...completedTours, tour.id] } };
        return await this.threadsService
            .putUserData(userId, userData)
            .pipe(map(() => true))
            .toPromise();
    }

    private recordAnalyticsEvent(category: string) {
        this.analyticsService.recordEvent("tour", category);
    }
}
