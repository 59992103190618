<loader-icon *ngIf="loader.counter || !tableData" [static]="true" color="light"></loader-icon>

<ng-container *ngIf="!loader.counter && tableData?.data">
    <div id="dashboard-threads" class="threads" *ngIf="tableData.data?.length; else noThreads">
        <fx-table [tableData]="tableData">
            <ng-container
                *fxColumn="
                    'title';
                    label: 'Title';
                    sortable: true;
                    mobileCollapse: true;
                    mobileHideLabel: true;
                    sortAnalyticsEvent: gaEvents.DASHBOARD_FILTER_TITLE;
                    let threadListing
                "
            >
                <div class="threads-title">
                    <a [routerLink]="['/timelines', threadListing.id]" [analyticsClick]="[gaEvents.DASHBOARD_TIMELINE,hotJarEvents.DashboardTimelineEvent]">{{
                        threadListing.title
                    }}</a>
                    <div class="threads-title-type">
                        {{ (threadListing.type | threadTypeName | async) || "..." }}
                    </div>
                </div>
            </ng-container>

            <ng-container
                *fxColumn="
                    'account.label';
                    label: 'Account name';
                    sortable: true;
                    mobileCollapse: true;
                    sortAnalyticsEvent: gaEvents.DASHBOARD_FILTER_ACCOUNT_NAME;
                    let threadListing
                "
            >
                <span class="threads-col">{{ threadListing.account?.label || "" }}</span>
            </ng-container>

            <ng-container *ngIf="!hideStatusCol">
                <ng-container
                    *fxColumn="
                        'status';
                        label: 'Status';
                        mobileCollapse: true;
                        sortAnalyticsEvent: gaEvents.DASHBOARD_FILTER_STATUS;
                        let threadListing
                    "
                >
                    <span class="threads-col threads-status">
                        <thread-workflow-status
                            *ngIf="threadListing?.workflow"
                            [thread]="threadListing"
                            [workflowState]="threadListing.workflow"
                            [enablePopUp]="true"
                            [shouldShowTitle]="true"
                            [fullWidth]="true"
                            [role]="role"
                        ></thread-workflow-status>
                    </span>
                </ng-container>
            </ng-container>

            <ng-container
                *fxColumn="
                    'updatedAt';
                    label: 'Last updated';
                    sortable: true;
                    mobileCollapse: true;
                    sortAnalyticsEvent: gaEvents.DASHBOARD_FILTER_LAST_UPDATED;
                    let threadListing
                "
            >
                <span class="threads-col" [title]="threadListing.updatedAt | date: 'short'">{{
                    threadListing.updatedAt | timeAgo
                }}</span>
            </ng-container>

            <ng-container
                *fxColumn="
                    'unresolvedNotifications';
                    label: 'Unread';
                    sortable: true;
                    mobileCollapse: true;
                    mobileHideLabel: true;
                    sortAnalyticsEvent: [gaEvents.DASHBOARD_UNREAD_MESSAGES, hotJarEvents.DashboardNotificationEvent];
                    let threadListing
                "
            >
                <span class="threads-col">
                    <div class="threads-unread-icon">
                        <a
                            *ngIf="threadListing.unresolvedNotifications"
                            [analyticsClick]="[gaEvents.DASHBOARD_UNREAD_MESSAGES, hotJarEvents.DashboardNotificationEvent]"
                            [routerLink]="['/timelines', threadListing.id]"
                            class="threads-unread-icon-badge"
                        >
                            <i class="las la-bell"></i>
                            {{ threadListing.unresolvedNotifications + " Unread" }}
                        </a>
                    </div>
                </span>
            </ng-container>

            <ng-container *fxRowChild="let threadListing">
                <ng-container *ngFor="let threadTask of threadListing.tasks$ | async; trackBy: trackThreadTask">
                    <view-extension [componentRef]="threadTaskComponentClass" [injector]="threadTask.injector">
                    </view-extension>
                </ng-container>
            </ng-container>
        </fx-table>
    </div>

    <ng-template #noThreads>
        <div class="threads-empty">
            <span>There are no timelines for this account</span>
        </div>
    </ng-template>
</ng-container>
