<ng-container *ngIf="card$ | async; let card">
    <centered-modal-layout
        *ngIf="{isThreadActive : thread | isThreadActive | async } as currentThread"
        [loading]="!!loader.counter"
        [cancelButtonTitle]="null"
        [disableSave]="!form.valid || !currentThread.isThreadActive"
        saveButtonTitle="{{ isReadonly ? 'Close' : actionedPercentage === 100 ? 'Complete Request' : 'Send For Review' }}"
        [analyticsPrefix]="ANALYTICS_PREFIX"
        (save)="save(card)"
        (close)="(currentThread.isThreadActive) ? save(card) : closeDialog()"
    >
        <div modal-header>
            {{ state?.title }}
        </div>
        <div modal-template-header-action>
            <fx-context-menu #menu [gutter]="false"  *ngIf="(canEditCard$ | async) && (currentThread.isThreadActive)">
                <a (click)="openEditRequest()">Edit</a>
                <a (click)="deleteCard()">Delete</a>
            </fx-context-menu>
        </div>
        <div modal-subhead class="request-common-subhead">
            <fx-avatar
                [name]="card?.createdBy | userToName | async"
                [image]="card?.createdBy | profilePicture"
                size="small"
            ></fx-avatar>
            <span class="request-common-subhead-name">{{ card?.createdBy | userToName | async }}&nbsp;</span> created
            this request on {{ card?.createdAt | date: "EEEE dd MMMM yyyy" }}
            <span *ngIf="card.dueDate" class="request-common-due-date">
                <b>Due Date</b> {{ card?.dueDate | date: "dd/MM/yy" }}
            </span>
        </div>
        <div modal-content class="action-request-content">
            <div class="request-common">
                <form [formGroup]="form">
                    <div class="request-common-group">
                        <div class="request-common-label request-common-group-header">
                            Note or message
                        </div>
                        <div class="request-common-request-message-view">
                            <div class="request-common-request-message-view-field">
                                <quill-view [content]="card?.description" aria-label="message"></quill-view>
                            </div>

                            <thread-card-replies
                                #threadCardRepliesComponent
                                [replies]="replies$ | async"
                                [card]="card"
                                [thread]="thread"
                                [userId$]="userId$"
                                [readonly]="card?.status === cardStatuses.Disabled"
                                [formGroup]="formGroup"
                                (onReplySaved)="replySaved()"
                            ></thread-card-replies>
                        </div>
                    </div>
                    <div *ngIf="state?.attachments?.data?.length" class="request-common-group">
                        <div class="request-common-list">
                            <div class="request-common-list-item">
                                <div class="request-common-list-title">
                                    Attachments
                                </div>
                                <div class="request-common-list-items">
                                    <div
                                        *ngFor="let attachment of state?.attachments?.data; let i = index"
                                        class="request-common-file"
                                    >
                                        <i class="request-common-file-icon las la-file"></i>
                                        <a
                                            class="request-common-file-name"
                                            (click)="downloadFile(state.attachments.fileId, attachment)"
                                            >{{ attachment.filename }}</a
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="request-common-secondary-header">
                        <h1>
                            To-do list
                        </h1>
                    </div>

                    <div class="request-common-secondary-sub-header">
                        Action each to-do item and tick them off to complete this request.
                        <div *ngIf="state" class="action-request-progress">
                            <span class="action-request-progress-actioned"
                                >{{ numActionedRequestItems }} / {{ state.requestItems?.length }} actioned</span
                            >
                            <progress-bar [progress]="actionedPercentage"></progress-bar>
                        </div>
                    </div>

                    <div class="request-common-todo-list" formArrayName="requestItems">
                        <fx-todolist>
                            <ng-container
                                *ngFor="
                                    let formGroup of $any(form.get('requestItems')).controls;
                                    let i = index;
                                    trackBy: trackRequestControl
                                "
                                [formGroupName]="i"
                            >
                                <fx-todolist-item
                                    formControlName="completed"
                                    (change)="
                                        markItemComplete(
                                            $event,
                                            formGroup.controls['requestItem'],
                                            card,
                                            ANALYTICS_PREFIX
                                        )
                                    "
                                    [disabled]="
                                        card?.status === cardStatuses.Disabled ||
                                        (isReadonly && currentUserRole === roles.Client) ||
                                        !currentThread.isThreadActive
                                    "
                                >
                                    <div
                                        class="request-common-todo"
                                        [ngClass]="{ 'request-common-todo--checked': formGroup.get('completed').value }"
                                    >
                                        <div class="request-common-todo-details">
                                            <textarea
                                                #todoItemComponents
                                                formControlName="description"
                                                class="request-common-todo-details-field"
                                                type="text"
                                                cdkTextareaAutosize
                                                cdkAutosizeMinRows="1"
                                                cdkAutosizeMaxRows="3"
                                            ></textarea>
                                        </div>
                                        <request-todo-actions
                                            #todoItemActionComponents
                                            formControlName="requestItem"
                                            [userId]="userId$ | async"
                                            [analyticsPrefix]="ANALYTICS_PREFIX"
                                            [readonly]="isReadonly || !currentThread.isThreadActive"
                                            (fileAttached)="handleFileAttached($event.requestItem, $event.file, card)"
                                            (fileRemoved)="handleFileDeleted($event.requestItem, $event.file)"
                                            (textInput)="handleTextResponse($event.requestItem, $event.value, card)"
                                            (fileDownloaded)="downloadFile($event.requestItem.fileId, $event.file)"
                                            [requestItem]="formGroup.controls['requestItem'].value"
                                            [participants]="thread.participants"
                                        ></request-todo-actions>
                                    </div>
                                </fx-todolist-item>
                            </ng-container>
                        </fx-todolist>
                    </div>
                    <div
                        [ngClass]="
                            actionedPercentage === 100
                                ? 'request-common-complete-all--disabled'
                                : 'request-common-complete-all'
                        "
                        *ngIf="(card?.status !== cardStatuses.Disabled || !isReadonly) && currentThread.isThreadActive"
                    >
                        <button (click)="markAllComplete(true, card)">Mark all as complete</button>
                    </div>
                </form>
            </div>
        </div>
    </centered-modal-layout>
</ng-container>
