<ng-container *ngIf="!hideLayout; else loggedOut">
    <div class="fx-l-header" *ngIf="!isIframe">
        <ng-container *ngIf="bannerUpdates$ | async as notifications">
            <list-notification-banners
                *ngIf="notifications?.length"
                [notifications]="notifications"
                (bannerDisplayed)="themer.toggleBanner(true)"
                (bannerHidden)="themer.toggleBanner(false)"
            >
            </list-notification-banners>
        </ng-container>

        <findex-header
            [layoutOpen]="showNavigation$ | async"
            [showMenuToggle]="showMobileCollapsedIcon$ | async"
            (logout)="logout()"
            (menu)="menuAction($event)"
        >
            <ng-container *ngIf="user$ | async as user">
                <div id="slide-menu-notifications" class="item">
                    <a
                        (click)="toggleRecentNotifications()"
                        [analyticsClick]="[gaEvents.APP_RECENTNOTIFICATIONS, hotJarEvents.NotificationOpenEvent]"
                        class="recent-notifications-link"
                        [ngClass]="{ active: (showNotificationMenu$ | async) || (showNotificationMobileMenu$ | async) }"
                    >
                        <div class="notification-counter" *ngIf="unreadNotificationCount">
                            {{ unreadNotificationCount }}
                        </div>
                        <i class="las la-bell"></i>
                    </a>
                </div>
                <div
                    class="item add-cursor"
                    cdkOverlayOrigin
                    #userProfileTrigger="cdkOverlayOrigin"
                    (click)="toggleUserProfileDropdown()"
                >
                    <fx-avatar [name]="user.name" [image]="user.id | profilePicture" size="medium"></fx-avatar>
                </div>
                <ng-template
                    cdkConnectedOverlay
                    (backdropClick)="hideProfileMenu()"
                    cdkConnectedOverlayBackdropClass="transparent-overlay-background"
                    [cdkConnectedOverlayHasBackdrop]="true"
                    [cdkConnectedOverlayOrigin]="userProfileTrigger"
                    [cdkConnectedOverlayOpen]="showProfileMenu$ | async"
                    [cdkConnectedOverlayPositions]="[
                        {
                            originX: isCollapsedView ? 'end' : 'center',
                            originY: 'bottom',
                            overlayX: 'end',
                            overlayY: 'top',
                            offsetX: isCollapsedView ? 12 : 0
                        }
                    ]"
                >
                    <div class="small-overlay-window">
                        <user-profile-dropdown
                            [user]="user"
                            [showSwitchAccount]="role | permissions: 'ReadUsers' | async"
                            [supportEmail]="supportEmail"
                            (logout)="logout()"
                            (switchAccount)="switchUser()"
                        ></user-profile-dropdown>
                    </div>
                </ng-template>
            </ng-container>
        </findex-header>
    </div>

    <div class="fx-l" [ngClass]="{ 'fx-l--naked': hideLayout, 'fx-l--hide-nav': (showNavigation$ | async) !== true }">
        <nav class="fx-l-nav">
            <side-menu (click)="mobileMenuAction(false)">
                <menu-item
                    id="home-menu-item"
                    icon="la-home"
                    [route]="['/dashboard']"
                    [analyticsClick]="['app_viewdashboard','DashboardEvent']"
                    >Home</menu-item
                >
                <menu-item
                    id="timeline-menu-item"
                    icon="la-comments"
                    [route]="['/timelines']"
                    analyticsClick="app_viewtimelines"
                    >Timelines</menu-item
                >

                <menu-item
                    *ngIf="role | permissions: 'ReadReports' | async"
                    icon="las la-clipboard-list"
                    [route]="['/reports']"
                    analyticsClick="app_viewreports"
                    >Reports</menu-item
                >

                <menu-item
                    id="accounts-menu-item"
                    *ngIf="showAccounts"
                    icon="la-city"
                    [route]="['/accounts']"
                    analyticsClick="app_viewaccounts"
                    >Accounts</menu-item
                >

                <menu-item
                    *ngIf="role | permissions: 'ReadUsers' | async"
                    icon="la-user-circle"
                    [route]="['/admin']"
                    analyticsClick="app_viewadmin"
                    >Users</menu-item
                >

                <menu-item
                    *ngFor="let extension of extensions$ | async"
                    class="app-extension {{ extension.id }}-menu-item"
                    analyticsClick="app_view{{ extension.id }}"
                    [icon]="extension.icon"
                    [route]="['/' + extension.path]"
                    [id]="extension.id + '-menu-item'"
                >
                    {{ extension.label }}
                </menu-item>
            </side-menu>
        </nav>

        <div class="fx-l-canvas">
            <div
                class="all-notifications"
                [ngClass]="{ 'all-notifications--show-nav': (showNotificationMobileMenu$ | async) === true }"
                *ngIf="user$ | async"
            >
                <findex-slide-menu>
                    <div slide-menu-drawer class="slide-menu-drawer">
                        <list-notifications
                            *ngIf="notifications$ | async; let notifications; else: loading"
                            [notifications]="notifications"
                            (getNextPage)="getNextActivityPage()"
                            (open)="openNotification($any($event))"
                            (markAllAsRead)="markAllAsUnread()"
                            (markAsUnread)="recordEvent('unread')"
                            (deleteMessage)="recordEvent('delete')"
                            (markAsRead)="recordEvent('read')"
                        >
                        </list-notifications>

                        <ng-template #loading>
                            <loader-icon color="light"></loader-icon>
                        </ng-template>
                    </div>
                </findex-slide-menu>
            </div>

            <div class="fx-l-main">
                <findex-main [loading]="loader.counter">
                    <router-outlet *ngIf="!isIframe"></router-outlet>
                </findex-main>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #loggedOut>
    <router-outlet *ngIf="!isIframe"></router-outlet>
</ng-template>
