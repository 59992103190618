import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { NoAuthGuard } from "projects/portal-modules/src/lib/findex-auth/services/no-auth.guard";
import { environment } from "src/environments/environment";
import { OnboardingTimelineContainerComponent } from "src/solutions/sigma/components/onboarding-timeline-container/onboarding-timeline-container.component";
import { AuthGuard } from "../findex-auth";
import { OnboardingInitialComponent } from "./components/onboarding-initial/onboarding-initial.component";
import { VerifyEmailSuccessComponent } from "./components/verify-email-success/verify-email-success.component";
import { VerifyMobileWizardComponent } from "./components/verify-mobile-wizard/verify-mobile-wizard.component";

export const onboardingTrial = {
    path: "signup",
    header: "SignUp"
};

export const onboardingVerifyEmailSuccess = {
    path: "email-success",
    header: "Verified"
};

export const onboardingVerifyMobile = {
    path: "verify-mobile",
    header: "Verified"
};

export const onboardingVerifyMobileSuccess = {
    path: "mobile-success",
    header: "Verified"
};

const routes: Routes = [
    //User should not be logged in for these screens
    {
        path: onboardingTrial.path,
        component: environment.featureFlags.inlineOnboarding
            ? OnboardingTimelineContainerComponent
            : OnboardingInitialComponent,
        canActivate: [NoAuthGuard]
    },
    { path: onboardingVerifyEmailSuccess.path, component: VerifyEmailSuccessComponent, canActivate: [NoAuthGuard] },
    //User should be logged in for these screens
    { path: onboardingVerifyMobile.path, component: VerifyMobileWizardComponent, canActivate: [AuthGuard] },
    { path: "", redirectTo: onboardingTrial.path, pathMatch: "full" }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class OnboardingRoutingModule {}
