import { Component, Inject, OnInit } from "@angular/core";
import {
    IInitialVaultPayload,
    PortalService
} from "../../../../../projects/portal-modules/src/lib/shared/services/portal.service";
import { Router } from "@angular/router";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { OnboardingService } from "projects/portal-modules/src/lib/onboarding/services/onboarding.service";
import { filter, shareReplay, switchMap, take, map } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { ISlot } from "@findex/fx-ui/lib/components/calendar/calendar";
import { Observable } from "rxjs";
import { IStaffProfile } from "@findex/threads";
import { animate, style, transition, trigger } from "@angular/animations";
import { EnvironmentSpecificConfig } from "../../../../../projects/portal-modules/src/lib/environment/environment.common";
import { StaffService } from "../../../../../projects/portal-modules/src/lib/shared/services/staff.service";
import { AnalyticsService } from "../../../../../projects/portal-modules/src/lib/analytics";
import { AuthService, AppUser } from "../../../../../projects/portal-modules/src/lib/findex-auth";
import { SubscriptionService } from "../../services/subscription.service";
import { ActivePackageDetails } from "../../../../../projects/portal-modules/src/lib/user-profile/services/user-profile.service";
import { FindAvailabilityModalComponent } from "../find-availability-modal/find-availability-modal.component";
import { IInvitation } from "@findex/calendar-types";
import { ENVIRONMENT } from "src/app/injection-token";
import { CalendarService } from "projects/default-plugins/calendar/services/calendar.service";
import { CalendarModalComponent } from "projects/default-plugins/calendar/components/calendar-modal/calendar-modal.component";

//These are specific to Same Day Tax.
const onboardingRfiDocuments = {
    Essentials: [
        { description: "Drivers licence / photo ID" }, //Comment to prevent code format for readability
        { description: "Employer payment summaries" }
    ],
    "Work related expenses": [
        { description: "Car (fuel, oil, repairs, interest)" },
        { description: "Home office (equipment, phone bills, internet bills, printing & stationery)" },
        { description: "Equipment insurance premiums" },
        { description: "Work / investment related journals and publications" },
        { description: "Professional association, Union fees" },
        { description: "Seminars / conferences / in-services" },
        { description: "Telephone (calls and rental charges)" },
        { description: "Tools of trade (depreciation, insurance, repairs, replacement)" },
        { description: "Travel (air, bus, taxi fares, accommodation, meals)" },
        { description: "Uniforms and protective clothing (purchased, rental, laundry)" },
        { description: "Self-education (course fees, books, copying, stationery, travel)" }
    ],
    "Other expenses": [
        { description: "Donations" },
        { description: "Interest and dividend deductions" },
        { description: "Tax agent fees" },
        { description: "Other" }
    ],
    "Rental property details (if applicable)": [
        { description: "Details of rental income received" },
        { description: "Interest paid (from bank statements)" },
        { description: "Rates and land tax" },
        { description: "Insurance / body corporate fees" },
        { description: "Repairs and maintenance" },
        { description: "Agents fees" }
    ],
    "Other income": [
        { description: "Details of bank interest received" },
        { description: "Share investment information" },
        { description: "Details of other income and earnings" }
    ],
    "Other items": [
        { description: "Previous years Notice of Assessment" },
        { description: "Details of spouse's income and date of birth" },
        { description: "Motor vehicle logbook" },
        { description: "Private health insurance annual statement" }
    ]
};

@Component({
    selector: "app-select-staff",
    templateUrl: "./select-staff.component.html",
    styleUrls: ["./select-staff.component.scss"],
    animations: [
        trigger("fadeIn", [
            transition(":enter", [style({ opacity: 0 }), animate("2s ease-out", style({ opacity: 1 }))])
        ])
    ]
})
export class SelectStaffComponent implements OnInit {
    user: AppUser;
    constructor(
        private staffService: StaffService,
        private portalService: PortalService,
        private router: Router,
        private analyticsService: AnalyticsService,
        private authService: AuthService,
        private onboardingService: OnboardingService,
        private subscriptionService: SubscriptionService,
        private dialog: MatDialog,
        private calendarService: CalendarService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig
    ) {}

    currentPackage$: Observable<ActivePackageDetails | null>;

    staffProfiles: IStaffProfile[];
    selectedStaff: IStaffProfile;
    error: string;
    readonly theme = this.environment.theme;

    loader = new Loader();

    statuses = ["Finalising your account...", "​Creating your profile...", "Preparing your dashboard..."];
    showLoadingStatus = false;

    ngOnInit() {
        this.loadStaff();
    }

    async checkUserStaffStatus() {
        this.loader.show();
        const onboardingDetails = await this.onboardingService.getCompletionDetails(this.user.id).toPromise();
        this.loader.hide();
        if (onboardingDetails && onboardingDetails.selectedStaff) {
            this.router.navigateByUrl("/dashboard");
        }
    }

    async selectStaff(staff: IStaffProfile, type: "message" | "call", bookSlot?: ISlot) {
        this.error = null;
        this.loader.show();
        this.showLoadingStatus = true;
        await this.onboardingService.updateCompletionDetails(this.user.id, { selectedStaff: staff }).toPromise();
        try {
            const { userId } = staff;
            const initialVaultPayload = this.createInitialVaultPayload();
            const createOnboarding$ = this.portalService.createOnboarding(userId, type === "call", initialVaultPayload);
            const { thread, appointmentId } = await this.loader.wrap(createOnboarding$).toPromise();
            const invite = await this.calendarService.getClientInvitation(appointmentId).toPromise();

            if (type !== "call") {
                this.router.navigate(["timelines", thread.id]);
                return;
            }

            if (bookSlot) {
                await this.bookMeeting(appointmentId, bookSlot);
            }

            this.showLoadingStatus = false;
            this.router.navigate(["/dashboard"]);

            if (!bookSlot) {
                await this.openCalendarModal(appointmentId, staff, invite);
            }
        } catch (err) {
            //TODO: temp till onboarding timeout issue fixed
            // this.error = "An error occurred, please try again";
            // throw err;
        } finally {
            this.loader.hide();
            this.showLoadingStatus = false;
            this.router.navigate(["dashboard"]);
        }
    }

    getFirstName(staffProfile: IStaffProfile) {
        const parts = staffProfile.name.split(" ");
        return parts.length > 0 ? parts[0] : "advisor";
    }

    private createInitialVaultPayload(): IInitialVaultPayload | undefined {
        const description = "";

        const documents = Object.keys(onboardingRfiDocuments).reduce(
            (acc: { description: string; category: string }[], categoryName) => {
                const documentsForCategory = onboardingRfiDocuments[categoryName];
                const mappedDocuments = documentsForCategory.map(document => ({
                    description: document.description,
                    category: categoryName
                }));
                return acc.concat(mappedDocuments);
            },
            []
        );
        return {
            description,
            documents
        };
    }

    async findAvailabilityModal() {
        const currentPackage = await this.currentPackage$.pipe(take(1)).toPromise();
        const options = {
            disableClose: true,
            backdropClass: "modal-backdrop",
            position: { top: "0px" },
            height: "100vh",
            maxWidth: "100vw",
            panelClass: ["mat-dialog-no-styling", "threads-sidebar"],
            data: {
                packageId: currentPackage.packageId,
                staffProfiles: this.staffProfiles,
                meetingDescription: "Select an available time below to book your online meeting"
            }
        };

        const booking = await this.dialog
            .open<FindAvailabilityModalComponent, any, { slot: ISlot; staff: IStaffProfile }>(
                FindAvailabilityModalComponent,
                options
            )
            .afterClosed()
            .toPromise();

        if (booking) {
            const { slot, staff } = booking;
            await this.selectStaff(staff, "call", slot);
        }
    }

    async openCalendarModal(invitationId: string, staff: IStaffProfile, invite: IInvitation) {
        const options = {
            disableClose: true,
            backdropClass: "modal-backdrop",
            panelClass: ["threads-sidebar", "mat-dialog-no-styling"],
            maxWidth: "100%",
            maxHeight: "100%",
            minHeight: "100%",
            data: {
                meetingDescription: "Select an available time below to book your online meeting",
                invitationId,
                participants: [...invite.invitees, ...invite.staff],
                duration: invite.duration,
                organiser: { name: staff.name, id: staff.userId },
                title: invite.details.title
            }
        };

        this.dialog
            .open<CalendarModalComponent, any, ISlot>(CalendarModalComponent, options)
            .afterClosed()
            .subscribe(async booking => {
                if (booking) {
                    await this.bookMeeting(invitationId, booking);
                }
            });
    }

    goBack() {
        this.analyticsService.recordEvent("select-staff", "go-back");
        this.router.navigate(["/onboarding-profile"]);
    }

    private async bookMeeting(invitationId: string, slot: ISlot) {
        this.loader.show();

        try {
            await this.calendarService.setAppointment(invitationId, slot.start, slot.end).toPromise();
        } finally {
            this.loader.hide();
        }
    }

    private async loadStaff() {
        this.loader.show();

        try {
            this.currentPackage$ = this.subscriptionService.getCurrentUserSubscriptionDetails().pipe(
                filter(subscription => !!subscription && !!subscription.currentPackage),
                map(subscription => subscription.currentPackage),
                shareReplay(1)
            );

            const staff$ = this.currentPackage$.pipe(
                map(subscription => subscription.packageId),
                switchMap(packageId => this.staffService.fetchStaff({ packageId }))
            );

            this.loader.wrap(staff$).subscribe(result => {
                this.staffProfiles = result.matchingStaff;
            });

            this.user = await this.authService
                .getUser()
                .pipe(take(1))
                .toPromise();

            this.checkUserStaffStatus();
        } catch (err) {
            console.error(err);
            this.error = "An error occurred, please try again";
        } finally {
            this.loader.hide();
        }
    }
}
