import { Injectable } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
import { mergeMap } from "rxjs/operators";
import { ToastService } from "../../threads-ui/services/toast.service";

@Injectable({ providedIn: "root" })
export class PromptUpdateService {
    constructor(private updates: SwUpdate, private toast: ToastService) {}

    checkForUpdate() {
        this.updates.versionUpdates
            .pipe(
                mergeMap(() => this.toast.openPromptDialog("A new version is available. Please refresh to get the latest version.", "Refresh")),
                mergeMap(() => this.updates.activateUpdate())
            )
            .subscribe(() => document.location.reload());

        // Listen in case broken files are served by SW (leading to chunkLoadError)
        this.updates.unrecoverable.subscribe(() => {
            this.toast.openWarningDialog("Updating to the latest version. Please wait.");
            document.location.reload();
        });
    }
}
