<loader-icon *ngIf="loader.counter" color="light" [showBackground]="true"></loader-icon>
<div class="extension-menu">
    <div class="extension-menu-header">
        <h2>
            {{ extension.title }}
        </h2>

        <div
            class="extension-menu-header-close"
            (click)="close()"
            [analyticsClick]="data.extensionConfig.extensionId + '-' + gaEvents.APP_EXTENSION_MENU_CANCEL"
        >
            <i class="las la-times icon-size"></i>
        </div>
    </div>
    <div class="extension-menu-subhead">
        {{ extension.description }}
    </div>

    <div class="extension-menu-options" *ngIf="role$ | async; let role">
        <ng-container *ngFor="let menuItem of extension.extensions">
            <div *ngIf="role | permissions: menuItem.permission | async" class="extension-menu-options-option">
                <fx-checkbox (change)="setExtensionAction($event, menuItem)" [checked]="currentSelection == menuItem">
                    {{ menuItem.title }}
                </fx-checkbox>
                <div class="extension-menu-options-option-description">
                    {{ menuItem.description }}
                </div>
            </div>
        </ng-container>
        <div class="extension-menu-footer">
            <button
                [analyticsClick]="data.extensionConfig.extensionId + '-' + gaEvents.APP_EXTENSION_MENU_CANCEL"
                (click)="close()"
                class="fx-btn fx-btn--white"
            >
                Cancel
            </button>
            <button
                (click)="continue(currentSelection)"
                [disabled]="!currentSelection"
                [analyticsClick]="data.extensionConfig.extensionId + '-' + gaEvents.APP_EXTENSION_MENU_CONTINUE"
                class="fx-btn fx-btn--primary"
                role="button"
            >
                Continue
            </button>
        </div>
    </div>
</div>
