<div class="fx-onboarding fx-onboarding-bg-verify-email">
    <div class="fx-onboarding-container">
        <div class="fx-onboarding-content">
            <portal-brand type="onboarding"></portal-brand>
            <h1 class="fx-onboarding-title">
                {{ textResource.verifyEmailTitle || textResource.default.verifyEmailTitle }}
            </h1>
            <p class="fx-onboarding-body" *ngIf="textResource.verifyEmailDescription">
                {{ textResource.verifyEmailDescription }} <strong> {{ emailAddress }}</strong>
            </p>
            <p class="fx-onboarding-body">
                Didn't receive an email?
                <a (click)="resend.emit()" role="button" analyticsClick="onboarding_verifyresend">Resend</a>
            </p>
            <div
                *ngIf="verifyEmailMessage && verifyEmailMessage.error !== undefined"
                [ngClass]="verifyEmailMessage.error ? 'fx-onboarding-form-error' : 'fx-onboarding-form-info'"
            >
                {{ verifyEmailMessage.message }}
            </div>
        </div>
    </div>
</div>
