import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
interface IToastModalData {
    message: string;
    buttonLabel: string;
}
@Component({
    selector: "toast",
    templateUrl: "./toast.component.html",
    styleUrls: ["./toast.component.scss"]
})
export class ToastComponent {
    message: string;
    buttonLabel: string;
    constructor(@Inject(MAT_DIALOG_DATA) data: IToastModalData, private dialogRef: MatDialogRef<IToastModalData>) {
        this.buttonLabel = data.buttonLabel;
        this.message = data.message;
    }

    closeDialog() {
        this.dialogRef.close(true);
    }
}
