import { Observable, of } from "rxjs";
import { environmentCommon, EnvironmentSpecificConfig } from "../../environment/environment.common";
import { Inject, Injectable } from "@angular/core";
import { ENVIRONMENT } from "../../../../../../src/app/injection-token";

@Injectable({
    providedIn: "root"
})
export class ProfilePictureService {
    constructor(@Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig) {}

    getUserProfilePicture(userId: string): Observable<string> {
        const url = this.getUserProfilePictureUrl(userId);
        return of(url);
    }

    getUserProfilePictureUrl(userId: string): string {
        const { profileBase } = this.environment.publicEndpoints;
        const { users } = environmentCommon.threadsEndpoints;
        return `${profileBase}${users}/${userId}/photo`;
    }
}
