<ng-container *ngIf="userId$ | async; let userId">
    <ng-container *ngIf="{ card: card$ | async, thread: thread$ | async }; let context">
        <app-card
            *ngIf="context.card.status === cardStatuses.Removed"
            description="This meeting has been moved."
            [timestamp]="context.card.modifiedAt"
            [conversation]="false"
        >
        </app-card>
        <thread-card
            *ngIf="context.card && context.card.status !== cardStatuses.Removed && {isThreadActive : context.thread | isThreadActive | async } as thread"
            class="calendar-card"
            [title]="meetingState$ | async"
            [thread]="context.thread"
            [card]="context.card"
            [replies]="replies$ | async"
            [invertTheme]="userId === context.card.createdBy"
            [loading]="!!loader.counter"
            [createdById]="context.card.createdBy"
            [edited]="context.card.status === cardStatuses.Edited"
            [edit]="edit$"
            (save)="save($event)"
        >
            <ng-container *ngIf="thread.isThreadActive">
                <ng-container *ngIf="invitationCancelled">
                    <p class="alt-meeting-text">
                        This meeting has been cancelled by the organiser.
                    </p>
                </ng-container>
                <ng-container *ngIf="!invitationCancelled && !(invitedToMeeting$ | async)">
                    <p class="alt-meeting-text">
                        {{ notInvitedToMeetingText }}
                    </p>
                </ng-container>
            </ng-container>
            <div class="calendar-card-details">
                <i class="mobile-hide-icon las la-calendar"></i>
                <p class="calendar-card-details-name">{{ meetingName$ | async }}</p>
            </div>
            <div class="calendar-card-details">
                <i class="mobile-hide-icon las la-history"></i>
                <p class="calendar-card-details-schedule-cancelled" *ngIf="invitationCancelled">
                    Cancelled
                </p>
                <p *ngIf="!invitationCancelled && (meetingState$ | async) === meetingStates.Ended">
                    Meeting Ended
                </p>
                <ng-container *ngIf="!invitationCancelled && (meetingState$ | async) !== meetingStates.Ended">
                    <div class="calendar-card-details-schedule">
                        <ng-container *ngIf="start && end && appointmentConfirmed">
                            <p class="calendar-card-details-schedule-date">{{ start | date: "EEEE MMMM d y" }}</p>
                            <p class="calendar-card-details-schedule-time">
                                {{ start | date: meetingTimeFormat }} - {{ end | date: meetingTimeFormat }}
                            </p>
                        </ng-container>
                        <ng-container *ngIf="!appointmentConfirmed">
                            <p
                                class="calendar-card-details-schedule-time"
                                *ngIf="role | permissions: 'CreateCalendarCard' | async"
                            >
                                A request has been sent. The client can now schedule an appointment using your
                                availability.
                            </p>
                            <p
                                class="calendar-card-details-schedule-time"
                                *ngIf="!(role | permissions: 'CreateCalendarCard' | async)"
                            >
                                Click "Schedule Meeting" to view available times and book a meeting.
                            </p>
                        </ng-container>
                    </div>
                    <button
                        *ngIf="canRescheduleMeeting$ | async"
                        class="fx-btn fx-btn--primary-outlined fx-btn--small calendar-card-details-schedule-button"
                        type="button"
                        [disabled]="loader.counter"
                        (click)="action(CALENDAR_RESCHEDULE_TASK_ACTION_ID)"
                    >
                        RESCHEDULE
                    </button>
                    <button
                        *ngIf="
                            (invitedToMeeting$ | async) && role === roles.Client && organiser && !appointmentConfirmed
                        "
                        class="fx-btn fx-btn--primary-outlined fx-btn--small calendar-card-details-schedule-button"
                        type="button"
                        [disabled]="loader.counter"
                        [analyticsClick]="gaEvents.APP_VIEWAVAILABILITY"
                        (click)="action(CALENDAR_SCHEDULE_TASK_ACTION_ID)"
                    >
                        SCHEDULE MEETING
                    </button>
                </ng-container>
            </div>
            <div class="calendar-card-details" *ngIf="(avatar$ | async)?.length">
                <i class="las la-user"></i>
                <fx-avatar-multi *ngIf="(avatar$ | async)?.length" [content]="avatar$ | async"></fx-avatar-multi>
            </div>
            <div class="calendar-card-details-view-details-container">
                <button
                    *ngIf="organiser"
                    class="fx-btn fx-btn--primary-outlined calendar-card-details-view-details"
                    type="button"
                    [disabled]="loader.counter"
                    [analyticsClick]="gaEvents.CALENDAR_VIEWDETAILS"
                    (click)="openDetailsModal()"
                >
                    VIEW DETAILS
                </button>
            </div>
            <ng-container *ngIf="!invitationCancelled && vcDetails$ | async as details">
                <div
                    class="meeting-details-container calendar-card-details calendar-card-details--break"
                    *ngIf="!details.isTerminated"
                >
                    <meeting-details
                        (join)="openFullscreen(true)"
                        (terminate)="promptAndTerminateSession()"
                        [sessionTerminated]="!details.sessionId"
                        [role]="role"
                        [showButtons]="context.thread | isThreadActive | async"
                    ></meeting-details>
                </div>

                <div
                    class="recording-link"
                    *ngIf="
                        (role | permissions: 'DownloadRecording' | async) && context.card.status !== CardStatus.Disabled
                    "
                >
                    <recording-link
                        *ngIf="details.isTerminated"
                        [threadId]="context.thread?.id"
                        [cardId]="context.card.id"
                        (showLoader)="loader.toggleVisibility($event)"
                    ></recording-link>
                </div>
            </ng-container>

            <div card-actions *ngIf="allowEdit && thread.isThreadActive">
                <fx-context-menu
                    #menu
                    [gutter]="false"
                    *ngIf="
                        (context.card.createdBy === userId && !invitationCancelled) ||
                        (role | permissions: 'ThreadUpdateAll' | async)
                    "
                >
                    <ng-container *ngIf="(meetingState$ | async) !== meetingStates.Ended">
                        <a (click)="openMeetingRequestModal(true)">
                            Edit Attendees
                        </a>

                        <a (click)="openInstanceModal()" *ngIf="(state$ | async)?.instances?.length > 1">
                            Edit Occurrence
                        </a>
                    </ng-container>

                    <a (click)="cancelMeeting()" analyticsClick="app_cancelmeeting">
                        Cancel meeting
                    </a>
                </fx-context-menu>
            </div>
            <div card-loader>
                <loader-icon [color]="'light'"></loader-icon>
            </div>
            <div class="thread-card-error" *ngIf="errorMessage">
                {{ errorMessage }}
            </div>
        </thread-card>
    </ng-container>
</ng-container>
