import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ThreadsRecordingService } from "projects/portal-modules/src/lib/threads-ui/services/threads-recording.service";

@Component({
    selector: "recording-link",
    templateUrl: "recording-link.component.html",
    styleUrls: ["./recording-link.component.scss"]
})
export class RecordingLinkComponent implements OnInit {
    @Input() threadId: string;
    @Input() cardId: string;

    @Output() showLoader: EventEmitter<boolean> = new EventEmitter<boolean>();

    isUnavailable: boolean;

    constructor(private recordingsService: ThreadsRecordingService) {}

    ngOnInit() {}

    async downloadUrl(): Promise<void> {
        this.isUnavailable = false;
        this.showLoader.emit(true);

        try {
            const url = await this.recordingsService.getDownloadUrlFromCard(this.threadId, this.cardId);
            if (!url) {
                this.setUnavailable();
                return;
            }

            const downloadWindow = window.open("", "_self");
            downloadWindow.location.href = url;
            this.showLoader.emit(false);
        } catch {
            this.setUnavailable();
        }
    }

    private setUnavailable(): void {
        this.isUnavailable = true;
        this.showLoader.emit(false);
        return;
    }
}
