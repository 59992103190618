import { UnsavedConfirmModalComponent, UnsavedConfirmModalParams } from '../components/unsaved-confirm-modal/unsaved-confirm-modal.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Injectable } from "@angular/core";
import { map, take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { GA_EVENTS_PREFIX } from '../../analytics/services/gtagAnalytics.service';

const DEFAULT_KEEP_EDITING_TEXT = "There are unsaved changes. If you would like to save changes, press the \"Keep editing\" button.";
@Injectable({
    providedIn: 'root',
})
export class UnsavedModalDialogService {

    public dialogRef: MatDialogRef<UnsavedConfirmModalComponent>;
    public isDirty: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public analyticsPrefix: string = GA_EVENTS_PREFIX.DEFAULT_UNSAVE_ANALYTICS_PREFIX;
    public dialogParamsDefaults: UnsavedConfirmModalParams = {
        promptText: "Unsaved Changes",
        confirmText: "Keep editing",
        analyticsPrefix: GA_EVENTS_PREFIX.DEFAULT_UNSAVE_ANALYTICS_PREFIX,
        declineText: "Close without saving",
        areYouSureText: DEFAULT_KEEP_EDITING_TEXT,
    };

    constructor(private dialog: MatDialog) {}
    
    public open(params: UnsavedConfirmModalParams, panelClass: string[] = ['centered-modal']) {
        this.dialogRef = this.dialog.open(UnsavedConfirmModalComponent, {
            data: params,
            panelClass,
            width: "540px"
        });
    }

    public confirm() {
        return this.dialogRef.afterClosed()
            .pipe(
                take(1),
                map(res => res)
            );
    }

    public confirmClose(analyticsPrefix: string, panelClass?: string[]): Promise<boolean> {
        this.open({
            ...this.dialogParamsDefaults,
            analyticsPrefix,
        }, panelClass);
        return this.confirm().pipe(map(confirmed => confirmed ? true : false)).toPromise();
    }

    public reset(): void {
        this.isDirty.next(false);
        this.analyticsPrefix = GA_EVENTS_PREFIX.DEFAULT_UNSAVE_ANALYTICS_PREFIX;
    }
}