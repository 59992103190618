<loader-icon *ngIf="loader.counter" [static]="true" color="light"></loader-icon>
<div class="upcoming-meeting" *ngFor="let meeting of upcomingMeetings">
    <upcoming-meeting-detail
        *ngIf="(meeting.nextInstance.end | parseStringDate) >= (currentDateMinuteInterval$ | async)"
        [upcomingMeeting]="meeting"
    ></upcoming-meeting-detail>
</div>
<p class="upcoming-meeting-message" *ngIf="!loader.counter && !upcomingMeetings?.length">
    Stay tuned! Your upcoming meetings will appear here.
</p>
