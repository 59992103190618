import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material/dialog";
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { MAT_LUXON_DATE_FORMATS } from "@angular/material-luxon-adapter";
import { CardStatus, IFileUploadResponse, IRequestFileData } from "@findex/threads";
import { IRequestModalData, RequestCommonComponent } from "../request-common.component";
import { VaultRequestService } from "../../../services/vault-request.service";
import { ThreadCardService } from "../../../../../portal-modules/src/lib/threads-ui/services/thread-card.service";
import { VaultService } from "@findex/vault";
import { IThreadCard } from "@findex/threads/dist/interfaces/IThreadCard";
import { AnalyticsService, GA_EVENTS_PREFIX, HOT_JAR_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { UnsavedModalDialogService } from "projects/portal-modules/src/lib/shared/services/unsaved-modal-dialog.service";

@Component({
    selector: "edit-request",
    templateUrl: "./edit-request.component.html",
    styleUrls: ["./../request-common.scss", "./edit-request.component.scss"],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: "en-AU" },
        { provide: MAT_DATE_FORMATS, useValue: MAT_LUXON_DATE_FORMATS }
    ]
})
export class EditRequestComponent extends RequestCommonComponent implements OnInit {
    readonly ANALYTICS_PREFIX = GA_EVENTS_PREFIX.RFI_EDIT_REQUEST;
    readonly hotJarEvents = HOT_JAR_EVENTS;
    minDate: Date = new Date();
    updatedRequestState: {
        attachmentsAdded: File[];
        attachmentsDeleted: { fileId: string; filenames: string[] }[];
        requestItemsRemoved: string[];
    };

    constructor(
        private dialogRef: MatDialogRef<EditRequestComponent>,
        @Inject(MAT_DIALOG_DATA)
        private modalData: IRequestModalData,
        private cardService: ThreadCardService,
        authService: AuthService,
        vaultRequestService: VaultRequestService,
        vaultService: VaultService,
        analytics: AnalyticsService,
        dialog: MatDialog,
        cdr: ChangeDetectorRef,
        private unsavedDialogService: UnsavedModalDialogService,
    ) {
        super(vaultRequestService, vaultService, authService, analytics, cdr, dialog);
    }
    async ngOnInit() {
        await this.initData(this.modalData);
        this.updatedRequestState = {
            attachmentsAdded: [],
            attachmentsDeleted: [],
            requestItemsRemoved: []
        };
    }
    async handleAttachmentAdded(attachment: File) {
        this.updatedRequestState.attachmentsAdded.push(attachment);
        this.state.attachments.data.push({
            actorId: "",
            timestamp: "",
            filename: attachment.name
        });
    }

    handleRequestItemRemoved(controlIndex: number, control: FormControl) {
        this.removeControl(controlIndex);
        if (control?.value?.fileId) {
            this.updatedRequestState.requestItemsRemoved.push(control.value.fileId);
        }
    }

    async handleAttachmentDeleted(attachment: IRequestFileData) {
        const fileToDelete = {
            fileId: this.state.attachments.fileId,
            filenames: [attachment.filename]
        };
        this.updatedRequestState.attachmentsDeleted.push(fileToDelete);
        this.state.attachments.data = this.state.attachments.data.filter(data => data !== attachment);
    }

    async updateRequestState(card: IThreadCard) {
        try {
            this.loader.show();
            const cardDescription = this.updateDescription(
                this.thread.id,
                card,
                this.form.controls.cardDescription.value
            );
            const dueDateUTC = this.form.controls.dueDate.value ? this.form.controls.dueDate.value.toUTC() : "";
            const dueDate = this.updateDueDate(
                this.thread.id,
                card,
                dueDateUTC
            );
            const updateTitle = this.updateTitle(
                this.thread.id,
                card,
                this.state.vaultId,
                this.form.controls.title.value
            );
            const removeRequestItems = this.vaultRequestService
                .removeRequestItems(
                    this.thread.id,
                    card.id,
                    this.state.vaultId,
                    this.updatedRequestState.requestItemsRemoved
                )
                .toPromise();
            const attachmentsDeleted = this.vaultRequestService
                .deleteRequestFiles(
                    this.thread.id,
                    card.id,
                    this.state.vaultId,
                    this.updatedRequestState.attachmentsDeleted
                )
                .toPromise();

            const addRequestItems = this.addRequestItems(this.thread.id, card, this.state.vaultId);
            const attachmentsAdded = this.vaultRequestService.uploadRequestAttachments(
                this.thread.id,
                card.id,
                this.state.vaultId,
                this.state.attachments.fileId,
                this.updatedRequestState.attachmentsAdded
            );
            this.trackAnalyticsEvent("mouse-click", this.hotJarEvents.RFIEditCompleteEvent);
            if(this.updatedRequestState.attachmentsAdded.length) {
                this.trackAnalyticsEvent("mouse-click", this.hotJarEvents.RFIAttachmentCompleteEvent);
            }
            await Promise.all([
                attachmentsAdded,
                cardDescription,
                addRequestItems,
                removeRequestItems,
                updateTitle,
                attachmentsDeleted,
                dueDate
            ]);

            this.dialogRef.close();
        } finally {
            this.loader.hide();
        }
    }
    private updateTitle(
        threadId: string,
        card: IThreadCard,
        vaultId: string,
        title: string
    ): Promise<IFileUploadResponse[]> | null {
        if (title !== this.state.title) {
            return this.vaultRequestService
                .updateRequestItems(threadId, card.id, vaultId, undefined, title)
                .toPromise();
        } else return null;
    }
    private updateDescription(threadId: string, card: IThreadCard, description: string): Promise<void> {
        if (description !== card.description) {
            return this.cardService
                .updateCardDescription(threadId, card.id, description, CardStatus.Edited)
                .toPromise();
        } else return null;
    }

    private updateDueDate(threadId: string, card: IThreadCard, dueDate: string): Promise<void> {
        if (dueDate !== card.dueDate) {
            return this.cardService
                .updateCard(threadId, card.id, undefined, undefined, CardStatus.Edited, dueDate)
                .toPromise();
        } else {
            return null;
        }
    }

    private async addRequestItems(threadId: string, card: IThreadCard, vaultId: string): Promise<void> {
        const addedRequestItems = (this.form.controls.requestItems as FormArray).controls
            .map((requestItemControl: FormGroup) => {
                if (requestItemControl.controls.requestItem?.value) {
                    return null;
                }
                return {
                    description: requestItemControl.controls.description?.value
                };
            })
            .filter(item => !!item);
        return this.vaultRequestService.addRequestItems(threadId, card.id, vaultId, addedRequestItems).toPromise();
    }

    async close() {
        if (!this.form.dirty) {
            this.dialogRef.close();
        } else {
            const confirmClose = await this.unsavedDialogService.confirmClose("rfi-edit");
            if (confirmClose) {
                this.dialogRef.close();
            }
        }
    }
}
