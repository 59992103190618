<ng-container *ngIf="role | permissions: 'ThreadUpdateAll' | async else readOnlyTitle">
    <ng-container *ngIf="!editMode">
        <span class="fx-threads-title-name">&nbsp;&mdash; {{ title }}</span>
        <i (click)="toggleEditMode(true)" class="las la-edit"></i>
    </ng-container>

    <ng-container *ngIf="editMode">
        <input [formControl]="formEditControl" class="edit-title-input"/>
        <i (click)="toggleEditMode(false)" class="lar la-times-circle"></i>
        <i (click)="changeTitle(formEditControl.value)" class="las la-save"></i>
        <i *ngIf="showLoader" class="las la-spinner"></i>
    </ng-container>
</ng-container>
<ng-template #readOnlyTitle>
    <span class="fx-threads-title-name">&nbsp;&mdash; {{ title }}</span>
</ng-template>
