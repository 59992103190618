import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

export interface UnsavedConfirmModalParams {
    confirmText?: string;
    declineText?: string;
    promptText: string;
    areYouSureText: string;
    analyticsPrefix: string;
}

@Component({
    selector: "app-unsaved-confirm-modal",
    templateUrl: "./unsaved-confirm-modal.component.html",
})
export class UnsavedConfirmModalComponent implements OnInit {
    confirmText: string;
    declineText: string;
    promptText: string;
    areYouSureText: string;
    loading = true;
    error: string;
    analyticsPrefix: string = 'unsaved';
    analyticsPostfix: string = '-save-warning';

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: UnsavedConfirmModalParams,
        private dialogRef: MatDialogRef<UnsavedConfirmModalComponent>
    ) {}

    ngOnInit() {
        this.confirmText = this.data.confirmText || "OK";
        this.declineText = this.data.declineText || "Cancel";
        this.promptText = this.data.promptText;
        this.areYouSureText = this.data.areYouSureText;
    }

    public acceptPrompt() {
        this.dialogRef.close();
    }

    public close(): void {
        this.dialogRef.close(true);
    }
}
