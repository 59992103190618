import { MatDialog } from "@angular/material/dialog";
import { Injector } from "@angular/core";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { TaskAction } from "projects/portal-modules/src/lib/plugins/services/Libraries";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { CardResources, IEventService } from "projects/portal-modules/src/lib/threads-ui/interfaces/IUiCard";
import {
    DefaultPermissions,
    PermissionService
} from "projects/portal-modules/src/lib/threads-ui/services/permissions.service";
import { EMPTY, of } from "rxjs";
import { filter, switchMap, take, tap } from "rxjs/operators";
import { VcEndSessionModalComponent } from "./components/vc-end-session-modal/vc-end-session-modal.component";
import { VcModalComponent, VCModalModel } from "./components/vc-modal/vc-modal.component";
import { VcStateBuilder } from "./services/vc-state-builder";
import { VideoChatService } from "./services/video-chat.service";
import { CardTaskActionLabel } from "@findex/threads";

const getVcDetails = (eventService: IEventService, threadId: string, cardId: string) => {
    const modelBuilder = new VcStateBuilder();
    modelBuilder.setThreadAndState(threadId, cardId);
    const modelChange$ = eventService.events.pipe(tap(event => modelBuilder.addEvent(event)));
    return modelChange$.pipe(switchMap(() => modelBuilder.getState()));
};

const callAction = (cardResources: Partial<CardResources>, injector: Injector, { join }: { join: boolean }) => {
    const matDialog = injector.get(MatDialog);
    const { role, eventService, threadId, cardId } = cardResources;
    const openedModal = matDialog.open<VcModalComponent, VCModalModel, boolean>(VcModalComponent, {
        maxWidth: "100vw",
        panelClass: "video-chat-modal-timeline",
        disableClose: true,
        closeOnNavigation: false,
        hasBackdrop: false,
        data: { details: getVcDetails(eventService, threadId, cardId), join, role }
    });

    return openedModal.afterClosed().toPromise();
};

const startCallAction = (): any => async (cardResources: Partial<CardResources>, injector: Injector) => {
    return callAction(cardResources, injector, { join: false });
};

const joinCallAction = (): any => async (cardResources: Partial<CardResources>, injector: Injector) => {
    return callAction(cardResources, injector, { join: true });
};

const endCallAction = (): any => (
    cardResources: Partial<CardResources>,
    injector: Injector
) => {
    const matDialog = injector.get(MatDialog);
    const permissionService: PermissionService = injector.get(PermissionService);
    const videoChatService: VideoChatService = injector.get(VideoChatService);
    const loader = injector.get(Loader);
    const { role, eventService, threadId, cardId } = cardResources;
    const vcDetails$ = getVcDetails(eventService, threadId, cardId);

    return permissionService
        .checkPermissions(role, DefaultPermissions.VcEndSession)
        .pipe(
            switchMap(hasPermission => {
                if (!hasPermission) return of(false);

                return matDialog
                    .open<VcEndSessionModalComponent, null, boolean>(VcEndSessionModalComponent, {
                        disableClose: false,
                        closeOnNavigation: false,
                        hasBackdrop: true,
                        panelClass: ["centered-modal"]
                    })
                    .afterClosed();
            }),
            switchMap(confirmed => {
                if (!confirmed) return EMPTY;

                return vcDetails$.pipe(
                    filter(details => !!details.sessionId),
                    take(1),
                    switchMap(({ sessionId, threadId, cardId }) => {
                        if (!sessionId) return EMPTY;
                        return loader.wrap(videoChatService.terminateSession(sessionId, threadId, cardId));
                    })
                );
            })
        )
        .toPromise();
};
export const vcStartCallTaskAction: TaskAction<any> = {
    analyticsEvents: [GA_EVENTS.APP_JOINVC],
    cardTypes: ["calendar", "video-chat"],
    action: startCallAction(),
    buttonLabel: CardTaskActionLabel.StartCall
};

export const vcJoinCallTaskAction: TaskAction<any> = {
    analyticsEvents: [GA_EVENTS.APP_JOINVC],
    cardTypes: ["calendar", "video-chat"],
    action: joinCallAction(),
    buttonLabel: CardTaskActionLabel.JoinCall
};

export const vcEndSessionTaskAction: TaskAction<any> = {
    analyticsEvents: [GA_EVENTS.APP_ENDVCSESSION],
    cardTypes: ["calendar", "video-chat"],
    action: endCallAction(),
    buttonLabel: CardTaskActionLabel.EndSession
};
