<div class="staff-viewer">
    <h2 class="staff-viewer-heading">Your first meeting will be with {{ staffProfile.name }}</h2>

    <div class="staff-viewer-avatar">
        <fx-avatar
            class="fx-avatar--resizable"
            [name]="staffProfile.name"
            [image]="staffProfile.userId | profilePicture: false:true"
            size="large"></fx-avatar>
    </div>

    <div class="staff-viewer-testimonial" *ngIf="staffProfile?.testimonial?.text">
        <p class="staff-viewer-testimonial-body">
            {{ staffProfile.testimonial.text }}
        </p>
        <p class="staff-viewer-testimonial-name">- {{ staffProfile.testimonial.name }}</p>
    </div>

    <div class="staff-viewer-body-text">
        <p class="staff-viewer-health-text">
            Chatting with {{ staffFirstName ? staffFirstName : staffProfile.name }} won't cost anything and you'll walk
            away with a <strong>free health check</strong> to help improve your business.
        </p>
        <div class="staff-viewer-specialisations">
            <p class="staff-viewer-specialisations-text">
                In your first meeting {{ staffFirstName ? staffFirstName : staffProfile.name }} will:
            </p>
            <div class="staff-viewer-specialisations-list-item">
                <i class="las la-check"></i>Discuss your accounting software setup
            </div>
            <div class="staff-viewer-specialisations-list-item">
                <i class="las la-check"></i>Highlight back office processes to make you more efficient
            </div>
            <div class="staff-viewer-specialisations-list-item">
                <i class="las la-check"></i>Capture your industry and business size to match you with the right experts
            </div>
        </div>

        <a
            *ngIf="STAGE !== 'prod'"
            class="staff-viewer-new-executive"
            (click)="staffMemberSelected.next()"
            [analyticsClick]="analyticsPrefix + gaEvents.SHOW_SUCCESS_MANAGER">
            No thanks, show me another {{ ONBOARDING_STAFF_TITLE }}
        </a>
    </div>
</div>
