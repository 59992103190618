import { Pipe, PipeTransform } from "@angular/core";


@Pipe({ name: "searchBy" })
export class SearchByPipe implements PipeTransform {
    constructor() {}

    transform(itemList: {[key: string]: any}[], searchKey: string, searchValue: string = "") {
        if (!searchKey || !itemList) return itemList;

        const lowerSearch = searchValue.toLowerCase();
        return itemList.filter(item => item[searchKey].toString().toLowerCase().includes(lowerSearch));
    }
}
