import { NgModule } from '@angular/core';
import { BoxChartComponent } from './components/box-chart/box-chart.component';
import { ChartComponent } from './components/chart/chart.component';
import { LineChartComponent } from './components/line-chart/line-chart.component';

import { BoxPlotController } from '@sgratzl/chartjs-chart-boxplot';
import { CategoryScale, Chart, Filler, Legend, LinearScale, LineController, LineElement, PointElement, ScatterController, Title, Tooltip } from "chart.js";
import { ScatterChartComponent } from './components/scatter-chart/scatter-chart.component';

Chart.register(
    BoxPlotController,
    ScatterController,
    LineController,
    PointElement,
    LineElement,
    CategoryScale,
    LinearScale,
    Title,
    Filler,
    Legend,
    Tooltip
);

const exportableDeclarations = [
    LineChartComponent,
    BoxChartComponent,
    ScatterChartComponent
];

@NgModule({
    imports: [
    ],
    exports: [
        ...exportableDeclarations
    ],
    declarations: [
        ChartComponent,
        ...exportableDeclarations
    ]
})
export class VisualisationModule {
    
}