import { Inject, Injectable } from "@angular/core";
import { ENVIRONMENT } from "../../../../../../src/app/injection-token";
import { EnvironmentSpecificConfig } from "../../environment/environment.common";
import { AppUser } from "../../findex-auth";
import { IAnalyticsService } from "./IAnalyticsService";

export enum GA_EVENTS_PREFIX {
    DEFAULT_UNSAVE_ANALYTICS_PREFIX = "unsaved",
    RFI = "rfi",
    RFI_CARD = "rficard",
    RFI_CREATE = "rficreate",
    CALENDAR = "calendar",
    DOCUMENT_CARD = "documentcard",
    DOCUMENTS = "documents",
    APP = "app",
    PAYMENT_CARD = "paymentcard",
    RFI_ACTION_REQUEST = "rfi-action-request",
    RFI_CREATE_REQUEST = "rfi-create-request",
    RFI_EDIT_REQUEST = "rfi-edit-request",
    RFI_CONFIRMCOMPLETE = "rfi_confirmcomplete",
    ONBOARDING = "onboarding",
    ONBOARDING_TILE = "onboarding_tile",
    THREADS = "threads",
}

export enum GA_EVENTS {
    RFI_CREATERFI = "rfi_createrfi",
    RFI_CLOSE = "rfi_close",
    RFI_COMPLETE = "rfi_complete",
    RFI_CHILDCOLLAPSE = "rfi_childcollapse",
    RFI_ADDDOCUMENT = "rfi_adddocument",
    RFI_BROWSEFILES = "rfi_browsefiles", // replaced by analytics prefix
    RFI_CANCELCOMPLETE = "rfi_cancelcomplete", // replaced by analytics prefix
    RFI_COLLAPSE = "rfi_collapse",
    RFICARD_EDIT = "rficard_edit",
    RFICARD_REMOVE = "rficard_remove",
    RFICARD_CANCEL = "rficard_cancel",
    RFICARD_VIEW = "rficard_view",
    RFICREATE_MARKASREPORT = "rficreate_markasreport",
    RFICREATE_RENAME = "rficreate_rename",
    RFICREATE_DELETE = "rficreate_delete",
    APP_PROVIDEDOCUMENTS = "app_providedocuments",
    APP_JOINVC = "app_joinvc",
    APP_SCREENSHAREVC = "app_screensharevc",
    APP_ENDVCSESSION = "app_endvcsession",
    APP_CREATE_REQUEST_MEETING = "app_create_schedulemeeting",
    APP_CREATE_SCHEDULE_MEETING = "app_create_bookmeeting",
    APP_CREATE_PAYMENT_CARD = "app_create_payment",
    APP_CREATE_VIDEO_CHAT = "app_create_videochat",
    CALENDAR_OPENSCHEDULING = "calendar_openscheduling",
    CALENDAR_OPENMEETINGREQUEST = "calendar_openmeetingrequest",
    CALENDAR_CREATEINVITATION = "calendar_createinvitation",
    CALENDAR_VIEWDETAILS = "calendar_viewdetails",
    CALENDAR_CREATESCHEDULED = "calendar_createscheduled",
    CALENDAR_RESCHEDULE = "calendar_reschedulemeeting",
    APP_VIEWAVAILABILITY = "app_viewavialability",
    APP_REQUESTSIGNATURE = "app_requestsignature",
    APP_ATTACHFILE = "app_attachfile",
    APP_SENDMESSAGE = "app_sendmessage",
    APP_CREATE_MESSAGE = "app_createmessage",
    APP_CREATE_REQUEST = "app_createrequest",
    APP_CREATE_MESSAGE_FILE_UPLOAD = "app_createmessage_fileupload",
    APP_CREATE_MESSAGE_CLOSE = "app_createmessage_close",
    APP_CREATEMEETING = "app_createmeeting",
    APP_SIGNDOCUMENT = "app_signdocument",
    APP_TABDOCUMENTS = "app_tabdocuments",
    INSIGHTS_PERMISSIONS_DELETE = "insights_permisisons_delete",
    INSIGHTS_PERMISSIONS_SAVE = "insights_permissions_save",
    INSIGHTS_REMOVE_DIALOG = "insights_remove_dialog",
    INSIGHTS_ADD_ENTERPISE_SEARCH = "insights_add_enterprise_search",
    APP_ERROR400 = "app_error400",
    APP_ERROR401 = "app_error401",
    APP_ERROR403 = "app_error403",
    APP_ERROR500 = "app_error500",
    APP_ERROR504 = "app_error504",
    PAYMENTCARD_VIEW = "paymentcard_view",
    PAYMENTCARD_PAYNOW = "paymentcard_paynow",
    PAYMENTCARD_PAID = "paymentcard_paid",
    PAYMENTCARD_ADDPAYMENTMETHOD = "paymentcard_addpaymentmethod",
    PAYMENTCARD_PAYMENTMETHODREADY = "paymentcard_paymentmethodready",
    PAYMENTCARD_EDIT = "paymentcard_edit",
    PAYMENTCARD_CANCEL = "paymentcard_cancel",
    APP_RECENTNOTIFICATIONS = "app_recentnotifications",
    APP_DELETENOTIFICATION = "app_deletenotification",
    APP_MARKASREADNOTIFICATION = "app_markasreadnotification",
    APP_MARKASUNREADNOTIFICATION = "app_markasunreadnotification",
    APP_VIEWINSIGHTS = "app_viewinsights",
    INSIGHTS_REPORTBUTTONDOWNLOAD = "insights_reportbuttondownload",
    BILLINGHISTORY_VIEWINVOICE = "billinghistory_viewinvoice",
    APP_WORKFLOWPROGRESSBAR = "app_workflowprogressbar",
    APP_SEENUNSEEN = "app_seenunseen",
    APP_ADDPARTICIPANTS = "app_addparticipants",
    APP_REMOVEPARTICIPANT = "app_removeparticipant",
    APP_INVITEPARTICIPANTS = "app_inviteparticipants",
    DOCUMENTS_DOWNLOAD = "documents_download",
    DOCUMENTS_DELETE = "documents_delete",
    DOCUMENTS_RENAME = "documents_rename",
    SHOW_SUCCESS_MANAGER = "_showsuccessmanager",
    LOGIN = "_login",
    INSIGHTS_DOWNLOAD = "insights_download",
    APP_EXTENSION_MENU_CANCEL = "menu-cancel-button",
    APP_EXTENSION_MENU_CONTINUE = "menu-continue-button",
    APP_EXTENSION_CHECKBOX = "checkbox-select",
    ACCOUNTS_SEARCHSUBMIT = "accounts_searchsubmit",
    ACCOUNTS_TIMELINE = "accounts_timeline",
    ACCOUNTS_TABTIMELINELIST = "accounts_tabtimelinelist",
    ACCOUNTS_TABDETAILS = "accounts_tabdetails",
    ACCOUNTS_TABREQUESTS = "accounts_tabrequests",
    ACCOUNTS_TABFILES = "accounts_tabfiles",
    ACCOUNTS_TABRECORDINGS = "accounts_tabrecordings",
    INSIGHTS_TABSCORECARD = "insights_tabscorecard",
    INSIGHTS_TABREPORTS = "insights_tabreports",
    APP_SEARCHTIMELINES = "app_searchtimelines",
    RFI_REOPEN_REQUEST_MODAL = "rfi_reopen_request_modal",
    RFI_REQUEST_MODAL = "rfi_reopen_request_modal",
    DASHBOARD_TIMELINE = "dashboard_timeline_title",
    DASHBOARD_TASK_ITEM = "dashboard_task_item",
    DASHBOARD_FILTER_TITLE = "dashboard_filter_title",
    DASHBOARD_FILTER_ACCOUNT_NAME = "dashboard_filter_account_name",
    DASHBOARD_FILTER_STATUS = "dashboard_filter_status",
    DASHBOARD_FILTER_LAST_UPDATED = "dashboard_filter_last_updated",
    DASHBOARD_UNREAD_MESSAGES = "dashboard_unread_messages",
    DASHBOARD_VIEW_MEETING_DETAILS = "dashboard_view_meeting_details",
    VAULT_LIST_FILTER_DISPLAY_NAME = "vault_list_filter_display_name",
    VAULT_LIST_FILTER_SERVICE_TYPE = "vault_list_filter_service_type",
    VAULT_LIST_FILTER_THREAD_TITLE = "vault_list_filter_thread_title",
    VAULT_LIST_FILTER_TIMESTAMP = "vault_list_filter_timestamp",
    VAULT_LIST_FILTER_CREATED_BY = "vault_list_filter_created_by",
    VAULT_LIST_SEARCHSUBMIT = "vault_list_searchsubmit",
    VAULT_LIST_FILTER_DATE_RANGE = "vault_list_daterange",
    VAULT_LIST_FILTER_ACCOUNTS = "vault_list_accounts",
    VAULT_LIST_CONFIRM_DELETE = "vault_list_confirm_delete",
    VAULT_LIST_CONTEXT_RENAME = "vault_list_context_rename",
    VAULT_LIST_CONTEXT_DOWNLOAD = "vault_list_context_download",
    VAULT_LIST_CONTEXT_VIEW_IN_TIMELINE = "vault_list_context_view_in_timeline",
    VAULT_LIST_CONTEXT_DELETE = "vault_list_context_delete",
}

declare let gtag: (...arg: any[]) => any;

declare global {
    interface Window {
        dataLayer?: any;
    }
}

@Injectable()
export class GtagAnalyticsService implements IAnalyticsService {
    configId: string;

    constructor(@Inject(ENVIRONMENT) environment: EnvironmentSpecificConfig) {
        const { trackingId } = environment.analytics.google;

        this.configId = trackingId;
    }

    setUserId(user: AppUser) {
        if (!user) {
            return;
        }

        const userId = user.id;

        if (this.configId && window.dataLayer) {
            gtag("set", { user_id: userId });
        }
    }

    init(user: AppUser) {
        this.setUserId(user);
    }

    pageViewed(path: string) {
        if (this.configId) {
            gtag("config", this.configId, {
                page_path: path,
            });
        }
    }

    sendEvent(category: string, action: string, label?: string, value?: number) {
        if (this.configId && window.dataLayer) {
            gtag("event", action, {
                event_category: category,
                event_label: label,
                value,
            });
        }
    }
}
