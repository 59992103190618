import { Component, Inject, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { IThread } from "@findex/threads";
import { DateTime } from "luxon";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { ButtonType, IStepConfiguration, MultiComponentLayoutComponent } from "projects/portal-modules/src/lib/threads-ui/components/multi-component-layout/multi-component-layout.component";
import { MultiComponentService } from "projects/portal-modules/src/lib/threads-ui/components/multi-component-layout/multi-component.service";
import { CalendarInstance, CalendarState } from "../../calendar-state.type";
import { CalendarService, ISlot } from "../../services/calendar.service";

export type CalendarInstanceData = {
    invitationId: string,
    state: CalendarState,
    thread: IThread
}

@Component({
    selector: 'calendar-instance-modal',
    templateUrl: './calendar-instance-modal.component.html',
    styleUrls: ['./calendar-instance-modal.component.scss']
})
export class CalendarInstanceModalComponent {
    slots: ISlot[];
    minDate: string;
    maxDate: string;
    duration: number;
    selectedSlot: ISlot;
    editInstance?: CalendarInstance;
    
    @ViewChild(MultiComponentLayoutComponent) multiComponentLayoutComponent: MultiComponentLayoutComponent;
    loader = new Loader();
    activeStepIndex = 0;
    stepConfigurations: IStepConfiguration[] = [
        {
            stepIndex: 0,
            buttons: [
                {
                    title: "Close",
                    type: ButtonType.Finish,
                    isDisabled: false,
                    isHidden: false
                }
            ]
        },
        {
            stepIndex: 1,
            buttons: [
                {
                    title: "Finish",
                    type: ButtonType.Finish,
                    isDisabled: false,
                    isHidden: false
                }
            ]
        }
    ];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: CalendarInstanceData,
        private dialogRef: MatDialogRef<CalendarInstanceModalComponent>,
        private calendarService: CalendarService,
        private multiComponentService: MultiComponentService,
    ) {
        console.log("modal opened with", this.data);
        
        this.maxDate = new Date(Date.now() + 60 * 24 * 60 * 60 * 1000).toISOString();
        this.minDate = new Date().toISOString();
    }

    async submit() {
        if (!this.editInstance) return this.dialogRef.close();

        if (!this.editInstance.reference) {
            alert("Meeting does not support this feature. Please try on a newer meeting");
            return;
        }

        this.loader.show();

        const instance = {
            reference: this.editInstance.reference,
            start: this.selectedSlot.start,
            end: this.selectedSlot.end
        };

        await this.calendarService.updateInstance(this.data.invitationId, instance).toPromise();

        this.loader.hide();
        this.dialogRef.close();
    }

    async cancelTime(instance: CalendarInstance) {
        this.loader.show();

        await this.calendarService.cancelInstance(this.data.invitationId, instance).toPromise();

        this.loader.hide();
        this.dialogRef.close();
    }

    close() {
        this.dialogRef.close();
    }

    async monthChange(startDate: string | Date) {
        this.loader.show();
        
        const startFrom = new Date(startDate).toISOString();
        const staffIds = this.data.state?.attendees?.staff?.map(staff => staff.id) || [];
        
        
        this.slots = [];
        const slots = await this.calendarService
            .checkUserAvailability(staffIds, startFrom, this.duration, this.data.thread.id)
            .toPromise();
        this.slots = slots?.slots || [];
        this.loader.hide();
    }

    async selectTime(instance: CalendarInstance) {
        this.loader.show();
        this.editInstance = instance;
        this.duration = DateTime.fromISO(this.editInstance.end).diff(DateTime.fromISO(this.editInstance.start), "minutes").minutes;
        await this.monthChange(this.minDate);
        this.loader.hide();
        this.multiComponentLayoutComponent.selectNextStep();
    }
    
    async handleTimeSelection(event: ISlot) {
        this.selectedSlot = event;
        this.stepConfigurations = [
            ...this.multiComponentService.toggleForwardButtons(this.activeStepIndex, this.stepConfigurations, true)
        ];
    }
}