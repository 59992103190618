import { ComponentType } from "@angular/cdk/portal";
import {
    Component,
    ElementRef,
    Inject,
    OnInit,
    ViewChild,
    OnDestroy,
    Injector,
    ViewChildren,
    QueryList
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { IThread, IThreadListing, Role, WorkflowStepType } from "@findex/threads";
import { combineLatest, Observable, of, Subscription } from "rxjs";
import { distinctUntilChanged, filter, map, pluck, shareReplay, switchMap } from "rxjs/operators";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { ThreadsService } from "projects/portal-modules/src/lib/threads-ui/services/threads.service";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { ICreateCardEvent } from "../create-card/create-card.component";
import { CancelThreadReasonModalComponent } from "../cancel-thread-reason-modal/cancel-thread-reason-modal.component";
import { AnalyticsService } from "projects/portal-modules/src/lib/analytics";
import { Environment, environmentCommon } from "../../../environment/environment.common";
import { IProviderWorkflow, WorkflowService } from "../../services/workflow.service";
import { ENVIRONMENT, THREAD_LIBRARY } from "src/app/injection-token";
import { ThreadsWebsocketService } from "../../../shared/services/threads-websocket.service";
import { TabsItemComponent } from "@findex/fx-ui";
import { ThreadStatus } from "../status-badge/status-badge.component";
import { ROLE, THREAD } from "../../interfaces/IUiCard";
import { ILibrary } from "../../../plugins";
import { ViewExtension } from "../../../plugins/services/Libraries";
import { PortalService } from "../../../shared/services/portal.service";
import { IEnrichedThreadListing } from "../threads-list-route/threads-list-route.component";

const ANALYTICS_CATEGORY = "threads";

enum ContextActions {
    close = "close",
    cancel = "cancel",
    reopen = "reopen"
}

export interface ICreateCardData {
    thread: IThread;
}

@Component({
    selector: "thread-route",
    templateUrl: "./thread-route.component.html",
    styleUrls: ["thread-route.component.scss"]
})
export class ThreadRouteComponent implements OnInit, OnDestroy {
    @ViewChild("threadTab") threadTab: TabsItemComponent;
    @ViewChildren("extensionTab") extensionTabs: QueryList<TabsItemComponent>;

    @ViewChild("threadsContainer") threadsContainer: ElementRef;

    readonly states = environmentCommon.workflow.states;
    readonly ContextActions = ContextActions;

    threadId$: Observable<string>;
    thread$: Observable<IEnrichedThreadListing>;
    cardId$: Observable<string>;
    globalRole$: Observable<Role>;
    userId$: Observable<string>;
    role$: Observable<Role>;
    roles = Role;
    loader = new Loader();
    createLoader = new Loader();
    contextEnabled = this.environment.featureFlags.closeThreadContextMenu;
    ThreadStatus = ThreadStatus;
    injector: Injector;
    threadExtensions$: Observable<ViewExtension[]>;

    private wsSubscription: Subscription;
    private tabResetSubscription: Subscription;
    observeThreadSubscription: Subscription;
    observingThreadId: string;
    threadWorkflow: IProviderWorkflow;

    constructor(
        private route: ActivatedRoute,
        private threadsService: ThreadsService,
        private portalService: PortalService,
        private analyticsService: AnalyticsService,
        private authService: AuthService,
        private dialog: MatDialog,
        private websocketService: ThreadsWebsocketService,
        private router: Router,
        private workflowService: WorkflowService,
        private parentInjector: Injector,
        @Inject(THREAD_LIBRARY) private threadLibrary: ILibrary<ViewExtension>,
        @Inject(ENVIRONMENT) private environment: Environment
    ) {}

    ngOnInit() {
        this.globalRole$ = this.authService.getUser().pipe(
            filter(user => !!user),
            pluck("globalRole"),
            shareReplay(1)
        );

        this.threadId$ = this.route.params.pipe(
            map(params => params.threadId),
            distinctUntilChanged(),
            shareReplay(1)
        );

        this.cardId$ = this.route.params.pipe(
            map(params => params.cardId),
            distinctUntilChanged(),
            filter(cardId => !!cardId)
        );

        this.thread$ = this.getThread();

        this.userId$ = this.authService.getUser().pipe(
            filter(user => !!user),
            map(user => user.id)
        );

        this.role$ = combineLatest([this.userId$, this.thread$]).pipe(
            switchMap(([userId, thread]) => this.getCurrentRole(userId, thread))
        );

        this.injector = this.getInjector(this.thread$, this.role$);

        const threadExtensions = this.threadLibrary.listAll().map(({ extension }) => {
            if (!extension.showView$) return of(extension);

            return extension.showView$.pipe(map(isShowing => (isShowing ? extension : null)));
        });

        this.threadExtensions$ = combineLatest(threadExtensions).pipe(
            map(extensions => extensions.filter(extension => extension != null))
        );

        this.initThread();

        this.observeThreadSubscription = combineLatest([this.role$, this.threadId$]).subscribe(([role, threadId]) => {
            if (role !== Role.Administrator || !threadId) return;

            if (this.observingThreadId && this.observingThreadId !== threadId) {
                this.unobserveThread(this.observingThreadId);
                this.observingThreadId = null;
            }

            if (!this.observingThreadId) {
                this.observingThreadId = threadId;
                this.observeThread(threadId);
            }
        });

        this.tabResetSubscription = this.threadId$.subscribe(() => {
            if (this.threadTab) {
                this.threadTab.setActive(true);
            }

            if (this.extensionTabs?.length) {
                this.extensionTabs.forEach(component => {
                    component.setActive(false);
                });
            }
        });
    }

    ngOnDestroy() {
        if (this.observeThreadSubscription) {
            this.observeThreadSubscription.unsubscribe();
        }

        if (this.observingThreadId) {
            this.unobserveThread(this.observingThreadId);
        }

        if (this.tabResetSubscription) {
            this.tabResetSubscription.unsubscribe();
        }
    }

    private observeThread(threadId: string) {
        this.websocketService.observe(threadId);
    }

    private unobserveThread(threadId: string) {
        this.websocketService.unobserve(threadId);
    }

    initThread() {
        if (this.wsSubscription) {
            this.wsSubscription.unsubscribe();
        }

        this.wsSubscription = this.route.params
            .pipe(
                map(params => params.threadId),
                switchMap(threadId =>
                    this.websocketService.watchThreadId(threadId).pipe(
                        filter(event => event.threadId && !event.cardId && !event.eventKey && !event.state),
                        filter(event => !event.preview)
                    )
                )
            )
            .subscribe(() => (this.thread$ = this.getThread()));
    }

    goUp() {
        this.router.navigate(["timelines"]);
    }

    scrollToBottom() {
        this.threadsContainer.nativeElement.scrollTop = 0;
    }

    trackByExtension(_index: number, obj: ViewExtension): ComponentType<any> {
        return obj.componentRef;
    }

    async addCard(event: ICreateCardEvent, thread: IThread): Promise<void> {
        const data = { thread, ...event?.data };
        this.dialog.open(event.component, {
            data,
            ...event.config
        });
    }

    private getThread() {
        return this.threadId$.pipe(
            switchMap(threadId => this.loader.wrap(this.portalService.getThreadListById(threadId))),
            shareReplay(1)
        );
    }

    private getCurrentRole(userId: string, thread: IThread): Observable<Role> {
        const userParticipant = thread?.participants?.find(participant => participant.id === userId);

        if (userParticipant?.role) {
            return of(userParticipant.role);
        } else {
            return this.globalRole$;
        }
    }

    async changeTimelineState(thread: IThreadListing, action: ContextActions) {
        switch (action) {
            case ContextActions.close:
                return await this.handleCloseAction(thread);
            case ContextActions.cancel:
                return await this.handleCancelAction(thread);
            case ContextActions.reopen:
                return await this.handleReopenAction(thread);
        }
    }

    private async handleCloseAction(thread: IThread) {
        return this.handleAction(thread, environmentCommon.workflow.states.closed);
    }

    private async handleCancelAction(thread: IThread) {
        return this.handleAction(thread, environmentCommon.workflow.states.cancelled);
    }

    private async handleAction(thread: IThread, stepId: string) {
        const workflow = await this.workflowService.resolveWorkflow(thread.workflow);
        if (workflow && workflow.steps.length > 0) {
            //There should only be one closed/cancelled step, so client facing and internal ID should be the same.
            const toStep = workflow.steps.find(step => (step.internalId || step.clientFacingId) === stepId);
            if (toStep) {
                const shouldUpdateThread = await this.workflowService.handleActions(thread, toStep);
                if (shouldUpdateThread) {
                    const updateState$ = this.threadsService.setActiveWorkflowStep(thread.id, toStep.clientFacingId);
                    await this.loader.wrap(updateState$).toPromise();

                    this.analyticsService.recordEvent(ANALYTICS_CATEGORY, stepId);
                }
            }
        }
    }

    private async handleReopenAction(thread: IThread) {
        const workflow = await this.workflowService.resolveWorkflow(thread.workflow);
        if (workflow && workflow.steps.length > 0) {
            // Find the last active step, and transition to this.
            const lastActiveStep = workflow.steps.filter(step => step.type === WorkflowStepType.OPEN).reverse()[0];
            const updateState$ = this.threadsService.setActiveWorkflowStep(thread.id, lastActiveStep?.clientFacingId);
            await this.loader.wrap(updateState$).toPromise();

            this.analyticsService.recordEvent(ANALYTICS_CATEGORY, "open");
        }
    }

    cancellationReason(thread: IThread) {
        const data = {
            notes: thread.notes,
            clientName: thread.participants
                .filter(participant => participant.role === Role.Client)

                .map(participant => participant.profile.name)
                .join(", ")
        };

        this.dialog.open(CancelThreadReasonModalComponent, {
            backdropClass: "modal-backdrop",
            panelClass: ["modal-container"],
            maxWidth: "420px",
            height: "auto",
            data
        });
    }

    private getInjector(thread$: Observable<IThread>, role$: Observable<Role>): Injector {
        return Injector.create({
            parent: this.parentInjector,
            providers: [
                { provide: THREAD, useValue: thread$ },
                { provide: ROLE, useValue: role$ }
            ]
        });
    }
}
