<centered-modal-layout
    (save)="!isFileValid ? close() : save()"
    (close)="close()"
    [disableSave]="isFileValid && !createReportForm.valid"
    [cancelButtonTitle]="!isFileValid ? null : 'Cancel'"
    saveButtonTitle="Continue"
    analyticsPrefix="create-report"
>
    <div modal-header>
        {{ !isFileValid ? "Unable to upload report" : "Are you sure you want to mark this file as a report?" }}
    </div>
    <div modal-subhead>
        {{
            isFileValid
                ? "Sorry, in order to upload this report, the file type needs to be PDF."
                : "This file will be embedded as a report and can be accessed by the users of this account."
        }}
    </div>
    <div modal-content>
        <form *ngIf="document.file.type === PDF_FILE_TYPE" [formGroup]="createReportForm">
            <div class="create-report-modal-title">
                <div class="fx-form-group">
                    <label class="fx-form-label" for="name">Report display title</label>
                    <div class="fx-form-control">
                        <input
                            formControlName="title"
                            id="ReportTitle"
                            type="text"
                            class="fx-form-input"
                            aria-label="Report Display Title"
                            placeholder="eg. John Doe - March 2021 Report"
                        />
                    </div>
                </div>
            </div>

            <div class="create-report-modal-period">
                <div class="fx-form-group">
                    <label class="fx-form-label">Report period</label>
                    <div class="fx-form-control">
                        <div>
                            <label [for]="reportingPeriod.Monthly">
                                <input
                                    formControlName="reportingPeriod"
                                    type="radio"
                                    name="reportingPeriod"
                                    [id]="reportingPeriod.Monthly"
                                    [value]="reportingPeriod.Monthly"
                                />
                                {{ reportingPeriod.Monthly }} report
                            </label>
                        </div>
                        <div>
                            <label [for]="reportingPeriod.Quarterly">
                                <input
                                    formControlName="reportingPeriod"
                                    type="radio"
                                    name="reportingPeriod"
                                    [id]="reportingPeriod.Quarterly"
                                    [value]="reportingPeriod.Quarterly"
                                />
                                {{ reportingPeriod.Quarterly }} report
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</centered-modal-layout>
