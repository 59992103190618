import { Inject, Injectable } from "@angular/core";
import { WebsocketNotification } from "@findex/threads";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { EnvironmentSpecificConfig } from "../../environment/environment.common";
import { WebsocketService } from "./websocket.service";
import { ENVIRONMENT } from "src/app/injection-token";

export interface ISocketEvent {
    threadId: string;
    cardId: string;
    eventKey: string;
    state?: boolean;
    preview?: boolean;
    subjectType?: string;
    eventType?: string;
}

@Injectable({ providedIn: "root" })
export class ThreadsWebsocketService {
    private websocket: WebsocketService;
    private threadEvents: Observable<ISocketEvent>;

    constructor(authService: AuthService, @Inject(ENVIRONMENT) environment: EnvironmentSpecificConfig) {
        this.websocket = new WebsocketService(authService, environment.threadsWebsockets);

        this.threadEvents = this.websocket.getEvents<WebsocketNotification>().pipe(
            filter(event => !!event.threadId),
            map(event => {
                //Not sure why we do this, just moving/porting the code for now
                const { threadId, cardId, eventKey, state, preview, eventType, subjectType } = event;
                return { threadId, cardId, eventKey, preview, state, eventType, subjectType };
            })
        );
    }

    observe(threadId: string): any {
        this.websocket.send("observe", { objectId: threadId });
    }

    unobserve(threadId: string): any {
        this.websocket.send("unobserve", { objectId: threadId });
    }

    watchThreadId(threadId: string): Observable<ISocketEvent> {
        return this.threadEvents.pipe(filter(event => event.threadId === threadId));
    }

    watchCardId(threadId: string, cardId?: string): Observable<ISocketEvent> {
        return this.watchThreadId(threadId).pipe(filter(event => !cardId || event.cardId === cardId));
    }

    connectAllEvents(): Observable<ISocketEvent> {
        return this.threadEvents;
    }
}
