import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { customValidators } from "../../../../../projects/portal-modules/src/lib/shared/validators";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { filter, switchMap, take } from "rxjs/operators";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { EnvironmentSpecificConfig } from "../../../../../projects/portal-modules/src/lib/environment/environment.common";
import { PrivateProfileService } from "../../services/private-profile.service";
import { ENVIRONMENT } from "src/app/injection-token";

type PrivateUserProfileDetails = {
    isReturningCustomer: boolean;
    dateOfBirth: string;
    taxFileNumber: string;
    idType: string;
    idNumber: string;
    idExpiry: string;
};

@Component({
    selector: "onboarding-profile",
    templateUrl: "./onboarding-profile.component.html",
    styleUrls: ["./onboarding-profile.component.scss"]
})
export class OnboardingProfileComponent implements OnInit, OnDestroy {
    private profileSubscription: Subscription;
    readonly appName = this.environment.appName;
    readonly theme = this.environment.theme;

    loader = new Loader();
    onboardingForm = new FormGroup({
        isReturningCustomer: new FormControl(null, [Validators.required]),
        dateOfBirth: new FormControl("", [Validators.required, customValidators.dateValidator("dd/MM/yyyy")]),
        taxFileNumber: new FormControl("", [Validators.required, customValidators.taxFileNumberValidator]),
        idType: new FormControl(null, []),
        idNumber: new FormControl(null, []),
        idExpiry: new FormControl(null, [customValidators.dateValidator("dd/MM/yyyy")])
    });

    constructor(
        private privateProfileService: PrivateProfileService,
        private authService: AuthService,
        private router: Router,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig
    ) {}

    ngOnInit() {
        this.profileSubscription = this.authService
            .getUser()
            .pipe(
                filter(user => !!user),
                switchMap(user =>
                    this.loader.wrap(
                        this.privateProfileService.getPrivateProfileDetails<PrivateUserProfileDetails>(user.id)
                    )
                )
            )
            .subscribe(user => this.setProfile(user));
    }

    ngOnDestroy() {
        if (this.profileSubscription) {
            this.profileSubscription.unsubscribe();
        }
    }

    async continue() {
        if (this.onboardingForm.invalid) return;

        this.loader.show();
        const {
            isReturningCustomer,
            dateOfBirth,
            taxFileNumber,
            idType,
            idNumber,
            idExpiry
        } = this.onboardingForm.value;
        const details: PrivateUserProfileDetails = {
            isReturningCustomer,
            dateOfBirth,
            taxFileNumber,
            idType,
            idNumber,
            idExpiry
        };

        const user = await this.authService
            .getUser()
            .pipe(take(1))
            .toPromise();
        await this.privateProfileService
            .updatePrivateProfileInfo<PrivateUserProfileDetails>(user.id, details)
            .toPromise();

        this.loader.hide();

        this.router.navigateByUrl("/select-staff");
    }

    private setProfile(profile: PrivateUserProfileDetails) {
        const formData = Object.entries(this.onboardingForm.value)
            .filter(([, val]) => !!val)
            .reduce((data, [key, val]) => (data[key] = val), {});

        const mergedData = Object.assign({}, profile, formData);
        this.onboardingForm.patchValue(mergedData);
    }
}
