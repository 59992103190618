<ng-container *ngIf="card$ | async; let card">
    <app-card
        *ngIf="card.status === cardStatuses.Removed || card.status === cardStatuses.Deleted"
        [lineCard]="card.status === cardStatuses.Removed || card.status === cardStatuses.Deleted"
        description="This message has been removed."
        [timestamp]="card.modifiedAt"
        [conversation]="false"
    >
    </app-card>
    <ng-container
        *ngIf="
            card.status !== cardStatuses.Removed && card.status !== cardStatuses.Deleted && userId$ | async;
            let userId
        "
    >
        <thread-card
            *ngIf="state && {isThreadActive : isThreadActive$ | async } as thread"
            [title]=""
            [thread]="thread$ | async"
            [card]="card$ | async"
            [replies]="replies$ | async"
            [invertTheme]="userId === card.createdBy"
            [createdById]="card.createdBy"
            [edited]="card.status === cardStatuses.Edited"
            [loading]="!!loader.counter"
            [hideReplies]="true"
        >
            <div
                class="request-panel"
                (click)="openRequestModal()"
            >
                <span *ngIf="card.dueDate" class="request-due-date">
                    <b>Due Date</b> {{ card?.dueDate | date: "dd/MM/yy" }}
                </span>
                <h4 class="request-heading">{{ state.title }}</h4>
                <fx-badge
                    [label]="actionedPercentage === 100 ? 'Completed' : 'Pending'"
                    [type]="actionedPercentage === 100 ? 'success' : 'default'"
                ></fx-badge>
                <div class="request-info">
                    <div class="request-info-progress">
                        <progress-bar [progress]="actionedPercentage"></progress-bar>
                        <span class="request-info-actioned"
                            >{{ numActionedRequestItems }} / {{ state.requestItems?.length }} actioned</span
                        >
                    </div>
                    <div class="request-info-icons">
                        <i class="las la-comment-alt"></i>
                        <span>{{ ((replies$ | async)?.length || 0) + requestItemTextResponses }}</span>
                        <i class="las la-paperclip"></i>
                        <span>{{ (state.attachments?.data?.length || 0) + requestItemUploadResponses }}</span>
                    </div>
                </div>
            </div>
            <ng-container>
                <button
                    *ngIf="(canRespondToRequest$ | async) === false && thread.isThreadActive"
                    class="request-button fx-btn fx-btn--primary"
                    [analyticsClick]="gaEventsPrefix.RFI_CARD + '_viewrequest'"
                    (click)="action(VAULT_ACTION_VIEW_RESPONSE)"
                >
                    View Request
                </button>
                <button
                    *ngIf="!thread.isThreadActive"
                    class="request-button fx-btn fx-btn--primary"
                    [analyticsClick]="gaEventsPrefix.RFI_CARD + '_viewrequest'"
                    (click)="action(VAULT_ACTION_VIEW_CANCELED_RESPONSE)"
                >
                    View Request
                </button>
                <button
                    *ngIf="(canRespondToRequest$ | async) && thread.isThreadActive"
                    class="request-button fx-btn fx-btn--primary"
                    (click)="action(VAULT_ACTION_REQUEST_RESPONSE)"
                >
                    Provide Information
                </button>
            </ng-container>
            <div card-actions *ngIf="allowEdit && thread.isThreadActive">
                <fx-context-menu #menu [gutter]="false" *ngIf="canEditCard$ | async">
                    <a (click)="action(VAULT_ACTION_EDIT_RESPONSE)">Edit</a>
                    <a (click)="deleteCard()">Delete</a>
                </fx-context-menu>
            </div>
            <div card-loader>
                <loader-icon [color]="'light'"></loader-icon>
            </div>
            <div class="thread-card-error" *ngIf="errorMessage">
                {{ errorMessage }}
            </div>
        </thread-card>
    </ng-container>
</ng-container>
