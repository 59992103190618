<loader-icon *ngIf="loader.counter" color="light"></loader-icon>

<div class="insights-overview" *ngFor="let group of report?.groups">
    <div class="insights-overview-header">
        <div>
            <h3>{{ group.kra.label }}</h3>
            <!-- <div>
                Your <a href="">[Xero] account</a> is integrated with [Visory]. Below are your
                <strong>key financial performance</strong> measures and actionable insights.
            </div> -->
        </div>
    </div>

    <div class="insights-overview-widgets">
        <insights-metric-widget *ngFor="let row of group?.rows"
            (click)="navigateTo('metric/' + row?.metric?.id)"
            [tableReportRow]="row"
            [lastUpdated]="report?.period?.start">
        </insights-metric-widget>
    </div>
</div>
