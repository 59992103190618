<div class="instance">
    {{ start | date:'EEEE dd MMM y, h:mm a' }} - {{ end | date:'h:mm a' }}

    <fx-context-menu
        #menu
        [gutter]="false"
    >
        <a (click)="cancelMeeting()" analyticsClick="app_cancelmeeting">
            Cancel instance
        </a>
    </fx-context-menu>
</div>
