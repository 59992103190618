import { ErrorHandler, Inject, Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { AnalyticsService } from "projects/portal-modules/src/lib/analytics";
import { ActivePackageDetails } from "../../../../projects/portal-modules/src/lib/user-profile/services/user-profile.service";
import { HandledError } from "projects/portal-modules/src/lib/shared/services/threads-error-handler";
import {
    environmentCommon,
    EnvironmentSpecificConfig
} from "../../../../projects/portal-modules/src/lib/environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";

interface GetSubscriptionResponse {
    userId: string;
    subscriptionDetails: SubscriptionDetails;
}

export interface SubscriptionDetails {
    currentPackage: ActivePackageDetails;
    activeSubscription?: ActivePackageDetails;
    packageHistory: ActivePackageDetails[];
}

@Injectable({ providedIn: "root" })
export class SubscriptionService {
    constructor(
        private httpClient: HttpClient,
        private analyticsService: AnalyticsService,
        private errorHandler: ErrorHandler,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig
    ) {}

    getUserSubscriptionDetails(userId: string) {
        const { base } = this.environment.sigmaEndpoints;
        const { subscription } = environmentCommon.sigmaEndpoints;
        const url = `${base}${subscription}/${userId}`;
        return this.getSubscriptionDetails(url);
    }

    getCurrentUserSubscriptionDetails() {
        const { base } = this.environment.sigmaEndpoints;
        const { mySubscription } = environmentCommon.sigmaEndpoints;
        const url = `${base}${mySubscription}`;
        return this.getSubscriptionDetails(url);
    }

    private getSubscriptionDetails(url: string): Observable<SubscriptionDetails> {
        return this.httpClient.get<GetSubscriptionResponse>(url).pipe(
            map(response => response.subscriptionDetails),
            catchError((errorResponse: HttpErrorResponse) => {
                try {
                    this.errorHandler.handleError(new HandledError(errorResponse));
                } catch (error) {}
                this.recordAnalyticsEvent("fetch-unknown-error");
                return throwError(new Error(errorResponse.error ? errorResponse.error.message : errorResponse.message));
            })
        );
    }

    updateSubscription(userId: string, packageId: string): Observable<void> {
        const { base } = this.environment.sigmaEndpoints;
        const { subscription } = environmentCommon.sigmaEndpoints;
        const url = `${base}${subscription}/${userId}`;
        const body = { packageId };
        return this.httpClient.post(url, body).pipe(
            tap(() => {
                this.recordAnalyticsEvent("update-success");
            }),
            map(() => undefined),
            catchError((errorResponse: HttpErrorResponse) => {
                try {
                    this.errorHandler.handleError(new HandledError(errorResponse));
                } catch (error) {}
                this.recordAnalyticsEvent("update-unknown-error");
                return throwError(new Error(errorResponse.error ? errorResponse.error.message : errorResponse.message));
            })
        );
    }

    cancelSubscription(userId: string): Observable<void> {
        const { base } = this.environment.sigmaEndpoints;
        const { subscription } = environmentCommon.sigmaEndpoints;
        const url = `${base}${subscription}/${userId}`;
        return this.httpClient.delete(url).pipe(
            tap(() => {
                this.recordAnalyticsEvent("cancel-success");
            }),
            map(() => undefined),
            catchError((errorResponse: HttpErrorResponse) => {
                try {
                    this.errorHandler.handleError(new HandledError(errorResponse));
                } catch (error) {}
                this.recordAnalyticsEvent("cancel-unknown-error");
                return throwError(new Error(errorResponse.error ? errorResponse.error.message : errorResponse.message));
            })
        );
    }

    private recordAnalyticsEvent(category: string) {
        this.analyticsService.recordEvent("subscription", category);
    }
}
