import { Subject } from "rxjs";
import { Directive, OnDestroy } from "@angular/core";

@Directive()
export class SubscriberBaseComponent implements OnDestroy {
    protected ngUnsubscribe = new Subject();

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
