import { Inject, Injectable } from "@angular/core";
import { Notification, NotificationsService, PossibleDeliveryData } from "@findex/notifications-angular";
import { Observable, of } from "rxjs";
import { map, shareReplay, switchMap } from "rxjs/operators";
import { ENVIRONMENT } from "src/app/injection-token";
import { environmentCommon, EnvironmentSpecificConfig } from "../../environment/environment.common";
import { AppUser, AuthService } from "../../findex-auth";
import { PaginatedDataset } from "@findex/datastore-types-frontend";

export type ActivityNotification = Notification & {
    avatarImage: string;
};
const ACTIVITY_PAGE_SIZE = 25;
@Injectable({ providedIn: "root" })
export class ActivityNotificationsService {
    private activity$: Observable<Notification[]>;
    private notificationsPage: PaginatedDataset<Notification>;
    constructor(
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private notificationsService: NotificationsService,
        authService: AuthService
    ) {
        const userChange$ = authService.getUserWithoutRole();
        this.activity$ = this.getActivity$(userChange$);
    }

    getActivity$(user$: Observable<AppUser>): Observable<Notification<PossibleDeliveryData>[]> {
        return user$.pipe(
            switchMap(() => this.updateNotificationPage()),
            switchMap(() => this.getNextPage()),
            switchMap(() => this.notificationsPage.getDataObservable()),
            map(notifications => notifications.map(notification => this.getActivityNotification(notification))),
            shareReplay()
        );
    }

    updateNotificationPage(): Observable<any> {
        this.notificationsPage = this.notificationsService.getCurrentNotifications("activity", ACTIVITY_PAGE_SIZE);
        return of([]);
    }

    /**
     * TODO: Refactor. Last day, bug squash to release important features.
     * See previous commit. There's a bug where the API will not return all
     * results because of the dynamo filter which means the cdk virtual scroll
     * thinks it's done loading items
     *
     * Shani's user in UAT experiences the problem
     */
    async getNextPage(prevCount = 0): Promise<number> {
        const nextPage = await this.notificationsPage.nextPage().toPromise();
        const count = nextPage?.result?.length || 0;

        if (count + prevCount < 10 && nextPage?.next) {
            const result = await this.getNextPage(prevCount);
            return result + count + prevCount;
        } else {
            return count;
        }
    }

    async markAllAsRead() {
        await this.notificationsService.markAllAsRead("activity");
    }

    getActivity(): Observable<Notification[]> {
        return this.activity$;
    }

    private getActivityNotification(notification: Notification): ActivityNotification {
        const avatarImage = this.getAvatarImage(notification.actorId);

        return {
            ...notification,
            avatarImage
        };
    }

    private getAvatarImage(actorId: string) {
        const { profileBase } = this.environment.publicEndpoints;
        const { users } = environmentCommon.threadsEndpoints;

        return `${profileBase}${users}/${actorId}/photo`;
    }
}
