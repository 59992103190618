<div class="request-response">
    <ng-container *ngIf="!showCompose && message?.length">
        <fx-avatar
            class="request-response-avatar"
            [name]="modifiedBy | userToName | async"
            [image]="modifiedBy | profilePicture"
            size="small"
        ></fx-avatar>
        <div class="request-response-details">
            <div class="request-response-actor">
                <div class="request-response-name">
                    {{modifiedBy | userToName | async}}
                </div>
                <div class="request-response-time">
                    {{modifiedAt | timeAgo}}
                </div>
            </div>
            <quill-view class="request-response-value" [content]="message"></quill-view>
        </div>
    </ng-container>
    <request-response-form
        *ngIf="showCompose && !readonly"
        class="request-response-edit"
        [userId]="userId"
        [editResponseValue]="message"
        (discardChanges)="discard()"
        (formSubmitResponse)="submitResponse($event)"
    >
    </request-response-form>
</div>
<div class="request-response-buttons" *ngIf="!showCompose && message && !readonly">
    <div class="request-response-buttons-edit" (click)="editMessage()">Edit</div>
    <div class="request-response-buttons-edit" (click)="deleteMessage()">Delete</div>
</div>
