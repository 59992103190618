import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { PaymentService } from "../../services/payment.service";
import { PaymentMethodTypes, PaymentTypes } from "@findex/payments-service-sdk";
import { IPaymentCardState } from "../../interfaces/IPaymentCardState";
import {
    loadStripe,
    Stripe,
    StripeCardCvcElement,
    StripeCardExpiryElement,
    StripeCardNumberElement,
    StripeElements
} from "@stripe/stripe-js";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { IPaymentInvoiceDetails } from "@findex/threads";
import { ENVIRONMENT } from "src/app/injection-token";
import {
    environmentCommon,
    EnvironmentSpecificConfig
} from "projects/portal-modules/src/lib/environment/environment.common";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { HandledError } from "projects/portal-modules/src/lib/shared/services/threads-error-handler";
import { UnsavedModalDialogService } from "projects/portal-modules/src/lib/shared/services/unsaved-modal-dialog.service";
import { IPackagePriceDetails } from "../payment-card/payment-card.component";

export interface IPaymentModalData {
    state: IPaymentCardState;
    threadId: string;
    cardId: string;
    paymentsSubjectId: string;
    accountId: string;
    packagePriceDetails: IPackagePriceDetails;
}

@Component({
    selector: "payment-modal",
    templateUrl: "./payment-modal.component.html",
    styleUrls: ["./payment-modal.component.scss"]
})
export class PaymentModalComponent implements OnInit {
    readonly paymentTypes = PaymentTypes;

    form = new FormGroup({
        name: new FormControl("", [Validators.required]),
        address: new FormControl("", [Validators.required]),
        city: new FormControl("", [Validators.required]),
        postcode: new FormControl("", [Validators.required]),
        country: new FormControl("", [Validators.required]),
        state: new FormControl("", [Validators.required])
    });
    cardNumber: StripeCardNumberElement;
    expiryDate: StripeCardExpiryElement;
    cvcNumber: StripeCardCvcElement;
    errorMessage = "";
    errorState = {
        cardNumber: {
            message: ""
        },
        expiryDate: {
            message: ""
        },
        cvcNumber: {
            message: ""
        }
    };
    loader = new Loader();
    packagePriceDetails: IPackagePriceDetails;
    cardState: IPaymentCardState;
    threadId: string;
    cardId: string;
    accountId: string;
    paymentsSubjectId: string;
    stripe: Stripe;
    elements: StripeElements;
    billingDate: Date;
    paymentType: PaymentTypes;
    acceptedCountries = environmentCommon.acceptedCountries;

    constructor(
        @Inject(MAT_DIALOG_DATA) data: IPaymentModalData,
        private dialog: MatDialogRef<PaymentModalComponent, boolean>,
        private paymentService: PaymentService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private unsavedDialogService: UnsavedModalDialogService
    ) {
        this.accountId = data.accountId;
        this.cardState = data.state;
        this.paymentType = this.cardState.paymentType;
        this.packagePriceDetails = data.packagePriceDetails;
        this.threadId = data.threadId;
        this.cardId = data.cardId;
        this.paymentsSubjectId = data.paymentsSubjectId;
        this.billingDate =
            this.paymentType === PaymentTypes.ScheduledPayment ? new Date(this.cardState.billingDate) : new Date();
    }


    private createStripeElements() {
        this.cardNumber = this.elements.create("cardNumber", {
            iconStyle: "solid",
            showIcon: true,
            style: environmentCommon.paymentElementStyle
        });
        this.expiryDate = this.elements.create("cardExpiry", { style: environmentCommon.paymentElementStyle });
        this.cvcNumber = this.elements.create("cardCvc", { style: environmentCommon.paymentElementStyle });
    }

    private mountStripeElements() {
        this.cardNumber.mount("#card-number");
        this.expiryDate.mount("#card-expiry");
        this.cvcNumber.mount("#card-cvc");
    }

    private addStripeElementEvents() {
        this.cardNumber.on("change", event => {
            if (event.error) {
                this.errorState.cardNumber.message = event.error.message;
            }
            if (event.complete) {
                this.errorState.cardNumber.message = "";
            }
        });
        this.expiryDate.on("change", event => {
            if (event.error) {
                this.errorState.expiryDate.message = event.error.message;
            }
            if (event.complete) {
                this.errorState.expiryDate.message = "";
            }
        });
        this.cvcNumber.on("change", event => {
            if (event.error) {
                this.errorState.cvcNumber.message = event.error.message;
            }
            if (event.complete) {
                this.errorState.cvcNumber.message = "";
            }
        });
    }

    private async initStripeCardElements() {
        this.stripe = await loadStripe(this.environment.payments.publishableApiKey);
        this.elements = this.stripe.elements({
            fonts: [
                {
                    cssSrc: "https://fonts.googleapis.com/css?family=Lato:300,400,600|Philosopher:400,700&display=swap"
                }
            ]
        });
        this.createStripeElements();
        this.mountStripeElements();
        this.addStripeElementEvents();
    }

    async ngOnInit() {
        await this.initStripeCardElements();
        await this.loadPaymentDetails();
    }

    private async loadPaymentDetails() {
        this.loader.show();
        try {
            const paymentDetails = await this.paymentService.getAccountCustomer(this.accountId).toPromise();
            if (paymentDetails.address) {
                const { line1, city, postal_code: postcode, state, country } = paymentDetails.address;
                this.form.setValue({
                    name: paymentDetails.name,
                    address: line1,
                    city,
                    postcode,
                    state,
                    country
                });
            }
        } catch (error) {
            //If there's a problem here, just use an empty form.
            throw new HandledError(error);
        } finally {
            this.loader.hide();
        }
    }

    private buildCardData(): {
        name: string;
        address_line1: string;
        address_city: string;
        address_country: string;
        address_state: string;
        address_zip: string;
    } {
        return {
            name: this.form.controls.name.value,
            address_line1: this.form.controls.address.value,
            address_city: this.form.controls.city.value,
            address_state: this.form.controls.state.value,
            address_country: this.form.controls.country.value,
            address_zip: this.form.controls.postcode.value
        };
    }

    private buildAddressData(): IPaymentInvoiceDetails {
        return {
            name: this.form.controls.name.value,
            address: {
                line1: this.form.controls.address.value,
                city: this.form.controls.city.value,
                state: this.form.controls.state.value,
                country: this.form.controls.country.value,
                postal_code: this.form.controls.postcode.value
            }
        };
    }

    async actionPaymentCard() {
        try {
            if (!this.form.valid) {
                this.errorMessage = "Please provide your billing details above.";
                return;
            }

            this.loader.show();
            const cardData = this.buildCardData();
            const cardToken = await this.stripe.createToken(this.cardNumber, cardData);
            if (cardToken.error) {
                return;
            }

            const addressData = this.buildAddressData();
            const setupIntent = await this.paymentService.setupPaymentMethod(
                cardToken.token.id,
                PaymentMethodTypes.Card,
                addressData,
                this.cardState.currency,
                this.accountId
            );
            if (setupIntent.status !== "succeeded") {
                const cardSetup = await this.stripe.confirmCardSetup(
                    setupIntent.client_secret,
                    {
                        payment_method: setupIntent.payment_method as string
                    },
                    {
                        handleActions: true
                    }
                );
                if (cardSetup.error) {
                    this.errorMessage = cardSetup.error.message;
                    return;
                }
            }
            if (this.paymentType === PaymentTypes.ScheduledPayment) {
                // Add payment method to card but don't pay it yet
                await this.paymentService.setupPaymentMethodForCard({
                    threadId: this.threadId,
                    cardId: this.cardId,
                    paymentMethodId: setupIntent.payment_method as string,
                    paymentMethodType: PaymentMethodTypes.Card,
                    invoiceDetails: addressData,
                    currency: this.cardState.currency,
                    accountId: this.accountId,
                    paymentSubjectId: this.paymentsSubjectId
                });
            } else if (this.cardState.isRecurring && !this.cardState.isPaid) {
                await this.paymentService.activateSubscription(
                    this.threadId,
                    this.paymentsSubjectId,
                    setupIntent.payment_method as string,
                    true
                );
            } else if (!this.cardState.isRecurring && this.cardState.status !== "succeeded") {
                await this.paymentService.completePayment(
                    this.threadId,
                    this.paymentsSubjectId,
                    setupIntent.payment_method as string,
                    true,
                    this.cardState.currency
                );
            }
            await this.close();
        } catch (error) {
            this.errorMessage = error.error.message || "Sorry, something went wrong. Please contact support.";
        } finally {
            this.loader.hide();
        }
    }

    public async close(): Promise<void> {
        if (!this.form.dirty) {
            this.dialog.close();
        } else {
            const confirmClose = await this.unsavedDialogService.confirmClose("payment_edit");
            if (confirmClose) {
                this.dialog.close();
            }
        }
    }
}
