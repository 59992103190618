import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "projects/portal-modules/src/lib/findex-auth";
import { VaultListRouteComponent } from "./components/vault-list-route/vault-list-route.component";

const routes: Routes = [
    { path: "", component: VaultListRouteComponent, canActivate: [AuthGuard] },
    { path: "user/:userId", component: VaultListRouteComponent, canActivate: [AuthGuard] }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class VaultRoutingModule {}
