import { Component, EventEmitter, OnDestroy, OnInit } from "@angular/core";
import { IUserProfileExtension } from "../../../../../projects/portal-modules/src/lib/user-profile/components/user-profile/IUserProfileExtension";
import { tap } from "rxjs/operators";
import { Observable, Subscription, throwError } from "rxjs";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { SignupBusinessPayload } from "../../../../../projects/portal-modules/src/lib/onboarding/services/onboarding.service";
import { HandledError } from "../../../../../projects/portal-modules/src/lib/shared/services/threads-error-handler";
import { customValidators } from "../../../../../projects/portal-modules/src/lib/shared/validators";
import { PrivateProfileService } from "../../services/private-profile.service";
@Component({
    selector: "app-same-day-user-profile-extension",
    templateUrl: "./sameday-user-profile-extension.component.html"
})
export class SamedayUserProfileExtensionComponent implements OnInit, OnDestroy, IUserProfileExtension {
    //Inputs
    userId: string;

    //Outputs
    shouldEnableSave: EventEmitter<boolean> = new EventEmitter();
    onError: EventEmitter<string> = new EventEmitter();

    private originalOnboardingData: any; //TODO type

    form = new FormGroup({
        dateOfBirth: new FormControl(null, [customValidators.dateValidator("dd/MM/yyyy")]),
        taxFileNumber: new FormControl(null, [customValidators.taxFileNumberValidator]),
        bankName: new FormControl(null, []),
        accountName: new FormControl(null, []),
        accountBsb: new FormControl(null, [Validators.pattern(/^\d+$/)]),
        accountNumber: new FormControl(null, [Validators.pattern(/^\d+$/)]),
        isReturningCustomer: new FormControl(null, []),
        idType: new FormControl(null, []),
        idNumber: new FormControl(null, []),
        idExpiry: new FormControl(null, [customValidators.dateValidator("dd/MM/yyyy")])
    });
    private formStatusChangesSubscription: Subscription;

    constructor(private privateProfileService: PrivateProfileService) {}

    ngOnInit() {}

    ngOnDestroy() {
        this.unsubscribe();
    }

    private unsubscribe() {
        if (this.formStatusChangesSubscription) {
            this.formStatusChangesSubscription.unsubscribe();
        }
    }

    async initialize(userId: string): Promise<any> {
        this.userId = userId;
        await this.getFormData().toPromise();

        //Listen to form changes
        this.unsubscribe();
        this.formStatusChangesSubscription = this.form.statusChanges.subscribe(() => {
            this.updateValidState();
        });
        this.updateValidState();
    }

    private updateValidState() {
        const changedProperties = this.getChangedProperties();
        const hasChanges = Object.keys(changedProperties).length > 0;
        this.shouldEnableSave.emit(this.form.valid && hasChanges);
    }

    private getFormData() {
        return this.privateProfileService.getPrivateProfileDetails(this.userId).pipe(
            tap((onboardingData: SignupBusinessPayload) => {
                this.extractFieldsFromOnboardingData(onboardingData);
            })
        );
    }

    private extractFieldsFromOnboardingData(onboardingData: any) {
        this.originalOnboardingData = onboardingData || {};

        const { controls } = this.form;
        controls.dateOfBirth.setValue(this.originalOnboardingData.dateOfBirth || "");
        controls.taxFileNumber.setValue(this.originalOnboardingData.taxFileNumber || "");
        controls.bankName.setValue(this.originalOnboardingData.bankName || "");
        controls.accountName.setValue(this.originalOnboardingData.accountName || "");
        controls.accountBsb.setValue(this.originalOnboardingData.accountBsb || "");
        controls.accountNumber.setValue(this.originalOnboardingData.accountNumber || "");
        controls.isReturningCustomer.setValue(
            this.originalOnboardingData.isReturningCustomer == null
                ? null
                : this.originalOnboardingData.isReturningCustomer
        );
        controls.idType.setValue(this.originalOnboardingData.idType || null);
        controls.idNumber.setValue(this.originalOnboardingData.idNumber || "");
        controls.idExpiry.setValue(this.originalOnboardingData.idExpiry || "");
    }

    getChangedProperties() {
        const controls = this.form.controls;

        const dateOfBirth =
            controls.dateOfBirth.value !== this.originalOnboardingData.dateOfBirth
                ? controls.dateOfBirth.value
                : undefined;
        const taxFileNumber =
            controls.taxFileNumber.value !== this.originalOnboardingData.taxFileNumber
                ? controls.taxFileNumber.value
                : undefined;
        const bankName =
            controls.bankName.value !== this.originalOnboardingData.bankName ? controls.bankName.value : undefined;
        const accountName =
            controls.accountName.value !== this.originalOnboardingData.accountName
                ? controls.accountName.value
                : undefined;
        const accountBsb =
            controls.accountBsb.value !== this.originalOnboardingData.accountBsb
                ? controls.accountBsb.value
                : undefined;
        const accountNumber =
            controls.accountNumber.value !== this.originalOnboardingData.accountNumber
                ? controls.accountNumber.value
                : undefined;
        const isReturningCustomer =
            controls.isReturningCustomer.value !== this.originalOnboardingData.isReturningCustomer
                ? controls.isReturningCustomer.value
                : undefined;
        const idType = controls.idType.value !== this.originalOnboardingData.idType ? controls.idType.value : undefined;
        const idNumber =
            controls.idNumber.value !== this.originalOnboardingData.idNumber ? controls.idNumber.value : undefined;
        const idExpiry =
            controls.idExpiry.value !== this.originalOnboardingData.idExpiry ? controls.idExpiry.value : undefined;
        const details = {
            dateOfBirth,
            taxFileNumber,
            bankName,
            accountName,
            accountBsb,
            accountNumber,
            isReturningCustomer,
            idType,
            idNumber,
            idExpiry
        };
        return Object.keys(details).reduce((acc, key) => {
            const value = details[key];
            return value || value === false ? { ...acc, [key]: value } : acc;
        }, {});
    }

    async saveChanges(): Promise<void> {
        this.onError.emit("");
        const changedOnboardingProperties = this.getChangedProperties();

        if (Object.keys(changedOnboardingProperties).length > 0) {
            return this.commitChanges();
        }
    }

    private async commitChanges() {
        try {
            const controls = this.form.controls;
            await this.privateProfileService
                .updatePrivateProfileInfo(this.userId, {
                    dateOfBirth: controls.dateOfBirth.value,
                    taxFileNumber: controls.taxFileNumber.value,
                    bankName: controls.bankName.value,
                    accountName: controls.accountName.value,
                    accountBsb: controls.accountBsb.value,
                    accountNumber: controls.accountNumber.value,
                    isReturningCustomer: controls.isReturningCustomer.value,
                    idType: controls.idType.value,
                    idNumber: controls.idNumber.value,
                    idExpiry: controls.idExpiry.value
                })
                .toPromise();
            const onboardingUpdate = await this.getFormData().toPromise();
            this.extractFieldsFromOnboardingData(onboardingUpdate);
        } catch (error) {
            this.handleError(error);
        }
    }

    private handleError(error: any): Observable<any> {
        if (error.error && error.error.message) {
            this.onError.emit(error.error.message);
        } else {
            this.onError.emit("Could not update the user profile");
        }

        return throwError(new HandledError(error));
    }
}
