import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { EVENT_REPLY_TYPE, IThread, IThreadCard, Role } from "@findex/threads";
import { DateTime } from "luxon";
import { Observable, Subscription } from "rxjs";
import { filter, take } from "rxjs/operators";
import { IUiCard } from "projects/portal-modules/src/lib/threads-ui/interfaces/IUiCard";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { UiCardService } from "projects/portal-modules/src/lib/threads-ui/services/ui-card.service";
import { ParticipantCache } from "../../services/participant-cache.service";
import { ThreadsWebsocketService, ISocketEvent } from "../../../shared/services/threads-websocket.service";
import { Notification, NotificationState } from "@findex/notifications-angular";
import { ActivatedRoute, Params } from "@angular/router";
import { ThreadCardService } from "../../services/thread-card.service";
import { ActivityNotificationsService } from "../../../notifications";

type CardKeyGroups = {
    [date: string]: IUiCard[];
};

type CardGroup = {
    date: string;
    cards: IUiCard[];
};

@Component({
    selector: "thread",
    templateUrl: "./thread.component.html",
    styleUrls: ["./thread.component.scss"]
})
export class ThreadComponent implements OnInit, OnChanges, OnDestroy {
    @Input() thread: IThread;
    @Input() role: Role;
    @Input() routeToCardId?: string;
    @Input() excludeCardTypes: string[];
    @Output() loadCardComplete = new EventEmitter<void>();

    private uiCards: IUiCard[] = [];
    private clearNewTimeout: any;

    uiCardsByDate: CardGroup[];
    loader = new Loader();
    firstUnreadCardId: string;
    isFirstLoad = true;

    activeCardScroller$: Observable<string>;
    activityNotifications$: Observable<Notification[]>;

    private wsSubscription: Subscription;
    private eventSubscription: Subscription;
    private cardRouteSubscription: Subscription;
    private notificationsSub: Subscription;

    constructor(
        private uiCardService: UiCardService,
        private cardService: ThreadCardService,
        private websocketService: ThreadsWebsocketService,
        private participantsCache: ParticipantCache,
        private activityNotifications: ActivityNotificationsService,
        private route: ActivatedRoute
    ) {
        this.activityNotifications$ = this.activityNotifications.getActivity();
    }

    ngOnInit() {
        this.activeCardScroller$ = this.uiCardService.getActiveCardScroller();
    }

    ngOnChanges(changes: SimpleChanges) {
        const { thread, routeToCardId } = changes;
        const params = this.route.snapshot.queryParams;
        const cardTypeToCheck = EVENT_REPLY_TYPE;
        const isModalDisabled = this.hasCardTypeParams(params, cardTypeToCheck);

        if (thread?.currentValue) {
            this.isFirstLoad = thread?.previousValue?.id !== thread.currentValue.id;
            this.initThread(thread.currentValue);
            this.initNotifications();
        }

        this.routeToCard(routeToCardId, isModalDisabled);
    }

    private routeToCard(routeToCardId, isModalDisabled: boolean) {
        if (routeToCardId && this.uiCards && this.uiCards.length) {
            this.uiCardService.routeToCard(this.uiCards, routeToCardId.currentValue, isModalDisabled);
        }
    }

    private initNotifications() {
        if (this.notificationsSub) {
            this.notificationsSub.unsubscribe();
        }
        this.notificationsSub = this.activityNotifications$.subscribe(notifications => this.setFirstUnread(notifications));
    }

    ngOnDestroy() {
        if (this.cardRouteSubscription) {
            this.cardRouteSubscription.unsubscribe();
        }

        if (this.wsSubscription) {
            this.wsSubscription.unsubscribe();
        }

        if (this.eventSubscription) {
            this.eventSubscription.unsubscribe();
        }

        if (this.notificationsSub) {
            this.notificationsSub.unsubscribe();
        }
    }

    trackId(_index: number, data: IUiCard) {
        return data.cardId;
    }

    trackGroup(_index: number, group: CardGroup): string {
        return group.date;
    }

    resetScrollTo(card: IUiCard): void {
        card.scrollTo = false;
        this.uiCardService.resetScroll();
    }

    private hasCardTypeParams(queryParams: Params, cardTypeToCheck: string): boolean {
        const { cardType } = queryParams;
        return cardType === cardTypeToCheck;
    }

    async regroupCards() {
        await this.recheckFirstUnread();
    }

    private async loadCards(thread: IThread): Promise<void> {
        this.uiCards = [];
        this.loader.show();

        const threadCards = await this.cardService.getCards(this.thread.id).toPromise();
        const filteredCards = this.excludeCardTypes
            ? threadCards.filter(card => !this.excludeCardTypes.includes(card.type))
            : threadCards;
        const params = this.route.snapshot.queryParams;
        const cardTypeToCheck = EVENT_REPLY_TYPE;
        const isModalDisabled = this.hasCardTypeParams(params, cardTypeToCheck);

        for (const card of filteredCards.reverse()) {
            this.loadCard(thread, card);
        }

        if (this.routeToCardId) {
            this.uiCardService.routeToCard(this.uiCards, this.routeToCardId, isModalDisabled);
        }

        this.uiCardsByDate = this.groupCardsByDate(this.uiCards);
        await this.recheckFirstUnread();

        this.isFirstLoad = false;

        this.loader.hide();
    }

    private async loadCard(thread: IThread, card: IThreadCard) {
        const uiCard = this.uiCardService.mapCard(thread, card, this.role);
        if (!uiCard) return;

        this.uiCards.push(uiCard);
        this.loadCardComplete.emit();
    }

    private async handleNotification(thread: IThread, notification: ISocketEvent) {
        const { threadId, cardId, eventKey } = notification;
        if (threadId !== thread.id) {
            return console.error("Received event for different thread", threadId, thread.id);
        }

        if (eventKey) {
            const event = await this.cardService.getEvent(threadId, cardId, eventKey).toPromise();
            this.uiCardService.addCardEvent(this.uiCards, cardId, event);
        }
        const card = await this.cardService.getCard(threadId, cardId).toPromise();
        this.loadCard(thread, card);
        this.uiCardsByDate = this.groupCardsByDate(this.uiCards);
        await this.recheckFirstUnread();
    }

    private initThread(thread: IThread) {
        this.participantsCache.update(thread.participants);
        this.loadCards(thread);

        if (this.wsSubscription) this.wsSubscription.unsubscribe();
        this.wsSubscription = this.websocketService
            .watchThreadId(thread.id)
            .pipe(
                filter(notification => !notification.state),
                filter(notification => !!notification.cardId),
                filter(notification => notification.eventType === "created" && notification.subjectType === "card")
            )
            .subscribe(notification => this.handleNotification(thread, notification));
    }

    private groupCardsByDate(uiCards: IUiCard[]): CardGroup[] {
        // sort the cards on first load only for a given thread
        const sortedCards = this.isFirstLoad ? uiCards.sort((a, b) => this.uiCardService.compareCards(a, b)) : uiCards;
        const uiCardsByDate = sortedCards
            .reduce((groups, card) => this.reduceByDate(groups, card), {} as CardKeyGroups);

        return Object.entries(uiCardsByDate).map(([date, cards]) => ({ date, cards }));
    }

    private reduceByDate(groups: CardKeyGroups, uiCard: IUiCard): CardKeyGroups {
        const date = this.formatMillis(uiCard.timestamp);

        if (!groups[date]) {
            groups[date] = [];
        }

        groups[date].push(uiCard);
        return groups;
    }

    private async recheckFirstUnread() {
        const notifications = await this.activityNotifications$.pipe(take(1)).toPromise();
        this.setFirstUnread(notifications);
    }

    private setFirstUnread(notifications: Notification[]) {
        const threadId = this.thread.id;

        const unreadNotifications = notifications.filter(
            notification => notification.state === NotificationState.Delivered
        );

        const firstCard = this.uiCards.find(uiCard =>
            unreadNotifications.some(notification =>
                notification.channel.endsWith(`${threadId}/cards/${uiCard.cardId}`)
            )
        );

        if (this.clearNewTimeout) {
            clearTimeout(this.clearNewTimeout);
        }

        if (firstCard) {
            this.firstUnreadCardId = firstCard.cardId;
        } else {
            this.clearNewTimeout = setTimeout(() => (this.firstUnreadCardId = null), 10000);
        }
    }

    private formatMillis(millis: number): string {
        return DateTime.fromMillis(millis).toLocaleString(DateTime.DATE_HUGE);
    }
}
