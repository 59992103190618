import { Component, Inject, InjectionToken, OnDestroy, OnInit } from "@angular/core";
import { CardTaskActionLabel, ICardTaskDetail, IThreadCard } from "@findex/threads";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { TaskActionService } from "projects/portal-modules/src/lib/shared/components/actionable-card/task-action.service";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { WindowListenersService } from "projects/portal-modules/src/lib/shared/services/window-listeners.service";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { ActionableCardComponent } from "../../../../../projects/portal-modules/src/lib/shared/components/actionable-card/actionable-card";
import { TASK_ACTION_LIBRARY } from "../../../../../src/app/injection-token";
import { ILibrary } from "../../../../portal-modules/src/lib/plugins/interfaces/ILibrary";
import { TaskAction } from "../../../../portal-modules/src/lib/plugins/services/Libraries";
import { CardResources, THREAD_CARD_RESOURCES } from "../../../../portal-modules/src/lib/threads-ui/interfaces/IUiCard";

export const DASHBOARD_THREAD_TASK_DATA = new InjectionToken<ICardTaskDetail>("DASHBOARD_THREAD_TASK_DATA");

const localLoader = new Loader();
@Component({
    selector: "dashboard-threads-task",
    templateUrl: "./threads-task.component.html",
    styleUrls: ["./threads-task.component.scss"],
    providers: [{ provide: Loader, useValue: localLoader }]
})
export class ThreadsTaskComponent extends ActionableCardComponent<void> implements OnInit, OnDestroy {
    readonly GA_EVENTS = GA_EVENTS;

    actionInProgress: boolean;
    actionLabel$: Observable<string>;
    card$: Observable<IThreadCard>;
    statusIcon = null;
    statusText = "Pending";

    TABLET_SIZE = 1024;

    constructor(
        @Inject(DASHBOARD_THREAD_TASK_DATA) public task: ICardTaskDetail,
        @Inject(THREAD_CARD_RESOURCES) protected resources: CardResources,
        @Inject(TASK_ACTION_LIBRARY) private taskActionLibrary: ILibrary<TaskAction<void>>,
        public loader: Loader,
        private windowListenersService: WindowListenersService,
        taskActionService: TaskActionService
    ) {
        super(resources, taskActionService);
    }

    async ngOnInit() {
        const taskAction = this.taskActionLibrary.resolve(this.task.taskId);
        if (!taskAction) {
            throw new Error("No task registered for this id.");
        }
        this.actionLabel$ = this.windowListenersService.resize.pipe(
            map(() => this.getActionLabelText(taskAction)),
            startWith(this.getActionLabelText(taskAction))
        );
        this.statusIcon = taskAction.statusIcon;
        this.card$ = this.getCard();

    }

    getCard(): Observable<IThreadCard> {
        return this.resources.card$;
    }

    getActionLabelText(taskAction: TaskAction<any>) {
        if (this.windowListenersService.isWindowSmaller(this.TABLET_SIZE)) {
            return CardTaskActionLabel.Respond;
        } else {
            return taskAction.buttonLabel;
        }
    }

    ngOnDestroy() {
        // prevent memory leaks
        if (this.resources?.eventsSubject) {
            this.resources?.eventsSubject.complete();
        }
        if (this.resources?.navigateToSubject) {
            this.resources?.navigateToSubject.complete();
        }
    }
}
