import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { IParticipant, IVaultListItem } from "@findex/threads";
import { ThreadsVaultService } from "projects/portal-modules/src/lib/threads-ui/services/threads-vault.service";
import { combineLatest, Observable, Subject, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, map, startWith, switchMap, takeUntil } from "rxjs/operators";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { WindowListenersService } from "projects/portal-modules/src/lib/shared/services/window-listeners.service";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";

import { DateTime } from "luxon";
import { ThreadsWebsocketService } from "projects/portal-modules/src/lib/shared/services/threads-websocket.service";
import { ParticipantCache } from "projects/portal-modules/src/lib/threads-ui/services/participant-cache.service";

const TIME_RANGE = [
    {
        label: "Last 7 days",
        value: "7"
    },
    {
        label: "Last 30 days",
        value: "30"
    },
    {
        label: "Last 3 months",
        value: "120" // set to roughly 4 months, timeAgo pipe represents 3-4 months as '3 months'.
    },
    {
        label: "Last 12 months",
        value: "365"
    }
];

interface IFilter {
    dateRange: string;
    accountId: string;
}

@Component({
    selector: "app-vault-list-route",
    templateUrl: "./vault-list-route.component.html",
    styleUrls: ["./vault-list-route.component.scss"]
})
export class VaultListRouteComponent implements OnInit {
    readonly GA_EVENTS = GA_EVENTS;
    readonly TIME_RANGE = TIME_RANGE;

    form = new FormGroup({
        dateRange: new FormControl(null),
        accountId: new FormControl(null)
    });

    isMobileView: boolean;
    searchTerm = new FormControl();
    filterSubscription: Subscription;
    documents: IVaultListItem[];
    filteredResults: IVaultListItem[];
    accounts: { name: string; id: string }[];

    private websocketSubs: Subscription[] = [];

    private searchTerm$: Observable<string>;
    private filters$: Observable<IFilter>;
    private unbindState$ = new Subject();
    searchableUsers: IParticipant[];

    constructor(
        private threadsVaultService: ThreadsVaultService,
        private windowListenersService: WindowListenersService,
        private websocketService: ThreadsWebsocketService,
        private participantCache: ParticipantCache,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig
    ) {
        this.isMobileView = this.windowListenersService.isWindowSmaller(
            this.environment.featureFlags.windowWidthTabletBreakpoint
        );
    }

    private async filterDuplicateActors(documents: IVaultListItem[]) {
        const actorIds = documents.map(items => items.actorId);
        const filtered = actorIds.filter((item, i) => actorIds.findIndex(actor => actor === item) === i);

        const getUserProfiles = await Promise.all(
            filtered.map(async id => this.participantCache.getParticipant(id).toPromise())
        );

        return getUserProfiles;
    }

    async ngOnInit() {
        this.documents = await this.threadsVaultService.listVaults().toPromise();

        this.bindWebsocketSubscriptions(this.documents);

        this.accounts = this.filterDuplicateAccounts(this.documents);
        this.searchableUsers = await this.filterDuplicateActors(this.documents);
        this.searchTerm$ = this.searchTerm.valueChanges.pipe(debounceTime(500), distinctUntilChanged(), startWith(""));
        this.filters$ = this.form.valueChanges.pipe(distinctUntilChanged(), startWith(""));
        this.filterSubscription = combineLatest([this.searchTerm$, this.filters$])
            .pipe(map(([searchTerm, filters]) => this.filterResults(this.documents, searchTerm, filters)))
            .subscribe(filteredResults => {
                this.filteredResults = filteredResults;
            });
    }

    updateSearchTerm(searchTerm: string): void {
        this.searchTerm.setValue(searchTerm);
    }

    ngOnDestroy() {
        this.unbindState$.next(null);
        this.unbindState$.complete();

        if (this.filterSubscription) {
            this.filterSubscription.unsubscribe();
        }
    }

    private bindWebsocketSubscriptions(documents: IVaultListItem[]): void {
        const uniqueCards = this.filterUniqueCards(documents);
        uniqueCards.forEach(vaultItem => {
            if (!vaultItem) {
                return;
            }
            this.websocketSubs.push(this.subscribeToSubject(vaultItem.threadId, vaultItem.cardId));
        });
    }

    private findParticipantsWithSearchTerm(searchTerm: string): string[] {
        return this.searchableUsers
            .filter(val => val.profile.name && val.profile.name.toLowerCase().includes(searchTerm))
            .map(val => val.id);
    }

    private filterResults(documents: IVaultListItem[], searchTerm: string, filters: IFilter): any[] {
        const lowerTerm = searchTerm.toLowerCase();
        const { accountId, dateRange } = filters;
        return documents.filter(item => {
            const { file, thread, account, actorId } = item;

            const returnMatchingParticipants = this.findParticipantsWithSearchTerm(lowerTerm);

            const searchTerm =
                file?.displayName?.toLowerCase().includes(lowerTerm) ||
                thread?.type?.toLowerCase().includes(lowerTerm) ||
                thread?.title?.toLowerCase().includes(lowerTerm) ||
                returnMatchingParticipants?.includes(actorId);

            const accountFilter = accountId ? account?.id?.includes(accountId) : true;
            const timeRangeFilter = this.checkRange(file.timestamp, dateRange);

            return searchTerm && accountFilter && timeRangeFilter;
        });
    }

    private checkRange(timeStamp: string, dateRange: string): boolean {
        const timeStampDate = DateTime.fromISO(timeStamp);
        const diff = Math.abs(timeStampDate.diffNow().as("day"));
        const wholeNumberedDateDifference = Math.round(diff);
        return dateRange ? wholeNumberedDateDifference <= Number(dateRange) : true;
    }

    private subscribeToSubject(threadId: string, cardId: string): Subscription {
        return this.websocketService
            .watchCardId(threadId, cardId)
            .pipe(
                takeUntil(this.unbindState$),
                switchMap(() => this.threadsVaultService.listVaults())
            )
            .subscribe(documents => {
                this.documents = documents;
                this.filteredResults = this.filterResults(documents, this.searchTerm.value || "", this.form.value);
            });
    }

    private filterDuplicateAccounts(documents: IVaultListItem[]): IVaultListItem["account"][] {
        const accounts = documents.filter(val => val && val.account?.id && val.account?.name).map(val => val.account);
        const filtered = accounts.filter((item, i) => accounts.findIndex(account => account.id === item.id) === i);
        return filtered;
    }

    private filterUniqueCards(
        documents: IVaultListItem[]
    ): {
        cardId: string;
        threadId: string;
    }[] {
        const cards = documents.map(val => ({
            cardId: val.cardId,
            threadId: val.thread.id
        }));

        const filtered = cards.filter((item, i) => cards.findIndex(card => card.cardId === item.cardId) === i);
        return filtered;
    }
}
