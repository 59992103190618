import { Injectable } from "@angular/core";
import {
    Notification,
    NotificationsService,
    NotificationState,
    PossibleDeliveryData
} from "@findex/notifications-angular";
import { Observable, of } from "rxjs";
import { map, shareReplay, switchMap } from "rxjs/operators";
import { AppUser, AuthService } from "../../findex-auth";
import { PaginatedDataset } from "@findex/datastore-types-frontend";

const BANNER_CHANNEL_PREFIX = "banner";
const ACTIVITY_PAGE_SIZE = 200;

@Injectable({ providedIn: "root" })
export class BannerNotificationsService {
    private banners$: Observable<Notification[]>;
    private notificationsPage: PaginatedDataset<Notification>;

    constructor(private notificationsService: NotificationsService, authService: AuthService) {
        const userChange$ = authService.getUserWithoutRole();
        this.banners$ = this.getBannerUpdates$(userChange$);
    }

    getBanners(): Observable<Notification<PossibleDeliveryData>[]> {
        return this.filterBannerNotifications$(this.banners$);
    }

    private getBannerUpdates$(user$: Observable<AppUser>): Observable<Notification<PossibleDeliveryData>[]> {
        return user$.pipe(
            switchMap(() => this.updateNotificationPage()),
            switchMap(() => this.getNextPage()),
            switchMap(() => this.notificationsPage.getDataObservable()),
            shareReplay()
        );
    }

    private filterBannerNotifications$(
        banner$: Observable<Notification<PossibleDeliveryData>[]>
    ): Observable<Notification<PossibleDeliveryData>[]> {
        return banner$.pipe(
            map(notifications => {
                const sortNotifications = notifications.sort(
                    (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
                );
                const unresolvedBanners = sortNotifications.filter(
                    notification =>
                        !!notification.deliveryData &&
                        notification.state !== NotificationState.Resolved &&
                        notification.channel.includes(BANNER_CHANNEL_PREFIX)
                );
                return unresolvedBanners;
            })
        );
    }

    private updateNotificationPage(): Observable<any> {
        this.notificationsPage = this.notificationsService.getCurrentNotifications(
            BANNER_CHANNEL_PREFIX,
            ACTIVITY_PAGE_SIZE
        );
        return of([]);
    }

    private async getNextPage(prevCount = 0): Promise<number> {
        const nextPage = await this.notificationsPage.nextPage().toPromise();
        const count = nextPage?.result?.length || 0;

        if (count + prevCount < 10 && nextPage?.next) {
            const result = await this.getNextPage(prevCount);
            return result + count + prevCount;
        } else {
            return count;
        }
    }
}
