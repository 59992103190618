import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { AuthInterceptor } from "./interceptors/auth.interceptor";
import { LoginDialogComponent } from "./components/login-dialog/login-dialog.component";
import { FindexUiModule } from "projects/portal-modules/src/lib/findex-ui";
import { CommonModule } from "@angular/common";
import { MatDialogModule } from "@angular/material/dialog";
import { RouterModule } from "@angular/router";
import { LoginPageComponent } from "./components/login-page/login-page.component";
import { LoginFormComponent } from "./components/login-form/login-form.component";
import { ForgotPasswordFormComponent } from "./components/forgot-password-form/forgot-password-form.component";
import { ForgotPasswordDialogComponent } from "./components/forgot-password-dialog/forgot-password-dialog.component";
import { NewPasswordFormComponent } from "./components/new-password-form/new-password-form.component";
import { NewPasswordDialogComponent } from "./components/new-password-dialog/new-password-dialog.component";
import { TwoFactorAuthenticationFormComponent } from "./components/two-factor-authentication-form/two-factor-authentication-form.component";
import { TwoFactorAuthenticationDialogComponent } from "./components/two-factor-authentication-dialog/two-factor-authentication-dialog.component";
import { ResetPasswordComponent } from "./components/reset-password/reset-password.component";
import { AnalyticsModule } from "projects/portal-modules/src/lib/analytics";
import { MsalBroadcastService, MsalGuard, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from "@azure/msal-angular";
import { LoginComponent } from "./components/login/login.component";
import { LoginFooterComponent } from "./components/login-footer/login-footer.component";
import { AzureAdInterceptor } from "./services/strategies/azure-ad.interceptor";
import { MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from "./services/strategies/azure-ad-auth.strategy";
import { ENVIRONMENT } from "src/app/injection-token";

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        HttpClientModule,
        FindexUiModule,
        MatDialogModule,
        RouterModule,
        AnalyticsModule,
        MsalModule
    ],
    exports: [LoginDialogComponent],
    declarations: [
        LoginDialogComponent,
        LoginPageComponent,
        LoginComponent,
        LoginFormComponent,
        ForgotPasswordFormComponent,
        ForgotPasswordDialogComponent,
        NewPasswordFormComponent,
        NewPasswordDialogComponent,
        TwoFactorAuthenticationFormComponent,
        TwoFactorAuthenticationDialogComponent,
        ResetPasswordComponent,
        LoginFooterComponent
    ],
    providers: [
        { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory,deps: [ENVIRONMENT] },
        { provide: MSAL_GUARD_CONFIG, useFactory: MSALGuardConfigFactory },
        { provide: MSAL_INTERCEPTOR_CONFIG, useFactory: MSALInterceptorConfigFactory, deps: [ENVIRONMENT] },
        AzureAdInterceptor,
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
    ]
})
export class FindexAuthModule { }
