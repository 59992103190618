import { ErrorHandler, Injectable } from "@angular/core";
import { ErrorService } from "projects/portal-modules/src/lib/shared/services/error.service";
import { HttpErrorResponse } from "@angular/common/http";
// import * as Sentry from "@sentry/angular";

export class HandledError {
    constructor(public originalError: any) {}
}
export class ThreadsHttpError extends Error {
    originalHttpErrorResponse: HttpErrorResponse;
    constructor(sourceError: HttpErrorResponse) {
        super((sourceError.error && sourceError.error.message) || sourceError.message);
        this.originalHttpErrorResponse = sourceError;
    }
}

// const FILTER_ERRORS = ["ClientAuthError: Token calls are blocked in hidden iframes"];
@Injectable()
export class ThreadsErrorHandler extends ErrorHandler {
    constructor(private errorService: ErrorService) {
        super();
    }

    handleError(err: any) {
        this.errorService.handleError(err);
    }
}
