import { Component, Inject, Input, OnChanges, SimpleChanges } from "@angular/core";
import { IThread, IUserSetupRequest, Role } from "@findex/threads";
import { MatDialog } from "@angular/material/dialog";
import { ThreadAddParticipantComponent } from "./thread-add-participant/thread-add-participant.component";
import { ThreadRemoveParticipantComponent } from "./thread-remove-participant/thread-remove-participant.component";
import { Router } from "@angular/router";
import { filter, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { ParticipantDetail } from "projects/portal-modules/src/lib/user-profile/services/user-profile.service";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { OnboardingService } from "../../../onboarding/services/onboarding.service";
import { ENVIRONMENT, PARTICIPANT_ENRICHMENT_SERVICE } from "src/app/injection-token";
import { GA_EVENTS } from "../../../analytics";
import { PermissionService } from "../../services/permissions.service";

export interface IParticipantEnrichmentService {
    enrichParticipantDetail(participant: ParticipantDetail, role: Role): Promise<ParticipantDetail>;
}

export interface IParticipantDetail extends Omit<ParticipantDetail, "id" | "type"> {
    id?: string;
    businessName?: string;
    type?: string;
}

const isIUserSetupRequest = (data: any): data is IUserSetupRequest =>
    data && data.clients && data.threads && data.additionalInformation;

@Component({
    selector: "thread-participants",
    templateUrl: "./thread-participants.component.html",
    styleUrls: ["./thread-participants.component.scss"]
})
export class ThreadParticipantsComponent implements OnChanges {
    readonly addParticipantsButton = this.environment.featureFlags.addParticipants;
    readonly threadParticipantDetails = this.environment.featureFlags.threadParticipantDetail;
    readonly gaEvents = GA_EVENTS;

    @Input() thread: IThread;
    @Input() role: Role;

    threadParticipants: IParticipantDetail[] = [];

    roles = Role;
    userId$: Observable<string>;
    userId: string;
    showDialog = false;

    constructor(
        private dialog: MatDialog,
        private router: Router,
        private authService: AuthService,
        private onboardingService: OnboardingService,
        private permissionService: PermissionService,
        @Inject(PARTICIPANT_ENRICHMENT_SERVICE) private participantEnrichmentService: IParticipantEnrichmentService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig
    ) {
        this.userId$ = this.authService.getUser().pipe(
            filter(user => !!user),
            map(user => {
                this.userId = user.id;
                return user.id;
            })
        );
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.thread && changes.thread.currentValue.participants) {
            await this.setParticipantData();
        }
    }

    async setParticipantData() {
        try {
            const participants = await this.getThreadParticipantsDetail();
            const currentParticipant = this.thread.participants.find(participant => participant.id === this.userId);
            if (currentParticipant && currentParticipant.role === Role.Client) {
                this.threadParticipants = participants;
                return;
            }

            this.threadParticipants = await Promise.all(
                participants.map(async (participant: IParticipantDetail) => {
                    if (participant.role === Role.Client && participant.id) {
                        const onboardingData = await this.onboardingService
                            .getCompletionDetails(participant.id)
                            .toPromise();
                        if (onboardingData && onboardingData.businessName) {
                            participant.businessName = onboardingData.businessName;
                        }
                    }
                    return participant;
                })
            );
        } catch (err) {
            this.threadParticipants = this.thread.participants;
        }
    }

    private async getThreadParticipantsDetail(): Promise<ParticipantDetail[]> {
        if (!this.threadParticipantDetails) {
            return this.thread.participants;
        }
        const enrichedParticipants = await Promise.all(
            this.thread.participants.map(participant =>
                this.participantEnrichmentService.enrichParticipantDetail(participant, this.role)
            )
        );
        return enrichedParticipants;
    }

    async openDialog() {
        const modalData = await this.dialog
            .open(ThreadAddParticipantComponent, {
                data: { thread: this.thread, currentUserId: this.userId, role: this.role },
                disableClose: true,
                panelClass: ["threads-add-participants-modal"]
            })
            .afterClosed()
            .toPromise();

        if (isIUserSetupRequest(modalData)) {
            //  TODO: ED-1193 race condition from thread endpoint prevents usernames from being returned, fix that and remove this workaround
            this.addInvitedParticipants(modalData);
        }
    }

    addInvitedParticipants(data: IUserSetupRequest) {
        const currentSetParticipants = this.threadParticipants;

        const filteredParticipants = currentSetParticipants.filter(participant => !!participant.profile);

        const newParticipants = [
            ...filteredParticipants,
            ...data.clients.map(client => ({
                profile: {
                    ...client,
                    name: client.firstName + " " + client.lastName
                }
            }))
        ];

        this.threadParticipants = newParticipants;
    }

    async openProfile(participantId: string) {
        const canOpenProfile = await this.permissionService.checkPermissions(this.role, "ReadProfile").toPromise();
        if (canOpenProfile) {
            this.router.navigate(["/admin", "clients", participantId]);
        }
    }

    remove(participant: IParticipantDetail) {
        this.dialog.open(ThreadRemoveParticipantComponent, {
            panelClass: "centered-modal",
            data: { participant, thread: this.thread }
        });
    }
}
