<div class="metric-widget"
     [ngClass]="{
        'metric-widget--positive-trend': isMetricBetterThenTarget,
        'metric-widget--negative-trend': !isMetricBetterThenTarget
     }">
    <div class="metric-widget-title">
        {{ label }}
    </div>

    <div class="metric-widget-targets">
        <span *ngIf="amount && unit === '$'"
              class="metric-widget-targets-value">
            {{ amount | currency }}
        </span>

        <span *ngIf="amount && unit !== '$'"
              class="metric-widget-targets-value">
            {{ amount | number:'1.0-2' }} {{ unit }}
        </span>

        <span *ngIf="!amount" class="metric-widget-targets-value">
            -
        </span>

        <span *ngIf="target"
              class="metric-widget-targets-change"
              [ngClass]="{
                'metric-widget-targets-change--positive-trend': isMetricBetterThenTarget,
                'metric-widget-targets-change--negative-trend': !isMetricBetterThenTarget
              }">
            <insights-trend-pill [isBetterThanTarget]="isMetricBetterThenTarget"
                                 [isBelowTarget]="isMetricBelowTarget"
                                 [value]="value | absolute | number:'1.0-1'">
            </insights-trend-pill>
        </span>

        <span *ngIf="target" class="metric-widget-targets-vs">
            vs target
        </span>
    </div>

    <div class="metric-widget-timestamp">
        Last updated
        <span>
            {{ lastUpdated | timeAgo }}
        </span>
    </div>
</div>
