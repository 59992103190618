import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from "@angular/core";
import { Loader } from "../../../shared/services/loader";
import { CardReply, IThread, IThreadCard } from "@findex/threads";
import { filter, take } from "rxjs/operators";
import { AuthService } from "../../../findex-auth";
import { Observable } from "rxjs";
import { FormControl, FormGroup } from "@angular/forms";
import { ThreadCardService } from "../../services/thread-card.service";
import { ReplyComponent } from "../reply/reply.component";
import { HOT_JAR_EVENTS } from "../../../analytics/services/hotjarAnalytics.service";

@Component({
    selector: "thread-card-replies",
    templateUrl: "./thread-card-replies.component.html",
    styleUrls: ["./thread-card-replies.component.scss"]
})
export class ThreadCardRepliesComponent implements OnInit {
    @ViewChildren("replyComponents") replyComponents: QueryList<ReplyComponent>;

    @Input() replies: CardReply[];
    @Input() thread: IThread;
    @Input() userId$: Observable<string>;
    @Input() card: IThreadCard;
    @Input() showCompose = false;
    @Input() readonly = false;
    @Input() formGroup: FormGroup;

    @Output() onReplyInput: EventEmitter<boolean> = new EventEmitter();
    @Output() onReplySaved: EventEmitter<CardReply> = new EventEmitter();

    readonly hotJarEvents = HOT_JAR_EVENTS;
    replyMessage = new FormControl("");
    replyLoader = new Loader();
    quillError: boolean = false;

    constructor(
        private cardService: ThreadCardService,
        private authService: AuthService
    ) {}

    ngOnInit(): void {
        if (this.formGroup) {
            this.formGroup.setControl("replyMessage", this.replyMessage);
        }
    }

    toggleReplyLoader(isLoading: boolean): void {
        if (isLoading) this.replyLoader.show();
        else this.replyLoader.hide();
    }

    onUserInput(message: FormControl) {
        this.replyMessage = message;
    }

    async reply(message: FormControl) {
        this.replyLoader.show();

        const user = await this.authService
            .getUser()
            .pipe(
                filter(userObj => !!userObj),
                take(1)
            )
            .toPromise();

        const reply = {
            id: "",
            message: message.value,
            timestamp: new Date().toISOString(),
            actor: user.id
        };

        try {
            this.replies = this.replies || [];
            this.replies.push(reply);
            this.replyMessage.setValue("");

            await this.cardService.replyCard(this.thread.id, this.card.id, reply.message).toPromise();

            this.showCompose = false;
            this.onReplySaved.next(reply);
        } catch (err) {
            const replyIndex = this.replies.indexOf(reply);
            this.replies.splice(replyIndex, 1);
            this.replyMessage.setValue(message.value);
            throw err; //Let global error show
        } finally {
            this.replyLoader.hide();
        }
    }

    replyTrack(_index: number, reply: CardReply): string {
        return reply.id;
    }
}
