import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { Subscription } from "rxjs";
import { ENVIRONMENT } from "src/app/injection-token";
import {
    customValidators,
    getPasswordErrorMessage
} from "../../../../../../projects/portal-modules/src/lib/shared/validators";
export interface ICreateAccountModel {
    businessName: string;
    givenName: string;
    familyName: string;
    mobileNumber: string;
    emailAddress: string;
    password: string;
    agreedToTermsAndConditions: boolean;
}

@Component({
    selector: "create-account",
    templateUrl: "./create-account.component.html",
    styleUrls: ["./create-account.component.scss"]
})
export class CreateAccountComponent implements OnInit, OnDestroy {
    @Input() errorMessage: string;
    readonly signupCountries = this.environment.featureFlags.signupCountries;
    constructor(@Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig) {}
    @Output() emitCreateAccount = new EventEmitter<ICreateAccountModel>();
    @Output() emitValidAccount = new EventEmitter<boolean>();
    internationalPhoneNo: string;
    internationalPhoneNoValid: boolean;
    form = new FormGroup({
        businessName: new FormControl(null, Validators.required),
        givenName: new FormControl(null, Validators.required),
        familyName: new FormControl(null, Validators.required),
        emailAddress: new FormControl(null, [Validators.required, Validators.email]),
        mobileNumber: new FormControl(null, {
            validators: [Validators.required, customValidators.mobileValidator(this.signupCountries)],
            updateOn: "blur"
        }),
        password: new FormControl(null, {validators: [Validators.required, customValidators.passwordValidator]}),
        agreedToTermsAndConditions: new FormControl(null, Validators.requiredTrue)
    });
    formStatusSubscription: Subscription;

    policyUrl = this.environment.policyUrl;
    termsUrl = this.environment.termsUrl;

    ngOnInit() {
        this.formStatusSubscription = this.form.statusChanges.subscribe(status => {
            if (status === "VALID") {
                this.emitValidAccount.emit(true);
                this.emitAccountDetails();
            } else {
                this.emitValidAccount.emit(false);
            }
        });
    }

    ngOnDestroy() {
        this.formStatusSubscription.unsubscribe();
    }

    private emitAccountDetails() {
        if (this.form.valid) {
            this.errorMessage = "";
            const {
                businessName,
                familyName,
                givenName,
                emailAddress,
                password,
                agreedToTermsAndConditions
            } = this.form.value;
            this.emitCreateAccount.emit({
                businessName,
                familyName,
                givenName,
                emailAddress,
                mobileNumber: this.internationalPhoneNo,
                password,
                agreedToTermsAndConditions
            });
        }
    }
    getPasswordError(controlName: string, passwordMatchError: boolean): string {
        return getPasswordErrorMessage(controlName, passwordMatchError === false);
    }
}
