import { Injectable } from "@angular/core";
import { IPluginFactory } from "projects/portal-modules/src/lib/plugins";
import { Libraries } from "projects/portal-modules/src/lib/plugins/services/Libraries";
import { ClientProfileCardComponent } from "./components/client-profile-card/client-profile-card.component";

@Injectable()
export class SameDayTaxPlugin implements IPluginFactory {
    id = "SameDayTaxPlugin";

    constructor(libraries: Libraries) {
        libraries.cardViews.register("client-profile", ClientProfileCardComponent);
    }
}
