<div class="calendar-card-details-modal">
    <loader-icon color="light" *ngIf="!(loaded$ | async)" [static]="true"></loader-icon>
    <ng-container *ngIf="loaded$ | async">
        <div class="calendar-card-details-modal-close">
            <div class="fx-l-modal-header-icon" [mat-dialog-close]="true">
                <i class="las la-times icon-size"></i>
            </div>
        </div>
        <ng-container *ngIf="userId$ | async; let userId">
            <ng-container *ngIf="(thread$ | async | isThreadActive | async) && invitationId">
                <ng-container *ngIf="invitationCancelled">
                    <p class="alt-meeting-text">
                        This meeting has been cancelled by the organiser.
                    </p>
                </ng-container>
                <ng-container *ngIf="!(invitedToMeeting$ | async) && !invitationCancelled">
                    <p class="alt-meeting-text">
                        {{ this.notInvitedToMeetingText }}
                    </p>
                </ng-container>
            </ng-container>
            <div class="calendar-card-details">
                <i class="mobile-hide-icon las la-calendar"></i>
                <p class="calendar-card-details-name">{{ meetingName$ | async }}</p>
            </div>
            <div class="calendar-card-details">
                <i class=" mobile-hide-icon las la-history"></i>
                <p class="calendar-card-details-schedule-cancelled" *ngIf="invitationCancelled">
                    Cancelled
                </p>
                <ng-container *ngIf="!invitationCancelled">
                    <div class="calendar-card-details-schedule">
                        <ng-container *ngIf="start && end && appointmentConfirmed">
                            <p class="calendar-card-details-schedule-date">{{ start | date: "EEEE MMMM d y" }}</p>
                            <p class="calendar-card-details-schedule-time">
                                {{ start | date: meetingTimeFormat }} - {{ end | date: meetingTimeFormat }}
                            </p>
                        </ng-container>
                        <ng-container *ngIf="!appointmentConfirmed">
                            <p
                                class="calendar-card-details-schedule-time"
                                *ngIf="role | permissions: 'CreateCalendarCard' | async"
                            >
                                A request has been sent. The client can now schedule an appointment using your
                                availability.
                            </p>
                            <p
                                class="calendar-card-details-schedule-time"
                                *ngIf="!(role | permissions: 'CreateCalendarCard' | async)"
                            >
                                Click "Schedule Meeting" to view available times and book a meeting.
                            </p>
                        </ng-container>
                    </div>
                    <button
                        *ngIf="
                            (canRescheduleMeeting$ | async) &&
                            ((invitation$ | async)?.recurrence || (meetingState$ | async) !== meetingStates.Ended)
                        "
                        class="fx-btn fx-btn--primary-outlined fx-btn--small"
                        type="button"
                        (click)="openCalendarModal('reschedule')"
                    >
                        RESCHEDULE
                    </button>
                    <button
                        *ngIf="
                            (invitedToMeeting$ | async) &&
                            role == roles.Client &&
                            this.organiser &&
                            !appointmentConfirmed
                        "
                        class="fx-btn fx-btn--primary-outlined fx-btn--small"
                        type="button"
                        analyticsClick="app_viewavialability"
                        (click)="openCalendarModal('schedule')"
                    >
                        SCHEDULE MEETING
                    </button>
                </ng-container>
            </div>
            <div class="calendar-card-details">
                <i class="mobile-hide-icon las la-sync"></i>
                <div
                    class="calendar-card-details-recurring"
                    *ngIf="(invitation$ | async)?.recurrence; let recurrence; else: norepeat"
                >
                    Repeat:
                    <span>
                        <strong>{{ recurrence.type | titlecase }}, {{ recurrence.numberOfOccurrences }} times</strong>
                    </span>
                </div>
                <ng-template #norepeat>
                    <div class="calendar-card-details-schedule-time">
                        Does not repeat
                    </div>
                </ng-template>
            </div>
            <div
                class="calendar-card-details calendar-card-details--break"
                *ngIf="attendees$ | async; let participants"
            >
                <i class="mobile-hide-icon las la-user"></i>
                <div class="calendar-card-details-participants">
                    <avatar-pill
                        *ngFor="let participant of participants"
                        [name]="participant.profile.name"
                        [id]="participant.id"
                        [image]="participant.id | profilePicture"
                        [disabled]="true"
                    ></avatar-pill>
                </div>
            </div>

            <div
                class="calendar-card-details calendar-card-details-description calendar-card-details--break"
                *ngIf="(invitation$ | async)?.message?.description; let description"
            >
                <i class="mobile-hide-icon las la-comment"></i>
                <quill-view [content]="description | allowWhitelistLinks" aria-label="description"></quill-view>
            </div>
        </ng-container>
    </ng-container>
</div>

