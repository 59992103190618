import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { IPaginated } from "@findex/datastore-types";
import { CardReply, CardStatus, ICardEvent, ICardSubject, IThreadCard } from "@findex/threads";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { ENVIRONMENT } from "src/app/injection-token";
import { environmentCommon, EnvironmentSpecificConfig } from "../../environment/environment.common";
import { CardStateResponse } from "./threads.service";

@Injectable({ providedIn: "root" })
export class ThreadCardService {
    constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig) {}

    createCard<CreateReq, Response>(threadId: string, cardType: string, data?: CreateReq): Observable<Response> {
        const { base } = this.environment.commonEndpoints;
        const { threads, cards } = environmentCommon.threadsEndpoints;

        const url = `${base}${threads}/${threadId}${cards}/${cardType}`;
        return this.http.post<Response>(url, { data });
    }

    editCard<EditRequest>(threadId: string, cardType: string, cardId: string, data?: EditRequest): Observable<void> {
        const { base } = this.environment.commonEndpoints;
        const { threads, cards } = environmentCommon.threadsEndpoints;

        const url = `${base}${threads}/${threadId}${cards}/${cardType}/${cardId}`;
        return this.http.put<void>(url, { data });
    }

    getCards(threadId: string): Observable<IThreadCard[]> {
        const { threads, cards } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${threads}/${threadId}${cards}`;
        return this.http.get<IThreadCard[]>(url);
    }

    getCard(threadId: string, cardId: string): Observable<IThreadCard> {
        const { threads, cards } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${threads}/${threadId}${cards}/${cardId}`;
        return this.http.get<IThreadCard>(url);
    }

    replyCard(threadId: string, cardId: string, message: string): Observable<void> {
        return this.addCardReplyEvent(threadId, cardId, { message });
    }

    updateReplyCard(threadId: string, cardId: string, replyId: string, message: string): Observable<void> {
        const cardReply = {
            id: replyId,
            message
        };

        return this.addCardReplyEvent(threadId, cardId, cardReply);
    }

    deleteReplyCard(threadId: string, cardId: string, replyId: string): Observable<void> {
        const cardReply = {
            id: replyId,
            status: CardStatus.Removed
        };

        return this.addCardReplyEvent(threadId, cardId, cardReply);
    }

    getCardEvents(threadId: string, cardId: string, next?: string): Observable<IPaginated<ICardEvent>> {
        const { threads, cards, events } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${threads}/${threadId}${cards}/${cardId}${events}`;
        const params = next && { next };
        return this.http.get<IPaginated<ICardEvent>>(url, { params });
    }

    getEvent(threadId: string, cardId: string, eventKey: string): Observable<ICardEvent> {
        const { threads, cards, events } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${threads}/${threadId}${cards}/${cardId}${events}/${eventKey}`;
        return this.http.get<ICardEvent>(url);
    }

    updateCard(
        threadId: string,
        cardId: string,
        subjects?: ICardSubject[],
        description?: string,
        status?: CardStatus,
        dueDate?: string
    ): Observable<void> {
        const { threads, cards } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${threads}/${threadId}${cards}/${cardId}`;
        return this.http.post<void>(url, { subjects, description, status, dueDate });
    }

    updateCardDescription(
        threadId: string,
        cardId: string,
        description?: string,
        status?: CardStatus
    ): Observable<void> {
        if (!description && !status) {
            return null;
        }
        const { threads, cards } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${threads}/${threadId}${cards}/${cardId}/description`;
        return this.http.post<void>(url, { description, status });
    }

    removeCard(threadId: string, cardId: string): Observable<void> {
        const { threads, cards } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${threads}/${threadId}${cards}/${cardId}/remove`;
        return this.http.post<void>(url, {});
    }

    cancelCard(threadId: string, cardId: string): Observable<void> {
        const { threads, cards } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${threads}/${threadId}${cards}/${cardId}/disable`;
        return this.http.put<void>(url, {});
    }

    deleteCard(threadId: string, cardId: string): Observable<void> {
        const { threads, cards } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${threads}/${threadId}${cards}/${cardId}`;
        return this.http.delete<void>(url, {});
    }

    migrateCalendarCards(threadId: string, newThreadId?: string): Observable<void> {
        const { threads, migrateCalendarCards } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${threads}${migrateCalendarCards}`;
        return this.http.post<void>(url, { threadId, newThreadId });
    }

    getCardState<StateType = any>(threadId: string, cardId: string): Observable<CardStateResponse<StateType>> {
        const { threads, cards, state } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${threads}/${threadId}${cards}/${cardId}${state}`;
        return this.http.get<any>(url).pipe(
            catchError(error => {
                if (error.status === 404) {
                    return of(undefined);
                }
                throw error;
            })
        );
    }

    rebuildCardState(threadId: string, cardId: string): Observable<void> {
        const { threads, cards, state } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${threads}/${threadId}${cards}/${cardId}${state}`;
        return this.http.delete<void>(url);
    }

    private addCardReplyEvent(threadId: string, cardId: string, cardReply: Partial<CardReply>): Observable<void> {
        const { threads, cards, events } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${threads}/${threadId}${cards}/${cardId}${events}`;

        const replyEvent = {
            type: "card-reply",
            correlationId: `card-reply/${threadId}/${cardId}`,
            payload: cardReply,
            description: cardReply.message
        };

        return this.http.post(url, replyEvent).pipe(map(() => {}));
    }
}
