<div class="create-card-documents" *ngIf="documents.length">
    <div *ngFor="let file of documents; let i = index" class="create-card-documents-file">
        <div class="create-card-documents-desc">
            <div class="create-card-documents-desc-icon">
                <i
                    class="las"
                    [ngClass]="{
                        'la-file': !file?.category && !file?.signature,
                        'la-signature': !file?.category && file?.signature,
                        'la-chart-bar': file?.category === documentType.Report
                    }"
                ></i>
            </div>
            <div class="text">
                {{ file.description }}
            </div>
        </div>

        <fx-context-menu [offsetY]="-50" analyticsClick="app_createcontext" (open)="updateDocumentTypeState()">
            <a
                [analyticsClick]="gaEvents.RFICREATE_RENAME"
                class="create-card-documents-menu"
                (click)="rename.emit(file)"
            >
                <i class="las la-pencil-alt"></i>
                Rename
            </a>
            <a
                [analyticsClick]="gaEvents.RFICREATE_MARKASREPORT"
                *ngIf="showInsights && (role | permissions: 'CreateInsights' | async) && !uploadIncludesSign"
                class="create-card-documents-menu"
                (click)="markDocumentAsReport(i, file)"
                [ngClass]="{ active: file.category === documentType.Report }"
            >
                <i class="las la-chart-bar"></i>
                Mark as report
            </a>
            <a
                class="create-card-documents-menu"
                *ngIf="
                    file.category !== documentType.Report &&
                    !(role | permissions: 'SignDocumentsVaultCard' | async) &&
                    !uploadIncludesReport
                "
                (click)="markDocumentAsSign(file)"
                [ngClass]="{ active: file.signature }"
                [analyticsClick]="gaEvents.APP_REQUESTSIGNATURE"
            >
                <i class="las la-signature"></i>
                Request signature
            </a>
            <a
                [analyticsClick]="gaEvents.RFICREATE_DELETE"
                class="create-card-documents-menu"
                (click)="removeDocument(file)"
            >
                <i class="las la-trash-alt"></i>
                Delete
            </a>
        </fx-context-menu>
    </div>
</div>
<div
    class="create-card-documents-error"
    *ngIf="errorMessages.length"
    [ngClass]="{ 'create-card-documents-error--has-documents': documents.length }"
>
    <div *ngFor="let messages of errorMessages" class="create-card-documents-error-messages">
        {{ messages }}
    </div>
</div>
