// worried that the typing might incorrect here and it might break, so disabling in whole file for now
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "formatOrdinalDate" })
export class FormatOrdinalDatePipe implements PipeTransform {
    transform(dayOfMonth: number): string {
        if (dayOfMonth === 31 || dayOfMonth === 21 || dayOfMonth === 1) return dayOfMonth + "st";
        else if (dayOfMonth === 22 || dayOfMonth === 2) return dayOfMonth + "nd";
        else if (dayOfMonth === 23 || dayOfMonth === 3) return dayOfMonth + "rd";
        else return dayOfMonth + "th";
    }
}
