import { Pipe, PipeTransform } from "@angular/core";
import { PaymentTypes } from "@findex/payments-service-sdk";

export const paymentTypeIsSubscriptionBased = (paymentType: PaymentTypes) =>
    paymentType === PaymentTypes.RecurringPayment || paymentType === PaymentTypes.ScheduledPayment;

@Pipe({ name: "paymentTypeIsSubscriptionBased" })
export class PaymentTypeIsSubscriptionBasedPipe implements PipeTransform {
    transform(type: PaymentTypes) {
        return paymentTypeIsSubscriptionBased(type);
    }
}
