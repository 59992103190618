import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { IThread, IThreadCard, Role, CardReply, IVaultRequestCardState } from "@findex/threads";
import { combineLatest, Observable, Subscription } from "rxjs";
import { CardResources, THREAD_CARD_RESOURCES } from "projects/portal-modules/src/lib/threads-ui/interfaces/IUiCard";
import { CardStatus } from "@findex/threads/dist/enums/CardStatus";
import { ENVIRONMENT, TASK_ACTION_LIBRARY } from "src/app/injection-token";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { ThreadCardService } from "projects/portal-modules/src/lib/threads-ui/services/thread-card.service";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { VaultRequestService } from "../../services/vault-request.service";
import { GA_EVENTS_PREFIX } from "projects/portal-modules/src/lib/analytics";
import { ActionableCardComponent } from "projects/portal-modules/src/lib/shared/components/actionable-card/actionable-card";
import { ILibrary, TaskAction } from "projects/portal-modules/src/lib/plugins";
import { VaultStateAction } from "@findex/threads";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { filter, map, switchMap, take } from "rxjs/operators";
import { TaskActionService } from "projects/portal-modules/src/lib/shared/components/actionable-card/task-action.service";
import { ThreadsService } from "projects/portal-modules/src/lib/threads-ui/services/threads.service";
import { PermissionService } from "projects/portal-modules/src/lib/threads-ui/services/permissions.service";

@Component({
    selector: "vault-request-card",
    templateUrl: "./vault-request-card.component.html",
    styleUrls: ["./vault-request-card.component.scss"]
})
export class VaultRequestCardComponent extends ActionableCardComponent<boolean> implements OnDestroy, OnInit {
    readonly gaEventsPrefix = GA_EVENTS_PREFIX;
    readonly VAULT_ACTION_REQUEST_RESPONSE = VaultStateAction.RequestResponse;
    readonly VAULT_ACTION_VIEW_RESPONSE = VaultStateAction.ViewResponse;
    readonly VAULT_ACTION_EDIT_RESPONSE = VaultStateAction.EditResponse;
    readonly VAULT_ACTION_VIEW_CANCELED_RESPONSE = VaultStateAction.ViewCanceledRequest;

    private stateSub: Subscription;
    private navigationSub: Subscription;
    private threadId: string;
    private cardId: string;

    cardStatus: CardStatus;
    role: Role;
    card$: Observable<IThreadCard>;
    state: IVaultRequestCardState;
    thread$: Observable<IThread>;
    userId$: Observable<string>;
    isThreadActive$: Observable<boolean>;
    canRespondToRequest$: Observable<boolean>;
    cardStatuses = CardStatus;
    allowEdit = this.environment.featureFlags.editCardDescription;
    errorMessage: string;
    loader = new Loader();

    actionedPercentage = 0;
    numActionedRequestItems = 0;
    requestItemTextResponses = 0;
    requestItemUploadResponses = 0;

    state$: Observable<IVaultRequestCardState>;
    replies$: Observable<CardReply[]>;

    canEditCard$: Observable<boolean>;

    constructor(
        @Inject(THREAD_CARD_RESOURCES) protected cardResources: CardResources<IVaultRequestCardState>,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        @Inject(TASK_ACTION_LIBRARY) protected taskActions: ILibrary<TaskAction<boolean>>,
        private cardService: ThreadCardService,
        private authService: AuthService,
        private threadsService: ThreadsService,
        private permissionService: PermissionService,
        protected taskActionService: TaskActionService
    ) {
        super(cardResources, taskActionService);
    }

    async ngOnInit() {
        const { threadId, cardId, thread$, card$, state$, role, replies$ } = this.cardResources;

        this.userId$ = this.authService.getUser().pipe(
            filter(user => !!user),
            map(user => user.id)
        );

        this.role = role;
        this.thread$ = thread$;
        this.card$ = card$;
        this.threadId = threadId;
        this.cardId = cardId;
        this.replies$ = replies$;
        this.state$ = state$;
        this.stateSub = state$.subscribe(vaultState => this.updateState(vaultState));
        this.isThreadActive$ = thread$.pipe(switchMap(thread => this.threadsService.isThreadActive(thread)));

        if (!this.navigationSub) {
            this.navigationSub = this.cardResources.navigateTo$.subscribe(async () => await this.openRequestModal());
        }

        this.canRespondToRequest$ = combineLatest([
            state$,
            this.permissionService.checkPermissions(this.role, "RespondToRequestVaultCard"),
            card$
        ]).pipe(
            map(
                ([_state$, hasRespondPermission, card]) =>
                    hasRespondPermission &&
                    card.status !== this.cardStatuses.Disabled &&
                    this.actionedPercentage !== 100
            )
        );

        this.canEditCard$ = combineLatest([
            state$,
            card$,
            this.permissionService.checkPermissions(this.role, "UpdateRequestOrVaultCard"),
            this.permissionService.checkPermissions(this.role, "ThreadUpdateAll"),
            this.userId$
        ]).pipe(
            map(([_state$, card, hasUpdateRFIPermission, hasThreadUpdateAllPermission, currentUserId]) => {
                if (card.status === CardStatus.Disabled) {
                    return false;
                }
                if (hasThreadUpdateAllPermission || (card.createdBy === currentUserId && hasUpdateRFIPermission)) {
                    return true;
                }
                return false;
            })
        );
    }

    ngOnDestroy() {
        if (this.stateSub) {
            this.stateSub.unsubscribe();
        }
        if (this.navigationSub) {
            this.navigationSub.unsubscribe();
        }
    }

    async deleteCard() {
        try {
            this.loader.show();
            await this.cardService.deleteCard(this.threadId, this.cardId).toPromise();
        } catch {
            this.errorMessage = "Sorry, something went wrong";
        } finally {
            this.loader.hide();
        }
    }

    async openRequestModal() {
        const threadIsActive = await this.isThreadActive$.pipe(take(1)).toPromise();
        if (!threadIsActive) return;

        const canRespondToRequest = await this.canRespondToRequest$.pipe(take(1)).toPromise();
        if (canRespondToRequest) {
            this.action(this.VAULT_ACTION_REQUEST_RESPONSE);
        } else {
            this.action(this.VAULT_ACTION_VIEW_RESPONSE);
        }
    }

    async actionCallback(actionId: string, stateUpdated: boolean) {
        if (
            (actionId === this.VAULT_ACTION_EDIT_RESPONSE || actionId === this.VAULT_ACTION_REQUEST_RESPONSE) &&
            stateUpdated
        ) {
            this.loader.show();
            const stateResponse = await this.cardService
                .getCardState<IVaultRequestCardState>(this.threadId, this.cardId)
                .toPromise();
            await this.updateState(stateResponse.state);
            this.loader.hide();
        }
    }

    private async updateState(state: IVaultRequestCardState) {
        if (state) {
            this.state = state;
            const progress = VaultRequestService.calculateProgress(this.state.requestItems);
            this.numActionedRequestItems = progress.numActionedRequestItems;
            this.actionedPercentage = progress.actionedPercentage;
            this.requestItemTextResponses = progress.requestItemTextResponses;
            this.requestItemUploadResponses = progress.requestItemUploadResponses;
        }
    }
}
