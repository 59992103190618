<centered-modal-layout
    (save)="completeRfi()"
    (close)="close()"
    [loading]="!!loader.counter"
    cancelButtonTitle="No"
    saveButtonTitle="Yes"
    [analyticsPrefix]="ANALYTICS_PREFIX"
>
    <div modal-header>Complete request for information</div>
    <div modal-subhead>
        Are you sure you want to complete this request? If yes, you won't be able to add further information
    </div>
</centered-modal-layout>
