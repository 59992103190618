import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { PreviewAction, VaultService, VAULT_ACTION } from "@findex/vault";
import { IVaultFile } from "@findex/vault-core";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

enum SUPPORTED_TYPES {
    PDF = "application/pdf",
    HTML = "text/html"
}

@Component({
    selector: "file-preview",
    templateUrl: "./file-preview.component.html",
    styleUrls: ["./file-preview.component.scss"]
})
export class FilePreviewComponent implements OnChanges {
    @Input() vaultId: string;
    @Input() file: IVaultFile;

    supportedTypes = SUPPORTED_TYPES;
    previewType: string;
    previewData$: Observable<string>;
    loader = new Loader();

    constructor(private vaultService: VaultService) {}

    ngOnChanges(changes: SimpleChanges) {
        const { file, vaultId } = changes;

        if (file || vaultId) {
            this.previewData$ = this.getPreviewData(this.vaultId, this.file);
        }
    }

    private getPreviewData(vaultId: string, file: IVaultFile): Observable<string> {
        if (!vaultId || !file) return null;

        const action: PreviewAction = file.actions[VAULT_ACTION.Preview]?.metadata;

        if (!action) {
            console.warn("No preview action on file", vaultId, file.fileId);
            return null;
        }

        this.previewType = action.type;

        return this.loader.wrap(
            this.vaultService
                .getPreview(vaultId, file.fileId, action)
                .pipe(map(data => this.blobToUrl(data, action.type)))
        );
    }

    private blobToUrl(blob: Blob | string, contentTypeOverride?: string): string {
        if (typeof blob === "string") return blob;

        const previewBlob = contentTypeOverride ? this.overrideContentType(blob, contentTypeOverride) : blob;
        return URL.createObjectURL(previewBlob);
    }

    private overrideContentType(blob: Blob, contentType: string): Blob {
        return blob.slice(0, blob.size, contentType);
    }
}
