<div class="book-meeting">
    <loader-icon
        [color]="'light'"
        *ngIf="!staffProfile || !(activeAvailability$ | async) || (loading$ | async)"
    ></loader-icon>
    <ng-container *ngIf="staffProfile && !(loading$ | async) && (activeAvailability$ | async) as availability">
        <h3 class="book-meeting-header">
            Select an available date and time
        </h3>
        <div class="book-meeting-expectation">
            <span>What should you expect from your first online meeting? </span>
            <a
                [href]="onboardingVideoUrl"
                (click)="openVideoModal($event)"
                [analyticsClick]="analyticsPrefix + '_video'"
            >
                <i class="las la-play-circle"></i>Watch the video
            </a>
        </div>
        <div class="slot-picker">
            <app-calendar
                *ngIf="availability && minDate"
                class="fx-calendar--two-columns fx-calendar--desktop-no-collapse"
                [slots]="availability.slots"
                [minDate]="minDate"
                [maxDate]="maxDate"
                [showDatePreview]="true"
                [options]="{ timepicker: { style: 'buttons', inputName: 'time-view-all' } }"
                (dateTimeSelected)="handleCalendarEvent($event)"
                (monthChange)="monthChange($event)"
            >
            </app-calendar>
        </div>
    </ng-container>
</div>
