import { ModalsModule } from './../threads-ui/modules/modals/modals.module';
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FindexUiModule } from "../findex-ui/findex-ui.module";
import { AnalyticsModule } from "../analytics/analytics.module";
import { ConfirmModalComponent } from "./components/confirm-modal/confirm-modal.component";
import { ErrorComponent } from "./components/error/error.component";
import { ErrorModalComponent } from "./components/error/error-modal/error-modal.component";
import { MatDialogModule } from "@angular/material/dialog";
import { ErrorService } from "./services/error.service";
import { QuillEditorService } from "./services/quill-editor.service";
import { PaginationComponent } from "./components/pagination/pagination.component";
import { MouseWheelDirective } from "./directives/mouseheel.directive";
import { VerifyMobileFormComponent } from "./components/verify-mobile-form/verify-mobile-form.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SharedPipesModule } from "./pipes/shared-pipes.module";
import { UnsavedConfirmModalComponent } from './components/unsaved-confirm-modal/unsaved-confirm-modal.component';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        FindexUiModule,
        MatDialogModule,
        ReactiveFormsModule,
        AnalyticsModule,
        SharedPipesModule,
        ModalsModule,
    ],
    declarations: [
        ConfirmModalComponent,
        ErrorComponent,
        ErrorModalComponent,
        PaginationComponent,
        VerifyMobileFormComponent,
        MouseWheelDirective,
        VerifyMobileFormComponent,
        UnsavedConfirmModalComponent,
    ],
    providers: [ErrorService, QuillEditorService],
    exports: [SharedPipesModule, PaginationComponent, MouseWheelDirective, VerifyMobileFormComponent, AnalyticsModule]
})
export class SharedModule {}
