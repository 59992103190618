import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { VideoChatAction } from "@findex/threads";
import { VcStateBuilder } from "projects/default-plugins/video-chat/services/vc-state-builder";
import { ILibrary, TaskAction } from "projects/portal-modules/src/lib/plugins";
import { ActionableCardComponent } from "projects/portal-modules/src/lib/shared/components/actionable-card/actionable-card";
import { TaskActionService } from "projects/portal-modules/src/lib/shared/components/actionable-card/task-action.service";
import { BANNER_LABEL, CardResources, THREAD_CARD_RESOURCES } from "projects/portal-modules/src/lib/threads-ui/interfaces/IUiCard";
import { Subscription } from "rxjs";
import { TASK_ACTION_LIBRARY } from "src/app/injection-token";

@Component({
    selector: "app-calendar-meeting-started-banner",
    templateUrl: "./calendar-meeting-started-banner.component.html",
    styleUrls: ["./calendar-meeting-started-banner.component.scss"]
})
export class CalendarMeetingStartedBannerComponent extends ActionableCardComponent<any> implements OnInit, OnDestroy {
    private eventSub: Subscription;
    private modelBuilder = new VcStateBuilder();
    vcDetails$ = this.modelBuilder.getState();
    label: string;

    constructor(
        @Inject(THREAD_CARD_RESOURCES) public cardResources: CardResources,
        @Inject(TASK_ACTION_LIBRARY) public taskActions: ILibrary<TaskAction<any>>,
        @Inject(BANNER_LABEL) label: string,
        protected taskActionService: TaskActionService
    ) {
        super(cardResources, taskActionService);
        this.label = label;
    }

    ngOnInit(): void {
        const { threadId, cardId, eventService } = this.cardResources;
        this.eventSub = eventService.events.subscribe(event => {
            this.modelBuilder.addEvent(event);
        });
        this.modelBuilder.setThreadAndState(threadId, cardId);
    }

    joinCall() {
        this.action(VideoChatAction.JOIN_CALL)
    }

    terminateSession() {
        this.action(VideoChatAction.END_SESSION);
    }

    async actionCallback(actionId: string) {
        if (actionId === VideoChatAction.JOIN_CALL) {
            this.terminateSession();
        }
    }

    ngOnDestroy() {
        this.eventSub.unsubscribe();
    }
}
