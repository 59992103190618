import { AfterViewInit, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import Plotly from "plotly.js-dist";

@Component({
    selector: "box-chart",
    templateUrl: "./box-chart.component.html"
})
export class BoxChartComponent implements OnChanges, AfterViewInit {
    @ViewChild("chart", { static: true }) chart: ElementRef;

    @Input() labels: string[];
    @Input() data: number[];

    private plotly: Plotly;

    ngAfterViewInit(): void {
        const data = this.getData();
        this.plotly = Plotly.newPlot(this.chart.nativeElement, data, {});
    }

    ngOnChanges(_changes: SimpleChanges) {
        if (this.plotly) {
            const data = this.getData();
            this.plotly = Plotly.react(this.chart.nativeElement, data, {});
        }
    }


    private getData() {
        const plotData = {
            x: this.data || [],
            type: 'box',
            name: ''
        }

        return [plotData];
    }
}