import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SyncFilesModalComponent } from "./components/sync-files-modal/sync-files-modal.component";
import { ClientDetailsFormComponent } from "./components/client-details-form/client-details-form.component";
import { ThreadsUiModule } from "projects/portal-modules/src/lib/threads-ui";
import { FindexUiModule } from "projects/portal-modules/src/lib/findex-ui";
import { SelectStaffComponent } from "./components/select-staff/select-staff.component";
import { SharedModule } from "../../../projects/portal-modules/src/lib/shared";
import { FindAvailabilityModalComponent } from "./components/find-availability-modal/find-availability-modal.component";
import { SamedayUserProfileExtensionComponent } from "./components/sameday-user-profile-extension/sameday-user-profile-extension.component";
import { SamedayProfileFormComponent } from "./components/sameday-profile-form/sameday-profile-form.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { OnboardingProfileComponent } from "./components/onboarding-profile/onboarding-profile.component";
import { ClientProfileCardComponent } from "./components/client-profile-card/client-profile-card.component";
import { RouterModule } from "@angular/router";
import { SameDayTaxPlugin } from "./same-day-tax.plugin";

@NgModule({
    imports: [
        CommonModule,
        ThreadsUiModule,
        FindexUiModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule
    ],
    declarations: [
        SyncFilesModalComponent,
        ClientDetailsFormComponent,
        ClientProfileCardComponent,
        SelectStaffComponent,
        FindAvailabilityModalComponent,
        SamedayUserProfileExtensionComponent,
        SamedayProfileFormComponent,
        OnboardingProfileComponent
    ],
    providers: [SameDayTaxPlugin],
    exports: [SamedayProfileFormComponent]
})
export class SameDayTaxModule {}
