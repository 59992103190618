import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { VaultService } from "@findex/vault";
import { GA_EVENTS_PREFIX } from "projects/portal-modules/src/lib/analytics";

export type CompleteRfiData = { vaultId: string };

@Component({
    selector: "complete-rfi",
    templateUrl: "./complete-rfi-modal.component.html",
    styleUrls: ["./complete-rfi-modal.component.scss"]
})
export class CompleteRfiModalComponent {
    readonly ANALYTICS_PREFIX = GA_EVENTS_PREFIX.RFI_CONFIRMCOMPLETE;
    public loader = new Loader();
    vaultId: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) data: CompleteRfiData,
        private dialogRef: MatDialogRef<CompleteRfiModalComponent>,
        private vaultService: VaultService
    ) {
        this.vaultId = data?.vaultId;
    }

    async completeRfi() {
        if (this.vaultId) {
            this.loader.show();
            await this.vaultService.closeVault(this.vaultId).toPromise();
            this.loader.hide();
        }
        this.dialogRef.close(true);
    }

    close() {
        this.dialogRef.close(false);
    }
}
