import { ComponentType } from "@angular/cdk/portal";
import { Component, Injector, Input, Output, EventEmitter, OnChanges, SimpleChanges, Inject } from "@angular/core";
import { Notification, PossibleDeliveryData } from "@findex/notifications-angular";
import { IThread, IThreadCard } from "@findex/threads";
import { forkJoin, Observable, of } from "rxjs";
import { catchError, filter, map, take, tap } from "rxjs/operators";
import { INotification } from "src/app/app.component";
import { BANNER_LIBRARY } from "src/app/injection-token";
import { AppUser, AuthService } from "../../../findex-auth";
import { BannerExtension, ILibrary } from "../../../plugins";

import { BANNER_LABEL, IUiCard, THREAD_CARD_RESOURCES } from "../../../threads-ui/interfaces/IUiCard";
import { ThreadCardService } from "../../../threads-ui/services/thread-card.service";
import { ThreadsService } from "../../../threads-ui/services/threads.service";
import { UiCardService } from "../../../threads-ui/services/ui-card.service";

interface IBanner {
    componentRef: ComponentType<any>;
    injector: Injector;
    uiCard: IUiCard<any, any>;
    notification: Notification<PossibleDeliveryData>;
}

@Component({
    selector: "notification-banner",
    templateUrl: "./notification-banner.component.html",
    styleUrls: ["./notification-banner.component.scss"]
})
export class NotificationBannerComponent implements OnChanges {
    @Input() notification: INotification;
    @Output() displayed = new EventEmitter<void>();
    @Output() close = new EventEmitter<string>();

    banner$: Observable<IBanner|void>;

    constructor(
        @Inject(BANNER_LIBRARY) private bannerLibrary: ILibrary<BannerExtension>,
        private injector: Injector,
        private threadService: ThreadsService,
        private threadCardService: ThreadCardService,
        private uiCardService: UiCardService,
        private authService: AuthService,
    ) {

    }

    ngOnChanges(changes: SimpleChanges): void {
        const { notification } = changes;

        if (notification && notification.currentValue) {
            this.banner$ = this.getBanner(notification.currentValue);
        }
    }

    private handleError(notification: INotification, err: Error): Observable<void> {
        console.log('Failed to load banner for notification', notification, err);
        this.close.next(notification.channel);
        return of(null);
    }

    private getBanner(notification: Notification<any>): Observable<IBanner|void> {
        const { threadId, cardId } = notification?.deliveryData?.metadata;
        if (!threadId || !cardId) return null;

        const thread$ = this.threadService.getThread(threadId);
        const card$ = this.threadCardService.getCard(threadId, cardId);

        const user$ = this.authService.getUser().pipe(
            filter(user => !!user),
            take(1)
        );

        return forkJoin([thread$, card$, user$]).pipe(
            map(([thread, card, user]) => this.getPluginResources(thread, card, user, notification)),
            tap(() => this.displayed.emit()),
            catchError(err => this.handleError(notification, err))
        );
    }

    private getPluginResources(thread: IThread, card: IThreadCard, user: AppUser, notification: Notification<any>): IBanner {
        const currentParticipant = thread.participants.find(participant => participant.id === user.id);
        const role = currentParticipant?.role || user.globalRole;

        const uiCard = this.uiCardService.mapCard(thread, card, role);

        const injector = Injector.create({
            parent: this.injector,
            providers: [
                { provide: THREAD_CARD_RESOURCES, useValue: uiCard },
                { provide: BANNER_LABEL, useValue: notification.label }
            ]
        });

        const { type } = notification?.deliveryData?.metadata;
        const component = this.bannerLibrary.resolve(type);
        if (!component) return undefined;

        return {
            componentRef: component.componentRef,
            injector,
            uiCard,
            notification
        };
    }
}
