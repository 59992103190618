import { Component, Inject, OnDestroy } from "@angular/core";
import {
    CardResources,
    IEventService,
    THREAD_CARD_RESOURCES
} from "projects/portal-modules/src/lib/threads-ui/interfaces/IUiCard";
import { CardReply, ICardEvent, IThread, IThreadCard, Role } from "@findex/threads";
import { combineLatest, Observable, Subscription } from "rxjs";
import { filter, map, scan, switchMap } from "rxjs/operators";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { customValidators } from "projects/portal-modules/src/lib/shared/validators";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { EnvironmentSpecificConfig } from "../../../../../projects/portal-modules/src/lib/environment/environment.common";
import { PrivateProfileService } from "../../services/private-profile.service";
import { ENVIRONMENT } from "src/app/injection-token";

interface PrivateUserProfileDetails {
    isReturningCustomer: boolean;
    dateOfBirth: string;
    taxFileNumber: string;
}

@Component({
    selector: "client-profile-card",
    templateUrl: "./client-profile-card.component.html",
    styleUrls: ["./client-profile-card.component.scss"]
})
export class ClientProfileCardComponent implements OnDestroy {
    readonly theme = this.environment.theme;
    readonly roles = Role;

    card$: Observable<IThreadCard>;
    thread$: Observable<IThread>;
    eventsSubscription: Subscription;
    role: Role;
    userId: Observable<string>;
    replies$: Observable<CardReply[]>;

    completed = false;
    saveDisabled = false;

    isReturningCustomer = true;

    form = new FormGroup({
        dateOfBirth: new FormControl(null, [Validators.required, customValidators.dateValidator("dd/MM/yyyy")]),
        taxFileNumber: new FormControl(null, [Validators.required, customValidators.taxFileNumberValidator])
    });

    loader = new Loader();

    constructor(
        @Inject(THREAD_CARD_RESOURCES) resources: CardResources,
        authService: AuthService,
        private privateProfileService: PrivateProfileService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig
    ) {
        const { eventService, thread$, card$, role, replies$: replies } = resources;

        this.thread$ = thread$;
        this.card$ = card$;
        this.role = role;
        this.replies$ = replies;
        this.eventsSubscription = eventService.events.pipe(scan(this.insertEvent, [])).subscribe();
        this.userId = authService.getUser().pipe(
            filter(user => !!user),
            map(user => user.id)
        );

        this.loadAllEvents(eventService);
    }

    ngOnDestroy(): void {
        if (this.eventsSubscription) {
            this.eventsSubscription.unsubscribe();
        }
    }

    insertEvent = (allEvents: ICardEvent[], event: ICardEvent): ICardEvent[] => {
        if (event.type === "complete-form") {
            this.completed = true;
        }
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const existing = allEvents.find(event => event.id === event.id);
        if (existing) {
            return allEvents;
        }

        allEvents.unshift(event);
        return allEvents;
    };

    private async loadAllEvents(eventService: IEventService) {
        const hasMore = await eventService.loadHistorical();

        if (hasMore) {
            await this.loadAllEvents(eventService);
        }
    }

    saveProfile() {
        if (this.completed) {
            return;
        }
        const { dateOfBirth, taxFileNumber } = this.form.value;
        const details: PrivateUserProfileDetails = {
            isReturningCustomer: this.isReturningCustomer,
            dateOfBirth,
            taxFileNumber
        };
        this.saveDisabled = true;
        this.loader
            .wrap(
                combineLatest([this.thread$, this.card$]).pipe(
                    switchMap(([thread, card]) =>
                        this.privateProfileService.updateCompleteProfileCard<PrivateUserProfileDetails>(
                            thread.id,
                            card.id,
                            details
                        )
                    )
                )
            )
            .subscribe(() => {});
    }

    shouldDisableSave() {
        return this.saveDisabled || (!this.isReturningCustomer && !this.form.valid);
    }
}
