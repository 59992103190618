import { Pipe, PipeTransform } from "@angular/core";
import { Observable } from "rxjs";
import { ThreadGrouper } from "../components/thread-list/thread-grouper";

@Pipe({ name: "threadTypeName" })
export class ThreadTypeNamePipe implements PipeTransform {
    constructor(private threadGrouper: ThreadGrouper) {}
    transform(type: string): Observable<string> {
        return this.threadGrouper.getDisplayName(type);
    }
}
