<div class="billing-history">
    <h2 class="billing-history-header">
        Billing history
    </h2>
    <div *ngIf="invoices$ | async; let invoices; else: loading" [ngSwitch]="invoices?.length > 0">
        <ng-container *ngSwitchDefault>
            <div>
                Sorry, you have no billing history.
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="true">
            <div class="billing-history-table">
                <div class="billing-history-table-row billing-history-table-row--header">
                    <div class="billing-history-table-column">
                        {{ tableHeaders.InvoiceNo }}
                    </div>
                    <div class="billing-history-table-column">
                        {{ tableHeaders.Item }}
                    </div>
                    <div class="billing-history-table-column">
                        {{ tableHeaders.Date }}
                    </div>
                    <div class="billing-history-table-column">
                        {{ tableHeaders.BillingPeriod }}
                    </div>
                    <div class="billing-history-table-column">
                        {{ tableHeaders.Status }}
                    </div>
                    <div class="billing-history-table-column">
                        {{ tableHeaders.Amount }}
                    </div>
                    <div class="billing-history-table-column">
                        {{ tableHeaders.Receipt }}
                    </div>
                </div>
                <div class="billing-history-table-row" *ngFor="let invoice of invoices">
                    <div class="billing-history-table-column">
                        <div class="billing-history-table-column-label">
                            {{ tableHeaders.InvoiceNo }}
                        </div>
                        {{ invoice.number }}
                    </div>
                    <div class="billing-history-table-column">
                        <ng-container *ngFor="let plan of invoice.lines.data">
                            <div class="billing-history-table-column-label">
                                {{ tableHeaders.Item }}
                            </div>
                            {{ plan?.plan?.nickname ? plan.plan.nickname : "Payment" }}
                        </ng-container>
                    </div>
                    <div class="billing-history-table-column">
                        <div class="billing-history-table-column-label">
                            {{ tableHeaders.Date }}
                        </div>
                        {{ invoice.created * 1000 | date: "dd MMMM yyyy" }}
                    </div>
                    <div class="billing-history-table-column">
                        <div class="billing-history-table-column-label">
                            {{ tableHeaders.BillingPeriod }}
                        </div>
                        <ng-container *ngFor="let plan of invoice.lines.data">
                            {{
                                (plan.price.type === "recurring" ? plan.price.recurring.interval + "ly" : "One off")
                                    | titlecase
                            }}
                        </ng-container>
                    </div>
                    <div class="billing-history-table-column">
                        <div class="billing-history-table-column-label">
                            {{ tableHeaders.Status }}
                        </div>
                        <span [ngClass]="{ paid: invoice.paid }">{{ invoice.status | titlecase }}</span>
                    </div>
                    <div class="billing-history-table-column">
                        <div class="billing-history-table-column-label">
                            {{ tableHeaders.Amount }}
                        </div>
                        {{ invoice.amount_due / 100 | currency: (invoice.currency | uppercase) }}
                    </div>
                    <div class="billing-history-table-column">
                        <div class="billing-history-table-column-label">
                            {{ tableHeaders.Receipt }}
                        </div>
                        <ng-container *ngIf="invoice.hosted_invoice_url">
                            <i class="las la-file billing-history-invoice-icon"></i>
                            <a
                                class="billing-history-invoice-link"
                                href="{{ invoice.hosted_invoice_url }}"
                                target="_blank"
                                rel="noopener noreferrer"
                                [analyticsClick]="gaEvents.BILLINGHISTORY_VIEWINVOICE"
                            >
                                View
                            </a>
                        </ng-container>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <ng-template #loading>
        <div *ngIf="errorMessage">
            <div>{{ errorMessage }}</div>
        </div>
        <div *ngIf="!errorMessage">
            <loader-icon [static]="true" color="light"></loader-icon>
        </div>
    </ng-template>
</div>
