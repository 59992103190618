<div class="payment-modal" *ngIf="cardState && packagePriceDetails">
    <div class="payment-modal-inner">
        <div class="payment-modal-inner-content">
            <div class="payment-modal-close">
                <div class="fx-l-modal-header-icon" (click)="close()" analyticsClick="payment_closemodal">
                    <i class="las la-times"></i>
                </div>
            </div>
            <div class="payment-modal-package-details">
                <div class="payment-modal-package-details-name">
                    <div class="payment-modal-package-details-price-dollar">{{ cardState.packageName }}</div>
                </div>
                <div class="payment-modal-package-details-price">
                    <div class="payment-modal-package-details-price-figures">
                        <div>{{ 0 | currency: (cardState?.currency | uppercase) | slice: 0:-4 }}</div>
                        <div class="payment-modal-package-details-price-dollar">
                            {{ packagePriceDetails.priceDollars }}
                        </div>
                        <div class="payment-modal-package-details-price-decimal">
                            <div class="payment-modal-package-details-price-decimal-value">
                                {{ packagePriceDetails.priceCents }}
                            </div>
                            <div *ngIf="cardState.isRecurring" class="payment-modal-package-details-price-decimal-text">
                                /MONTH
                            </div>
                        </div>
                    </div>
                    <div class="payment-modal-added-details-period">
                        <ng-container *ngIf="cardState.isRecurring">
                            <i class="las la-sync-alt payment-modal-added-details-period-icon"></i>
                            <div>This is a recurring direct debit</div>
                        </ng-container>
                        <div *ngIf="!cardState.isRecurring">This is a once-off payment</div>
                    </div>
                </div>
            </div>
            <div class="payment-modal-added-details">
                <div class="payment-modal-added-details-card-icon">
                    <div
                        class="payment-modal-added-details-card-icon-text"
                        *ngIf="cardState.isRecurring && paymentType !== paymentTypes.ScheduledPayment"
                    >
                        {{
                            (0 | currency: (cardState?.currency | uppercase) | slice: 0:-4) +
                                packagePriceDetails.priceDollars
                        }}.{{ packagePriceDetails.priceCents }} will be billed on the
                        {{ billingDate.getDate() | formatOrdinalDate }} of each month
                    </div>
                    <div
                        class="payment-modal-added-details-card-icon-text"
                        *ngIf="cardState.isRecurring && paymentType === paymentTypes.ScheduledPayment"
                    >
                        {{
                            (0 | currency: (cardState?.currency | uppercase) | slice: 0:-4) +
                                packagePriceDetails.priceDollars
                        }}.{{ packagePriceDetails.priceCents }} will be billed on the
                        {{ billingDate.getDate() | formatOrdinalDate }} of each month beginning on
                        {{ billingDate | date: "MMMM d y" }}
                    </div>
                    <div class="payment-modal-added-details-card-icon-text">
                        We accept all major credit and debit cards
                    </div>
                    <div class="payment-modal-added-details-card-icon-text">
                        <i class="lab la-cc-visa payment-modal-added-details-card-icon-picture"></i>
                        <i class="lab la-cc-mastercard payment-modal-added-details-card-icon-picture"></i>
                        <i class="lab la-cc-amex payment-modal-added-details-card-icon-picture"></i>
                    </div>
                </div>
            </div>
            <div class="payment-modal-card-details">
                <form [formGroup]="form">
                    <div class="payment-modal-card-details-row">
                        <div class="payment-modal-card-details-row-multi-column">
                            <label class="fx-form-label" for="name">Full Name</label>
                            <div class="fx-form-control">
                                <input
                                    class="fx-form-input"
                                    formControlName="name"
                                    placeholder="John Smith"
                                    [ngClass]="{
                                        'fx-form-input--error':
                                            !form.controls['name'].valid && form.controls['name'].touched
                                    }"
                                />
                            </div>
                            <div
                                class="fx-form-validation brand-danger"
                                *ngIf="form.controls['name'].hasError('required') && form.controls['name'].touched"
                            >
                                Name is required.
                                <i class="fx-form-validation-icon las la-exclamation-triangle brand-danger"></i>
                            </div>
                        </div>
                        <div class="payment-modal-card-details-row-multi-column">
                            <label class="fx-form-label" for="address">Address</label>
                            <div class="fx-form-control">
                                <input
                                    class="fx-form-input"
                                    formControlName="address"
                                    placeholder="300/171 Williams St"
                                    [ngClass]="{
                                        'fx-form-input--error':
                                            !form.controls['address'].valid && form.controls['address'].touched
                                    }"
                                />
                            </div>
                            <div
                                class="fx-form-validation brand-danger"
                                *ngIf="
                                    form.controls['address'].hasError('required') && form.controls['address'].touched
                                "
                            >
                                Address is required.
                                <i class="fx-form-validation-icon las la-exclamation-triangle brand-danger"></i>
                            </div>
                        </div>
                    </div>
                    <div class="payment-modal-card-details-row">
                        <div class="payment-modal-card-details-row-multi-column">
                            <label class="fx-form-label" for="city">City</label>
                            <div class="fx-form-control">
                                <input
                                    class="fx-form-input"
                                    formControlName="city"
                                    placeholder="Melbourne"
                                    [ngClass]="{
                                        'fx-form-input--error':
                                            !form.controls['city'].valid && form.controls['city'].touched
                                    }"
                                />
                            </div>
                            <div
                                class="fx-form-validation brand-danger"
                                *ngIf="form.controls['city'].hasError('required') && form.controls['city'].touched"
                            >
                                City is required.
                                <i class="fx-form-validation-icon las la-exclamation-triangle brand-danger"></i>
                            </div>
                        </div>
                        <div
                            class="payment-modal-card-details-row-multi-column payment-modal-card-details-row-multi-column--column-wrap"
                        >
                            <div class="payment-modal-card-details-postcode">
                                <label class="fx-form-label" for="postcode">Postcode</label>
                                <div class="fx-form-control">
                                    <input
                                        class="fx-form-input"
                                        formControlName="postcode"
                                        placeholder="3000"
                                        [ngClass]="{
                                            'fx-form-input--error':
                                                !form.controls['postcode'].valid && form.controls['postcode'].touched
                                        }"
                                    />
                                </div>
                                <div
                                    class="fx-form-validation brand-danger"
                                    *ngIf="
                                        form.controls['postcode'].hasError('required') &&
                                        form.controls['postcode'].touched
                                    "
                                >
                                    Postcode is required.
                                    <i class="fx-form-validation-icon las la-exclamation-triangle brand-danger"></i>
                                </div>
                            </div>
                            <div class="payment-modal-card-details-postcode">
                                <label class="fx-form-label" for="state">State</label>
                                <div class="fx-form-control">
                                    <input
                                        class="fx-form-input"
                                        formControlName="state"
                                        placeholder="Victoria"
                                        [ngClass]="{
                                            'fx-form-input--error':
                                                !form.controls['state'].valid && form.controls['state'].touched
                                        }"
                                    />
                                </div>
                                <div
                                    class="fx-form-validation brand-danger"
                                    *ngIf="
                                        form.controls['state'].hasError('required') && form.controls['state'].touched
                                    "
                                >
                                    State is required.
                                    <i class="fx-form-validation-icon las la-exclamation-triangle brand-danger"></i>
                                </div>
                            </div>
                            <div class="payment-modal-card-details-country">
                                <label class="fx-form-label" for="country">Country</label>
                                <div class="fx-form-control">
                                    <select
                                        class="fx-form-select"
                                        formControlName="country"
                                        [ngClass]="{
                                            'fx-form-input--error':
                                                !form.controls['country'].valid && form.controls['country'].touched
                                        }"
                                    >
                                        <option value="" [selected]="true" [disabled]="true">Select</option>
                                        <ng-container *ngFor="let country of acceptedCountries">
                                            <option [ngValue]="country.countryCode">{{ country.name }}</option>
                                        </ng-container>
                                    </select>
                                </div>
                                <div
                                    class="fx-form-validation brand-danger"
                                    *ngIf="
                                        form.controls['country'].hasError('required') &&
                                        form.controls['country'].touched
                                    "
                                >
                                    Country is required.
                                    <i class="fx-form-validation-icon las la-exclamation-triangle brand-danger"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="payment-modal-card-details-row">
                        <div class="payment-modal-card-details-row-multi-column">
                            <div class="fx-form-label">Card Number</div>
                            <div class="fx-form-input">
                                <div id="card-number"></div>
                            </div>
                        </div>
                        <div
                            class="payment-modal-card-details-row-multi-column payment-modal-card-details-row-multi-column--column-wrap"
                        >
                            <div class="payment-modal-card-details-expiry">
                                <div class="fx-form-label">Expiry</div>
                                <div class="fx-form-input">
                                    <div id="card-expiry"></div>
                                </div>
                            </div>
                            <div class="payment-modal-card-details-cvc">
                                <div class="fx-form-label">CVC</div>
                                <div class="fx-form-input">
                                    <div id="card-cvc"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="payment-modal-pay-now">
                <button
                    class="fx-btn fx-btn--primary-outlined payment-details-buttons-pay-now"
                    role="button"
                    (click)="actionPaymentCard()"
                    analyticsClick="payment_completepayment"
                >
                    <ng-container *ngIf="paymentType !== paymentTypes.ScheduledPayment">
                        PAY NOW
                    </ng-container>
                    <ng-container *ngIf="paymentType === paymentTypes.ScheduledPayment">
                        ADD PAYMENT METHOD
                    </ng-container>
                </button>
            </div>
            <loader-icon *ngIf="loader.counter" color="light" [showBackground]="true"></loader-icon>
            <div class="thread-card-error" *ngIf="errorMessage">{{ errorMessage }}</div>
            <div class="thread-card-error" *ngIf="errorState.cardNumber.message">
                {{ errorState.cardNumber.message }}
            </div>
            <div class="thread-card-error" *ngIf="errorState.expiryDate.message">
                {{ errorState.expiryDate.message }}
            </div>
            <div class="thread-card-error" *ngIf="errorState.cvcNumber.message">
                {{ errorState.cvcNumber.message }}
            </div>
        </div>
    </div>
</div>
