import { EnvironmentSpecificConfig } from "../../projects/portal-modules/src/lib/environment/environment.common";
import { IThemeInfo } from "../../projects/portal-modules/src/lib/shared/services/theme.service";

export const THEMES: { [key: string]: IThemeInfo } = {
    findex: {
        assetDirectory: "findex",
        title: "Findex",
        message: "TBD",
        tileHexColor: "#034650"
    },
    sigma: {
        assetDirectory: "sigma",
        title: "Visory",
        message: "Good people, Better Bookkeepers",
        tileHexColor: "#FFFFFF"
    },
    sameday: {
        assetDirectory: "sameday",
        title: "Same Day Tax Refunds",
        message: "Same Day Tax Refunds",
        tileHexColor: "#FFFFFF"
    }
};

export const themeFactory = (providedEnvironment: EnvironmentSpecificConfig) => THEMES[providedEnvironment.appTheme];
