import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { AnalyticsService, HOT_JAR_EVENTS } from "projects/portal-modules/src/lib/analytics";
@Component({
    selector: "vc-end-session-modal",
    templateUrl: "./vc-end-session-modal.component.html",
    styleUrls: ["./vc-end-session-modal.component.scss"]
})
export class VcEndSessionModalComponent {
    constructor(
        private dialogRef: MatDialogRef<VcEndSessionModalComponent, boolean>,
        private analytics: AnalyticsService,) { }

    endSession() {
        this.analytics.recordEvent("mouse-click", HOT_JAR_EVENTS.MeetingSessionEndCompleteEvent);
        this.dialogRef.close(true);
    }

    close() {
        this.dialogRef.close();
    }
}
