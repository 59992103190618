import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { IPaymentCardState } from "../../interfaces/IPaymentCardState";
import { IPackagePriceDetails } from "../../index";
import { CardStatus, IThreadCard, PaymentAction, Role } from "@findex/threads";
import { PaymentTypes } from "@findex/payments-service-sdk";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";

@Component({
    selector: "payment-details",
    templateUrl: "./payment-details.component.html",
    styleUrls: ["./payment-details.component.scss"]
})
export class PaymentDetailsComponent implements OnChanges {
    readonly gaEvents = GA_EVENTS;
    readonly paymentTypes = PaymentTypes;
    readonly ADD_PAYMENT_METHOD_TASK_ACTION = PaymentAction.PAYMENT_METHOD_REQUIRED;
    readonly PAY_NOW_TASK_ACTION = PaymentAction.PAYMENT_REQUIRED;

    @Input() packagePriceDetails: IPackagePriceDetails;
    @Input() cardState: IPaymentCardState;
    @Input() accountId: string;
    @Input() role: Role;
    @Input() card: IThreadCard;
    @Output() makePayment = new EventEmitter<string>();

    roles = Role;
    cardStatuses = CardStatus;

    constructor() {}
    ngOnChanges(changes: SimpleChanges) {
        const { cardState, packagePriceDetails } = changes;
        if (cardState && cardState.currentValue) {
            this.cardState = cardState.currentValue;
        }
        if (packagePriceDetails && packagePriceDetails.currentValue) {
            this.packagePriceDetails = packagePriceDetails.currentValue;
        }
    }
}
