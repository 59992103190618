import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Account, IThreadListing, Role, SubjectType } from "@findex/threads";
import { Observable, Subject, of, Subscription, merge } from "rxjs";
import { catchError, debounceTime, distinctUntilChanged, filter, map, shareReplay, switchMap, take, takeUntil } from "rxjs/operators";
import { PortalService } from "projects/portal-modules/src/lib/shared/services/portal.service";
import { AppUser, AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { ThreadGrouper } from "projects/portal-modules/src/lib/threads-ui/components/thread-list/thread-grouper";
import { HIDE_LIST_SIZE } from "projects/portal-modules/src/lib/threads-ui/components/thread-list/thread-list.component";
import { ThreadsWebsocketService } from "../../../shared/services/threads-websocket.service";
import { ThreadsService } from "../../services/threads.service";
import { EnvironmentSpecificConfig } from "../../../environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";
import { Loader } from "../../../shared/services/loader";
export interface IEnrichedThreadListing extends IThreadListing {
    account?: Account;
    unresolvedNotifications: number;
    updatedAt?: string;
}
@Component({
    selector: "threads-list",
    templateUrl: "./threads-list-route.component.html",
    styleUrls: ["./threads-list-route.component.scss"]
})
export class ThreadsListRouteComponent implements OnInit, OnDestroy {
    globalRole$: Observable<Role>;

    threadsList$: Observable<IEnrichedThreadListing[]>;
    showDisabledThread = true;
    userStatusFilter$: Observable<string>;
    loader = new Loader();

    private unbindPreviews = new Subject();
    private statusFilter$ = new Subject<string>();
    private rebuildList$ = new Subject<IEnrichedThreadListing[]>(); //not a fan, needs a cleanup. Something isn't right with the events we listen to, listening on dashboard for create events below
    private routeSub: Subscription;

    constructor(
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private portalService: PortalService,
        private router: Router,
        private authService: AuthService,
        private websocketService: ThreadsWebsocketService,
        private threadGrouper: ThreadGrouper,
        private threadsService: ThreadsService,
        public route: ActivatedRoute
    ) {}

    ngOnInit() {
        const currentUser$ = this.authService.getUser().pipe(
            filter(user => !!user),
            shareReplay(1)
        );

        this.globalRole$ = currentUser$.pipe(
            map(user => user.globalRole)
        );

        this.userStatusFilter$ = this.getStatusFilterFromUser(currentUser$, this.statusFilter$);
        this.threadsList$ = this.globalRole$.pipe(
            switchMap(() => this.getThreadsList(currentUser$, this.userStatusFilter$)),
            shareReplay(1)
        );

        this.routeSub = this.threadsList$.pipe(catchError(() => of([]))).subscribe(threads => {
            if (threads) {
                this.routeAndBind(threads);
            }
        });
    }
    
    async statusFilterUpdate(statusFilter: string): Promise<void> {
        this.statusFilter$.next(statusFilter);

        await this.authService.getUser()
            .pipe(
                filter(user => !!user),
                take(1),
                switchMap(user => this.threadsService.putUserData(user.id, { statusFilter }))
            )
            .toPromise();
    }

    selectThread(listing: IThreadListing) {
        this.router.navigate(["/timelines", listing.id]);
    }

    private getEnrichedTimelines(status$: Observable<string>): Observable<IEnrichedThreadListing[]> {
        return status$.pipe(
            switchMap(status => this.portalService.getThreadList({ status }))
        );
    }

    private getThreadsList(currentUser$: Observable<AppUser>, statusFilter$: Observable<string>): Observable<IEnrichedThreadListing[]> {
        const initialList$ = statusFilter$.pipe(
            switchMap(status => this.loader.wrap(this.portalService.getThreadList({ status }))),
            shareReplay(1)
        );

        const websocketList$ = this.getThreadsListUpdates(currentUser$, statusFilter$, initialList$);
        const updates$ = merge(websocketList$, this.rebuildList$);

        return merge(initialList$, updates$).pipe(
            shareReplay(1)
        );;
    }

    private mergeTimelineLists(
        existingListings: IEnrichedThreadListing[],
        newListings: IEnrichedThreadListing[]
    ): IEnrichedThreadListing[] {
        const newThreadListings = newListings.filter(
            newThreadListing =>
                !existingListings.find(existingThreadListing => existingThreadListing.id === newThreadListing.id)
        );
        return existingListings.concat(newThreadListings);
    }

    private getThreadsListUpdates(
        currentUser$: Observable<AppUser>,
        statusFilter$: Observable<string>,
        initialList: Observable<IEnrichedThreadListing[]>
    ): Observable<IEnrichedThreadListing[]> {
        return currentUser$.pipe(
            switchMap(() => this.portalService.getMyDashboard()),
            switchMap(dashboardId => this.websocketService.watchThreadId(dashboardId)),
            filter(notification => notification.subjectType === "card" && notification.eventType === "updated"),
            switchMap(() => this.getEnrichedTimelines(statusFilter$)),
            switchMap(newTimelineList =>
                initialList.pipe(
                    map(previousTimelineList => this.mergeTimelineLists(previousTimelineList, newTimelineList))
                )
            )
        );
    }

    private async routeAndBind(threads: IEnrichedThreadListing[]) {
        this.routeToFirst(threads);
        this.bindWebSocketSubscriptions(threads);
    }

    private routeToFirst(listings: IEnrichedThreadListing[]) {
        //Do not route if we are already on a thread
        if (this.route.firstChild && this.route.firstChild.snapshot.params.threadId) {
            return;
        }

        //Do not auto route on mobile
        if (window.innerWidth < HIDE_LIST_SIZE) {
            return;
        }

        if (listings && listings.length) {
            const sorted = listings.sort((a, b) => this.threadGrouper.orderThreads(a, b));
            this.selectThread(sorted[0]);
        }
    }

    private getStatusFilterFromUser(currentUser$: Observable<AppUser>, filterChanges$: Observable<string>): Observable<string> {
        const preferredFilter$ = currentUser$.pipe(
            switchMap(user => this.threadsService.getUserData(user.id)),
            map(userData => userData?.statusFilter || this.environment.featureFlags.threadListFilterStatus.active?.toLowerCase())
        );

        return merge(preferredFilter$, filterChanges$).pipe(
            distinctUntilChanged(),
            shareReplay(1)
        );
    }

    private bindWebSocketSubscriptions(threads: IEnrichedThreadListing[]) {
        this.unbindPreviews.next(null);
        for (const thread of threads) {
            this.websocketService
                .watchThreadId(thread.id)
                .pipe(
                    filter(notification => notification.subjectType === SubjectType.Thread),
                    debounceTime(500),
                    switchMap(notification => this.portalService.getThreadListById(notification.threadId)),
                    takeUntil(this.unbindPreviews)
                )
                .subscribe(updatedThread => {
                    thread.preview = updatedThread.preview;
                    thread.workflow = updatedThread.workflow;
                    thread.participants = updatedThread.participants;
                    thread.unresolvedNotifications = updatedThread.unresolvedNotifications;
                    this.rebuildList$.next([...threads]);
                });
        }
    }

    ngOnDestroy() {
        this.unbindPreviews.next(null);
        this.unbindPreviews.complete();
        if (this.routeSub) {
            this.routeSub.unsubscribe();
        }
    }
}
