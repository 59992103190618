<loader-icon *ngIf="loader.counter" color="light"></loader-icon>

<ng-container *ngIf="{ recordings: recordings$ | async, role: role$ | async, thread: thread$ | async }; let context">
    <div class="recordings">
        <ng-container *ngIf="context.recordings?.length && context.thread">
            <h4>
                <span class="recordings-heading">Recordings &mdash; </span>
                <span>{{ context.thread.title }}</span>
            </h4>
            <hr />
        </ng-container>

        <ng-template #noRecordings>
            <div class="recordings-empty" *ngIf="context.recordings?.length === 0">
                <span>There are no recordings in this timeline</span>
            </div>
        </ng-template>

        <div *ngIf="tableData.data.length > 0; else noRecordings">
            <fx-table
                [tableData]="tableData"
            >
                <ng-container
                    *fxColumn="
                        'cardDescription';
                        label: 'Meeting title';
                        sortable: true;
                        mobileCollapse: true;
                        mobileHideLabel: true;
                        let recording
                    "
                >
                    <div class="recording-col">
                        <div class="recording-col-file-container">
                            <span class="recording-col-file-container-title" (click)="download(recording)">{{
                                recording.cardDescription
                            }}</span>
                        </div>
                    </div>
                </ng-container>

                <ng-container
                    *fxColumn="
                        'duration';
                        label: 'Duration';
                        sortable: true;
                        mobileCollapse: true;
                        mobileHideLabel: true;
                        let recording
                    "
                >
                    <div class="recording-col">
                        <i class="las la-clock recording-duration-icon"></i>
                        <span> {{ recording.duration * 1000 | date: "mm:ss" }}</span>
                    </div>
                </ng-container>

                <ng-container
                    *fxColumn="
                        'attendees';
                        label: 'Attendees';
                        sortable: false;
                        mobileHideLabel: true;
                        mobileCollapse: false;
                        let recording
                    "
                >
                    <div class="recording-users">
                        <fx-avatar-multi
                            [content]="recording.avatars | async"
                        >
                        </fx-avatar-multi>
                    </div>
                </ng-container>

                <ng-container
                    *fxColumn="
                        'createdAt';
                        label: 'Date / Time';
                        sortable: true;
                        mobileCollapse: false;
                        let recording
                    "
                >
                    <div class="recording-col">
                        <!-- Displays recording creation date (timestamp ms) and date + duration (seconds) -->
                        <span>{{ recording.createdAt | date: dateFormat.long }} - {{ recording.createdAt + (recording.duration * 1000) | date: dateFormat.short }}</span>
                        <div class="recording-col-file"></div>
                    </div>
                </ng-container>

                <ng-container *fxColumn="'contextmenu'; mobileHideLabel: true; let recording">
                    <fx-context-menu [gutter]="false">
                        <a class="menu-button" (click)="download(recording)">
                            <i class="las la-download la-lg"></i>
                            <span>Download</span>
                        </a>
                    </fx-context-menu>
                </ng-container>
            </fx-table>
        </div>
    </div>
</ng-container>
