import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Account, IAccountListing, IParticipant, IThreadCardsState, IThreadListing } from "@findex/threads";
import { Observable } from "rxjs";
import { ENVIRONMENT } from "src/app/injection-token";
import { EnvironmentSpecificConfig, environmentCommon } from "../../environment/environment.common";
import { ParticipantCache } from "./participant-cache.service";
import { IRecordingWithContext } from "./threads-recording.service";
import { VideoChatService } from "../../../../../default-plugins/video-chat/services/video-chat.service";
import { IAvatarContent } from "@findex/fx-ui";

@Injectable({ providedIn: "root" })
export class AccountsService {
    constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig, private videoChatService: VideoChatService, private participantsCache: ParticipantCache) {}

    getAccount(accountId: string): Observable<Account> {
        const { accounts } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${accounts}/${accountId}`;
        return this.http.get<Account>(url);
    }

    listAccountsByContact(contactId: string): Observable<Account[]> {
        const { contacts, accounts } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${contacts}/${contactId}${accounts}`;

        return this.http.get<Account[]>(url);
    }

    listAccounts(): Observable<IAccountListing[]> {
        const { accounts } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${accounts}`;

        return this.http.get<IAccountListing[]>(url);
    }

    searchAccounts(searchTerm: string): Observable<IAccountListing[]> {
        const { accounts } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${accounts}/search/${searchTerm}`;

        return this.http.get<IAccountListing[]>(url);
    }

    listThreads(accountId: string): Observable<IThreadListing[]> {
        const { accounts, threads } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${accounts}/${accountId}${threads}`;
        return this.http.get<IThreadListing[]>(url);
    }

    listCardsStateByAccount(accountId: string): Observable<IThreadCardsState<any>[]> {
        const { accounts, cards } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${accounts}/${accountId}${cards}`
        return this.http.get<IThreadCardsState<any>[]>(url);
    }


    async getAccountRecordings(accountId: string): Promise<IRecordingWithContext[]> {
        const recordingsByAccount = await this.videoChatService.getRecordingsByAccount(accountId);
        const threadRecordings = recordingsByAccount.map(threadRecording =>
            threadRecording.recordings.map(recording => ({
                threadId: threadRecording.threadId,
                cardId: threadRecording.cardId,
                cardDescription: threadRecording.description,
                avatars: this.getAttendeeAvatars(recording.participants),
                ...recording
            }))
        );

        return [].concat(...threadRecordings);
    }

    private getAttendeeAvatars(participants: IParticipant[]): Observable<IAvatarContent[]> {
        return this.participantsCache.getMultipleAvatars(participants);
    }
}
