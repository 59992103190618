<div class="banner-calendar">
    <div class="banner-title" *ngIf="label">
        <i class="las la-video"></i>
        <svg height="12" width="12" class="record-dot record-dot-blink">
            <circle cx="6" cy="6" r="6" fill="red" />
        </svg>
        <span [innerHTML]="label"></span>
    </div>
    <div class="banner-actions">
        <div class="banner-actions-button">
            <button class="fx-btn fx-btn--primary" type="button" (click)="joinCall()">
                JOIN NOW
            </button>

            <button
                *ngIf="cardResources.role | permissions: 'EndSession' | async"
                class="fx-btn fx-btn--secondary "
                type="button"
                (click)="terminateSession()"
            >
                END CALL
            </button>
        </div>
    </div>
</div>
