import { Component, Inject, OnInit } from "@angular/core";
import { OnboardingService } from "../../../../../projects/portal-modules/src/lib/onboarding/services/onboarding.service";
import { OnboardingWizardComponent } from "src/solutions/sigma/components/onboarding-wizard/onboarding-wizard.component";
import { EnvironmentSpecificConfig } from "../../../../../projects/portal-modules/src/lib/environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";

/**
 * wrapper for timelines during onboarding so we don't have to add any onboarding logic to thread-list-route
 */
@Component({
    selector: "app-onboarding-timeline-container-component",
    templateUrl: "./onboarding-timeline-container.component.html"
})
export class OnboardingTimelineContainerComponent implements OnInit {
    constructor(
        private onboardingService: OnboardingService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig
    ) {}

    ngOnInit() {
        if (this.environment.featureFlags.inlineOnboarding) {
            this.onboardingService.initVisoryOnboardingModal(OnboardingWizardComponent);
        }
    }
}
