<div class="calendar-meeting-request">
    <multi-component-layout
        [stepConfigurations]="stepConfigurations"
        (emitComplete)="submit()"
        [loading]="loader.counter !== 0"
        [enableFooter]="true"
        [enableHeader]="true"
        contentClass="multi-component-layout--book-meeting"
    >
        <div class="calendar-meeting-request-header multi-component-layout-header">
            <div class="calendar-meeting-request-title">Please select the occurrence to edit</div>
            <div class="calendar-meeting-request-header-close">
                <div class="fx-l-modal-header-icon" (click)="close()">
                    <i class="las la-times icon-size"></i>
                </div>
            </div>
        </div>

        <step-layout stepTitle="Calendar">
            <calendar-instance *ngFor="let instance of data.state?.instances" 
                (click)="selectTime(instance)" 
                (cancel)="cancelTime(instance)"
                [start]="instance.start" 
                [end]="instance.end"></calendar-instance>
        </step-layout>

        
        <step-layout stepTitle="Review & Book">
            <app-calendar-choose-time
                *ngIf="slots"
                [maxDate]="maxDate"
                [minDate]="minDate"
                [slots]="slots"
                [duration]="duration"
                (emitMonthSelection)="monthChange($event)"
                (emitSelection)="handleTimeSelection($event)"
            ></app-calendar-choose-time>
        </step-layout>


        <loader-icon *ngIf="loader.counter" [color]="'light'"></loader-icon>
    </multi-component-layout>
</div>
