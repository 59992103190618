import { Pipe, PipeTransform } from "@angular/core";
import { IThread, IThreadListing } from "@findex/threads";
import { ThreadsService } from "../services/threads.service";

@Pipe({ name: "isThreadActive" })
export class IsThreadActivePipe implements PipeTransform {
    constructor(private threadsService: ThreadsService) {}

    async transform(thread: IThread | IThreadListing): Promise<boolean> {
        return this.threadsService.isThreadActive(thread);
    }
}
