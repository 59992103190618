import { Component, Inject, OnDestroy, ViewChild } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { TabsItemComponent, TabsComponent } from "@findex/fx-ui";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { VisibleExtension, ILibrary, RouteExtension, RouteHelper } from "projects/portal-modules/src/lib/plugins";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { Observable, Subscription, combineLatest } from "rxjs";
import { filter, map, startWith, switchMap } from "rxjs/operators";
import { INSIGHTS_ROUTE_LIBRARY } from "src/app/injection-token";
import { OpenMeasuresService } from "../../services/open-measures.service";

@Component({
    selector: "app-insights-route",
    templateUrl: "./insights-route.component.html",
    styleUrls: ["./insights-route.component.scss"]
})
export class InsightsRouteComponent implements OnDestroy {
    @ViewChild("scorecardTab") scorecardTab: TabsItemComponent;
    @ViewChild("reportsTab") reportsTab: TabsItemComponent;

    @ViewChild("tabs") set tabs(tabs: TabsComponent) {
        if (tabs) this.initTabs(tabs);
    }
    
    gaEvents = GA_EVENTS;
    loader = new Loader();
    extensions$: Observable<VisibleExtension[]>;
    entities: any[];
    private routeSub: Subscription;

    constructor(
        @Inject(INSIGHTS_ROUTE_LIBRARY) library: ILibrary<RouteExtension>,
        private route: ActivatedRoute,
        private router: Router,
        private openMeasures: OpenMeasuresService
    ) {
        RouteHelper.loadAll(route, library)
        this.initEntities();
    }

    async initEntities() {
        this.loader.show();
        
        const enterprises = await this.openMeasures.getEnterprises().toPromise();

        const entities$ = enterprises.map(async enterprise => {
            const entities = await this.openMeasures.getEntities(enterprise.id).toPromise();
            return entities.map(entity => ({
                ...entity,
                label: `${enterprise.label} - ${entity.label}`
            }));
        })

        this.entities = [].concat(...(await Promise.all(entities$)));

        this.loader.hide();
    }

    navigateTo(path: string) {
        this.router.navigate([path], { relativeTo: this.route });
    }

    //See HTML comment, one big hack, should be able to remove this with eco-ui
    initTabs(tabs: TabsComponent) {
        if (this.routeSub) {
            this.routeSub.unsubscribe();
        }

        const path$ = this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(() => this.route.firstChild),
            startWith(this.route.firstChild),
            filter(child => !!child),
            switchMap(child => child.url),
            map(url => url.find(segment => !!segment)?.path)
        );

        this.routeSub = combineLatest([this.extensions$, path$]).subscribe(([extensions, path]) =>
            Promise.resolve().then(() => this.setTab(extensions, tabs, path))
        );
    }

    ngOnDestroy() {
        if (this.routeSub) {
            this.routeSub.unsubscribe();
        }
    }

    private setTab(extensions: VisibleExtension[], tabs: TabsComponent, path: string) {
        switch (path) {
            case "scorecard":
                tabs.selectTab(this.scorecardTab);
                return;
            case "reports":
                tabs.selectTab(this.reportsTab);
                return;
        }

        if (extensions) {
            const matchedExtension = extensions?.find(extension => extension.path === path);
            const matchedTab = tabs.tabComponents.find(tab => tab.header === matchedExtension?.label);
            if (matchedTab) {
                tabs.selectTab(matchedTab);
            }
        }
    }
}
