<dashboard-greeting-banner></dashboard-greeting-banner>
<div
    class="fx-dashboard-threads"
    *ngIf="{ role: role$ | async, thread: thread$ | async, userId: userId$ | async }; let context"
>
    <div class="fx-dashboard-frame fx-container fx-container--fluid">
        <thread [role]="context.role" [thread]="context.thread"></thread>
    </div>
    <loader-icon *ngIf="!context.userId || loader.counter" color="light"></loader-icon>
</div>
