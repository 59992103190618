import { Inject, Injectable } from "@angular/core";
import {
    IThread,
    IThreadListing,
    IThreadWorkflowState,
    IThreadWorkflowStep,
    IWorkflow,
    IWorkflowAction,
    IWorkflowStep,
    WorkflowStepType
} from "@findex/threads";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ComponentType } from "@angular/cdk/portal";
import { Environment, environmentCommon } from "../../environment/environment.common";
import { HttpClient } from "@angular/common/http";
import { ENVIRONMENT } from "src/app/injection-token";

const deepFreeze = (object): Readonly<typeof object> => {
    if (object == null) {
        return object;
    }
    // Retrieve the property names defined on object
    const propNames = Object.getOwnPropertyNames(object);

    // Freeze properties before freezing self

    for (const name of propNames) {
        const value = object[name];

        if (value && typeof value === "object") {
            deepFreeze(value);
        }
    }

    return Object.freeze(object);
};

export interface IProviderWorkflow {
    provider: string;
    workflow: IWorkflow;
}

export interface IThreadWorkflowGroup {
    threads: IThread[];
    provider: string;
    workflow: IWorkflow;
    active?: boolean;
}

type ActionRegistry = {
    [action: string]: {
        component: ComponentType<any>;
        config: MatDialogConfig;
    };
};

type WorkflowCache = {
    [workflowId: string]: Promise<IWorkflow>;
};

@Injectable({
    providedIn: "root"
})
export class WorkflowService {
    private readonly actionRegistry: ActionRegistry = {};
    private readonly workflowsByProvider: { [providerId: string]: WorkflowCache } = {};

    constructor(
        private http: HttpClient,
        private dialog: MatDialog,
        @Inject(ENVIRONMENT) private environment: Environment
    ) {}

    registerAction(action: string, component: ComponentType<any>, config: MatDialogConfig<any>) {
        this.actionRegistry[action] = {
            component,
            config
        };
    }

    async resolveWorkflow(threadWorkflow: IThreadWorkflowState): Promise<Readonly<IWorkflow>> {
        const { provider, id: workflowId } = threadWorkflow;
        return this.getWorkflow(provider, workflowId);
    }

    async getWorkflow(provider: string, workflowId: string): Promise<Readonly<IWorkflow>> {
        const providerCache = this.getProviderCache(provider);

        if (!providerCache[workflowId]) {
            providerCache[workflowId] = this.fetchWorkflow(provider, workflowId);
            const workflow = await providerCache[workflowId];
            return deepFreeze(workflow);
        } else {
            const workflow = await this.workflowsByProvider[provider][workflowId];
            return deepFreeze(workflow);
        }
    }

    private getProviderCache(provider: string): WorkflowCache {
        if (!this.workflowsByProvider[provider]) {
            this.workflowsByProvider[provider] = {};
        }

        return this.workflowsByProvider[provider];
    }

    private async fetchWorkflow(provider: string, workflowId: string): Promise<IWorkflow> {
        const { workflow } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${workflow}/${provider}/${workflowId}`;

        try {
            return await this.http.get<IWorkflow>(url).toPromise();
        } catch (err) {
            console.error("WORKFLOW FETCH FAILED", null);
            return null;
        }
    }

    isStepOpen(step: IThreadWorkflowStep): boolean {
        if (!step) return true;
        return step.type === WorkflowStepType.OPEN;
    }

    getCurrentStep(threadWorkflow: IThreadWorkflowState): Readonly<IThreadWorkflowStep> {
        return threadWorkflow?.steps?.find(step => step.isCurrentStep);
    }

    async getProvidedWorkflow(workflowState: IThreadWorkflowState): Promise<IProviderWorkflow> {
        if (!workflowState) return null;

        const workflow = await this.resolveWorkflow(workflowState);
        const resolvedWorkflow = { workflow, provider: workflowState.provider };

        return {
            workflow,
            provider: resolvedWorkflow.provider
        };
    }

    async groupThreadsByWorkflow(threads: Array<IThread | IThreadListing>): Promise<IThreadWorkflowGroup[]> {
        const groupedWorkflowsByProvider = await this.getWorkflowsByProvider(threads);

        const groupedThreadsArrays = Object.keys(groupedWorkflowsByProvider).map(provider => {
            const workflows = groupedWorkflowsByProvider[provider];
            return Object.values(workflows).map(workflow => ({
                workflow,
                provider,
                threads: threads.filter(
                    thread =>
                        thread.workflow && thread.workflow.provider === provider && thread.workflow.id === workflow.id
                )
            }));
        });
        return [].concat(...groupedThreadsArrays);
    }

    private async getWorkflowsByProvider(
        threads: Array<IThread | IThreadListing>
    ): Promise<{ [provider: string]: { [id: string]: Readonly<IWorkflow> } }> {
        // First get all the workflows, duplicates don't matter since promises resolve once.
        const workflowPromises = threads
            .filter(thread => thread.workflow)
            .map(async thread => {
                const workflow = await this.resolveWorkflow(thread.workflow);

                if (!workflow) return null;
                return { workflow, provider: thread.workflow.provider };
            });

        // Now group by provider, duplicates are removed.
        const workflows = await Promise.all(workflowPromises);
        const workflowsByProvider = workflows
            .filter(workflow => !!workflow)
            .reduce((acc, workflowDetails) => {
                const { provider, workflow } = workflowDetails;
                const workflowsForProvider = acc[provider];
                // Overwriting doesn't matter, as they are the same object.
                return {
                    ...acc,
                    [provider]: {
                        ...workflowsForProvider,
                        [workflow.id]: workflow
                    }
                };
            }, {});
        return workflowsByProvider;
    }

    async handleActions(thread: IThread, step: IWorkflowStep): Promise<boolean> {
        /** TODO this would ideally execute the actions atomically on the back-end. **/
        const action = step.actions.find((actionId: IWorkflowAction) => actionId.type in this.actionRegistry);
        if (action) {
            const actionHandler = this.actionRegistry[action.type];
            const config = {
                ...actionHandler.config,
                data: { ...actionHandler.config.data, thread, workflowStepName: step.internalId }
            };

            return await this.dialog
                .open(actionHandler.component, config)
                .afterClosed()
                .toPromise();
        }
        return true;
    }
}
