import { PortalNewsArticle } from "../../projects/default-plugins/dashboard/components/news-article/model/portal-news-article";
import {
    environmentCommon as common,
    EnvironmentSpecificConfig,
} from "../../projects/portal-modules/src/lib/environment/environment.common";
import { CLIENT_PORTAL_FEATURE_CONFIG } from "./client-portal-feature-flags";

const threadsUrl = "https://threads-backend.portal.findex.dev/qa";
const vaultUrl = "https://vault-backend.portal.findex.dev/qa";
const authUrl = "https://auth-backend.portal.findex.dev/qa";
const notificationsUrl = "https://notifications.portal.findex.dev/qa-user";
const notificationsWsUrl = "wss://notifications-ws.portal.findex.dev/qa";

export const environmentCommon = common;
export const environment: EnvironmentSpecificConfig = {
    appVersion: require("../../package.json").version,
    featureFlags: CLIENT_PORTAL_FEATURE_CONFIG,
    production: false,
    appName: "myFindex",
    appTheme: "findex",
    appUrl: "https://qa.portal.findex.dev",
    stage: "qa",
    sentry: "https://274ac3e30c5242b2b970fe5766fbba62@o516902.ingest.sentry.io/5623966",
    analytics: {
        google: {
            trackingId: "UA-164137897-2",
        },
    },
    hotJar: {
        hjid: "2975102",
        hjsv: "6",
    },
    theme: {
        onboardingCentreAligned: true,
        formSubmitActionOutlined: true,
    },
    searchOptimization: {
        url: "",
    },
    threadsEndpoints: {
        base: threadsUrl,
    },
    commonEndpoints: {
        base: threadsUrl + "-common",
    },
    sigmaEndpoints: {
        base: threadsUrl + "-app/sigma",
    },
    videoChatEndpoints: {
        base: threadsUrl + "-video",
    },
    paymentsEndpoints: {
        base: threadsUrl + "-payments",
    },
    calendarEndpoints: {
        base: threadsUrl + "-calendar",
    },
    vaultEndpoints: {
        base: vaultUrl,
    },
    vaultThreadsEndpoints: {
        base: threadsUrl + "-vault",
    },
    notificationsEndpoints: {
        base: notificationsUrl,
        websockets: notificationsWsUrl,
    },
    publicEndpoints: {
        base: threadsUrl + "-public",
        profileBase: "https://qa.portal.findex.dev",
    },
    openMeasures: {
        endpoint: "",
    },
    threadsWebsockets: "wss://threads-ws.portal.findex.dev/qa",
    registration: {
        redirectUrl: "https://qa.portal.findex.dev/register/email-success",
    },
    emailVerifyUrl: "https://qa.portal.findex.dev/profile/verify-email",
    auth: {
        base: authUrl,
        userPoolId: "ap-southeast-2_4G42RYHvZ",
        userPoolWebClientId: "3h6ejquhjjm1k15b27cl0ubnq0",
        protectedResources: [threadsUrl, vaultUrl, authUrl, notificationsUrl],
        includeCredentialsEndpoints: [threadsUrl],
        forgotPasswordRedirect: "https://qa.portal.findex.dev/reset-password",
        authenticationFlowType: "USER_SRP_AUTH",
    },
    activeDirectory: {
        clientId: "737bec3b-43c2-4f83-99cb-a35787d18a6e",
    },
    appId: "client-portal",
    opentokApiKey: "46649842",
    payments: {
        publishableApiKey: "pk_test_7CuWQ8aWDDQ4F1nRNopgioVO00XVN5FWFl",
        taxRateIds: {
            aud: "txr_1HSBK3FWvxwGxwztD47IgWk0",
            nzd: "txr_1HZPQ3FWvxwGxwzthuqkNysa",
        },
    },
    news: {
        sourceUrl: "https://findex.qa.findex.solutions",
        allArticlePath: "insights",
        fileName: "articles.json",
    },
    policyUrl: "https://findex.qa.findex.solutions/disclaimers/privacy-policy",
    termsOfBusinessUrl: "https://findex.qa.findex.solutions/disclaimers/terms-of-business",
    termsOfUseUrl: 'https://www.findex.com.au/disclaimers/terms-of-use',
    disclaimerUrl: "https://findex.qa.findex.solutions/disclaimers/disclaimer-and-disclosure",
    emailDisclaimerUrl: "https://findex.qa.findex.solutions/disclaimers/email-disclaimer",
    calendarLocation: "Findex VC",
    signupRedirectUrl: "https://findex.qa.findex.solutions/",
    errorRedirectUrl: "https://qa.portal.findex.dev/error",
    emailTimelineUrl: "https://qa.portal.findex.dev/timelines",
    newsArticleMapper: PortalNewsArticle,
    cognitoDomainUrl: "https://client-portal-qa.auth.ap-southeast-2.amazoncognito.com"
};
