<div class="fx-threads-summary-card-bottom">
    <span class="fx-threads-summary-card-summary" [ngSwitch]="hasMultipleParticipants">
        <ng-container *ngIf="preview?.summary">
            <ng-container *ngSwitchCase="true">
                <ng-container *ngIf="preview?.participantId">
                    {{ preview.participantId | userToName | async }}:
                </ng-container>
                {{ preview?.summary | striphtml | slice: 0:60 }}
            </ng-container>
            <ng-container *ngSwitchDefault>
                <ng-container *ngIf="preview?.participantId">
                    {{ preview.participantId === userId ? "You:" : "" }}
                </ng-container>
                {{ preview?.summary | striphtml | slice: 0:60 }}
            </ng-container>
        </ng-container>
    </span>
    <span class="fx-threads-summary-card-time">
        {{ preview?.timestamp || createdAt | timeAgo }}
    </span>
</div>
