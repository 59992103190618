import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

interface IWindowDimensions {
    width: number;
    height: number;
}

@Injectable({
    providedIn: "root"
})
export class WindowListenersService {
    resize = new Subject<IWindowDimensions>();
    width: number;
    height: number;

    constructor(private window: Window) {
        this.resize = new Subject();
        this.width = this.window.innerWidth;
        this.height = this.window.innerHeight;
    }

    windowResized() {
        this.resize.next({
            width: this.window.innerWidth,
            height: this.window.innerHeight
        });
    }

    isWindowSmaller(deviceWidth: number): boolean {
        const width = this.window.innerWidth;
        return width < deviceWidth;
    }
}
