import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { AuthService } from "../../../findex-auth";
import { IMobileVerifyResponse, MobileVerifyService, MobileVerifyStatus } from "./mobile-verify.service";

@Injectable({
    providedIn: "root"
})
export class CognitoMobileVerifyService implements MobileVerifyService {
    constructor(private authService: AuthService) {}

    async requestMFACode(phoneNo: string): Promise<IMobileVerifyResponse> {
        return await this.authService
            .beginVerifyMobileNumber(phoneNo)
            .pipe(
                map(response => ({
                    ...response,
                    status: MobileVerifyStatus[response.status]
                }))
            )
            .toPromise();
    }

    async validateMFACode(code: string): Promise<IMobileVerifyResponse> {
        await this.refreshUserTokens();

        const result = await this.authService
            .confirmMobileNumber(code)
            .pipe(
                map(response => ({
                    ...response,
                    status: MobileVerifyStatus[response.status]
                }))
            )
            .toPromise();

        if (result.status === MobileVerifyStatus.OK) {
            await this.refreshUserTokens();
        }

        return result;
    }

    async refreshUserTokens() {
        //Refresh user tokens as updating the profile invalidates existing ones.
        await this.authService.refreshUserTokens();
    }
}
