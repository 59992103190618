import { Directive } from "@angular/core";
import { SubscriberBaseComponent } from "../subscriber-base.component";
import { CardResources } from "../../../threads-ui/interfaces/IUiCard";
import { TaskActionService } from "./task-action.service";

@Directive()
export class ActionableCardComponent<ActionReturnType> extends SubscriberBaseComponent {
    protected actionInProgress = false;

    constructor(protected resources: CardResources, protected taskActionService: TaskActionService) {
        super();
    }

    async action(actionId: string) {
        return await this.taskActionService.action<ActionReturnType>(actionId, this.resources, (_actionId, _result) =>
            this.actionCallback(_actionId, _result)
        );
    }

    async actionCallback(_actionId: string, _value: ActionReturnType): Promise<void> {
        // override by the child component, useful for afterClosed() etc
    }
}
