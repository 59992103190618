import { Pipe, PipeTransform } from "@angular/core";
import { IThreadWorkflowStep, IThreadWorkflowState } from "@findex/threads";
import { WorkflowService } from "../services/workflow.service";

@Pipe({ name: 'getCurrentStep' })
export class GetCurrentStepPipe implements PipeTransform {
    constructor(private workflowService: WorkflowService) {

    }

    transform(workflowState: IThreadWorkflowState): IThreadWorkflowStep {
        return this.workflowService.getCurrentStep(workflowState);
    }
}