<loader-icon *ngIf="loader.counter" [static]="true" color="light"></loader-icon>
<ng-container *ngIf="!loader.counter">
    <div *ngIf="tableData.data?.length; else noDocuments">
        <fx-table [tableData]="tableData">
            <ng-container
                *fxColumn="
                    'file.displayName';
                    label: 'File name';
                    sortable: true;
                    mobileCollapse: true;
                    mobileHideLabel: true;
                    sortAnalyticsEvent: gaEvents.VAULT_LIST_FILTER_DISPLAY_NAME;
                    let documentList
                "
            >
                <div class="vault-list-col">
                    <span
                        class="vault-list-label"
                        (click)="download(documentList.file.id, documentList.file.fileName, documentList.vaultId)"
                    >
                        {{ documentList.file.displayName }}
                    </span>
                    <i class="las la-chart-bar" *ngIf="documentList.displayName === documentCategory.Report"></i>
                    <i class="las la-list-alt" *ngIf="documentList.cardType === vaultType.VaultRequest"></i>
                    <ng-container *ngIf="documentList.actions && documentList.actions.indexOf('sign') > -1">
                        <signature-icon *ngIf="documentList.signed" theme="dark" [cursor]="false"
                            >Signed by {{ documentList.signer | userToName | async }}
                            {{ documentList.signedOn | date: "dd MMM yyy" }}</signature-icon
                        >
                        <signature-icon *ngIf="!documentList.signed" theme="light" [cursor]="false"
                            >Pending Signature</signature-icon
                        >
                    </ng-container>
                </div>
            </ng-container>
            <ng-container
                *fxColumn="
                    'thread.type';
                    label: 'Service Type';
                    sortable: true;
                    mobileCollapse: true;
                    sortAnalyticsEvent: gaEvents.VAULT_LIST_FILTER_SERVICE_TYPE;
                    let documentList
                "
            >
                <div class="vault-list-col">
                    <span class="vault-list-col"> {{ documentList.thread.type | titlecase }} </span>
                </div>
            </ng-container>
            <ng-container
                *fxColumn="
                    'thread.title';
                    label: 'Timeline';
                    sortable: true;
                    mobileCollapse: true;
                    sortAnalyticsEvent: gaEvents.VAULT_LIST_FILTER_THREAD_TITLE;
                    let documentList
                "
            >
                <div class="vault-list-col">
                    <span class="vault-list-col"> {{ documentList.thread.title }} </span>
                </div>
            </ng-container>
            <ng-container
                *fxColumn="
                    'file.timestamp';
                    label: 'Date added';
                    sortable: true;
                    mobileCollapse: true;
                    sortAnalyticsEvent: gaEvents.VAULT_LIST_FILTER_TIMESTAMP;
                    let documentList
                "
            >
                <span class="vault-list-col"> {{ documentList.file.timestamp | timeAgo }} </span>
            </ng-container>

            <ng-container
                *fxColumn="
                    'actorName';
                    label: 'Uploaded by';
                    sortable: true;
                    mobileCollapse: true;
                    sortAnalyticsEvent: gaEvents.VAULT_LIST_FILTER_CREATED_BY;
                    let documentList
                "
            >
                <div class="vault-list-user">
                    <fx-avatar
                        [name]="documentList.actorName"
                        [image]="documentList.actorId | profilePicture"
                        size="medium"
                    ></fx-avatar>
                </div>
            </ng-container>

            <ng-container *fxColumn="'contextmenu'; mobileHideLabel: true; let documentList">
                <fx-context-menu [gutter]="false">
                    <a
                        *ngIf="
                            documentList.cardType !== vaultType.VaultRequest &&
                            (userId$ | async) === documentList.actorId
                        "
                        class="menu-button"
                        [analyticsClick]="gaEvents.VAULT_LIST_CONTEXT_RENAME"
                        (click)="renameDocument(documentList.thread.id, documentList)"
                    >
                        <i class="las la-pencil-alt la-lg"></i>
                        <span>Rename</span>
                    </a>
                    <a
                        class="menu-button"
                        [analyticsClick]="gaEvents.VAULT_LIST_CONTEXT_DOWNLOAD"
                        (click)="download(documentList.file.id, documentList.file.fileName, documentList.vaultId)"
                    >
                        <i class="las la-download"></i>
                        <span>Download</span>
                    </a>
                    <a
                        class="menu-button"
                        [analyticsClick]="gaEvents.VAULT_LIST_CONTEXT_VIEW_IN_TIMELINE"
                        [routerLink]="['/timelines', documentList.thread.id, 'cards', documentList.cardId]"
                    >
                        <i class="las la-comments"></i>
                        <span>View in timeline</span>
                    </a>
                    <a
                        *ngIf="
                            documentList.cardType !== vaultType.VaultRequest &&
                            (userId$ | async) === documentList.actorId
                        "
                        class="menu-button"
                        [analyticsClick]="gaEvents.VAULT_LIST_CONTEXT_DELETE"
                        (click)="
                            deleteItem(
                                documentList.thread.id,
                                documentList.vaultId,
                                documentList.file.id,
                                documentList.cardId
                            )
                        "
                    >
                        <i class="las la-trash-alt"></i>
                        <span>Delete</span>
                    </a>
                </fx-context-menu>
            </ng-container>
        </fx-table>
    </div>

    <ng-template #noDocuments>
        <div class="vault-list-empty">
            <img src="./assets/images/file-icon.svg" />
            <div class="vault-list-empty-text">
                <div class="vault-list-empty-text-header">Your file list is currently empty</div>
                <div class="vault-list-empty-text-description">
                    All of the files uploaded to the Visory platform will appear here. You will be able to search for
                    and download files at any time.
                </div>
            </div>
        </div>
    </ng-template>
</ng-container>
