import { Component, Input, EventEmitter, Output, Inject } from "@angular/core";
import { EnvironmentSpecificConfig } from "../../../../environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";
@Component({
    selector: "app-verify-email-prompt",
    templateUrl: "./verify-email-prompt.component.html",
    styleUrls: ["./verify-email-prompt.component.scss"]
})
export class VerifyEmailPromptComponent {
    @Input() emailAddress: string;
    @Input() verifyEmailMessage: {
        error: boolean;
        message: string;
    };

    @Output() resend = new EventEmitter();

    readonly theme = this.environment.theme;
    readonly textResource = this.environment.featureFlags.text;

    constructor(@Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig) {}
}
