import { Component, EventEmitter, Input, Output, OnInit, ViewChild, Inject } from "@angular/core";
import { VideoChatService as VcService } from "@findex/video-chat";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { VcDevice } from "@findex/video-chat/types/vc-device";
import { PreCallSessionComponent } from "@findex/video-chat/components/pre-call-session/pre-call-session.component";
import { VideoChatService } from "../../services/video-chat.service";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";
import { IVisualEffect } from "@findex/video-chat/types/vc-visual-effect";


@Component({
    selector: "pre-call-details",
    templateUrl: "./pre-call-details.component.html",
    styleUrls: ["pre-call-details.component.scss"]
})
export class PreCallDetailsComponent implements OnInit {
    @ViewChild("session") session: PreCallSessionComponent;

    @Input() publishAudio = true;
    @Input() publishVideo = false;
    @Input() name: string;
    @Input() visualEffect?: IVisualEffect;

    @Output() joinCall = new EventEmitter();
    @Output() toggleAudio = new EventEmitter();
    @Output() toggleVideo = new EventEmitter();
    @Output() setAudioDevice = new EventEmitter();
    @Output() addVisualEffect = new EventEmitter();

    audioDevices$: Observable<VcDevice[]>;
    selectedAudioId: string;

    readonly policyUrl = this.environment.policyUrl;

    constructor(
        private vcService: VcService,
        private videoChatService: VideoChatService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig
    ) {}

    ngOnInit() {
        this.audioDevices$ = this.vcService.getDevices().pipe(
            map(devices => devices.filter(device => device.deviceId && device.kind === "audioInput"))
        );
    }


    selectAudioDevice(audioDeviceId: string) {
        this.selectedAudioId = audioDeviceId;
        this.setAudioDevice.emit(audioDeviceId);
    }

    handleError(error) {
        this.videoChatService
            .handleError(error)
            .afterClosed()
            .subscribe(allowRetry => {
                if (allowRetry) this.session.retrySession();
            });
    }
}
