import { Component, Inject, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { map, filter } from "rxjs/operators";
import { ENVIRONMENT } from "src/app/injection-token";
import { EnvironmentSpecificConfig } from "../../../environment/environment.common";
import { Role } from "@findex/threads";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
@Component({
    selector: "app-user-settings",
    styleUrls: ["./user-settings.component.scss"],
    templateUrl: "./user-settings.component.html"
})
export class UserSettingsComponent implements OnInit {
    readonly subscriptionEnabled = this.environment.featureFlags.subscriptionEnabled;

    isViewingClient: boolean;
    roles = Role;
    role$: Observable<Role>;

    constructor(
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private authService: AuthService
    ) {}

    ngOnInit() {
        this.role$ = this.authService.getUser().pipe(
            filter(user => !!user),
            map(user => user.globalRole)
        );
    }
}
