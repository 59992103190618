import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { FxUiModule } from "@findex/fx-ui";
import { SharedModule } from "../shared";
import { FxTableComponent } from "./components/fx-table.component";
import { FxColumnDirective } from "./directives/fx-column.directive";
import { FxRowChildDirective } from "./directives/fx-row-child.directive";

//TODO: move this to FX UI once stable
const exportableDeclarations = [FxTableComponent, FxColumnDirective, FxRowChildDirective];

@NgModule({
    declarations: [...exportableDeclarations],
    exports: [...exportableDeclarations],
    imports: [CommonModule, MatTableModule, MatSortModule, FxUiModule, SharedModule]
})
export class FxTableModule {}
