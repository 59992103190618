import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { IThreadWorkflowState, IThreadWorkflowStep, IWorkflow, IWorkflowStep, IWorkflowTimeEstimate } from "@findex/threads";
import { DateTime } from "luxon";
import { WorkflowStepsService } from "../../services/workflow-steps.service";
import { dueDateValidator } from "./due-date.validator";

@Component({
    selector: 'due-dates',
    templateUrl: './due-dates.component.html',
    styleUrls: ['./due-dates.component.scss']
})
export class DueDatesComponent implements OnChanges {
    @Input() title: string;
    @Input() workflow: IWorkflow;
    @Input() workflowState?: IThreadWorkflowState;
    @Input() startDate: DateTime;
    @Output() datesUpdated = new EventEmitter<Record<string, DateTime>|void>();

    rhythmDates = new FormGroup({});

    constructor(private workflowStepsService: WorkflowStepsService) {

    }

    handleChange() {
        if (this.rhythmDates.valid) {
            const val = this.rhythmDates.value;
            this.datesUpdated.emit(val);
        } else {
            this.datesUpdated.emit(null);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        const { workflow, workflowState, startDate } = changes;

        if ((workflow || startDate) && this.workflow && this.startDate && !this.workflowState) {
            this.rhythmDates = this.getDueDatesControl(this.workflow.steps, this.startDate);
        }

        if ((workflow || startDate || workflowState) && this.workflow && this.workflowState && this.startDate) {
            const steps = this.workflow.steps.map(step => ({ ...step, timeEstimate: this.getExistingTimeEstimate(this.workflowState.steps, step) }))
            this.rhythmDates = this.getDueDatesControl(steps, this.startDate);
        }
    }

    private getExistingTimeEstimate(steps: IThreadWorkflowStep[], workflowStep: IWorkflowStep): IWorkflowTimeEstimate {
        return steps.find(stateStep => workflowStep.clientFacingId === stateStep.clientFacingId)?.timeEstimate;
    }

    private getDueDatesControl(workflowSteps: IWorkflowStep[], startDate: DateTime): FormGroup {
        const dueDates = this.workflowStepsService.calculateDueDates(workflowSteps, startDate.toMillis());

        const formGroup: Record<string, FormControl> = dueDates.reduce((formGroup, stepDueDate) => {
            const dueDate = DateTime.fromMillis(stepDueDate.dueDate);

            return {
                ...formGroup,
                [stepDueDate.step.clientFacingId]: new FormControl(dueDate, [Validators.required])
            }
        }, {});

        return new FormGroup(formGroup, dueDateValidator(workflowSteps));
    }
}