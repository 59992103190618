import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { IParticipant, IThread } from "@findex/threads";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { ThreadsService } from "../../../services/threads.service";

@Component({
    selector: "thread-remove-participant",
    templateUrl: "./thread-remove-participant.component.html",
    styleUrls: ["./thread-remove-participant.component.scss"]
})
export class ThreadRemoveParticipantComponent {
    loader = new Loader();
    participant: IParticipant;
    private thread: IThread;

    constructor(
        @Inject(MAT_DIALOG_DATA) data: any,
        private dialogRef: MatDialogRef<ThreadRemoveParticipantComponent>,
        private threadsService: ThreadsService
    ) {
        this.participant = data.participant;
        this.thread = data.thread;
    }

    async confirm() {
        this.loader.show();

        await this.threadsService.removeParticipant(this.thread.id, this.participant.id).toPromise();

        this.loader.hide();
        this.close();
    }

    close() {
        this.dialogRef.close();
    }
}
