<ng-container>
    <div class="request-item-text-response" *ngFor="let comment of comments">
        <request-response
            *ngIf="!!comment.textinput || (userId === comment.modifiedBy && showTextResponse)"
            [userId]="userId"
            class="request-item-text-response-view"
            [message]="comment.textinput"
            [modifiedAt]="comment.modifiedAt"
            [modifiedBy]="comment.modifiedBy"
            [readonly]="!(userId === comment.modifiedBy) || readonly"
            (formDeleteResponse)="deleteTextResponse(comment)"
            (formSubmitResponse)="submitTextResponse($event)"
            (valueChanges)="responseUpdated($event)"
            (discardChanges)="discardChanges()"
        >
        </request-response>
    </div>
    <request-response-form
        *ngIf="isAddingNewResponse"
        [userId]="userId"
        (formSubmitResponse)="submitNewResponse($event)"
        (discardChanges)="discardNewResponse()"
    >
    </request-response-form>
    <div *ngIf="files.length" class="request-item-attached-files">
        <div *ngFor="let file of files" class="request-item-attached-files-attachment">
            <div class="request-common-file">
                <i class="request-common-file-icon las la-paperclip"></i>
                <a class="request-common-file-name" (click)="downloadFile(file)">{{ file.filename }}</a>
            </div>

            <div
                *ngIf="!readonly && userId === file.actorId"
                (click)="removeFile(file)"
                analyticsClick="{{ analyticsPrefix }}_todo-remove-file"
                class="request-item-attached-files-delete"
            >
                <i class="las la-times"></i>
            </div>
        </div>
    </div>
    <div *ngIf="!requestItem.response.complete.state && !readonly" class="request-item-buttons">
        <div
            (click)="toggleTextResponse()"
            class="request-item-buttons-reply"
        >
            <i class="las la-comment-alt"></i>
            Add a response
        </div>
        <div
            (click)="toggleAttach()"
            class="request-item-buttons-attach"
        >
            <i class="las la-paperclip"></i>
            Attach a file
        </div>
    </div>
    <div class="request-item-dropzone" *ngIf="showAttach">
        <file-input [enableDirectoryDrop]="true" [analyticsPrefix]="analyticsPrefix" (file)="attachFile($event)"></file-input>
    </div>
</ng-container>
