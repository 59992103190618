import { HttpClient } from "@angular/common/http";
import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { IActionLink } from "@findex/threads";
import { IVaultFile, VaultService, VAULT_BASE } from "@findex/vault";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { AppUser, AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { SubscriberBaseComponent } from "projects/portal-modules/src/lib/shared/components/subscriber-base.component";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { HandledError } from "projects/portal-modules/src/lib/shared/services/threads-error-handler";
import {
    FocusWizardModel,
    FOCUS_WIZARD_DATA
} from "projects/portal-modules/src/lib/threads-ui/components/focus-action-wizard/focus-action-wizard.service";
import { ThreadCardService } from "projects/portal-modules/src/lib/threads-ui/services/thread-card.service";
import { CardStateResponse } from "projects/portal-modules/src/lib/threads-ui/services/threads.service";
import { filter, take } from "rxjs/operators";
import { ENVIRONMENT } from "src/app/injection-token";
import { IVaultItem } from "../../interfaces/IVaultItem";
import { IVaultState } from "../../interfaces/IVaultState";

const cardStateIsVault = (cardState: CardStateResponse<any>["state"]): IVaultState =>
    !!(cardState as IVaultState)?.groups && cardState;

const vaultFocusLoader = new Loader();

@Component({
    selector: "vault-sign-focus",
    templateUrl: "./vault-sign-focus.component.html",
    styleUrls: ["./vault-sign-focus.component.scss"],
    providers: [
        { provide: Loader, useValue: vaultFocusLoader },
        {
            provide: VAULT_BASE,
            useFactory: (environment: EnvironmentSpecificConfig) => environment.vaultThreadsEndpoints.base,
            deps: [ENVIRONMENT]
        },
        {
            provide: VaultService,
            useClass: VaultService,
            deps: [VAULT_BASE, HttpClient]
        }
    ]
})
export class VaultSignFocusComponent extends SubscriberBaseComponent implements OnInit {
    vaultItem: IVaultItem;
    vaultFile: IVaultFile;
    errorMessage = "";

    isSigned: boolean;
    signer: string;
    signedOn: string;
    title: string;
    user: AppUser;

    actionLink: IActionLink;

    signLoader = new Loader();

    signingForm = new FormGroup({
        name: new FormControl("", [Validators.required]),
        title: new FormControl("", [Validators.required]),
        agreed: new FormControl(false, [Validators.required])
    });

    constructor(
        @Inject(FOCUS_WIZARD_DATA) private focusWizardModel: FocusWizardModel,
        public loader: Loader,
        private authService: AuthService,
        private cardService: ThreadCardService,
        private vaultService: VaultService
    ) {
        super();
    }

    ngOnInit(): void {
        this.authService
            .getUser()
            .pipe(
                filter(user => !!user),
                take(1)
            )
            .subscribe(user => this.initialize(user));

        this.focusWizardModel.state
            .pipe(
                filter(state => !!state?.signed),
                take(1)
            )
            .subscribe(state => {
                this.isSigned = state.signed;
                this.title = state?.signerTitle || "";
            });
    }

    async initialize(user?: AppUser): Promise<void> {
        this.loader.show();

        this.user = user;

        try {
            this.actionLink = this.focusWizardModel.actionLink;
            const { threadId, cardId } = this.actionLink;

            const vaultCardStateResponse = await this.cardService
                .getCardState(threadId, cardId)
                .pipe(take(1))
                .toPromise();

            this.setCardState(vaultCardStateResponse);

            // fallbacks in case card state hasn't updated yet
            this.signer = this.vaultItem?.signer || this.user?.id;
            this.signedOn = this.vaultItem?.signedOn || new Date().toISOString();
            this.title = this.vaultItem?.signerTitle || this.focusWizardModel?.state?.getValue()?.signerTitle;
            this.vaultFile = await this.getVaultFile(this.vaultItem.vaultId, this.vaultItem.fileId);
        } catch (err) {
            console.error(err);
            throw new HandledError(err);
        } finally {
            this.loader.hide();
        }
    }

    private setCardState(vaultCardStateResponse: CardStateResponse<IVaultState>): void {
        const state = cardStateIsVault(vaultCardStateResponse.state);

        this.vaultItem = this.getFirstVaultItem(state);

        this.isSigned = this.focusWizardModel?.state?.getValue()?.signed || !!this.vaultItem?.signed;
        if (this.isSigned) {
            this.focusWizardModel.complete.emit({ signed: true, signerTitle: this.title });
        }
    }

    private getFirstVaultItem(state: CardStateResponse<IVaultState>["state"]) {
        return state?.groups?.find(group => !!group)?.items?.find(item => !!item);
    }

    async download() {
        if (!this.vaultItem.files.length) return;
        const filename = this.vaultItem.files[0].filename;

        const { vaultId, fileId } = this.vaultItem;

        const downloadWindow = window.open("", "_self");
        const downloadUrl = await this.vaultService.getDownloadUrl(vaultId, fileId, filename).toPromise();
        downloadWindow.location.href = downloadUrl;
    }
    // TODO: extract to its own service, DRY - used in insights-reporting.component
    async getVaultFile(vaultId: string, fileId: string): Promise<IVaultFile> {
        const vault = await this.vaultService.getVault(vaultId).toPromise();
        return vault.files.find(file => file.fileId === fileId);
    }

    async acceptAndSign() {
        const formValue = this.signingForm.value;
        if (formValue.agreed && this.vaultItem) {
            try {
                this.signLoader.show();
                await this.vaultService.sign(this.vaultItem.vaultId, this.vaultItem.fileId, formValue).toPromise();

                this.focusWizardModel.complete.emit({ signed: true, signerTitle: formValue.title });
                this.initialize(this.user); // TODO: required to force angular to rerender the step after (since CSA flow shows both in a row), remove after invite to account setup is added
            } catch (err) {
                this.errorMessage = err?.error?.message || err?.message || err;
            } finally {
                this.signLoader.hide();
            }
        }
    }
}
