import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "projects/portal-modules/src/lib/findex-auth";
import { InsightsAccountListingComponent } from "./components/account-listing/insights-account-listing.component";
import { InsightsMetricDetailRouteComponent } from "./components/insights-metric-detail-route/insights-metric-detail-route.component";
import { InsightsOverviewComponent } from "./components/insights-overview/insights-overview.component";
import { InsightsReportingComponent } from "./components/insights-reporting/insights-reporting.component";

import { InsightsRouteComponent } from "./components/insights-route/insights-route.component";

// rename route
export const scorecardRoutes: Routes = [
    { path: "overview/:enterpriseId/entity/:entityId", component: InsightsOverviewComponent },
    { path: "overview/:enterpriseId/entity/:entityId/metric/:metricId", component: InsightsMetricDetailRouteComponent },
    { path: "", component: InsightsAccountListingComponent, canActivate: [AuthGuard] },
    { path: "**", redirectTo: "overview" }
];

const routes: Routes = [
    { 
        path: "", 
        component: InsightsRouteComponent, 
        canActivate: [AuthGuard], 
        children: [
            { path: "reports", component: InsightsReportingComponent },
            { path: "scorecard", canActivate: [AuthGuard], children: scorecardRoutes },
            { path: "**", redirectTo: "scorecard" }
        ]
    },
    { path: "user/:userId", component: InsightsReportingComponent, canActivate: [AuthGuard] },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class InsightsRoutingModule {}
