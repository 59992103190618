import { Pipe, PipeTransform, Injectable, Inject } from "@angular/core";
import { ParticipantCache } from "../services/participant-cache.service";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { EnvironmentSpecificConfig } from "../../environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";

@Injectable({ providedIn: "root" })
@Pipe({ name: "userToName" })
export class UserToNamePipe implements PipeTransform {
    readonly systemName = this.environment.appName;

    constructor(
        private participantCache: ParticipantCache,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig
    ) {}

    transform(userId: string): Observable<string> {
        if (userId === "system") {
            return of(this.systemName);
        }
        return this.participantCache
            .getParticipant(userId)
            .pipe(map(participant => this.participantCache.cleanName(participant)));
    }
}
