<ng-container *ngIf="card$ | async; let card">
    <app-card
        *ngIf="card.status === cardStatuses.Removed"
        [lineCard]="card.status === cardStatuses.Removed"
        [description]="'This message has been removed.'"
        [timestamp]="card.modifiedAt"
        [conversation]="false"
    >
    </app-card>
    <thread-card
        *ngIf="card.status !== cardStatuses.Removed && userId$ | async; let userId"
        title="{{ cardTitle$ | async }}"
        [thread]="thread$ | async"
        [card]="card$ | async"
        [replies]="replies$ | async"
        [invertTheme]="userId === card.createdBy"
        [loading]="loading"
        [createdById]="card.createdBy"
        [edited]="card.status === cardStatuses.Edited"
        [edit]="edit$"
        (save)="save($event)"
    >

        <payment-details
            [packagePriceDetails]="packagePriceDetails$ | async"
            [cardState]="state$ | async"
            [accountId]="(thread$ | async).accountId"
            [card]="card"
            [role]="role"
            (makePayment)="action($event)"
        >
        </payment-details>
        <div card-actions *ngIf="allowEdit && role | permissions: 'DeletePaymentCard' | async">
            <fx-context-menu #menu [gutter]="false" *ngIf="(card$ | async).createdBy === userId">
                <a [analyticsClick]="gaEvents.PAYMENTCARD_EDIT" (click)="editMessage()">Edit</a>
                <a [analyticsClick]="gaEvents.PAYMENTCARD_CANCEL" (click)="cancelCard()" *ngIf="isCancelable$ | async"
                    >Cancel</a
                >
            </fx-context-menu>
        </div>
        <div card-loader>
            <loader-icon [color]="'light'"></loader-icon>
        </div>
        <div class="thread-card-error" *ngIf="errorMessage">
            {{ errorMessage }}
        </div>
    </thread-card>
</ng-container>
