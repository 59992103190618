<div class="vaults">
    <div class="vaults-header">
        <h3>Files</h3>
    </div>

    <div class="vaults-filter">
        <div class="vaults-search-container">
            <app-thread-search
                [analyticsClickEvent]="GA_EVENTS.VAULT_LIST_SEARCHSUBMIT"
                [isMobileView]="isMobileView"
                (search)="updateSearchTerm($event)"
                placeholder="Search by filename, service type, timeline, or person"
            ></app-thread-search>
        </div>

        <div class="vaults-filter-dropdown">
            <form [formGroup]="form">
                <fx-select
                    placeholder="All time"
                    formControlName="dateRange"
                    [analyticsClick]="GA_EVENTS.VAULT_LIST_FILTER_DATE_RANGE"
                >
                    <fx-option [value]="">All time</fx-option>
                    <fx-option *ngFor="let time of TIME_RANGE" [value]="time.value">{{ time.label }}</fx-option>
                </fx-select>
                <fx-select
                    placeholder="All Accounts"
                    formControlName="accountId"
                    [analyticsClick]="GA_EVENTS.VAULT_LIST_FILTER_ACCOUNTS"
                >
                    <fx-option *ngFor="let account of accounts" [value]="account.id">{{ account.name }}</fx-option>
                    <fx-option [value]="">All accounts</fx-option>
                </fx-select>
            </form>
        </div>
    </div>
    <app-vault-list-table [documents]="filteredResults"></app-vault-list-table>
</div>
