import { Component, Input, Output, EventEmitter, Inject, OnChanges, ElementRef } from "@angular/core";
import { Subject, Observable } from "rxjs";
import { IThread, IThreadCard, CardReply, CardStatus } from "@findex/threads";
import { filter, map, shareReplay, take } from "rxjs/operators";
import { AuthService } from "../../../findex-auth";
import { EnvironmentSpecificConfig } from "../../../environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";
import { ViewportObserverService } from "../../services/viewport-observer.service";

enum CardBorderColors {
    SECONDARY = "secondary",
    PRIMARY = "primary"
}

@Component({
    selector: "thread-card",
    templateUrl: "./thread-card.component.html",
    styleUrls: ["./thread-card.component.scss"]
})
export class ThreadCardComponent implements OnChanges {
    @Input() thread: IThread;
    @Input() card: IThreadCard;
    @Input() replies: CardReply[];
    @Input() title: string;
    @Input() name: string;
    @Input() timestamp: string;
    @Input() description: string;
    @Input() invertTheme: boolean;
    @Input() loading: boolean;
    @Input() edited: boolean | Subject<boolean>;
    @Input() edit: Subject<boolean>;
    @Input() createdById: string;
    @Input() hideReplies = false;
    @Output() save = new EventEmitter<any>();

    readonly appName = this.environment.appName;

    cardBorderColor = CardBorderColors.SECONDARY;
    userId$: Observable<string>;
    expandCardView = false;
    showSeeMore: boolean;
    cardStatuses = CardStatus;

    cardVisible$: Observable<boolean>;

    constructor(private authService: AuthService, @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig, viewportObserver: ViewportObserverService, elm: ElementRef) {
        this.userId$ = this.authService.getUser().pipe(
            filter(user => !!user),
            map(user => user.id)
        );

        this.cardVisible$ = viewportObserver.observe(elm).pipe(
            filter(visible => !!visible),
            take(1),
            shareReplay(1)
        );
    }

    ngOnChanges(changes: any) {
        const { createdById } = changes;

        if (createdById) {
            //TODO: reconsider border colouring, visory/client or you/everyone else
            //If the we are keeping the former, clean this up to not use ID
            this.cardBorderColor =
                !this.createdById || this.createdById.startsWith("cognito")
                    ? CardBorderColors.SECONDARY
                    : CardBorderColors.PRIMARY;
        }
    }
}
