<div class="onboarding-context">
    <h1 class="onboarding-context-title">Let's talk about your business</h1>
    <p class="onboarding-context-subtitle">Tell us your goals, your pain points and where we can help.</p>

    <div class="onboarding-context-content">
        <div class="onboarding-context-text">
            <h2 class="onboarding-context-header">How it works</h2>
            <ol class="onboarding-context-list">
                <li><strong>Tell us the services</strong> you're interested in</li>
                <li><strong>Select a time to meet</strong> with a Visory team member</li>
                <li><strong>Create your account</strong> to join your meeting on the Visory platform</li>
            </ol>

        </div>
        <div class="onboarding-context-video">
            <span>What should you expect from your first online meeting? </span>
            <a [href]="onboardingVideoUrl" (click)="openVideoModal($event)" analyticsClick="onboarding_video">
                <i class="las la-play-circle"></i>Watch the video
            </a>
        </div>

        <img class="onboarding-context-image" src="./assets/images/visory/onboarding/goals/group-photo.png" alt="We'll match you with the perfect team to manage your back office." />
    </div>

</div>
