import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Inject, Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { SENTRY_ERROR_HANDLER } from "src/app/injection-token";
import { AnalyticsService } from "../../analytics";
import { ErrorModalComponent } from "../components/error/error-modal/error-modal.component";

export class HandledError {
    constructor(public originalError: any) {}
}

@Injectable({
    providedIn: "root"
})
export class ErrorService extends ErrorHandler {
    private static readonly maxErrorDepth = 99;

    constructor(
        private dialog: MatDialog,
        @Inject(SENTRY_ERROR_HANDLER) private sentryErrorHandler: ErrorHandler,
        private analytics: AnalyticsService
    ) {
        super();
    }

    public showError(title?: string, message?: string, buttonTitle?: string, allowAction?: boolean) {
        const options = {
            disableClose: true,
            backdropClass: "modal-backdrop",
            panelClass: ["fx-error-modal-dialog"],
            maxWidth: "100%",
            maxHeight: "100%",
            data: {
                title: title || undefined,
                message: message || undefined,
                buttonTitle: buttonTitle || undefined,
                allowAction: allowAction || undefined
            }
        };

        return this.dialog.open(ErrorModalComponent, options);
    }

    handleError(error: any): void {
        const err = error?.rejection || error;
        const isHandled = err instanceof HandledError;

        const responseError = this.unwrapError(err);
        // Notes: Global error handler appears to only fire once the unsubscribes
        if (!isHandled) {
            if (this.isHttpErrorResponse(responseError)) {
                const title =
                    responseError.status && responseError?.error?.error
                        ? `${responseError.status} - ${responseError.error.error}`
                        : null;
                const message = responseError?.error?.message || null;

                this.trackAnalytics(responseError.status);
                if (this.userShouldSeeError(responseError)) {
                    this.showError(title, message);
                }
            } else if (this.isJSError(responseError) && !this.sentryShouldIgnoreError(responseError)) {
                // not sure if this is needed or not, shows unfriendly errors
                if (this.userShouldSeeError(responseError)) {
                    this.showError(responseError.name, responseError.message);
                }
            }
        }

        if (responseError && this.sentryShouldIgnoreError(responseError)) {
            return;
        }

        this.sentryErrorHandler.handleError(responseError);
    }

    trackAnalytics(statusCode: number) {
        if (statusCode >= 400) {
            this.analytics.recordEvent("error", `app_error${statusCode}`);
        }
    }

    isHttpErrorResponse(err: any): err is HttpErrorResponse {
        return err && err.name && err.name === "HttpErrorResponse" && err.ok === false && err.message;
    }

    isJSError(err: any): err is Error {
        return err && err.name && err.message;
    }

    /**
     * filter errors we don't want in Sentry
     */
    sentryShouldIgnoreError(error: any): boolean {
        // 403s
        if (this.isHttpErrorResponse(error) && error.status === 403) {
            return true;
        }
        return false;
    }

    /**
     * filter errors we don't want to show to the user (but still want in Sentry)
     */
    private userShouldSeeError(error: any): boolean {
        // firefox/quill bug (seems harmless)
        if (this.isJSError(error) && error.message === `Permission denied to access property \"parentNode\"`) {
            return false;
        }
        if (error.name.toLowerCase() === "chunkloaderror") {
            return false;
        }
        return true;
    }

    private unwrapError(error: any, errorDepth = 0) {
        if (errorDepth > ErrorService.maxErrorDepth) {
            console.error("Error service self-reference loop");
            return error;
        }
        if (error instanceof HandledError) {
            return this.unwrapError(error.originalError, errorDepth++);
        }
        if (error.rejection) {
            return this.unwrapError(error.rejection, errorDepth++);
        }
        return error;
    }
}
