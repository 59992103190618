import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { IVaultListItem } from "@findex/threads";
import { VaultService } from "@findex/vault";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { RenameFileModalComponent } from "projects/portal-modules/src/lib/threads-ui/components/create-card/rename-file-modal/rename-file-modal.component";
import { ThreadCardService } from "projects/portal-modules/src/lib/threads-ui/services/thread-card.service";
import { Observable } from "rxjs";
import { filter, map, shareReplay } from "rxjs/operators";
import { environmentCommon } from "src/environments/environment";
import { DocumentCategory } from "../../enums/DocumentCategory";
import { VaultCardDeleteItem, VaultCardRenameItem } from "../../types/EditCardRequests";
import { ConfirmDeleteDialogComponent } from "../confirm-delete-dialog/confirm-delete-dialog.component";

export enum VaultType {
    VaultRequest = "vault-request",
    Vault = "vault"
}

@Component({
    selector: "app-vault-list-table",
    templateUrl: "./vault-list-table.component.html",
    styleUrls: ["./vault-list-table.component.scss"]
})
export class VaultListTableComponent implements OnInit, OnChanges {
    readonly vaultType = VaultType;
    readonly gaEvents = GA_EVENTS;
    readonly documentCategory = DocumentCategory;

    @Input() documents: IVaultListItem[];
    @Input() loader = new Loader();

    tableData = new MatTableDataSource<IVaultListItem>();
    userId$: Observable<string>;

    constructor(
        private dialog: MatDialog,
        private authService: AuthService,
        private cardService: ThreadCardService,
        private vaultService: VaultService
    ) {}

    async ngOnInit() {
        this.loader.show();

        this.userId$ = this.authService.getUser().pipe(
            filter(user => !!user),
            map(user => user.id),
            shareReplay(1)
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.documents?.currentValue) {
            this.tableData.data = this.sortByLatest(this.documents);
            if (this.loader.counter) {
                this.loader.hide();
            }
        }
    }

    async download(itemId: string, fileName: string, vaultId: string) {
        const downloadWindow = window.open("", "_self");
        downloadWindow.location.href = await this.vaultService.getDownloadUrl(vaultId, itemId, fileName).toPromise();
    }

    async downloadItem(itemId: string, fileName: string, vaultId: string) {
        this.loader.show();
        await this.download(itemId, fileName, vaultId);
        this.loader.hide();
    }

    async renameDocument(threadId: string, document: IVaultListItem) {
        this.dialog
            .open(RenameFileModalComponent, {
                data: { description: document.file.displayName },
                panelClass: ["centered-modal"]
            })
            .afterClosed()
            .subscribe(async updatedDescription => {
                if (!updatedDescription) return;

                if (updatedDescription === document.file.displayName) {
                    return;
                }

                const { vault } = environmentCommon.cardsEndpoints;

                this.loader.show();
                try {
                    const data: VaultCardRenameItem = {
                        vaultId: document.vaultId,
                        fileId: document.file.id,
                        editAction: "rename",
                        displayName: updatedDescription
                    };

                    await this.cardService
                        .editCard<VaultCardRenameItem>(threadId, vault, document.cardId, data)
                        .toPromise();
                } finally {
                    this.loader.hide();
                }
            });
    }

    async deleteItem(threadId: string, vaultId: string, fileId: string, cardId: string) {
        this.dialog
            .open(ConfirmDeleteDialogComponent, {
                panelClass: ["centered-modal"]
            })
            .afterClosed()
            .subscribe(async val => {
                if (!val) return;

                const { vault } = environmentCommon.cardsEndpoints;

                this.loader.show();
                try {
                    const data: VaultCardDeleteItem = {
                        vaultId: vaultId,
                        fileId: fileId,
                        editAction: "delete"
                    };
                    await this.cardService.editCard<VaultCardDeleteItem>(threadId, vault, cardId, data).toPromise();
                } finally {
                    this.loader.hide();
                }
            });
    }

    private sortByLatest(vaultListItem: IVaultListItem[]): IVaultListItem[] {
        return vaultListItem.sort(
            (documentA, documentB) =>
                new Date(documentB.file.timestamp).getTime() - new Date(documentA.file.timestamp).getTime()
        );
    }
}
