import { Component, Inject, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";
import { ISlot } from "@findex/fx-ui/lib/components/calendar/calendar";
import { IMeetingReviewData } from "../calendar-review-meeting/calendar-review-meeting.component";
import {
    CreateInvitationCloseModalComponent,
    ICreateInvitationCloseModalData
} from "../calendar-meeting-request/close-modal/create-invitation-close-modal.component";
import { IMeetingRequestDetails } from "../create-invitation/create-invitation.component";
import { ICalendarParticipant, IParticipant, IThread, Role } from "@findex/threads";
import { CalendarService } from "../../services/calendar.service";
import { IInvitee } from "@findex/calendar-types";
import { map, take } from "rxjs/operators";
import {
    ButtonType,
    IStepConfiguration,
    MultiComponentLayoutComponent
} from "projects/portal-modules/src/lib/threads-ui/components/multi-component-layout/multi-component-layout.component";
import { AppUser, AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { MultiComponentService } from "projects/portal-modules/src/lib/threads-ui/components/multi-component-layout/multi-component.service";
import { CalendarCardService } from "../../services/calendar-card.service";
import { AnalyticsService, GA_EVENTS, HOT_JAR_EVENTS } from "projects/portal-modules/src/lib/analytics";

@Component({
    selector: "calendar-book-meeting",
    templateUrl: "./calendar-book-meeting.component.html",
    styleUrls: ["./calendar-book-meeting.component.scss"]
})
export class CalendarBookMeetingComponent {
    @ViewChild(MultiComponentLayoutComponent)
    multiComponentLayoutComponent: MultiComponentLayoutComponent;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: { thread: IThread },
        private dialogRef: MatDialogRef<CalendarBookMeetingComponent>,
        private dialog: MatDialog,
        private cardService: CalendarCardService,
        private calendarService: CalendarService,
        private authService: AuthService,
        private multiComponentService: MultiComponentService,
        private analytics: AnalyticsService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig
    ) {
        this.dialogRef.disableClose = true;
        this.participants = this.data.thread.participants;
        this.maxDate = new Date(Date.now() + 60 * 24 * 60 * 60 * 1000).toISOString();
        this.minDate = new Date().toISOString();
    }
    readonly theme = this.environment.theme;
    loader = new Loader();
    slots: ISlot[];
    minDate: string;
    maxDate: string;
    duration: number;
    meetingReviewData: IMeetingReviewData;
    activeStepIndex = 0;
    userConfirmedEmptyMeetingDescription = false;
    participants: IParticipant[] = [];
    organiser: { name: string; id: string };
    showAttendeeErrorMessage = false;
    meetingRequestData: IMeetingRequestDetails;
    user: AppUser;
    staffUserIds: string[];
    selectedSlot: ISlot;
    showValidationErrors = false;
    stepConfigurations: IStepConfiguration[] = [
        {
            stepIndex: 0,
            buttons: [
                {
                    title: "Next",
                    type: ButtonType.Forward,
                    isDisabled: false,
                    isHidden: false
                }
            ]
        },
        {
            stepIndex: 1,
            buttons: [
                {
                    title: "Meeting Details",
                    type: ButtonType.Backward,
                    isDisabled: false,
                    isHidden: true
                },
                {
                    title: "Next",
                    type: ButtonType.Forward,
                    isDisabled: true,
                    isHidden: true
                }
            ]
        },
        {
            stepIndex: 2,
            buttons: [
                {
                    title: "Calendar",
                    type: ButtonType.Backward,
                    isDisabled: false,
                    isHidden: true
                },
                {
                    title: "Yes, Book Meeting",
                    type: ButtonType.Finish,
                    isDisabled: false,
                    isHidden: true
                }
            ]
        }
    ];

    static validateMeetingDetailsEventBody(event: IMeetingRequestDetails): boolean {
        return !!(
            event.numberOfOccurrences >= 2 &&
            event.numberOfOccurrences <= 10 &&
            event.title &&
            event.attendees.length > 1 &&
            event.duration
        );
    }

    async monthChange(startDate: string | Date) {
        this.loader.show();
        this.slots = [];
        const slots = await this.calendarService
            .checkUserAvailability(
                this.staffUserIds,
                new Date(startDate).toISOString(),
                this.meetingRequestData.duration,
                this.data.thread.id
            )
            .toPromise();
        this.slots = slots?.slots || [];
        this.loader.hide();
    }

    async handleTransition(activeStepIndex: number) {
        if (!CalendarBookMeetingComponent.validateMeetingDetailsEventBody(this.meetingRequestData)) {
            this.showValidationErrors = true;
            this.stepConfigurations = this.multiComponentService.toggleForwardButtons(
                this.activeStepIndex,
                this.stepConfigurations,
                false
            );
            this.multiComponentLayoutComponent.selectPreviousStep(); // Stay on first step

            return;
        }

        const previousIndex = this.activeStepIndex;

        this.stepConfigurations = [
            ...this.multiComponentService.showCurrentStepButtons(activeStepIndex, this.stepConfigurations)
        ];
        this.activeStepIndex = activeStepIndex;
        this.setMeetingRequestData(this.meetingRequestData);

        switch (activeStepIndex) {
            case 0:
                this.userConfirmedEmptyMeetingDescription = false;
                return;
            case 1:
                if (!this.userConfirmedEmptyMeetingDescription) {
                    await this.confirmEmptyMeetingDescription();
                }
                if (this.userConfirmedEmptyMeetingDescription || this.meetingRequestData.meetingDescription) {
                    this.userConfirmedEmptyMeetingDescription = true;
                    if (this.staffUserIds && previousIndex < activeStepIndex) {
                        await this.monthChange(this.minDate);
                    }
                }
                return;
            case 2:
                if (!this.user) {
                    this.loader.show();
                    this.user = await this.getCurrentUser();
                    this.loader.hide();
                }
                const attendees: IInvitee[] = this.buildAttendees(this.meetingRequestData.attendees);
                this.meetingReviewData = this.buildMeetingReviewData(this.selectedSlot, attendees);
                return;
        }
    }

    private async getCurrentUser(): Promise<AppUser> {
        return await this.authService
            .getUser()
            .pipe(
                map(user => user),
                take(1)
            )
            .toPromise();
    }

    async handleTimeSelection(event: ISlot) {
        this.selectedSlot = event;
        this.stepConfigurations = [
            ...this.multiComponentService.toggleForwardButtons(this.activeStepIndex, this.stepConfigurations, true)
        ];
    }
    private buildAttendees(calendarParticipants: ICalendarParticipant[]) {
        return calendarParticipants.map(attendee => ({
            id: attendee.id,
            name: attendee.profile.name,
            email: attendee.profile.emailAddress,
            invitedBy: this.user.id,
            required: attendee.required
        }));
    }

    private buildMeetingReviewData(event: ISlot, attendees: IInvitee[]) {
        return {
            title: this.meetingRequestData.title,
            date: event,
            organiser: { name: this.user.name, id: this.user.id },
            message: this.meetingRequestData.meetingDescription,
            attendees,
            recurrence: {
                type: this.meetingRequestData.recurrenceType,
                numberOfOccurrences: this.meetingRequestData.numberOfOccurrences
            }
        };
    }

    async create() {
        this.analytics.recordEvent("calendar", GA_EVENTS.CALENDAR_CREATESCHEDULED);
        this.analytics.recordEvent("calendar", HOT_JAR_EVENTS.MeetingScheduleCompleteEvent);
        try {
            this.loader.show();
            await this.cardService
                .createBooked(
                    this.meetingReviewData.date.start,
                    this.meetingReviewData.date.end,
                    this.data.thread.id,
                    this.meetingRequestData.title,
                    this.meetingRequestData.duration,
                    this.meetingRequestData.attendees,
                    this.meetingRequestData.recurrenceType,
                    this.meetingRequestData.numberOfOccurrences,
                    this.meetingRequestData.meetingDescription
                )
                .toPromise();
            this.dialogRef.close();
        } finally {
            this.loader.hide();
        }
    }

    async close() {
        const data: ICreateInvitationCloseModalData = {
            acceptButtonName: "Discard",
            rejectButtonName: "Keep Editing",
            confirmDescription: "Are you sure you want to discard this meeting?",
            confirmTitle: "Discard meeting request"
        };

        const confirmClose = await this.confirmClose(data);

        if (confirmClose) {
            this.analytics.recordEvent("calendar", HOT_JAR_EVENTS.MeetingScheduleCloseEvent);
            this.dialogRef.close();
        }
    }

    private async confirmEmptyMeetingDescription() {
        if (!this.meetingRequestData.meetingDescription) {
            const data: ICreateInvitationCloseModalData = {
                acceptButtonName: "Yes",
                rejectButtonName: "No",
                confirmDescription: "Are you sure you want to continue this meeting request without a description?",
                confirmTitle: "Missing description"
            };
            const shouldContinue = await this.confirmClose(data);
            if (!shouldContinue) {
                this.activeStepIndex = 0;
                this.userConfirmedEmptyMeetingDescription = false;
                this.multiComponentLayoutComponent.selectPreviousStep();
                return;
            }
        }
        this.userConfirmedEmptyMeetingDescription = true;
    }

    private async setMeetingRequestData(event: IMeetingRequestDetails) {
        this.duration = event.duration;
        this.staffUserIds = event.attendees
            .filter(attendee => attendee.role !== Role.Client && attendee.required)
            .map(attendee => attendee.id);
    }

    async handleMeetingDetailsUpdated(event: IMeetingRequestDetails) {
        this.meetingRequestData = event;

        if (CalendarBookMeetingComponent.validateMeetingDetailsEventBody(event)) {
            this.stepConfigurations = [
                ...this.multiComponentService.toggleForwardButtons(this.activeStepIndex, this.stepConfigurations, true)
            ];
        }
    }

    private async confirmClose(data: ICreateInvitationCloseModalData) {
        const config = {
            data,
            panelClass: ["threads-sidebar", "threads-sidebar-inner-close-modal"],
            autoFocus: true,
            maxWidth: "100vw"
        };

        return await this.dialog
            .open(CreateInvitationCloseModalComponent, config)
            .afterClosed()
            .toPromise();
    }
}
