import { Component, Inject, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Output } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { SignUpUserPayload } from "../../../services/onboarding.service";
import { EnvironmentSpecificConfig } from "../../../../environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";

@Component({
    selector: "app-signup",
    templateUrl: "./signup.component.html",
    styleUrls: ["./signup.component.scss"]
})
export class SignUpComponent implements OnInit {
    form = new FormGroup({
        givenName: new FormControl(null, Validators.required),
        familyName: new FormControl(null, Validators.required),
        emailAddress: new FormControl(null, [Validators.required, Validators.email]),
        agreedToTermsAndConditions: new FormControl(false, Validators.required)
    });

    policyUrl = this.environment.policyUrl;
    termsUrl = this.environment.termsUrl;
    termsOfUseUrl = this.environment.termsOfUseUrl;
    disclaimerUrl = this.environment.disclaimerUrl;
    readonly textResource = this.environment.featureFlags.text;
    readonly appName = this.environment.appName;
    readonly theme = this.environment.theme;

    @Input() userDataModel: SignUpUserPayload;
    @Input() errorMessage: string;
    @Output() progress: EventEmitter<SignUpUserPayload> = new EventEmitter();

    constructor(@Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig) {}

    ngOnInit() {
        this.form.setValue({
            familyName: this.userDataModel.familyName,
            givenName: this.userDataModel.givenName,
            emailAddress: this.userDataModel.emailAddress,
            agreedToTermsAndConditions: false
        });
    }

    updateParentModel() {
        this.progress.emit(this.form.value);
    }
}
