import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { AuthService } from "./auth.service";

@Injectable({ providedIn: "root" })
export class LoggedInGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        return this.authService.getUserWithoutRole().pipe(
            switchMap(user => {
                if (user) {
                    const redirectUrl = this.getRedirectUrl(route);
                    this.router.navigateByUrl(redirectUrl);
                    return of(false);
                } else {
                    return of(true);
                }
            })
        );
    }

    private getRedirectUrl(route: ActivatedRouteSnapshot): string {
        if (route.queryParams && route.queryParams.redirect) {
            return route.queryParams.redirect;
        }

        // redirect to dashboard for all logged in users
        return "/dashboard";
    }
}
