<ng-container *ngIf="card$ | async; let card">

    <app-card
        *ngIf="card.status === cardStatuses.Removed"
        [lineCard]="card.status === cardStatuses.Removed"
        [description]="'This message has been removed.'"
        [timestamp]="card.modifiedAt"
    >
    </app-card>

    <thread-card
        *ngIf="card.status !== cardStatuses.Removed && userId$ | async; let userId"
        title="Message"
        [thread]="thread$ | async"
        [card]="card$ | async"
        [replies]="replies$ | async"
        [invertTheme]="userId === card.createdBy"
        [createdById]="card.createdBy"
        [loading]="loading"
        [edited]="card.status === cardStatuses.Edited"
        [edit]="edit$"
        (save)="save($event)"
    >
        <div card-actions *ngIf="allowEdit &&  (thread$ | async | isThreadActive | async) ">
            <fx-context-menu #menu [gutter]="false" *ngIf="card.createdBy === userId">
                <a (click)="editMessage()">Edit</a>
                <a (click)="removeMessage()">Delete</a>
            </fx-context-menu>
        </div>
        <div card-loader>
            <loader-icon [color]="'light'"></loader-icon>
        </div>
        <div class="thread-card-error" *ngIf="errorMessage">
            {{ errorMessage }}
        </div>
    </thread-card>
</ng-container>
