import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ToastConfig, FxNotifierComponent } from "./fx-notifier.component";

/**
 * Service in charge of handling toast notifications as a simpler way to open the toast
 */
@Injectable()
export class FxNotifierService {
    constructor(
        public snack: MatSnackBar
    ) { }

    openToast(config: ToastConfig) {
        return this.snack.openFromComponent(FxNotifierComponent, {
            data: config,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: ["fx-snackbar", "fx-snackbar-info"],

        })
    }
}