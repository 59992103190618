import { Injector } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { CardTaskActionLabel, SubjectType } from "@findex/threads";
import { DateTime } from "luxon";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { TaskAction } from "projects/portal-modules/src/lib/plugins/services/Libraries";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { CardResources } from "projects/portal-modules/src/lib/threads-ui/interfaces/IUiCard";
import { of } from "rxjs";
import { mergeMap, take } from "rxjs/operators";
import { CalendarModalComponent, ICalendarModalData } from "./components/calendar-modal/calendar-modal.component";
import { CalendarService, ICalendarAvailability, ISlot } from "./services/calendar.service";

const meetingAction = (reschedule?: boolean) => async ({ card$ }: Partial<CardResources>, injector: Injector) => {
    const matDialog = injector.get(MatDialog);
    const calendarService = injector.get(CalendarService);
    const loader = injector.get(Loader);

    loader.show();
    const card = await card$.pipe(take(1)).toPromise();

    const calendarSubjects = card.subjects.filter(cardSubject => cardSubject.type === SubjectType.Calendar);
    if (calendarSubjects.length !== 1) {
        console.error("Did not find exactly 1 calendar subject", calendarSubjects);
        return null;
    }

    const invitationId = calendarSubjects.slice(0, 1).pop()?.id;

    const data: ICalendarModalData = {
        card,
        reschedule,
        invitationId
    };
    const options = {
        disableClose: false,
        backdropClass: "modal-backdrop",
        panelClass: ["threads-sidebar", "threads-sidebar--large", "mat-dialog-no-styling"],
        closeOnNavigation: true,
        maxWidth: "100%",
        maxHeight: "100%",
        minHeight: "100%",
        height: "100vh",
        data
    };

    const slot = await matDialog
        .open<CalendarModalComponent, ICalendarModalData, ISlot>(CalendarModalComponent, options)
        .afterClosed()
        .pipe(take(1))
        .toPromise();

    if (slot) {
        await calendarService
            .checkAvailability(invitationId, slot.start)
            .pipe(
                mergeMap((checkAvailabilityResponse: ICalendarAvailability) => {
                    const startDate = DateTime.fromISO(slot.start);
                    const slotIsAvailable = checkAvailabilityResponse.slots.find(
                        (currentSlot: ISlot) => DateTime.fromISO(currentSlot.start).toMillis() === startDate.toMillis()
                    );
                    if (slotIsAvailable) {
                        return calendarService.setAppointment(invitationId, slot.start, slot.end);
                    }
                    return of({});
                })
            )
            .toPromise();
    }

    loader.hide();

    return slot;
};

export const calendarScheduleTaskAction: TaskAction<ISlot> = {
    analyticsEvents: [GA_EVENTS.APP_VIEWAVAILABILITY, GA_EVENTS.CALENDAR_OPENSCHEDULING],
    cardTypes: ["calendar"],
    action: meetingAction(false),
    buttonLabel: CardTaskActionLabel.ScheduleMeeting,
    statusIcon: "las la-calendar"
};

export const calendarRescheduleTaskAction: TaskAction<ISlot> = {
    analyticsEvents: [GA_EVENTS.CALENDAR_RESCHEDULE],
    cardTypes: ["calendar"],
    action: meetingAction(true),
    buttonLabel: CardTaskActionLabel.RescheduleMeeting,
    statusIcon: "las la-calendar"
};
