<form *ngIf="updated" (submit)="save($event, updated)" class="fx-form fx-form--dark">
    <div class="fx-form-group">
        <label class="fx-form-label">Service</label>
        <div class="fx-form-control">
            <select class="fx-form-select" (change)="updated.packageId = $event.target.value">
                <option
                    *ngFor="let package of packages"
                    [value]="package.packageId"
                    [selected]="package.packageId === updated.packageId"
                    >{{ package.label }}</option
                >
            </select>
        </div>
    </div>

    <threads-input
        [value]="updated.taxFileNumber"
        (currentValue)="updated.taxFileNumber = $event"
        placeholder="eg 012 3456 8756"
        >TFN</threads-input
    >
    <threads-input
        [value]="updated.bankName"
        (currentValue)="updated.bankName = $event"
        placeholder="eg Commonwealth Bank"
        >Bank</threads-input
    >
    <threads-input [value]="updated.accountName" (currentValue)="updated.accountName = $event" placeholder="eg John Doe"
        >Account Name</threads-input
    >

    <div class="half-inputs">
        <threads-input
            [value]="updated.accountBsb"
            [errorMessage]="updated.accountBsb.length !== 6 ? 'BSB must be 6 characters' : null"
            (currentValue)="updated.accountBsb = $event"
            placeholder="eg 123456"
            >BSB</threads-input
        >
        <threads-input
            [value]="updated.accountNumber"
            (currentValue)="updated.accountNumber = $event"
            placeholder="eg 012344567"
            >Account Number</threads-input
        >
    </div>

    <button
        class="fx-btn fx-btn--primary-outlined fx-btn--full-width"
        [disabled]="updated.accountBsb.length !== 6 || enableButton(updated)"
    >
        <i class="las la-lock"></i> SAVE AND CLOSE
    </button>
</form>
