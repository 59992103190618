import { Inject, Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ENVIRONMENT } from "../../../../src/app/injection-token";
import {
    environmentCommon,
    EnvironmentSpecificConfig
} from "../../../portal-modules/src/lib/environment/environment.common";
import { IFileUploadResponse, IUpdatedRequestItems, IRequestItem, IRequestTextData } from "@findex/threads";
import { VaultService } from "@findex/vault";
import { of, Observable } from "rxjs";

type RequestProgress = {
    actionedPercentage: number;
    numActionedRequestItems: number;
    requestItemTextResponses: number;
    requestItemUploadResponses: number;
};

@Injectable({ providedIn: "root" })
export class VaultRequestService {
    constructor(
        private vaultService: VaultService,
        private http: HttpClient,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig
    ) {}

    async uploadRequestAttachments(
        threadId: string,
        cardId: string,
        vaultId: string,
        fileId: string,
        attachments: File[]
    ): Promise<void> {
        if (!attachments?.length) {
            return null;
        }

        const filenames = attachments.map(file => file.name);
        const attachmentUploadResponse = await this.addRequestAttachments(
            threadId,
            cardId,
            vaultId,
            fileId,
            filenames
        ).toPromise();
        const signedFiles = attachments.map(attachment => ({
            file: attachment,
            signedUrl: attachmentUploadResponse.find(uploadResponse => uploadResponse.filename === attachment.name)
                .signedRequest
        }));
        await Promise.all(
            signedFiles.map(signedFile => this.vaultService.upload(signedFile.signedUrl, signedFile.file).toPromise())
        );
    }

    private addRequestAttachments(
        threadId: string,
        cardId: string,
        vaultId: string,
        fileId: string,
        filenames: string[]
    ): Observable<IFileUploadResponse[]> {
        if (!filenames?.length) {
            return of(null);
        }
        const { threads } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.commonEndpoints;
        const url = `${base}${threads}/${threadId}/cards/vault-request/${cardId}/vault/${vaultId}/request/attachment`;
        const body = { fileId, filenames };
        return this.http.post<IFileUploadResponse[]>(url, body);
    }
    addRequestItems(
        threadId: string,
        cardId: string,
        vaultId: string,
        requestItems: { description: string }[]
    ): Observable<void> {
        if (!requestItems?.length) {
            return of(null);
        }
        const { threads } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.commonEndpoints;
        const url = `${base}${threads}/${threadId}/cards/vault-request/${cardId}/vault/${vaultId}/request/item`;
        const body = { requestItems };
        return this.http.post<void>(url, body);
    }
    deleteRequestFiles(
        threadId: string,
        cardId: string,
        vaultId: string,
        files: { fileId: string; filenames: string[] }[]
    ): Observable<void> {
        if (!files?.length) {
            return of(null);
        }
        const { threads } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.commonEndpoints;
        const url = `${base}${threads}/${threadId}/cards/vault-request/${cardId}/vault/${vaultId}/request/files`;
        const options = {
            headers: new HttpHeaders({
                "Content-Type": "application/json"
            }),
            body: { files }
        };
        return this.http.delete<void>(url, options);
    }
    removeRequestItems(threadId: string, cardId: string, vaultId: string, fileIds: string[]): Observable<void> {
        if (!fileIds?.length) {
            return of(null);
        }
        const { threads } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.commonEndpoints;
        const url = `${base}${threads}/${threadId}/cards/vault-request/${cardId}/vault/${vaultId}/request/item`;
        const options = {
            headers: new HttpHeaders({
                "Content-Type": "application/json"
            }),
            body: { fileIds }
        };
        return this.http.delete<void>(url, options);
    }

    async uploadRequestResponse(
        threadId: string,
        cardId: string,
        vaultId: string,
        updatedRequestItems: IUpdatedRequestItems,
        requestTitle?: string,
        file?: File
    ) {
        if (file) {
            await this.vaultService.uploadRfi(vaultId, updatedRequestItems.fileId, file).toPromise();
        }
        await this.updateRequestItems(
            threadId,
            cardId,
            vaultId,
            [updatedRequestItems],
            requestTitle
        ).toPromise();
    }

    updateRequestItems(
        threadId: string,
        cardId: string,
        vaultId: string,
        updatedRequestItems: IUpdatedRequestItems[],
        requestTitle?: string
    ): Observable<IFileUploadResponse[]> {
        if (!updatedRequestItems?.length && !requestTitle) {
            return of(null);
        }
        const { threads } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.commonEndpoints;
        const url = `${base}${threads}/${threadId}/cards/vault-request/${cardId}/vault/${vaultId}/request/item`;
        const body = { updatedRequestData: { requestItems: updatedRequestItems || [], requestTitle } };
        return this.http.put<IFileUploadResponse[]>(url, body);
    }

    async downloadFile(vaultId: string, fileId: string, filename: string) {
        window.open("", "_self").location.href = await this.vaultService
            .getDownloadUrl(vaultId, fileId, filename)
            .toPromise();
    }
    static isRequestComplete(requestItems: IRequestItem[]): boolean {
        return !requestItems.filter(requestItem => requestItem.response.complete.state === false).length;
    }

    static calculateProgress(requestItems: IRequestItem[]): RequestProgress {
        const numRequestItems = requestItems?.length;

        if (numRequestItems) {
            const numActionedRequestItems = requestItems.filter(requestItem => requestItem.response?.complete?.state)
                .length;
            const requestItemTextResponses = requestItems.reduce((acc, curr) => {
                const comments = curr.response?.text?.state;
                if(!Array.isArray(comments)) return acc + 0;
                return acc + this.getCommentCount(comments);
            }, 0)
            const requestItemUploadResponses = requestItems.filter(item => item.response?.data?.state?.length).length;

            const actionedPercentage = Math.floor((numActionedRequestItems / numRequestItems) * 100);

            return {
                actionedPercentage,
                numActionedRequestItems,
                requestItemTextResponses,
                requestItemUploadResponses
            };
        } else {
            return {
                actionedPercentage: 0,
                numActionedRequestItems: 0,
                requestItemTextResponses: 0,
                requestItemUploadResponses: 0
            };
        }
    }

    private static getCommentCount(comments: IRequestTextData[]): number {
        const validComments = comments.filter(textResponse => {
            if(textResponse.textinput) {
                return textResponse.textinput.length > 0
            }
            return false;
        });

        return validComments.length;
    }
}
