import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatDialogModule } from "@angular/material/dialog";
import { FxUiModule } from "@findex/fx-ui";
import { VAULT_BASE, VaultModule as VaultSdkModule } from "@findex/vault";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatInputModule } from "@angular/material/input";

import { VaultCardModule } from "./components/vault-card/vault-card.module";
import { DocumentListComponent } from "./components/document-list/document-list.component";
import { UploadComponent } from "./components/upload/upload.component";
import { UploadItemComponent } from "./components/upload/upload-item/upload-item.component";
import { UploadCancelModalComponent } from "./components/upload/upload-cancel-modal/upload-cancel-modal.component";
import { SigningModalComponent } from "./components/signing-modal/signing-modal.component";
import { FilePreviewComponent } from "./components/file-preview/file-preview.component";
import { PdfPreviewComponent } from "./components/pdf-preview/pdf-preview.component";
import { HtmlPreviewComponent } from "./components/html-preview/html-preview.component";
import { CompleteRfiModalComponent } from "./components/upload/complete-rfi-modal/complete-rfi-modal.component";

import { CreateRequestComponent } from "./components/request/create-request/create-request.component";
import { EditRequestComponent } from "./components/request/edit-request/edit-request.component";
import { ActionRequestComponent } from "./components/request/action-request/action-request.component";
import { RequestTodoActionsComponent } from "./components/request/request-todo-actions/request-todo-actions.component";
import { RequestCommonComponent } from "./components/request/request-common.component";

import { IncludesPipe } from "./pipes/includes.pipe";
import { DataInputComponent } from "./components/data-input/data-input.component";
import { QuillModule } from "ngx-quill";
import { DocumentPreviewModalComponent } from "./components/document-preview/document-preview.component";
import { RouterModule } from "@angular/router";
import { AnalyticsModule } from "projects/portal-modules/src/lib/analytics";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { FindexAuthModule } from "projects/portal-modules/src/lib/findex-auth";
import { FindexUiModule } from "projects/portal-modules/src/lib/findex-ui";
import { SharedModule } from "projects/portal-modules/src/lib/shared";
import { ThreadsUiModule } from "projects/portal-modules/src/lib/threads-ui";
import { ENVIRONMENT } from "src/app/injection-token";
import { PLUGIN_FACTORY } from "projects/portal-modules/src/lib/plugins";
import { VaultPlugin } from "./vault.plugin";
import { FxTableModule } from "../../portal-modules/src/lib/fx-table/fx-table.module";
import { VaultSignFocusComponent } from "./components/vault-sign-focus/vault-sign-focus.component";
import { FileInputModule } from "./components/file-input/file-input.module";
import { InlineEditModule } from "./components/inline-edit/inline-edit.module";
import { FxTodoListModule } from "projects/portal-modules/src/lib/fx-todolist/fx-todolist.module";
import { RequestResponseComponent } from "./components/request/request-response/request-response";
import { RequestListComponent } from "./components/request-list/request-list.component";
import { MessageAttachModalComponent } from "./components/message-attach-modal/message-attach-modal.component";
import { TextFieldModule } from "@angular/cdk/text-field";
import { ReopenRequestModalComponent } from "./components/request/reopen-request/reopen-request-modal.component";
import { PreCreateRequestComponent } from "./components/request/pre-create-request/pre-create-request.component";
import { VaultRoutingModule } from "./vault-routing.module";
import { VaultListRouteComponent } from "./components/vault-list-route/vault-list-route.component";
import { VaultListTableComponent } from "./components/vault-list-table/vault-list-table.component";
import { ConfirmDeleteDialogComponent } from "./components/confirm-delete-dialog/confirm-delete-dialog.component";
import { SharedPipesModule } from "../../portal-modules/src/lib/shared/pipes/shared-pipes.module";
import { RequestResponseFormComponent } from './components/request/request-response-form/request-response-form.component';

@NgModule({
    imports: [
        CommonModule,
        VaultSdkModule,
        MatDialogModule,
        FormsModule,
        MatDatepickerModule,
        MatInputModule,
        MatNativeDateModule,
        ReactiveFormsModule,
        FindexAuthModule,
        FxUiModule,
        ThreadsUiModule,
        FindexUiModule,
        AnalyticsModule,
        SharedModule,
        QuillModule,
        RouterModule,
        FxTableModule,
        VaultCardModule,
        FileInputModule,
        InlineEditModule,
        FxTodoListModule,
        TextFieldModule,
        VaultRoutingModule,
        SharedPipesModule
    ],
    exports: [
        PdfPreviewComponent,
        FilePreviewComponent,
        CreateRequestComponent,
        EditRequestComponent,
        ActionRequestComponent,
        RequestTodoActionsComponent,
        RequestCommonComponent,
        HtmlPreviewComponent,
        MessageAttachModalComponent,
        ReopenRequestModalComponent,
        PreCreateRequestComponent,
        VaultListRouteComponent,
        VaultListTableComponent,
        ConfirmDeleteDialogComponent
    ],
    declarations: [
        RequestListComponent,
        RequestResponseComponent,
        UploadComponent,
        UploadItemComponent,
        UploadCancelModalComponent,
        IncludesPipe,
        SigningModalComponent,
        CompleteRfiModalComponent,
        DataInputComponent,
        DocumentPreviewModalComponent,
        FilePreviewComponent,
        PdfPreviewComponent,
        DocumentListComponent,
        VaultSignFocusComponent,
        CreateRequestComponent,
        EditRequestComponent,
        ActionRequestComponent,
        RequestTodoActionsComponent,
        RequestCommonComponent,
        HtmlPreviewComponent,
        MessageAttachModalComponent,
        ReopenRequestModalComponent,
        PreCreateRequestComponent,
        VaultListRouteComponent,
        VaultListTableComponent,
        ConfirmDeleteDialogComponent,
        RequestResponseFormComponent
    ],
    providers: [
        {
            provide: VAULT_BASE,
            useFactory: (environment: EnvironmentSpecificConfig) => environment.vaultEndpoints.base,
            deps: [ENVIRONMENT]
        },
        { provide: PLUGIN_FACTORY, useClass: VaultPlugin, multi: true }
    ]
})
export class VaultModule {}
