/* eslint-disable no-console */
import { Pipe, PipeTransform } from "@angular/core";
import { DateTime } from "luxon";

@Pipe({
    name: "timeOverDue",
    pure: true
})
export class TimeOverDuePipe implements PipeTransform {
    transform(date: string): string {
        const dueDate = DateTime.fromISO(date);
        if (!dueDate.isValid) return "";
        
        const isOverdue = this.isDateOverdue(dueDate);
        if (isOverdue) return this.sentenceCase("overdue");
        
        return this.sentenceCase(this.toRelativeTime(dueDate));
    }
    
    isDateOverdue(date: DateTime): boolean {
        const relativeTimeInDays = date.toRelative({ unit:"days" });
        if (!date.hasSame(DateTime.now(),"day") && relativeTimeInDays.includes("ago")) 
            return true;
        return false; 
    }

    toRelativeTime(date:DateTime): string {
        const relativeTimeTomorrow = date.toRelativeCalendar();
        if (relativeTimeTomorrow.includes("tomorrow")) 
            return  "Tomorrow";
        if (date.hasSame(DateTime.now(),"day")) 
            return "Today";
        return date.toRelative({ unit:"days" });
    }

    sentenceCase(date: string): string {
        return date[0].toUpperCase() + date.substring(1);
    }
    
}
