<div class="avatar-pill">
    <fx-avatar [name]="name" [image]="image" [inverted]="inverted" [border]="avatarBorder"></fx-avatar>
    <div class="avatar-pill-name">
        {{ name }}
    </div>
    <div class="avatar-pill-detail" *ngIf="detailText">
        {{ detailText }}
    </div>
    <div class="fx-l-modal-header-icon avatar-pill-icon" *ngIf="!disabled" (click)="crossClicked.emit(id)">
        <i class="las la-times-circle avatar-pill-icon-size"></i>
    </div>
</div>
