import { Component, Input } from "@angular/core";
import { IThread, IThreadWorkflowState, Role } from "@findex/threads";
import { ThreadStatus } from "../status-badge/status-badge.component";

@Component({
    selector: "thread-workflow-status",
    templateUrl: "./thread-workflow-status.component.html",
    styleUrls: ["./thread-workflow-status.component.scss"]
})
export class ThreadWorkflowStatusComponent {
    @Input() workflowState: IThreadWorkflowState;
    @Input() enablePopUp = false;
    @Input() small = false;
    @Input() role: Role;
    @Input() thread: IThread;
    @Input() shouldShowTitle?: boolean;
    @Input() fullWidth: boolean;

    ThreadStatus = ThreadStatus;
}
