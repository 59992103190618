import { Pipe, PipeTransform } from "@angular/core";
import { VaultRequestRow } from "../services/threads-vault.service";

@Pipe({ name: "reportRequestSearch" })
export class ReportRequestSearchPipe implements PipeTransform {
    constructor() {}

    transform(requests: VaultRequestRow[], searchTerm: string): VaultRequestRow[] {
        if (!searchTerm || !requests) return requests;

        const requestsResponse = requests.filter(request => {
            const {title, status} = request;
            return title.toLowerCase().includes(searchTerm) ||
                status.toLowerCase().includes(searchTerm);
        });
        return  requestsResponse;
    }
}
