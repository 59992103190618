import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
    CardReply,
    IInviteUsersResponse,
    IParticipant,
    IThread,
    IUserSetupRequest,
    Role,
    ThreadTypes,
    ParticipantType,
    SubjectType,
    IThreadUnresolvedNotifications,
    IThreadListing,
    IThreadWorkflowState,
    IWorkflowTimeEstimate
} from "@findex/threads";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { IUserDataService } from "../interfaces/IUserDataService";
import { environmentCommon, EnvironmentSpecificConfig } from "../../environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";
import { PortalService } from "../../shared/services/portal.service";
import { ThreadCardService } from "./thread-card.service";
import { WorkflowService } from "./workflow.service";

//move to sdk
export type CardStateResponse<StateType> = { state?: StateType; cardReplies: CardReply[] };

@Injectable({ providedIn: "root" })
export class ThreadsService implements IUserDataService {
    constructor(
        private http: HttpClient,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private portalService: PortalService,
        private workflowService: WorkflowService,
        private cardsService: ThreadCardService
    ) {}

    createThread(
        type: ThreadTypes,
        subType: string,
        title: string,
        participants: IParticipant[],
        workflowProvider: string,
        workflowId: string,
        accountId: string,
        initialMessage?: string,
        timeEstimates?: Record<string, IWorkflowTimeEstimate>
    ): Observable<IThread> {
        const { threads } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${threads}`;
        const body = { type, subType, title, participants, workflowProvider, workflowId, accountId, initialMessage, timeEstimates };
        return this.http.post<IThread>(url, body);
    }

    updateThread(threadId: string, updates: Partial<Pick<IThread, "title" | "notes" | "workflow" | "type" | "subType">>): Observable<void> {
        const { threads } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${threads}/${threadId}`;
        return this.http.put<void>(url, updates);
    }


    setActiveWorkflowStep(threadId: string, clientFacingId: string): Observable<IThreadWorkflowState> {
        const { threads, workflow, steps } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${threads}/${threadId}${workflow}${steps}/${clientFacingId}`;
        return this.http.put<IThreadWorkflowState>(url, {});
    }

    updateTimeEstimates(threadId: string, timeEstimates: Record<string, IWorkflowTimeEstimate>): Observable<IThreadWorkflowState> {
        const { threads, workflow, timeestimates } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${threads}/${threadId}${workflow}${timeestimates}`;
        return this.http.put<IThreadWorkflowState>(url, { timeEstimates });
    }

    getGlobalRole(participantId: string): Observable<Role> {
        const { participants, role: rolePath } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${participants}/${participantId}${rolePath}`;
        return this.http.get<{ role: Role }>(url).pipe(map(({ role }) => role));
    }

    putGlobalRole(participantId: string, roleToPut: Role): Observable<Role> {
        const { participants, role: rolePath } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${participants}/${participantId}${rolePath}`;
        return this.http
            .put<{ role: Role }>(url, { role: roleToPut })
            .pipe(map(({ role }) => role));
    }

    getRole(threadId: string, participantId: string): Observable<Role> {
        const { participants, threads, role: rolePath } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${threads}/${threadId}${participants}/${participantId}${rolePath}`;
        return this.http.get<{ role: Role }>(url).pipe(map(({ role }) => role));
    }

    addParticipant(threadId: string, participantId: string, role: Role, type: ParticipantType) {
        const { threads, participants } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${threads}/${threadId}${participants}/${participantId}`;
        return this.http.post(url, { role, type });
    }

    removeParticipant(threadId: string, participantId: string) {
        const { threads, participants } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${threads}/${threadId}${participants}/${participantId}`;
        return this.http.delete(url);
    }

    getThread(threadId: string): Observable<IThread> {
        const { threads } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${threads}/${threadId}`;
        return this.http.get<IThread>(url).pipe(
            map((thread: IThread) => {
                const mappedParticipants = thread.participants.map((participant: IParticipant) =>
                    this.mapParticipant(participant)
                );
                return {
                    ...thread,
                    participants: mappedParticipants
                };
            })
        );
    }

    getUnresolvedNotifications(threadId: string): Observable<IThreadUnresolvedNotifications> {
        const { notifications } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${notifications}/threads/${threadId}/unresolved`;
        return this.http.get<IThreadUnresolvedNotifications>(url);
    }

    getUserData(participantId: string): Observable<any> {
        const { participants, data } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${participants}/${participantId}${data}`;
        return this.http.get<any>(url);
    }

    putUserData(participantId: string, body: any): Observable<void> {
        const { participants, data } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${participants}/${participantId}${data}`;
        return this.http.put<void>(url, body);
    }

    removeTask(taskId: string): Observable<void> {
        const { tasks } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${tasks}/${taskId}`;
        return this.http.delete<void>(url);
    }

    getParticipants(participantIds: string[]): Observable<IParticipant[]> {
        const { participants } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${participants}`;
        return this.http.post<IParticipant[]>(url, { participantIds });
    }

    sendClientInvites(userSetupRequest: IUserSetupRequest) {
        const { inviteParticipants } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${inviteParticipants}`.replace(":threadId", userSetupRequest.threadId);
        return this.http.post<IInviteUsersResponse[]>(url, userSetupRequest);
    }

    updateUserTimeZone(userId: string): Observable<void> {
        return this.putUserData(userId, {
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
        });
    }

    mapParticipant(participant: IParticipant) {
        const staffTitleOverride = this.environment.featureFlags.temporaryFeatureFlags.staffTitleOverride;
        if (staffTitleOverride) {
            if (participant.profile && participant.profile.title) {
                return {
                    ...participant,
                    profile: {
                        ...participant.profile,
                        title: staffTitleOverride
                    }
                };
            }
        }
        return participant;
    }

    async getActiveThreadsByUserId(userId: string): Promise<IThread[]> {
        //TODO: should not need to do this anymore, should be able to use the same call as getting the timelines
        //Not refactoring now as this I'm just moving things around

        const dashboardId = await this.portalService.getDashboard(userId).toPromise();
        if (!dashboardId) throw new Error("User does not have a dashboard. Have they signed up?");

        const cards = await this.cardsService.getCards(dashboardId).toPromise();
        const dashboardCard = cards.find(card => card.type === SubjectType.Thread);

        const threadIds = dashboardCard.subjects
            .filter(subject => subject.type === SubjectType.Thread)
            .map(subject => subject.id);

        const threadDetails$ = threadIds.map(async id => {
            const thread = await this.getThread(id).toPromise();
            const currentWorkflowStep = this.workflowService.getCurrentStep(thread.workflow);
            const isActive = this.workflowService.isStepOpen(currentWorkflowStep);
            return { thread, isActive };
        });
        const threadDetails = await Promise.all(threadDetails$);

        return threadDetails.filter(threadDetail => threadDetail.isActive).map(threadDetail => threadDetail.thread);
    }

    async saveWorkflowState(thread: IThread, clientFacingId: string): Promise<IThread> {
        const workflowTemplate = await this.workflowService.getWorkflow(thread.workflow.provider, thread.workflow.id);
        const stepTemplate = workflowTemplate.steps.find(step => step.clientFacingId === clientFacingId);
        const shouldHandleActions = await this.workflowService.handleActions(thread, stepTemplate);

        if (shouldHandleActions) {
            const workflow = await this.setActiveWorkflowStep(thread.id, clientFacingId).toPromise();

            const updatedThread = {
                ...thread,
                workflow
            };

            return updatedThread;
        } else {
            return thread;
        }
    }

    async isThreadActive(thread: IThread | IThreadListing) {
        if (!thread) {
            return true;
        }

        const currentStep = this.workflowService.getCurrentStep(thread.workflow);
        return this.workflowService.isStepOpen(currentStep);
    }

    getThreadTypes(): Observable<Record<string, string>> {
        const { timelineGroups } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.commonEndpoints;
        const url = `${base}${timelineGroups}`;
        return this.http.get<Record<string, string>>(url);
    }
}
