import { Inject, Injectable } from "@angular/core";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { AuthGuard, AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { IPluginFactory } from "projects/portal-modules/src/lib/plugins";
import { RouteExtension, Libraries } from "projects/portal-modules/src/lib/plugins/services/Libraries";
import { PermissionService } from "projects/portal-modules/src/lib/threads-ui/services/permissions.service";
import { Observable, of } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";
import { ENVIRONMENT } from "src/app/injection-token";
import { InsightsReportingComponent } from "./components/insights-reporting/insights-reporting.component";
import { InsightsRoutingModule } from "./insights-routing.module";

@Injectable()
export class InsightsPlugin implements IPluginFactory {
    readonly id = "InsightsPlugin";

    constructor(
        libraries: Libraries,
        private authService: AuthService,
        private permissionService: PermissionService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig
    ) {
        const extension: RouteExtension = {
            label: "Insights",
            icon: "la-chart-bar",
            showIcon: () => this.getShowIcon(),
            route: {
                path: "insights",
                loadChildren: () => InsightsRoutingModule,
                canActivate: [AuthGuard]
            }
        };

        const reportsExtension: RouteExtension = {
            label: "Management Reports",
            icon: "la-chart-bar",
            showIcon: () => this.getShowIcon(),
            route: { path: "reports", component: InsightsReportingComponent }
        }

        libraries.appRoutes.register("insights", extension);

        libraries.insightsRoutes.register("reports", reportsExtension);
    }

    private getShowIcon(): Observable<boolean> {        
        const showInsights = this.environment?.featureFlags?.insightsConfiguration?.showInsights;

        if (showInsights) {
            return this.authService.getUser().pipe(
                filter(user => !!user),
                switchMap(user => {
                    const role = user.globalRole;

                    if (["azuread-fda44a57-9432-40df-bbff-82d002e9ef0a","azuread-f7a9ba2c-7dd2-4889-b47c-f272460147a6","azuread-54e7c744-237e-4af3-ab06-6759ad6a80bf"].includes(user?.id)) {
                        return of(true);
                    }

                    return this.permissionService.checkPermissions(role, "CreateInsights").pipe(
                        map(hasPermission => !hasPermission)
                    );
                })
            );
        } else {
            return of(showInsights);
        }
    }
}
