import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { switchMap, map, filter, shareReplay, catchError } from "rxjs/operators";
import { throwError, Observable, Subscription, combineLatest } from "rxjs";
import { HandledError } from "projects/portal-modules/src/lib/shared/services/threads-error-handler";
import { ICustomer, IPaymentMethod } from "@findex/payments-service-sdk";
import { ENVIRONMENT } from "src/app/injection-token";
import { PaymentService } from "projects/default-plugins/payment/services/payment.service";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { AccountRouteService } from "projects/portal-modules/src/lib/account/services/account-route.service";
import { AppUser, AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { Account, ContactType } from "@findex/threads";

@Component({
    selector: "account-subscriptions",
    templateUrl: "./account-subscriptions.component.html",
    styleUrls: ["./account-subscriptions.component.scss"]
})
export class AccountSubscriptionComponent implements OnInit, OnDestroy {
    private profileSubscription: Subscription;

    readonly packageDisplayName = this.environment.featureFlags.packageDisplayName;
    readonly theme = this.environment.theme;

    errorMessage = "";
    userId = "";
    loader = new Loader();

    customer$: Observable<ICustomer>;
    paymentMethods$: Observable<IPaymentMethod[]>;
    showPaymentMethods$: Observable<boolean>;

    appTheme = this.environment.appTheme;
    constructor(
        private paymentService: PaymentService,
        private accountRoute: AccountRouteService,
        private authService: AuthService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig
    ) {}

    ngOnInit() {
        this.initPaymentSubscriptions();
    }

    ngOnDestroy() {
        if (this.profileSubscription) {
            this.profileSubscription.unsubscribe();
        }
    }

    initPaymentSubscriptions() {
        this.errorMessage = "";

        const account$ = this.accountRoute.getAccount();

        this.customer$ = account$.pipe(
            switchMap(account => this.loader.wrap(this.paymentService.getAccountCustomer(account.id))),
            catchError(err => this.handleError(err)),
            shareReplay(1)
        );

        const user$ = this.authService.getUser().pipe(filter(user => !!user));

        this.showPaymentMethods$ = combineLatest([account$, user$]).pipe(
            map(([account, user]) => this.hasPaymentMethodPermission(account, user)),
            shareReplay()
        );

        this.paymentMethods$ = this.showPaymentMethods$.pipe(
            filter(showMethods => showMethods),
            switchMap(() => this.customer$),
            switchMap(customer => this.paymentService.getPaymentMethods(customer.id)),
            catchError(err => this.handleError(err))
        );
    }

    private hasPaymentMethodPermission(account: Account, user: AppUser): boolean {
        if (account.contacts.some(contact => contact.id === user.id && contact.type === ContactType.Primary)) {
            return true;
        } else {
            return false;
        }
    }

    private handleError(error: any): Observable<any> {
        if (error.error && error.error.message) {
            this.errorMessage = error.error.message;
        } else {
            this.errorMessage = "A problem occurred fetching the user profile";
        }

        return throwError(new HandledError(error));
    }
}
