<div class="reply" *ngIf="{isThreadActive : thread | isThreadActive | async } as thread">
    <ng-container *ngIf="!editingReply">
        <fx-avatar
            [name]="reply.actor | userToName | async"
            [image]="reply.actor | profilePicture: showSecondaryAvatar"
            size="medium"
            [secondary]="showSecondaryAvatar"
        ></fx-avatar>
        <div class="reply-details" [ngSwitch]="reply.status == CardStatus.Removed">
            <div class="reply-details-name">{{ reply.actor | userToName | async }}</div>
            <div
                *ngSwitchDefault
                class="reply-details-message"
                [ngClass]="{
                    'reply-expanded': expandCardView || !thread.isThreadActive
                }"
            >
                <quill-view
                    #replyMessage
                    (onEditorCreated)="observeQuill()"
                    [content]="reply.message | allowWhitelistLinks"
                    aria-label="message"
                ></quill-view>
            </div>
            <div *ngSwitchCase="true" class="reply-details-removed">
                This message has been removed.
            </div>

            <div
                class="reply-see-more"
                (click)="expandCardView = !expandCardView"
                *ngIf="showSeeMore && thread.isThreadActive"
            >
                <div>
                    <i class="las la-angle-{{ expandCardView ? 'up' : 'down' }} reply-see-more-icon"></i
                    >{{ expandCardView ? "Read less" : "Read more" }}
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="!editingReply">
        <div class="reply-details-right-content">
            <div *ngIf="reply.status === CardStatus.Edited" class="reply-details-edited">
                Edited
            </div>
            <div class="reply-details-timestamp">
                <div>
                    {{ reply.timestamp | date: "dd/MM/yy" }}
                </div>
                <div>&nbsp;{{ reply.timestamp | date: "h:mm a" }}</div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="editingReply">
        <div class="fx-form-input reply-details-edit">
            <quill-editor-wrapper
                (userInputEmitter)="onUserInput($event)"
                (error)="quillError = $event"
                [message]="editReplyMessage"
                [inline]="true"
            ></quill-editor-wrapper>

            <reply-buttons (discardOutput)="editingReply = false; editReplyMessage.setValue(reply.message)"
                           (submitOutput)="updateReply(reply, editReplyMessage)"
                           [submitDisabled]="!editReplyMessage.value
                           || editReplyMessage.value === reply.message
                           || loading">
            </reply-buttons>
        </div>
    </ng-container>

    <ng-container
        [ngSwitch]="
            reply.id && reply.actor === (userId$ | async) && reply.status !== CardStatus.Removed && !editingReply
        "
        *ngIf="thread.isThreadActive"
    >
        <fx-context-menu #menu [gutter]="false" *ngSwitchCase="true">
            <a (click)="editReplyMessage.setValue(reply.message); editingReply = true">Edit</a>
            <a (click)="deleteReply(reply)">Delete</a>
        </fx-context-menu>
    </ng-container>
</div>
