import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "./auth.service";
import { map } from "rxjs/operators";
import { Role } from "@findex/threads";

@Injectable({ providedIn: "root" })
export class StaffGuard implements CanActivate {
    constructor(private authService: AuthService) {}

    canActivate(): Observable<boolean> {
        return this.authService.getUser().pipe(
            map(user => user && user.globalRole),
            map(role => role === Role.Staff || role === Role.Administrator)
        );
    }
}
