import { Component, Input, Output, EventEmitter, ViewChild, OnDestroy, OnChanges, SimpleChanges } from "@angular/core";
import { CardReply, IThread, IThreadCard, Role, CardStatus } from "@findex/threads";
import { Observable } from "rxjs";
import { environmentCommon } from "../../../environment/environment.common";
import { QuillViewComponent } from "ngx-quill";
import { FormControl } from "@angular/forms";
import { ThreadCardService } from "../../services/thread-card.service";

@Component({
    selector: "reply",
    templateUrl: "./reply.component.html",
    styleUrls: ["./reply.component.scss"]
})
export class ReplyComponent implements OnChanges, OnDestroy {
    @Input() thread: IThread;
    @Input() reply: CardReply;
    @Input() userId$: Observable<string>;
    @Input() card: IThreadCard;
    @Output() replyLoading = new EventEmitter<boolean>();
    @ViewChild("replyMessage") replyMessage: QuillViewComponent;

    loading: boolean;
    showSecondaryAvatar: boolean;
    expandCardView = false;
    editingReply = false;
    CardStatus = CardStatus;
    editReplyMessage = new FormControl("");
    QUILL_MAX_HEIGHT = environmentCommon.quillConfig.maxHeight.reply;
    showSeeMore = false;
    quillError = false;
    isThreadActive: Promise<boolean>

    private resizeObservable: ResizeObserver;

    constructor(
        private cardService: ThreadCardService
    ) {
        this.resizeObservable = new ResizeObserver(() => this.toggleSeeMoreButton());
    }

    observeQuill() {
        this.resizeObservable.observe(this.replyMessage?.quillEditor?.root);
    }

    ngOnChanges(changes: SimpleChanges) {
        const { reply } = changes;

        if (reply) {
            this.showSecondaryAvatar = this.isClient(this.reply?.actor);
        }
    }

    ngOnDestroy() {
        if (this.resizeObservable) {
            this.resizeObservable.disconnect();
        }
        this.replyLoading.emit(false);
    }

    toggleSeeMoreButton() {
        //Measures the height of the quill editor and triggers 'see more' if longer than our max height
        this.showSeeMore = this.replyMessage?.quillEditor?.root?.offsetHeight >= this.QUILL_MAX_HEIGHT;
    }

    onUserInput(message: FormControl) {
        this.editReplyMessage = message;
    }

    async updateReply(reply: CardReply, message: FormControl) {
        this.loading = true;

        this.replyLoading.emit(true);

        await this.cardService.updateReplyCard(this.thread.id, this.card.id, reply.id, message.value).toPromise();
        reply.message = message.value;
        reply.status = CardStatus.Edited;

        this.editReplyMessage.setValue("");
        this.editingReply = false;
        this.replyLoading.emit(false);
        this.loading = false;
    }

    async deleteReply(reply: CardReply) {
        this.replyLoading.emit(true);

        await this.cardService.deleteReplyCard(this.thread.id, this.card.id, reply.id).toPromise();
        reply.status = CardStatus.Removed;

        this.replyLoading.emit(false);
    }

    private isClient(actorId: string): boolean {
        try {
            const role = this.thread.participants.find(user => user.id === actorId).role;
            return role === Role.Client || role === null;
        } catch (e) {
            return false;
        }
    }
}
