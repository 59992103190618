import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { IThread, Role } from "@findex/threads";
import { CreateMenuExtension, CreateMenuExtensionOption, ILibrary } from "projects/portal-modules/src/lib/plugins";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { Observable } from "rxjs";
import { filter, map, shareReplay } from "rxjs/operators";
import { EXTENSION_MENU_LIBRARY } from "src/app/injection-token";
import { AnalyticsService, GA_EVENTS } from "../../../analytics";
import { AuthService } from "../../../findex-auth";

interface IExtensionModalData {
    extensionConfig: {
        extensionId: string;
    };
    thread: IThread;
}

@Component({
    selector: "calendar-create-modal",
    templateUrl: "./extension-menu-modal.component.html",
    styleUrls: ["./extension-menu-modal.component.scss"]
})
export class ExtensionMenuModalComponent implements OnInit {
    readonly gaEvents = GA_EVENTS;

    currentSelection: CreateMenuExtensionOption;
    extension: CreateMenuExtension;
    extensionItem: CreateMenuExtensionOption[];
    loader = new Loader();
    data: IExtensionModalData;
    role$: Observable<Role>;

    constructor(
        @Inject(EXTENSION_MENU_LIBRARY) private meetingExtensions: ILibrary<CreateMenuExtension>,
        private dialog: MatDialog,
        private dialogRef: MatDialogRef<ExtensionMenuModalComponent>,
        @Inject(MAT_DIALOG_DATA) data: IExtensionModalData,
        private analytics: AnalyticsService,
        private authService: AuthService
    ) {
        this.data = data;
    }

    ngOnInit() {
        this.extension = this.meetingExtensions.resolve(this.data.extensionConfig.extensionId);
        this.role$ = this.authService.getUser().pipe(
            filter(user => !!user),
            map(user => user.globalRole),
            shareReplay()
        );
    }

    setExtensionAction($event: boolean, extension: CreateMenuExtensionOption): void {
        this.analytics.recordEvent(
            "mouse-click",
            `${this.data.extensionConfig.extensionId}-${extension.analyticsEvent}-${this.gaEvents.APP_EXTENSION_CHECKBOX}`
        );

        if (!$event) {
            this.currentSelection = null;
        } else {
            this.currentSelection = extension;
        }
    }

    async continue(currentSelection: CreateMenuExtensionOption): Promise<void> {
        if (!("componentRef" in currentSelection) && "action$" in currentSelection) {
            await this.createCard(currentSelection);
        }

        if ("componentRef" in currentSelection && !("action$" in currentSelection)) {
            this.createModal(currentSelection);
        }

        this.dialogRef.close();
    }

    close(): void {
        this.dialogRef.close();
    }

    private async createCard(extension: CreateMenuExtensionOption): Promise<void> {
        this.loader.show();
        if (!("action$" in extension)) {
            return;
        }
        await extension.action$(this.data.thread).toPromise();
        this.loader.hide();
        this.dialogRef.close();
    }

    private createModal(currentSelection: CreateMenuExtensionOption): void {
        if (!("componentRef" in currentSelection)) {
            return;
        }

        this.dialog.open(currentSelection.componentRef, {
            disableClose: false,
            backdropClass: "modal-backdrop",
            panelClass: ["threads-sidebar", "threads-sidebar--large", "mat-dialog-no-styling"],
            closeOnNavigation: true,
            maxWidth: "100%",
            maxHeight: "100%",
            minHeight: "100%",
            height: "100vh",
            autoFocus: false,
            data: this.data
        });
    }
}
