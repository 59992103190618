import { NgModule } from "@angular/core";

import { InsightsRoutingModule } from "./insights-routing.module";
import { InsightsReportListComponent } from "./components/insights-reporting/insights-report-list/insights-report-list.component";
import { InsightsReportComponent } from "./components/insights-reporting/insights-report/insights-report.component";
import { InsightsReportListItemComponent } from "./components/insights-reporting/insights-report-list-item/insights-report-list-item.component";
import { InsightsAccountListingComponent } from "./components/account-listing/insights-account-listing.component";
import { CommonModule } from "@angular/common";
import { InsightsReportingComponent } from "./components/insights-reporting/insights-reporting.component";
import { InsightsRouteComponent } from "./components/insights-route/insights-route.component";
import { InsightsOverviewComponent } from "./components/insights-overview/insights-overview.component";
import { InsightsMetricWidgetComponent } from "./components/insights-metric-widget/insights-metric-widget.component";
import { InsightsMetricDetailRouteComponent } from "./components/insights-metric-detail-route/insights-metric-detail-route.component";
import { ScatterReportComponent } from "./components/scatter-report/scatter-report.component";
import { HistoricalComponent } from "./components/historical-report/historical-report.component";
import { VaultModule } from "../vault/vault.module";
import { InsightsPlugin } from "../insights/insights.plugin";
import { OpenMeasuresService } from "./services/open-measures.service";
import { AbsolutePipe } from "./pipes/absolute.pipe";
import { VisualisationModule } from "./modules/visualisation";
import { ThreadsUiModule } from "../../portal-modules/src/lib/threads-ui";
import { FindexUiModule } from "../../portal-modules/src/lib/findex-ui";
import { FxTableModule } from "../../portal-modules/src/lib/fx-table/fx-table.module";
import { PLUGIN_FACTORY } from "../../portal-modules/src/lib/plugins";
import { InsightsMetricSummaryComponent } from "./components/insights-metric-summary/insights-metric-summary.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { InsightsTrendPillComponent } from "./components/insights-trend-pill/insights-trend-pill.component";

@NgModule({
    declarations: [
        InsightsTrendPillComponent,
        InsightsMetricSummaryComponent,
        InsightsReportingComponent,
        InsightsReportListComponent,
        InsightsReportComponent,
        InsightsReportListItemComponent,
        InsightsAccountListingComponent,
        InsightsOverviewComponent,
        InsightsMetricWidgetComponent,
        InsightsRouteComponent,
        InsightsMetricDetailRouteComponent,
        ScatterReportComponent,
        HistoricalComponent,
        AbsolutePipe
    ],
    imports: [CommonModule, InsightsRoutingModule, ThreadsUiModule, FindexUiModule, VaultModule, FxTableModule, VisualisationModule, MatTooltipModule],
    providers: [
        { provide: PLUGIN_FACTORY, useClass: InsightsPlugin, multi: true },
        OpenMeasuresService
    ]
})
export class InsightsModule {}
