import { Inject, Pipe, PipeTransform } from "@angular/core";
import { ENVIRONMENT } from "src/app/injection-token";
import { EnvironmentSpecificConfig, environmentCommon } from "../../environment/environment.common";

@Pipe({ name: "profilePicture" })
export class ProfilePicturePipe implements PipeTransform {
    constructor(@Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig) {}

    transform(userId: string, secondary: boolean = false, getMaxSize: boolean = false) {
        const { profileBase } = this.environment.publicEndpoints;
        const { users } = environmentCommon.threadsEndpoints;
        const url = `${profileBase}${users}/${userId}/photo`;

        return `${url}?secondary=${secondary}&getMaxSize=${getMaxSize}`;
    }
}
