import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ToastComponent } from "../components/toast/toast.component";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class ToastService {
    constructor(private matDialog: MatDialog) {}

    public openWarningDialog(message: string): void {
        this.matDialog.open(ToastComponent, {
            closeOnNavigation: false,
            disableClose: true,
            backdropClass: "modal-backdrop",
            panelClass: ["centre-toast-modal"],
            data: {
                message,
            },
        });
    }

    public openPromptDialog(message: string, buttonLabel: string): Observable<void> {
        const dialogRef = this.matDialog.open(ToastComponent, {
            closeOnNavigation: false,
            disableClose: true,
            backdropClass: "modal-backdrop",
            panelClass: ["centre-toast-modal"],
            data: {
                message,
                buttonLabel,
            },
        });
        return dialogRef.afterClosed();
    }
}
