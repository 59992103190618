<centered-modal-layout
    (save)="update()"
    (close)="close()"
    [disableSave]="form.invalid || loading"
    [loading]="loading"
    saveButtonTitle="Save"
    analyticsPrefix="edit-billing-details"
>
    <div modal-header>Billing details</div>
    <div modal-content>
        <form [formGroup]="form" class="edit-billing-details fx-form fx-form--dark">
            <div class="fx-form-group">
                <label class="fx-form-label" for="name">Full Name</label>
                <div class="fx-form-control">
                    <input
                        class="fx-form-input"
                        formControlName="name"
                        placeholder="John Smith"
                        [ngClass]="{
                            'fx-form-input--error': !form.controls['name'].valid && form.controls['name'].touched
                        }"
                    />
                </div>
                <div
                    class="fx-form-validation"
                    *ngIf="
                        form.controls['name'].hasError('required') && form.controls['name'].touched;
                        else spaceFiller
                    "
                >
                    Name is required.
                    <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                </div>
            </div>
            <div class="fx-form-group">
                <label class="fx-form-label" for="address">Address</label>
                <div class="fx-form-control">
                    <input
                        class="fx-form-input"
                        formControlName="address"
                        placeholder="300/171 Williams St"
                        [ngClass]="{
                            'fx-form-input--error': !form.controls['address'].valid && form.controls['address'].touched
                        }"
                    />
                </div>
                <div
                    class="fx-form-validation"
                    *ngIf="
                        form.controls['address'].hasError('required') && form.controls['address'].touched;
                        else spaceFiller
                    "
                >
                    Address is required.
                    <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                </div>
            </div>
            <div class="fx-form-group">
                <label class="fx-form-label" for="city">City</label>
                <div class="fx-form-control">
                    <input
                        class="fx-form-input"
                        formControlName="city"
                        placeholder="Melbourne"
                        [ngClass]="{
                            'fx-form-input--error': !form.controls['city'].valid && form.controls['city'].touched
                        }"
                    />
                </div>
                <div
                    class="fx-form-validation"
                    *ngIf="
                        form.controls['city'].hasError('required') && form.controls['city'].touched;
                        else spaceFiller
                    "
                >
                    City is required.
                    <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                </div>
            </div>
            <div class="fx-form-group">
                <label class="fx-form-label" for="postcode">Postcode</label>
                <div class="fx-form-control">
                    <input
                        class="fx-form-input"
                        formControlName="postcode"
                        placeholder="3000"
                        [ngClass]="{
                            'fx-form-input--error':
                                !form.controls['postcode'].valid && form.controls['postcode'].touched
                        }"
                    />
                </div>
                <div
                    class="fx-form-validation"
                    *ngIf="
                        form.controls['postcode'].hasError('required') && form.controls['postcode'].touched;
                        else spaceFiller
                    "
                >
                    Postcode is required.
                    <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                </div>
            </div>
            <div class="fx-form-group">
                <label class="fx-form-label" for="state">State</label>
                <div class="fx-form-control">
                    <input
                        class="fx-form-input"
                        formControlName="state"
                        placeholder="Victoria"
                        [ngClass]="{
                            'fx-form-input--error': !form.controls['state'].valid && form.controls['state'].touched
                        }"
                    />
                </div>
                <div
                    class="fx-form-validation"
                    *ngIf="
                        form.controls['state'].hasError('required') && form.controls['state'].touched;
                        else spaceFiller
                    "
                >
                    State is required.
                    <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                </div>
            </div>
            <div class="fx-form-group">
                <label class="fx-form-label" for="country">Country</label>
                <div class="fx-form-control">
                    <select
                        class="fx-form-select"
                        formControlName="country"
                        [ngClass]="{
                            'fx-form-input--error': !form.controls['country'].valid && form.controls['country'].touched
                        }"
                    >
                        <ng-container *ngFor="let country of acceptedCountries">
                            <option [ngValue]="country.countryCode">{{ country.name }}</option>
                        </ng-container>
                    </select>
                </div>
                <div
                    class="fx-form-validation"
                    *ngIf="
                        form.controls['country'].hasError('required') && form.controls['country'].touched;
                        else spaceFiller
                    "
                >
                    Country is required.
                    <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                </div>
            </div>
            <div class="fx-form-group">
                <label class="fx-form-label">Card number</label>
                <div class="fx-form-input">
                    <div id="card-number"></div>
                </div>
            </div>
            <div class="edit-billing-details-expiry">
                <label class="fx-form-label">Expiry</label>
                <div class="fx-form-input">
                    <div id="card-expiry"></div>
                </div>
            </div>
            <div class="edit-billing-details-cvc">
                <label class="fx-form-label">CVC</label>
                <div class="fx-form-input">
                    <div id="card-cvc"></div>
                </div>
            </div>
            <div class="edit-billing-details-default-payment-method">
                <fx-checkbox formControlName="setAsDefault">
                    Set As Default
                </fx-checkbox>
            </div>
            <div class="edit-billing-details-default-warning" *ngIf="!!form.getRawValue().setAsDefault">
                Warning: Setting this payment method as default will update all subscriptions you have to use this
                payment method. For other payment method options, please contact your Account Executive.
            </div>
            <div class="edit-billing-details-default-warning" *ngIf="!form.getRawValue().setAsDefault">
                This will only add your card as an payment method option, not affecting any existing subscriptions.
                Click <strong>Set As Default</strong> to update all subscriptions to use this payment method.
            </div>
            <div class="edit-billing-details-error" *ngIf="errorMessage">{{ errorMessage }}</div>
            <div class="edit-billing-details-error" *ngIf="errorState.cardNumber.message">
                {{ errorState.cardNumber.message }}
            </div>
            <div class="edit-billing-details-error" *ngIf="errorState.expiryDate.message">
                {{ errorState.expiryDate.message }}
            </div>
            <div class="edit-billing-details-error" *ngIf="errorState.cvcNumber.message">
                {{ errorState.cvcNumber.message }}
            </div>
        </form>
    </div>
</centered-modal-layout>

<!-- avoid CLS from validation -->
<ng-template #spaceFiller>
    <div class="fx-form-validation--hidden" aria-hidden="true">Field has an error</div>
</ng-template>
