import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { INewsArticle } from "../model/INewsArticle";
import { INewsArticleMapper } from "../model/INewsArticleMapper";
import { ENVIRONMENT, NEWS_ARTICLE_MAPPER } from "src/app/injection-token";
import { HandledError } from "projects/portal-modules/src/lib/shared/services/threads-error-handler";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";

@Injectable({ providedIn: "root" })
export class NewsService {
    constructor(
        private http: HttpClient,
        @Inject(NEWS_ARTICLE_MAPPER) private mapper: INewsArticleMapper,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig
    ) {}

    getRecentArticles(): Observable<INewsArticle[]> {
        const { news: NEWS_CONFIG } = this.environment;
        const url = `${NEWS_CONFIG.sourceUrl}/${NEWS_CONFIG.fileName}`;
        return this.http.get<any>(url).pipe(
            catchError(err => {
                throw new HandledError(err);
            }),
            map(response => this.mapper.map(response))
        );
    }
}
