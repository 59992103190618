import { Component, OnInit } from "@angular/core";
import { AuthService } from "./../../../../portal-modules/src/lib/findex-auth";
import { concat, EMPTY, Observable, Subscription } from "rxjs";
import { filter, map, switchMap, take } from "rxjs/operators";
import { TaskNotificationsService } from "./../../../../portal-modules//src/lib/notifications";
import { NotificationsService } from "@findex/notifications-angular";
import { ICardTaskDetail, Role } from "@findex/threads";
import { GreetingFunction } from "../../greeting-helper";
import { PermissionService } from "projects/portal-modules/src/lib/threads-ui/services/permissions.service";
import { taskChannelPrefix } from "projects/portal-modules/src/lib/environment/environment.common";

@Component({
    selector: "dashboard-greeting-banner",
    templateUrl: "./dashboard-greeting-banner.html",
    styleUrls: ["./dashboard-greeting-banner.scss"]
})
export class DashboardGreetingBannerComponent implements OnInit {
    role: Role;
    greeting$: Observable<string>;
    tasks$: Observable<ICardTaskDetail[]>;
    subscriptions: Subscription[] = [];

    constructor(
        private authService: AuthService,
        private taskNotificationService: TaskNotificationsService,
        private notificationService: NotificationsService,
        private permissionService: PermissionService
    ) {}

    async ngOnInit() {
        this.role = await this.authService
            .getUser()
            .pipe(
                filter(user => !!user),
                map(user => user.globalRole),
                take(1)
            )
            .toPromise();

        this.greeting$ = this.authService.getUser().pipe(
            filter(user => !!user),
            map(user => GreetingFunction.userToGreeting(user))
        );

        const canViewDashboardTasks = await this.permissionService.checkPermissions(this.role, "ReadTask").toPromise();

        if (!canViewDashboardTasks) {
            this.tasks$ = EMPTY;
            return;
        }

        const tasks$ = this.taskNotificationService.getCardTasks();
        const updates$ = this.notificationService
            .subscribeToChannel(taskChannelPrefix)
            .pipe(switchMap(() => this.taskNotificationService.getCardTasks()));

        this.tasks$ = concat(tasks$, updates$);
    }
}
