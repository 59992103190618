import { Pipe, PipeTransform } from "@angular/core";
import { VaultRequestRow } from "../services/threads-vault.service";

@Pipe({ name: "getRequestCompletedDate" })
export class GetRequestCompletedDatePipe implements PipeTransform {
    constructor() {}

    transform(request: VaultRequestRow): string {
        if(request.status !== "Complete" ){
            return "";
        }
        const requestItems = request.state.requestItems.map((request) => request.response.complete.modifiedAt);
        const completedDate = requestItems.sort((firstDate, secondDate) => Number(firstDate) - Number(secondDate));
        return completedDate[0];
    }
}
