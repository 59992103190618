import {
    AfterContentInit,
    AfterViewInit,
    Component,
    ContentChildren,
    Input,
    OnChanges,
    QueryList,
    SimpleChanges,
    ViewChild,
    EventEmitter,
    Output,
    ContentChild
} from "@angular/core";
import { MatSort, MatSortable } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { combineLatest, merge, Observable, of, Subject } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { FxColumnDirective } from "../directives/fx-column.directive";
import { SelectionModel } from "@angular/cdk/collections";
import { AnalyticsService } from "../../analytics";
import { FxRowChildDirective } from "../directives/fx-row-child.directive";
import { IsAllSelectedPipe } from "../../shared/pipes/is-all-selected.pipe";

@Component({
    selector: "fx-table",
    templateUrl: "./fx-table.component.html",
    styleUrls: ["./fx-table.component.scss"]
})
export class FxTableComponent<RowType> implements AfterContentInit, AfterViewInit, OnChanges {
    readonly checkboxColumnId = "checkbox";

    @Input() tableData: MatTableDataSource<RowType>;
    @Input() selectable?: boolean;
    @Input() analyticsPrefix = "";
    @Input() rowClickable?: boolean;
    @Output() rowClick = new EventEmitter<RowType>();
    @Output() selected = new EventEmitter<Set<RowType>>();
    @ContentChildren(FxColumnDirective) columns: QueryList<FxColumnDirective>;
    @ContentChild(FxRowChildDirective) rowChild: FxRowChildDirective;
    @ViewChild(MatSort) sort: MatSort;

    displayedColumns$: Observable<string[]>;
    selectedRows = new Set<RowType>();
    selection = new SelectionModel<RowType>(true, []);

    get selectedRowCount() {
        return this.selection.selected.length;
    }

    get totalRowsCount() {
        return this.tableData.data.length;
    }

    constructor(private analytics: AnalyticsService, private selectedPipe: IsAllSelectedPipe) {}

    private updateColumns = new Subject<void>();

    ngAfterContentInit(): void {
        this.displayedColumns$ = merge(of(null), this.updateColumns, this.columns.changes).pipe(
            switchMap(() => this.watchColumns(this.columns)),
            map(columnIds => columnIds.filter(id => !!id)),
            map(columnIds => (this.selectable ? [this.checkboxColumnId, ...columnIds] : columnIds))
        );
    }

    async ngAfterViewInit() {
        if (this.tableData) {
            this.tableData.sortingDataAccessor = (item, property) => this.accessColumnProperty(item, property);
            this.tableData.sort = this.sort;
        }
    }

    private accessColumnProperty(item: RowType, property: string) {
        const columnVal = this.getColumnProperty(item, property);

        return typeof columnVal === "string" ? columnVal.toLocaleLowerCase() : columnVal;
    }

    private getColumnProperty(item: RowType, property: string) {
        if (!property) return;

        if (property.includes(".")) {
            return property.split(".").reduce((accumulator, value) => accumulator && accumulator[value], item);
        }

        return item[property];
    }

    ngOnChanges(changes: SimpleChanges): void {
        const { tableData, selectable } = changes;

        if (tableData || selectable) {
            this.selectedRows = new Set();
            this.emitSelectedRows(this.selectedRows);
        }

        if (tableData?.currentValue && this.sort) {
            this.tableData.sort = this.sort;
        }

        if (selectable) {
            this.updateColumns.next();
        }
    }

    checkboxClicked(row: RowType, isChecked: boolean) {
        this.analytics.recordEvent("mouse-click", `${this.analyticsPrefix}_selectone`);
        if (isChecked) {
            this.selectedRows.add(row);
        } else {
            this.selectedRows.delete(row);
        }

        this.emitSelectedRows(this.selectedRows);
    }

    toggleSelectAll() {
        this.analytics.recordEvent("mouse-click", `${this.analyticsPrefix}_selectall`);
        const allSelected = this.selectedPipe.transform(this.selectedRowCount, this.totalRowsCount);

        if (allSelected) {
            this.selection.clear();
        }

        this.tableData.data.forEach(row => {
            if (!allSelected) {
                this.selection.select(row);
            }
            this.checkboxClicked(row, !allSelected);
        });
    }

    sortBy(columnId: string, direction: "asc" | "desc" | "") {
        if (this.sort) {
            const sortConfig: MatSortable = {
                id: columnId,
                start: direction || "desc",
                disableClear: false
            };

            this.sort.sort(sortConfig);
        }
    }

    analyticsSortClick(column: FxColumnDirective) {
        if (column.sortAnalyticsEvent) {
            if (Array.isArray(column.sortAnalyticsEvent)) {
                column.sortAnalyticsEvent.forEach(sortAnalyticsEvent => {
                    this.analytics.recordEvent("mouse-click", sortAnalyticsEvent);
                });
            } else {
                this.analytics.recordEvent("mouse-click", column.sortAnalyticsEvent);
            }
        }
    }

    trackColumn(_index: number, column: FxColumnDirective): string {
        return column.id;
    }

    private emitSelectedRows(selected: Set<RowType>) {
        const clonedSet = new Set(selected);
        this.selected.emit(clonedSet);
    }

    private watchColumns(columnDirectives: QueryList<FxColumnDirective>): Observable<string[]> {
        this.selection.clear(); // clear selection on table data change
        return combineLatest(columnDirectives.map(column => column.idUpdated));
    }
}
