import { Injectable } from "@angular/core";
import { catchError, map, take } from "rxjs/operators";
import { AuthService } from "../../../findex-auth";
import { LoginChallengeResult, LoginStep } from "../../../findex-auth/model/LoginStep";
import { IMobileVerifyResponse, MobileVerifyService, MobileVerifyStatus } from "./mobile-verify.service";

@Injectable()
export class InvitationMobileVerifyService implements MobileVerifyService {
    activeInvitationId: string;
    constructor(private authService: AuthService) {}

    async requestMFACode(_phoneNo: string): Promise<IMobileVerifyResponse> {
        try {
            return await this.authService
                .loginWithToken(this.activeInvitationId)
                .pipe(
                    map(response => {
                        switch (response.step) {
                            case LoginStep.LOGIN_CHALLENGE:
                                return {
                                    status: MobileVerifyStatus.OK,
                                    message: "Success"
                                };
                            case LoginStep.LOGIN_THROTTLED:
                                return {
                                    status: MobileVerifyStatus.THROTTLED,
                                    message:
                                        "Too many requests in a short period of time, please wait a few minutes before retrying."
                                };
                            default:
                                return {
                                    status: MobileVerifyStatus.UNKNOWN_ERROR,
                                    message: "Unknown error, please contact support."
                                };
                        }
                    }),
                    catchError(async (_err, _caught) => ({
                        status: MobileVerifyStatus.UNKNOWN_ERROR,
                        message: "Unknown error, please contact support."
                    })),
                    take(1)
                )
                .toPromise();
        } catch (err) {
            console.error(err);
            throw err;
        }
    }

    async validateMFACode(code: string): Promise<IMobileVerifyResponse> {
        return await this.authService
            .verifyCode(code)
            .pipe(
                map(response => {
                    switch (response) {
                        case LoginChallengeResult.SUCCESS:
                            return {
                                status: MobileVerifyStatus.OK,
                                message: "Success"
                            };
                        case LoginChallengeResult.FAILED_ATTEMPT:
                            return {
                                status: MobileVerifyStatus.INCORRECT_CODE,
                                message: "Your code was invalid. Please try again."
                            };
                        case LoginChallengeResult.TIMEOUT:
                            return {
                                status: MobileVerifyStatus.EXPIRED,
                                message: "Your code expired. Please send another and try again."
                            };
                        default:
                            return {
                                status: MobileVerifyStatus.UNKNOWN_ERROR,
                                message: "An unknown error occurred."
                            };
                    }
                }),
                catchError(async (_err, _caught) => ({
                    status: MobileVerifyStatus.UNKNOWN_ERROR,
                    message: "Unknown error, please contact support."
                })),
                take(1)
            )
            .toPromise();
    }
}
