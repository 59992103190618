import { Injectable } from "@angular/core";
import { IThreadListing } from "@findex/threads";
import { Observable, of } from "rxjs";
import { map, shareReplay, take } from "rxjs/operators";
import { ThreadsService } from "../../services/threads.service";
import { IEnrichedThreadListing } from "../threads-list-route/threads-list-route.component";
import { AccountsService } from "../../services/accounts.service";

export interface IThreadGroupListing {
    id: string;
    name?: string;
    active?: boolean;
    isExpanded?: boolean;
    children?: IEnrichedThreadListing[];
}

@Injectable({ providedIn: "root" })
export class ThreadGrouper {
    initialised = false;
    accountNameCache = new Map<string, string>();
    threadTypes$: Observable<Record<string, string>>;

    constructor(
        private threadsService: ThreadsService,
        private accountsService: AccountsService
    ) { }
    async groupThreads(threads: IEnrichedThreadListing[]): Promise<IThreadGroupListing[]> {
        if (!threads || !threads.length) return [];

        return await this.buildGroupByAccount(threads);
    }

    private async buildGroupByAccount(threads: IEnrichedThreadListing[]): Promise<IThreadGroupListing[]> {
        const groups: IThreadGroupListing[] = [];

        for (const thread of threads) {
            const { accountId } = thread;

            const accountName = await this.getAccountName(accountId)
                    .pipe(take(1))
                    .toPromise();
            const group = this.safelyGetGroup({
                groups,
                accountId,
                accountName
            });
            group.children.push(thread);
        }

        for (const group of groups) {
            group.children.sort(this.orderThreads);
        }

        return groups.sort(this.compareGroups);
    }

    getDisplayName(type: string): Observable<string> {
        if (!this.initialised) {
            this.threadTypes$ = this.threadsService.getThreadTypes().pipe(shareReplay(1));
            this.initialised = true;
        }
        return this.threadTypes$.pipe(map(threadTypes => threadTypes?.[type] || "Other"));
    }

    private getAccountName(accountId: string): Observable<string> {
        const cachedAccountName = this.accountNameCache.get(accountId);
        if (cachedAccountName) {
          return of(cachedAccountName);
        } else {
          return this.accountsService.getAccount(accountId).pipe(
            map(threadAccount => {
              const accountName = threadAccount?.label || "Other";
              this.accountNameCache.set(accountId, accountName);
              return accountName;
            })
          );
        }
      }

    private safelyGetGroup({
        groups,
        accountId,
        accountName
    }: {
        groups: IThreadGroupListing[],
        accountId: string,
        accountName: string
    }): IThreadGroupListing {
        const group = groups.find(shadowGroup => shadowGroup.id === accountId);

        if (group) {
            return group;
        } else {
            const newGroup = { id: accountId, name: accountName, children: [] };
            groups.push(newGroup);
            return newGroup;
        }
    }

    private compareGroups(a: IThreadListing, b: IThreadListing): number {
        const aTitle = a.title || "";
        const bTitle = b.title || "";
        return aTitle.localeCompare(bTitle);
    }

    public orderThreads(a: IThreadListing, b: IThreadListing): number {
        const aUpdated = a.preview ? a.preview.timestamp : a.createdAt;
        const bUpdated = b.preview ? b.preview.timestamp : b.createdAt;

        if (!aUpdated || !bUpdated) return 0;

        const aTime = new Date(aUpdated);
        const bTime = new Date(bUpdated);

        return bTime.getTime() - aTime.getTime();
    }
}
