<div class="fx-dropdown">
    <div (click)="toggleMenu = !toggleMenu">
        <ng-content select="[dropdown-trigger]"></ng-content>
    </div>
    <div
        class="fx-dropdown-items"
        [ngClass]="{
            'fx-dropdown-items--is-visible': toggleMenu,
            'fx-dropdown-items--left-align': !isRightAligned,
            'fx-dropdown-items--right-align': isRightAligned
        }"
    >
        <ng-content></ng-content>
    </div>
</div>
